/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _map = require('lodash/map');
const _flatten = require('lodash/flatten');
const _findIndex = require('lodash/findIndex');
const _zip = require('lodash/zip');

const time = require('../time');

const formatFlight = (flight) =>
  _map(flight.getLegs(), (leg) => [
    leg.getOrigin().getCode(),
    leg.getDestination().getCode(),
    leg.getFlightNumber(),
    time.strftime('%Y-%m-%d %H:%M:%S', leg.getDeparture()),
    time.strftime('%Y-%m-%d %H:%M:%S', leg.getArrival()),
  ]);

const formatFare = function (vf, vendorIdMap) {
  const vendorId = vf.getVendor().getKey();
  return {
    fare: vf.getTotal(),
    currency: vf.getCurrency(),
    vendor:
      (vendorIdMap != null ? vendorIdMap[vendorId] : undefined) != null
        ? vendorIdMap != null
          ? vendorIdMap[vendorId]
          : undefined
        : vendorId,
  };
};

module.exports = function (search, itinerary, currency, theFare, vendorFares, vendorIdMap) {
  let fare, flights;
  let k;
  if (vendorFares == null) {
    vendorFares = undefined;
  }
  if (vendorIdMap == null) {
    vendorIdMap = undefined;
  }
  const params = {
    version: 1,
    fares: {},
    residency: search.getMeta().getResidency(),
    currency,
    n_adults: search.getMeta().getNumberOfAdults(),
  };

  const ages = search.getMeta().getYoungstersAges();
  if (ages.length) {
    params.youngsters_ages = ages;
  }

  const iterable = theFare.getFares();
  for (let iFare = 0; iFare < iterable.length; iFare++) {
    fare = iterable[iFare];
    const vf =
      (vendorFares != null ? vendorFares[iFare] : undefined) != null
        ? vendorFares != null
          ? vendorFares[iFare]
          : undefined
        : fare.getVendorFare();
    params.fares[iFare] = formatFare(vf, vendorIdMap);
  }

  const routeFlights = [];
  for (var route of Array.from(itinerary.getRoutes())) {
    flights = (() => {
      const result = [];
      for (let f of Array.from(route.getFlights())) {
        var needle;
        if (((needle = f.getLegs()[0]), Array.from(fare.getLegs()).includes(needle))) {
          result.push(f);
        }
      }
      return result;
    })();
    if (flights.length) {
      routeFlights.push(flights);
    }
  }

  params['fare-combo'] = _map(_flatten(routeFlights), (flight) =>
    _findIndex(theFare.getFares(), function (fare) {
      let needle1;
      return (needle1 = flight.getLegs()[0]), Array.from(fare.getLegs()).includes(needle1);
    })
  );

  for ([k, flights] of Array.from(_zip(['flights-out', 'flights-home'], routeFlights))) {
    if (flights) {
      params[k] = _map(flights, formatFlight);
    }
  }

  return params;
};
