import Immutable from 'immutable';
import { STATUS, HEADERS } from '../actions/responseActions';
import { HYDRATE } from '../actions/requestActions';

const defaultState = Immutable.Map({
  status: 200,
  headers: {},
});

export default function responseReducer(state = defaultState, action) {
  switch (action.type) {
    case STATUS:
      return state.set('status', action.status);
    case HEADERS:
      return state.set('headers', Immutable.fromJS(action.headers));
    case HYDRATE:
      // no need to send response headers to client
      return state.delete('headers');
    default:
      return state;
  }
}
