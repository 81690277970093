import translate from './translate';

export default function formatNumber(context, n, expo = 0) {
  let t = translate.bind(null, context);
  let [major, minor] = parseFloat(n)
    .toFixed(expo)
    .split('.');
  let formatted = major.replace(/\d(?=(\d{3})+$)/g, '$&' + t('thousandseparator'));
  if (minor) formatted += t('decimalseparator') + minor;
  return formatted;
}
