import isBrowser from '@lib/isBrowser';

/**
 * Gets cookie value by name from document.cookie
 * @param {String} name - Cookie name
 */
export default function getDocumentCookie(name) {
  // Check if we are rendering on the server
  if (!isBrowser()) return;
  // Continue if we are on the client
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
}
