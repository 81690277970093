import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  getItineraryDeparture,
  getItineraryArrival,
  getItineraryOrigin,
  getItineraryDestination,
  isOneDayTrip,
  getHomeboundDeparture,
} from '../../../lib/itineraryInfo';
import Itinerary from '../../flights/itinerary/Itinerary';
import ItinerarySectionLoading from './ItinerarySectionLoading';
import ArrowRight from '@content/icons/arrow-right.svg';
import Arrows from '@content/img/icons/arrows_white.svg';
import './ItinerarySection.less';

class ItinerarySection extends Component {
  /**
   * Renders the header of the itinerary.
   * Includes information about the origin and destination cities and travel dates.
   */
  renderHeader() {
    const {
      filters: { formatDate },
      itinerary,
    } = this.props;
    const origin = getItineraryOrigin(itinerary)._city;
    const destination = getItineraryDestination(itinerary)._city;
    const isOneWay = itinerary.getRoutes().length === 1;
    const departure = formatDate(getItineraryDeparture(itinerary), 'll');
    //  If we have a one way trip we use the arrival date of this one way route.
    //  If we have a round trip, we use the departure of the first leg in the last route.
    //  We use the departure of the homebound, but not the arrival because the traveller plans their
    // trip around the departure of their last day, not the arrival.
    const arrival = formatDate(
      isOneWay ? getItineraryArrival(itinerary) : getHomeboundDeparture(itinerary),
      'll'
    );
    const shouldShowArrival = !isOneDayTrip(itinerary);

    return (
      <div className="ItinerarySection_header">
        <div className="ItinerarySection__title">
          {origin}
          {isOneWay ? (
            <ArrowRight className="ItinerarySection__arrows" />
          ) : (
            <Arrows className="ItinerarySection__arrows" />
          )}
          {destination}
        </div>
        <div className="ItinerarySection__subTitle">{`${departure}${
          shouldShowArrival ? ` - ${arrival}` : ''
        }`}</div>
      </div>
    );
  }

  render() {
    const {
      filters: { t },
      search,
      searchData,
      ItinerarySectionUrl,
      itinerary,
      isSearchingForItineraries,
    } = this.props;

    if (itinerary) {
      return (
        <div className="ItinerarySection">
          {this.renderHeader()}
          <Itinerary
            city={searchData.getIn(['destination', 'name'])}
            search={search}
            itinerary={itinerary}
            ItinerarySectionUrl={ItinerarySectionUrl}
            isExpandable
            insideTile
            mobileVersion
          />
        </div>
      );
    }
    if (isSearchingForItineraries) {
      return <ItinerarySectionLoading />;
    }

    return (
      <div className="SingleItinerary__notFound">
        <div className="SingleItinerary__title">{t('itinerary_not_found')}</div>
      </div>
    );
  }
}

ItinerarySection.defaultProps = {
  ItinerarySectionUrl: '',
};

ItinerarySection.propTypes = {
  filters: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  searchData: ImmutablePropTypes.map.isRequired,
  ItinerarySectionUrl: PropTypes.string,
  itinerary: PropTypes.object,
  isSearchingForItineraries: PropTypes.bool.isRequired,
};

export default withRouter(connect()(ItinerarySection));
