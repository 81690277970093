import React from 'react';
import classNames from 'classnames';
import { TAB, ENTER } from '@lib/keyCodes';
import ClickOutside from '../../../ClickOutside';
import MatchMedia, { queries } from '../../../MatchMedia';
import MobileView from '../../../MobileView';
import SearchInput from '../../../frontpage/SearchForm/SearchInput';
import ControlRow from '../ControlRow';
import User from '@content/icons/user-gray.svg';
import './Travellers.less';

// Constants
const PAX_LIMIT = 9;
const INFANT_LIMIT = 2;
const ID = 'travellers';
const ADULT = 'adult';
const CHILD = 'child';
const CHILD_AGE = 'childAge';

// Func
const isPositive = x => Boolean(x > 0);
const isNegative = x => Boolean(x < 0);

// Restrictions on passengers.
const rules = {
  /**
   * Checks if pax restrictions are good.
   * Total nr of passengers cannot exceed PAX_LIMIT.
   *
   * @param {Object<Immutable.List>} childrenAges
   * @param {Number} adults - qty of adults
   * @returns {Boolean} True if total pax is OK for given input.
   * @memberof Travellers
   */
  isTotalPaxOk: (childrenAges, adults) => {
    return adults + childrenAges.size < PAX_LIMIT;
  },
  /**
   * Gives you an boolean check if the state is OK for changes
   * based on the input you give the function.
   *
   * @param {Object<Immutable.List>} childrenAges
   * @param {Number} adults - qty of adults
   * @param {Number} age - can I modify this age
   * @returns {Boolean} True if infant rule holds for the given input.
   * @memberof Travellers
   */
  isInfantsOk: (childrenAges, adults, age) => {
    const infantList = childrenAges.filter(ca => ca < INFANT_LIMIT);
    // Qty of adults must at least correspond to qty of infants (age < INFANT_LIMIT).
    // It is OK to modify a age already registered in infant list.
    return infantList.size < adults || age > INFANT_LIMIT || infantList.find(a => a === age);
  },
};

class Travellers extends React.Component {
  state = { isOpen: false };

  input = React.createRef();

  /**
   * Checks if conditions are OK to dispatch change in number of adults
   *
   * @param {Number} diff
   * @returns {undefined}
   * @memberof Travellers
   */
  onChangeAdults = diff => {
    const { actions, childrenAges, adults } = this.props;
    const totalPaxRule = isPositive(diff) && rules.isTotalPaxOk(childrenAges, adults);
    const infantRule = isNegative(diff) && rules.isInfantsOk(childrenAges, adults, null);
    if (totalPaxRule || infantRule) {
      return actions.setAdults(adults + diff);
    }
  };

  /**
   * Checks if conditions are OK to dispatch change in children qty
   *
   * @param {Number} diff
   * @returns {undefined}
   * @memberof Travellers
   */
  onChangeChildren = diff => {
    const { actions, childrenAges, adults } = this.props;
    const val = childrenAges.size + diff;
    // Always OK to decrement number of children
    if (isNegative(diff) || rules.isTotalPaxOk(childrenAges, adults)) {
      return actions.setChildren(val);
    }
  };

  /**
   * Checks if conditions are OK to dispatch change age action
   *
   * @param {Number} idx
   * @param {Number} diff
   * @param {Number} age
   * @returns {undefined}
   * @memberof Travellers
   */
  onChangeChildAge = (idx, diff, age) => {
    const { actions, childrenAges, adults } = this.props;
    // Incrementing or age above infant limit all good
    // If we have enough adults OR are
    // decrementing age already registered as an infant
    if (isPositive(diff) || rules.isInfantsOk(childrenAges, adults, age)) {
      return actions.setChildAge(idx, childrenAges.get(idx) + diff);
    }
  };

  onClickOutside = () => {
    if (queries.ipad.matches) this.onClose();
  };

  onFocus = () => {
    const { onFocusCallback } = this.props;
    this.setState({ isOpen: true });

    if (onFocusCallback) {
      onFocusCallback();
    }
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  /**
   * Checks if decrement child should be disabled for styling purposes.
   * @param {Number} age
   * @returns {Boolean} True if we are on the edge of editable changes and should not go further down.
   * @memberof Travellers
   */
  disableDecrementChildAge(age) {
    const { childrenAges, adults } = this.props;
    if (age > INFANT_LIMIT) return false;
    if (age <= 0) return true;
    return !rules.isInfantsOk(childrenAges, adults, age);
  }

  /**
   * Checks if decrement adults should be disabled for styling purposes.
   * @returns {Boolean} True if we are on the edge of editable changes and should not go further down.
   * @memberof Travellers
   */
  disableDecrementAdults() {
    const { childrenAges, adults } = this.props;
    if (adults <= 1) return true;
    return !rules.isInfantsOk(childrenAges, adults, null);
  }

  getTravellerTypeInfo(type, childAge, idx) {
    const { adults, childrenAges, t } = this.props;
    const isAddDisabled = !rules.isTotalPaxOk(childrenAges, adults);
    const addTooltip = isAddDisabled ? t('pax_rules_qty', { paxLimit: PAX_LIMIT }) : null;
    const infantRuleTxt = t('pax_rules_infants', { infantAges: '0, 1' });

    switch (type) {
      case ADULT:
        return {
          label: t('adults'),
          age: '18+',
          number: adults,
          decTooltip: this.disableDecrementAdults() && adults > 1 ? infantRuleTxt : null,
          addTooltip,
          isDecDisabled: this.disableDecrementAdults(),
          isAddDisabled,
          onClickDec: () => this.onChangeAdults(-1),
          onClickAdd: () => this.onChangeAdults(1),
          onKeyDownAdd: e => {
            if (e.keyCode === ENTER) {
              this.onChangeAdults(1);
            }
          },
          onKeyDownDec: e => {
            if (e.keyCode === ENTER) {
              this.onChangeAdults(-1);
            }
          },
        };

      case CHILD:
        return {
          label: t('children'),
          age: '0-17',
          number: childrenAges.size,
          decTooltip: null,
          addTooltip,
          isDecDisabled: childrenAges.size <= 0,
          isAddDisabled,
          onClickDec: () => this.onChangeChildren(-1),
          onClickAdd: () => this.onChangeChildren(1),
          onKeyDownAdd: e => {
            if (e.keyCode === ENTER) {
              this.onChangeChildren(1);
            }

            // If no childen and we are on the last button,
            // then close the popup when pressing the tab key
            if (childrenAges.size === 0 && e.keyCode === TAB && !e.shiftKey) {
              this.onClose();
            }
          },
          onKeyDownDec: e => {
            if (e.keyCode === ENTER) {
              this.onChangeChildren(-1);
            }
          },
        };
      case CHILD_AGE:
        return {
          label: t('age_of_child %(idx)s', { idx: idx + 1 }),
          number: childAge,
          decTooltip:
            childAge > 1 && this.disableDecrementChildAge(childAge) ? infantRuleTxt : null,
          addTooltip: null,
          isDecDisabled: this.disableDecrementChildAge(childAge),
          isAddDisabled: childAge >= 17,
          onClickDec: () => this.onChangeChildAge(idx, -1, childAge),
          onClickAdd: () => this.onChangeChildAge(idx, 1),
          onKeyDownAdd: e => {
            if (e.keyCode === ENTER) {
              this.onChangeChildAge(idx, 1);
            }

            // If last child then close the popup when pressing tab key
            if (childrenAges.size === idx + 1 && e.keyCode === TAB && !e.shiftKey) {
              this.onClose();
            }
          },
          onKeyDownDec: e => {
            if (e.keyCode === ENTER) {
              this.onChangeChildAge(idx, -1, childAge);
            }
          },
        };

      default:
        return {};
    }
  }

  renderControlsRow({ type, childAge, idx }) {
    const {
      label,
      age,
      number,
      decTooltip,
      addTooltip,
      isDecDisabled,
      isAddDisabled,
      onClickDec,
      onClickAdd,
      onKeyDownAdd,
      onKeyDownDec,
    } = this.getTravellerTypeInfo(type, childAge, idx);

    return (
      <ControlRow
        key={`${type}-${idx}`}
        label={label}
        age={age}
        number={number}
        decTooltip={decTooltip}
        addTooltip={addTooltip}
        isDecDisabled={isDecDisabled}
        isAddDisabled={isAddDisabled}
        onClickDec={onClickDec}
        onClickAdd={onClickAdd}
        onKeyDownAdd={onKeyDownAdd}
        onKeyDownDec={onKeyDownDec}
      />
    );
  }

  renderControls() {
    const { childrenAges } = this.props;

    return (
      <div className="Travellers__controls">
        {this.renderControlsRow({ type: ADULT, idx: 0 })}
        {this.renderControlsRow({ type: CHILD, idx: 0 })}
        {childrenAges
          .toArray()
          .map((age, i) => this.renderControlsRow({ type: CHILD_AGE, childAge: age, idx: i }))}
      </div>
    );
  }

  /**
   * Handles key down event for search input
   */
  onKeyDownHandler = e => {
    if (e.keyCode === TAB && e.shiftKey) {
      this.onClose();
    } else if (e.keyCode === ENTER) {
      // If passengers input is focused and user pressed enter then we will focus the
      // search button
      const searchButton = document.querySelector('button.Button--submit');

      if (searchButton) {
        e.preventDefault();
        searchButton.focus();
      }
    }
  };

  render() {
    const {
      childrenAges,
      adults,
      inputClassName,
      iconClassName,
      arrowClassName,
      showLabel,
      shouldPopOpen,
      t,
    } = this.props;
    const { isOpen } = this.state;
    const label = t('passengers');
    const passengersCount = adults + childrenAges.size;
    const passengerString = passengersCount === 1 ? t('passenger') : t('passengers');

    return (
      <ClickOutside event="mousedown" onClickOutside={this.onClickOutside}>
        <div
          className={classNames('Travellers', {
            'Travellers--focus': isOpen,
          })}
        >
          <SearchInput
            id={ID}
            ref={this.input}
            readOnly
            value={passengersCount + ' ' + passengerString}
            label={label}
            showLabel={showLabel}
            icon={cn => <User className={cn} />}
            onFocus={this.onFocus}
            hasFocus={isOpen}
            shouldPopOpen={shouldPopOpen}
            onKeyDown={this.onKeyDownHandler}
            inputClassName={inputClassName}
            iconClassName={iconClassName}
            pointer
            withArrow
            arrowClassName={arrowClassName}
          />
          <MatchMedia max="ipad">
            {isOpen && (
              <MobileView title={t('passengers')} onClose={this.onClose} showConfirm>
                {this.renderControls()}
              </MobileView>
            )}
          </MatchMedia>
          <MatchMedia min="ipad">
            {isOpen && (
              <div className="Travellers__modal">
                <div className="Travellers__modalContent">{this.renderControls()}</div>
              </div>
            )}
          </MatchMedia>
        </div>
      </ClickOutside>
    );
  }
}

export default Travellers;
