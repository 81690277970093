// vendor names
export const SAS = 'SAS';
export const NEOS = 'Neos';
export const JET2 = 'Jet2';
export const KIWI = 'Kiwi';
export const FLYBE = 'FlyBe';
export const AVIANCA = 'Avianca';
export const VOLOTEA = 'Volotea';
export const EASYJET = 'easyJet';
export const VIRGIN = 'Virgin Atlantic';
export const ERNEST = 'Ernest Airlines';
export const CONDOR = 'Condor';
export const VUELING = 'Vueling';
export const AURIGNY = 'Aurigny';
export const WESTJET = 'WestJet';
export const JETSTAR = 'Jetstar';
export const INDIGO = 'IndiGo';
export const TIGERAIR = 'Tigerair Taiwan';
export const RYANAIR = 'Ryanair';
export const BLUEAIR = 'Blue Air';
export const EMIRATES = 'Emirates';
export const EUROWINGS = 'Eurowings';
export const NORWEGIAN = 'Norwegian';
export const TRANSAVIA = 'Transavia';
export const SUNEXPRESS = 'SunExpress';
export const ICELANDAIR = 'Icelandair';
export const JAZEERA = 'Jazeera';
export const AIR_TRANSAT = 'Air Transat';
export const CATHAY_PACIFIC = 'Cathay Pacific';
export const ICELANDAIR_DOHOP = 'Icelandair on Dohop';
export const BLUE_PANORAMA_AIRLINES = 'Blue Panorama Airlines';
export const CHINA_AIRLINES = 'China Airlines';
export const ETIHAD = 'Etihad';
export const SKY_EXPRESS = 'Sky Express';
export const SCOOT = 'Scoot';
export const SINGAPORE = 'Singapore Airlines';
export const DEUTSCHE_BAHN = 'Deutsche Bahn';
export const AIR_FRANCE = 'Air France';
export const AZORES_AIRLINES = 'Azores Airlines';
export const CEBU = 'Cebu Pacific Air';
export const LEVEL = 'Level';
export const CORSAIR = 'Corsair';
export const AEGEAN = 'AEGEAN Airlines';
export const OLYMPIC = 'Olympic Air';
export const PEGASUS = 'Pegasus';
export const STAR_PERU = 'Star Peru';
export const QATAR = 'Qatar';
export const LATAM = 'Latam';
export const PHILIPPINEAIRLINES = 'Philippine Airlines';
export const NORSE = 'Norse';
export const FLYR = 'Flyr';
export const TRANZER = 'Tranzer';
// fee vendors
export const DOHOP_CONNECT = 'dohop_connect';
export const JETSTAR_CONNECT = 'jetstar_connect';
// luggage ground handling vendors
export const SELF_CONNECT = 'SelfConnect';
// partner names on platform
export const AEGEAN_PARTNER = 'AEGEAN';
export const AIR_TRANSAT_PARTNER = 'AirTransat';
export const SATA = 'Sata';
