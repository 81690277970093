import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import _compact from 'lodash/compact';
import _findIndex from 'lodash/findIndex';
import time from 'dohop-client-api/src/time';
import createFilters from '@lib/createFilters';
import { trackFlexibleOpened, trackFlexibleSearch } from '@lib/tracking';
import jenks from '@lib/jenks';
import { expandDateMatrix, collapseDateMatrix } from '@actions/dateMatrixActions';
import ClickOutside from '../ClickOutside';
import CloseButton from '../CloseButton';

import './DateMatrix.less';

function dateRange(min, max) {
  let dates = [];
  while (min.getTime() <= max.getTime()) {
    dates.push(min);
    min = new Date(min.getTime() + time.day);
  }
  return dates;
}

function dateKey(d1, d2) {
  return [d1, d2]
    .filter(Boolean)
    .map((d) => time.strftime('%Y-%m-%d', d))
    .join('*');
}

function getBreakpoints(fares, outboundDates, homeboundDates, n = 5) {
  let dateFares = [];
  for (let d1 of outboundDates) {
    // loop once if oneway flight
    let dates = homeboundDates ? homeboundDates : [1];
    for (let d2 of dates) {
      let f = fares.getIn([homeboundDates ? dateKey(d1, d2) : dateKey(d1), 'fare']);
      if (f) dateFares.push(f);
    }
  }
  if (dateFares.length === 0) return [];

  let breakpoints;
  try {
    // jenks doesn't work well on low number of unique elements
    breakpoints = jenks(dateFares, n).slice(1);
  } catch (e) {
    // don't care - fallback below
  }
  if (!breakpoints || breakpoints.some((v) => !v)) {
    // fallback to n evenly sized breakpoints
    let min = Math.min(...dateFares);
    let max = Math.max(...dateFares);
    breakpoints = Array(n)
      .fill(0)
      .map((_, i) => min + ((max - min) / n) * (i + 1));
  }
  return breakpoints;
}

const dateMatrixSelector = createSelector(
  (state) => state.get('dateMatrix'),
  (dateMatrix) => {
    if (!dateMatrix.get('isActive')) return;

    let { d1Min, d1Max, d2Min, d2Max, oneWay } = dateMatrix.toObject();
    let outboundDates = dateRange(d1Min, d1Max);

    let homeboundDates = !oneWay ? dateRange(d2Min, d2Max) : false;

    let breakpoints = getBreakpoints(dateMatrix.get('fares'), outboundDates, homeboundDates);
    let rows = [];

    // loop once if oneway flightSearch
    let dates = homeboundDates ? homeboundDates : [false];

    for (let d2 of dates) {
      let row = [];
      rows.push(row);
      for (let d1 of outboundDates) {
        let key = oneWay
          ? time.strftime('%Y-%m-%d', d1)
          : [d1, d2]
              .filter(Boolean)
              .map((d) => time.strftime('%Y-%m-%d', d))
              .join('*');
        let total = dateMatrix.getIn(['fares', key, 'fare']);
        let age = dateMatrix.getIn(['fares', key, 'age']);
        let breakpoint = total && _findIndex(breakpoints, (v) => total <= v);
        row.push({ total, age, d1, d2, breakpoint });
      }
    }
    return rows;
  }
);

function createURL(search, d1, d2) {
  return (
    '/' +
    _compact([
      'flights',
      search.getMeta().getFrom().join('-'),
      search.getMeta().getTo().join('-'),
      time.strftime('%Y-%m-%d', d1),
      d2 ? time.strftime('%Y-%m-%d', d2) : '',
      search.getMeta().getNumberOfAdults() > 1 && `adults-${search.getMeta().getNumberOfAdults()}`,
      search.getMeta().getYoungstersAges().length > 0 &&
        `children-${search.getMeta.getYoungstersAges().join('-')}`,
    ]).join('/')
  );
}

export class DateMatrix extends Component {
  state = {};

  onToggle = () => {
    this.props.expanded ? this.props.onCollapse() : this.props.onExpand();
  };

  onHover(hoverOutbound, hoverHomebound) {
    this.setState({ hoverOutbound, hoverHomebound });
  }

  renderContent() {
    const { t, formatCurrency, search } = this.props;
    const isOneWay = search.getMeta().oneway;

    return (
      <ClickOutside onClickOutside={this.props.onCollapse}>
        <div className="DateMatrix__content">
          <div className="DateMatrix__headingDepart">
            <div className="DateMatrix__textDepart">{t('Departure')}</div>
            <CloseButton onClose={this.props.onCollapse.bind(this)} />
          </div>
          <div className="DateMatrix__faresWrapper">
            {!isOneWay && (
              <div className="DateMatrix__headingReturn">
                <div className="DateMatrix__textReturn">{t('returndate')}</div>
              </div>
            )}

            <div className="DateMatrix__fares">
              <div className="DateMatrix__cell DateMatrix__cell--heading" />
              {this.props.rows[0].map(({ d1 }) => (
                <div
                  key={time.strftime('%m%d', d1)}
                  className={classNames('DateMatrix__cell DateMatrix__cell--heading', {
                    'DateMatrix__cell--selected': d1 === this.state.hoverOutbound,
                  })}
                >
                  {this.props.formatDate(d1, 'shortDate')}
                </div>
              ))}
              {this.props.rows.map((row) => (
                <div
                  key={!isOneWay ? time.strftime('%m%d', row[0].d2) : 'oneWayRow'}
                  className="DateMatrix__row"
                >
                  {!isOneWay && (
                    <div
                      className={classNames('DateMatrix__cell DateMatrix__cell--heading', {
                        'DateMatrix__cell--selected': row[0].d2 === this.state.hoverHomebound,
                      })}
                    >
                      {this.props.formatDate(row[0].d2, 'shortDate')}
                    </div>
                  )}

                  {isOneWay && (
                    <div className={classNames('DateMatrix__cell DateMatrix__cell--heading')}>
                      {t('price')}
                    </div>
                  )}

                  {row.map(({ d1, d2, total, age, breakpoint }) => (
                    <div
                      key={time.strftime('%m%d', d1)}
                      className={classNames('DateMatrix__cell', {
                        ['DateMatrix__cell--break' + breakpoint]: breakpoint != null,
                        'DateMatrix__cell--date': d2 ? d1.getTime() <= d2.getTime() : d1.getTime(),
                        'DateMatrix__cell--selected':
                          !time.daysApart(d1, this.props.search.getMeta().getOutboundDate()) &&
                          (d2
                            ? !time.daysApart(d2, this.props.search.getMeta().getHomeboundDate())
                            : true),
                      })}
                    >
                      {(!d2 || d1.getTime() <= d2.getTime()) && (
                        <Link
                          to={createURL(this.props.search, d1, d2)}
                          state={{ scroll: false }}
                          className="DateMatrix__link"
                          onClick={trackFlexibleSearch}
                          onMouseOver={this.onHover.bind(this, d1, d2)}
                          onMouseOut={this.onHover.bind(this)}
                        >
                          {total ? (
                            formatCurrency(total)
                          ) : (
                            <span className="DateMatrix__searchIcon" />
                          )}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="DateMatrix__info">
            <div className="DateMatrix__info__block" />
            {t('low')}
            <div className="DateMatrix__info__block DateMatrix__info__block--expensive" />
            {t('high')}
          </div>
        </div>
      </ClickOutside>
    );
  }

  render() {
    let { t, formatCurrency, savings, expanded, visible } = this.props;
    if (!visible) return false;

    return (
      <div className="DateMatrix">
        <div
          className={classNames('DateMatrix__text', { 'DateMatrix__text--active': expanded })}
          onClick={this.onToggle}
        >
          {savings != null && savings > 1 ? (
            <span
              dangerouslySetInnerHTML={{
                __html: t('would_you_like_to_save %(amount)s', {
                  amount: [
                    '<span class="DateMatrix__bannerAmount">',
                    formatCurrency(savings),
                    '</span>',
                  ].join(''),
                }),
              }}
            />
          ) : (
            <span>{t('are_you_flexible')}</span>
          )}
        </div>
        {expanded && (
          <div>
            {this.renderContent()}
            <div className="DateMatrix__pointer" />
          </div>
        )}
      </div>
    );
  }
}

DateMatrix.propTypes = {
  search: PropTypes.object.isRequired,
};

DateMatrix = createFilters(['t', 'formatDate', 'formatCurrency'])(DateMatrix);

function mapStateToProps(state, props) {
  let itinerary = state.getIn(['flightSearch', 'cheapestItinerary']);
  let cheapestDateMatrixFare = state.getIn(['dateMatrix', 'cheapest']);
  return {
    rows: dateMatrixSelector(state),
    visible: state.getIn(['dateMatrix', 'isActive']),
    expanded: state.getIn(['dateMatrix', 'isExpanded']),
    savings:
      itinerary &&
      cheapestDateMatrixFare &&
      itinerary.getFares().getBestFare().getTotal() - cheapestDateMatrixFare,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onExpand: () => {
      trackFlexibleOpened();
      dispatch(expandDateMatrix());
    },
    onCollapse: () => dispatch(collapseDateMatrix()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DateMatrix));
