/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// number of milliseconds in a second
const second = 1000;
// number of milliseconds in a minute
const minute = second * 60;
// number of milliseconds in an hour
const hour = minute * 60;
// number of milliseconds in a day
const day = hour * 24;
// number of milliseconds in a week
const week = day * 7;

// @private
const pad = (i) => ('0' + i).slice(-2);

// Formats supprted by strf- and strptime
// prettier-ignore
const FORMATS = {
	Y: {regex: '\\d{4}', get(d) { return d.getUTCFullYear(); }, set(d, v) { return d.setUTCFullYear(v); }},
	y: {regex: '\\d{2}', get(d) { return pad(d.getUTCFullYear() % 100); }, set(d, v) { return d.setUTCFullYear(2000 + Number(v)); }},
	m: {regex: '\\d{2}', get(d) { return pad(d.getUTCMonth() + 1); }, set(d, v) { return d.setUTCMonth(v - 1); }},
	d: {regex: '\\d{2}', get(d) { return pad(d.getUTCDate()); }, set(d, v) { return d.setUTCDate(v); }},
	H: {regex: '\\d{2}', get(d) { return pad(d.getUTCHours()); }, set(d, v) { return d.setUTCHours(v); }},
	M: {regex: '\\d{2}', get(d) { return pad(d.getUTCMinutes()); }, set(d, v) { return d.setUTCMinutes(v); }},
	S: {regex: '\\d{2}', get(d) { return pad(d.getUTCSeconds()); }, set(d, v) { return d.setUTCSeconds(v); }},
	'%': {get() { return '%'; }}
};

// Formats UTC date by format string
// @param [String] format Format string
// @param [Date] date object
// @return [String] formatted date string
// @example
//   time.strftime('%Y-%m-%d %H:%M', time.UTCDate(2016, 0, 1, 12, 30)) // '2016-01-01 12:30'
const strftime = function (format, d) {
  if (d == null) {
    d = new Date();
  }
  return format.replace(/%([a-z%])/gi, function (all, k) {
    if (k in FORMATS) {
      return FORMATS[k].get(d);
    } else {
      return all;
    }
  });
};

// Parses time of the given string format
// It DOESN'T escape regex special characters so whoever is calling the method has to do so himself
// @param [String] format format of date string to parse
// @param [String] dateString Optional. formatted date string. Curried function will be return if not supplied
// @return [Date] UTC date object
// @example Parsing a date string
//   time.strptime('%Y-%m-%d %H:%M', '2016-01-01 12:30');
// @example escaping regex characters
//   time.strptime('%d\\.%m\\.%y', '01.01.16')
// @example Parsing multiple strings with same format
//   // If parsing the same format multiple times, this will perform much better
//   parseFunc = time.strptime('%Y-%m-%d %H:%M')
//   parseFunc('2016-01-01 12:30') // Date object
const strptime = function (format, s) {
  const setters = [];
  const regex = new RegExp(
    '^' +
      format.replace(/%([a-z])/gi, function (all, k) {
        if (!(k in FORMATS)) {
          throw new Error(`Unknown strptime format: ${k}`);
        }
        setters.push(FORMATS[k].set);
        return `(${FORMATS[k].regex})`;
      }) +
      '$'
  );

  format = function (s) {
    const matches = s.match(regex);
    if (!matches) {
      return;
    }

    const d = new Date(0);
    for (
      let i = 0, end = setters.length, asc = 0 <= end;
      asc ? i < end : i > end;
      asc ? i++ : i--
    ) {
      setters[i](d, matches[i + 1]);
    }
    return d;
  };

  // return curried function when called with only format argument
  if (arguments.length === 1) {
    return format;
  } else {
    return format(s);
  }
};

// Function to parse common year-month-day format
const dateFormat = '%Y-%m-%d';
const parseDate = strptime(dateFormat);

// Function to parse common year-month-day hour:minute:second format
const dateTimeFormat = '%Y-%m-%d %H:%M:%S';
const parseDateTime = strptime(dateTimeFormat);

// Wrapper function around creating UTC Date objects. All dates in this library are stored like this,
// where you have to call getUTC* on dates to get the correct values. This is because of javascript's
// poor timezone support. Support same parameters as Date.UTC
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
const UTCDate = (...args) => new Date(Date.UTC(...Array.from(args || [])));

// @nodoc
const fromUTC = (d) =>
  new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );

// @nodoc
const toUTC = (d) =>
  UTCDate(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());

// Calculates how many days apart two dates are. Handles correctly rounding errors due to different timezones
// or daylight saving changes
const daysApart = function (d1, d2) {
  const t1 = UTCDate(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate()).getTime();
  const t2 = UTCDate(d2.getUTCFullYear(), d2.getUTCMonth(), d2.getUTCDate()).getTime();
  return Math.round((t2 - t1) / day);
};

module.exports = {
  second,
  minute,
  hour,
  day,
  week,
  strftime,
  strptime,
  dateFormat,
  parseDate,
  dateTimeFormat,
  parseDateTime,
  UTCDate,
  fromUTC,
  toUTC,
  daysApart,
};
