import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import moment from 'moment';
import classNames from 'classnames';
import createFilters from '../../../../lib/createFilters';
import formatAirport from '../../../../lib/formatAirport';
import Tooltip from '../../../Tooltip';
import Button from '../../../Button';
import ThreeArrows from '@content/svg/three-arrows.svg';
import SelfConnect from '@content/svg/self-connect.svg';
import GroundTransit from '@content/svg/ground_transit_orange.svg';
import SelfTransfer from '@content/svg/self-transfer.svg';
import ShortTransfer from '@content/svg/short_transfer_orange.svg';
import './ItineraryStop.less';

// mininum duration for 'book a room' button is 5 hours
const MIN_DURATION = 18000000; // 5 * 60 * 60 * 1000
// Items ids
const SELF_CONNECT = 'selfConnect';
const GROUND_TRANSIT = 'groundTransit';
const SELF_TRANSFER = 'selfTransfer';
const SHORT_TRANSFER = 'shortTransfer';

class ItineraryStop extends Component {
  /**
   * Generates an url for the 'book a room' button, that links to hotel results
   * that matches the itinerary information.
   * @param {object} params
   */
  generateHotelUrl(params) {
    const url = '//www.booking.com/searchresults.html?aid=946321&';
    const { checkinDate, checkoutDate } = params;

    if (checkinDate && checkoutDate) {
      let hotelCheckinDate = moment.utc(checkinDate);
      let hotelCheckoutDate = moment.utc(checkoutDate);

      if (hotelCheckinDate.isSame(hotelCheckoutDate, 'day')) {
        hotelCheckoutDate.add(1, 'd');
      }
      Object.assign(params, {
        checkin_year: hotelCheckinDate.get('year'),
        checkin_month: hotelCheckinDate.get('month') + 1,
        checkin_monthday: hotelCheckinDate.get('date'),
        checkout_year: hotelCheckoutDate.get('year'),
        checkout_month: hotelCheckoutDate.get('month') + 1,
        checkout_monthday: hotelCheckoutDate.get('date'),
        order: 'distance_from_search',
      });
    }

    return `${url}${Object.keys(omit(params, 'checkinDate', 'checkoutDate'))
      .map(param => `${param}=${params[param]}`)
      .join('&')}`;
  }

  /**
   * If the travellers need to change airports during the layover we render
   * information about that.
   */
  renderGroundTransit() {
    const { t, event } = this.props;
    return (
      <div className="ItineraryStop__groundTransit">
        {this.renderItem(t('groundtransit'), GROUND_TRANSIT)}
        <div className="ItineraryStop__groundTransitAirports">
          {event.groundTransit.airports.map((airport, i) => (
            <div
              key={`ground-transit-airport-${i}`}
              className="ItineraryStop__groundTransitAirport"
            >
              <Tooltip
                key={i}
                title={formatAirport(airport)}
                className="ItineraryStop__groundTransitAirportText"
              >
                {airport.getCityName()}
                <span className="ItineraryStop__groundTransitIata">({airport.getCode()})</span>
              </Tooltip>
              {i < event.groundTransit.airports.length - 1 && (
                <ThreeArrows className="ItineraryStop__arrow" />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  /**
   * Renders a button that transfers the user to our hotel results
   * that matches the itinerary information.
   */
  renderBookARoomButton() {
    const {
      t,
      event: { duration, item, start, end },
    } = this.props;
    const iata = item.getOrigin().getCode();
    const label = 'SearchResult-ItineraryButton';

    if (duration <= MIN_DURATION) return null;

    return (
      <Button
        className="ItineraryStop__button"
        href={this.generateHotelUrl({
          label,
          iata,
          checkinDate: start,
          checkoutDate: end,
        })}
        target="_blank"
        small
      >
        {t('book_room')}
      </Button>
    );
  }

  /**
   * Checks if it is needed to show extra information about the layover.
   * @returns {Boolean}
   */
  hasLayoverInfo(event) {
    const {
      event: { isSelfConnect, isSelfTransfer, shortTransfer, groundTransit, duration },
    } = this.props;
    return (
      isSelfConnect || isSelfTransfer || shortTransfer || groundTransit || duration > MIN_DURATION
    );
  }

  renderIconFromId(id) {
    const className = 'ItineraryStop__itemIcon';

    switch (id) {
      case SELF_CONNECT:
        return <SelfConnect className={className} />;
      case GROUND_TRANSIT:
        return <GroundTransit className={className} />;
      case SELF_TRANSFER:
        return <SelfTransfer className={className} />;
      case SHORT_TRANSFER:
        return <ShortTransfer className={className} />;
      default:
        return null;
    }
  }

  /**
   * Renders information item for the layover.
   * @param {String} title - The title of the information.
   * @param {String} id  - id that corresponds to a descriptive icon stored in the content/svg folder.
   */
  renderItem(title, id, tooltipTitle) {
    const item = (
      <div className="ItineraryStop__item">
        {this.renderIconFromId(id)}
        {title}
      </div>
    );

    return tooltipTitle ? <Tooltip title={tooltipTitle}>{item}</Tooltip> : item;
  }

  render() {
    const { event, key, t, formatDuration } = this.props;

    return (
      <div key={key} className="ItineraryStop">
        <div className="ItineraryStop__duration">{formatDuration(event.duration)}</div>

        <div className="ItineraryStop__info">
          <div className="ItineraryStop__line" />
          <div
            className={classNames('ItineraryStop__title', {
              'ItineraryStop__title--only': !this.hasLayoverInfo(event),
            })}
          >
            {t('layover')}
          </div>
          {event.isSelfConnect &&
            this.renderItem('Self-Connect', SELF_CONNECT, t('self_connect_explanation'))}
          {event.isSelfTransfer &&
            this.renderItem('Self-Transfer', SELF_TRANSFER, t('self_transfer_explanation'))}
          {event.shortTransfer && this.renderItem(t('self-connect-alert'), SHORT_TRANSFER)}
          {event.groundTransit && this.renderGroundTransit(event)}
          {this.renderBookARoomButton(event)}
        </div>
      </div>
    );
  }
}

ItineraryStop.defaultProps = {
  key: '',
};

ItineraryStop.propTypes = {
  t: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  formatDuration: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  key: PropTypes.string,
};

ItineraryStop = createFilters(['t', 'formatDate', 'formatDuration'])(ItineraryStop);

export default ItineraryStop;
