import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';

export default function formatAirport(airport, sep = ', ', sepMulti = ' & ') {
  if (isArray(airport)) {
    let airports = airport;
    airport = {
      getName: () => uniq(airports.map(a => a.getName())).join(sepMulti),
      getCityName: () => uniq(airports.map(a => a.getCityName())).join(sepMulti),
      getCountryName: () => uniq(airports.map(a => a.getCountryName())).join(sepMulti),
    };
  }

  let s = airport.getName();
  if (airport.getName() !== airport.getCityName()) {
    s += sep + airport.getCityName();
  }
  s += sep + airport.getCountryName();
  return s;
}
