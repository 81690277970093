import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import { getHotelUrlFromSearch, getCarsUrlFromItinerary } from '@lib/getFlightUrl';
import config from '@lib/config';
import './TransferStepAd.less';

const { DOHOP_CARS_URL } = config;

const TransferStepAd = props => {
  const { t, destination, type, imgSrc, user, itinerary, search } = props;
  const city = destination.get('city') || destination.get('name');

  /**
   * Generates link that links to the hotel or car page depending on the type prop.
   */
  const createLink = () => {
    if (type === 'hotel') {
      return getHotelUrlFromSearch(search, destination, user) + '&label=transferstep&class=any';
    }

    if (type === 'car') {
      return itinerary ? getCarsUrlFromItinerary(itinerary, user) : DOHOP_CARS_URL;
    }
  };

  return (
    <a className={`TransferStepAd TransferStepAd--${type}`} target="_blank" href={createLink()}>
      <div className="TransferStepAd__container">
        <div className="TransferStepAd__imageContainer">
          <div className="TransferStepAd__image" style={{ backgroundImage: `url('${imgSrc}')` }} />
          <div className="TransferStepAd__imageOpacity" />
        </div>
        <div className="TransferStepAd__contentContainer">
          {destination && !destination.isEmpty() && (
            <div className="TransferStepAd__content">
              <span className="TransferStepAd__text">
                {type === 'hotel' && t('dohop_recommends %(city)s', { city })}
                {type === 'car' && t('landing_car %(city)s', { city })}
              </span>
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

TransferStepAd.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  destination: ImmutablePropTypes.map.isRequired,
  type: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  user: PropTypes.any.isRequired,
};

export default withRouter(
  connect(state => ({
    user: state.get('user'),
    destination: state.getIn(['flightSearch', 'destination'], Map()),
  }))(TransferStepAd)
);
