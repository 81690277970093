// http://easings.net/
// https://github.com/danro/easing-js/blob/master/easing.js

// https://api.jqueryui.com/easings/
// https://github.com/jquery/jquery-ui/blob/master/ui/effect.js#L1586

export function easeOutQuad(pos) {
  return -(Math.pow(pos - 1, 2) - 1);
}

export function easeInOutSine(pos) {
  return -0.5 * (Math.cos(Math.PI * pos) - 1);
}

export function elastic(pos) {
  return -1 * Math.pow(4, -8 * pos) * Math.sin((pos * 6 - 1) * (2 * Math.PI) / 2) + 1;
}

export default function animate(options) {
  let { cb, duration = 300, easing = easeOutQuad, startValue = 0, endValue = 1 } = options;

  return new Promise(resolve => {
    window.requestAnimationFrame(() => {
      // initialize start time after first requestAnimationFrame callback, otherwise there seems to
      // be a noticable jump at the start of the animation
      let startTime = Date.now();
      let endTime = startTime + duration;
      cb(startValue);
      window.requestAnimationFrame(function loop() {
        let now = Date.now();
        if (now >= endTime) {
          cb(endValue);
          resolve();
        } else {
          let currentValue = easing((now - startTime) / duration);
          cb((endValue - startValue) * currentValue + startValue);
          window.requestAnimationFrame(loop);
        }
      });
    });
  });
}
