import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { encodeURLQueryData, decodeURLQueryData } from 'shared/utils/transformURLQueryData';
import FilterBox from './FilterBox';
import Tooltip from '../Tooltip';
import RadioButton from '../form/RadioButton';
import './FilterStops.less';

class FilterStops extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(n) {
    const { filters } = this.props;
    const state = location.state || {};
    const oldQueryString = location.search ? decodeURLQueryData(location.search) : {};
    const queries = oldQueryString;
    queries.stops = n;
    const queryString = encodeURLQueryData(queries);
    history.replaceState(state, null, `${location.pathname}?${queryString}`);
    filters.set('stops', { 0: n >= 0, 1: n >= 1, 2: n >= 2 });
  }

  renderCheckbox(n, { text, title, checked, disabled }) {
    return (
      <Tooltip comp="div" title={title} className="FilterStops__filter">
        <RadioButton
          name="FilterStops__checkbox"
          disabled={disabled}
          checked={checked && !disabled}
          onChange={() => this.onToggle(n)}
        >
          {text}
        </RadioButton>
      </Tooltip>
    );
  }

  render() {
    const { t, filters, search, initialOpen } = this.props;
    const f = filters.getFilter('stops');
    const meta = search.getMeta();
    const disabledTxt = t('title_no_flights_available_in_cat');
    const noStopDisabled = !meta.hasStopCount(0);
    const oneStopDisabled = !meta.hasStopCount(0) && !meta.hasStopCount(1);

    return (
      <FilterBox title={t('stopsfilter')} initialOpen={initialOpen}>
        <div className="FilterStops__container">
          {this.renderCheckbox(0, {
            text: t('stop_none'),
            title: noStopDisabled ? disabledTxt : t('title_nostop'),
            checked: !f.allows(1) && !f.allows(2),
            disabled: noStopDisabled,
          })}
          {this.renderCheckbox(1, {
            text: t('stop_max1'),
            title: oneStopDisabled ? disabledTxt : t('title_onestop'),
            checked: f.allows(1) && !f.allows(2),
            disabled: oneStopDisabled,
          })}
          {this.renderCheckbox(2, {
            text: t('stop_any'),
            title: t('title_twostops'),
            checked: f.allows(1) && f.allows(2),
          })}
        </div>
      </FilterBox>
    );
  }
}

FilterStops.propTypes = {
  filters: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  initialOpen: PropTypes.bool,
};

export default FilterStops;
