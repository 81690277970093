import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { is } from 'immutable';
import { fetchInspired as fetchInspiredAction } from '@actions/goActions';
import * as flightSearchFormActions from '@actions/flightSearchFormActions';
import * as userActions from '@actions/userActions';
import createFilters from '@lib/createFilters';
import fetchData from '@lib/fetchData';
import FrontPage from '@components/frontpage/FrontPage';
import RecaptchaLinks from '@components/RecaptchaLinks';

class FlightFrontPageRoute extends Component {
  componentDidMount() {
    this.props.fetchCal();
  }

  componentDidUpdate(prevProps) {
    const { fetchInspired, flightSearchForm } = this.props;
    const prevOrigin = prevProps.flightSearchForm.get('origin');
    const thisOrigin = flightSearchForm.get('origin');

    if (!is(prevOrigin, thisOrigin)) {
      fetchInspired();
    }
  }

  render() {
    const {
      inspired,
      user,
      filters: { t, formatCurrency },
      fetchInspired,
    } = this.props;
    const props = {
      t,
      formatCurrency,
      inspired,
      user,
      fetchInspired,
    };

    return (
      <Fragment>
        <FrontPage {...props} />
        <RecaptchaLinks t={t} />
      </Fragment>
    );
  }
}

FlightFrontPageRoute = createFilters('filters')(FlightFrontPageRoute);

FlightFrontPageRoute = connect(
  state => ({
    inspired: state.getIn(['go', 'inspired']),
    user: state.get('user'),
    flightSearchForm: state.get('flightSearchForm'),
    hotels: state.getIn(['hotel', 'recommended']),
  }),
  dispatch => ({
    fetchCal: () => dispatch(flightSearchFormActions.fetchCalendar()),
    fetchInspired: () => dispatch(fetchInspiredAction()),
    flightSearchFormActions: bindActionCreators(flightSearchFormActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  })
)(FlightFrontPageRoute);

FlightFrontPageRoute = fetchData(FlightFrontPageRoute, async (store, { location: { query } }) => {
  await store.dispatch(flightSearchFormActions.populateFromQueryAndCookie(query));
  await store.dispatch(fetchInspiredAction());
  return store;
});

export default FlightFrontPageRoute;
