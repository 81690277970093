import { createAction, createImmutableAction } from '../lib/redux/actions';
import { requestCarAutocomplete } from './autocompleteActions';
import _zipObject from 'lodash/zipObject';
import _isEmpty from 'lodash/isEmpty';
import time from 'dohop-client-api/src/time';
import { Map } from 'immutable';
import { getCookie, setCookie } from '../lib/redux/cookie';
import processException from '../lib/processException';
import { FORM_COOKIE_NAME } from './flightSearchFormActions';

export const CARS_RECEIVE_PICKUP_LOCATION = 'CARS_RECEIVE_PICKUP_LOCATION';
export const CARS_RECEIVE_DROPOFF_LOCATION = 'CARS_RECEIVE_DROPOFF_LOCATION';
export const CARS_RECEIVE_PICKUP_DATE = 'CARS_RECEIVE_PICKUP_DATE';
export const CARS_RECEIVE_PICKUP_TIME = 'CARS_RECEIVE_PICKUP_TIME';
export const CARS_RECEIVE_DROPOFF_DATE = 'CARS_RECEIVE_DROPOFF_DATE';
export const CARS_RECEIVE_DROPOFF_TIME = 'CARS_RECEIVE_DROPOFF_TIME';
export const CARS_RECEIVE_IS_DIFFERENT_DROPOFF_LOCATION =
  'CARS_RECEIVE_IS_DIFFERENT_DROPOFF_LOCATION';
export const CARS_RECEIVE_DRIVERS_AGE = 'CARS_RECEIVE_DRIVERS_AGE';
export const CARS_RECEIVE_CAR_GROUP = 'CARS_RECEIVE_CAR_GROUP';
export const CARS_RECEIVE_COOKIEKEYS_OR_QUERYKEYS = 'CARS_RECEIVE_COOKIEKEYS_OR_QUERYKEYS';

export const setPickupLocationAction = createImmutableAction(
  CARS_RECEIVE_PICKUP_LOCATION,
  'pickup'
);
export const setIsDiffLocationAction = createAction(
  CARS_RECEIVE_IS_DIFFERENT_DROPOFF_LOCATION,
  'differentDropoff'
);
export const setDropoffLocationAction = createAction(CARS_RECEIVE_DROPOFF_LOCATION, 'dropoff');
export const setPickupDateAction = createAction(CARS_RECEIVE_PICKUP_DATE, 'pickupDate');
export const setPickupTimeAction = createAction(CARS_RECEIVE_PICKUP_TIME, 'pickupTime');
export const setDropoffDateAction = createAction(CARS_RECEIVE_DROPOFF_DATE, 'dropoffDate');
export const setDropoffTimeAction = createAction(CARS_RECEIVE_DROPOFF_TIME, 'dropoffTime');

export const setPickupLocation = (pickup) => saveCookieWrapper(setPickupLocationAction, pickup);
export const setDropoffLocation = (dropoff) => saveCookieWrapper(setDropoffLocationAction, dropoff);
export const setPickupDate = (date) => saveCookieWrapper(setPickupDateAction, date);
export const setDropoffDate = (date) => saveCookieWrapper(setDropoffDateAction, date);
export const setPickupTime = (pickupTime) => saveCookieWrapper(setPickupTimeAction, pickupTime);
export const setDropoffTime = (dropoffTime) => saveCookieWrapper(setDropoffTimeAction, dropoffTime);
export const setIsDiffLocation = (differentDropoff) =>
  saveCookieWrapper(setIsDiffLocationAction, differentDropoff);

function saveSearchCookie() {
  return (dispatch, getState) => {
    const prevCookieValue = dispatch(getCookie(FORM_COOKIE_NAME));
    let [flightCookieValue, hotelCookieValue, carCookieValue] = (prevCookieValue || '').split('__');

    const {
      pickup,
      dropoff,
      pickupDate,
      dropoffDate,
      pickupTime,
      dropoffTime,
      differentDropoff,
    } = getState().getIn(['cars', 'form']).toObject();
    carCookieValue = [
      pickup ? pickup.get('airportCode') : '',
      dropoff ? dropoff.get('airportCode') : '',
      pickupDate ? time.strftime('%Y-%m-%d', pickupDate) : '',
      dropoffDate ? time.strftime('%Y-%m-%d', dropoffDate) : '',
      pickupTime,
      dropoffTime,
      dropoff ? differentDropoff : false,
    ].join('|');

    const cookieValue = [flightCookieValue, hotelCookieValue, carCookieValue].join('__');
    const cookieOptions = { path: '/', maxAge: 60 * 60 * 24 * 30 };
    dispatch(setCookie(FORM_COOKIE_NAME, cookieValue, cookieOptions));
  };
}

function saveCookieWrapper(func, ...args) {
  return (dispatch, getState) => {
    dispatch(func(...args));
    dispatch(saveSearchCookie());
  };
}

function getInputFromCookie(cookieValue) {
  const carCookieValue = (cookieValue || '').split('__')[2];
  const cookieKeys = [
    'pickup',
    'dropoff',
    'pickupDate',
    'dropoffDate',
    'pickupTime',
    'dropoffTime',
    'differentDropoff',
  ];
  return _zipObject(cookieKeys, carCookieValue ? carCookieValue.split('|') : []);
}

// Function that formats string from "HH:MM" to milliseconds from 00:00
function formatTime(t) {
  const timeValues = t.split(':');
  const hours = parseInt(timeValues[0].substring(0, 2), 10);
  // Always round up to next multiple of 15
  const minutes = timeValues.length === 2 ? Math.ceil(parseInt(timeValues[1], 10) / 15) * 15 : 0;
  return hours * time.hour + minutes * time.minute;
}

export function autoFillForm(queries = {}) {
  return async (dispatch, getState) => {
    // queries params have more value than cookie params
    if (!queries.pickup && queries.dropoff) queries.pickup = null;
    if (queries.pickup && !queries.dropoff) queries.dropoff = null;
    if (queries.pickupTime) queries.pickupTime = formatTime(queries.pickupTime);
    if (queries.dropoffTime) queries.dropoffTime = formatTime(queries.dropoffTime);
    const cookieValue = dispatch(getCookie(FORM_COOKIE_NAME));

    // Check if any cookies are set and query parameters for rental cars
    if (!cookieValue && _isEmpty(queries)) return;

    const input = Object.assign(getInputFromCookie(cookieValue), queries);
    const action = { type: CARS_RECEIVE_COOKIEKEYS_OR_QUERYKEYS, params: Map() };

    for (let key in input) {
      if (input.hasOwnProperty(key) && input[key]) {
        if (key === 'pickupDate' || key === 'dropoffDate') input[key] = new Date(input[key]);
        if (key === 'pickupTime' || key === 'dropoffTime') input[key] = parseInt(input[key], 10);
        if (key === 'differentDropoff') input[key] = input.dropoff ? true : false;
        if (key !== 'pickup' && key !== 'dropoff') {
          action.params = action.params.set(key, input[key]);
        }
      }
    }

    dispatch(action);

    try {
      const promises = [];
      const inputPickup = input.pickup ? input.pickup.split(',')[0] : '';
      const inputDropoff = input.dropoff ? input.dropoff.split(',')[0] : '';
      const userLang = getState().getIn(['user', 'language']);

      promises.push(await requestCarAutocomplete(inputPickup, userLang));
      promises.push(await requestCarAutocomplete(inputDropoff, userLang));
      const responses = await Promise.all(promises);
      const [pickupResults, dropoffResults] = responses;

      if (pickupResults.length > 0) {
        dispatch(setPickupLocationAction(Map(pickupResults[0].item)));
      }

      if (dropoffResults.length > 0) {
        dispatch(setDropoffLocationAction(Map(dropoffResults[0].item)));
      }
    } catch (error) {
      processException(error);
    }
  };
}
