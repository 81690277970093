import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { formatTransferQueryParams } from 'shared/utils/transferUtils';
import createBookingParams from 'dohop-client-api/src/helpers/createBookingParams';
import redirectWithPost from '../../lib/redirectWithPost';
import execFBPixelEvent from '../../lib/facebookPixel';
import config from '../../lib/config';
import isDohopFare from '../../lib/isDohopFare';
import Button from '../Button';
import { trackTransfer } from '../../lib/tracking';

const PROP_NAMES = [
  'comp',
  'search',
  'itinerary',
  'multiFare',
  'fare',
  'vendorFare',
  'onClickMulti',
  'user',
  'trackTransfer',
];

function createTransferURL(search, vendorFare, currency) {
  const params = [
    search.getSessionKey(),
    vendorFare.getFareId(),
    vendorFare.getVendor().getKey(),
    currency,
  ];
  let deeplink = '/transfer/' + params.join('/');

  if (isDohopFare(vendorFare)) {
    // Add / replace query params if they are there and are necessary
    deeplink = formatTransferQueryParams(deeplink);
  }

  return deeplink;
}

class TransferLink extends Component {
  onClickSingle(e) {
    const { search, itinerary, multiFare, fare, user, form } = this.props;
    const vendorFare = this.getVendorFare();
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
    } = form.toObject();

    // Execute facebook pixel ViewContent event
    execFBPixelEvent({
      event: 'ViewContent',
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
    });

    this.props.trackTransfer(itinerary, multiFare, fare, vendorFare);

    // intercept if vendor is bookable with dohops booking api
    if (
      config.BOOKING_PAGE_URL &&
      config.BOOKABLE_VENDORS &&
      config.BOOKABLE_VENDORS[vendorFare.getVendor().getKey()]
    ) {
      e.preventDefault();
      const params = {
        language: user.get('language'),
        currency: user.get('currency'),
        post: JSON.stringify(
          createBookingParams(search, itinerary, user.get('currency'), fare, [vendorFare])
        ),
      };
      redirectWithPost(config.BOOKING_PAGE_URL, params, '_blank');
    }
  }

  getVendorFare() {
    if (this.props.vendorFare) return this.props.vendorFare;
    if (this.props.fare && !this.props.fare.isMulti()) return this.props.fare.getVendorFare();
  }

  render() {
    const { search, fare, onClickMulti, user } = this.props;
    const vendorFare = this.getVendorFare();
    const props = omit(this.props, PROP_NAMES);

    if (vendorFare) {
      props.href = createTransferURL(search, vendorFare, user.get('currency'));
      props.target = '_blank';
      props.onClick = this.onClickSingle.bind(this);
    } else if (fare.isMulti()) {
      props.onClick = onClickMulti;
    } else {
      props.target = '_blank';
      props.href = 'http://' + fare.getOutboundLegs()[0].getAirline().getWebsiteURL();
    }

    const Comp = this.props.comp;

    return <Comp {...props}>{this.props.children}</Comp>;
  }
}

TransferLink.propTypes = {
  search: PropTypes.any.isRequired,
  itinerary: PropTypes.object.isRequired,
  fare: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

TransferLink.defaultProps = {
  comp: Button,
};

function mapStateToProps(state) {
  return {
    form: state.get('flightSearchForm'),
    user: state.get('user'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    trackTransfer: (...args) => dispatch(trackTransfer(...args)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferLink);
