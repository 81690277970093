import React, { Component } from 'react';
import { Map, List } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';
import createFilters from '@lib/createFilters';
import {
  setDestination as setDestinationAction,
  createUrl as createUrlAction,
  fetchGo as fetchGoAction,
  resetFilters,
  setDates,
} from '@actions/goActions';
import {
  setDateFlexible,
  setFlexibleDates,
  setDestination as setFormDestinationAction,
} from '@actions/flightSearchFormActions';
import suggestedCities, { CONTINENTS } from '../../constants/suggestedCities';
import MobileFilters from './MobileGoFilters';
import MatchMedia from '../MatchMedia';
import Loading from '../Loading';
import AllResultsFiltered from '../flights/AllResultsFiltered';
import GoImageLayer from './GoImageLayer';
import './GoDestinations.less';

class GoDestinations extends Component {
  state = { show: 14 };

  componentDidMount() {
    window.addEventListener('scroll', this.updateView);
    window.addEventListener('resize', this.updateView);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateView);
    window.removeEventListener('resize', this.updateView);
  }

  onChangeDestination = city => {
    const { setDestination, setFormDestination, fetchGo, history, createUrl } = this.props;
    const destination = Map({
      name: city.get('name'),
      country: city.get('countryName'),
      code: city.get('to').join(','),
      iata: city.get('iata'),
    });
    setDestination(destination);
    setFormDestination(destination);
    fetchGo();
    history.push(createUrl());
  };

  getIsSearchLoading() {
    const { search, searchIsLoading, results } = this.props;

    return (
      (search && !search.isDone() && !search.hasResults()) ||
      (searchIsLoading && results.size === 0)
    );
  }

  updateView = () => {
    const { results } = this.props;
    const { show } = this.state;

    if (!this.refs.more) {
      return;
    }

    if (this.refs.more.getBoundingClientRect().top - window.innerHeight - 50 <= 0) {
      if (show < results.size) {
        this.setState(state => {
          return {
            show: state.show + 14,
          };
        });
      }
    }
  };

  getContinentName(continent) {
    const { t } = this.props;
    const translations = {
      [CONTINENTS.EUROPE]: t('europe'),
      [CONTINENTS.ASIA]: t('asia'),
      [CONTINENTS.NORTH_AMERICA]: t('north america'),
      [CONTINENTS.SOUTH_AMERICA]: t('south america'),
      [CONTINENTS.AFRICA]: t('africa'),
      [CONTINENTS.OCEANIA]: t('oceania'),
    };
    return translations[continent];
  }

  renderEmptyResults() {
    const { t, isCountry, reset, toAnytime } = this.props;

    if (isCountry) {
      return <AllResultsFiltered t={t} onReset={() => reset()} modifyDates={() => toAnytime()} />;
    }

    return (
      <div>
        {suggestedCities
          .map((continent, iContinent) => (
            <div key={iContinent} className="GoDestinations__continent">
              <div className="GoDestinations__continent__header">
                {this.getContinentName(iContinent)}
              </div>
              <div className="GoDestinations__cities">{continent.map(this.renderCity)}</div>
            </div>
          ))
          .toArray()}
      </div>
    );
  }

  renderCity = city => {
    const { origin } = this.props;

    if (origin.get('code') === city.get('to').join(',')) {
      return false;
    }

    return (
      <div
        key={city.get('to').join(',')}
        className="GoDestinations__city"
        onClick={() => this.onChangeDestination(city)}
      >
        <GoImageLayer city={city} />
      </div>
    );
  };

  render() {
    const { t, origin, results, loading } = this.props;
    const { show } = this.state;

    if (!results) {
      return false;
    }

    return (
      <div className="GoDestinations">
        <div className="GoDestinations__inspire">
          <div className="GoDestinations__inspireWrapper">
            <div className="GoDestinations__textWrapper">
              <div className="GoDestinations__inspire__header">
                {t('check_out_our_cheapest_flights %(origin)s', {
                  origin: origin.get('name'),
                })}
              </div>
              <div className="GoDestinations__warning">{t('go_warning')}</div>
            </div>
            <MatchMedia max="desktop">
              <MobileFilters t={t} showButtonText />
            </MatchMedia>
          </div>
        </div>
        <div className="GoDestinations__cities">{results.slice(0, show).map(this.renderCity)}</div>
        {results.size === 0 && !loading && this.renderEmptyResults()}
        {this.getIsSearchLoading() && <Loading text={t('gettingprice') + '...'} />}
        <div ref="more" className="GoDestinations__more" />
      </div>
    );
  }
}

GoDestinations = createFilters(['formatCurrency'])(GoDestinations);

const mapStateToProps = state => ({
  origin: state.getIn(['go', 'origin']),
  isCountry: state.getIn(['go', 'destination', 'isCountry']),
  results: state
    .getIn(['go', 'results', state.getIn(['go', 'activeKey'])], List())
    .filter(result => !result.get('disabled')),
  loading: state.getIn(['go', 'loading']),
  searchIsLoading: state.getIn(['go', 'searchIsLoading']),
  search: state.getIn(['flightSearch', 'search']),
});

const mapDispatchToProps = dispatch => ({
  createUrl: () => dispatch(createUrlAction()),
  fetchGo: () => dispatch(fetchGoAction()),
  setDestination: destination => dispatch(setDestinationAction(destination)),
  setFormDestination: destination => dispatch(setFormDestinationAction(destination)),
  reset: () => {
    dispatch(resetFilters());
    dispatch(fetchGo());
  },
  toAnytime: () => {
    dispatch(setDateFlexible(true));
    dispatch(
      setFlexibleDates(
        Map({
          d1: moment(),
          d1_: moment()
            .add(11, 'months')
            .endOf('month'),
        })
      )
    );
    dispatch(
      setDates(
        true,
        Map({
          d1: moment(),
          d1_: moment()
            .add(11, 'months')
            .endOf('month'),
        })
      )
    );
    dispatch(fetchGo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoDestinations);
