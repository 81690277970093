import React, { Component } from 'react';
import classNames from 'classnames';
import './Overlay.less';

class Overlay extends Component {
  render() {
    const { middle, className, absolute, contentClassName, children, onClick } = this.props;
    if (middle) {
      return (
        <div>
          <div
            className={classNames('Overlay', className, {
              'Overlay--absolute': absolute,
            })}
            onClick={() => onClick && onClick()}
          />
          <div className={classNames('Overlay__middle', contentClassName)}>{children}</div>
        </div>
      );
    }
    return (
      <div
        className={classNames('Overlay', className, {
          'Overlay--absolute': absolute,
        })}
        onClick={() => onClick && onClick()}
      >
        {children}
      </div>
    );
  }
}

export default Overlay;
