import React from 'react';
import { connect } from 'react-redux';
import createFilters from '../../lib/createFilters';
import { Map } from 'immutable';
import { getHotelUrlFromSearch } from '../../lib/getFlightUrl';
import StarIcon from '@content/svg/star.svg';
import './HotelsExplore.less';

const HotelsExplore = ({ search, destination, user, t }) => {
  if (!destination) {
    return null;
  }
  const city = destination.get('city') || destination.get('name', '');
  const createLink = (star = 'any') =>
    getHotelUrlFromSearch(search, destination, user) + `&label=searchsidebar&class=${star}`;

  return (
    <div className="HotelsExplore">
      <div className="HotelsExplore__image" />
      <div className="HotelsExplore__header">
        {t('book_hotels_in %(city)s', {
          city,
        })}
      </div>
      <div className="HotelsExplore__rows">
        {[5, 4, 3].map(star => (
          <a
            className="HotelsExplore__row"
            href={createLink(star)}
            target="_blank"
            key={`rating${star}`}
          >
            <div className="HotelsExplore__row__container">
              <div className="HotelsExplore__row__number">{t('star_hotels', { star })}</div>
              {Array(star)
                .fill()
                .map((v, i) => (
                  <StarIcon key={i} className="HotelsExplore__star" />
                ))}
            </div>
          </a>
        ))}
      </div>
      <a className="HotelsExplore__more" target="_blank" href={createLink()}>
        {t('show_more_hotels')}
      </a>
    </div>
  );
};

export default createFilters(['t'])(
  connect(state => ({
    user: state.get('user'),
    destination: state.getIn(['flightSearch', 'destination'], Map()),
  }))(HotelsExplore)
);
