import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import CookieMessage from 'react-components/src/CookieMessage';
import isBrowser from '@lib/isBrowser';
import { saveLocally, getLocallySaved } from '@lib/localSave';
import { routes, redirectRoutes } from '@routes';
import './App.less';

const App = ({ t, noCookieRoutes }) => {
  let showCookieMessage = true;

  if (isBrowser()) {
    noCookieRoutes.forEach((route) => {
      if (location.href.includes(route)) {
        showCookieMessage = false;
      }
    });
  }

  return (
    <div>
      {showCookieMessage && (
        <CookieMessage t={t} saveLocally={saveLocally} getLocallySaved={getLocallySaved} />
      )}
      <Switch>
        {redirectRoutes.map((route, i) => (
          <Redirect key={i} {...route} />
        ))}
        {routes.map((route, i) => {
          if (route.render && route.render.render) {
            return <Route key={i} render={route.render.render} />;
          }

          return <Route key={i} {...route} />;
        })}
      </Switch>
    </div>
  );
};

App.propTypes = {
  t: PropTypes.func.isRequired,
  noCookieRoutes: PropTypes.array.isRequired,
};

export default App;
