/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');
const _isEmpty = require('lodash/isEmpty');
const time = require('./time');
const request = require('./request');

/**
 * generates a new Calendar object for the given airport codes which
 * can tell us flight availability for any dates
 * @param {string} origin Comma separated origin airport codes
 * @param {string} destination omma separated destination airport codes
 * @param {{success: Funtion, error: Function, apiVersion: number}} [options]
 * success Success callback. Will be passed a {Calendar} instance
 * error Error callback if request is unsuccessful.
 * Example:
 *  calendar.get('LHR', 'JFK', {
 *    success: function(calendar) {
 *      // do something with calendar, or save to outer scope etc.
 *      this.calendar = calendar
 *    }.bind(this)
 *  });
 * @returns {Promise}
 */
const get = function (origin, destination, options) {
  options = _extend({ success() {}, error() {} }, options);

  if (_isEmpty(origin) || _isEmpty(destination)) {
    options.success(new Calendar());
    return;
  }

  return request.get('days', {
    from: origin,
    to: destination,
    apiVersion: options.apiVersion || 3,
    success(data) {
      return options.success(new Calendar(data));
    },
    error: options.error,
  });
};

// Calendar class that can tell us availability of flight dates
class Calendar {
  constructor(data) {
    if (data) {
      ({ i: this.i, o: this.o, n: this.n, tags: this.tags } = data);
      this.baseDate = time.parseDate(data.base_date);
    }
  }

  /**
   *
   * @returns {boolean} If this has valid data to operate on. If this is false, all availability queries will report
   * direct flights available for all dates
   */
  isValid() {
    return this.baseDate != null;
  }

  // @return [Object] json serializeable datastructure that can be passed to the constructor to re-create the calendar object
  serialize() {
    if (this.isValid()) {
      return {
        i: this.i,
        o: this.o,
        n: this.n,
        base_date: time.strftime(time.dateFormat, this.baseDate),
      };
    }
  }

  /**
   *
   * @param {Date} date UTC date to check for outbound availability
   */
  getOutboundAvailability(date) {
    if (!this.isValid()) {
      return this._legsToName(1);
    }
    const n = this.o[time.daysApart(this.baseDate, date)];
    if (n) {
      return this._legsToName(n);
    }
  }

  /**
   *
   * @param {Date} date UTC date to check for homebound availability
   * @param {Date} [outDate] Optional. UTC date of outbound flight. Availability of homebound flights can vary based on outbound date
   */
  getHomeboundAvailability(date, outDate) {
    if (!this.isValid()) {
      return this._legsToName(1);
    }
    const nl_i = this.i[time.daysApart(this.baseDate, date)];
    const nl_o = outDate && this.o[time.daysApart(this.baseDate, outDate)];
    if ((!outDate && nl_i) || (outDate && nl_o)) {
      return this._legsToName(nl_i);
    }
  }

  /**
   *
   * @param {Number} n number of legs for a given day
   * @returns {"direct" | "indirect" | undefined} "direct" if direct flights are available.
   * "indirect" if only flights are available with 1 or more stops
   * or undefined if no flights are available on that day
   */
  _legsToName(n) {
    if (n === 1) {
      return 'direct';
    }
    if (n > 1) {
      return 'indirect';
    }
    return undefined;
  }
}

module.exports = { get, Calendar };
