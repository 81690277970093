import React, { Component } from 'react';
import createFilters from '@lib/createFilters';
import GoContent from '@components/go/GoContent';
import SearchForm from '@components/frontpage/SearchForm';
import DohopHead from '@components/DohopHead';
import Constraint from '@components/Constraint';
import Section from '@components/Section';
import Footer from '@components/Footer';
import LandingHeader from './LandingHeader';
import './City.less';

class City extends Component {
  render() {
    const { t, formatCurrency } = this.props.filters;
    const {
      results,
      flightSearchForm,
      hotels,
      match: { params },
    } = this.props;
    const city = flightSearchForm.getIn(['destination', 'name']);
    const titleTag = t('great_fares_to %(destination)s', {
      destination: city ? city : params.destination,
    });

    return (
      <div className="City PageRouteAnimation__fadeIn">
        <DohopHead title={titleTag} canonical filters={this.props.filters} />
        <Section header>
          <Constraint laptop front>
            <LandingHeader
              params={params}
              price={results.getIn([0, 'price'])}
              formatCurrency={formatCurrency}
              flightSearchForm={flightSearchForm}
              t={t}
            />
            <SearchForm showCompare autoFocusNext initialOpen="departureDate" />
          </Constraint>
        </Section>
        <Section content>
          <div className="City__promoBanner">{t('search_trusted')}</div>
          <div className="City__outerContainer">
            <div className="City__innerContainer">
              {((results && results.size) || (hotels && hotels.get('count') > 0)) && (
                <div>
                  <div className="City__flexible">
                    <div className="City__flexibleHeader">{t('landing_flexible_subtitle')}</div>
                  </div>
                  <div className="City__go">
                    <GoContent init={14} />
                  </div>
                </div>
              )}
              {city && (
                <div>
                  <div className="City__information">
                    <div className="City__flightHotelInfo">
                      {t('landing_information_header', {
                        city,
                      })}
                    </div>
                  </div>
                  <div className="City__tabs">
                    <a className="City__box City__box--left" href="/hotels" target="_blank">
                      <div className="City__boxContent">
                        {t('landing_hotel %(city)s', {
                          city,
                        })}
                        <div className="City__boxIcon City__boxIcon--hotel" />
                      </div>
                    </a>
                    <a className="City__box City__box--right" href="/cars">
                      <div className="City__boxContent">
                        {t('landing_car %(city)s', {
                          city,
                        })}
                        <div className="City__boxIcon City__boxIcon--car" />
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Constraint wide>
            <Footer />
          </Constraint>
        </Section>
      </div>
    );
  }
}

export default createFilters('filters')(City);
