import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Results from './Results';
import ClickOutside from '../ClickOutside';
import connectAutocomplete from './ConnectAutocomplete';
import Location from '@content/svg/location.svg';
import SearchInput from '../frontpage/SearchForm/SearchInput';

class Autocomplete extends Component {
  inputRef = React.createRef();

  formatValue(value) {
    const {
      autocomplete: { shouldClearInput },
    } = this.props;

    return shouldClearInput ? '' : value;
  }

  render() {
    const {
      t,
      id,
      placeholder,
      label,
      showLabel,
      error,
      shouldPopOpen,
      autocomplete,
      autoFocus,
      iconClassName,
      inputClassName,
      autocomplete: { onClose, onFocus, onBlur, onKeyDown, onChange, value, hasFocus },
    } = this.props;

    return (
      <ClickOutside event="mousedown" onClickOutside={onClose}>
        <div>
          <SearchInput
            id={id}
            ref={this.inputRef}
            label={label}
            showLabel={showLabel}
            icon={cn => <Location className={cn} />}
            value={this.formatValue(value)}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onMouseUp={this.onMouseUp}
            autoComplete="off"
            placeholder={placeholder}
            hasFocus={hasFocus}
            autoFocus={autoFocus}
            shouldPopOpen={shouldPopOpen}
            iconClassName={iconClassName}
            inputClassName={inputClassName}
            error={error}
          >
            <Results t={t} {...autocomplete} />
          </SearchInput>
        </div>
      </ClickOutside>
    );
  }
}

Autocomplete.defaultProps = {
  id: null,
  placeholder: '',
  label: '',
  showLabel: false,
  shouldPopOpen: false,
  autoFocus: false,
  iconClassName: '',
  inputClassName: '',
  onFocus: null,
  error: false,
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  shouldPopOpen: PropTypes.bool,
  autoFocus: PropTypes.bool,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  autocomplete: PropTypes.object.isRequired,
  source: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  error: PropTypes.bool,
};

Autocomplete = connectAutocomplete()(Autocomplete);

export default Autocomplete;
