import moment from 'moment';
import { formatFlexibleMonths } from '../components/DatePicker/Utils';

/**
 * Formats the recent searches flight search list to standard form used for rendering the results.
 * @param {object <Immutable.List>} recentSearches - recent searches from redux state.
 */
export function formatRecentFlightSearches(recentSearches, t, formatDate) {
  return recentSearches.length > 0
    ? recentSearches.map(search => {
        const origin = search.getIn(['origin', 'name']);
        const destination = search.getIn(['destination', 'name']);
        const departure = moment(search.get('departure')).format('Do MMM');
        const arrival = moment(search.get('arrival')).format('Do MMM');
        const passengerCount = search.get('adults') + search.get('childrenAges').size;
        const passengerString = passengerCount === 1 ? t('passenger') : t('passengers');
        const isOneWay = search.get('isOneWay');
        const isDateFlexible = search.get('isDateFlexible');
        const flexibleDates = search.get('flexibleDates');
        const specificDatesString = isOneWay ? departure : `${departure} - ${arrival}`;
        const flexibleDatesString = formatFlexibleMonths(flexibleDates, t, formatDate);

        return {
          item: search,
          title: `${origin} - ${destination}`,
          subTitle: `${
            isDateFlexible ? flexibleDatesString : specificDatesString
          }, ${passengerCount} ${passengerString.toLowerCase()}`,
        };
      })
    : [];
}
