import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MobileView from '@components/MobileView';
import GoFilters from '@components/go/GoFilters';
import ButtonRounded from '@components/ButtonRounded';
import Sort from '@content/svg/sort_green.svg';
import './MobileGoFilters.less';

class MobileGoFilters extends Component {
  state = { open: false };

  onCloseHandler = () => {
    this.setState({ open: false });
  };

  onOpenHandler = () => {
    this.setState({ open: true });
  };

  render() {
    const { t, showButtonText } = this.props;
    const { open } = this.state;

    return (
      <div className="MobileGoFilters">
        <ButtonRounded
          color="ocean"
          onClick={this.onOpenHandler}
          className="MobileGoFilters__button MobileGoFilters__button"
        >
          <span
            className={classNames('MobileGoFilters__buttonInnerContainer', {
              'MobileGoFilters__buttonText--show': showButtonText,
            })}
          >
            <Sort className="MobileGoFilters__filterIcon" />
            {t('Filters')}
          </span>
        </ButtonRounded>
        {open && (
          <MobileView onClose={this.onCloseHandler} title={t('Filters')} showConfirm>
            <GoFilters t={t} />
          </MobileView>
        )}
      </div>
    );
  }
}

MobileGoFilters.propTypes = {
  showButtonText: true,
};

MobileGoFilters.propTypes = {
  t: PropTypes.func.isRequired,
  showButtonText: PropTypes.bool,
};

export default MobileGoFilters;
