import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { trackFlightpageContent } from '@lib/tracking';
import GoImageLayer from '../go/GoImageLayer';
import { Link } from 'react-router-dom';
import createFilters from '../../lib/createFilters';
import './Inspired.less';

class Inspired extends Component {
  renderCity(city, i) {
    const { trackingId } = this.props;
    const key = `${city.get('iata')}-${i}`;

    return (
      <div className="Inspired__container" key={key}>
        <a
          href={city.get('url')}
          onClick={() => trackFlightpageContent(trackingId)}
          // To also track when user right clicks on it.
          onMouseDown={() => trackFlightpageContent(trackingId)}
        >
          <GoImageLayer city={city} lazyLoad hotelTheme />
        </a>
      </div>
    );
  }

  render() {
    const { title, subtitle, items, hotels, maxSize, t } = this.props;
    return (
      Boolean(items && items.slice(0, maxSize).size > 0) && (
        <div
          className={classNames('Inspired', {
            'Inspired--oddSize': items.slice(0, maxSize).size >= 5 && items.size % 2,
            'Inspired--big': items.slice(0, maxSize).size <= 2,
            'Inspired--hotels': hotels,
          })}
        >
          <div className="Inspired__header">
            <div className="Inspired__headerContent">
              <h2 className="Inspired__title">{title}</h2>
              <div className="Inspired__subtitle">{subtitle}</div>
            </div>
          </div>
          <div className="Inspired__cities">
            {items
              .slice(0, maxSize)
              .map((item, i) => this.renderCity(item, i))
              .toArray()}
          </div>
          {!hotels && (
            <Link to="/go/anywhere" className="Inspired__anywhere">
              {t('search_anywhere')}
            </Link>
          )}
        </div>
      )
    );
  }
}
Inspired = createFilters(['t'])(Inspired);

Inspired.defaultProps = {
  maxSize: 5,
};

Inspired.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.object.isRequired,
};

export default Inspired;
