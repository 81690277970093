import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import classNames from 'classnames';
import { fetchItineraryURL } from '../../../../actions/flightSearchActions';
import Link from '@content/icons/link.svg';
import Facebook from '@content/icons/facebook_plain.svg';
import Twitter from '@content/icons/twitter_plain.svg';
import './ShareItinerary.less';

class ShareItinerary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showContainer: false };

    this.shareOnFacebook = this.shareOnFacebook.bind(this);
    this.shareOnTwitter = this.shareOnTwitter.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.onToggleContainer = this.onToggleContainer.bind(this);
  }

  /**
   * Returns information about this itinerary, needed for the sharing feature.
   */
  getShareInfo() {
    const { t, form } = this.props;

    const originCityName = form.getIn(['origin', 'name']);
    const originCountry = form.getIn(['origin', 'country']);

    const destinationCityName = form.getIn(['destination', 'name']);
    const destinationCountry = form.getIn(['destination', 'country']);

    const description =
      originCityName +
      ', ' +
      originCountry +
      ' \u2192 ' +
      destinationCityName +
      ', ' +
      destinationCountry;

    const caption = t('Dohop is a search engine');
    const hashtags =
      destinationCityName.replace(' ', '') + ', ' + destinationCountry.replace(' ', '');

    return { description, caption, hashtags };
  }

  /**
   * Returns the url for sharing this single itinerary.
   */
  getUrl() {
    return encodeURIComponent(window.location.href.replace('localhost:4000', 'dohop.com'));
  }

  /**
   * Opens a facebook share window.
   */
  shareOnFacebook() {
    const info = this.getShareInfo();
    const url =
      'http://www.facebook.com/dialog/share?app_id=221202224687280' +
      `&display=popup&href=${this.getUrl()}&redirect_uri=${this.getUrl()}` +
      `&description=${info.description}&caption=${info.caption}`;
    window.open(url, '_blank', 'width=555, height=624');
  }

  /**
   * Opens a twitter share window.
   */
  shareOnTwitter() {
    const info = this.getShareInfo();

    const baseLink = 'https://twitter.com/intent/tweet?';
    const via = 'dohoptravel';
    const text = encodeURIComponent(info.description);

    const twitterLink =
      baseLink + 'url=' + info.url + '&via=' + via + '&text=' + text + '&hashtags=' + info.hashtags;

    window.open(twitterLink);
  }

  /**
   * Triggered when sharing container is clicked.
   */
  onToggleContainer() {
    const { onShare, itinerary } = this.props;
    this.setState({ showContainer: true });
    onShare(itinerary);
  }

  /**
   * Copies the input string to clipboard.
   * @param {string} string - the string to copy.
   */
  copyToClipboard(string) {
    const { urlData } = this.props;
    let clipboardData;
    document.addEventListener('copy', e => {
      e.preventDefault();
      e.clipboardData.setData('text', string);
      clipboardData = e.clipboardData.getData('text');
      document.removeEventListener('copy', e);
    });
    const success = document.execCommand('copy');

    // Display a message to show the user the sting have been copied.
    if (success && clipboardData === urlData.get('url', '')) {
      this.setState({ showCopy: true });
      setTimeout(() => {
        this.setState({ showCopy: false });
      }, 1500);
    }
  }

  render() {
    const { t, urlData } = this.props;
    const { showContainer, showCopy } = this.state;

    return (
      <div className="ShareItinerary">
        <div
          className={classNames('ShareItinerary__copyMessage', {
            'ShareItinerary__copyMessage--enabled': showCopy,
          })}
        >
          {t('copied')}
        </div>
        {showContainer ? (
          <div className="ShareItinerary__toggleContainer">
            <a
              className="ShareItinerary__icon"
              onClick={() => this.copyToClipboard(urlData.get('url', ''))}
            >
              <Link />
            </a>
            <a className="ShareItinerary__icon" onClick={this.shareOnFacebook}>
              <Facebook />
            </a>
            <a className="ShareItinerary__icon" onClick={this.shareOnTwitter}>
              <Twitter />
            </a>
          </div>
        ) : (
          <a onClick={this.onToggleContainer} className="ShareItinerary__share">
            {t('sharetrip')}
          </a>
        )}
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    form: state.get('flightSearchForm'),
    urlData: state.getIn(
      ['flightSearch', 'itineraryURLs', props.itinerary.getKey()],
      Immutable.Map()
    ),
  }),
  dispatch => ({
    onShare: itinerary => dispatch(fetchItineraryURL(itinerary)),
  })
)(ShareItinerary);
