import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import fromPairs from 'lodash/fromPairs';
import { setVendorsFilters as setVendorsFiltersAction } from '@actions/filtersActions';
import FilterBox from './FilterBox';
import Checkbox from '../form/Checkbox';
import Tooltip from '../Tooltip';
import { trackFilterCheckbox } from '../../lib/tracking';
import './FilterCheckboxList.less';

const VENDORS = 'vendors';

class FilterCheckboxList extends React.Component {
  onToggle(code) {
    const { filters, filterName, setVendorsFilters, vendorFilters } = this.props;
    const newFilterState = !this.getFilter().allows(code);
    // Track filtering
    trackFilterCheckbox(filterName);

    const filter = {
      [code]: newFilterState,
    };

    // Used for picking out vendors we want to display on the transfer step.
    if (filterName === VENDORS) {
      vendorFilters[code] = newFilterState;
      setVendorsFilters(vendorFilters);
    }

    // This keeps track of the filtering of the results.
    filters.set(filterName, filter);
  }

  onSetAll({ allow, override = {} }) {
    const { options } = this.props;
    this.filterMany(options, allow, override);
  }

  onToggleAll = () => {
    const { filterName } = this.props;
    const isActive = this.getFilter().isActive();
    // Track filtering
    trackFilterCheckbox(filterName);

    this.onSetAll({ allow: isActive });
  };

  onSetOnly(code) {
    const { filterName } = this.props;
    trackFilterCheckbox(filterName);
    this.onSetAll({ allow: false, override: { [code]: true } });
  }

  componentDidUpdate(prevProps, prevState) {
    const filter = this.getFilter();
    const prevOptions = prevProps.options;
    const { options, setNewResultsPopup } = this.props;
    // If more options are available in newProps
    // than prevProps AND we have elements filtered
    // we apply filters on new items
    if (filter.isActive() && prevOptions.length < options.length) {
      const newlyArrivedOptions = options.filter(
        o => !Boolean(prevOptions.find(prO => prO.code === o.code))
      );
      this.filterMany(newlyArrivedOptions, false);
      // Notify user he has more filters available
      setNewResultsPopup(true);
    }
  }

  /**
   * Changes filter status of array of
   * options to the boolean value showResults.
   * Override if we want to exclude specific value.
   *
   * @param {Array} options - [{name:"", code: ""}]
   * @param {Boolean} showResults
   * @param {Object} override
   * @memberof FilterCheckboxList
   */
  filterMany(options, showResults, override = {}) {
    const { filters, filterName, setVendorsFilters } = this.props;
    let filterState = fromPairs(options.map(o => [o.code, showResults]));
    Object.assign(filterState, override || {});

    // Used for picking out vendors we want to display on the transfer step.
    if (filterName === VENDORS) {
      setVendorsFilters(filterState);
    }

    // This keeps track of the filtering of the results.
    filters.set(filterName, filterState);
  }

  getFilter() {
    const { filters, filterName } = this.props;
    return filters.getFilter(filterName);
  }

  render() {
    const { title, t, options, showOnly, mobile } = this.props;
    const filter = this.getFilter();
    const isFilterOn = Object.values(filter._state).length > 0;

    return (
      <FilterBox title={title} initialOpen={false} isFilterOn={mobile && isFilterOn}>
        <div className="FilterCheckboxList FilterCheckboxList--animation">
          <ul className="FilterCheckboxList__rows">
            {options.length > 0 && (
              <li className="FilterCheckboxList__row">
                <Checkbox checked={!filter.isActive()} onChange={this.onToggleAll}>
                  {t('all_select_deselect')}
                </Checkbox>
              </li>
            )}
            {options.map(({ name, code }) => (
              <li key={code} className="FilterCheckboxList__row FilterCheckboxList--animation">
                <Checkbox checked={filter.allows(code)} onChange={this.onToggle.bind(this, code)}>
                  <Tooltip title={name}>{name}</Tooltip>
                </Checkbox>
                {showOnly && (
                  <div
                    className="FilterCheckboxList__only"
                    onClick={this.onSetOnly.bind(this, code)}
                  >
                    {t('only')}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </FilterBox>
    );
  }
}

FilterCheckboxList.propTypes = {
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  vendorFilters: PropTypes.object.isRequired,
};

FilterCheckboxList.defaultProps = {
  showOnly: false,
};

/**
 * Maps state to components props
 *
 * @param {Object} state - Redux state
 * @returns {Object}
 */
function mapStateToProps(state) {
  return {
    vendorFilters: state.getIn(['filters', 'vendors']),
  };
}

/**
 * Maps dispatch to components props
 *
 * @param {Object} dispatch - Redux dispatch medhod
 * @returns {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setVendorsFilters: filterState => dispatch(setVendorsFiltersAction(filterState)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCheckboxList);
