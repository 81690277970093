import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createFilters from '../../lib/createFilters';
import CollapseWithAnimation from '../CollapseWithAnimation';
import Search from '@content/svg/search.svg';
import ArrowDown from '@content/svg/arrow_down.svg';
import './Disclaimer.less';

class Disclaimer extends Component {
  state = { isOpen: false };

  onClickHeader = () => {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen };
    });
  };

  /**
   * Renders the header of the component.
   * The header is clickable, it opens and closes the disclaimer text when clicked.
   */
  renderHeader() {
    const { t } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="Disclaimer__title">
        <Search className="Disclaimer__icon" />
        {t('price_advisory')}
        <ArrowDown
          className={classNames('Disclaimer__arrow', {
            'Disclaimer__arrow--open': isOpen,
          })}
        />
      </div>
    );
  }

  /**
   * Renders the disclaimer text for this itinerary.
   */
  renderText() {
    const { itinerary, fare, t } = this.props;

    return (
      <div className="Disclaimer__text">
        <Fragment>
          <div
            className="Disclaimer__body"
            dangerouslySetInnerHTML={{ __html: t('pricedisclaimer_transferstep') }}
          />
          {itinerary.isSelfConnect(fare) && (
            <div
              className="Disclaimer__body"
              dangerouslySetInnerHTML={{ __html: t('ConnectedFlightTips') }}
            />
          )}
        </Fragment>
      </div>
    );
  }

  render() {
    const { itinerary, search, t } = this.props;
    const { isOpen } = this.state;
    const fare = itinerary.getFares().getBestFare();

    // If the fare is not completed and nor it is still polling we weren't able to
    // price this itinerary.
    if (!fare.isComplete()) {
      return search && search.isPolling() ? null : (
        <div>
          <div className="Disclaimer__subTitle">{t('noprice_title')}</div>
          <div className="Disclaimer__body">{t('noprice_description')}</div>
        </div>
      );
    }

    return (
      <CollapseWithAnimation
        renderHeader={() => this.renderHeader()}
        isCollapsed={!isOpen}
        onClick={this.onClickHeader}
        className="Disclaimer"
      >
        {this.renderText()}
      </CollapseWithAnimation>
    );
  }
}

Disclaimer.propTypes = {
  itinerary: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

Disclaimer = createFilters(['t'])(Disclaimer);

export default Disclaimer;
