import { isObjectEmpty } from 'shared/utils/jsTypes';

export default function translate({ translations }, transName, params = {}) {
  let trans = transName;
  if (translations && translations.has(transName)) trans = translations.get(transName);

  // Checks if params is an empty object.
  // This check is made so we won't run the unnecessary
  // logic in the return statement below
  if (isObjectEmpty(params)) {
    return trans;
  }

  return trans
    .split(/%\((.+?)\)s/)
    .map((s, i) => {
      if (i % 2 === 0) return s;
      return params[s] || '';
    })
    .join('');
}
