import time from 'dohop-client-api/src/time';
import { decodeURLQueryData } from 'shared/utils/transformURLQueryData';

function formatDate(date) {
  return time.strftime('%Y-%m-%d', date);
}

function formatDateTime(date) {
  return time.strftime('%Y-%m-%dT%H:%M:%S', date);
}

export function trackVirtualPageView() {
  dataLayer.push({
    event: 'virtualPageView',
  });
}

export function trackTransfer(itinerary, multiFare, fare, vendorFare) {
  return (dispatch, getState) => {
    let state = getState();
    let search = state.getIn(['flightSearch', 'search']);

    dataLayer.push({
      event: 'transfer',
      selfConnect: Boolean(multiFare) && itinerary.isSelfConnect(multiFare),
      multiTicket: Boolean(multiFare),
      searchOrigin: search.getMeta().getFrom().join(','),
      searchDestination: search.getMeta().getTo().join(','),
      origin: itinerary.getRoutes()[0].getOrigin().getCode(),
      destination: itinerary.getRoutes()[0].getDestination().getCode(),
      oneWay: search.getMeta().isOneWay(),
      departureDate: formatDate(search.getMeta().getOutboundDate()),
      airlines: itinerary
        .getAirlines()
        .map((airline) => airline.getName())
        .join(','),
      price: vendorFare.getTotal(),
      currency: 'EUR',
      vendor: vendorFare.getVendor().getName(),
      numberOfPassengers: search.getMeta().getNumPassengers(),
      adults: search.getMeta().getNumberOfAdults(),
      children: search.getMeta().getYoungstersAges().join(','),
    });
  };
}

export function trackFlightSearch() {
  return (dispatch, getState) => {
    let form = getState().get('flightSearchForm');

    dataLayer.push({
      event: 'flightSearch',
      oneWay: form.get('isOneWay'),
      origin: form.getIn(['origin', 'iata']),
      destination: form.getIn(['destination', 'iata']),
      departureDate: formatDateTime(form.get('departureDate')),
      returnDate: form.get('isOneWay') ? null : formatDateTime(form.get('returnDate')),
      numberOfPassengers: form.get('adults') + form.get('childrenAges').size,
    });
  };
}

export function trackCarSearch() {
  return (dispatch, getState) => {
    let form = getState().getIn(['cars', 'form']);

    dataLayer.push({
      event: 'carSearch',
      differentDropoff: form.get('differentDropoff'),
      pickupLocation: form.getIn(['pickup', 'name']),
      pickupDate: formatDateTime(
        new Date(form.get('pickupDate').getTime() + form.get('pickupTime'))
      ),
      dropoffDate: formatDateTime(
        new Date(form.get('dropoffDate').getTime() + form.get('dropoffTime'))
      ),
      dropoffLocation:
        form.get('differentDropoff') && form.get('dropoff')
          ? form.getIn(['dropoff', 'name'])
          : form.getIn(['pickup', 'name']),
    });
  };
}

export function trackHotelSearch() {
  return (dispatch, getState) => {
    let form = getState().getIn(['hotel', 'form']);

    dataLayer.push({
      event: 'hotelSearch',
      flexible: form.get('flexible'),
      destination: form.getIn(['destination', 'label']),
      checkin: formatDate(form.get('checkinDate')),
      checkout: formatDate(form.get('checkoutDate')),
      numberOfPeople: form.get('adults') + form.get('childrenAges').size,
    });
  };
}

export function trackFlexibleOpened() {
  dataLayer.push({
    event: 'flexibleOpened',
  });
}

export function trackFlexibleSearch() {
  dataLayer.push({
    event: 'flexibleSearch',
  });
}

export function trackCloseHotelAd() {
  dataLayer.push({
    event: 'closeHotelAd',
  });
}

export function trackEmptyGoResults() {
  return (dispatch, getState) => {
    if (!process.env.IS_BROWSER) return Promise.resolve();
    const go = getState().get('go');
    dataLayer.push({
      event: 'emptygoresult',
      origin: go.getIn(['origin', 'code']),
      destination: go.getIn(['destination', 'code'], 'anywhere'),
      d1: go.get('d1').format('YYYY-MM-DD'),
      d1_: go.get('d1_').format('YYYY-MM-DD'),
    });
  };
}

export function trackEmailRegistration(email) {
  dataLayer.push({
    event: 'signup',
    email,
  });
}

export function trackFilterCheckbox(checkboxName) {
  dataLayer.push({
    event: 'filtercheckbox',
    checkboxName,
  });
}

// Search expired after 60 minutes
export function trackSearchExpired() {
  dataLayer.push({
    event: 'searchExpired',
  });
}

// User refreshes prices after search expiring
export function trackSearchRefreshed() {
  dataLayer.push({
    event: 'searchRefreshed',
  });
}

export function trackNoSearchResults() {
  const queryParams = decodeURLQueryData(location.search);
  dataLayer.push({
    event: 'noSearchResults',
    vendorFilter: queryParams.vendors || 'None',
    url: location.href,
  });
}

export function trackGoPrice(url, price) {
  sessionStorage.setItem(url, price);
}

export function trackGoPriceDiff(price) {
  if (location.pathname in sessionStorage) {
    const goPrice = Number(sessionStorage[location.pathname]);
    let percDiff = (Math.abs(Number(price) - goPrice) / goPrice) * 100;
    const gap = 5;
    percDiff -= percDiff % gap;
    const operator = goPrice <= price ? '+' : '-';
    dataLayer.push({
      event: 'goPriceDifference',
      percentDifference: `${operator} ${percDiff}-${percDiff + gap}%`,
      search: location.pathname,
    });
    sessionStorage.removeItem(location.pathname);
  }
}

export function trackUserLanguage(user) {
  if (user && user.get('language')) {
    dataLayer.push({
      event: 'language',
      language: user.get('language'),
    });
  }
}

/**
 * When user clicks on recent search
 */
export function trackRecentSearch() {
  dataLayer.push({
    event: 'recentSearch',
  });
}

export function trackFlightpageContent(flightPageContentType) {
  dataLayer.push({
    event: 'flightPageContent',
    flightPageContentType,
  });
}

/**
 * When user clicks on the plant a tree button on the transfer page.
 * @param {Number} treeCnt - Number of trees
 */
export function trackTreePlantingTransferStep(treeCnt) {
  dataLayer.push({
    event: 'treePlantingTransferStep',
    treeCnt,
  });
}

/**
 * When the package deal banner is loaded.
 * @param {string} origin - Origin
 * @param {string} destination - Destination
 */
export function trackPackageDealResultsBanner({ origin, destination }) {
  dataLayer.push({
    event: 'packageDealsResultsBannerLoaded',
    origin,
    destination,
  });
}

/**
 * When user clicks on a package in the banner between itineraries.
 * @param {string} origin - Origin
 * @param {string} destination - Destination
 */
export function trackPackageDealTransferFromResultsBanner({ origin, destination }) {
  dataLayer.push({
    event: 'packageDealsResultsBannerTransfer',
    origin,
    destination,
  });
}
