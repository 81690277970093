import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _once from 'lodash/once';
import './MatchMedia.less';

export const matchMedia = process.env.IS_BROWSER ? window.matchMedia : () => {};

// these must match page-width definitions in variables.less
export const queries = {
  zero: matchMedia('(min-width: 0px)'),
  mobile: matchMedia('(min-width: 480px)'),
  ipad: matchMedia('(min-width: 768px)'),
  desktop: matchMedia('(min-width: 960px)'),
  'ipad-landscape': matchMedia('(min-width: 1024px)'),
  laptop: matchMedia('(min-width: 1280px)'),
  max: matchMedia('(min-width: 1420px)'),
};

class MatchMedia extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
    this.checkVisibility = this.checkVisibility.bind(this);
    this.initSizeCheck = _once(() => {
      window.addEventListener('resize', this.checkVisibility);
      this.checkVisibility();
    });
  }

  componentDidMount() {
    this.initSizeCheck();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkVisibility);
  }

  checkVisibility() {
    const { min, max } = this.props;
    const { visible } = this.state;
    const isVisible = (!min || queries[min].matches) && (!max || !queries[max].matches);
    if (isVisible !== visible) this.setState({ visible: isVisible });
  }

  render() {
    const { min, max, children, className, style } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return false;
    }

    const cN = classNames(
      'MatchMedia',
      {
        [`MatchMedia--minSize_${min}`]: min,
        [`MatchMedia--maxSize_${max}`]: max,
      },
      className
    );

    return (
      <div className={cN} style={style}>
        {React.Children.toArray(children)}
      </div>
    );
  }
}

MatchMedia.defaultProps = {
  min: 'zero',
  max: '',
  className: '',
};

MatchMedia.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  className: PropTypes.string,
};

export default MatchMedia;
