import React from 'react';
import _omit from 'lodash/omit';
import classNames from 'classnames';
import './RadioButton.less';

const RadioButton = props => (
  <label
    className={classNames(
      'RadioButton',
      { 'RadioButton--disabled': props.disabled },
      props.className
    )}
  >
    <input {..._omit(props, 'className', 'children')} type="radio" className="RadioButton__input" />
    <span className="RadioButton__radio" />
    <span className="RadioButton__content">{props.children}</span>
  </label>
);

export default RadioButton;
