import Immutable from 'immutable';
import { FETCH_SUCCESS } from '../actions/experimentActions';

const DEFAULT_STATE = Immutable.fromJS({
  experiments: {},
});

export default function experimentReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.mergeIn(['experiments'], action.experiments);
    default:
      return state;
  }
}
