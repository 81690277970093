import moment from 'moment';
import { Map, List } from 'immutable';
import compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import time from 'dohop-client-api/src/time';
import picker from 'dohop-client-api/src/picker';
import { months } from '../components/DatePicker/Utils';
import {
  getOutboundArrival,
  getHomeboundDeparture,
  getItineraryDestination,
} from './itineraryInfo';
import processException from './processException';

const DESTINATION = 'destination';

export default function getFlightUrl(city, adults = 1, childrenAges = List()) {
  let segments = [
    city.get('from').join('-'),
    city.get('to').join('-'),
    time.strftime('%Y-%m-%d', city.get('departureDate')),
  ];
  if (city.get('returnDate')) segments.push(time.strftime('%Y-%m-%d', city.get('returnDate')));
  if (adults > 1) segments.push('adults-' + adults);
  if (childrenAges.size) segments.push('children-' + childrenAges.join('-'));
  return '/flights/' + segments.join('/') + '/';
}

/**
 * Gets airport information based on destination airport codes and language.
 * Airports from large area have many codes separated by comma (e.g. Milan all - 'MXP,LIN,BGY,PM').
 * However, picker.autocomplete function needs a strict code (e.g. 'MXP'):
 *    picker.autocomplete('LIN') --> ['MXP,LIN,BGY,PM', 'LIN']
 *    picker.autocomplete('MXP,LIN,BGY,PM') --> []
 * hence singleCode and singleOrManyCodes.
 *
 * @param {String} singleCode
 * @param {String} singleOrManyCodes
 * @param {String} lang
 * @returns array with airport objects
 * @memberof SearchForm
 */
export async function getAirport(singleCode, singleOrManyCodes, lang) {
  return new Promise((resolve, reject) => {
    picker.autocomplete(singleCode, {
      language: lang,
      success: (airports, countries) => {
        resolve(airports.filter((airport) => airport.code === singleOrManyCodes)[0]);
      },
      error: reject,
    });
  });
}

/**
 * Generates url based on information in the search form state object.
 * @param {object<Immutable.map>} form The form state object.
 */
export function getFlightUrlFromSearchForm(form) {
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    isOneWay,
    adults,
    childrenAges,
    isDateFlexible,
    flexibleDate,
  } = form.toObject();

  const isFlexible = destination.get('flexible') || isDateFlexible;
  const isCountry = destination.get('isCountry');

  const formatFlexibleDate = (d) => months[d.month()] + d.format('YY');

  const queryParams = getQueryParamsFromSearchForm(form);

  return isFlexible || isCountry
    ? '/go/' +
        compact([
          origin.get('code').replace(/,/g, '-'),
          destination.get('code', 'anywhere').replace(/,/g, '-'),
          isDateFlexible &&
            [flexibleDate.get('d1'), flexibleDate.get('d1_')].map(formatFlexibleDate).join('-'),
          !isDateFlexible && time.strftime('%Y-%m-%d', departureDate),
          !isDateFlexible && !isOneWay && time.strftime('%Y-%m-%d', returnDate),
        ]).join('/') +
        queryParams
    : '/flights/' +
        compact([
          origin.get('code').replace(/,/g, '-'),
          destination.get('code').replace(/,/g, '-'),
          time.strftime('%Y-%m-%d', departureDate),
          !isOneWay && time.strftime('%Y-%m-%d', returnDate),
          `adults-${adults}`,
          childrenAges.size > 0 && `children-${childrenAges.join('-')}`,
        ]).join('/') +
        queryParams;
}

/**
 * Gets the query parameters based on the search form.
 * In some cases, changes in search form might need to change the query parameters already in the the url.
 * @param {object<Immutable.map>} form The form state object.
 */
function getQueryParamsFromSearchForm(form) {
  const { destination, isOneWay, isDateFlexible } = form.toObject();
  const isFlexible = destination.get('flexible') || isDateFlexible;
  const defaultParams = window.location.search;

  return isFlexible
    ? getOverwritedQueryParams(defaultParams, `?oneWay=${isOneWay}`)
    : defaultParams;
}

/**
 * Get object with key value pairs of the query parameters.
 * @param {string} params the params on the form ?param1=value1&param2=value2...
 * @returns {object} key value pairs on the form {param1: value1, param2: value2 ...}
 */
export function getQueryParamsAsObject(params) {
  const paramObj = {};

  if (!params) return paramObj;

  const paramsNoQuestionMark = params.replace('?', '');
  const paramsList = paramsNoQuestionMark.split('&');

  paramsList.forEach((param) => {
    const [key, value] = param.split('=');
    paramObj[key] = value;
  });

  return paramObj;
}

/**
 * Get query parameters on the form required in url.
 * @param {object} key value pairs on the form {param1: value1, param2: value2 ...}
 * @returns {string} on the form ?param1=value1&param2=value2...
 */
export function getQueryParamsAsString(paramsObj) {
  if (_isEmpty(paramsObj)) return '';

  const paramsList = [];
  Object.keys(paramsObj).forEach((key) => paramsList.push(`${key}=${paramsObj[key]}`));

  return `?${paramsList.join('&')}`;
}

/**
 * Gets query parameters on the form ?param1=value1&param2=value2...
 * Where values for query parameters in defaultParams are overwrited with values in dominatedParams.
 * So if defaultParams is ?param1=value1&param2=defaultValue2
 * and dominatedParams is ?param2=dominatedValue2
 * it returns ?param1=value1&param2=dominatedValue2
 * @param {string} defaultParams
 * @param {string} dominatedParams
 */
export function getOverwritedQueryParams(defaultParams, dominatedParams) {
  const paramsObj = getQueryParamsAsObject(defaultParams);
  const dominatedParamsObj = getQueryParamsAsObject(dominatedParams);

  // Overwrite values for parameters that are in both query params.
  Object.keys(paramsObj).map((key) => {
    if (dominatedParamsObj[key]) {
      paramsObj[key] = dominatedParamsObj[key];
    }
  });

  // Add params that are in dominatedParams but not defaultParams.
  Object.keys(dominatedParamsObj).map((key) => {
    if (!paramsObj[key]) {
      paramsObj[key] = dominatedParamsObj[key];
    }
  });

  return getQueryParamsAsString(paramsObj);
}

export function getInspiredUrl(city, stay, days) {
  let segments = [`${city.get('from').join('-')}`, `${city.get('to').join('-')}`];
  let queries = [`stay=${stay}`];
  if (days) queries.push('weekdays=56');
  return '/go/' + segments.join('/') + '?' + queries.join('&');
}

export function getHotelUrl(city, user = Map()) {
  if (city.get('type') === 'theme') {
    return (
      `https://hotel.dohop.com/${city.get('id')}/index.en-gb.html?aid=946321&` +
      'label=gen173nr-1DCAEoggJCAlhYSDNiBW5vcmVmaHCIAQGYAS64AQfIAQzYAQPoAQGoAgM;' +
      'sid=4f52975148bc3de0c5b666f966df38fb;dcid=1'
    );
  }
  return (
    'https://hotel.dohop.com/searchresults.html?' +
    [
      `language=${user.get('language', 'en')}`,
      `selected_currency=${user.get('currency', 'EUR')}`,
      'group_adults=2',
      'group_children=0',
      'no_rooms=1',
      'interval_of_time=any',
      'flex_checkin_year_month=any',
      'aid=946321',
      'dest_type=city',
      `dest_id=${city.get('id')}`,
      'si=ai%2Cco%2Cci%2Cre%2Cdi',
    ].join('&')
  );
}

export function getHotelUrlFromSearch(search, destination, user = Map()) {
  const meta = search.getMeta();
  const checkinDate = moment(meta.d1);
  const checkoutDate = meta.d2 ? moment(meta.d2) : checkinDate.clone().add(1, 'week');
  const city = destination.get('city') || destination.get('name');

  return (
    'https://hotel.dohop.com/searchresults.html?' +
    [
      `lang=${user.get('language', 'en')}`,
      `selected_currency=${user.get('currency', 'EUR')}`,
      `checkin_year_month=${checkinDate.format('YYYY-MM')}`,
      `checkin_monthday=${checkinDate.format('DD')}`,
      `checkout_year_month=${checkoutDate.format('YYYY-MM')}`,
      `checkout_monthday=${checkoutDate.format('DD')}`,
      'flex_checkin_year_month=any',
      'aid=946321',
      `ss=${city}`,
    ].join('&')
  );
}

/**
 * Generates deeplink for car search from itinerary.
 * @param {object} itinerary
 * @param {object <Immutable.map>} user
 */
export function getCarsUrlFromItinerary(itinerary, user) {
  const lang = user.get('language', 'en');
  const isOneWay = itinerary.getRoutes().length === 1;

  // Pickup location
  const destination = getItineraryDestination(itinerary);
  const pickupIATACode = destination.getCode();

  // Dropoff location is always the same as pickup location when generating deeplink from itinerary.
  const returnIATACode = pickupIATACode;

  // Pickup date and time.
  const outboundArrival = getOutboundArrival(itinerary);
  const pickup = moment.utc(outboundArrival).add(1, 'hours');
  const pickupCorrectFormat = pickup.toISOString().split('.')[0];

  // Dropoff date and time.
  let dropoffCorrectFormat = '';
  // Dropoff date and time is set week after the pickup for one way flights.
  if (isOneWay) {
    const dropoff = moment.utc(outboundArrival).add(7, 'days');
    dropoffCorrectFormat = dropoff.toISOString().split('.')[0];
  } else {
    // Otherwise 2 hours before departure of the homebound flight.
    const homeboundDeparture = getHomeboundDeparture(itinerary);
    const dropoff = moment.utc(homeboundDeparture).subtract({ hours: 2 });
    dropoffCorrectFormat = dropoff.toISOString().split('.')[0];
  }

  return (
    `https://rentalcars.dohop.com/${lang}/book?` +
    [
      'age=25',
      'carGroup=0',
      `pickupIATACode=${pickupIATACode}`,
      `returnIATACode=${returnIATACode}`,
      `pickupDateTime=${pickupCorrectFormat}`,
      `returnDateTime=${dropoffCorrectFormat}`,
      `currency=${user.get('currency', 'EUR')}`,
      `residencyId=${user.get('residency', 'UK')}`,
      'clientId=77445', // Our (Dohop) client id stored with cartrawler. Used for tracking.
    ].join('&')
  );
}

/**
 * Creates web_api hotels/comparison url with necessary params
 * @returns {String|null}
 */
export async function getHotelUrlFromForm(form, user) {
  const departureDate = form.get('departureDate');
  const outboundDate = departureDate;
  const checkinMonth = departureDate.getMonth() + 1;
  let returnDate = form.get('returnDate');

  if (!form.get('returnDate')) {
    returnDate = new Date(outboundDate.getTime() + time.week);
  }

  const checkoutMonth = returnDate.getUTCMonth() + 1;
  const destinationCode = form.getIn([DESTINATION, 'code']);
  const destinationCountry = form.getIn([DESTINATION, 'country']);
  const city = form.getIn([DESTINATION, 'city']);

  if (!destinationCode || !destinationCountry) {
    return null;
  }

  let destinationInfo;
  try {
    // Just in case destinationCode consists of many (e.g. Milan all - 'MXP,LIN,BGY,PM')
    const firstDestinationCode = destinationCode.split(',')[0];
    // Results from hotel search are most accurate with english parameters
    destinationInfo = await getAirport(firstDestinationCode, destinationCode, 'en');
  } catch (e) {
    processException(e);
    // Fallback to user input if english version fails.
    destinationInfo = {
      name: form.getIn([DESTINATION, 'name']),
      country: destinationCountry,
    };
  }

  const params = {
    aid: '946321',
    airports: destinationCode,
    city_name: `${city ? city : destinationInfo.name} ${destinationInfo.country}`,
    checkin_ym: `${outboundDate.getUTCFullYear()}-${checkinMonth}`,
    checkin_d: outboundDate.getUTCDate(),
    checkout_ym: `${outboundDate.getUTCFullYear()}-${checkoutMonth}`,
    checkout_d: returnDate.getUTCDate(),
    language: user.get('language'),
  };

  return params;
}

export function getFlightFormUrl(rootState) {
  return rootState.getIn(['request', 'tabOrder', 0]) === 'flights' ? '/' : '/flights';
}
