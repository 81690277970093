import { Map } from 'immutable';
import time from 'dohop-client-api/src/time';

import {
  CARS_RECEIVE_PICKUP_LOCATION,
  CARS_RECEIVE_IS_DIFFERENT_DROPOFF_LOCATION,
  CARS_RECEIVE_DROPOFF_LOCATION,
  CARS_RECEIVE_PICKUP_DATE,
  CARS_RECEIVE_PICKUP_TIME,
  CARS_RECEIVE_DROPOFF_DATE,
  CARS_RECEIVE_DROPOFF_TIME,
  CARS_RECEIVE_DRIVERS_AGE,
  CARS_RECEIVE_CAR_GROUP,
  CARS_RECEIVE_COOKIEKEYS_OR_QUERYKEYS,
} from '../actions/carSearchActions';

function defaultState() {
  return Map({
    pickup: null,
    dropoff: null,
    differentDropoff: false,
    pickupDate: new Date(Date.now() + 2 * time.week),
    pickupTime: time.hour * 10,
    dropoffDate: new Date(Date.now() + 3 * time.week),
    dropoffTime: time.hour * 10,
    age: 25,
    carGroup: 0,
  });
}

export function formReducer(state = defaultState(), action) {
  switch (action.type) {
    case CARS_RECEIVE_PICKUP_LOCATION:
      return state.set('pickup', action.pickup);
    case CARS_RECEIVE_IS_DIFFERENT_DROPOFF_LOCATION:
      return state.set('differentDropoff', action.differentDropoff);
    case CARS_RECEIVE_DROPOFF_LOCATION:
      return state.set('dropoff', action.dropoff);
    case CARS_RECEIVE_PICKUP_DATE:
      return state.set('pickupDate', action.pickupDate);
    case CARS_RECEIVE_PICKUP_TIME:
      return state.set('pickupTime', action.pickupTime);
    case CARS_RECEIVE_DROPOFF_DATE:
      return state.set('dropoffDate', action.dropoffDate);
    case CARS_RECEIVE_DROPOFF_TIME:
      return state.set('dropoffTime', action.dropoffTime);
    case CARS_RECEIVE_DRIVERS_AGE:
      return state.set('age', action.age);
    case CARS_RECEIVE_CAR_GROUP:
      return state.set('carGroup', action.carGroup);
    case CARS_RECEIVE_COOKIEKEYS_OR_QUERYKEYS:
      state = defaultState();
      return state.merge(action.params);
    default:
      return state;
  }
}

export default function carSearchReducer(state = Map(), action) {
  state = state.set('form', formReducer(state.get('form'), action));
  return state;
}
