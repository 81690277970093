import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Immutable, { Map } from 'immutable';
import { getMonthsInRange } from './Utils';
import './DatePickerMonths.less';

const WINTER_ARR = [0, 1, 11];
const MONTHS_IN_RANGE = getMonthsInRange(new Date(), 12);

class DatePickerMonths extends PureComponent {
  /**
   * Checks whether month shuld be active or not
   * @param {Number} currentMonth - Current month in date number
   * @param {Number} seasonStartMonthNum - Season start month in date number
   * @param {Number} seasonEndMonthNum - Season end month in date number
   * @param {Moment.<Date>} startOfMonthDt - Season start date
   * @param {Moment.<Date>} endOfMonthDt - Season end date
   */
  isMonthActive(
    currentMonth,
    seasonStartMonthNum,
    seasonEndMonthNum,
    startOfMonthDt,
    endOfMonthDt,
    dateDiff
  ) {
    const { d1, d1_, seasonHover } = this.props;

    if (!seasonHover && dateDiff === 11) {
      return false;
    } else if (
      (d1.isSame(startOfMonthDt) && d1_.isSame(endOfMonthDt)) ||
      (currentMonth >= seasonStartMonthNum && currentMonth <= seasonEndMonthNum) ||
      (seasonStartMonthNum > seasonEndMonthNum && WINTER_ARR.includes(currentMonth))
    ) {
      // Check if d1 and d1_ dates are the same as startOfMonthDate and endOfMonthDate
      // Check if currentMonth index is with in season range
      // Because winter season has diffrent logic than other season then make one last winter check
      return true;
    }

    return false;
  }

  render() {
    const { d1, d1_, clickHandler, season } = this.props;
    const seasonStartMonthNum = season ? season.get('d1').month() : d1.month();
    const seasonEndMonthNum = season ? season.get('d1_').month() : d1_.month();
    const dateDiff = d1_.diff(d1, 'months');

    return (
      <div className="DatePickerMonths">
        {MONTHS_IN_RANGE.map((month, i) => {
          const currentMonth = moment
            .utc(month)
            .startOf('month')
            .month();
          const startOfMonthDt = moment.utc(month).startOf('month');
          const endOfMonthDt = moment.utc(month).endOf('month');
          const text = `${moment.months(startOfMonthDt.month())} ${endOfMonthDt.year()}`;
          let active = this.isMonthActive(
            currentMonth,
            seasonStartMonthNum,
            seasonEndMonthNum,
            startOfMonthDt,
            endOfMonthDt,
            dateDiff
          );

          return (
            <div
              className={classnames('DatePickerMonths__month', {
                'DatePickerMonths__month--active': active,
              })}
              key={i}
              onClick={() => clickHandler(Map({ d1: startOfMonthDt, d1_: endOfMonthDt }))}
            >
              {text}
            </div>
          );
        })}
      </div>
    );
  }
}

DatePickerMonths.propTypes = {
  d1: PropTypes.object.isRequired,
  d1_: PropTypes.object.isRequired,
  clickHandler: PropTypes.func.isRequired,
  season: PropTypes.instanceOf(Immutable.Map),
  seasonHover: PropTypes.bool.isRequired,
};

export default DatePickerMonths;
