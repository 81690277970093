import axios from 'axios';
import isBrowser from '@lib/isBrowser';
import config from './config';

const BROWSER_TIMEOUT = 4000;
const SERVER_TIMEOUT = 4000;
// Axios property timeout in instance create method does not
// work on the server thats why we have to set it by default.
axios.defaults.timeout = isBrowser() ? BROWSER_TIMEOUT : SERVER_TIMEOUT;

const instance = axios.create({
  timeout: BROWSER_TIMEOUT,
});

const getPath = path => {
  const prefix = process.env.IS_BROWSER ? '/api' : config.WEB_API;
  return prefix + path;
};

export default function axiosWrapper(options) {
  if (options.url) {
    options = Object.assign({ url: getPath(options.url) }, options);
  }
  return instance(options);
}

axiosWrapper.getPath = getPath;

// add http request methods
['get', 'delete', 'head', 'post', 'put', 'patch'].forEach(method => {
  axiosWrapper[method] = (url, ...args) => {
    const urlReady = args[0] && args[0].noPrefix ? args[0].noPrefix : false;
    return instance[method](!urlReady ? getPath(url) : url, ...args);
  };
});
