import React from 'react';
import createFilters from '../../lib/createFilters';
import Button from '../Button';
import PlainPage from '../PlainPage';
import config from '../../lib/config';
import Airplane from '@content/svg/airplane.svg';
import './TransferPage.less';

class TransferPage extends React.Component {
  renderLoading() {
    let { t } = this.props.filters;

    const dohopVendorIds = [1637, 1638];
    const vendorId = this.props.vendor.get('id');
    let srcImage = `${config.M_STATIC}/vendors/${this.props.vendor.get('id')}.gif`;
    if (dohopVendorIds.includes(vendorId)) {
      srcImage = `${config.M_STATIC}/whitelabel/airlines/dohop.svg`;
    }

    return (
      <div>
        <div className="TransferPage__logoContainer">
          <div className="TransferPage__airplaneContainer">
            <Airplane className="TransferPage__airplane" />
          </div>
          <img src={srcImage} alt={this.props.vendor.get('name')} className="TransferPage__logo" />
        </div>
        <p className="TransferPage__textTitle">
          {t('offeredby %(vendor)s', { vendor: this.props.vendor.get('name') })}
        </p>
        <p className="TransferPage__text">{t('where you can book directly')}</p>
      </div>
    );
  }

  renderFailure() {
    let { t } = this.props.filters;

    return (
      <div>
        <p className="TransferPage__text">
          {t('no deeplink for %(vendor)s', { vendor: this.props.vendor.get('name') })}
        </p>
        <Button big onClick={this.props.onSubmit}>
          {t('continue')}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <PlainPage>
        <div className="TransferPage">
          <div className="TransferPage__content">
            {this.props.hasError ? this.renderFailure() : this.renderLoading()}
          </div>
        </div>
      </PlainPage>
    );
  }
}

TransferPage = createFilters('filters')(TransferPage);

export default TransferPage;
