/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _once = require('lodash/once');
const _extend = require('lodash/extend');
const _keys = require('lodash/keys');
const _values = require('lodash/values');
const _isArray = require('lodash/isArray');
const _isEmpty = require('lodash/isEmpty');
const _fromPairs = require('lodash/fromPairs');
const _some = require('lodash/some');
const _map = require('lodash/map');
const { EventEmitter } = require('events');

const request = require('./request');
const time = require('./time');
const util = require('./util');
const config = require('./config');
const { CurrencyConverter } = require('./helpers');
const sorting = require('./sorting');
const { log, logJSON } = require('./util');
const { ArrayIter, PrefixMatch } = require('./collections');
const {
  Transport,
  Carrier,
  Station,
  Flight,
  Leg,
  StopOver,
  Vendor,
  FareCollection,
  MultiFare,
  Fare,
  VendorFare,
  Route,
  Itinerary,
} = require('./flight');
const { FilterManager } = require('./filters');
const { MetaTracker } = require('./metatracker');
const { ItineraryManager, FareRefresher, ItineraryLoader, ItineraryCache } = require('./pipeline');
const { FareSort, validateFaresort } = require('./faresort');

// @nodoc
class Poller {
  constructor(key, continuation, events, options) {
    this._pollLoop = this._pollLoop.bind(this);
    this._success = this._success.bind(this);
    this._error = this._error.bind(this);
    this.key = key;
    this.continuation = continuation;
    this.events = events;
    if (options == null) {
      options = {};
    }
    this.options = options;
    this._polling = this._forcePoll = this._cancelled = false;
  }

  isPolling() {
    return this._polling;
  }

  poll() {
    if (this._cancelled) {
      return;
    }

    this._forcePoll = this._polling;
    if (!this._polling) {
      this._pollLoop();
      return this.events.emit('poll-start');
    }
  }

  _pollLoop() {
    this._forcePoll = false;
    this._polling = true;

    return request.get('poll', {
      apiVersion: this.options.apiVersion || 1,
      timeout_max: this.options.timeoutMax != null ? this.options.timeoutMax : time.second * 10,
      key: this.key,
      continuation: this.continuation,
      include_faresort: config.crossValidateFaresort ? true : undefined,
      success: this._success,
      error: this._error,
    });
  }

  _success(response) {
    const prevContinuation = this.continuation;
    this.continuation = response.continuation;

    this._polling = !this._cancelled && (this._forcePoll || response.is_done === false);
    if (this._polling) {
      setTimeout(
        this._pollLoop,
        this.options.interval != null ? this.options.interval : 2 * time.second
      );
    }
    if (prevContinuation !== response.continuation) {
      this.options.success(response);
    }
    if (!this._polling) {
      return this.options.stop();
    }
  }

  _error(e) {
    if (e === 'timeout error') {
      return this._pollLoop();
    } else {
      logJSON('error', e);
      return this.options.stop();
    }
  }

  stop() {
    return (this._cancelled = true);
  }
}

// FlightSearch is the entry point in initializing a new flight search.
class FlightSearch {
  // @example Initialize a new fligh search
  //   var search = new FlightSearch({
  //     from: 'LHR,LGW,STN',
  //     to: 'JFK,EWR,LGA',
  //     d1: time.UTCDate(2016, 0, 1),
  //     d2: time.UTCDate(2016, 0, 14),
  //     residency: 'UK'
  //   })
  //
  // @param [Object] options The flight search options
  // @option options [String] from Required. Comma separated station codes
  // @option options [String] to Required. Comma separated station codes
  // @option options [Date] d1 Required. Outbound departure date
  // @option options [Date] d2 Optional. Return date. One way if empty
  // @option options [String] residency Required. residency of user making the search.
  //   Results can vary on residency
  // @option options [String] currency Optional. Currency code used when storing fare prices. Default=EUR
  // @option options [Boolean] run Optional. If disabled, the search must be initialized
  //   manually by calling {Search#run}. Default=true
  // @option options [Boolean] poll Optional. If disabled, the poll loop for server-api data
  //   must be started manually by calling search.poll(). Default=true
  //   NB: the poll loop blocks window.onload in browsers, so it might be necessary to
  //   initialize the polling manually after window.onload.
  // @option options [Boolean] syncWithFilters Optional. See {FlightSearch#syncWithFilters}
  // @option options [Boolean] syncWithChanges Optional. See {FlightSearch#syncWithChanges}
  // @option options [Number] adults Options. Number of adults. Default=1
  // @option options [Array<Number>] youngstersAges Optional. Age of children. Default = no children.
  // @option options [Object] extraSearchParams. Arbitrary parameters sent to api-server when initializing search
  constructor(options) {
    let left;
    this.run = this.run.bind(this);
    this.poll = this.poll.bind(this);
    const {
      currency,
      currencies,
      run,
      poll,
      syncWithFilters,
      syncWithChanges,
      extraSearchParams,
      token,
    } = options;

    this.transport = {};
    this.carriers = {};
    this.stations = {};
    this.vendors = {};
    this.fares = [];
    this.flights = [];
    this.legmap = {}; // Leg.key => Leg
    /**
     * @type Route[]
     */
    this.outbound = [];
    /**
     * @type Route[]
     */
    this.homebound = [];
    this.token = token != null ? token : '';
    this.currency =
      (left = currency != null ? currency : config.defaultCurrency) != null ? left : 'EUR';
    this.extraSearchParams = extraSearchParams != null ? extraSearchParams : {};
    this.apiVersion = extraSearchParams.apiVersion || 1;
    if (currencies) {
      this.currencies = new CurrencyConverter(currencies);
    }

    this.faresort = new FareSort();
    this.fareLoader = new sorting.FareLoader(
      this.faresort.update(),
      this.fares.slice(),
      new FilterManager({ filters: [] })
    );
    this.itineraryCache = new ItineraryCache();
    this.events = new EventEmitter();
    this.events.setMaxListeners(0);
    this.meta = new MetaTracker(this.events, options);
    /** @type {FilterManager} */
    this.filters = new FilterManager({ meta: this.meta, events: this.events });

    this.sort([{ by: 'fare' }, { by: 'duration' }]);

    this._syncWithFilters = syncWithFilters != null ? syncWithFilters : true;
    this.events.on('filter-change', () => {
      if (this._syncWithFilters) {
        return this.sort();
      }
    });

    this._syncWithChanges = syncWithChanges != null ? syncWithChanges : true;
    this.events.on('change', () => {
      if (this._syncWithChanges) {
        return this.sort();
      }
    });

    this._autoPoll = poll != null ? poll : true;

    this.run = _once(this.run);
    if (run !== false) {
      this.run();
    }
  }

  /**
   *Returns an object that generates itineraries as needed
   * @returns {ItineraryManager}
   */
  getItineraries() {
    return this.itineraries;
  }

  // Returns filters for this search
  getFilters() {
    return this.filters;
  }

  // Returns metadata about the search
  // @return [MetaTracker]
  getMeta() {
    return this.meta;
  }

  // Returns the apiVersion in used
  // @return [number]
  getApiVersion() {
    return this.apiVersion;
  }

  // @return [String] session key of the search
  getSessionKey() {
    return this.key;
  }

  // @return [Boolean] is there a poll loop running polling for prices
  isPolling() {
    return this._poller != null ? this._poller.isPolling() : undefined;
  }

  _loadCurrencies(options) {
    if (this.currencies) {
      return options.success();
    } else {
      return request.get('locale', {
        success: ({ currencies }) => {
          this.currencies = new CurrencyConverter(currencies);
          return options.success();
        },
        error: options.error,
      });
    }
  }

  // Initializes the flight search. Only needs to be called manually if {run: false} was used when creating the FlighSearch
  run() {
    return this._loadCurrencies({
      success: () => {
        const options = _extend(
          {},
          config.extraSearchParams != null ? config.extraSearchParams : {},
          this.extraSearchParams,
          {
            residency: this.meta.residency,
            apiVersion: this.apiVersion,
            fareClass:
              this.apiVersion >= 3
                ? typeof fareClass !== 'undefined' && fareClass !== null
                  ? fareClass
                  : 'legacy'
                : undefined,
            from: _keys(this.meta.from).join(','),
            to: _keys(this.meta.to).join(','),
            d1: time.strftime('%Y-%m-%d', this.meta.d1),
            d2: this.meta.d2 ? time.strftime('%Y-%m-%d', this.meta.d2) : undefined,
            language: this.meta.getLanguage(),
            index: this.meta.getIndex(),
            n_adults: this.meta.getNumberOfAdults(),
            youngsters_ages: this.meta.getYoungstersAges().length
              ? this.meta.getYoungstersAges().join(',')
              : undefined,
            success: (response) => {
              ({ continuation: this.continuation, key: this.key } = response);
              if (this._autoPoll) {
                this.getPoller().poll();
              }
              return this.events.emit('ready');
            },
            error: (e, details) => {
              const statusCode = /[0-9]*$/.exec(e.message);
              try {
                this.error = {
                  error: e,
                  details: JSON.parse(details).error,
                  status: JSON.parse(statusCode),
                };
              } catch {
                // In case we are unable to parse the details object
                this.error = {
                  error: e,
                  details: '',
                  status: JSON.parse(statusCode),
                };
              }
              return this.events.emit('error', e);
            },
          }
        );

        if (this.token) {
          const postData = {
            token: this.token,
          };

          return request.post('search', options, postData);
        } else {
          return request.get('search', options);
        }
      },

      error: (e) => {
        return this.events.emit('error', e);
      },
    });
  }

  poll() {
    return this.on('ready', () => {
      return this.getPoller().poll();
    });
  }

  // Gets or sets if sorting should be updated automatically on filter changes
  // @return [Boolean]
  syncWithFilters(value) {
    if (value != null) {
      this._syncWithFilters = !!value;
    }
    return this._syncWithFilters;
  }

  // Gets or sets if sorting should be updated automatically when new flight data arrives from api server
  // @return [Boolean]
  syncWithChanges(value) {
    if (value != null) {
      this._syncWithChanges = !!value;
    }
    return this._syncWithChanges;
  }

  // Returns current sort options
  getSortOptions() {
    if (_isArray(this._sortOptions)) {
      return this._sortOptions;
    } else {
      return [this._sortOptions];
    }
  }

  // Returns true if after initial api server response and we have basic flight data to operate on
  isReady() {
    return this.key != null && this.continuation != null;
  }

  // Returns true if polling is done
  isDone() {
    return this.isReady() && this._poller != null && !this._poller.isPolling();
  }

  // @return [Boolean] Returns true if we have confirmation that there are no flights available for this search
  isEmpty() {
    return this.isDone() && !this.hasResults();
  }

  // @return [Boolean] if there is any data to display
  hasResults() {
    return this.outbound.length > 0 && (this.meta.oneway || this.homebound.length > 0);
  }

  // @return [Boolean] if there is at least 1 result with fares when no filters are applied
  hasResultsWithFares() {
    const fs = this.faresort.update();
    return (
      this.hasResults &&
      (!_isEmpty(fs.rt) ||
        (!_isEmpty(fs['1-way-out']) && (this.meta.oneway || !_isEmpty(fs['1-way-home']))))
    );
  }

  // @return [String] currency code used for storing all prices
  getCurrency() {
    return this.currency;
  }

  // @return An instance of {CurrencyConverter}. This will only be available if {FlightSearch#isReady}
  //   returns true. Will otherwise throw an Error.
  getCurrencyConverter() {
    util.assert(this.currencies, 'Currencies not loaded yet');
    return this.currencies;
  }

  // @nodoc
  getPoller() {
    util.assert(this.isReady(), 'Search is not ready to poll');
    return this._poller != null
      ? this._poller
      : (this._poller = new Poller(this.key, this.continuation, this.events, {
          apiVersion: this.apiVersion,
          success: (r) => this._processPoll(r),
          stop: () => this.events.emit('end'),
        }));
  }

  // @nodoc
  getFareRefresher() {
    return this._fareRefresher != null
      ? this._fareRefresher
      : (this._fareRefresher = new FareRefresher(this.getPoller(), this.faresort, this.events));
  }

  // Reloads all itineraries, returning new Itinerary objects with fresh up-to-date prices
  // @param [Array<Itinerary>]
  // @return [Array<Itinerary>]
  reloadItineraries(itineraries) {
    const fareLoader = this.fareLoader.clone(this.filters);
    let q = new ArrayIter(Array.from(itineraries).map((itin) => itin.getRoutes()));
    q = new ItineraryLoader(q, fareLoader, this.itineraryCache);
    q = new ItineraryManager(q);
    return q.getRange(0, itineraries.length);
  }

  // Re-sorts results by optionally new sort options
  //
  // @example Changing sort order
  //   search.sort() // resort by same options as before
  //   search.sort({by: 'fare'}) // sort itineraries by fare
  //   search.sort([{by: 'fare'}, {by: 'duration'}]) // sort itineraries by fare, then duration
  //
  // @param [Object|Array<Object>] sortOptions
  sort(sortOptions) {
    if (sortOptions != null) {
      this._sortOptions = sortOptions;
    }
    this.itineraries = this.createQueue(this._sortOptions);
    return this.events.emit('sort');
  }

  // @nodoc
  createQueue(sort) {
    if (sort == null) {
      sort = {};
    }
    const filters = this.filters.clone();
    const fareLoader = this.fareLoader.clone(filters);
    let q = sorting.createQueue(sort, {
      outbound: this.outbound,
      homebound: this.meta.twoway ? this.homebound : undefined,
      fareLoader,
      filters,
      meta: this.meta,
    });
    this._loader = q = new ItineraryLoader(q, fareLoader, this.itineraryCache);
    const manager = new ItineraryManager(q, this.isReady() ? this.getFareRefresher() : undefined);
    return manager;
  }

  // @nodoc
  _processPoll(poll) {
    let processors;
    this.continuation = poll.continuation;

    _extend(poll, util.popKey(poll, 'search', {}));

    if (this.getApiVersion() >= 4) {
      processors = [
        ['transport', genericProcessor('transport', Transport)],
        ['carriers', genericProcessor('carriers', Carrier)],
        ['stations', genericProcessor('stations', Station)],
        ['vendors', genericProcessor('vendors', Vendor)],
        ['segments', processFlights],
        ['outbound', routeProcessor('outbound')],
        ['homebound', routeProcessor('homebound')],
        ['fares', processFares],
        ['faresort', processFaresort],
      ];
    } else {
      processors = [
        ['aircraft', processTransportV1],
        ['airports', processStationV1],
        ['airlines', processCarrierV1],
        ['vendors', genericProcessor('vendors', Vendor)],
        ['flights', processFlights],
        ['outbound', routeProcessor('outbound')],
        ['homebound', routeProcessor('homebound')],
        ['fares', processFares],
        ['faresort', processFaresort],
      ];
    }

    const events = [];
    for (let [attr, func] of Array.from(processors)) {
      const data = poll[attr];
      if (data && !_isEmpty(data)) {
        const broadcast = func(this, data);
        if (broadcast && !_isEmpty(broadcast)) {
          events.push([attr, broadcast]);
        }
      }
    }

    for (let [eventName, args] of Array.from(events)) {
      this.events.emit(eventName, args);
    }

    const eventMap = _fromPairs(events);
    if (_some(['outbound', 'homebound', 'fares'], (k) => k in poll)) {
      this.fareLoader.update(this.faresort.update(), eventMap.fares != null ? eventMap.fares : []);
    }

    if (
      _some(processors, function (...args1) {
        let attr;
        [attr] = Array.from(args1[0]);
        return attr in poll;
      })
    ) {
      return this.events.emit('change');
    }
  }

  // @return instance of {EventEmitter}
  // @see http://nodejs.org/api/events.html
  //
  // @example Unregistering event handlers
  //   search.getEvents().removeListener('change', changeCallback)
  //   // or for one time listener
  //   search.getEvents().once('change', function(){ })
  getEvents() {
    return this.events;
  }

  // Registers a callback function when some search event occures. This is a shortcut for
  //   {FlightSearch#getEvents}().on(...)
  //
  // @example
  //   search.on('change', function() { }) // called when data changes and we need to re-sort
  //   search.on('sort', function() { }) // called after results are sorted
  //   search.on('filter-change', function(filterNames) { }) // called when filters are changed
  //
  // @param [String] events Event name to listen on
  // @param [Function] callback to be executed when the event is triggered
  on(events, callback) {
    if (!_isArray(events)) {
      events = [events];
    }
    for (let event of Array.from(events)) {
      if (event === 'ready') {
        if (this.isReady()) {
          callback();
        } else {
          this.events.once(event, callback);
        }
      } else {
        this.events.on(event, callback);
      }
    }
    return this;
  }

  // Shortcut to {#getEvents().removeListener(...)}
  // Supports an array of listeners like {#on}
  // @param [String] events Event name to stop listening to
  // @param [Function] callback Callback function that was passed to {#on}
  removeListener(events, callback) {
    if (!_isArray(events)) {
      events = [events];
    }
    return Array.from(events).map((event) => this.events.removeListener(event, callback));
  }

  // Closes the search. Removes all registered event listeners and stops polling
  close() {
    this.events.removeAllListeners();
    return this._poller != null ? this._poller.stop() : undefined;
  }
}

// @nodoc
var genericProcessor = (type, cls) =>
  function (fs, data) {
    let k, v;
    const newData = _fromPairs(
      (() => {
        const result = [];
        for (k in data) {
          v = data[k];
          result.push([k, new cls(k, v)]);
        }
        return result;
      })()
    );
    for (k in newData) {
      v = newData[k];
      if (fs[type][k] == null) {
        fs[type][k] = v;
      }
    }
    return newData;
  };

const flightDate = time.strptime('%Y-%m-%d %H:%M');

// @nodoc
var processCarrierV1 = function (fs, data) {
  let k, v;
  const carrier = _fromPairs(
    (() => {
      const result = [];
      for (k in data) {
        v = data[k];
        result.push([k, new Carrier(k, { code: v.iata, name: v.name, www: v.www })]);
      }
      return result;
    })()
  );
  for (k in carrier) {
    v = carrier[k];
    if (fs['carriers'][k] == null) {
      fs['carriers'][k] = v;
    }
  }
  return carrier;
};

// @nodoc
var processTransportV1 = function (fs, data) {
  let k, v;
  const transport = _fromPairs(
    (() => {
      const result = [];
      for (k in data) {
        v = data[k];
        result.push([k, new Transport(k, { code: v.code, name: v.name })]);
      }
      return result;
    })()
  );
  for (k in transport) {
    v = transport[k];
    if (fs['transport'][k] == null) {
      fs['transport'][k] = v;
    }
  }
  return transport;
};

// @nodoc
var processStationV1 = function (fs, data) {
  let k, v;
  const station = _fromPairs(
    (() => {
      const result = [];
      for (k in data) {
        v = data[k];
        result.push([
          k,
          new Station(k, {
            name: v.name,
            city: v.city,
            continent: v.continent,
            country: v.country,
            region: v.region,
            code: v.iata,
          }),
        ]);
      }
      return result;
    })()
  );
  for (k in station) {
    v = station[k];
    if (fs['stations'][k] == null) {
      fs['stations'][k] = v;
    }
  }
  return station;
};

// @nodoc
var processFlights = (fs, data) =>
  _map(data, function (row) {
    const [airline, operatedBy, leglist, duration] = Array.from(row);

    const legs = _map(leglist, function (...args) {
      let arrival, departure, fn, from, name, to, transport;
      let duration;
      [from, to, fn, departure, arrival, duration, transport] = Array.from(args[0]);
      const leg = new Leg(
        fs.carriers[airline],
        fn,
        fs.stations[from],
        fs.stations[to],
        flightDate(departure),
        flightDate(arrival),
        duration * time.minute,
        fs.carriers[operatedBy],
        fs.transport[transport]
      );
      if (fs.legmap[(name = leg.getKey())] == null) {
        fs.legmap[name] = leg;
      }
      return leg;
    });

    const flight = new Flight(fs.flights.length, legs, duration * time.minute);

    fs.flights.push(flight);
    return flight;
  });

// @nodoc
var processFares = function (fs, rawFares) {
  const mapLegs = (data) =>
    (() => {
      const result = [];
      for (let i = 0, end = data.length; i < end; i += 4) {
        const [f, t, fn, d] = Array.from(data.slice(i, i + 4));
        const key = `${fn}-${f}-${t}-${d.substring(5)}`;
        util.assert(key in fs.legmap, `Invalid fare: Unknown flight leg reference: ${key}`);
        result.push(fs.legmap[key]);
      }
      return result;
    })();

  const fares = _map(rawFares, function (data, fareId) {
    // map already known fares by vendor-id
    let left;
    const vendorFareMap = {};
    if (fs.fares[fareId]) {
      for (let vf of Array.from(fs.fares[fareId].getAllVendorFares())) {
        vendorFareMap[vf.getVendor().getKey()] = vf;
      }
    }
    // add or override with new fares from api-server
    for (let vid in data.f) {
      const fd = data.f[vid];
      vendorFareMap[vid] = new VendorFare(
        +fareId,
        fs.currency,
        time.second * fd.a,
        fs.currencies.convert(fd.f, fd.c, fs.currency),
        fs.vendors[vid],
        fs.getMeta().getNumPassengers()
      );
    }
    // increment version every time new vendor-fares arrive
    const version =
      ((left = fs.fares[fareId] != null ? fs.fares[fareId].getVersion() : undefined) != null
        ? left
        : 0) + 1;
    return (fs.fares[fareId] = new Fare(
      fareId,
      _values(vendorFareMap),
      mapLegs(data.o),
      mapLegs(data.h),
      version
    ));
  });

  fs.faresort.appendFares(fares);

  return fares;
};

// @nodoc
var routeProcessor = function (direction) {
  const isOutbound = direction === 'outbound';
  return function (fs, rIndexes) {
    const routes = _map(rIndexes, function (...args) {
      let i;
      const [fIndexes, groundTransits] = Array.from(args[0]);
      const flights = (() => {
        const result = [];
        for (i of Array.from(fIndexes)) {
          result.push(fs.flights[i]);
        }
        return result;
      })();
      const stops = (() => {
        const result1 = [];
        for (i = 0; i < groundTransits.length; i++) {
          const minutes = groundTransits[i];
          const [a, b] = Array.from(flights.slice(i, +(i + 1) + 1 || undefined));
          result1.push(
            new StopOver(
              a.getDestination(),
              b.getOrigin(),
              a.getArrival(),
              b.getDeparture(),
              minutes * time.minute
            )
          );
        }
        return result1;
      })();
      const route = new Route(flights, stops, { id: fs[direction].length, outbound: isOutbound });
      fs[direction].push(route);
      return route;
    });
    fs.faresort.appendRoutes(routes);
    return routes;
  };
};

// @nodoc
var processFaresort = function (fs, serverFaresort) {
  for (let rIdOut of Object.keys(serverFaresort.rt || {})) {
    const homebound = serverFaresort.rt[rIdOut];
    serverFaresort.rt[rIdOut] = {};
    for (let [rIdHome, fareIds, bits] of Array.from(homebound)) {
      (serverFaresort.rt[rIdOut][rIdHome] != null
        ? serverFaresort.rt[rIdOut][rIdHome]
        : (serverFaresort.rt[rIdOut][rIdHome] = [])
      ).push([fareIds, bits]);
    }
  }

  const clientFaresort = fs.faresort.update();
  return validateFaresort(serverFaresort, clientFaresort);
};

exports.FlightSearch = FlightSearch;
