import React from 'react';
import classNames from 'classnames';
import './Constraint.less';

export default function Constraint(props) {
  return (
    <div
      className={classNames(
        'Constraint',
        {
          'Constraint--front': props.front,
          'Constraint--slim': props.slim,
          'Constraint--wide': props.wide,
          'Constraint--absolute': props.absolute,
          'Constraint--laptop': props.laptop,
        },
        props.className
      )}
    >
      {React.Children.toArray(props.children)}
    </div>
  );
}
