/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');
const config = require('../config');
const time = require('../time');
const util = require('../util');

let unique = 0;

const newCallbackId = () => `_dohop_${unique++}_${Math.random()}`.replace('.', '');

exports.post = function (options) {
  options = _extend(
    {
      success() {},
      error() {},
    },
    options
  );

  const req = new XMLHttpRequest();
  const url = `${config.protocol}//${options.hostname}${options.path}`;
  req.onreadystatechange = function () {
    if (req.readyState === 4) {
      if (req.status === 200) {
        let data;
        try {
          data = JSON.parse(req.responseText);
        } catch (e) {
          options.error(e);
          return;
        }
        return options.success(data);
      } else {
        return options.error(new Error(`HTTP error: ${req.status}`), req.response);
      }
    }
  };

  req.open('POST', url, true);
  req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');
  return req.send(JSON.stringify(options.postData));
};

exports.get = function (options) {
  let req;
  options = _extend(
    {
      success() {},
      error() {},
    },
    options
  );

  const supportsJSON = typeof JSON !== 'undefined' && typeof JSON.parse === 'function';

  if (supportsJSON && typeof XDomainRequest !== 'undefined') {
    // ie8 and ie9 support CORS via XDomainRequest
    req = new XDomainRequest();
    req.onprogress = function () {};
    req.ontimeout = function () {};
    req.onerror = () => options.error(new Error('request error'));
    req.onload = function () {
      let data;
      try {
        data = JSON.parse(req.responseText);
      } catch (e) {
        options.error(e);
        return;
      }
      return options.success(data);
    };
    // protocol must be set for XDomainRequest and must be the same as the current page
    req.open('get', `${location.protocol}//${options.hostname}${options.path}`);
    setTimeout(() => req.send());
  } else if (supportsJSON && typeof XMLHttpRequest !== 'undefined') {
    // XMLHttpRequest for everyone else that supports it
    req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (req.readyState === 4) {
        if (req.status === 200) {
          let data;
          try {
            data = JSON.parse(req.responseText);
          } catch (e) {
            options.error(e);
            return;
          }
          return options.success(data);
        } else {
          return options.error(
            new Error(`HTTP error: ${req.status} - ${req.responseURL}`),
            req.response
          );
        }
      }
    };

    req.open('get', `${config.protocol}//${options.hostname}${options.path}`, true);
    req.send();
  } else {
    // fallback to jsonp
    let left;
    const id = newCallbackId();
    let timeoutId = null;

    const head = (left = document.getElementsByTagName('head')[0]) != null ? left : document.body;
    let script = null;

    // setup a timeout in case the server does not respond with jsonp (503 etc)
    if (options.timeout !== false) {
      timeoutId = window.setTimeout(
        function () {
          options.error('timeout error');
          return (options.error = options.success = function () {});
        },
        typeof options.timeout === 'number' ? options.timeout : 30 * time.second
      );
    }

    // create a global success callback
    window[id] = (response) => {
      try {
        // remove global success callback and timeout
        delete window[id];
      } catch (error) {
        // some versions of IE throw an exception when attempting to delete from the window object
        window[id] = undefined;
      }
      window.clearTimeout(timeoutId);

      // cleanup script tag since we're done with it
      if (script) {
        head.removeChild(script);
      }

      return options.success(response);
    };

    // create the url
    let url = `${config.protocol}//${options.hostname}${options.path}`;
    url += `${Array.from(url).includes('?') ? '&' : '?'}cb=${id}`;

    // and dyncamically insert a script tag to make the request
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    head.appendChild(script);
  }

  return undefined;
};
