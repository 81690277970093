import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { is } from 'immutable';
import * as goActions from '@actions/goActions';
import { fetchRecommendedHotels } from '@actions/hotelActions';
import GoFrontPage from '@components/go/GoFrontPage';
import fetchData from '@lib/fetchData';
import getGoParams from '@lib/getGoParams';
import createFilters from '@lib/createFilters';
import RecaptchaLinks from '@components/RecaptchaLinks';

class GoRoute extends Component {
  componentDidUpdate(prevProps) {
    const { go: prevProp, goActions: prevGoActions } = prevProps;
    const { history, go } = this.props;

    if (!is(prevProp.get('results'), go.get('results'))) {
      const url = prevGoActions.createUrl();

      if (!window || url !== location.pathname + location.search) {
        history.replace(url, {});
      }
    }
  }

  render() {
    const {
      filters: { t },
    } = this.props;
    return (
      <Fragment>
        <GoFrontPage {...this.props} />
        <RecaptchaLinks t={t} isSearchPage />
      </Fragment>
    );
  }
}
GoRoute = createFilters('filters')(GoRoute);

GoRoute = connect(
  state => ({
    go: state.get('go'),
    form: state.get('flightSearchForm'),
  }),
  dispatch => ({
    goActions: bindActionCreators(goActions, dispatch),
  })
)(GoRoute);

// Pre fetch data on server
GoRoute = fetchData(GoRoute, async (store, { params, location, prevParams, prevLocation }) => {
  if (location.action === 'REPLACE') return;

  const pathParams = params;
  const queryParams = location.search;

  params = getGoParams(pathParams, queryParams);

  const promises = [];
  const { destination } = params;

  await store.dispatch(goActions.populateFromParams(params));
  promises.push(store.dispatch(goActions.fetchGo()));

  if (
    destination &&
    (!prevParams || destination !== getGoParams(prevParams, queryParams).destination)
  ) {
    promises.push(store.dispatch(fetchRecommendedHotels(destination, 3)));
  }

  return await Promise.all(promises);
});

export default withRouter(GoRoute);
