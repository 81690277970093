import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import classNames from 'classnames';
import bemGenerator from '@lib/bemGenerator';
import './Button.less';

const bem = bemGenerator('Button');
const modifiers = [
  'big',
  'small',
  'fill',
  'ocean',
  'sort',
  'select',
  'loadMore',
  'submit',
  'active',
  'form',
  'disabled',
];

class Button extends Component {
  render() {
    const { className, submit, children } = this.props;
    let { comp } = this.props;
    const props = omit(this.props, 'comp', ...modifiers);
    props.className = classNames(bem(pick(this.props, modifiers)), className);
    comp = comp || 'a';

    if (submit) {
      comp = 'button';
      props.type = 'submit';
    }

    return React.createElement(comp, props, ...React.Children.toArray(children));
  }
}

Button.propTypes = {
  className: PropTypes.string,
  comp: PropTypes.string,
  submit: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;
