const GET_COOKIE_ACTION = '__GET_COOKIE__';
const SET_COOKIE_ACTION = '__SET_COOKIE__';

export function cookieMiddleware(cookie) {
  return () => {
    return next => action => {
      switch (action.type) {
        case GET_COOKIE_ACTION:
          return cookie.get(action.name);
        case SET_COOKIE_ACTION:
          return cookie.set(action.name, action.value, action.options);
        default:
          return next(action);
      }
    };
  };
}

export function setCookie(name, value, options = {}) {
  // workaround for cookie-dough bug
  if (!process.env.IS_BROWSER && options.maxAge != null) {
    options = Object.assign({}, options, { maxAge: options.maxAge * 1000 });
  }

  return {
    type: SET_COOKIE_ACTION,
    name,
    value,
    options,
  };
}

export function getCookie(name) {
  return {
    type: GET_COOKIE_ACTION,
    name,
  };
}
