/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _filter = require('lodash/filter');
const _uniq = require('lodash/uniq');
const _last = require('lodash/last');
const _sortedIndex = require('lodash/sortedIndex');
const _fromPairs = require('lodash/fromPairs');
const _isArray = require('lodash/isArray');
const _bind = require('lodash/bind');
const _once = require('lodash/once');
const _compact = require('lodash/compact');
const { EventEmitter } = require('events');
const { FilterManager } = require('./filters');
const sorting = require('./sorting');
const util = require('./util');
const { ROUTE_SEPERATOR, MINIMUM_DESTINATION_STAY } = require('./flight');
const { ItineraryLoader, ItineraryCache, ItineraryManager } = require('./pipeline');

// TODO: we should move each helper to a seperate file. This file can remain with only exports of
// all helpers. That way the user if this library can import only the helpers he needs without
// affecting final bundled file size (as long as it's built with browserify/webpack or similar)

// Converts an array of legs to an array of flights. Main use case when you have a fare object
// which has a list of outbound and homebound legs, and you need the same reprecentation of
// flights as in the itinerary, where multiple legs can be grouped together in a single flight
const legsToFlights = (flights, legs) =>
  _filter(flights, function (f) {
    let needle;
    return (needle = f.getLegs()[0]), Array.from(legs).includes(needle);
  });

// calculates number of itineraries that have complete fares for all legs, disregarding filter state
const countItinerariesWithFares = function (search) {
  let routeOut;
  let rId, r;
  const faresort = search.faresort.update();
  let routesOut = (() => {
    const result = [];
    for (rId in faresort['1-way-out']) {
      result.push(search.outbound[rId]);
    }
    return result;
  })();
  if (search.getMeta().isOneWay()) {
    return routesOut.length;
  }
  let routesHome = (() => {
    const result1 = [];
    for (rId in faresort['1-way-home']) {
      result1.push(search.homebound[rId]);
    }
    return result1;
  })();

  if (search.outbound.length === 0 || search.homebound.length === 0) {
    return 0;
  }

  let total = 0;

  routesOut = _uniq(routesOut, (rOut) => rOut.getKey());
  routesHome = _uniq(routesHome, (rHome) => rHome.getKey());

  routesOut.sort((a, b) => b.getArrival().getTime() - a.getArrival().getTime());
  routesHome.sort((a, b) => a.getDeparture().getTime() - b.getDeparture().getTime());

  // count 1-way-out * 1-way-home
  if (routesOut.length > 0 && routesHome.length > 0) {
    if (_last(routesOut).isValidPair(routesHome[0])) {
      // all route pairs are valid
      total += routesOut.length * routesHome.length;
    } else if (routesOut.length > 0 && routesHome.length > 0) {
      const routesHomeDepartures = (() => {
        const result2 = [];
        for (r of Array.from(routesHome)) {
          result2.push(r.getDeparture().getTime());
        }
        return result2;
      })();
      for (routeOut of Array.from(routesOut)) {
        total += routesHome.length;
        if (!routeOut.isValidPair(routesHome[0])) {
          // subtract number of invalid pairs
          total -= _sortedIndex(
            routesHomeDepartures,
            routeOut.getArrival().getTime() + MINIMUM_DESTINATION_STAY
          );
        }
      }
    }
  }

  // create sets of 1-way routes to exclude from twoway fare counting
  const oneWayOutSet = util.createSet(
    (() => {
      const result3 = [];
      for (r of Array.from(routesOut)) {
        result3.push(r.getKey());
      }
      return result3;
    })()
  );
  const oneWayHomeSet = util.createSet(
    (() => {
      const result4 = [];
      for (r of Array.from(routesHome)) {
        result4.push(r.getKey());
      }
      return result4;
    })()
  );

  // count two-way fares
  for (let rIdOut in faresort.rt) {
    const homebound = faresort.rt[rIdOut];
    routeOut = search.outbound[rIdOut];
    for (let rIdHome in homebound) {
      const routeHome = search.homebound[rIdHome];
      const isOneWayPair = routeOut.getKey() in oneWayOutSet && routeHome.getKey() in oneWayHomeSet;
      if (!isOneWayPair && routeOut.isValidPair(routeHome)) {
        total++;
      }
    }
  }

  return total;
};

// Currency converter. {FlightSearch} will create one when running search
// @see {FlightSearch#getCurrencyConverter}
class CurrencyConverter {
  constructor(currencies) {
    this.currencies = currencies;
    if (_isArray(this.currencies)) {
      this.currencies = _fromPairs(this.currencies);
    }
  }

  // @param [Number] amount The amount to convert
  // @param [String] fromCurrency The currency to convert from
  // @param [String] toCurrency The currency to convert to
  convert(amount, fromCurrency, toCurrency) {
    const v = (amount * this.currencies[toCurrency]) / this.currencies[fromCurrency];
    util.assert(
      !isNaN(v),
      () => `could not convert ${amount} from ${fromCurrency} to ${toCurrency}`
    );
    return v;
  }
}

// FareChecker is to find cheapest fares, either by different filter settings, or to see what cheapest
// available price is without affecting the currently displayed results.
// @example Check for cheapest available price
//   var lowest = new FareChecker(search).getLowest()
//
// @example Check cheapest price for different filter settings
//   fareChecker = new fareChecker(search)
//   var lowestIfSelfConnectToggled = fareChecker.checkFare(function(filters) {
//       filters.set('selfConnect', !filters.getFilter('selfConnect').isActive());
//   });
//   // The filter state is reset before checkFare returns, so we don't have to reset to do another check.
//   var lowestIfRedEyeToggled = fareChecker.checkFare(function(filters) {
//       filters.set('redEye', !filters.getFilter('redEye').isActive());
//   });
//   // When creating FareChecker, the filter state will be the same as search.getFilters().
//   // We can reset it by doing:
//   fareChecker.filters.clear()
//   var lowestWithoutGroundTransit = fareChecker.checkFare(function(filters) {
//       filters.set('groundTransit', false);
//   });
//
// @example Keeping it up to date
//   // FareChecker will report the prices as they were when it is initialized.
//   // To keep displaying the cheapes fare when server responds with new fares,
//   // we must re-create it when search changes
//   renderUI(new FareChecker(search));
//   search.on('change', function(){
//       renderUI(new FareChecker(search));
//   })
class FareChecker {
  constructor(search) {
    this.meta = search.meta;
    this.itineraryCache = new ItineraryCache();
    this.filters = search.filters.clone();
    this.outbound = search.outbound.slice();
    this.homebound = search.homebound.slice();
    this.fareLoader = search.fareLoader.clone();
  }

  getItinerary() {
    const fareLoader = this.fareLoader.clone(this.filters.clone());
    const q = sorting.createQueue(
      { by: 'fare' },
      {
        outbound: this.outbound,
        homebound: this.meta.twoway ? this.homebound : undefined,
        fareLoader,
        filters: this.filters,
      }
    );
    return new ItineraryLoader(q, fareLoader, this.itineraryCache).next();
  }

  // @return [Number] the lowest possible price when nothing is filtered out
  getLowest() {
    return this.checkFare((filters) => filters.clear());
  }

  // @return [Itinerary] the cheapest possible itinerary for certain filter settings
  // @param [Function] callback A callback that receives a FilterManager intance as an argument
  checkItinerary(cb) {
    if (!cb) {
      return this.getItinerary();
    }
    const state = this.filters.getState();
    this.filters.atomic(cb);
    const itin = this.getItinerary();
    this.filters.setState(state);
    return itin;
  }

  // @return [Number] the total amount for the cheapest possible itinerary for certain filter settings
  // @param [Functoin] callback Same as CheckItinerary
  checkFare(cb) {
    const itin = this.checkItinerary(cb);
    if (itin) {
      return itin.getFares().getBestFare().getTotal();
    }
  }
}

class MultipleFareChecker {
  constructor(search) {
    this.meta = search.meta;
    this.filters = search.filters.clone();
    this.outbound = search.outbound.slice();
    this.homebound = search.homebound.slice();
    this.fareLoader = search.fareLoader.clone();
    this.sortOptions = search.getSortOptions().slice();
  }

  getItineraries(cb) {
    const state = this.filters.getState();
    this.filters.atomic(cb);
    const fareLoader = this.fareLoader.clone(this.filters.clone());
    const q = sorting.createQueue(this.sortOptions, {
      outbound: this.outbound,
      homebound: this.meta.twoway ? this.homebound : undefined,
      fareLoader,
      filters: this.filters,
      meta: this.meta,
    });
    const itineraryLoader = new ItineraryLoader(q, fareLoader);
    return new ItineraryManager(itineraryLoader);
  }
}

const _storeRoute = (map, route) => (map[route.getKey()] = route);

// ItineraryFinder is a lookup mechanizm for finding itineraries for e.g. bookmarking or direct linking.
//
// @example Checking for an itinerary
//   finder = new ItineraryFinder(search, itineraryId)
//   itinerary = finder.find()
//
// @example Watching for itinerary
//   // The server can respond with additional itineraries at any time, so the itinerary we're looking
//   // for might not necessarily be available initially. In that case we can watch for changes.
//   finder = new ItineraryFinder(search, itineraryId)
//   finder.watch(function(itinerary) {
//       // do something with itinerary
//   });
//   // Later, if we don't need the finder anymore but are going to continue using the search, we must
//   // clean up to not leak memory through event listeners which never get un-registered.
//   finder.close();
class ItineraryFinder {
  constructor(search, id) {
    this.search = search;
    this.keys = id.split(ROUTE_SEPERATOR);
    this.events = new EventEmitter();
    this._mapRoutes();

    this._searchListeners = {
      outbound: _bind(this._appendOutbound, this),
      homebound: _bind(this._appendHomebound, this),
      change: _bind(this._onChange, this),
    };

    this._bindListeners = _once(() => {
      this._mapRoutes();
      return (() => {
        const result = [];
        for (let k in this._searchListeners) {
          const f = this._searchListeners[k];
          result.push(this.search.events.on(k, f));
        }
        return result;
      })();
    });
  }

  _mapRoutes() {
    this.outbound = util.eachWithObject(this.search.outbound, {}, _storeRoute);
    return (this.homebound = util.eachWithObject(this.search.homebound, {}, _storeRoute));
  }

  _appendOutbound(routes) {
    return util.eachWithObject(routes, this.outbound, _storeRoute);
  }

  _appendHomebound(routes) {
    return util.eachWithObject(routes, this.homebound, _storeRoute);
  }

  _onChange() {
    if (
      !this.events ||
      !this.events.listeners ||
      !this.events.listeners('found') ||
      !this.events.listeners('found').length
    ) {
      return;
    }
    const itinerary = this.find();
    if (!itinerary) {
      return;
    }
    return this.events.emit('found', itinerary);
  }

  // @param [Function] callback Callback to be called when itinerary is found.
  // @note Callback function can be triggered multiple times. If server responds with more fares
  //   after itinerary has already been found, the callback will be called again with a new itinerary object
  //   containing the latest known fares
  watch(callback) {
    this._bindListeners();
    this.events.on('found', callback);
    const itinerary = this.find();
    if (itinerary) {
      return callback(itinerary);
    }
  }

  // @return [Itinerary|undefined] itinerary if the search has it
  find() {
    const outbound = this.outbound[this.keys[0]];
    const homebound = this.keys[1] ? this.homebound[this.keys[1]] : undefined;
    if (outbound && (homebound || this.keys.length < 2)) {
      let q = {
        next() {
          return _compact([outbound, homebound]);
        },
      };
      const fareLoader = this.search.fareLoader.clone();
      q = new ItineraryLoader(q, fareLoader, this.search.itineraryCache);
      return q.next();
    }
  }

  // Cleans up so we don't leak memory through dangling callback functions
  close() {
    for (let k in this._searchListeners) {
      const f = this._searchListeners[k];
      this.search.events.removeListener(k, f);
    }
    this.events.removeAllListeners();
    return delete this.events;
  }
}

module.exports = {
  CurrencyConverter,
  legsToFlights,
  countItinerariesWithFares,
  FareChecker,
  MultipleFareChecker,
  ItineraryFinder,
  createBookingParams: require('./helpers/createBookingParams'),
};
