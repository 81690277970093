import forOwn from 'lodash/forOwn';

export default function redirectWithPost(url, params = {}, target) {
  if (!process.env.IS_BROWSER) throw new Error('redirectWithPost does not work server-side');
  let form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  if (target) form.target = target;
  document.body.appendChild(form);

  forOwn(params, (v, k) => {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = k;
    input.value = v;
    form.appendChild(input);
  });

  form.submit();
  form.parentNode.removeChild(form);
}
