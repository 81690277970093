import immutableSerializer from './lib/immutableSerializer';

const globals = immutableSerializer.fromJS(window.DOHOP_RESOURCES);

// We wait 1 second to delete the DOHOP_RESOURCES from the window variable.
// The reason is that we are doing code splitting and some of the
// code splitted files rely on our config do be defined when they are loaded a bit later.
setTimeout(() => {
  delete window.DOHOP_RESOURCES;
}, 1000);

export default (...args) => globals.getIn(args);
