import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SEASONS, seasonsIds } from './Utils';
import WinterIcon from '@content/svg/winter.svg';
import SpringIcon from '@content/svg/spring.svg';
import SummerIcon from '@content/svg/sun.svg';
import FallIcon from '@content/svg/leaf.svg';
import './DatePickerSeasons.less';

class DatePickerSeasons extends PureComponent {
  /**
   * Mouse enter handler
   *
   * @param {Object.<Map>} season
   */
  seasonHoverEnter(season) {
    // Invoke callback function
    this.props.callbackHandler(season, true);
  }

  /**
   * Mouse leave handler
   *
   * @param {Object.<Map>} season
   */
  seasonHoverLeave(season) {
    // Invoke callback function
    this.props.callbackHandler(season, false);
  }

  /**
   * Season click handler
   *
   * @param {Object.<Map>} season
   */
  seasonClickHandler(season) {
    this.props.clickHandler(season);
  }

  renderSeasonIcon(id) {
    const className = `DatePickerSeasons__seasonIcon DatePickerSeasons__seasonIcon--${id}`;

    switch (id) {
      case seasonsIds.WINTER:
        return <WinterIcon className={className} />;
      case seasonsIds.SUMMER:
        return <SummerIcon className={className} />;
      case seasonsIds.SPRING:
        return <SpringIcon className={className} />;
      case seasonsIds.FALL:
        return <FallIcon className={className} />;
      default:
        return null;
    }
  }

  /**
   * Renders weather seasons
   */
  renderSeasons() {
    const { t, d1, d1_ } = this.props;

    return SEASONS.map((season, key) => {
      const isActiveSeason = d1.isSame(season.get('d1')) && d1_.isSame(season.get('d1_'));

      return (
        <div
          className={classnames(
            'DatePickerSeasons__season',
            `DatePickerSeasons__season--${season.get('id')}`,
            {
              'DatePickerSeasons__season--active': isActiveSeason,
            }
          )}
          key={key}
          onClick={() => this.seasonClickHandler(season)}
          onMouseEnter={() => this.seasonHoverEnter(season)}
          onMouseLeave={() => this.seasonHoverLeave(season)}
        >
          {this.renderSeasonIcon(season.get('id'))}
          {season.get('name')(t)}
        </div>
      );
    }).toArray();
  }

  render() {
    return <div className="DatePickerSeasons">{this.renderSeasons()}</div>;
  }
}

DatePickerSeasons.propTypes = {
  d1: PropTypes.object.isRequired,
  d1_: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  callbackHandler: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
};

export default DatePickerSeasons;
