const config = window.__DohopConfig__;

// We wait 1 second to delete the __DohopConfig__ from the window variable.
// The reason is that we are doing code splitting and some of the
// code splitted files rely on our config do be defined when they are loaded a bit later.
setTimeout(() => {
  delete window.__DohopConfig__;
}, 1000);

export default config;
