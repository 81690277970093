import React, { Component } from 'react';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import OverlayStack from '@components/modal/OverlayStack';
import ArrowLeft from '@content/icons/arrow_large_left.svg';
import './MobileView.less';

class MobileView extends Component {
  renderTop(ghost) {
    const { t, onClose, showConfirm } = this.props;
    // the "ghost" element will be set to visibility:hidden to take the necessary space required by
    // the actual header which has position fixed. That way we can have a position:fixed header
    // with dynamic height
    return (
      <div
        className={classNames('MobileView__bar', {
          'MobileView__bar--ghost': ghost,
        })}
      >
        <div
          role="button"
          onClick={onClose}
          className={classNames('MobileView__headerButton', 'MobileView__backButton')}
        >
          <ArrowLeft className="MobileView__arrow" />
        </div>
        {showConfirm && (
          <div
            role="button"
            onClick={onClose}
            className={classNames('MobileView__headerButton', 'MobileView__confirm')}
          >
            {t('Confirm')}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { title, subtitle, padding, children } = this.props;

    return (
      <OverlayStack>
        <div className="MobileView">
          {this.renderTop()}
          {this.renderTop(true)}
          <div
            className={classNames('MobileView__content', {
              'MobileView__content--padding': padding,
            })}
          >
            <div className="MobileView__title">{title}</div>
            {Boolean(subtitle) && <div className="MobileView__subtitle">{subtitle}</div>}
            {children}
          </div>
        </div>
      </OverlayStack>
    );
  }
}

MobileView.defaultProps = {
  padding: false,
  showConfirm: false,
};

MobileView = createFilters(['t'])(MobileView);

export default MobileView;
