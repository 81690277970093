import classNames from 'classnames';
import './RecaptchaLinks.less';

import React from 'react';
import PropTypes from 'prop-types';
import splitTranslateStringToArr from 'shared/utils/splitTranslateStringToArr';

const RecaptchaLinks = ({ t, isSearchPage }) => {
  const [firstText, linkText1, afterLink1, linkText2, afterLink2] = splitTranslateStringToArr(
    t('recaptcha_tems_and_conditions')
  );

  return (
    <div className={classNames('RecaptchaLinks', { 'RecaptchaLinks--searchPage': isSearchPage })}>
      <p>
        {firstText}
        <a target="_blank" href="https://policies.google.com/privacy">
          {linkText1}
        </a>
        {afterLink1}
        <a target="_blank" href="https://policies.google.com/terms">
          {linkText2}
        </a>
        {afterLink2}
      </p>
    </div>
  );
};

RecaptchaLinks.propTypes = {
  t: PropTypes.func.isRequired,
};

export default RecaptchaLinks;
