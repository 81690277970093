import React, { Component } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import classNames from 'classnames';
import Immutable, { List, Map } from 'immutable';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import updateUserSettings from '@lib/updateUserSettings';
import ClickOutside from '../ClickOutside';
import Button from '../Button';
import Dropdown from '../Dropdown';
import * as userActions from '../../actions/userActions';
import createFilters from '../../lib/createFilters';
import MatchMedia from '../MatchMedia';
import globals from '../../globals';
import Portal from '../modal/Portal';
import isSupportedLanguage from '../../lib/isSupportedLanguage';
import DownArrow from '../../../content/svg/tiny-down-arrow.svg';
import Globe from '@content/svg/globe_outlined.svg';
import './Settings.less';

const MAIN_CURRENCIES = Immutable.OrderedSet([
  'EUR',
  'USD',
  'CAD',
  'GBP',
  'DKK',
  'NOK',
  'SEK',
  'ISK',
  'HUF',
]);

function dropdownOptions(items) {
  return items.map((o) => Map({ label: o.get('name'), value: o.get('code') }));
}

function currencyOptions(currencies) {
  return currencies.map((c) => {
    return Map({ label: `${c.get('name')} (${c.get('code')})`, value: c.get('code') });
  });
}

class Settings extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      isOpen: false,
      residency: user.get('residency'),
      language: user.get('language'),
      currency: user.get('currency'),
    };

    this.onToggle = this.onToggle.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onUpdateSettings = this.onUpdateSettings.bind(this);
  }

  onToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onClickOutside() {
    const { isOpen } = this.state;

    if (isOpen) {
      this.setState({ isOpen: false });
    }
  }

  onUpdateSettings() {
    const { actions, history } = this.props;
    const settings = pick(this.state, 'residency', 'language', 'currency');
    const updatedSettings = updateUserSettings(history, settings);
    this.setState({ isOpen: false });
    actions.setUserSettings(updatedSettings);
  }

  onHandleChange(changedDropdown, event) {
    this.setState({ [changedDropdown]: event.target.value });
  }

  renderContent() {
    const {
      user,
      filters: { t },
    } = this.props;
    const { residency, currency, language: stateLanguage } = this.state;
    const language = user.get('language');
    const currencies = globals('currencies', language);
    const mainCurrencies = MAIN_CURRENCIES.toList().map((k) => currencies.get(k));
    const moreCurrencies = currencies.toList().filter((c) => !MAIN_CURRENCIES.has(c.get('code')));
    const languageOptions = globals('languages').filter((lang) =>
      isSupportedLanguage(lang.get('code'))
    );

    return (
      <div className="Settings__window">
        <div className="Settings__label">{t('residency')}</div>
        <div className="Settings__dropdown">
          <Dropdown
            value={residency}
            options={dropdownOptions(globals('countries', language))}
            onChange={this.onHandleChange.bind(this, 'residency')}
          />
        </div>
        <div className="Settings__label">
          {t('language')}
          {user.get('language') !== 'en' && ' (Language)'}
        </div>
        <div className="Settings__dropdown">
          <Dropdown
            value={stateLanguage}
            options={dropdownOptions(languageOptions)}
            onChange={this.onHandleChange.bind(this, 'language')}
          />
        </div>
        <div className="Settings__label">{t('currency')}</div>
        <div className="Settings__dropdown">
          <Dropdown
            value={currency}
            options={List([
              Map({ label: t('maincurrencies'), options: currencyOptions(mainCurrencies) }),
              Map({ label: t('morecurrencies'), options: currencyOptions(moreCurrencies) }),
            ])}
            onChange={this.onHandleChange.bind(this, 'currency')}
          />
        </div>
        <Button
          big
          fill
          className="Settings__cancel"
          onClick={() => this.setState({ isOpen: false })}
        >
          {t('cancel')}
        </Button>
        <Button big fill className="Settings__activate" onClick={this.onUpdateSettings}>
          {t('apply')}
        </Button>
        <div className="Settings__closeIcon" onClick={() => this.setState({ isOpen: false })} />
      </div>
    );
  }

  render() {
    const { user, dark } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="Settings">
        <a
          className={classNames('Settings__menu', {
            'Settings__menu--dark': dark,
          })}
          onClick={this.onToggle}
        >
          <MatchMedia max="ipad">
            <Globe className="Settings__globeIcon" />
          </MatchMedia>
          <MatchMedia min="ipad">
            <span className="Settings__language">
              {globals('languages', user.get('language'), 'name')}
            </span>
            <span className="Settings__currency">({user.get('currency')})</span>
            <DownArrow
              className={classNames('Settings__downArrow', { 'Settings__downArrow--open': isOpen })}
            />
          </MatchMedia>
        </a>
        <div className="Settings__bottom">
          <MatchMedia max="ipad">
            {isOpen && (
              <Portal>
                <div>
                  <div className="Settings__overlay" onClick={this.onToggle} />
                  {this.renderContent()}
                </div>
              </Portal>
            )}
          </MatchMedia>
          <MatchMedia min="ipad">
            <CSSTransition in={isOpen} timeout={200} unmountOnExit classNames="Settings__window">
              <ClickOutside key="window" onClickOutside={this.onClickOutside}>
                {this.renderContent()}
              </ClickOutside>
            </CSSTransition>
          </MatchMedia>
        </div>
      </div>
    );
  }
}

Settings = createFilters('filters')(Settings);

export default connect(
  (state) => ({
    user: state.get('user'),
  }),
  (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
  })
)(withRouter(Settings));
