import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _omit from 'lodash/omit';
import './Checkbox.less';

const Checkbox = props => {
  const Comp = props.comp || 'label';

  return (
    <Comp
      className={classNames('Checkbox', { 'Checkbox--disabled': props.disabled }, props.className)}
    >
      <input
        {..._omit(props, 'comp', 'className', 'children')}
        type="checkbox"
        className="Checkbox__input"
      />
      <span
        className={classNames('Checkbox__check', {
          'Checkbox__check--white': props.white === 'true',
        })}
      />
      {Boolean(props.children) && <span className="Checkbox__content">{props.children}</span>}
    </Comp>
  );
};

Checkbox.defaultProps = {
  white: 'false',
  disabled: false,
  className: '',
  children: null,
};

Checkbox.propTypes = {
  white: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Checkbox;
