/**
 * Checks if localstorage is availiable in browser
 * @return {boolean}
 */
function localStorageChecker() {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function saveLocally(key, value) {
  if (localStorageChecker()) {
    window.localStorage.setItem(key, value);
  }
}

export function getLocallySaved(key) {
  if (localStorageChecker()) {
    return window.localStorage.getItem(key);
  }
}
