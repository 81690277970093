import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../form/Checkbox';
import FilterBox from './FilterBox';
import Tooltip from '../Tooltip';
import { trackFilterCheckbox } from '../../lib/tracking';
import './FilterMain.less';

class FilterMain extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.onClearAll = this.onClearAll.bind(this);
  }

  onToggle(name) {
    const { filters } = this.props;
    const isActive = filters.getFilter(name).isActive();
    filters.set(name, isActive);
    trackFilterCheckbox(name);
  }

  onClearAll() {
    this.props.filters.clear();
  }

  renderFilter({ name, text, title, icon, disabled }) {
    const { filters } = this.props;
    const checked = !filters.getFilter(name).isActive();
    const checkbox = (
      <Checkbox
        disabled={disabled}
        checked={!disabled && checked}
        onChange={() => this.onToggle(name)}
      >
        {text}
      </Checkbox>
    );

    return (
      <div className="FilterMain__filter">
        {disabled ? (
          <div className="FilterMain__tooltip">{checkbox}</div>
        ) : (
          <Tooltip title={title} className="FilterMain__tooltip">
            {checkbox}
          </Tooltip>
        )}
      </div>
    );
  }

  render() {
    const { t, filters, showHeader, search } = this.props;

    return (
      <FilterBox
        showHeader={showHeader}
        title={t('Filters')}
        togglable={false}
        clickableText={filters.isActive() && t('reset')}
        onClick={this.onClearAll}
        noLine
      >
        <div className="FilterMain">
          {this.renderFilter({
            name: 'selfConnect',
            text: 'Self-Connect',
            title: t('title_multiticket'),
            disabled: false,
          })}
          {this.renderFilter({
            name: 'overNight',
            text: t('overnight'),
            title: t('title_overnight'),
            disabled: !search.getMeta().hasOverNight(),
          })}
          {this.renderFilter({
            name: 'redEye',
            text: t('redeye'),
            title: t('title_redeye'),
            disabled: !search.getMeta().hasRedEye(),
          })}
          {this.renderFilter({
            name: 'groundTransit',
            text: t('groundtransit'),
            title: t('title_groundtransit'),
            disabled: !search.getMeta().hasGroundTransit(),
          })}
        </div>
      </FilterBox>
    );
  }
}

FilterMain.propTypes = {
  filters: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
  search: PropTypes.object.isRequired,
};

export default FilterMain;
