import { createSelector } from 'reselect';
import mapValues from 'lodash/mapValues';
import globals from '../globals';
import formatCurrency from '../lib/formatCurrency';
import formatDate from '../lib/formatDate';
import formatDuration from '../lib/formatDuration';
import formatNumber from '../lib/formatNumber';
import t from '../lib/translate';

const KNOWN_FILTERS = {
  formatCurrency,
  formatDate,
  formatDuration,
  formatNumber,
  t,
};

export default function filtersSelector(filterNames = Object.keys(KNOWN_FILTERS)) {
  let filterFunctions = {};
  filterNames.forEach(name => {
    let func = KNOWN_FILTERS[name];
    if (!func) throw new Error(`Unknown filter name: ${name}`);
    filterFunctions[name] = func;
  });

  return createSelector(
    state => state.get('user'),
    state => state.getIn(['request', 'currencyRates']),
    (user, currencyRates) => {
      const context = {
        user,
        currencyRates,
        translations: globals('translations', user.get('language')),
        currencies: globals('currencies', user.get('language')),
      };
      return mapValues(filterFunctions, func => func.bind(null, context));
    }
  );
}
