/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');
const _find = require('lodash/find');
const config = require('./config');
const request = require('./request');
const util = require('./util');

// normalizes cvs airport codes by uppercasing and sorting airport codes
// airport codes can be compared by passing them through this function
// @example
//   normalizeAC('ltn,lhr'); // 'LHR,LTH'
//   normalizeAC('CDG,ORY') == normalizeAC('ory,cdg'); // true
const normalizeAC = function (code) {
  const codes = (code != null ? code : '').toUpperCase().split(',');
  codes.sort(function (a, b) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  });
  return codes.join(',');
};

// process the response from the picker for one item under "matches"
// returns a flat array of the "match" with it's children if it had any
var processAirport = function (airport, isChild, city) {
  let r = [];
  const children = airport.children != null ? airport.children : [];
  r.push({
    name: airport.name,
    country: airport.country,
    countryCode: airport.country_code,
    code: airport.airports.join(','),
    iata: typeof airport.i === 'string' ? airport.i : airport.i.join(','),
    isChild,
    numChildren: children.length,
    city,
    transportType: airport.transport_type,
  });
  for (let child of Array.from(children)) {
    r = r.concat(processAirport(child, true, city));
  }
  return r;
};

// process the response from service "completer_aggregate" and make
// a flat list of airports that's easier to work with
// @nodoc
const processResponse = function (airports) {
  let city;
  if (airports && airports[0] && airports[0].name) {
    city = airports[0].name;
  }
  let r = [];
  for (let airport of Array.from(airports)) {
    r = r.concat(processAirport(airport, false, city));
  }
  return r;
};

// This function can be used if we have an country name and need to access more
// info about the country.
const getCountryFromCode = function (countryCode, options) {
  options = _extend({ success() {}, error() {} }, options || {});
  return request.get('picker', {
    input: countryCode,
    limit: 1,
    language: options.language,
    success(response) {
      const countries = response.matches_countries;
      const country = _find(countries, { country_code: countryCode });
      if (country) {
        options.success(country);
        return;
      }
      // can't find the country
      return options.error(`Can't find country by code: ${countryCode}`);
    },
    error: options.error,
  });
};

// This function can be used if we have an airport code and need to access the
// name and country as the autocomplete function returns.
const getAirportFromCode = function (airportCode, options) {
  options = _extend({ success() {}, error() {} }, options || {});
  const airportCodes = airportCode.split(',');
  return request.get('picker', {
    input: airportCodes[0],
    limit: 1,
    language: options.language,
    apiVersion: options.apiVersion || 1,
    'departure-airports': options['departure-airports'],
    success(response) {
      const acnorm = normalizeAC(airportCode);
      const airports = processResponse(response.matches || []);
      for (let airport of Array.from(airports)) {
        if (
          normalizeAC(airport.code) === acnorm ||
          (airportCodes.length > 1 && acnorm.indexOf(airportCodes[0] !== -1))
        ) {
          options.success(airport);
          return;
        }
      }
      // can't find the airport
      return options.error(`Can't find airport by code: ${airportCode}`);
    },
    error: options.error,
  });
};

// Autocomplete airports by text input
// @example
//   autocomplete('lon', {
//       success: function(airports) {
//           // airports is an array of objects of the format:
//           {
//             name: [String] name of airport or city
//             country: [String] name of the where the airport is located
//             code: [String] comma separated airport codes
//             isChild: [Boolean] if this is belongs to a previous airport in the results
//             numChildren: [Integer] Number of child airports following in the results
//           }
//       }
//   });
// @param [String] input Arbitrary input to search by
// @param [Object] options
// @option options [Function] success Success callback will be called with an array of Objects in the form of
// @option options [Function] error Error callback in case the request is unsuccessful
// @option options [String] departure-airports Origin airport to get correct combination (optional)
const autocomplete = function (input, options) {
  options = _extend(
    {
      success() {},
      error() {},
      limit: 10,
    },
    options || {}
  );

  return request.get('picker', {
    input,
    'departure-airports': options['departure-airports'],
    language: options.language,
    apiVersion: options.apiVersion || 1,
    success(response) {
      const airports = processResponse(response.matches || []).slice(0, options.limit);
      const countries = response.matches_countries;
      return options.success(airports, countries);
    },
    error: options.error,
  });
};

module.exports = {
  autocomplete,
  getAirportFromCode,
  getCountryFromCode,
  normalizeAC,
  processResponse,
  processAirport,
};
