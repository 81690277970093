import moment from 'moment';

/**
 * This function executes facebook pixel event
 * @param {Object} obj
 * @param {Object<immutable.Map>} origin - Flight origin information
 * @param {Object<immutable.Map>} destination - Flight destination information
 * @param {Object<Date>} departureDate - Flight departure date
 * @param {Object<Date>} returnDate - Flight return date
 * @param {Number} adults - Number of adults
 * @param {immutable.List<immutable.Map>} childrenAges - Number of child ages
 */
function execFBPixelEvent({
  event,
  origin,
  destination,
  departureDate,
  returnDate,
  adults,
  childrenAges,
}) {
  if (window && window.fbq) {
    const obj = {
      // IF YOU DO NOT TO USE A RECOMMENDED PARAM, THEN REMOVE, DON'T LEAVE EMPTY
      // REQUIRED: DO NOT change this, must be set to 'flight'
      content_type: 'flight',

      // HIGHLY RECOMMENDED: departure date
      // Allows you to target people based on their travel dates, using a booking window
      // Improves the landing experience with travel dates filled in using template tags
      // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
      departing_departure_date: moment(departureDate).format('YYYY-MM-DD'),

      // HIGHLY RECOMMENDED: return date, in case of round trip, remove otherwise
      // use YYYYMMDD, YYYY-MM-DD, YYYY-MM-DDThh:mmTZD or YYYY-MM-DDThh:mm:ssTZD
      returning_departure_date: moment(returnDate).format('YYYY-MM-DD'),

      // REQUIRED: use official IATA code of departure airport
      origin_airport: origin.get('iata'),

      // REQUIRED: use official IATA code of destination airport
      destination_airport: destination.get('iata'),

      // RECOMMENDED: number of adults on flight
      num_adults: adults,

      // RECOMMENDED: number of children on flight
      num_children: childrenAges.toJS().length,
    };

    fbq('track', event, obj);
  }
}

export default execFBPixelEvent;
