import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@components/Button';
import styles from './CounterButton.module.less';

const {
  counterButton,
  subButton,
  addButton,
  largeButton,
  buttonDisabled,
  input,
  largeInput,
  readOnlyInput,
  minus,
  plus,
} = styles;

const CounterButton = ({
  className,
  value,
  onChange,
  readOnly,
  onClickSubtract,
  onClickAdd,
  isSubDisabled,
  isAddDisabled,
  large,
  onKeyDownAdd,
  onKeyDownDec,
}) => (
  <div className={classNames(counterButton, className)}>
    <Button
      type="button"
      tabIndex={0}
      className={classNames(subButton, {
        [buttonDisabled]: isSubDisabled,
        [largeButton]: large,
      })}
      onClick={isSubDisabled ? () => {} : onClickSubtract}
      onKeyDown={isSubDisabled ? () => {} : onKeyDownDec}
    >
      <div className={minus}>-</div>
    </Button>
    <input
      className={classNames(input, { [readOnlyInput]: readOnly, [largeInput]: large })}
      readOnly={readOnly}
      onChange={onChange}
      value={value}
      tabIndex="-1"
    />
    <Button
      type="button"
      tabIndex={0}
      className={classNames(addButton, {
        [buttonDisabled]: isAddDisabled,
        [largeButton]: large,
      })}
      onClick={isAddDisabled ? () => {} : onClickAdd}
      onKeyDown={isAddDisabled ? () => {} : onKeyDownAdd}
    >
      <div className={plus}> +</div>
    </Button>
  </div>
);

CounterButton.defaultProps = {
  className: null,
  readOnly: true,
  onChange: null,
  isSubDisabled: false,
  isAddDisabled: false,
  large: false,
  onKeyDownAdd: () => {},
  onKeyDownDec: () => {},
};

CounterButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onClickSubtract: PropTypes.func.isRequired,
  onKeyDownAdd: PropTypes.func,
  onKeyDownDec: PropTypes.func,
  onClickAdd: PropTypes.func.isRequired,
  large: PropTypes.bool,
  isSubDisabled: PropTypes.bool,
  isAddDisabled: PropTypes.bool,
};

export default CounterButton;
