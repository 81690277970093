import config from './config';
import bugsnag from '@bugsnag/js';

const { VERSION, BUGSNAG_API_KEY } = config;
export const BUGSNAG_CONFIG = {
  apiKey: BUGSNAG_API_KEY,
  appVersion: VERSION,
  releaseStage: process.env.NODE_ENV || 'development',
  notifyReleaseStages: ['production'],
  autoCaptureSessions: false,
};
const bugsnagClient = bugsnag(BUGSNAG_CONFIG);

if (process.env.IS_BROWSER) {
  window.Bugsnag = bugsnagClient;
}

export default bugsnagClient;
