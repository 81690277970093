import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './CollapseWithAnimation.less';

class CollapseWithAnimation extends Component {
  content = React.createRef();

  componentDidMount() {
    const { isCollapsed } = this.props;

    if (isCollapsed) {
      this.collapseSection();
    }
  }

  componentDidUpdate() {
    const { isCollapsed } = this.props;

    // To make sure the wrapper fit its content.
    if (!isCollapsed) {
      this.expandSection();
    }
  }

  /**
   * Called when componend is collapsed.
   * Updates the height to 0.
   */
  collapseSection() {
    const content = this.content.current;
    content.style.height = 0 + 'px';
  }

  /**
   * Called when componend is expanded.
   * Updates the height to fit its content.
   */
  expandSection() {
    const content = this.content.current;
    const sectionHeight = content.scrollHeight;
    content.style.height = sectionHeight + 'px';
  }

  /**
   * Called when header is clicked.
   * Clicking the header expands or collapses the components content.
   */
  onClickHeader = () => {
    const { onClick, isCollapsed } = this.props;

    if (isCollapsed) {
      this.expandSection();
    } else {
      this.collapseSection();
    }
    onClick();
  };

  render() {
    const { className, renderHeader, children } = this.props;

    return (
      <div className={classNames(className)}>
        <div onClick={this.onClickHeader}>{renderHeader()}</div>
        <div ref={this.content} className="CollapseWithAnimation__content">
          {children}
        </div>
      </div>
    );
  }
}

CollapseWithAnimation.defaultProps = {
  children: '',
  className: '',
  onClick: () => {},
  isCollapsed: true,
};

CollapseWithAnimation.propTypes = {
  renderHeader: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CollapseWithAnimation;
