import React, { Component } from 'react';
import { connect } from 'react-redux';
import './GoShareDestination.less';

class GoShareDestination extends Component {
  onMessengerShare() {
    const url =
      'http://www.facebook.com/dialog/send?app_id=221202224687280' +
      `&link=${this.getUrl()}&redirect_uri=${this.getUrl()}`;
    const win = window.open(url, '_blank');
    win.focus();
  }

  onFBShare() {
    const url =
      'http://www.facebook.com/dialog/share?app_id=221202224687280' +
      `&display=popup&href=${this.getUrl()}&redirect_uri=${this.getUrl()}`;
    window.open(url, '_blank', 'width=555, height=624');
  }

  onTwitterShare() {
    const { t } = this.props;
    let twitterLink = 'https://twitter.com/intent/tweet?';
    twitterLink += `text=${t('found_great_flight_to', { destination: this.props.destination })}`;
    twitterLink += '&via=dohoptravel';
    twitterLink += `&url=${this.getUrl()}`;
    window.open(twitterLink, '_blank', 'width=500, height=460');
  }

  getUrl() {
    return encodeURIComponent(window.location.href.replace('localhost:4000', 'dohop.com'));
  }

  render() {
    return (
      <div className="GoShareDestination">
        <div className="GoShareDestination__container">
          <div
            onClick={this.onMessengerShare.bind(this)}
            className="GoShareDestination__icon GoShareDestination__icon--messenger"
          />
          <div
            onClick={this.onFBShare.bind(this)}
            className="GoShareDestination__icon GoShareDestination__icon--fb"
          />
          <div
            onClick={this.onTwitterShare.bind(this)}
            className="GoShareDestination__icon GoShareDestination__icon--twitter"
          />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  destination: state.getIn(['go', 'destination', 'name']),
}))(GoShareDestination);
