import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MatchMedia from '../MatchMedia';
import Autocomplete from './Autocomplete';
import SearchInput from '../frontpage/SearchForm/SearchInput';
import Location from '@content/svg/location.svg';
import MobileAutocomplete from './MobileAutocomplete';

class ResponsiveAutocomplete extends Component {
  state = { mobileOpen: false };

  mobileInput = React.createRef();

  /**
   * Opens the mobile autocomplete.
   */
  onOpenMobile = e => {
    const { onFocus } = this.props;
    this.setState({ mobileOpen: true });

    if (onFocus) {
      onFocus();
    }
  };

  /**
   * Closes the mobile autocomplete.
   */
  onCloseMobile = e => {
    this.setState({ mobileOpen: false });
  };

  /**
   * Selects an value in the mobile autocomplete.
   */
  onSelect = async (...args) => {
    const { onSelect } = this.props;
    this.setState({ mobileOpen: false });
    await onSelect(...args);
  };

  render() {
    const {
      t,
      className,
      breakpoint,
      source,
      placeholder,
      extraResult,
      inputClassName,
      iconClassName,
      value,
      autoFocus,
      onSelect,
      id,
      label,
      onFocus,
      showLabel,
      shouldPopOpen,
      recentSearches,
      error,
    } = this.props;
    const { mobileOpen } = this.state;

    return (
      <div className={className}>
        <MatchMedia
          className={classNames('ResponsiveAutocomplete__mobile', {
            'ResponsiveAutocomplete__mobile--focus': mobileOpen,
          })}
          max={breakpoint}
        >
          {mobileOpen && (
            <MobileAutocomplete
              t={t}
              placeholder={placeholder}
              onSelect={this.onSelect}
              onClose={this.onCloseMobile}
              extraResult={extraResult}
              id={id}
              onFocus={onFocus}
              source={source}
              recentSearches={recentSearches}
              onSelectRecentSearch={this.onCloseMobile}
              error={error}
              inputClassName={inputClassName}
            />
          )}
          <SearchInput
            id={id}
            ref={this.mobileInput}
            readOnly
            placeholder={placeholder}
            value={value}
            onFocus={this.onOpenMobile}
            error={error}
            inputClassName={inputClassName}
            icon={cn => <Location className={cn} />}
          />
        </MatchMedia>

        <MatchMedia min={breakpoint}>
          <Autocomplete
            t={t}
            source={source}
            value={value}
            showLabel={showLabel}
            autoFocus={autoFocus}
            shouldPopOpen={shouldPopOpen}
            placeholder={placeholder}
            onSelect={onSelect}
            iconClassName={iconClassName}
            inputClassName={inputClassName}
            id={id}
            label={label}
            extraResult={extraResult}
            onFocus={onFocus}
            recentSearches={recentSearches}
            error={error}
          />
        </MatchMedia>
      </div>
    );
  }
}

ResponsiveAutocomplete.defaultProps = {
  placeholder: '',
  extraResult: null,
  className: null,
  iconClassName: null,
  inputClassName: null,
  autoFocus: false,
  id: null,
  label: '',
  showLabel: false,
  shouldPopOpen: false,
  breakpoint: 'ipad',
  onFocus: null,
  recentSearches: null,
  error: false,
};

ResponsiveAutocomplete.propTypes = {
  className: PropTypes.string,
  source: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  extraResult: PropTypes.object,
  iconClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  autoFocus: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  shouldPopOpen: PropTypes.bool,
  breakpoint: PropTypes.string,
  inputClassName: PropTypes.string,
  onFocus: PropTypes.func,
  recentSearches: PropTypes.array,
  error: PropTypes.bool,
};

export default ResponsiveAutocomplete;
