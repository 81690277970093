import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FilterMain from './FilterMain';
import FilterStops from './FilterStops';
import FilterCheckboxList from './FilterCheckboxList';
import FilterAirportList from './FilterAirportList';
import FilterConnectionTime from './FilterConnectionTime';
import FilterBox from './FilterBox';
import FilterDateSlider from './FilterDateSlider';
import NewResultsPopup from '../newResultsPopup';
import createFilters from '../../lib/createFilters';
import './Filters.less';

const FILTERS = {
  outbound: {
    departure: 'outboundDeparture',
    arrival: 'outboundArrival',
  },
  homebound: {
    departure: 'homeboundDeparture',
    arrival: 'homeboundArrival',
  },
};
class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewResultsPopup: false,
    };
    this.onChange = () => this.forceUpdate();
    this.isFilterOn = this.isFilterOn.bind(this);
    this.onHoverSlider = this.onHoverSlider.bind(this);
    this.setNewResultsPopup = this.setNewResultsPopup.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    search.on('change', this.onChange);
    this.getFilters()
      .getEvents()
      .on('filter-change', this.onChange);
  }

  componentWillUnmount() {
    const { search } = this.props;
    search.removeListener('change', this.onChange);
    this.getFilters()
      .getEvents()
      .removeListener('filter-change', this.onChange);
  }

  onHoverSlider(filterName, isHovering) {
    // TODO: this.props.searchActions.setHoveringSlider(filterName, isHovering);
  }

  setNewResultsPopup(bool) {
    this.setState({ showNewResultsPopup: bool });
  }

  getFilters() {
    const { search } = this.props;
    return search.getFilters();
  }

  getName(place) {
    if (!place) return '';
    return `${place.get('name')} (${place.get('iata')})`;
  }

  isFilterOn(name) {
    const filters = this.getFilters();
    const f = filters.getFilter(name);

    if (Object.values(f._state).length > 0) {
      return true;
    }
    return false;
  }

  render() {
    const {
      t,
      formatDuration,
      formatDate,
      search,
      user,
      origin,
      destination,
      location,
      mobile,
    } = this.props;
    const { showNewResultsPopup } = this.state;
    const filters = this.getFilters();
    const props = { t, formatDuration, formatDate, search, filters, user, location, mobile };
    const airlineOptions = search
      .getMeta()
      .getAirlines()
      .map(a => ({ name: a.getName(), code: a.getCode() }));

    const vendorOptions = search
      .getMeta()
      .getVendors()
      .map(v => ({ name: v.getName(), code: v.getKey() }));

    const aircraftOptions = search
      .getMeta()
      .getAircrafts()
      .map(a => ({ name: a.getName(), code: a.getCode() }));

    return (
      <div className="Filters">
        <FilterMain {...props} showHeader={!mobile} />
        <FilterStops {...props} initialOpen={!mobile} />
        <FilterConnectionTime {...props} initialOpen={!mobile} />
        <FilterBox
          title={t('takeoff')}
          initialOpen={!mobile}
          isFilterOn={
            mobile &&
            (this.isFilterOn(FILTERS.outbound.departure) ||
              this.isFilterOn(FILTERS.homebound.departure))
          }
        >
          <FilterDateSlider
            {...props}
            title={this.getName(origin)}
            filterName={FILTERS.outbound.departure}
            onMouseOver={this.onHoverSlider(this, FILTERS.outbound.departure, true)}
            onMouseOut={this.onHoverSlider(this, FILTERS.outbound.departure, false)}
          />
          {!search.getMeta().isOneWay() && (
            <FilterDateSlider
              {...props}
              title={this.getName(destination)}
              filterName={FILTERS.homebound.departure}
              onMouseOver={this.onHoverSlider(this, FILTERS.homebound.departure, true)}
              onMouseOut={this.onHoverSlider(this, FILTERS.homebound.departure, false)}
            />
          )}
        </FilterBox>
        <FilterBox
          title={t('landing')}
          initialOpen={false}
          isFilterOn={
            mobile &&
            (this.isFilterOn(FILTERS.outbound.arrival) ||
              this.isFilterOn(FILTERS.homebound.arrival))
          }
        >
          <FilterDateSlider
            {...props}
            title={this.getName(destination)}
            filterName={FILTERS.outbound.arrival}
            onMouseOver={this.onHoverSlider(this, FILTERS.outbound.departure, true)}
            onMouseOut={this.onHoverSlider(this, FILTERS.outbound.departure, false)}
          />
          {!search.getMeta().isOneWay() && (
            <FilterDateSlider
              {...props}
              title={this.getName(origin)}
              filterName={FILTERS.homebound.arrival}
              onMouseOver={this.onHoverSlider(this, FILTERS.homebound.departure, true)}
              onMouseOut={this.onHoverSlider(this, FILTERS.homebound.departure, false)}
            />
          )}
        </FilterBox>
        <FilterCheckboxList
          {...props}
          title={t('vendors')}
          options={vendorOptions}
          showOnly
          filterName="vendors"
          setNewResultsPopup={this.setNewResultsPopup}
        />
        <FilterCheckboxList
          {...props}
          title={t('airlines')}
          options={airlineOptions}
          showOnly
          filterName="airlines"
          setNewResultsPopup={this.setNewResultsPopup}
        />
        <FilterAirportList
          {...props}
          title={t('airports')}
          origin={origin}
          destination={destination}
          filterName="airports"
          setNewResultsPopup={this.setNewResultsPopup}
        />
        <FilterCheckboxList
          {...props}
          title={t('aircrafts')}
          options={aircraftOptions}
          showOnly
          filterName="aircrafts"
          setNewResultsPopup={this.setNewResultsPopup}
        />
        {/* Fade: Fades element in/out based on opacitiy */}
        <div
          className={classNames({
            FilterBox__hidden: !showNewResultsPopup,
          })}
        >
          {showNewResultsPopup && (
            <NewResultsPopup
              title={t('new_results_popup_title')}
              txt={t('new_results_popup_txt')}
              btn={t('new_results_popup_got_it')}
              setNewResultsPopup={this.setNewResultsPopup}
            />
          )}
        </div>
      </div>
    );
  }
}

Filters.propTypes = {
  mobile: PropTypes.bool,
};

Filters.defaultProps = {
  mobile: false,
};

Filters = connect(state => ({
  origin: state.getIn(['flightSearch', 'origin']),
  destination: state.getIn(['flightSearch', 'destination']),
  user: state.get('user'),
}))(Filters);

Filters = createFilters(['t', 'formatDuration', 'formatDate'])(Filters);

export default Filters;
