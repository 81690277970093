import React, { Component } from 'react';
import BigLogoHeader from '../header/BigLogoHeader';
import MatchMedia from '../MatchMedia';

class LandingHeader extends Component {
  renderHeading() {
    const { t, price, formatCurrency, flightSearchForm, params } = this.props;
    const formattedPrice = price ? formatCurrency(price) : '';
    const from = flightSearchForm.getIn(['origin', 'name']);
    const to = flightSearchForm.getIn(['destination', 'name']);

    return (
      <div className="City__header">
        <MatchMedia max="ipad">
          <div>
            <h1 className="City__headerCity">
              {!params.from && !price && Boolean(to) && `${t('landing_to')} `}
              {params.from ? [from, to].filter(value => Boolean(value)).join(' - ') : to || from}
            </h1>
            {price && (
              <h2 className="City__subtitle">
                {[t('landing_flights_from'), formattedPrice].join(' ')}
              </h2>
            )}
          </div>
        </MatchMedia>
        <MatchMedia min="ipad">
          <h1 className="City__headerCity">
            {params.from
              ? [
                  t('landing_flights_from'),
                  from,
                  to && t('to').toLowerCase(),
                  to,
                  formattedPrice && t('landing_from'),
                  formattedPrice,
                ]
                  .filter(value => Boolean(value))
                  .join(' ')
              : [
                  (to && t('landing_to')) || t('landing_flights_from'),
                  to || from,
                  formattedPrice && t('landing_from'),
                  formattedPrice,
                ]
                  .filter(value => Boolean(value))
                  .join(' ')}
          </h1>
        </MatchMedia>
      </div>
    );
  }

  render() {
    return (
      <div>
        <BigLogoHeader tab="flights" showBarOnly />
        <div className="City__logo--container">
          <a href="/" className="City__logo" />
          {this.renderHeading()}
        </div>
      </div>
    );
  }
}

export default LandingHeader;
