import PropTypes from 'prop-types';
import React from 'react';
import time from 'dohop-client-api/src/time';
import FilterBox from './FilterBox';
import FilterSlider from './FilterSlider';

const STEP = 5 * time.minute;

class FilterConnectionTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSliderDrag([min, max]) {
    this.setState({
      draggingMin: min,
      draggingMax: max,
    });
  }

  onSliderApply() {
    const { filters } = this.props;
    const { draggingMin, draggingMax } = this.state;

    const filterState = { min: draggingMin, max: draggingMax };
    filters.set('connectionTime', filterState);
    this.setState({ draggingMin: undefined, draggingMax: undefined });
  }

  render() {
    const { t, formatDuration, filters, initialOpen, mobile } = this.props;
    const { draggingMin, draggingMax } = this.state;

    const filter = filters.getFilter('connectionTime');
    if (!filter.getBounds().min || !filter.getBounds().max) return false;

    const minAllowed = Math.floor(filter.getBounds().min / STEP) * STEP;
    const maxAllowed = Math.ceil(filter.getBounds().max / STEP) * STEP;
    const min = draggingMin || filter.getMin() || minAllowed;
    const max = draggingMax || filter.getMax() || maxAllowed;
    const isFilterOn = Object.values(filter._state).length > 0;
    return (
      <FilterBox
        title={t('stoptimecaption')}
        initialOpen={initialOpen}
        isFilterOn={mobile && isFilterOn}
      >
        <FilterSlider
          value={[min, max]}
          hint={[min, max].map(formatDuration).join(' - ')}
          step={STEP}
          min={minAllowed}
          max={maxAllowed}
          onChange={this.onSliderDrag.bind(this)}
          onAfterChange={this.onSliderApply.bind(this)}
        />
      </FilterBox>
    );
  }
}

FilterConnectionTime.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default FilterConnectionTime;
