import React from 'react';
import classNames from 'classnames';
import './ItinerarySeparator.less';

const ItinerarySeparator = ({ detailsOpen }) => (
  <div className="ItinerarySeparator">
    <div className="ItinerarySeparator__line" />
    <div
      className={classNames('ItinerarySeparator__firstBite', {
        'ItinerarySeparator__firstBite--detailsOpen': detailsOpen,
      })}
    />
    <div
      className={classNames('ItinerarySeparator__secondBite', {
        'ItinerarySeparator__secondBite--detailsOpen': detailsOpen,
      })}
    />
  </div>
);

export default ItinerarySeparator;
