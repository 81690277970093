import Immutable from 'immutable';
import {
  TRANSFER_RESUME_SUCCESS,
  TRANSFER_INITIALIZE_SUCCESS,
  TRANSFER_INITIALIZE_FAILED,
} from '../actions/transferActions';

export default function transferReducer(state = Immutable.Map(), action) {
  switch (action.type) {
    case TRANSFER_INITIALIZE_SUCCESS:
      return state.set('vendor', action.vendor).set('transferState', action.transferState);
    case TRANSFER_INITIALIZE_FAILED:
      state = state.merge({
        vendor: action.vendor,
        error: action.error,
        notFound: !action.vendor,
      });
      if (action.vendor && action.vendor.get('website')) {
        // recover by creating a deeplink to vendor website of same format as returned by the api
        state = state.set(
          'deeplink',
          Immutable.Map({
            isFallback: true,
            url: 'http://' + action.vendor.get('website'),
          })
        );
      }
      return state;
    case TRANSFER_RESUME_SUCCESS:
      return state.set('deeplink', action.deeplink);
    default:
      return state;
  }
}
