import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ENTER } from '@lib/keyCodes';
import Button from '../../../Button';
import './OneWayOrRoundTrip.less';

class OneWayOrRoundTrip extends Component {
  /**
   * Handles changes on the isOneWay value in redux state.
   * @param {Boolean} oneWay - defines whether we are searching for one way trip or not.
   */
  onChangeIsOneWay = oneWay => {
    const { setIsOneWay } = this.props;
    setIsOneWay(oneWay);
  };

  /**
   * Handles changes on the isOneWay value in redux state if user presses ENTER.
   * @param {Object} e - onKeyDown event handler
   * @param {Boolean} oneWay - defines whether we are searching for one way trip or not.
   */
  onKeyPressHandler = (e, oneWay) => {
    const { setIsOneWay } = this.props;
    if (e.keyCode === ENTER) {
      e.preventDefault();
      setIsOneWay(oneWay);
    }
  };

  renderTabs() {
    const { t, ocean, isOneWay } = this.props;

    return (
      <div className="OneWayOrRoundTrip--tabs">
        <div
          className={classNames('OneWayOrRoundTrip__linkButton', 'OneWayOrRoundTrip__roundTrip', {
            'OneWayOrRoundTrip__linkButton--active': !isOneWay,
            'OneWayOrRoundTrip__linkButton--ocean': ocean,
          })}
          role="button"
          tabIndex={0}
          onClick={() => this.onChangeIsOneWay(false)}
          onKeyDown={e => this.onKeyPressHandler(e, false)}
        >
          {t('roundtrip')}
        </div>
        <div
          className={classNames('OneWayOrRoundTrip__linkButton', 'OneWayOrRoundTrip__oneWay', {
            'OneWayOrRoundTrip__linkButton--active': isOneWay,
            'OneWayOrRoundTrip__linkButton--ocean': ocean,
            'OneWayOrRoundTrip__linkButton--ocean--active': ocean && isOneWay,
          })}
          role="button"
          tabIndex={0}
          onClick={() => this.onChangeIsOneWay(true)}
          onKeyDown={e => this.onKeyPressHandler(e, true)}
        >
          {t('noreturndate')}
        </div>
      </div>
    );
  }

  renderButton() {
    const { t, isOneWay } = this.props;
    return (
      <Button
        className="OneWayOrRoundTrip__button"
        onClick={() => this.onChangeIsOneWay(!isOneWay)}
      >
        {isOneWay ? t('roundtrip') : t('noreturndate')}
      </Button>
    );
  }

  render() {
    const { showAsButton, className } = this.props;
    return (
      <div className={classNames('OneWayOrRoundTrip', className)}>
        {showAsButton ? this.renderButton() : this.renderTabs()}
      </div>
    );
  }
}

OneWayOrRoundTrip.defaultProps = { ocean: false, isDisabled: false };

OneWayOrRoundTrip.propTypes = {
  t: PropTypes.func.isRequired,
  isOneWay: PropTypes.bool.isRequired,
  setIsOneWay: PropTypes.func.isRequired,
  ocean: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default OneWayOrRoundTrip;
