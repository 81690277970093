import { Map } from 'immutable';
import { HYDRATE } from '../actions/requestActions';
import {
  FLIGHT_SEARCH_RESET,
  FLIGHT_SEARCH_SET_SEARCH,
  FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME,
  FLIGHT_SEARCH_FLEXIBLE_UPDATE,
  FLIGHT_SEARCH_LOAD_LINK_STARTING,
  FLIGHT_SEARCH_LOAD_LINK_SUCCESS,
  FLIGHT_SEARCH_LOAD_LINK_FAILED,
  FLIGHT_SEARCH_GET_CITY_DATA,
  FLIGHT_SEARCH_CHEAPEST_ITINERARY,
  FLIGHT_SEARCH_RECOMMENDED_ITINERARIES,
  FLIGHT_SEARCH_VENDORS,
} from '../actions/flightSearchActions';

const INITIAL_STATE = Map({
  itineraryURLs: Map(),
  hoveringSlider: false,
  vendors: Map(),
});

export default function flightSearchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FLIGHT_SEARCH_RESET:
      return INITIAL_STATE.set('vendors', state.get('vendors'));
    case FLIGHT_SEARCH_LOAD_LINK_STARTING:
      return state.setIn(['itineraryURLs', action.key], Map({ loading: true }));
    case FLIGHT_SEARCH_LOAD_LINK_SUCCESS:
    case FLIGHT_SEARCH_LOAD_LINK_FAILED:
      return state.setIn(['itineraryURLs', action.key], Map({ loading: false, url: action.url }));
    case FLIGHT_SEARCH_SET_SEARCH:
      return state.set('search', action.search);
    case FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME:
      return state.set('progressbarStartTime', action.time);
    case FLIGHT_SEARCH_FLEXIBLE_UPDATE:
      return state.set('flexible', action.fares);
    case FLIGHT_SEARCH_GET_CITY_DATA:
      return state.merge(action);
    case FLIGHT_SEARCH_CHEAPEST_ITINERARY:
      return state.set('cheapestItinerary', action.itinerary);
    case FLIGHT_SEARCH_RECOMMENDED_ITINERARIES:
      return state.set('recommendedItineraries', action.itineraries);
    case FLIGHT_SEARCH_VENDORS:
      return state.mergeDeep({ vendors: action.vendors });
    case HYDRATE:
      // don't send search object to the client
      if (state.get('search')) state = state.set('search', false);
      return state;
    default:
      return state;
  }
}
