import time from 'dohop-client-api/src/time';
import axios from 'axios';
import once from 'lodash/once';
import fromPairs from 'lodash/fromPairs';
import { List } from 'immutable';
import { FareChecker, MultipleFareChecker } from 'dohop-client-api/src/helpers';
import { decodeURLQueryData } from 'shared/utils/transformURLQueryData';
import { getRecaptchaToken } from 'shared/utils/recaptcha';
import config from '../lib/config';
import processException from '../lib/processException';
import { createAction } from '../lib/redux/actions';
import { trackGoPriceDiff, trackNoSearchResults } from '../lib/tracking';
import { FlightSearch } from 'dohop-client-api/src/search';
import { populateFromParams } from './flightSearchFormActions';
import { fetchDateMatrix } from './dateMatrixActions';
import { addSearchResultToGo } from './goActions';
import axiosWrapper from '../lib/axiosWrapper';

export const PROGRESSBAR_DURATION = 20 * time.second;
export const FLIGHT_SEARCH_RESET = 'FLIGHT_SEARCH_RESET';
export const FLIGHT_SEARCH_SET_SEARCH = 'FLIGHT_SEARCH_SET_SEARCH';
export const FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME = 'FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME';
export const FLIGHT_SEARCH_FLEXIBLE_UPDATE = 'FLIGHT_SEARCH_FLEXIBLE_UPDATE';
export const FLIGHT_SEARCH_LOAD_LINK_STARTING = 'FLIGHT_SEARCH_LOAD_LINK_STARTING';
export const FLIGHT_SEARCH_LOAD_LINK_SUCCESS = 'FLIGHT_SEARCH_LOAD_LINK_SUCCESS';
export const FLIGHT_SEARCH_LOAD_LINK_FAILED = 'FLIGHT_SEARCH_LOAD_LINK_FAILED';
export const FLIGHT_SEARCH_HOVER_SLIDER = 'FLIGHT_SEARCH_HOVER_SLIDER';
export const FLIGHT_SEARCH_GET_CITY_DATA = 'FLIGHT_SEARCH_GET_CITY_DATA';
export const FLIGHT_SEARCH_CHEAPEST_ITINERARY = 'FLIGHT_SEARCH_CHEAPEST_ITINERARY';
export const FLIGHT_SEARCH_RECOMMENDED_ITINERARIES = 'FLIGHT_SEARCH_RECOMMENDED_ITINERARIES';
export const FLIGHT_SEARCH_VENDORS = 'FLIGHT_SEARCH_VENDORS';

export const setProgressbarStartTime = createAction(
  FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME,
  'time'
);
export const reset = createAction(FLIGHT_SEARCH_RESET);
export const setSearch = createAction(FLIGHT_SEARCH_SET_SEARCH, 'search');
export const loadLinkStarting = createAction(FLIGHT_SEARCH_LOAD_LINK_STARTING, 'key');
export const loadLinkSuccess = createAction(FLIGHT_SEARCH_LOAD_LINK_SUCCESS, 'key', 'url');
export const loadLinkFailed = createAction(FLIGHT_SEARCH_LOAD_LINK_FAILED, 'key', 'url', 'error');
export const hoverSlider = createAction(FLIGHT_SEARCH_HOVER_SLIDER, 'sliderName');
export const receiveAirports = createAction(FLIGHT_SEARCH_GET_CITY_DATA, 'origin', 'destination');
export const receiveCheapestItinerary = createAction(FLIGHT_SEARCH_CHEAPEST_ITINERARY, 'itinerary');
export const setVendors = createAction(FLIGHT_SEARCH_VENDORS, 'vendors');

const checkForRecommended = false; // Lets hide it for a while.

const MIN_FARES_NUMBER = 3;

export function closeProgressbar() {
  return {
    type: FLIGHT_SEARCH_SET_PROGRESSBAR_START_TIME,
    time: Date.now() - PROGRESSBAR_DURATION,
  };
}

export function fetchItineraryURL(itinerary) {
  return (dispatch, getState) => {
    if (getState().hasIn(['flightSearch', 'itineraryURLs', itinerary.getKey()])) return;

    dispatch(loadLinkStarting(itinerary.getKey()));
    let longURL = location.href.split('#')[0].split('?')[0] + '?i=' + itinerary.getKey();
    axios
      .post('/api/shorten-url/', { url: longURL })
      .then(
        (res) => dispatch(loadLinkSuccess(itinerary.getKey(), res.data.url)),
        (error) => dispatch(loadLinkFailed(itinerary.getKey(), longURL, error))
      )
      .catch(processException);
  };
}

function countValidOneWayRoutes(oneWay, routes, filter) {
  let n = 0;
  for (let routeId of Object.keys(oneWay)) {
    if (!filter || filter(routes[routeId])) n++;
  }
  return n;
}

function hasNResultsWithFares(search, nMin, filter = undefined) {
  let fs = search.faresort.update();
  let nValidOut = countValidOneWayRoutes(fs['1-way-out'], search.outbound, filter);
  if (search.getMeta().isOneWay()) return nValidOut >= nMin;
  let n = nValidOut * countValidOneWayRoutes(fs['1-way-home'], search.homebound, filter);
  if (n >= nMin) return true;
  for (let rIdOut of Object.keys(fs.rt)) {
    if (filter && !filter(search.outbound[rIdOut])) continue;
    for (let rIdHome of Object.keys(fs.rt[rIdOut])) {
      if (filter && !filter(search.homebound[rIdHome])) continue;
      if (fs['1-way-out'][rIdOut] && fs['1-way-home'][rIdHome]) continue;
      if (++n >= nMin) return true;
    }
  }
  return false;
}

function checkGroundTransit(search) {
  function check() {
    // uncheck ground-transit if we a few results with fares that are not ground-transit
    if (hasNResultsWithFares(search, MIN_FARES_NUMBER, (r) => !r.isGroundTransit())) {
      search.removeListener('change', check);
      search.getFilters().set('groundTransit', false);
    }
  }
  search.on('change', check);
  check();
}

function checkMainFilters(search) {
  function check() {
    let queries = decodeURLQueryData(location.search);
    let checkedFilters = ['selfConnect', 'overNight', 'redEye', 'groundTransit', 'noFare'];
    // After we have some results we check if we can apply the filters and then
    // unregister the change event
    if (hasNResultsWithFares(search, MIN_FARES_NUMBER)) {
      // Clone the filters so we only trigger filter change once
      let filters = search.getFilters().clone();
      for (let filter of checkedFilters) {
        if (filter in queries) {
          // If it isn't set to true we treat it as false
          let filterValue = queries[filter] === 'true';
          filters.set(filter, filterValue);
        }
        search.removeListener('change', check);
        search.getFilters().setState(filters.getState());
      }
      if ('stops' in queries) checkStops(search, queries.stops);
    }
  }
  search.on('change', check);
  // force the first check
  check();
}

function checkStops(search, numStop) {
  numStop = parseInt(numStop, 10);
  if (!isNaN(numStop)) {
    let stops = { 0: numStop >= 0, 1: numStop >= 1, 2: numStop >= 2 };
    let meta = search.getMeta();
    if (meta.hasStopCount(numStop)) {
      search.getFilters().set('stops', stops);
    }
  }
}

function checkHasFares(search, next) {
  function check() {
    // uncheck "no fares" filter if we have at least a few results with fares
    if (hasNResultsWithFares(search, MIN_FARES_NUMBER)) {
      search.events.removeListener('change', check);
      search.getFilters().set('noFare', false);
      next();
    }
  }
  search.on('change', check);
}

function shouldShowRecommended(cheapest, cheapestRecommended) {
  if (!cheapest || !cheapestRecommended) return false;
  let minPriceDiff = 40;
  let minPriceDiffPercentage = 0.2;
  let priceDiff =
    cheapestRecommended.getFares().getBestFare().getTotal() -
    cheapest.getFares().getBestFare().getTotal();
  return (
    priceDiff < minPriceDiff ||
    priceDiff / cheapest.getFares().getBestFare().getTotal() < minPriceDiffPercentage
  );
}

function checkRecommendedItineraries(search, dispatch, getState) {
  search.on(['change', 'sort'], () => {
    const recommendedVendors = [1266, 1272, 1201];
    const maxResults = 50;
    let itineraries = new MultipleFareChecker(search)
      .getItineraries((f) => {
        return f.set(
          'vendors',
          Object.assign(
            fromPairs(
              search
                .getMeta()
                .getVendors()
                .map((v) => [v.getKey(), false])
            ),
            fromPairs(recommendedVendors.map((v) => [v, true]))
          )
        );
      })
      .getRange(0, maxResults);
    let checker = new FareChecker(search);
    checker.filters.clear();
    checker.filters.set('groundTransit', false);
    let cheapest = checker.checkItinerary();
    let cheapestRecommended = checker.checkItinerary((f) =>
      f.set(
        'vendors',
        Object.assign(
          fromPairs(
            search
              .getMeta()
              .getVendors()
              .map((v) => [v.getKey(), false])
          ),
          fromPairs(recommendedVendors.map((v) => [v, true]))
        )
      )
    );
    itineraries = shouldShowRecommended(cheapest, cheapestRecommended) ? List(itineraries) : List();
    dispatch({ type: FLIGHT_SEARCH_RECOMMENDED_ITINERARIES, itineraries });
  });
}

function trackCheapestItinerary(search, dispatch, getState) {
  const itinerary = new FareChecker(search).checkItinerary((f) => f.clear());
  if (!itinerary) return;
  let cheapest = getState().getIn(['flightSearch', 'cheapestItinerary']);
  if (
    !cheapest ||
    itinerary.getFares().getBestFare().getTotal() < cheapest.getFares().getBestFare().getTotal()
  ) {
    itinerary.toJSON = () => ({}); // same issue as for search object below
    dispatch(receiveCheapestItinerary(itinerary));
    dispatch(addSearchResultToGo(itinerary));
  }
}

function fetchVendorInfo(vendors) {
  return (dispatch, getState) => {
    const checkedVendors = getState().getIn(['flightSearch', 'vendors']);
    let vendorsToGet = vendors;
    if (checkedVendors) {
      vendorsToGet = vendors.filter((v) => !checkedVendors.get(v));
    }
    if (vendorsToGet.length > 0) {
      const url = `${config.SEARCH_API}/api/v2/vendor/${vendorsToGet.join(',')}`;
      axiosWrapper
        .get(url, { noPrefix: true })
        .then((res) => dispatch(setVendors(res.data)))
        .catch(processException);
    }
  };
}

function trackSearchChanges(search, dispatch, getState) {
  /**
   * Default ranking used should be rank (also known as best)
   */
  search.sort([{ by: 'rank' }]);
  trackCheapestItinerary(search, dispatch, getState);
  dispatch(fetchVendorInfo(Object.keys(search.vendors)));
}

export function initializeFlightSearch(params) {
  return (dispatch, getState) => {
    getRecaptchaToken(async (token) => {
      if (token) {
        params.token = token;
      }

      // make sure to clean up event listeners from previous search
      const prevSearch = getState().getIn(['flightSearch', 'search']);
      if (prevSearch) prevSearch.close();
      dispatch(reset());

      // create the flight search object
      dispatch(initializeFlightSearchObject(params));

      // fetch flexible date matrix data
      const dateMatrixRequest = dispatch(fetchDateMatrix());

      // sync search form with search params
      const { origin, destination, departureDate, returnDate, adults, childrenAges } = params;
      await dispatch(
        populateFromParams(origin, destination, departureDate, returnDate, adults, childrenAges)
      );

      // the form has been synced, lets persist the airports so we can display correct
      // origin/destination for the current search even if user makes changes to the form
      const form = getState().getIn(['flightSearchForm']);
      dispatch(receiveAirports(form.get('origin'), form.get('destination')));

      await dateMatrixRequest;
    });
  };
}

export function initializeFlightSearchObject(params, queries) {
  return (dispatch, getState) => {
    const search = dispatch(createFlightSearch(params, queries));

    // nothing more to do server-side
    if (!process.env.IS_BROWSER) return;
    // register events
    search.on('change', () => trackSearchChanges(search, dispatch, getState));
    if (checkForRecommended) checkRecommendedItineraries(search, dispatch, getState);
    checkHasFares(search, () => checkGroundTransit(search));
    checkMainFilters(search);

    // when async actions return, they should do nothing if another search has been made
    const hasSearchChanged = () => getState().getIn(['flightSearch', 'search']) !== search;

    // when progressbar times out or search stops
    const onEnd = once(() => {
      if (!hasNResultsWithFares(search, 1)) {
        trackNoSearchResults();
      }
      if (hasSearchChanged()) return;
      const cheapestItinerary = getState().getIn(['flightSearch', 'cheapestItinerary']);
      if (cheapestItinerary) {
        trackGoPriceDiff(cheapestItinerary.getFares().getBestFare().getTotal());
      }
      // stops auto-resorting on search-data change
      search.syncWithChanges(false);
      // close progressbar in case this was triggered by search stopping
      dispatch(closeProgressbar());
    });

    // init progressbar when search starts
    search.getEvents().once('change', () => {
      if (hasSearchChanged()) return;
      dispatch(setProgressbarStartTime(Date.now()));
      // initialize timer when progressbar should stop
      setTimeout(onEnd, PROGRESSBAR_DURATION);
    });

    // stop progressbar if the search finishes early
    search.on('end', onEnd);
  };
}

function createFlightSearch(params, queries = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const urlQuery = decodeURLQueryData(location.search);
    // eslint-disable-next-line camelcase
    const { vendors } = queries;
    const prevSearch = state.getIn(['flightSearch', 'search']);

    if (prevSearch) {
      prevSearch.close();
    }

    const { origin, destination, departureDate, returnDate, childrenAges } = params;

    // our currency rates might be a tiny bit outdated - we want to make sure the library uses
    // the same currency rates as we do when we convert/format fares
    const rates = state.getIn(['request', 'currencyRates']);
    const currencies = rates.size ? rates.toObject() : undefined;
    const user = state.get('user');
    params = Object.assign(
      {
        from: origin,
        to: destination,
        d1: departureDate,
        d2: returnDate,
        language: user.get('language'),
        residency: user.get('residency'),
        youngstersAges: childrenAges,
        currencies,
        extraSearchParams: {
          affiliate_id:
            state.getIn(['request', 'affiliateID']) ||
            (state.getIn(['request', 'tabOrder', 0]) === 'hotels' ? 'dohop-hotels' : undefined),
        },
        run: process.env.IS_BROWSER === true,
      },
      params
    );
    if (vendors) {
      params.extraSearchParams['include_vendors'] = vendors;
    }
    // eslint-disable-next-line camelcase
    if (urlQuery.no_bot_ticketing) {
      params.extraSearchParams['no_bot_ticketing'] = true;
    }

    const search = new FlightSearch(params);

    // search object crashes redux-devtools because of circular references
    // https://github.com/gaearon/redux-devtools/issues/262
    search.toJSON = () => ({});

    dispatch(setSearch(search));
    return search;
  };
}

export function initializeFlightSearchFromGo(params) {
  return (dispatch, getState) => {
    // make sure to clean up event listeners from previous search
    const prevSearch = getState().getIn(['flightSearch', 'search']);
    if (prevSearch) prevSearch.close();
    dispatch(reset());
    const search = dispatch(createFlightSearch(params));
    if (!process.env.IS_BROWSER) return;
    trackCheapestItinerary(search, dispatch, getState);
  };
}
