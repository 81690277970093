import requestIp from 'request-ip';

export default function getIp(req) {
  let clientIp = requestIp.getClientIp(req);
  // set to dohops own ip for local requests
  if (!clientIp || clientIp === '127.0.0.1') {
    clientIp = '178.19.58.65';
  }
  return clientIp;
}
