import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import './CloseButton.less';

class CloseButton extends Component {
  render() {
    const { t, id, onClose, hasCloseText, className } = this.props;
    const closeText = t('close');

    return (
      <div className={classNames('CloseButton', className)} onClick={onClose}>
        {hasCloseText && (
          <div id={id} className="CloseButton__text">
            {closeText}
          </div>
        )}
        <div id={id} className="CloseButton__button" />
      </div>
    );
  }
}

CloseButton.defaultProps = {
  hasCloseText: true,
  id: null,
};

CloseButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasCloseText: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default createFilters(['t'])(CloseButton);
