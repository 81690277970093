import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../../../lib/config';
import formatAirport from '../../../../lib/formatAirport';
import Tooltip from '../../../Tooltip';
import ItineraryStop from '../ItineraryStop';
import createFilters from '../../../../lib/createFilters';
import './ItineraryDetailedRoute.less';
import MatchMedia from '../../../MatchMedia';
import AirplaneOut from '@content/icons/airplane-out.svg';
import AirplaneHome from '@content/icons/airplane-home.svg';
import DepartIcon from '@content/svg/depart.svg';
import ArriveIcon from '@content/svg/arrive.svg';

class ItineraryDetailedRoute extends Component {
  /**
   * Renders an event in the route.
   * We divide the route into events to make it easier to work with the
   * route information
   * @param {Object<Immutable.Map>} event - object with information about one event in the route.
   */
  renderEvent = event => {
    const { t } = this.props;
    switch (event.type) {
      case 'heading':
        return this.renderHeading(event);
      case 'wait':
        return <ItineraryStop event={event} key={'wait-' + event.start.getTime()} t={t} />;
      case 'flight':
        return this.renderFlight(event);
      default:
        return null;
    }
  };

  renderMobileHeading(event) {
    const { t, formatDate, formatDuration, route } = this.props;
    const duration = route.getDuration();

    return (
      <div
        className="ItineraryDetailedRoute__mobileHeading"
        key={`heading-${event.date.getTime()}`}
      >
        <div className="ItineraryDetailedRoute__mobileHeadingTitle">
          {event.isOutbound ? t('depart') : t('returndate')}: {formatDate(event.date, 'll')}
        </div>
        <div className="ItineraryDetailedRoute__mobileHeadingSubTitle">
          {t('journey_duration')}: {formatDuration(duration)}
        </div>
      </div>
    );
  }

  /**
   * Renders a route heading.
   * @param {Object} event - object with information about the heading.
   */
  renderDesktopHeading(event) {
    const { t, formatDate, formatDuration, route } = this.props;
    const direction = event.isOutbound ? 0 : 1;
    const duration = route.getDuration();

    return (
      <div className="ItineraryDetailedRoute__heading" key={`heading-${event.date.getTime()}`}>
        <div className="ItineraryDetailedRoute__headingTitle">
          {direction === 0 ? (
            <AirplaneOut className="ItineraryDetailedRoute__headingIcon" />
          ) : (
            <AirplaneHome className="ItineraryDetailedRoute__headingIcon" />
          )}
          <span className="ItineraryDetailedRoute__routeDirection">
            {`${event.isOutbound ? t('depart') : t('returndate')} `}
          </span>
          {' \u2013 '}
          {formatDate(event.date, 'LL')}
          {event.daysApart === 1 && <span className="ItineraryDetailedRoute__nextDay" />}
        </div>
        <div className="ItineraryDetailedRoute__headingSubTitle">
          {t('journey_duration')} : {formatDuration(duration)}
        </div>
      </div>
    );
  }

  /**
   * Renders a route heading.
   * @param {Object} event - object with information about the heading.
   */
  renderHeading(event) {
    const { mobileVersion } = this.props;

    if (mobileVersion) {
      return this.renderMobileHeading(event);
    }
    return (
      <Fragment key={`heading-${event.date.getTime()}`}>
        <MatchMedia max="ipad">{this.renderMobileHeading(event)}</MatchMedia>
        <MatchMedia min="ipad">{this.renderDesktopHeading(event)}</MatchMedia>
      </Fragment>
    );
  }

  /**
   * Renders a flight.
   * @param {Object} event - object with information about the flight.
   */
  renderFlight(event) {
    return (
      <div
        key={'flight-' + event.start.getTime() + event.airports[0].getCode()}
        className="ItineraryDetailedRoute__flight"
      >
        {this.renderTimeAndAirport({
          time: event.start,
          airport: event.airports[0],
          aircraft: event.item.getAircraft(),
          isNewDay: event.newDayDeparture,
          icon: <DepartIcon className="ItineraryDetailedRoute__airplaneIcon" />,
        })}
        {this.renderAirtime(event)}
        {this.renderTimeAndAirport({
          time: event.end,
          airport: event.airports[event.airports.length - 1],
          aircraft: event.item.getAircraft(),
          isNewDay: event.newDayArrival,
          icon: <ArriveIcon className="ItineraryDetailedRoute__airplaneIcon" />,
        })}
      </div>
    );
  }

  /**
   * Renders the departure or the arrival of the flight.
   * @param {Object} obj
   * @param {Date} obj.time - The time of the departure/arrival.
   * @param {Object} obj.airport - The origin/destination.
   * @param {bool} obj.isNewDay - Defines whether the departure is the day after the arrival
   * of the flight before or the arrival is the day after the departure of the flight.
   * @param {svg} obj.icon - Icon to show (airplane departing or airplane arriving)
   */

  renderTimeAndAirport({ time, airport, aircraft, isNewDay, icon }) {
    const { formatDate } = this.props;

    return (
      <div
        className={classNames('ItineraryDetailedRoute__timeAndAirport', {
          'ItineraryDetailedRoute__timeAndAirport--withNewDay': isNewDay,
        })}
      >
        <Tooltip title={formatDate(time, 'LLLL')} className="ItineraryDetailedRoute__date">
          {formatDate(time, 'LT')}
          {isNewDay && (
            <div className="ItineraryDetailedRoute__newDay">{formatDate(time, 'D MMM')}</div>
          )}
        </Tooltip>
        <Tooltip title={aircraft.getName()}>{icon}</Tooltip>
        <Tooltip title={formatAirport(airport)} className="ItineraryDetailedRoute__airport">
          {`${airport.getCode()} - ${airport.getCityName()}`}
        </Tooltip>
      </div>
    );
  }

  /**
   * Renders information about the airline of this flight.
   * @param {Object} event - object with information about the flight.
   */
  renderAirline(event) {
    const { t } = this.props;
    const { item } = event;

    return (
      <div className="ItineraryDetailedRoute__airline">
        <div className="ItineraryDetailedRoute__airlineInfo">
          <div
            className="ItineraryDetailedRoute__airlineLogo"
            style={{
              backgroundImage:
                `url(${config.M_STATIC}/airlines/` +
                `${item
                  .getAirline()
                  .getIATACode()
                  .toLowerCase()}.png)`,
            }}
          />
          <Tooltip
            title={item.getAircraft().getName()}
            className="ItineraryDetailedRoute__airlineText"
          >
            {item.getAirline().getName()}
          </Tooltip>
          <span className="ItineraryDetailedRoute__airlineSeparator">{' - '}</span>
          <Tooltip
            title={item.getAircraft().getName()}
            className="ItineraryDetailedRoute__airlineText"
          >
            {item.getFlightNumber()}
          </Tooltip>
        </div>
        {item.isCodeshare() && (
          <div className="ItineraryDetailedRoute__airlineMessage">
            {t('Operated_by %(airlineName)s', {
              airlineName: item.getOperatingAirline().getName(),
            })}
          </div>
        )}
      </div>
    );
  }

  /**
   * Renders information about the flight itself, the time that the airplane is in the air.
   * @param {Object} event - object with information about the flight.
   */
  renderAirtime(event) {
    const { formatDuration } = this.props;

    return (
      <div className="ItineraryDetailedRoute__airTime">
        <div className="ItineraryDetailedRoute__duration">{formatDuration(event.duration)}</div>
        <div className="ItineraryDetailedRoute__flightInfo">{this.renderAirline(event)}</div>
      </div>
    );
  }

  render() {
    const { flightPlan } = this.props;

    return (
      <div className="ItineraryDetailedRoute">
        <MatchMedia max="ipad" className="ItineraryDetailedRoute__line" />
        {flightPlan.map(this.renderEvent)}
      </div>
    );
  }
}

ItineraryDetailedRoute.defaultProps = {
  mobileVersion: false,
};

ItineraryDetailedRoute.propTypes = {
  t: PropTypes.func.isRequired,
  formatDuration: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  flightPlan: PropTypes.array,
  route: PropTypes.object.isRequired,
  // True if we want to use the mobile version for all screen sizes.
  mobileVersion: PropTypes.bool,
};

ItineraryDetailedRoute = createFilters(['t', 'formatDate', 'formatDuration'])(
  ItineraryDetailedRoute
);

export default ItineraryDetailedRoute;
