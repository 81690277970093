import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import CookieDough from 'cookie-dough';
import reducers from '../reducers';
import immutableSerializer from '../lib/immutableSerializer';
import { cookieMiddleware } from '../lib/redux/cookie';
import { breadcrumbsMiddleware } from '../lib/breadcrumbsMiddleware';

// Keep store as a variable
let store = {};

/**
 * Creates redux store with middleware
 * @param {Object} initialState - Preloaded state from server
 */
export default function configureStore(initialState) {
  store = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(thunk, cookieMiddleware(new CookieDough()), breadcrumbsMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

/**
 * Gets redux storestore
 */
export function getStore() {
  // If Redux store dosen't initialize properly then configure store again
  if (Object.keys(store).length === 0 && store.constructor === Object) {
    const storeState = immutableSerializer.fromJS(window.__DohopState__);
    return configureStore(storeState);
  }

  return store;
}
