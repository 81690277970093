import React, { Component } from 'react';
import ReactSlider from 'react-slider';
import omit from 'lodash/omit';
import classNames from 'classnames';
import './FilterSlider.less';

class FilterSlider extends Component {
  render() {
    const { disabled, title, onMouseOver, onMouseOut, hint, rightHint } = this.props;

    return (
      <div className={classNames('FilterSlider', { 'FilterSlider--disabled': disabled })}>
        {Boolean(title) && <div className="FilterSlider__title">{title}</div>}
        <div className="FilterSlider__wrapper" onMouseOver={onMouseOver} onMouseLeave={onMouseOut}>
          {Boolean(hint) && <div className="FilterSlider__hint">{hint}</div>}
          {Boolean(rightHint) && (
            <div className="FilterSlider__hint FilterSlider__hint--right"> - {rightHint}</div>
          )}
          <div className="FilterSlider__sliderWrapper">
            <ReactSlider
              className="FilterSlider__slider"
              pearling
              trackClassName="FilterSlider__bar"
              renderThumb={(props, state) => (
                <div
                  {...props}
                  className={classNames('FilterSlider__handle', {
                    'FilterSlider__handle--disabled': disabled,
                  })}
                />
              )}
              {...omit(this.props, 'title', 'hint')}
            />
          </div>
        </div>
      </div>
    );
  }
}

FilterSlider.defaultProps = {
  onMouseOver: () => {},
  onMouseOut: () => {},
};

export default FilterSlider;
