import React from 'react';
import { FareChecker } from 'dohop-client-api/src/helpers';
import createFilters from '../../lib/createFilters';
import './CheaperPriceIndicator.less';

class CheaperPriceIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, forceClose: true };
  }

  componentDidMount() {
    this.checkIfCheaper = this.onCheckIfCheaper.bind(this);
    this.props.search.on('change', this.checkIfCheaper);
  }

  componentWillUnmount() {
    this.props.search.removeListener('change', this.checkIfCheaper);
  }

  onCheckIfCheaper() {
    if (this.props.search.getSortOptions()[0].by === 'fare') {
      let lowest = new FareChecker(this.props.search).checkFare();
      // No fares available
      if (!this.props.search.itineraries.get(0)) return;
      let fare = this.props.search.itineraries.get(0).getFares().getBestFare();
      let current;
      if (fare && fare.isComplete()) {
        current = fare.getAverage();
      }
      // Check if we have thw lowest price that is at least one euro cheaper
      if ((!current && lowest) || (lowest && current && lowest <= current - 1)) {
        this.setState({ show: true, lowest });
      }
    }
  }

  onApplyPrices() {
    this.props.search.sort();
    this.setState({ show: false });
  }

  onCloseIndicator() {
    this.setState({ forceClose: false });
  }

  render() {
    let { t, formatCurrency } = this.props;

    if (!this.state.forceClose || !this.state.show) return null;

    return (
      <div className="CheaperPriceIndicator">
        <a onClick={this.onCloseIndicator.bind(this)} className="CheaperPriceIndicator__close" />
        <div className="CheaperPriceIndicator__icon" />
        <div className="CheaperPriceIndicator__rightContainer">
          <div
            className="CheaperPriceIndicator__price"
            dangerouslySetInnerHTML={{
              __html: t('we_found_lower_price %(price)s', {
                price: `<span class='price'>${formatCurrency(this.state.lowest)}</span>`,
              }),
            }}
          />
          <div>
            <a className="CheaperPriceIndicator__link" onClick={this.onApplyPrices.bind(this)}>
              {t('click to update results')}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default createFilters(['t', 'formatCurrency'])(CheaperPriceIndicator);
