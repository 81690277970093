import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import FlightIcon from '@content/svg/flight.svg';
import HotelIcon from '@content/svg/hotel.svg';
import CarIcon from '@content/svg/car.svg';
import './MobileTabs.less';

const ids = {
  FLIGHTS: 'flights',
  HOTELS: 'hotels',
  CARS: 'cars',
};

class MobileTabs extends Component {
  renderIcon(id) {
    const className = 'MobileTabs__icon';

    switch (id) {
      case ids.FLIGHTS:
        return <FlightIcon className={className} />;
      case ids.HOTELS:
        return <HotelIcon className={className} />;
      case ids.CARS:
        return <CarIcon className={className} />;
      default:
        return null;
    }
  }

  renderTab(tab, url, text) {
    return (
      <Link
        key={tab}
        to={url}
        className={classNames('MobileTabs__tab', {
          'MobileTabs__tab--active': tab === this.props.tab,
        })}
      >
        {this.renderIcon(tab)}
        <span className="MobileTabs__text">{text}</span>
      </Link>
    );
  }

  render() {
    const { t, tabOrder } = this.props;

    return (
      <div className="MobileTabs">
        {tabOrder.map((id, idx) => this.renderTab(id, '/' + (idx ? id : ''), t(id)))}
      </div>
    );
  }
}

MobileTabs = createFilters(['t'])(MobileTabs);
MobileTabs = connect((state) => ({ tabOrder: state.getIn(['request', 'tabOrder']) }))(MobileTabs);
export default MobileTabs;
