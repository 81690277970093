import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { legsToFlights } from 'dohop-client-api/src/helpers';
import TicketFlight from './TicketFlight';
import Disclaimer from './Disclaimer';
import TransferLink from './TransferLink';
import Portal from '../modal/Portal';
import Overlay from '../modal/Overlay';
import CloseButton from '../CloseButton';
import MatchMedia from '../MatchMedia';
import createFilters from '../../lib/createFilters';
import config from '../../lib/config';
import './MultiTicket.less';

function getAirlineNames(fare) {
  return fare
    .getAirlines()
    .map((a) => a.getName())
    .join(', ');
}

function getAirlineLogoStyle(airlines) {
  if (airlines.length) {
    return `url(${config.M_STATIC}/airlines/${airlines[0].getIATACode().toLowerCase()}.png)`;
  }

  return 'none';
}

function isOutboundFlight(itinerary, flight) {
  return itinerary.getRoutes()[0].getLegs().indexOf(flight.getLegs()[0]) >= 0;
}

function isNextDayDeparture(flight, prevFlight, itinerary) {
  // The first flight in the home bound trip is not interesting
  if (
    !prevFlight ||
    isOutboundFlight(itinerary, prevFlight) !== isOutboundFlight(itinerary, flight)
  ) {
    return false;
  }
  return flight.getDeparture().getDate() !== prevFlight.getArrival().getDate();
}

class MultiTicket extends React.Component {
  renderFooter(fare, priceInButton) {
    const { search, itinerary, user, filters } = this.props;
    const { t, formatCurrency } = filters;

    return (
      <div className="MultiTicket__footer">
        <TransferLink
          fare={fare}
          user={user}
          search={search}
          itinerary={itinerary}
          multiFare={this.props.fare}
          className="MultiTicket__button"
        >
          {priceInButton && fare.isComplete() ? formatCurrency(fare.getAverage()) : t('select')}
        </TransferLink>
        <div className="MultiTicket__price">
          <div
            className={classNames('MultiTicket__single', {
              'MultiTicket__single--centered': search.getMeta().getNumPassengers() === 1,
            })}
          >
            {!fare.isComplete() ? t('gettingprice') : formatCurrency(fare.getAverage())}
          </div>
          {search.getMeta().getNumPassengers() > 1 && fare.isComplete() && (
            <div className="MultiTicket__total">
              {formatCurrency(fare.getTotal())} {t('total')}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderFlight(flight, prevFlight) {
    const { filters, itinerary } = this.props;

    return (
      <TicketFlight
        key={flight.getId()}
        flight={flight}
        filters={filters}
        isOutbound={isOutboundFlight(itinerary, flight)}
        nextDayDeparture={isNextDayDeparture(flight, prevFlight, itinerary)}
      />
    );
  }

  getFlights() {
    const { itinerary } = this.props;
    const flights = itinerary.getFlights();

    return flights.reduce((acc, curr, index) => {
      return Object.assign(acc, { [curr.getId()]: this.renderFlight(curr, flights[index - 1]) });
    }, {});
  }

  render() {
    const { onClose, fare, mobile, itinerary, filters } = this.props;
    const { t, formatCurrency } = filters;
    const flights = this.getFlights();

    return (
      <Portal>
        <div
          className={classNames('MultiTicket', {
            'MultiTicket__mobile--hidden': !mobile,
          })}
        >
          <Overlay className="MultiTicket__overlay" onClick={onClose} />
          <div className="MultiTicket__content">
            <div className="MultiTicket__title">
              {t('multiple_tickets_header %(number)s', { number: fare.size() })}
            </div>
            <CloseButton className="MultiTicket__close" onClose={onClose.bind(this)} />
            <div className="MultiTicket__notice">
              <div className="MultiTicket__noticeText">{t('price_advisory_warning')}</div>
            </div>
            <div className="MultiTicket__rowContainer">
              {fare.getFares().map((f, iFare) => (
                <div key={iFare} className="MultiTicket__row">
                  <div className="MultiTicket__header">
                    <div className="MultiTicket__ticketNumber">{iFare + 1}</div>
                    <div
                      className="MultiTicket__airlineLogo"
                      style={{ backgroundImage: getAirlineLogoStyle(f.getAirlines()) }}
                    />
                    <div className="MultiTicket__airline">{getAirlineNames(f)}</div>
                    <div className="MultiTicket__vendor">
                      {f.isComplete()
                        ? f.getVendorFare().getVendor().getName()
                        : getAirlineNames(f)}
                    </div>
                  </div>
                  <MatchMedia min="ipad">{this.renderFooter(f, false)}</MatchMedia>
                  <div className="MultiTicket__flights">
                    {legsToFlights(itinerary.getFlights(), f.getLegs()).map(
                      (flight) => flights[flight.getId()]
                    )}
                  </div>
                  <MatchMedia max="ipad">
                    <div className="MultiTicket__footer__topBorder" />
                    {this.renderFooter(f, false)}
                  </MatchMedia>
                </div>
              ))}
              {fare.isComplete() && (
                <div className="MultiTicket__sum__total">
                  {t('total')}: <strong>{formatCurrency(fare.getTotal())}</strong>
                </div>
              )}
            </div>
            <div className="MultiTicket__border" />
            <div className="MultiTicket__disclaimer">
              <Disclaimer itinerary={itinerary} fare={fare} />
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

MultiTicket.propTypes = {
  fare: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  itinerary: PropTypes.object.isRequired,
};

MultiTicket = createFilters('filters')(MultiTicket);

export default MultiTicket;
