import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ArrowDown from '@content/svg/arrow_down.svg';
import './FilterBox.less';

class FilterBox extends Component {
  state = { open: this.props.initialOpen };

  onToggle = e => {
    e.stopPropagation();
    const { togglable } = this.props;
    const { open } = this.state;

    if (!togglable) {
      return;
    }

    this.setState({ open: !open });
  };

  renderHeader() {
    const { togglable, clickableText, onClick } = this.props;
    const { open } = this.state;

    if (togglable) {
      return (
        <ArrowDown
          className={classNames('FilterBox__arrow', {
            'FilterBox__arrow--open': open,
          })}
        />
      );
    }

    if (clickableText) {
      return (
        <div onClick={onClick} className="FilterBox__clickableText">
          {clickableText}
        </div>
      );
    }

    return false;
  }

  render() {
    const { showHeader, title, children, isFilterOn, noLine } = this.props;
    const { open } = this.state;

    return (
      <div className="FilterBox">
        <div
          className={classNames({
            FilterBox__hidden: !showHeader,
          })}
        >
          <div className="FilterBox__heading" onClick={this.onToggle} role="button" tabIndex={0}>
            <div
              className={classNames('FilterBox__title', {
                'FilterBox__title--noline': noLine,
              })}
            >
              {/* We toggle small circle to identify where filters are active */}
              <div
                className={classNames({
                  FilterBox__hidden: !isFilterOn,
                })}
              >
                <span className="FilterBox__title__marker" />
              </div>
              {title}
            </div>
            {this.renderHeader()}
          </div>
        </div>
        <div
          className={classNames({
            FilterBox__hidden: !open,
          })}
        >
          <div className="FilterBox__content FilterBox__content--animation">{children}</div>
        </div>
      </div>
    );
  }
}

FilterBox.defaultProps = {
  noLine: false,
  initialOpen: true,
  togglable: true,
  showHeader: true,
  isFilterOn: false,
};

FilterBox.propTypes = {
  noLine: PropTypes.bool,
  onClick: PropTypes.func,
  togglable: PropTypes.bool,
  isFilterOn: PropTypes.bool,
  initialOpen: PropTypes.bool,
};

export default FilterBox;
