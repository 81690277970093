import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import omit from 'lodash/omit';
import classNames from 'classnames';
import mapProps from 'map-props';
import withForwardedRef from './wrappers/WithForwardedRef';
import { createSelector } from 'reselect';
import './Dropdown.less';

function flattenOptions(options) {
  const reducer = (acc, o) => (o.get('options') ? acc.concat(o.get('options')) : acc.push(o));
  return options.reduce(reducer, Immutable.List());
}

class Dropdown extends Component {
  state = { focused: false };

  /**
   * If the shouldPopOpen props changes from false to true, we trigger the
   * onFocus function for that dropdown.
   */
  static getDerivedStateFromProps(props, state) {
    const { forwardedRef, shouldPopOpen } = props;
    const { wasOpen } = state;
    const hasRef = forwardedRef && forwardedRef.current;

    if (!wasOpen && shouldPopOpen && hasRef) {
      forwardedRef.current.focus();
    }

    return { wasOpen: shouldPopOpen };
  }

  /**
   * Triggered when the dropdown is focused.
   */
  onFocus = () => {
    const { onFocusCallback } = this.props;
    this.setState({ focused: true });

    if (onFocusCallback) {
      onFocusCallback();
    }
  };

  /**
   *  Triggered when the dropdown is not focused anymore.
   */
  onBlur = () => {
    this.setState({ focused: false });
  };

  /**
   * Renders the options (values) of the dropdown.
   * @param {object<Immutable.list>} options - the list of options
   * @param {bool} allowOptgroup - whether the optisons should be grouped or not.
   */
  renderOptions(options, allowOptgroup = true) {
    const optionsArr = [];

    options.forEach((option, i) => {
      if (option.get('options') && allowOptgroup) {
        optionsArr.push(
          <optgroup key={i} label={option.get('label')}>
            {this.renderOptions(option.get('options'), false)}
          </optgroup>
        );
      } else {
        optionsArr.push(
          <option
            key={i + option.get('value')}
            value={option.get('value')}
            disabled={option.get('disabled')}
          >
            {option.get('label')}
          </option>
        );
      }
    });

    return optionsArr;
  }

  render() {
    const {
      id,
      options,
      value,
      label,
      showLabel,
      className,
      valueClassName,
      icon,
      forwardedRef,
    } = this.props;
    const { focused } = this.state;
    const match = flattenOptions(options).find(o => o.get('value') === value);
    const styledValue = match ? match.get('label') : '';

    return (
      <div
        className={classNames('Dropdown', className, {
          'Dropdown--withIcon': icon,
          'Dropdown--focus': focused,
        })}
      >
        {icon && icon('Dropdown__icon')}
        {showLabel && (
          <label
            className={classNames('Dropdown__label', { 'Dropdown__label--focus': focused })}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        <div className={classNames('Dropdown__selected', valueClassName)}>{styledValue}</div>
        <span className="Dropdown__arrow" />
        <select
          id={id}
          ref={forwardedRef}
          className="Dropdown__dropdown"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...omit(
            this.props,
            'options',
            'className',
            'valueClassName',
            'iconClassName',
            'icon',
            'shouldPopOpen',
            'forwardedRef',
            'onFocusCallback',
            'showLabel'
          )}
        >
          {this.renderOptions(options)}
        </select>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  id: null,
  forwardedRef: null,
  className: null,
  iconClassName: null,
  icon: null,
  label: '',
  showLabel: false,
  valueClassName: null,
  onFocusCallback: null,
};

Dropdown.propTypes = {
  id: PropTypes.string,
  forwardedRef: PropTypes.object,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  icon: PropTypes.func,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  valueClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocusCallback: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

Dropdown = mapProps({
  options: createSelector(
    props => props.options,
    options => (Immutable.List.isList(options) ? options : Immutable.fromJS(options))
  ),
})(Dropdown);

export default withForwardedRef(Dropdown);
