import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MatchMedia from '../MatchMedia';
import Bar from './Bar';
import MobileTabs from '../mobile/MobileTabs';
import './BigLogoHeader.less';

// Header for frontpage with the big dohop logo
const BigLogoHeader = ({
  colorBackground,
  tab,
  url,
  showBarOnly,
  isFrontpage,
  logo,
  transparent,
  className,
}) => (
  <div
    className={classNames('BigLogoHeader', className, {
      'BigLogoHeader--transparent': transparent,
    })}
  >
    <Bar
      isFrontpage={isFrontpage}
      tab={tab}
      colorBackground={colorBackground}
      noPadding={!colorBackground}
      logo={logo !== undefined}
      className="BigLogoHeader__bar"
    />
    {!showBarOnly && (
      <Fragment>
        <MatchMedia max="ipad">
          <MobileTabs tab={tab} />
        </MatchMedia>
      </Fragment>
    )}
  </div>
);

BigLogoHeader.defaultProps = {
  showBarOnly: false,
  colorBackground: false,
  isFrontpage: false,
  transparent: false,
};

BigLogoHeader.propTypes = {
  tab: PropTypes.string,
  showBarOnly: PropTypes.bool,
  colorBackground: PropTypes.bool,
  isFrontpage: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default BigLogoHeader;
