/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _clone = require('lodash/clone');
const _some = require('lodash/some');
const _keys = require('lodash/keys');
const _filter = require('lodash/filter');
const _map = require('lodash/map');
const _isArray = require('lodash/isArray');
const _extend = require('lodash/extend');
const util = require('./util');
const time = require('./time');
const { hasFareSortDiff, emptyFareSort } = require('./faresort');
const {
  PriorityQueue,
  OrderedPairs,
  OrderedNestedList,
  IterCache,
  ArrayIter,
  FilteredList,
  LazySortMulti,
} = require('./collections');
const { Fare, MultiFare, SHORT_TRANSFERTIME } = require('./flight');

// reverses a score function if reverse is true
const maybeReverse = function (kfunc, reverse) {
  if (!reverse) {
    return kfunc;
  }

  return function (r) {
    const v = kfunc(r);
    if (v != null) {
      return -v;
    }
  };
};

// FareLoader takes care of processing the faresort datastructure and creating fares
// with respect to what the filters allow.
class FareLoader {
  constructor(faresort, fares, filters) {
    this.fares = fares;
    this.filters = filters;
    this.faresort = emptyFareSort();
    this._sorted = { rIdsOut: [], rIdOutToHomebound: {} };
    this.update(faresort, []);
  }

  clone(filters) {
    if (filters == null) {
      ({ filters } = this);
    }
    const fl = new FareLoader(emptyFareSort(), this.fares.slice(), filters);
    fl.faresort = this.faresort;
    for (let k in this._sorted) {
      const v = this._sorted[k];
      fl._sorted[k] = _clone(v);
    }
    return fl;
  }

  update(faresort, fares) {
    let rIdOut;
    this.cleanFares = {};

    const fareIdsChanged = [];
    for (let fare of Array.from(fares)) {
      if (!this.fares[fare.getId()] || fare.getTotal() !== this.fares[fare.getId()].getTotal()) {
        fareIdsChanged.push(fare.getId());
      }
      this.fares[fare.getId()] = fare;
    }

    for (var k of ['1-way-out', '1-way-home']) {
      if (
        !(k in this._sorted) ||
        hasFareSortDiff(this.faresort[k], faresort[k]) ||
        _some(fareIdsChanged, (id) => this._sorted[k].fareIdSet[id])
      ) {
        this._sorted[k] = this._getSortedOneWay(faresort[k]);
      }
    }

    const routeIdsOut = [];
    for (rIdOut in faresort.rt) {
      var homebound = this._sorted.rIdOutToHomebound[rIdOut];
      if (
        hasFareSortDiff(this.faresort.rt[rIdOut], faresort.rt[rIdOut]) ||
        _some(fareIdsChanged, (id) => homebound.fareIdSet[id])
      ) {
        routeIdsOut.push(rIdOut);
      }
    }

    for (rIdOut of Array.from(routeIdsOut)) {
      this._sorted.rIdOutToHomebound[rIdOut] = this._getSortedOneWay(faresort.rt[rIdOut]);
    }

    if (routeIdsOut.length > 0) {
      this._sorted.rIdsOut = util.sortBy(_keys(faresort.rt), (rId) => {
        const { fareIdsList, fareIdsToAmount } = this._sorted.rIdOutToHomebound[rId];
        return fareIdsToAmount[fareIdsList[0].join('-')];
      });
    }

    return (this.faresort = faresort);
  }

  getSortedOutbound() {
    return this._sorted['1-way-out'];
  }

  getSortedHomebound() {
    return this._sorted['1-way-home'];
  }

  getSortedTwoWayFares() {
    return [this._sorted.rIdsOut, this._sorted.rIdOutToHomebound];
  }

  getOneWayFares(route, clean) {
    if (clean == null) {
      clean = true;
    }
    if (route.isOutbound()) {
      return this.getOutboundFares(route.getId(), clean);
    } else {
      return this.getHomeboundFares(route.getId(), clean);
    }
  }

  getOutboundFares(routeId, clean) {
    if (clean == null) {
      clean = true;
    }
    const fareIdsList =
      this.faresort['1-way-out'][routeId] != null ? this.faresort['1-way-out'][routeId] : [];
    return this.loadList(
      (() => {
        const result = [];
        for (let [fareIds] of Array.from(fareIdsList)) {
          result.push(fareIds);
        }
        return result;
      })(),
      clean
    );
  }

  getHomeboundFares(routeId, clean) {
    if (clean == null) {
      clean = true;
    }
    const fareIdsList =
      this.faresort['1-way-home'][routeId] != null ? this.faresort['1-way-home'][routeId] : [];
    return this.loadList(
      (() => {
        const result = [];
        for (let [fareIds] of Array.from(fareIdsList)) {
          result.push(fareIds);
        }
        return result;
      })(),
      clean
    );
  }

  getTwoWayFares(rIdOut, rIdHome, clean) {
    if (clean == null) {
      clean = true;
    }
    const fareIdsList =
      (this.faresort['rt'][rIdOut] != null ? this.faresort['rt'][rIdOut][rIdHome] : undefined) !=
      null
        ? this.faresort['rt'][rIdOut] != null
          ? this.faresort['rt'][rIdOut][rIdHome]
          : undefined
        : [];
    return this.loadList(
      (() => {
        const result = [];
        for (let [fareIds] of Array.from(fareIdsList)) {
          result.push(fareIds);
        }
        return result;
      })(),
      clean
    );
  }

  // returns a single fare object, optionally validating the fare with the filters
  get(fareId, clean) {
    if (clean == null) {
      clean = true;
    }
    const fare = this.fares[fareId];
    if (!clean) {
      return fare;
    }

    // If the fare itself is not allowed, we return undefined.
    // If only a subset of the vendors are allowed, we must create a new fare object with only the allowed vendors.
    if (!(fareId in this.cleanFares)) {
      this.cleanFares[fareId] = (() => {
        if (this.filters.activeFareFilters() === 0) {
          return fare;
        } else if (this.filters.isValidFare(fare)) {
          const vendorFares = _filter(fare.getVendorFares(), (vf) =>
            this.filters.isValidVendorFare(vf)
          );
          if (vendorFares.length === fare.getVendorFares().length) {
            return fare;
          } else {
            util.assert(vendorFares.length > 0);
            return new Fare(
              fare.getId(),
              vendorFares,
              fare.getOutboundLegs(),
              fare.getHomeboundLegs(),
              fare.getVersion(),
              true
            );
          }
        }
      })();
    }

    return this.cleanFares[fareId];
  }

  getTotal(fareIds, clean) {
    if (clean == null) {
      clean = true;
    }
    return __guard__(this.load(fareIds, clean), (x) => x.getTotal());
  }

  // returns a single fare for the given list of fare-ids. A {Fare} is returned if a single fare-id is given,
  // otherwise creates a new {MultiFare}
  load(fareIds, clean) {
    if (clean == null) {
      clean = true;
    }
    const fares = [];

    for (let fareId of Array.from(fareIds)) {
      const fare = this.get(fareId, clean);
      // return undefined if any of the fares are not allowed
      if (fare == null) {
        return;
      }
      fares.push(fare);
    }

    if (fares.length === 1) {
      return fares[0];
    } else {
      return new MultiFare(fares);
    }
  }

  // Same as {load} expect accepts a list of fare-ids and returns a list of fares.
  // If clean is true, an {IterCache}  will be returned that generates valid fare objects.
  loadList(fareIdsList, clean) {
    let fareIds;
    if (clean == null) {
      clean = true;
    }
    const { fares } = this;
    fareIdsList = util.sortBy(fareIdsList, (fareIds) =>
      util.sum(fareIds, (fareId) => fares[fareId].getTotal())
    );

    if (!clean) {
      return (() => {
        const result = [];
        for (fareIds of Array.from(fareIdsList)) {
          result.push(this.load(fareIds, false));
        }
        return result;
      })();
    }

    const fareIter = new FareListIter(fareIdsList, this);
    return new IterCache({
      next: () => {
        fareIds = __guard__(fareIter.next(), (x) => x.fareIds);
        if (fareIds) {
          return this.load(fareIds);
        }
      },
    });
  }

  // Returns data that can be used to iterate in sorted order through all fares of faresort's
  // 1-way-out, 1-way-home or any of rt[routeIdOut]
  _getSortedOneWay(oneWay) {
    let fareIdsList = [];
    const fareIdsToRoute = {};
    const fareIdsToAmount = {};
    const fareIdSet = {};

    for (let routeId in oneWay) {
      const fares = oneWay[routeId];
      for (let [fareIds] of Array.from(fares)) {
        // There can be duplicates when we have one route A:
        // 	[Flight(leg-a, leg-b)]
        // and route B:
        // 	[Flight(leg-a), Flight(leg-b)]
        // In such cases faresort returns identical fares for both routes. For now we will ignore the duplicates.
        const key = fareIds.join('-');
        if (key in fareIdsToRoute) {
          continue;
        }

        for (let fareId of Array.from(fareIds)) {
          fareIdSet[fareId] = true;
        }

        fareIdsList.push(fareIds);
        fareIdsToRoute[key] = routeId;
        fareIdsToAmount[key] = util.sum(fareIds, (fareId) => this.fares[fareId].getTotal());
      }
    }

    fareIdsList = util.sortBy(fareIdsList, (fareIds) => fareIdsToAmount[fareIds.join('-')]);

    return { fareIdsList, fareIdsToRoute, fareIdsToAmount, fareIdSet };
  }

  // Attemps to find a single fare for the given flights. Is used to find partial fares for routes
  // that we don't have complete price for.
  getFareForFlights(flights, clean) {
    if (clean == null) {
      clean = true;
    }
    const mkKey = (fs) => _map(fs, (f) => f.getKey()).join('*');

    if (this._flightsToFareId == null) {
      this._flightsToFareId = (() => {
        return util.eachWithObject(this.fares, {}, function (map, fare) {
          if (fare != null) {
            return (map[mkKey(fare.getLegs())] = fare.getId());
          }
        });
      })();
    }

    const fareId = this._flightsToFareId[mkKey(flights)];
    if (fareId != null) {
      return this.get(fareId, clean);
    }
  }
}

// generates route-pairs in sorted order from the faresort's "rt" where there is at least one fare
// that covers flights from separate routes
class FareSortReturn {
  constructor(outbound, homebound, fareLoader, filters) {
    let sortedRouteIdsOut;
    let rId;
    this.outbound = outbound;
    this.homebound = homebound;
    this.fareLoader = fareLoader;
    this.filters = filters;
    [sortedRouteIdsOut, this.rIdOutToSortedHomebound] = Array.from(
      this.fareLoader.getSortedTwoWayFares()
    );

    // if there are fare filters active, the order of outbound routes could be affected if the
    // lowest fare of an outbound route is disallowed by the filters. In such cases we must
    // re-sort the outbound routes by the cheapest fare the filters allow.
    if (this.filters.activeFareFilters().length > 0) {
      const validRouteIdsOut = [];
      for (let rIdOut of Array.from(sortedRouteIdsOut)) {
        if (
          this.filters.isValidRoute(this.outbound[rIdOut]) &&
          this.rIdOutToHome(rIdOut).get(0) != null
        ) {
          validRouteIdsOut.push(rIdOut);
        }
      }
      sortedRouteIdsOut = util.sortBy(validRouteIdsOut, (rId) => {
        return this.rIdOutToHome(rId).get(0).amount;
      });
    }

    this.routesOut = new IterCache(
      new FilteredList(
        (() => {
          const result = [];
          for (rId of Array.from(sortedRouteIdsOut)) {
            result.push(this.outbound[rId]);
          }
          return result;
        })(),
        (route) => this.filters.isValidRoute(route)
      )
    );

    this.q = new OrderedNestedList(sortedRouteIdsOut.length, (i, j) => {
      const routeOut = this.routesOut.get(i);
      if (routeOut == null) {
        return;
      }

      return __guard__(this.rIdOutToHome(routeOut.getId()).get(j), (x) => x.amount);
    });
  }

  rIdOutToHome(rIdOut) {
    let base;
    return (base = this.__rIdOutToHome != null ? this.__rIdOutToHome : (this.__rIdOutToHome = {}))[
      rIdOut
    ] != null
      ? base[rIdOut]
      : (base[rIdOut] = (() => {
          const { fareIdsList, fareIdsToAmount, fareIdsToRoute } = this.rIdOutToSortedHomebound[
            rIdOut
          ];
          return new IterCache(
            new FareListRouteIter(fareIdsList, fareIdsToAmount, fareIdsToRoute, this.fareLoader)
          );
        })());
  }

  next() {
    let ij;
    while ((ij = this.q.next())) {
      const [i, j] = Array.from(ij);
      const routeOut = this.routesOut.get(i);
      const { routeId, amount } = this.rIdOutToHome(routeOut.getId()).get(j);
      const routeHome = this.homebound[routeId];
      if (this.filters.isValidRoute(routeHome)) {
        return [routeOut, routeHome];
      }
    }
  }
}

// generates routes in sorted order from faresort's 1-way-out/1-way-home
class FareSortOneWay {
  constructor({ fareIdsList, fareIdsToRoute, fareIdsToAmount }, routes, fareLoader, filters) {
    this.fareIdsToRoute = fareIdsToRoute;
    this.routes = routes;
    this.fareLoader = fareLoader;
    this.filters = filters;
    this.fareListRouteIter = new FareListRouteIter(
      fareIdsList,
      fareIdsToAmount,
      this.fareIdsToRoute,
      this.fareLoader
    );
    this.routeIdToAmount = {};
  }

  next() {
    while (true) {
      const item = this.fareListRouteIter.next();
      if (item == null) {
        return;
      }

      const { routeId, amount } = item;
      this.routeIdToAmount[routeId] = amount;

      const route = this.routes[routeId];
      if (
        this.filters.isValidRoute(route, {
          sorting: 'one-way',
          fares: this.fareLoader.getOneWayFares(route, false),
        })
      ) {
        return [route];
      }
    }
  }
}

// generates route pairs in sorted order, the cross product of 1-way-out and 1-way-home.
// ten outbound routes and ten homebound routes will therefor yield 100 unique route pairs
class FareSortPairs {
  constructor(outbound, homebound, fareLoader, filters) {
    this.outbound = outbound;
    this.homebound = homebound;
    const sortedOutbound = fareLoader.getSortedOutbound();
    this.outboundIter = new FareSortOneWay(sortedOutbound, this.outbound, fareLoader, filters);
    this.outboundIterCache = new IterCache(this.outboundIter);

    const sortedHomebound = fareLoader.getSortedHomebound();
    this.homeboundIter = new FareSortOneWay(sortedHomebound, this.homebound, fareLoader, filters);
    this.homeboundIterCache = new IterCache(this.homeboundIter);

    this.q = new OrderedPairs({
      aScore: (i) => {
        const route = __guard__(this.outboundIterCache.get(i), (x) => x[0]);
        if (route) {
          return this.outboundIter.routeIdToAmount[route.getId()];
        }
      },
      bScore: (i) => {
        const route = __guard__(this.homeboundIterCache.get(i), (x) => x[0]);
        if (route) {
          return this.homeboundIter.routeIdToAmount[route.getId()];
        }
      },
    });
  }

  next() {
    const ij = this.q.next();
    if (ij) {
      const [i, j] = Array.from(ij);
      const rOut = this.outboundIterCache.get(i)[0];
      const rHome = this.homeboundIterCache.get(j)[0];
      return [rOut, rHome];
    }
  }
}

// generates fare-ids in sorted order with respect to what the filters allow
class FareListIter {
  constructor(fareIdsList, fareLoader, fareIdsToAmount) {
    this.fareIdsList = fareIdsList;
    this.fareLoader = fareLoader;
    if (fareIdsToAmount == null) {
      fareIdsToAmount = {};
    }
    this.fareIdsToAmount = fareIdsToAmount;
    this.seen = {};
    this.i = 0;
    this.q = new PriorityQueue((a, b) => a.amount - b.amount);
    this._push();
  }

  _push() {
    if (this.i < this.fareIdsList.length) {
      let left;
      const fareIds = this.fareIdsList[this.i++];
      const amount =
        (left = this.fareIdsToAmount[fareIds.join('-')]) != null
          ? left
          : this.fareLoader.getTotal(fareIds, false);
      return this.q.push({ fareIds, amount, validated: false });
    }
  }

  next() {
    while (true) {
      if (this.q.length() === 0) {
        return;
      }

      const { fareIds, amount, validated } = this.q.pop();

      if (!validated) {
        this._push();

        const cleanAmount = this.fareLoader.getTotal(fareIds);
        if (cleanAmount == null) {
          continue;
        }

        if (amount < cleanAmount) {
          this.q.push({ fareIds, amount: cleanAmount, validated: true });
          continue;
        }
      }

      return { fareIds, amount };
    }
  }
}

// generates unique route-ids in order of cheapest fare
class FareListRouteIter {
  constructor(fareIdsList, fareIdsToAmount, fareIdsToRoute, fareLoader) {
    this.fareIdsToRoute = fareIdsToRoute;
    this.fareListIter = new FareListIter(fareIdsList, fareLoader, fareIdsToAmount);
    this.seen = {};
  }

  next() {
    while (true) {
      const item = this.fareListIter.next();
      if (item == null) {
        return;
      }

      const { fareIds, amount } = item;
      const routeId = this.fareIdsToRoute[fareIds.join('-')];

      if (!this.seen[routeId]) {
        this.seen[routeId] = true;
        return { routeId, amount };
      }
    }
  }
}

// sorts one-way route list by arbitrary score function
class SortedOneWay {
  constructor(routes, filters, fareLoader, kfunc) {
    this.filters = filters;
    this.fareLoader = fareLoader;
    this.kfunc = kfunc;
    util.assert(kfunc);
    routes = util.sortBy(routes, (route) => kfunc(route));

    const getTotal = (route) => {
      let left;
      return (left = __guard__(this.fareLoader.getOneWayFares(route).next(), (x) =>
        x.getTotal()
      )) != null
        ? left
        : Infinity;
    };

    this.routes = new LazySortMulti(new ArrayIter(routes), kfunc, getTotal);
  }

  next() {
    while (true) {
      const route = this.routes.next();
      if (route == null) {
        return;
      }
      if (
        this.filters.getFilter('noFare').isActive() &&
        this.fareLoader.getOneWayFares(route).next() == null
      ) {
        continue;
      }
      if (
        !this.filters.isValidRoute(route, {
          sorting: 'one-way',
          fares: this.fareLoader.getOneWayFares(route, false),
        })
      ) {
        continue;
      }
      return [route];
    }
  }
}

// same as FareSortPairs expect sorts routes by arbitrary score function
class SortedRoutePairs {
  constructor(outbound, homebound, filters, fareLoader, kout, khome) {
    util.assert(kout);
    util.assert(khome);
    this.outbound = new IterCache(new SortedOneWay(outbound, filters, fareLoader, kout));
    this.homebound = new IterCache(new SortedOneWay(homebound, filters, fareLoader, khome));

    const q = new OrderedPairs({
      aScore: (i) => {
        const route = __guard__(this.outbound.get(i), (x) => x[0]);
        if (route != null) {
          return kout(route);
        }
      },
      bScore: (i) => {
        const route = __guard__(this.homebound.get(i), (x) => x[0]);
        if (route != null) {
          return khome(route);
        }
      },
    });

    // XXX: if OrderedPairs accepted a compare function is stead of score function, we wouldn't need LazySortMulti here
    this.q = new LazySortMulti(
      q,
      (...args) => {
        const [i, j] = Array.from(args[0]);
        return kout(this.outbound.get(i)[0]) + khome(this.homebound.get(j)[0]);
      },
      (...args) => {
        const [i, j] = Array.from(args[0]);
        const totalOut = fareLoader.getOneWayFares(this.outbound.get(i)[0]);
        const totalHome = fareLoader.getOneWayFares(this.homebound.get(j)[0]);
        if (totalOut == null || totalHome == null) {
          return Infinity;
        }
        return totalOut + totalHome;
      }
    );
  }

  next() {
    const ij = this.q.next();
    if (ij != null) {
      const [i, j] = Array.from(ij);
      return this.outbound.get(i).concat(this.homebound.get(j));
    }
  }
}

// Same as FareSortReturn expect sorts routes by arbitrary score function
class SortedRoutesReturn {
  constructor(outbound, homebound, filters, fareLoader, kout, khome) {
    let id;
    this.homebound = homebound;
    this.filters = filters;
    this.fareLoader = fareLoader;
    util.assert(kout);
    util.assert(khome);
    let routesOut = (() => {
      const result = [];
      for (id in this.fareLoader.faresort.rt) {
        result.push(outbound[id]);
      }
      return result;
    })();

    this.rIdOutToRoutesHome = {};
    util.each(routesOut, (routeOut) => {
      let routesHome = (() => {
        const result1 = [];
        for (id in this.fareLoader.faresort.rt[routeOut.getId()]) {
          result1.push(this.homebound[id]);
        }
        return result1;
      })();
      routesHome = util.sortBy(routesHome, khome);
      // FIXME: we don't need LazySortMulti here since it is used on the output of OrderedNestedList below
      return (this.rIdOutToRoutesHome[routeOut.getId()] = new IterCache(
        new LazySortMulti(new ArrayIter(routesHome), khome, (routeHome) => {
          let left;
          return (left = __guard__(
            this.fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).next(),
            (x) => x.getTotal()
          )) != null
            ? left
            : Infinity;
        })
      ));
    });

    routesOut = util.sortBy(routesOut, (routeOut) => {
      return kout(routeOut) + khome(this.rIdOutToRoutesHome[routeOut.getId()].get(0));
    });

    this.outbound = new IterCache(
      new FilteredList(routesOut, (route) => this.filters.isValidRoute(route))
    );

    const q = new OrderedNestedList(outbound.length, (i, j) => {
      const routeOut = this.outbound.get(i);
      if (routeOut == null) {
        return;
      }

      const routeHome = this.rIdOutToRoutesHome[routeOut.getId()].get(j);
      if (routeHome == null) {
        return;
      }

      return kout(routeOut) + khome(routeHome);
    });

    // XXX: if OrderedNestedList accepted a compare function in stead of score function, we wouldn't need LazySortMulti here
    this.q = new LazySortMulti(
      q,
      (...args) => {
        const [i, j] = Array.from(args[0]);
        const routeOut = this.outbound.get(i);
        return kout(routeOut) + khome(this.rIdOutToRoutesHome[routeOut.getId()].get(j));
      },
      (...args) => {
        let left;
        const [i, j] = Array.from(args[0]);
        const routeOut = this.outbound.get(i);
        const routeHome = this.rIdOutToRoutesHome[routeOut.getId()].get(j);
        return (left = __guard__(
          this.fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).next(),
          (x) => x.getTotal()
        )) != null
          ? left
          : Infinity;
      }
    );
  }

  next() {
    let ij;
    while ((ij = this.q.next())) {
      const [i, j] = Array.from(ij);
      const routeOut = this.outbound.get(i);
      const routeHome = this.rIdOutToRoutesHome[routeOut.getId()].get(j);
      if (
        this.filters.isValidRoute(routeHome) &&
        this.fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).next() != null
      ) {
        return [routeOut, routeHome];
      }
    }
  }
}

// sorts return flights in coherance with the ranking system
class RankSortReturn {
  constructor(outbound, homebound, filters, fareLoader, meta) {
    // routesOut contains ids from fareloader for return
    let routeOut;
    let id;
    this.homebound = homebound;
    this.filters = filters;
    this.fareLoader = fareLoader;
    let routesOut = (() => {
      const result = [];
      for (id in this.fareLoader.faresort.rt) {
        result.push(outbound[id]);
      }
      return result;
    })();

    this.rIdOutToRoutesHome = {};
    for (routeOut of Array.from(routesOut)) {
      let routesHome = (() => {
        const result1 = [];
        for (id in this.fareLoader.faresort.rt[routeOut.getId()]) {
          result1.push(this.homebound[id]);
        }
        return result1;
      })();
      routesHome = util.sortBy(routesHome, (route) => {
        return getRank(this.fareLoader, meta, routeOut, route);
      });
      this.rIdOutToRoutesHome[routeOut.getId()] = routesHome;
    }

    routesOut = util.sortBy(routesOut, (routeOut) => {
      return getRank(this.fareLoader, meta, routeOut, this.rIdOutToRoutesHome[routeOut.getId()][0]);
    });

    this.outbound = new IterCache(
      new FilteredList(routesOut, (route) => this.filters.isValidRoute(route))
    );

    this.q = new OrderedNestedList(outbound.length, (i, j) => {
      routeOut = this.outbound.get(i);
      if (routeOut == null) {
        return;
      }

      const routeHome = this.rIdOutToRoutesHome[routeOut.getId()][j];
      if (routeHome == null) {
        return;
      }

      return getRank(this.fareLoader, meta, routeOut, routeHome);
    });
  }

  next() {
    let ij;
    while ((ij = this.q.next())) {
      const [i, j] = Array.from(ij);
      const routeOut = this.outbound.get(i);
      const routeHome = this.rIdOutToRoutesHome[routeOut.getId()][j];
      if (
        this.filters.isValidRoute(routeHome) &&
        this.fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).next() != null
      ) {
        return [routeOut, routeHome];
      }
    }
  }
}

// Merges itineraries in sorted order generated by other sorting routines (PairSort, ReturnSort)
class OrderedMerge {
  constructor(qs, kfuncs) {
    this.qs = qs;
    if (!_isArray(kfuncs)) {
      kfuncs = [kfuncs];
    }
    this.kfuncs = _map(
      kfuncs,
      (f) =>
        function ({ item }) {
          if (item) {
            return f(item);
          } else {
            return Infinity;
          }
        }
    );
    this.items = Array.from(this.qs).map((q) => q.next());
  }

  next() {
    const items = _map(this.items, (item, i) => ({
      item,
      i,
    }));
    const [{ item, i }] = Array.from(util.sortBy(items, ...Array.from(this.kfuncs)));
    const routes = this.items[i];
    if (routes != null) {
      this.items[i] = this.qs[i].next();
      return routes;
    }
  }
}

// Chains multiple queues, draining the queues in order
class ChainMerge {
  constructor(qs) {
    this.qs = qs;
    this.i = 0;
  }

  next() {
    while (this.i < this.qs.length) {
      const item = this.qs[this.i].next();
      if (item != null) {
        return item;
      }
      this.i++;
    }
  }
}

// Returns results in order from a queue, guarantying uniquness of generated routes
class UniqueRoutes {
  constructor(q) {
    this.q = q;
    this.seen = {};
  }

  next() {
    while (true) {
      const routes = this.q.next();
      if (routes == null) {
        return;
      }

      const k = Array.from(routes)
        .map((r) => r.getKey())
        .join('_');

      if (!this.seen[k]) {
        this.seen[k] = true;
        return routes;
      }
    }
  }
}

class TimeConstraintsFilter {
  constructor(q) {
    this.q = q;
  }

  next() {
    let routes;
    while ((routes = this.q.next())) {
      if (this._isValidRoutes(routes)) {
        return routes;
      }
    }
  }

  _isValidRoutes(routes) {
    for (let i = 1, end = routes.length, asc = 1 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      if (!routes[i - 1].isValidPair(routes[i])) {
        return false;
      }
    }
    return true;
  }
}

var getRank = function (fareLoader, meta, route, returnRoute) {
  let routeDuration, routeFare;
  if (returnRoute) {
    routeFare = fareLoader.getTwoWayFares(route.getId(), returnRoute.getId()).get(0);
    routeDuration = route.getDuration() + returnRoute.getDuration();
  } else {
    routeFare = route.isOutbound()
      ? fareLoader.getOutboundFares(route.getId()).get(0)
      : fareLoader.getHomeboundFares(route.getId()).get(0);
    routeDuration = route.getDuration();
  }
  const pricePerMinute = 25 / 60;
  let rank = getAdditionalRank(route, routeFare, meta);
  if (returnRoute) {
    rank += getAdditionalRank(returnRoute, routeFare, meta);
  }
  if (!routeFare) {
    return rank + routeDuration;
  }
  rank += (routeDuration / 60 / 1000) * pricePerMinute;
  return rank + routeFare.getAverage();
};

var getAdditionalRank = function (route, fare, meta) {
  let addedRank = route.isGroundTransit() ? 50 : 0; // ground transit
  if (route.isRedEye()) {
    addedRank += 10;
  } // overnight flight
  if (route.isOverNight()) {
    addedRank += 10;
  }
  addedRank += 10 * meta.getNumPassengers() * route.getStopOvers().length; // stops
  if (fare) {
    if (fare.isSelfConnect(meta.from, meta.to)) {
      addedRank += 10;
    } // self-connect
    if (hasShortTransfer(fare, route)) {
      addedRank += 10;
    } // short tranfer time between self-connect flights
  }
  return addedRank;
};

var hasShortTransfer = function (fare, route) {
  for (
    let i = 1, end = route.getFlights().length, asc = 1 <= end;
    asc ? i < end : i > end;
    asc ? i++ : i--
  ) {
    const a = route.getFlights()[i - 1];
    const b = route.getFlights()[i];
    if (
      fare.isSelfConnectConnection(a, b) &&
      b.getDeparture().getTime() - a.getArrival().getTime() < SHORT_TRANSFERTIME
    ) {
      return true;
    }
  }
};

const twoWaySort = function (outbound, homebound, filters, fareLoader, reverse, { kout, khome }) {
  kout = maybeReverse(kout, reverse);
  khome = maybeReverse(khome != null ? khome : () => 0, reverse);

  const pairs = new SortedRoutePairs(outbound, homebound, filters, fareLoader, kout, khome);
  let twoway = new SortedRoutesReturn(outbound, homebound, filters, fareLoader, kout, khome);

  const byScore = function (...args) {
    const [routeOut, routeHome] = Array.from(args[0]);
    return kout(routeOut) + khome(routeHome);
  };

  const byTotal = function (...args) {
    const [routeOut, routeHome] = Array.from(args[0]);
    const totals = [];
    twoway = fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).get(0);
    if (twoway != null) {
      totals.push(twoway.getTotal());
    }

    const onewayOut = fareLoader.getOutboundFares(routeOut.getId()).get(0);
    const onewayHome = fareLoader.getHomeboundFares(routeHome.getId()).get(0);
    if (onewayOut && onewayHome) {
      totals.push(onewayOut.getTotal() + onewayHome.getTotal());
    }
    return Math.min(...Array.from(totals || []));
  };

  return new OrderedMerge([pairs, twoway], [byScore, byTotal]);
};

const durationSortOneWay = function ({ outbound, filters, fareLoader, reverse }) {
  const kfunc = (route) => route.getDuration();
  return new SortedOneWay(outbound, filters, fareLoader, maybeReverse(kfunc, reverse));
};

const durationSortTwoWay = ({ outbound, homebound, filters, fareLoader, reverse }) =>
  twoWaySort(outbound, homebound, filters, fareLoader, reverse, {
    kout(route) {
      return route.getDuration();
    },
    khome(route) {
      return route.getDuration();
    },
  });

const departureSortOneWay = function ({ outbound, filters, fareLoader, reverse }) {
  const kfunc = (route) => route.getDeparture().getTime();
  return new SortedOneWay(outbound, filters, fareLoader, maybeReverse(kfunc, reverse));
};

const departureSortTwoWay = ({ outbound, homebound, filters, fareLoader, reverse }) =>
  twoWaySort(outbound, homebound, filters, fareLoader, reverse, {
    kout(route) {
      return route.getDeparture().getTime();
    },
  });

const arrivalSortOneWay = function ({ outbound, filters, fareLoader, reverse }) {
  const kfunc = (route) => route.getArrival().getTime();
  return new SortedOneWay(outbound, filters, fareLoader, maybeReverse(kfunc, reverse));
};

const arrivalSortTwoWay = ({ outbound, homebound, filters, fareLoader, reverse }) =>
  twoWaySort(outbound, homebound, filters, fareLoader, reverse, {
    kout(route) {
      return route.getArrival().getTime();
    },
  });

const fareSortOneWay = ({ outbound, faresort, filters, fareLoader, reverse }) =>
  new FareSortOneWay(fareLoader.getSortedOutbound(), outbound, fareLoader, filters);

const fareSortTwoWay = function ({ outbound, homebound, fareLoader, filters }) {
  const returnRoutes = new FareSortReturn(outbound, homebound, fareLoader, filters);
  const oneWayPairs = new FareSortPairs(outbound, homebound, fareLoader, filters);

  return new OrderedMerge([returnRoutes, oneWayPairs], function (...args) {
    const [routeOut, routeHome] = Array.from(args[0]);
    const totals = [];
    const twoway = fareLoader.getTwoWayFares(routeOut.getId(), routeHome.getId()).get(0);
    if (twoway != null) {
      totals.push(twoway.getTotal());
    }

    const onewayOut = fareLoader.getOutboundFares(routeOut.getId()).get(0);
    const onewayHome = fareLoader.getHomeboundFares(routeHome.getId()).get(0);
    if (onewayOut && onewayHome) {
      totals.push(onewayOut.getTotal() + onewayHome.getTotal());
    }
    return Math.min(...Array.from(totals || []));
  });
};

const rankSortOneWay = function ({ outbound, faresort, filters, fareLoader, reverse, meta }) {
  const kfunc = (route) => getRank(fareLoader, meta, route);
  return new SortedOneWay(outbound, filters, fareLoader, maybeReverse(kfunc, reverse));
};

const rankSortTwoWay = function ({ outbound, homebound, fareLoader, filters, reverse, meta }) {
  const kfunc = (route) => getRank(fareLoader, meta, route);
  const pairs = new SortedRoutePairs(outbound, homebound, filters, fareLoader, kfunc, kfunc);
  let twoway = new RankSortReturn(outbound, homebound, filters, fareLoader, meta);

  return new OrderedMerge([pairs, twoway], function (...args) {
    const [routeOut, routeHome] = Array.from(args[0]);
    const totals = [];
    twoway = getRank(fareLoader, meta, routeOut, routeHome);
    if (twoway != null) {
      totals.push(twoway);
    }

    const onewayOut = getRank(fareLoader, meta, routeOut);
    const onewayHome = getRank(fareLoader, meta, routeHome);
    if (onewayOut && onewayHome) {
      totals.push(onewayOut + onewayHome);
    }
    return Math.min(...Array.from(totals || []));
  });
};

const SORT_TYPES = {
  'duration-oneway': durationSortOneWay,
  'duration-twoway': durationSortTwoWay,
  'departure-oneway': departureSortOneWay,
  'departure-twoway': departureSortTwoWay,
  'arrival-oneway': arrivalSortOneWay,
  'arrival-twoway': arrivalSortTwoWay,
  'fare-oneway': fareSortOneWay,
  'fare-twoway': fareSortTwoWay,
  'rank-oneway': rankSortOneWay,
  'rank-twoway': rankSortTwoWay,
};

const createQueue = function (sorts, data) {
  if (!_isArray(sorts)) {
    sorts = [sorts];
  }

  const qs = (() => {
    const result = [];
    for (let sort of Array.from(sorts)) {
      const k = `${sort.by}-${data.homebound != null ? 'two' : 'one'}way`;
      util.assert(k in SORT_TYPES, `Unknown sort order: ${sort}`);
      result.push(SORT_TYPES[k](_extend({ reverse: !!sort.reverse }, data)));
    }
    return result;
  })();

  return new TimeConstraintsFilter(new UniqueRoutes(new ChainMerge(qs)));
};

module.exports = { createQueue, FareLoader, FareListRouteIter };

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
