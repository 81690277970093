import { List } from 'immutable';
import { StopOver } from 'dohop-client-api/src/flight';
import isWhitelabelFare from '@lib/isWhitelabelFare';
import isDohopFare, { DOHOP_VENDORS } from '@lib/isDohopFare';

/**
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {object} - the first route of the itinerary.
 */
function getFirstRoute(itinerary) {
  return itinerary.getRoutes()[0];
}

/**
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {object} - the last route of the itinerary.
 */
function getLastRoute(itinerary) {
  return itinerary.getRoutes()[itinerary.getRoutes().length - 1];
}

/**
 * @param {object} route - the route object.
 * @returns {object} - the first flight of this route.
 */
function getFirstFlightFromRoute(route) {
  return route.getFlights()[0];
}

/**
 * @param {object} route - the route object.
 * @returns {object} - the last flight from this route.
 */
function getLastFlightFromRoute(route) {
  return route.getFlights()[route.getFlights().length - 1];
}

/**
 * @param {object} flight - the flight object.
 * @returns {object} - the first leg of this flight.
 */
function getFirstLegFromFlight(flight) {
  return flight.getLegs()[0];
}

/**
 *  @param {object} flight - the flight object.
 * @returns {object} - the last leg of this flight.
 */
function getLastLegFromFlight(flight) {
  return flight.getLegs()[flight.getLegs().length - 1];
}

export function getLastLegFirstRoute(itinerary) {
  const firstRoute = getFirstRoute(itinerary);
  const lastFlightFirstRoute = getLastFlightFromRoute(firstRoute);
  return getLastLegFromFlight(lastFlightFirstRoute);
}

export function getFirstLegLastRoute(itinerary) {
  const lastRoute = getLastRoute(itinerary);
  const firstFlightLastRoute = getFirstFlightFromRoute(lastRoute);
  return getFirstLegFromFlight(firstFlightLastRoute);
}

/**
 * Get the departure of the whole journey.
 * The departure of the itinerary is the departure of the first leg in the first flight.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {Date} - the departure of the whole journey.
 */
export function getItineraryDeparture(itinerary) {
  const firstRoute = getFirstRoute(itinerary);
  const firstFlightFirstRoute = getFirstFlightFromRoute(firstRoute);

  return getFirstLegFromFlight(firstFlightFirstRoute).getDeparture();
}

/**
 * Get the arrival date of the whole journey.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {Date} - the arrival date of the whole journey.
 */
export function getItineraryArrival(itinerary) {
  const lastRoute = getLastRoute(itinerary);
  const lastFlightLastRoute = getLastFlightFromRoute(lastRoute);
  const lastLegLastRoute = getLastLegFromFlight(lastFlightLastRoute);

  return lastLegLastRoute.getArrival();
}

/**
 * Get the arrival of the outbound route.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {Date} - the the arrival of the last leg in the homebound route.
 */
export function getOutboundArrival(itinerary) {
  const firstRoute = getFirstRoute(itinerary);
  const lastFlightFirstRoute = getLastFlightFromRoute(firstRoute);

  return getLastLegFromFlight(lastFlightFirstRoute).getArrival();
}

/**
 * Get the departure of the homebound route.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {Date} - the the departure of the first leg in the last route.
 */
export function getHomeboundDeparture(itinerary) {
  const lastRoute = getLastRoute(itinerary);
  const firstFlightLastRoute = getFirstFlightFromRoute(lastRoute);
  const firstLegLastRoute = getFirstLegFromFlight(firstFlightLastRoute);

  return firstLegLastRoute.getDeparture();
}

/**
 * Get the origin of the itinerary.
 * The origin of the itinerary is the origin of the first flight.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {object} - the origin of the itinerary.
 */
export function getItineraryOrigin(itinerary) {
  const firstRoute = getFirstRoute(itinerary);
  const firstFlightFirstRoute = getFirstFlightFromRoute(firstRoute);

  return getFirstLegFromFlight(firstFlightFirstRoute).getOrigin();
}

/**
 * Get the destination of the itinerary.
 * The destination of the itinerary is the destination of the first flight.
 * That is because we don't offer users to plan a trip that is not either one way or back and fourth from the
 * same two cities. If we later implement feature to allow users to plan trips with multiple locations we need
 * to rethink this.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {object} - the destination of the itinerary.
 */
export function getItineraryDestination(itinerary) {
  const firstRoute = getFirstRoute(itinerary);
  const lastFlightFirstRoute = getLastFlightFromRoute(firstRoute);

  return getLastLegFromFlight(lastFlightFirstRoute).getDestination();
}

/**
 * Get the list of all airports that are in this itinerary.
 * The origin, all stopover airports and the destination.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {object <Immutable.List>} - list of all airports in the itinerary.
 *
 */
export function getAirportsInRoute(route) {
  let allAirports = List();
  const flights = route.getFlights();

  flights.forEach((flight) => {
    const airports = flight.getAirports();

    airports.forEach((airport) => {
      const airportCode = airport.getCode();
      let isAlreadyAdded = false;

      allAirports.forEach((a) => {
        if (a.getCode() === airportCode) {
          isAlreadyAdded = true;
        }
      });

      if (!isAlreadyAdded) {
        allAirports = allAirports.push(airport);
      }
    });
  });

  return allAirports;
}

/**
 * Indicates if the whole itinerary only takes one day or not.
 * @param {object} itinerary - the itinerary object from dohop client api
 * @returns {boolean} - true if the whole itinerary takes one day.
 */
export function isOneDayTrip(itinerary) {
  const departure = getItineraryDeparture(itinerary);
  const arrival = getItineraryArrival(itinerary);

  return (
    departure.getFullYear() === arrival.getFullYear() &&
    departure.getMonth() === arrival.getMonth() &&
    departure.getDate() === arrival.getDate()
  );
}

/**
 * Checks if route has stop over
 * @param {object} route - route object.
 */
export function doesRouteHaveStopOver(route) {
  let hasStopOver = false;

  route.getFlightplan().forEach((item) => {
    if (item instanceof StopOver) {
      hasStopOver = true;
    }
  });

  return hasStopOver;
}

/**
 * Checks if itinerary has a non stop flight
 * @param {object} itinerary
 */
export function isNonStop(itinerary) {
  const routes = itinerary.getRoutes();
  let hasSecondRoute = null;
  let secondRouteHasStopOver = false;
  let firstRouteHasStopOver = doesRouteHaveStopOver(routes[0]);

  if (routes.length > 1) {
    hasSecondRoute = true;
    secondRouteHasStopOver = doesRouteHaveStopOver(routes[1]);
  }

  if (hasSecondRoute) {
    return !firstRouteHasStopOver && !secondRouteHasStopOver;
  }
  return !firstRouteHasStopOver;
}

/**
 * Checks if vendor fare is an airline
 * @param {Object} vendors
 * @param {Object} fare
 * @returns Boolean if fare is airline fare or not
 */
export function isAirlineFare(vendors, fare) {
  if (fare.isMulti()) {
    return false;
  }

  const key = fare.getVendor().getKey();

  return vendors.getIn([key.toString(), 'is-airline']);
}

/**
 * Checks if vendorFiltesr object includes a Dohop vendor key and if the key is true
 * @param {Object} vendorFilters - vendors filters object
 * @returns {Boolean}
 */
function showDohopTicketsFromFiltering(vendorFilters) {
  let showDohopTickets = false;

  Object.keys(vendorFilters).forEach((vendorKey) => {
    if (
      vendorFilters.hasOwnProperty(vendorKey) &&
      vendorFilters[Number(vendorKey)] &&
      DOHOP_VENDORS.includes(vendorKey)
    ) {
      showDohopTickets = true;
    }
  });

  return showDohopTickets;
}

/**
 * Takes the list of all alternatives and filters out the one we don't want to offer and sort
 * the items in our preferred order.
 * @param {object} itinerary - The itinerary object.
 * @param {object <Immutable.Map>} vendors - Object with information about all available vendors.
 * @param {object} vendorFilters - The vendor filter state.
 */
export function sortAndFilterVendorFares(itinerary, vendors, vendorFilters = {}) {
  const bestFare = itinerary.getFares().getBestFare();
  const singleTicket = itinerary.getFares().getSingleTicket();
  const tickets = singleTicket ? singleTicket.getVendorFares().slice(0) : [];
  const ticketsToSort = bestFare.isMulti() ? [bestFare, ...tickets] : tickets;
  let whitelabelFares = List();
  let dohopFares = List();
  let airlineFares = List();
  let restOfFares = List();
  let displayedFares = List();

  ticketsToSort.forEach((ticket) => {
    const isWhitelabelTicket = isWhitelabelFare(ticket);

    if (isWhitelabelTicket) {
      whitelabelFares = whitelabelFares.push(ticket);
    } else if (isDohopFare(ticket)) {
      dohopFares = dohopFares.push(ticket);
    } else if (isAirlineFare(vendors, ticket)) {
      airlineFares = airlineFares.push(ticket);
    } else {
      restOfFares = restOfFares.push(ticket);
    }
  });

  if (whitelabelFares.size > 0) {
    if (showDohopTicketsFromFiltering(vendorFilters)) {
      displayedFares = whitelabelFares.push(...dohopFares, ...airlineFares, ...restOfFares);
    } else {
      displayedFares = whitelabelFares.push(...airlineFares, ...restOfFares);
    }
  } else {
    // Otherwise we show all the results available, with sorted with Dohop on top,
    // then airline tickets and finally the rest.
    displayedFares = dohopFares.push(...airlineFares, ...restOfFares);
  }

  return {
    whitelabelFares,
    dohopFares,
    airlineFares,
    restOfFares,
    displayedFares,
  };
}
