import time from 'dohop-client-api/src/time';
import { Flight, StopOver } from 'dohop-client-api/src/flight';
import { assert } from 'dohop-client-api/src/util';

// flightplan converts an itinerary into a datastructure that is easy to display
// detailed information about an itinerary.
export default function fun(itinerary, { shortTransferTime = 0 } = {}) {
  let flightplan = [];

  itinerary.getRoutes().forEach((route) => {
    if (!route.isOutbound()) flightplan.push({ type: 'separator' });

    let prevEvent = null;
    let flightsAndStops = route.getFlightplan();

    for (let i = 0; i < flightsAndStops.length; i++) {
      let item = flightsAndStops[i];
      let prev = flightsAndStops[i - 1];
      let next = flightsAndStops[i + 1];

      let event = {
        item,
        airports: item.getAirports(),
        duration: item.getDuration(),
      };

      if (item instanceof Flight) {
        event.type = 'flight';
        event.start = item.getDeparture();
        event.end = item.getArrival();
        event.airports = item.getAllAirports();
        event.newDayDeparture = Boolean(prevEvent && time.daysApart(prevEvent.start, event.start));
        event.newDayArrival = Boolean(time.daysApart(event.start, event.end));
      } else {
        assert(item instanceof StopOver);
        event.type = 'wait';
        event.start = item.getStartDate();
        event.end = item.getEndDate();
        event.isSelfConnect = Boolean(
          prev && next && itinerary.getFares().getBestFare().isSelfConnectConnection(prev, next)
        );

        event.isSelfTransfer = Boolean(
          prev && next && prev.getAirline()._iata !== next.getAirline()._iata
        );
        event.shortTransfer = event.isSelfConnect && item.getDuration() < shortTransferTime;

        // add ground transit if needed
        if (item.isGroundTransit()) {
          const transferTime = item.getGroundTransitDuration();
          event.groundTransit = {
            duration: transferTime,
            start: event.start,
            end: new Date(event.start.getTime() + transferTime),
            airports: event.airports,
          };

          event.airports = [event.airports[event.airports.length - 1]];
          event.start = new Date(event.start.getTime() + transferTime);
          event.duration -= transferTime;
        }
      }

      if (!(item instanceof StopOver)) {
        if (!prevEvent) {
          flightplan.push({
            type: 'heading',
            isOutbound: route.isOutbound(),
            date: event.start,
          });
        }
      }

      // after we've pushed the heading we add the actual flight/wait
      flightplan.push(event);
      prevEvent = event;
    }
  });

  return flightplan;
}
