import React from 'react';
import './PlainPage.less';

export default class PlainPage extends React.Component {
  render() {
    return (
      <div className="PlainPage">
        <div className="PlainPage__padding">
          <div className="PlainPage__content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
