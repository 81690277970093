import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Map, List } from 'immutable';
import { resetFilters, fetchGo, imageSize } from '@actions/goActions';
import { trackGoPrice } from '../../lib/tracking';
import createFilters from '../../lib/createFilters';
import getFlightUrl, { getHotelUrl } from '../../lib/getFlightUrl';
import RecommendedHotels from '../ads/RecommendedHotels';
import AllResultsFiltered from '../flights/AllResultsFiltered';
import Overlay from '../modal/Overlay';
import MatchMedia from '../MatchMedia';
import Loading from '../Loading';
import Button from '../Button';
import MobileFilters from './MobileGoFilters';
import GoShareDestination from './GoShareDestination';
import './GoContent.less';

class GoContent extends Component {
  state = {
    flightsShow: this.props.init || 20,
  };

  getFlightDates(flight, format) {
    let dates = this.props.formatDate(flight.get('departureDate'), format);
    if (flight.get('returnDate')) {
      dates += ` - ${this.props.formatDate(flight.get('returnDate'), format)}`;
    }
    return dates;
  }

  getIsSearchLoading() {
    return (
      (this.props.search && !this.props.search.isDone() && !this.props.search.hasResults()) ||
      (this.props.searchIsLoading && this.props.results.size === 0)
    );
  }

  renderResult(flight, iFlight) {
    const { t } = this.props;
    const flightUrl = getFlightUrl(flight, this.props.adults, this.props.childrenAges);

    return (
      <Link
        target="_blank"
        key={iFlight}
        to={flightUrl}
        className="GoContent__flightDetails"
        onClick={() => trackGoPrice(flightUrl, flight.get('price'))}
      >
        <div className="GoContent__flightDetails__column GoContent__price">
          <Button comp="span" className="GoContent__price__button">
            {this.props.formatCurrency(flight.get('price'))}
          </Button>
        </div>
        <div className="GoContent__flightDetails__column GoContent__airportsAndDates">
          <div className="GoContent__flightDetails__column GoContent__dates">
            <MatchMedia max="mobile">{this.getFlightDates(flight, 'D MMM')}</MatchMedia>
            <MatchMedia min="mobile">{this.getFlightDates(flight, 'D MMM YYYY')}</MatchMedia>
          </div>
          {flight.get('returnDate') && (
            <div className="GoContent__flightDetails__column GoContent__stay">
              {moment
                .utc(flight.get('returnDate'))
                .diff(moment.utc(flight.get('departureDate')), 'days')}{' '}
              {t('days')}
            </div>
          )}
          <div className="GoContent__flightDetails__column GoContent__airports">
            <span className="GoContent__airport--from">{flight.getIn(['from', 0])}</span>
            <span className="GoContent__airportIcon" />
            <span className="GoContent__airport--to">{flight.getIn(['to', -1])}</span>
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { t, results, hotels, destination } = this.props;
    const flights = results
      .slice(0, this.state.flightsShow)
      .map(this.renderResult.bind(this))
      .toArray();
    const headerImageSize = 'l';
    const headerImage = results.size
      ? `${results.getIn([0, 'image']).replace(imageSize, headerImageSize)}`
      : '';

    return (
      <div className="GoContent">
        <div className="GoContent__topWrapper">
          <div className="GoContent__mobileFilters">
            <MatchMedia max="desktop">
              <MobileFilters t={t} showButtonText />
            </MatchMedia>
          </div>
        </div>
        <div
          className={classNames('GoContent__flights', {
            'GoContent__flights--withHotels':
              hotels && hotels.get('hotels') && hotels.get('hotels').size,
          })}
        >
          {results.size > 0 && (
            <div className="GoContent__flights__container">
              <div
                className="GoContent__header GoContent__header--results"
                style={{
                  backgroundImage: `url(${headerImage})`,
                }}
              >
                <Overlay absolute>
                  <div className="GoContent__resultsHeader__titleContainer">
                    <div className="GoContent__icon" />
                    <div className="GoContent__resultsHeader__text">
                      {t('great_fares_to %(destination)s', {
                        destination: destination && destination.get('name'),
                      })}
                    </div>
                  </div>
                  <div className="GoContent__warning">{t('go_warning')}</div>
                  <div className="GoContent__shareDestination">
                    <GoShareDestination key="sharedestination" t={t} />
                  </div>
                </Overlay>
              </div>

              {flights}
              {this.state.flightsShow < this.props.results.size && (
                <div
                  className="GoContent__more GoContent__more--flights"
                  onClick={() => this.setState({ flightsShow: this.state.flightsShow + 6 })}
                >
                  {t('show_more_flights')}
                </div>
              )}
            </div>
          )}
          {this.props.results.size === 0 && !this.getIsSearchLoading() && !this.props.isLoading && (
            <AllResultsFiltered t={t} onReset={() => this.props.reset()} />
          )}
          {this.getIsSearchLoading() && <Loading text={t('gettingprice') + '...'} />}
        </div>
        {hotels && hotels.get('hotels') && hotels.get('hotels').size > 0 && (
          <div className="GoContent__hotels">
            <div className="GoContent__header">
              <div className="GoContent__icon GoContent__icon--hotels" />
              <div className="GoContent__header__text">
                {t('book_hotels_in %(city)s', {
                  city: this.props.destination.get('name'),
                })}
              </div>
            </div>
            <MatchMedia max="laptop">
              <RecommendedHotels hideText horizontal hotels={hotels} limit={3} />
            </MatchMedia>
            <MatchMedia min="laptop">
              <RecommendedHotels hideText hotels={hotels} limit={3} />
            </MatchMedia>
            <a
              className="GoContent__more"
              href={getHotelUrl(
                Map({ id: hotels.getIn(['hotels', 0, 'cityId']) }),
                this.props.user
              )}
              target="_blank"
            >
              {t('show_more_hotels')}
            </a>
          </div>
        )}
      </div>
    );
  }
}

GoContent = createFilters(['t', 'formatDate', 'formatCurrency'])(GoContent);

export default connect(
  (state) => ({
    origin: state.getIn(['go', 'origin']),
    destination: state.getIn(['go', 'destination']),
    results: state
      .getIn(['go', 'results', state.getIn(['go', 'activeKey'])], List())
      .filter((result) => !result.get('disabled') || result.get('disabled') === 'false'),
    hotels: state.getIn(['hotel', 'recommended']),
    adults: state.getIn(['flightSearchForm', 'adults']),
    childrenAges: state.getIn(['flightSearchForm', 'childrenAges']),
    user: state.get('user'),
    search: state.getIn(['flightSearch', 'search']),
    searchIsLoading: state.getIn(['go', 'searchIsLoading']),
    isLoading: state.getIn(['go', 'loading']),
  }),
  (dispatch) => ({
    reset: () => {
      dispatch(resetFilters());
      dispatch(fetchGo());
    },
  })
)(GoContent);
