import config from './config';

/**
 * Checks if config.SUPPORTED_LANGUAGES includes language code
 * @param {String} language - Language code
 * @returns {Boolean}
 */
function isSupportedLanguage(language) {
  return config.SUPPORTED_LANGUAGES.includes(language);
}

export default isSupportedLanguage;
