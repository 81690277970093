import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../../MatchMedia';
import MobileView from '../../MobileView';
import Filters from '../Filters';
import SortButtons from '../SortButtons';
import FilterFeedback from '../../FilterFeedback';
import ButtonRounded from '../../ButtonRounded';
import SortIcon from '@content/svg/sort_green.svg';
import FilterIcon from '@content/svg/filter_green.svg';
import './MobileFilters.less';

class MobileFilters extends Component {
  state = {
    // Filters/sort view
    open: false,
    // If true we show filters view otherwise sort view
    toggleFilters: false,
  };

  /**
   * Opens filters view
   */
  openFiltersView = () => {
    const { search } = this.props;
    const filters = search.getFilters().clone();
    this.setState({ open: true, filters, toggleFilters: true });
  };

  /**
   * Opens sorting buttons view
   */
  openSortView = () => {
    this.setState({ open: true, toggleFilters: false });
  };

  /**
   * Closes mobile view
   */
  onClose = () => {
    this.setState({ open: false, filters: undefined });
  };

  /**
   * Returns the number as a string of the results after filters have been applied
   * @returns {String}
   */
  getResultCount() {
    const { search } = this.props;
    const max = 200;
    const itinAvailableCount = search.getItineraries().getLengthOrMax(max);
    const maybePlus = itinAvailableCount === max ? '+' : '';
    return String(`${itinAvailableCount}${maybePlus}`);
  }

  render() {
    const { t, search, location, filters, onChangeSort } = this.props;
    const { open, toggleFilters } = this.state;
    const resultCount = this.getResultCount();

    return (
      <MatchMedia max="desktop" className="MobileFilters">
        <ButtonRounded
          color="ocean"
          onClick={this.openSortView}
          className="MobileFilters__button MobileFilters__button--sort"
        >
          <span className="MobileFilters__buttonInnerContainer">
            <SortIcon className="MobileFilters__filterIcon" />
            {t('Sort By')}
          </span>
        </ButtonRounded>
        <ButtonRounded
          color="ocean"
          onClick={this.openFiltersView}
          className="MobileFilters__button MobileFilters__button--filter"
        >
          <span className="MobileFilters__buttonInnerContainer">
            <FilterIcon className="MobileFilters__filterIcon" />
            {t('Filters')}
          </span>
        </ButtonRounded>
        {open && (
          <MobileView
            onClose={this.onClose}
            title={t(toggleFilters ? 'Filters' : 'Sort By')}
            showConfirm={!toggleFilters}
          >
            {toggleFilters ? (
              <Fragment>
                <Filters mobile search={search} location={location} />
                <FilterFeedback
                  apply={t('apply')}
                  showingResults={t('filter_result_count', {
                    resultCount,
                  })}
                  onClose={this.onClose}
                />
              </Fragment>
            ) : (
              <SortButtons search={search} filters={filters} onChangeSort={onChangeSort} />
            )}
          </MobileView>
        )}
      </MatchMedia>
    );
  }
}

MobileFilters.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
};

export default MobileFilters;
