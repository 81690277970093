export default function formatCurrency(context, amount, options = {}) {
  let { currencies, currencyRates, user, translations } = context;

  if (isNaN(amount)) throw new Error('NaN is not a number');
  let base = options.base || 'EUR';
  let target = options.target || user.get('currency');

  if (!currencies) throw new Error('Can not format without currencies');
  if (!currencies.get(base)) throw new Error(`Unknown base currency: ${base}`);
  if (!currencies.get(target)) throw new Error(`Unknown target currency: ${target}`);

  let currency = currencies.get(base);
  let format = `${base} *`;
  let targetAmount = amount;

  if (currencyRates.has(base) && currencyRates.has(target)) {
    currency = currencies.get(target);
    format = currency.get('format') || `${target} *`;
    targetAmount = amount * currencyRates.get(target) / currencyRates.get(base);
  }

  let expo = 0;
  if (options.expo === true) expo = currency.get('expo');

  let thousandSeparator = translations.get('thousandseparator');
  let decimalSeparator = translations.get('decimalseparator');

  let [major, minor] = parseFloat(targetAmount)
    .toFixed(expo)
    .split('.');
  let formattedAmount = major.replace(/\d(?=(\d{3})+$)/g, '$&' + thousandSeparator);
  if (minor) formattedAmount += decimalSeparator + minor;

  return format.replace('*', formattedAmount);
}
