import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import time from 'dohop-client-api/src/time';
import { decodeURLQueryData } from 'shared/utils/transformURLQueryData';
import * as flightSearchActions from '@actions/flightSearchActions';
import * as flightSearchFormActions from '@actions/flightSearchFormActions';
import * as userActions from '@actions/userActions';
import { fetchRecommendedHotels } from '@actions/hotelActions';
import createFilters from '@lib/createFilters';
import fetchData from '@lib/fetchData';
import config from '@lib/config';
import { getStore } from '@store/configureStore';
import SearchPage from '@components/flights/SearchPage';
import TransferStep from '@components/transferStep/TransferStep';
import RecaptchaLinks from '@components/RecaptchaLinks';

const { TRANSFER_STEP_URL } = config;

function cleanNumber(s, min, max) {
  const n = Number(s);
  if (s && !isNaN(n) && min <= n && n <= max) return n;
}

/**
 * Checks if argument is number
 *
 * @param {String|Number} num
 * @return {Boolean} True if `num` is a `Int Number`.
 */
function isIntNumber(num) {
  return !isNaN(parseInt(num, 10)) && isFinite(num);
}

export function getSearchParams(params) {
  const { origin, destination, departureDate, returnDate } = params;
  let { adults, children } = params;

  if (adults) {
    const adultsArr = adults.split('-');
    // Check if any element in array is a integer number
    for (let i = 0; i < adultsArr.length; i++) {
      if (isIntNumber(adultsArr[i])) {
        adults = adultsArr[i];
        break;
      }
    }
  }

  adults = cleanNumber(adults, 1, 9) || 1;

  if (children) {
    children = children.replace(/children-/g, '');
  }

  const childrenAges = (children || '')
    .split('-')
    .map((n) => cleanNumber(n, 0, 17))
    .filter((n) => n != null);

  return {
    origin: origin.replace(/-/g, ','),
    destination: destination.replace(/-/g, ','),
    departureDate: time.strptime('%Y-%m-%d', departureDate),
    returnDate: params.returnDate && time.strptime('%Y-%m-%d', returnDate),
    adults,
    childrenAges,
  };
}

class SearchPageRoute extends Component {
  state = { version: 0, showTransferStep: false, currentSearchKey: null };

  reloadSearch(params) {
    this.props.flightSearchActions.initializeFlightSearch(getSearchParams(params));
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    await getStore().dispatch(flightSearchActions.initializeFlightSearch(getSearchParams(params)));
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params: prevParams },
    } = prevProps;
    const {
      match: { params },
    } = this.props;

    let shouldUpdate = false;

    for (const [key, value] of Object.entries(prevParams)) {
      if (params[key] !== value) {
        shouldUpdate = true;
        break;
      }
    }

    if (shouldUpdate) {
      this.reloadSearch(params);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { showTransferStep, currentSearchKey, version } = state;
    const { location, flightSearch } = props;
    const { hash } = location;
    const search = flightSearch.get('search');

    if (!search) {
      return null;
    }

    const newSearchKey = search.key;

    if (!currentSearchKey) {
      return { ...state, currentSearchKey: newSearchKey };
    }

    // when the search object changes we throw away the DOM/components and
    // render everything from scratch
    if (currentSearchKey !== newSearchKey) {
      return { ...state, currentSearchKey: newSearchKey, version: version + 1 };
    }

    if (hash.includes(TRANSFER_STEP_URL) && !showTransferStep) {
      return { ...state, showTransferStep: true };
    }

    if (!hash.includes(TRANSFER_STEP_URL) && showTransferStep) {
      return { ...state, showTransferStep: false };
    }

    return null;
  }

  render() {
    const { version, showTransferStep } = this.state;
    const {
      flightSearch,
      location,
      location: { hash },
      match: { params },
      filters,
    } = this.props;

    if (!flightSearch.get('search')) return null;

    const isTransferStep = hash.includes(TRANSFER_STEP_URL);

    return (
      /* Position fixed when transferStep open so no scroll */
      <Fragment>
        <div style={{ position: showTransferStep && 'fixed' }}>
          <SearchPage
            key={version}
            search={flightSearch.get('search')}
            searchData={flightSearch}
            reloadSearch={() => this.reloadSearch(params)}
            itineraryKey={decodeURLQueryData(location.search).i}
            {...this.props}
          />
          {isTransferStep && (
            <TransferStep
              search={flightSearch.get('search')}
              filters={filters}
              params={params}
              searchData={flightSearch}
              itineraryKey={decodeURLQueryData(location.search).i}
            />
          )}
        </div>
        <RecaptchaLinks t={filters.t} isSearchPage />
      </Fragment>
    );
  }
}

SearchPageRoute = createFilters('filters')(SearchPageRoute);

SearchPageRoute = connect(
  (state) => ({
    flightSearch: state.get('flightSearch'),
    form: state.get('flightSearchForm'),
    user: state.get('user'),
    flexible: state.getIn(['flightSearch', 'flexible']),
    recommendedItineraries: state.getIn(['flightSearch', 'recommendedItineraries']),
  }),
  (dispatch) => ({
    flightSearchActions: bindActionCreators(flightSearchActions, dispatch),
    formActions: bindActionCreators(flightSearchFormActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  })
)(SearchPageRoute);

// Pre fetch data on server
SearchPageRoute = fetchData(SearchPageRoute, async (store, { params, location, prevLocation }) => {
  const searchParams = getSearchParams(params);
  const prevPathName = prevLocation ? prevLocation.pathname : '';
  const pathName = location ? location.pathname : '';
  const searchAlreadyInitialized = prevPathName === pathName;

  // We want to make sure we don't start a new search if we have already initialized it.
  if (!searchAlreadyInitialized) {
    return await store.dispatch(fetchRecommendedHotels(searchParams.destination));
  }
});

export default SearchPageRoute;
