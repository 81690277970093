import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { queries } from '@components/MatchMedia';
import Result from './Result';
import './Results.less';

const AutocompleteResults = ({
  t,
  hasFocus,
  results,
  noResults,
  onChangeSelected,
  onSelect,
  selectedIndex,
  id,
}) => {
  if (results.length === 0 || !hasFocus) {
    return null;
  }

  const { ipad } = queries;
  const isShowingRecentSearches = results[0].item.get('recentSearch');
  const hasHeader = isShowingRecentSearches || noResults;

  return (
    <div className="AutocompleteResults">
      <div
        className={classNames('AutocompleteResults__arrow', {
          'AutocompleteResults__arrow--sunburn':
            selectedIndex === 0 && !hasHeader && ipad && ipad.matches,
        })}
      />
      <ul className="AutocompleteResults__content">
        {isShowingRecentSearches && (
          <li className="AutocompleteResults__header">{t('recent_searches')}</li>
        )}
        {noResults && <li className="AutocompleteResults__header">{t('noresults')}</li>}
        {results.map((result, idx) => {
          const isActive = idx === selectedIndex;

          return (
            <Result
              key={idx}
              idx={idx}
              id={id}
              result={result}
              isActive={isActive}
              onMouseOver={onChangeSelected}
              onClick={onSelect}
            />
          );
        })}
      </ul>
    </div>
  );
};

AutocompleteResults.defaultProps = {
  id: '',
  selectedIndex: 0,
};

AutocompleteResults.propTypes = {
  id: PropTypes.string,
  results: PropTypes.array.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
};

export default AutocompleteResults;
