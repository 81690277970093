/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var solve = function (i, bitsNeeded, lsbToBits, solutions) {
  // if we have a solution
  if (bitsNeeded === 0) {
    solutions.push([i]);
    return;
  }

  // otherwise run the solver again, adding fares that cover the first missing bit
  return (() => {
    let left;
    const result = [];
    for (let [j, bits] of Array.from(
      (left = lsbToBits[bitsNeeded & -bitsNeeded]) != null ? left : []
    )) {
      if ((bits & bitsNeeded) === bits) {
        const sLen = solutions.length;
        solve(j, bits ^ bitsNeeded, lsbToBits, solutions);
        result.push(__range__(sLen, solutions.length, false).map((k) => solutions[k].push(i)));
      } else {
        result.push(undefined);
      }
    }
    return result;
  })();
};

const exactCover = function (v, n) {
  let bits, i;
  const bitsNeeded = (1 << n) - 1;

  // early exit if there can't be a solution
  let bitset = 0;
  for (bits of Array.from(v)) {
    bitset |= bits;
  }
  if (bitset !== bitsNeeded) {
    return [];
  }

  // map least significant bit to index and bits
  const lsbToBits = {};
  for (i = 0; i < v.length; i++) {
    var name;
    bits = v[i];
    (lsbToBits[(name = bits & -bits)] != null ? lsbToBits[name] : (lsbToBits[name] = [])).push([
      i,
      bits,
    ]);
  }

  // run solver starting with each bits in v where first bit is set
  const solutions = [];
  for ([i, bits] of Array.from(lsbToBits[1] != null ? lsbToBits[1] : [])) {
    solve(i, bits ^ bitsNeeded, lsbToBits, solutions);
  }

  // reverse solutions to get the indexes in the order of first set bit
  for (let s of Array.from(solutions)) {
    s.reverse();
  }

  return solutions;
};

module.exports = exactCover;

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
