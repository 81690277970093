/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _omit = require('lodash/omit');
const _clone = require('lodash/clone');
const _isEmpty = require('lodash/isEmpty');
const _extend = require('lodash/extend');
const { EventEmitter } = require('events');

const config = require('./config');
const util = require('./util');
const http = require('./http');
const encode = encodeURIComponent;

const DEFAULT_API_VERSION = 1;

const SERVICES = {
  1: {
    language: [],
    locale: ['language'],
    picker: ['index', 'language', 'input'],
    days: ['index', 'language', 'from', 'to'],
    search: ['index', 'language', 'residency', 'from', 'to', 'd1', 'd2?'],
    poll: ['key', 'continuation'],
    refresh: ['key'],
    transfer: ['key', 'fareId', 'vendorId', 'currency'],
    vendor: ['id'],
  },
  2: {
    days: ['index', 'language', 'from', 'to'],
    picker: ['index', 'language', 'input'],
  },
  3: {
    days: ['index', 'language', 'from', 'to'],
    transfer: ['key', 'fareId', 'vendorId', 'fareClass', 'currency'],
  },
  4: {
    poll: ['key', 'continuation'],
    transfer: ['key', 'fareId', 'vendorId', 'fareClass', 'currency'],
    search: ['index', 'language', 'residency', 'fareClass', 'from', 'to', 'd1', 'd2?'],
  },
};

// @nodoc
const servicePath = function (service, options) {
  let k, v;
  const apiVersion = options.apiVersion || DEFAULT_API_VERSION;
  if (!SERVICES[apiVersion][service]) {
    throw new Error(`unknown service: ${service}`);
  }

  options = _clone(options);
  if (!options.language) {
    options.language = config.language;
  }
  if (!options.index) {
    options.index = config.index;
  }

  const path = [service];
  for (k of Array.from(SERVICES[apiVersion][service])) {
    const optional = k.slice(-1) === '?';
    if (optional) {
      k = k.slice(0, -1);
    }
    v = util.popKey(options, k);
    if (v != null) {
      path.push(encode(v));
    } else if (!optional) {
      throw new Error(`service ${service}: missing required parameter: ${k}`);
    }
  }

  let url = `/api/v${apiVersion}/${path.join('/')}`;
  options = _omit(options, 'language', 'index', 'success', 'error', 'apiVersion', 'token');
  if (!_isEmpty(options)) {
    url +=
      '?' +
      (() => {
        const result = [];
        for (k in options) {
          v = options[k];
          if (v != null) {
            result.push(`${encode(k)}=${encode(v)}`);
          }
        }
        return result;
      })().join('&');
  }
  return url;
};

// call a specific api service
// @param [String] service Service name
// @param [Object] options
// @option options [Function] success Success callback if server response is successful
// @option options [Function] error Error callback if server responds with error
// @option options [Object] * parameters to pass to service
const get = function (service, options) {
  let path;
  if (service != null) {
    path = servicePath(service, options);
  } else {
    path = options.fullPath;
  }

  options = _extend({ success() {}, error() {} }, options || {});
  return http.get({
    hostname: `${config.domainPrefix}${config.apiDomain}`,
    path: `${config.searchAPIPrefix}${path}`,
    success(response) {
      for (let k of ['error', 'internal_service_error']) {
        if (k in response) {
          options.error(new Error(response[k]));
          return;
        }
      }
      return options.success(response);
    },
    error: options.error,
  });
};

// POST request to a specific api service
// @param [String] service Service name
// @param [Object] options
// @param [Object] postData
// @option options [Function] success Success callback if server response is successful
// @option options [Function] error Error callback if server responds with error
// @option options [Object] * parameters to pass to service
const post = function (service, options, postData) {
  let path;
  if (service != null) {
    path = servicePath(service, options);
  } else {
    path = options.fullPath;
  }

  return http.post({
    postData,
    hostname: `${config.domainPrefix}${config.apiDomain}`,
    path: `${config.searchAPIPrefix}${path}`,
    error: options.error,
    success(response) {
      for (let k of ['error', 'internal_service_error']) {
        if (k in response) {
          options.error(new Error(response[k]));
          return;
        }
      }
      return options.success(response);
    },
  });
};

exports.servicePath = servicePath;
exports.get = get;
exports.post = post;
