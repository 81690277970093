import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import Portal from '../modal/Portal';
import Hamburger from '../Hamburger';
import FlightIcon from '@content/svg/flight.svg';
import HotelIcon from '@content/svg/hotel.svg';
import CarIcon from '@content/svg/car.svg';
import GoIcon from '@content/svg/roadsign.svg';
import AboutIcon from '@content/svg/group.svg';
import BusinessIcon from '@content/svg/handshake.svg';
import ContactIcon from '@content/svg/phone.svg';
import ConnectIcon from '@content/svg/Self-Connect-ocean.svg';
import LocationIcon from '@content/svg/location.svg';
import './MobileMenu.less';

class MobileMenu extends Component {
  state = { isOpen: false };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderLink(url, text, svg, isExternal = false) {
    const isSameHost = !isExternal && url.startsWith('/') && url[1] !== '/';
    const Comp = isSameHost ? Link : 'a';
    const linkProps = isSameHost ? { to: url } : { href: url };
    return (
      <div className="MobileMenu__item">
        <Comp {...linkProps} className="MobileMenu__link">
          {svg}
          {text}
        </Comp>
      </div>
    );
  }

  renderMenu() {
    const { t } = this.props;
    const iconClassName = 'MobileMenu__icon';

    return (
      <Portal>
        <div>
          <div className="MobileMenu__overlay" onClick={this.onToggle} />
          <div className="MobileMenu__menu">
            {this.renderLink('/', t('flights'), <FlightIcon className={iconClassName} />)}
            {this.renderLink('/hotels', t('hotels'), <HotelIcon className={iconClassName} />)}
            {this.renderLink('/cars', t('cars'), <CarIcon className={iconClassName} />)}
            {this.renderLink('/go', t('Go'), <GoIcon className={iconClassName} />)}
            {this.renderLink(
              'https://experiences.dohopconnect.com/',
              t('experiences'),
              <LocationIcon className={iconClassName} />
            )}
            {this.renderLink(
              '/connect',
              t('Connect'),
              <ConnectIcon className={iconClassName} />,
              true
            )}
            {this.renderLink(
              'https://b2b.dohop.com',
              t('B2B'),
              <BusinessIcon className={iconClassName} />
            )}
            {this.renderLink(
              '/about/about-us',
              t('aboutdohop'),
              <AboutIcon className={iconClassName} />
            )}
            {this.renderLink(
              '/about/contact-us/',
              t('contact_us'),
              <ContactIcon className={iconClassName} />
            )}
            <div className="MobileMenu__close" onClick={this.onToggle}>
              <div className="MobileMenu__x" />
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  render() {
    const { className, hamburgerWhite, children, hamburgerRight } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={classNames('MobileMenu', className, {
          MobileMenu__hamburgerRight: hamburgerRight,
        })}
      >
        <Hamburger onClick={this.onToggle} white={hamburgerWhite} />
        {isOpen && this.renderMenu()}
        {children}
      </div>
    );
  }
}

MobileMenu.defaultProps = {
  hamburgerWhite: false,
  children: null,
  hamburgerRight: false,
};

MobileMenu.propTypes = {
  t: PropTypes.func.isRequired,
  hamburgerWhite: PropTypes.bool,
  children: PropTypes.node,
  hamburgerRight: PropTypes.bool,
};

export default createFilters(['t'])(MobileMenu);
