import React from 'react';
import classNames from 'classnames';
import Bar from './Bar';
import './ContentHeader.less';

/* Header component with customizable content via props.children */
const ContentHeader = ({
  results,
  tab,
  url,
  children,
  subtitle,
  onClickTitle,
  title,
  noPadding = false,
}) => {
  const props = {
    className: 'ContentHeader__content ContentHeader__contentLeft',
  };

  // If page is either /flights or /go then add these props
  if (results) {
    props.className = classNames('ContentHeader__content', {
      'ContentHeader__titles--clickable': onClickTitle,
    });
    props.onClick = onClickTitle;
    props.role = 'button';
    props.tabIndex = 0;
  }

  return (
    <div className={classNames('ContentHeader', { 'ContentHeader--results': results })}>
      <Bar tab={tab} url={url} noPadding={noPadding} />
      <div className="ContentHeader__container">
        <div className="ContentHeader__banner" />
        {children ? (
          <div className="ContentHeader__content">{children}</div>
        ) : (
          <div {...props}>
            <div
              className={classNames('ContentHeader__titles', {
                'ContentHeader__titles--clickable': onClickTitle,
              })}
            >
              <div className="ContentHeader__title">{title}</div>
              {Boolean(subtitle) && <div className="ContentHeader__subtitle">{subtitle}</div>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentHeader;
