import Immutable from 'immutable';
import { SET_FILTER_VENDORS } from '../actions/filtersActions';

const DEFAULT_STATE = Immutable.Map({
  vendors: {},
});

export default function filtersReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_FILTER_VENDORS:
      return state.set('vendors', action.payload);
    default:
      return state;
  }
}
