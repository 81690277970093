import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { VendorFare } from 'dohop-client-api/src/flight';
import { isAirlineFare } from '@lib/itineraryInfo';
import TransferLink from '../../../flights/TransferLink';
import isDohopFare from '../../../../lib/isDohopFare';
import isWhitelabelFare from '../../../../lib/isWhitelabelFare';
import './VendorTile.less';

class VendorTile extends Component {
  /**
   * @param {Object} fare
   * @returns {String} of airline names
   * @memberof VendorTilea
   */
  getAirlineNames(fare) {
    const { filters } = this.props;
    const { t } = filters;
    const airlines = [];

    fare.getFares().forEach((f) => {
      f.getAirlines().forEach((a) => {
        if (airlines.indexOf(a.getName() === -1)) {
          airlines.push(a.getName());
        }
      });
    });

    if (airlines.length > 3) {
      return t('multiple_airlines');
    }

    return airlines.join(' + ');
  }

  getVendorInfo(fare) {
    const {
      vendors,
      filters: { t },
    } = this.props;

    let vendorInfo = null;
    let hasLabel = false;

    if (fare.isComplete && !fare.isComplete()) {
      vendorInfo = null;
    } else if (fare.isMulti()) {
      vendorInfo = t('multiple tickets');
      hasLabel = true;
    } else if (isWhitelabelFare(fare) || isDohopFare(fare)) {
      vendorInfo = this.getVendorData(
        isDohopFare(fare) ? t('recommended_book_with_dohop') : 'Powered by Dohop',
        fare.getVendor().getName()
      );
      hasLabel = true;
    } else if (isAirlineFare(vendors, fare)) {
      vendorInfo = this.getVendorData(t('book_with_airline'), fare.getVendor().getName());
      hasLabel = true;
    } else {
      vendorInfo = fare.getVendor().getName();
    }

    return {
      vendorInfo,
      hasLabel,
    };
  }

  /**
   * @param {String} label - extra label information
   * @param {String} name - the name of vendor
   * @param {String} extraClassNames - classnames if wanted
   * @returns React component wrapping inputs
   * @memberof VendorTile
   */
  getVendorData(label, name, extraClassNames) {
    return (
      <div className="VendorTile__extraVendorInfo">
        <span className={classNames('VendorTile__bookWithFlag', extraClassNames)}>{label}</span>
        <span>{name}</span>
      </div>
    );
  }

  /**
   * Get text of button which
   * takes user to booking process
   * @param {Object} fare
   * @returns {String}
   * @memberof VendorPicker
   */
  getConfirmBtnTxt(fare) {
    const { filters } = this.props;
    const { t } = filters;

    if (isDohopFare(fare)) {
      return t('book');
    }

    if (fare.isMulti()) {
      return t('select');
    }

    return t('go_to_site');
  }

  render() {
    const {
      fare,
      search,
      bestFare,
      itinerary,
      openMultiFare,
      filters: { t, formatCurrency },
    } = this.props;

    const pickedProps = _pick(this.props, 'search', 'itinerary', 'fare', 'user');
    const linkedProps = { ...pickedProps, onClickMulti: openMultiFare.bind(null, fare) };
    const isMultiPassenger = search.getMeta().getNumPassengers() > 1;
    const { vendorInfo, hasLabel } = this.getVendorInfo(fare);

    return (
      <div className="VendorTile">
        <div
          className={classNames('VendorTile__content', {
            'VendorTile__content--extraPaddingTop': hasLabel,
          })}
        >
          <span className="VendorTile__vendor">{vendorInfo}</span>
          <span className="VendorTile__price">
            {fare instanceof VendorFare || fare.isComplete() ? (
              <div className="VendorTile--fadeIn">
                <strong>{formatCurrency(fare.getAverage())}</strong>
                {/* More than one passenger in the trip */}
                {isMultiPassenger && (
                  <div className="VendorTile__multiPaxPrice">
                    {formatCurrency(fare.getTotal())} {t('total').toLowerCase()}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {search.isPolling() ? <span className="LoadingTile__shimmer" /> : t('farenotfound')}
              </div>
            )}
          </span>
        </div>
        <TransferLink
          fare={bestFare}
          search={search}
          className="Button VendorTile__btn"
          itinerary={itinerary}
          vendorFare={fare instanceof VendorFare ? fare : null}
          onClickMulti={linkedProps.onClickMulti}
        >
          {this.getConfirmBtnTxt(fare)}
        </TransferLink>
      </div>
    );
  }
}

VendorTile.propTypes = {
  fare: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  bestFare: PropTypes.object.isRequired,
  itinerary: PropTypes.object.isRequired,
  openMultiFare: PropTypes.func.isRequired,
};

export default connect((state, props) => ({
  vendors: state.getIn(['flightSearch', 'vendors']),
}))(VendorTile);
