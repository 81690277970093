import axios from 'axios';
import time from 'dohop-client-api/src/time';
import processException from '../lib/processException';
import { createAction } from '../lib/redux/actions';

export const DATE_MATRIX_EXPAND = 'FLIGHT_SEARCH_DATE_MATRIX_EXPAND';
export const DATE_MATRIX_COLLAPSE = 'FLIGHT_SEARCH_DATE_MATRIX_COLLAPSE';
export const FETCH_DATE_MATRIX_STARTING = 'FLIGHT_SEARCH_FETCH_DATE_MATRIX_STARTING';
export const FETCH_DATE_MATRIX_SUCCESS = 'FLIGHT_SEARCH_FETCH_DATE_MATRIX_SUCCESS';
export const FETCH_DATE_MATRIX_FAILED = 'FLIGHT_SEARCH_FETCH_DATE_MATRIX_FAILED';
export const expandDateMatrix = createAction(DATE_MATRIX_EXPAND);
export const collapseDateMatrix = createAction(DATE_MATRIX_COLLAPSE);
export const fetchDateMatrixStarting = createAction(
  FETCH_DATE_MATRIX_STARTING,
  'location',
  'dates'
);
export const fetchDateMatrixSuccess = createAction(FETCH_DATE_MATRIX_SUCCESS, 'response');
export const fetchDateMatrixFailed = createAction(FETCH_DATE_MATRIX_FAILED, 'error');

// how many results we need from livestore to be cover what we need
export const DATEMATRIX_NUM_RESULTS = 91;

// fetches date matrix data
export function fetchDateMatrix() {
  return (dispatch, getState) => {
    let user = getState().get('user');
    let { isActive, origin, destination, d1Min, d1Max, d2Min, d2Max, oneWay } = getState()
      .get('dateMatrix')
      .toObject();

    if (!isActive) return Promise.resolve();

    let url = [
      'https://api.dohop.com/api/v1/livestore',
      user.get('language'),
      user.get('residency'),
      'per-airport',
      origin,
      destination,
      time.strftime('%Y-%m-%d', d1Min),
      time.strftime('%Y-%m-%d', d1Max),
    ].join('/');

    let params = {
      currency: 'EUR',
      b_max: DATEMATRIX_NUM_RESULTS,
      n_max: DATEMATRIX_NUM_RESULTS * destination.split(',').length,
    };

    if (!oneWay) {
      params.include_split = true;
      params.stay = [Math.max(0, time.daysApart(d1Max, d2Min)), time.daysApart(d1Min, d2Max)].join(
        '-'
      );
    }

    dispatch(fetchDateMatrixStarting());
    let search = getState().getIn(['flightSearch', 'search']);
    return axios
      .get(url, { params })
      .then(
        (r) => {
          if (getState().getIn(['flightSearch', 'search']) !== search) return;
          dispatch(fetchDateMatrixSuccess(r.data));
        },
        (e) => {
          processException(e);
          if (getState().getIn(['flightSearch', 'search']) !== search) return;
          dispatch(fetchDateMatrixFailed(e));
        }
      )
      .catch(processException);
  };
}
