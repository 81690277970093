/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');
const _some = require('lodash/some');
const _isEqual = require('lodash/isEqual');
const _isEmpty = require('lodash/isEmpty');
const _keys = require('lodash/keys');
const _values = require('lodash/values');
const _now = require('lodash/now');
const _clone = require('lodash/clone');
const util = require('./util');
const exactCover = require('./exact_cover');

// Fast way to check if faresort datastructure has changed between two versions.
// Inputs are the '1-way-out', '1-way-home' or any value of rt[routeIdOut]
const hasFareSortDiff = (prev, next) =>
  prev == null ||
  _some(next, (_v, routeId) => !(routeId in prev) || prev[routeId].length < next[routeId].length);

const emptyFareSort = () => ({
  '1-way-out': {},
  '1-way-home': {},
  rt: {},
});

const validateFaresort = function (fs1, fs2) {
  let f1, f2;
  let fareIds, bits;
  for (var rIdOut in fs1.rt) {
    const v = fs1.rt[rIdOut];
    util.assert(rIdOut in fs2.rt, `faresort mismatch: route out ${rIdOut} missing`);
    for (var rIdHome in fs1.rt[rIdOut]) {
      util.assert(
        rIdHome in fs2.rt[rIdOut],
        `faresort mismatch: route home ${rIdOut}.${rIdHome} missing`
      );
      f1 = (() => {
        const result = [];
        for ([fareIds, bits] of Array.from(fs1.rt[rIdOut][rIdHome])) {
          result.push(fareIds.slice().sort());
        }
        return result;
      })().sort();
      f2 = (() => {
        const result1 = [];
        for ([fareIds, bits] of Array.from(fs2.rt[rIdOut][rIdHome])) {
          result1.push(fareIds.slice().sort());
        }
        return result1;
      })().sort();
      util.assert(_isEqual(f1, f2), `faresort mismatch: two way ${rIdOut}.${rIdHome} mismatch`);
    }
  }

  return ['1-way-out', '1-way-home'].map((dir) =>
    (() => {
      const result2 = [];
      for (var routeId in fs1[dir]) {
        util.assert(routeId in fs2[dir], `faresort mismatch: ${dir} ${routeId} missing`);
        f1 = (() => {
          const result3 = [];
          for ([fareIds] of Array.from(fs1[dir][routeId])) {
            result3.push(fareIds.slice().sort());
          }
          return result3;
        })().sort();
        f2 = (() => {
          const result4 = [];
          for ([fareIds] of Array.from(fs2[dir][routeId])) {
            result4.push(fareIds.slice().sort());
          }
          return result4;
        })().sort();
        result2.push(
          util.assert(_isEqual(f1, f2), `faresort mismatch: ${dir} ${routeId} mismatch`)
        );
      }
      return result2;
    })()
  );
};

const constructBits = function (n) {
  const b = {};

  for (let i = 0, end = n, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    for (let j = i, end1 = n, asc1 = i <= end1; asc1 ? j < end1 : j > end1; asc1 ? j++ : j--) {
      const k = `${i}-${j}`;
      b[k] = 0;

      for (let m = i, end2 = j, asc2 = i <= end2; asc2 ? m <= end2 : m >= end2; asc2 ? m++ : m--) {
        b[k] += 1 << m;
      }
    }
  }

  return b;
};

const BITS = constructBits(16);

const continuousSubSetIter = function (v) {
  // [0, 1, 2] -> [ [0], [1], [2], [1, 2], [2, 3], [1, 2, 3] ]
  const output = [];
  const n = v.length;

  // but not necessarily in that order
  for (let i = 0, end = n, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    for (let j = i, end1 = n, asc1 = i <= end1; asc1 ? j < end1 : j > end1; asc1 ? j++ : j--) {
      const k = `${i}-${j}`;
      const a = v.slice(i, +j + 1 || undefined);
      const b = BITS[k];
      output.push([a, b]);
    }
  }

  return output;
};

const flightKey = (flights) =>
  Array.from(flights)
    .map((flight) => legKey(flight.getLegs()))
    .join('*');

var legKey = (legs) =>
  Array.from(legs)
    .map((leg) => leg.getKey())
    .join('*');

/**
 *
 * @typedef {{
 * '1-way-out': Object.<string, any>;
 * '1-way-home': Object.<string, any>;
 * 'rt': Object.<string, any>;
 * }} FareSorting
 */
class FareSort {
  constructor() {
    this.fares = {};
    /**
     * @type {FareSorting}
     */
    this._faresort = emptyFareSort();
    this._benchmarks = [];

    this.routes = {
      outbound: [],
      homebound: [],
    };

    this.legsToRoute = {
      outbound: {},
      homebound: {},
    };

    this.affected = {
      outbound: {},
      homebound: {},
      twoWay: {},
    };
  }

  appendRoutes(routes) {
    return (() => {
      const result = [];
      for (var route of Array.from(routes)) {
        var k = route.isOutbound() ? 'outbound' : 'homebound';

        this.routes[k].push(route);
        util.assert(this.routes[k][route.getId()] === route);

        this.markAffected(route);

        result.push(
          (() => {
            const result1 = [];
            for (let [flights, bits] of Array.from(continuousSubSetIter(route.getFlights()))) {
              var name;
              result1.push(
                (this.legsToRoute[k][(name = flightKey(flights))] != null
                  ? this.legsToRoute[k][name]
                  : (this.legsToRoute[k][name] = [])
                ).push([bits, route])
              );
            }
            return result1;
          })()
        );
      }
      return result;
    })();
  }

  appendFares(fares) {
    return (() => {
      const result = [];
      for (let fare of Array.from(fares)) {
        if (!(fare.getId() in this.fares)) {
          var _bits;
          const kOut = legKey(fare.getOutboundLegs());
          const kHome = legKey(fare.getHomeboundLegs());

          if (kHome !== '') {
            var routeOut;
            for ([_bits, routeOut] of Array.from(
              this.legsToRoute.outbound[kOut] != null ? this.legsToRoute.outbound[kOut] : []
            )) {
              var routeHome;
              for ([_bits, routeHome] of Array.from(
                this.legsToRoute.homebound[kHome] != null ? this.legsToRoute.homebound[kHome] : []
              )) {
                var name;
                (this.affected.twoWay[(name = routeOut.getId())] != null
                  ? this.affected.twoWay[name]
                  : (this.affected.twoWay[name] = {}))[routeHome.getId()] = routeHome;
              }
            }
          } else {
            for (let k in this.legsToRoute) {
              var route;
              const legsToRoute = this.legsToRoute[k];
              for ([_bits, route] of Array.from(
                legsToRoute[kOut] != null ? legsToRoute[kOut] : []
              )) {
                this.markAffected(route);
              }
            }
          }
        }

        result.push((this.fares[fare.getId()] = fare));
      }
      return result;
    })();
  }

  markAffected(route) {
    if (route.isOutbound()) {
      let name;
      this.affected.outbound[route.getId()] = route;
      return Array.from(this.routes.homebound).map(
        (routeHome) =>
          ((this.affected.twoWay[(name = route.getId())] != null
            ? this.affected.twoWay[name]
            : (this.affected.twoWay[name] = {}))[routeHome.getId()] = routeHome)
      );
    } else {
      let name1;
      this.affected.homebound[route.getId()] = route;
      return Array.from(this.routes.outbound).map(
        (routeOut) =>
          ((this.affected.twoWay[(name1 = routeOut.getId())] != null
            ? this.affected.twoWay[name1]
            : (this.affected.twoWay[name1] = {}))[route.getId()] = route)
      );
    }
  }

  generateEnumMaps() {
    const map = {
      // route_id -> fare_id
      routeOutToFare1w: {},
      routeOutToFareRt: {},
      routeOutToFareIdRt: {},
      routeHomeToFare1w: {},
      routeHomeToFareRt: {},

      // fare_id -> route_id
      fareRtToRouteHome: {},
    };

    for (let fareId in this.fares) {
      var bits, route, routesHome, routesOut;
      const fare = this.fares[fareId];
      util.assert(fare.getOutboundLegs().length > 0);

      const kOut = legKey(fare.getOutboundLegs());
      const kHome = legKey(fare.getHomeboundLegs());

      if (kHome) {
        routesOut = this.legsToRoute.outbound[kOut] != null ? this.legsToRoute.outbound[kOut] : [];
        routesHome =
          this.legsToRoute.homebound[kHome] != null ? this.legsToRoute.homebound[kHome] : [];
        if (routesOut.length > 0 && routesHome.length > 0) {
          for ([bits, route] of Array.from(routesOut)) {
            var name, name1;
            (map.routeOutToFareRt[(name = route.getId())] != null
              ? map.routeOutToFareRt[name]
              : (map.routeOutToFareRt[name] = [])
            ).push([bits, fare.getId()]);
            (map.routeOutToFareIdRt[(name1 = route.getId())] != null
              ? map.routeOutToFareIdRt[name1]
              : (map.routeOutToFareIdRt[name1] = {}))[fare.getId()] = true;
          }
          for ([bits, route] of Array.from(routesHome)) {
            var name2, name3;
            (map.routeHomeToFareRt[(name2 = route.getId())] != null
              ? map.routeHomeToFareRt[name2]
              : (map.routeHomeToFareRt[name2] = [])
            ).push([bits, fare.getId()]);
            (map.fareRtToRouteHome[(name3 = fare.getId())] != null
              ? map.fareRtToRouteHome[name3]
              : (map.fareRtToRouteHome[name3] = [])
            ).push(route);
          }
        }
      } else {
        routesOut = this.legsToRoute.outbound[kOut] != null ? this.legsToRoute.outbound[kOut] : [];
        routesHome =
          this.legsToRoute.homebound[kOut] != null ? this.legsToRoute.homebound[kOut] : [];
        for ([bits, route] of Array.from(routesOut)) {
          var name4;
          (map.routeOutToFare1w[(name4 = route.getId())] != null
            ? map.routeOutToFare1w[name4]
            : (map.routeOutToFare1w[name4] = [])
          ).push([bits, fare.getId()]);
        }
        for ([bits, route] of Array.from(routesHome)) {
          var name5;
          (map.routeHomeToFare1w[(name5 = route.getId())] != null
            ? map.routeHomeToFare1w[name5]
            : (map.routeHomeToFare1w[name5] = [])
          ).push([bits, fare.getId()]);
        }
      }
    }

    return map;
  }

  /**
   *
   * @returns {FareSorting}
   */
  update() {
    let rIdOut, rt;
    if (_some(_values(this.affected), (v) => !_isEmpty(v))) {
      const t0 = _now();

      const map = this.generateEnumMaps();

      _extend(
        this._faresort['1-way-out'],
        enumerateOneWayResults(_values(this.affected.outbound), map.routeOutToFare1w)
      );
      _extend(
        this._faresort['1-way-home'],
        enumerateOneWayResults(_values(this.affected.homebound), map.routeHomeToFare1w)
      );

      rt = enumerateTwoWayResults(
        this.routes.outbound,
        this.affected.twoWay,
        map.routeOutToFare1w,
        map.routeOutToFareRt,
        map.routeOutToFareIdRt,
        map.fareRtToRouteHome,
        map.routeHomeToFare1w,
        map.routeHomeToFareRt
      );
      for (rIdOut in rt) {
        const homebound = rt[rIdOut];
        _extend(
          this._faresort.rt[rIdOut] != null
            ? this._faresort.rt[rIdOut]
            : (this._faresort.rt[rIdOut] = {}),
          homebound
        );
      }

      for (let k of Array.from(_keys(this.affected))) {
        this.affected[k] = {};
      }

      this._benchmarks.push(_now() - t0);
    }

    // we're going to mutate 1-way-out, 1-way-home and rt[routeIdOut], but anything deeper will not be touched
    rt = {};
    for (rIdOut in this._faresort.rt) {
      const home = this._faresort.rt[rIdOut];
      rt[rIdOut] = _clone(home);
    }

    return {
      '1-way-out': _clone(this._faresort['1-way-out']),
      '1-way-home': _clone(this._faresort['1-way-home']),
      rt: rt,
    };
  }
}

var enumerateOneWayResults = function (routes, route_to_fare_1w) {
  let i;
  const fareCombos = {};

  for (let route of Array.from(routes)) {
    var left;
    var bits_fares = (left = route_to_fare_1w[route.getId()]) != null ? left : [];
    var bitsList = Array.from(bits_fares).map((bits_fare) => bits_fare[0]);

    for (var cover of Array.from(exactCover(bitsList, route.getFlights().length))) {
      var name;
      const fares = (() => {
        const result = [];
        for (i of Array.from(cover)) {
          result.push(+bits_fares[i][1]);
        }
        return result;
      })();
      const bits = (() => {
        const result1 = [];
        for (i of Array.from(cover)) {
          result1.push(bitsList[i]);
        }
        return result1;
      })();
      (fareCombos[(name = route.getId())] != null
        ? fareCombos[name]
        : (fareCombos[name] = [])
      ).push([fares, bits]);
    }
  }

  return fareCombos;
};

const exactCoverImpossible = function (n, oneway, twoway) {
  let allBits = 0;

  for (let bitsFares of [oneway, twoway]) {
    for (let [bits] of Array.from(bitsFares)) {
      allBits |= bits;
    }
  }

  return allBits !== (1 << n) - 1;
};

var enumerateTwoWayResults = function (
  routesOut,
  allowedRoutes,
  routeOutToFare1w,
  routeOutToFareRt,
  routeOutToFareIdRt,
  fareRtToRouteHome,
  routeHomeToFare1w,
  routeHomeToFareRt
) {
  let bits, fareId, i;
  const fareCombos = {};

  for (let rIdOut in allowedRoutes) {
    var left;
    const allowedHome = allowedRoutes[rIdOut];
    var routeOut = routesOut[rIdOut];

    const fareOut1w = (left = routeOutToFare1w[routeOut.getId()]) != null ? left : [];
    const fareOutRt = routeOutToFareRt[routeOut.getId()];

    if (!fareOutRt || exactCoverImpossible(routeOut.getFlights().length, fareOut1w, fareOutRt)) {
      continue;
    }

    const fareIdToBitsOut = {};
    for ([bits, fareId] of Array.from(fareOutRt)) {
      fareIdToBitsOut[fareId] = bits;
    }

    const theseRoutesHome = {};
    for ([bits, fareId] of Array.from(fareOutRt)) {
      for (let route of Array.from(fareRtToRouteHome[fareId])) {
        if (route.getId() in allowedHome) {
          theseRoutesHome[route.getId()] = route;
        }
      }
    }

    for (let rIdHome in theseRoutesHome) {
      const routeHome = theseRoutesHome[rIdHome];
      var fareHome1w = routeHomeToFare1w[rIdHome] != null ? routeHomeToFare1w[rIdHome] : [];
      var fareHomeRt = routeHomeToFareRt[rIdHome];

      if (
        !fareHomeRt ||
        exactCoverImpossible(routeHome.getFlights().length, fareHome1w, fareHomeRt)
      ) {
        continue;
      }

      fareHome1w = (() => {
        const result = [];
        for ([bits, fareId] of Array.from(fareHome1w)) {
          result.push([bits << routeOut.getFlights().length, fareId]);
        }
        return result;
      })();
      fareHomeRt = (() => {
        const result1 = [];
        for ([bits, fareId] of Array.from(fareHomeRt)) {
          result1.push([bits << routeOut.getFlights().length, fareId]);
        }
        return result1;
      })();

      const fareIdToBitsHome = {};
      for ([bits, fareId] of Array.from(fareHomeRt)) {
        fareIdToBitsHome[fareId] = bits;
      }

      const fareIdRtToBits = [];
      for (fareId in fareIdToBitsOut) {
        if (fareId in fareIdToBitsHome) {
          bits = fareIdToBitsOut[fareId] | fareIdToBitsHome[fareId];
          fareIdRtToBits.push([bits, fareId]);
        }
      }

      var bitsList = [];
      var faresList = [];

      for (let bitsFaresList of [fareOut1w, fareHome1w, fareIdRtToBits]) {
        for ([bits, fareId] of Array.from(bitsFaresList)) {
          bitsList.push(bits);
          faresList.push(fareId);
        }
      }

      const nLegs = routeOut.getFlights().length + routeHome.getFlights().length;

      for (var cover of Array.from(exactCover(bitsList, nLegs))) {
        const fareIds = (() => {
          const result2 = [];
          for (i of Array.from(cover)) {
            result2.push(+faresList[i]);
          }
          return result2;
        })();
        bits = (() => {
          const result3 = [];
          for (i of Array.from(cover)) {
            result3.push(bitsList[i]);
          }
          return result3;
        })();
        if (_some(fareIds, (fareId) => routeOutToFareIdRt[routeOut.getId()][fareId])) {
          var base, name, name1;
          ((base =
            fareCombos[(name = routeOut.getId())] != null
              ? fareCombos[name]
              : (fareCombos[name] = {}))[(name1 = routeHome.getId())] != null
            ? base[name1]
            : (base[name1] = [])
          ).push([fareIds, bits]);
        }
      }
    }
  }

  return fareCombos;
};

module.exports = { FareSort, validateFaresort, hasFareSortDiff, emptyFareSort };
