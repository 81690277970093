import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import shallowEquals from 'is-equal-shallow';
import time from 'dohop-client-api/src/time';
import config from '../../../../lib/config';
import flightplan from '../../../../lib/flightplan';
import propTypes from '../../../../lib/propTypes';
import createFilters from '../../../../lib/createFilters';
import { getAncestors } from '../../../../lib/dom';
import ItineraryPrice from '../ItineraryPrice';
import ItineraryDetails from '../ItineraryDetails';
import ItineraryRoute from '../ItineraryRoute';
import ItinerarySeparator from '../ItinerarySeparator';
import ShareItinerary from '../ShareItinerary';
import MatchMedia, { queries } from '../../../MatchMedia';
import ArrowDown from '@content/svg/arrow_down.svg';
import './Itinerary.less';

const { TRANSFER_STEP_URL } = config;
const SHORT_TRANSFER_TIME = 2.5 * time.hour;

class Itinerary extends Component {
  constructor(props) {
    super(props);

    this.outboundFlightplan = [];
    this.inboundFlightplan = [];
    let isOutbound = true;
    flightplan(props.itinerary, { shortTransferTime: props.shortTransferTime }).forEach((event) => {
      isOutbound ? this.outboundFlightplan.push(event) : this.inboundFlightplan.push(event);
      if (event.type === 'separator') isOutbound = false;
    });

    this.state = {
      isDetailsOpen: false,
    };

    this.stopPropagationOnMobile = this.stopPropagationOnMobile.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEquals(this.props, nextProps) || !shallowEquals(this.state, nextState);
  }

  /**
   * Opens transferStep or bubbles to next clickHandler to open info
   * @param {Object} e
   * @returns
   * @memberof ItineraryPrice
   */
  openTransferStep(e) {
    const { history, itinerary } = this.props;

    // Stop the event from bubbling and causing side effects
    e.stopPropagation();

    history.push(
      history.location.pathname +
        window.location.search +
        `#${TRANSFER_STEP_URL}/${itinerary.getKey()}`,
      'onBtnClick'
    );
  }

  stopPropagationOnMobile(e) {
    const { zero, ipad } = queries;

    // Only mobile
    if (zero.matches && !ipad.matches) {
      e.stopPropagation();
    }
  }

  // Only if it was not clicked on certain tag types do expand to the more detailed version.
  // shouldIgnoreClick = node => ['A', 'INPUT', 'BUTTON'].includes(node.tagName);

  /**
   * Triggered when clicking on the itinerary.
   * @param {Object} e - the event.
   */
  onClick(e) {
    const { isDetailsOpen } = this.state;
    const { hash } = location;
    const { zero, ipad } = queries;

    const shouldIgnoreClick = (node) => ['A', 'BUTTON'].includes(node.tagName);

    if (!getAncestors(e.target, { includeSelf: true }).some(shouldIgnoreClick)) {
      // In desktop expand and show itinerary details.
      if (ipad.matches) {
        this.setState({ isDetailsOpen: !isDetailsOpen });
      }

      // In mobile open transfer extra step unless we are already there.
      if (zero.matches && !ipad.matches && !hash.includes(TRANSFER_STEP_URL)) {
        this.openTransferStep(e);
      }
    }
  }

  /**
   * Renders airline name and logo
   * @param {Object} airline - Airline object
   * @param {Number} index
   * @param {Boolean} withLogos - Indicates whether we want to show airline logos next to the airline names.
   */
  renderAirline(airline, index, withLogos) {
    const style = {};

    if (airline.getIATACode) {
      style.backgroundImage = `${
        config.M_STATIC
      }/airlines/${airline.getIATACode().toLowerCase()}.png`;
    }

    return (
      <div className="Itinerary__airline" key={index}>
        {withLogos && <div className="Itinerary__airlineLogo" style={style} />}
        {airline.getName ? airline.getName() : ''}
      </div>
    );
  }

  /**
   * Renders the header of the itinerary.
   * The header stores information about the airlines.
   * @param {Boolean} withLogos - Indicates whether we want to show airline logos next to the airline names.
   */
  renderHeader(withLogos = false) {
    const { itinerary } = this.props;
    const airlines = itinerary.getAirlines();

    return (
      <div className="Itinerary__header">
        <div className="Itinerary__airlines">
          {airlines.map((airline, idx) => this.renderAirline(airline, idx, withLogos))}
        </div>
      </div>
    );
  }

  /**
   * Renders the footer of the itinerary.
   * The footer includes a share button and label to show that the itinerary is expandable.
   */
  renderFooter() {
    const { t, itinerary } = this.props;
    const { isDetailsOpen } = this.state;

    return (
      <div className="Itinerary__footer">
        <ShareItinerary t={t} itinerary={itinerary} />
        <div className="Itinerary__details">
          {t('details')}
          <ArrowDown
            className={classNames('Itinerary__arrow', {
              'Itinerary__arrow--open': isDetailsOpen,
            })}
          />
        </div>
      </div>
    );
  }

  /**
   * Renders the main section of the itinerary in desktop version.
   */
  renderDesktopMain() {
    const { t, itinerary, showPrice } = this.props;

    return (
      <Fragment>
        {this.renderHeader()}
        {itinerary.getRoutes &&
          itinerary
            .getRoutes()
            .map((route, idx) => (
              <ItineraryRoute
                t={t}
                key={`route-${idx}`}
                route={route}
                itinerary={itinerary}
                showPrice={showPrice}
              />
            ))}
        {this.renderFooter()}
      </Fragment>
    );
  }

  /**
   * Renders the main section of the itinerary in mobile version.
   */
  renderMobileMain() {
    const { t, itinerary, isExpandable, mobileVersion } = this.props;

    return (
      <Fragment>
        {itinerary.getRoutes &&
          itinerary
            .getRoutes()
            .map((route, idx) => (
              <ItineraryRoute
                t={t}
                key={`route-${idx}`}
                itinerary={itinerary}
                route={route}
                isExpandable={isExpandable}
                flightPlan={idx === 0 ? this.outboundFlightplan : this.inboundFlightplan}
                mobileVersion={mobileVersion}
              />
            ))}
      </Fragment>
    );
  }

  render() {
    const { search, itinerary, user, showPrice, insideTile, mobileVersion, vendors } = this.props;
    const { isDetailsOpen } = this.state;

    if (!itinerary || !search) {
      return null;
    }

    const fares = itinerary.getFares ? itinerary.getFares() : null;
    const fare = fares && fares.getBestFare ? fares.getBestFare() : null;

    return (
      <div
        className={classNames(mobileVersion ? 'Itinerary--alwaysMobile' : 'Itinerary', {
          'Itinerary--noPrice': !showPrice && !mobileVersion,
          'Itinerary--noPrice--alwaysMobile': !showPrice && mobileVersion,
          'Itinerary--insideTile': insideTile,
        })}
        ref={(c) => (this.itinerary = c)}
      >
        <div
          className={classNames(
            mobileVersion ? 'Itinerary__clickable--alwaysMobile' : 'Itinerary__clickable',
            {
              'Itinerary__clickable--withDetails': isDetailsOpen,
            }
          )}
          onClick={this.onClick}
        >
          <div
            className={
              mobileVersion ? 'Itinerary__collapsed--alwaysMobile' : 'Itinerary__collapsed'
            }
          >
            {mobileVersion ? (
              <div
                className={classNames('Itinerary__main--alwaysMobile', {
                  'Itinerary__main--noPrice--alwaysMobile': !showPrice,
                  'Itinerary__main--insideTile--alwaysMobile': insideTile,
                })}
              >
                {this.renderMobileMain()}
              </div>
            ) : (
              <Fragment>
                <MatchMedia
                  className={classNames('Itinerary__main', {
                    'Itinerary__main--noPrice': !showPrice,
                    'Itinerary__main--withPrice': showPrice,
                    'Itinerary--noShadow': isDetailsOpen,
                    'Itinerary__main--insideTile': insideTile,
                  })}
                  min="ipad"
                >
                  {this.renderDesktopMain()}
                </MatchMedia>
                <MatchMedia
                  className={classNames('Itinerary__main', {
                    'Itinerary__main--noPrice': !showPrice,
                    'Itinerary--noShadow': showPrice,
                    'Itinerary__main--insideTile': insideTile,
                  })}
                  max="ipad"
                >
                  {this.renderMobileMain()}
                </MatchMedia>
              </Fragment>
            )}
            {showPrice && <ItinerarySeparator detailsOpen={isDetailsOpen} />}
            {showPrice && fare && (
              <ItineraryPrice
                className={isDetailsOpen ? 'Itinerary--noShadow' : ''}
                user={user}
                fare={fare}
                search={search}
                itinerary={itinerary}
                vendors={vendors}
              />
            )}
          </div>
          {!mobileVersion && (
            <MatchMedia min="ipad">
              <ItineraryDetails
                itinerary={itinerary}
                shortTransferTime={SHORT_TRANSFER_TIME}
                outboundFlightplan={this.outboundFlightplan}
                inboundFlightplan={this.inboundFlightplan}
                visible={isDetailsOpen}
              />
            </MatchMedia>
          )}
        </div>
      </div>
    );
  }
}

Itinerary = connect((state, props) => ({
  user: state.get('user'),
  vendors: state.getIn(['flightSearch', 'vendors']),
}))(Itinerary);

Itinerary.defaultProps = {
  itinerary: null,
  user: null,
  showPrice: false,
  mobileVersion: false,
};

Itinerary.propTypes = {
  itinerary: propTypes.itinerary,
  search: PropTypes.object.isRequired,
  user: ImmutablePropTypes.map,
  t: PropTypes.func.isRequired,
  showPrice: PropTypes.bool,
  // True if we want to use the mobile version for all screen sizes.
  mobileVersion: PropTypes.bool,
};

Itinerary = createFilters(['t'])(Itinerary);

export default withRouter(Itinerary);
