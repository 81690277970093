import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import { getFlightFormUrl } from '@lib/getFlightUrl';
import ContentHeader from '../header/ContentHeader';
import SearchForm from '../frontpage/SearchForm';
import MobileView from '../MobileView';
import MatchMedia from '../MatchMedia';
import AirplaneOutbound from '@content/svg/airplane-outbound.svg';
import Pen from '@content/img/icons/pen.svg';
import './ResultsHeader.less';

class ResultsHeader extends Component {
  state = { change: false };

  onEdit = () => this.setState({ change: true });
  onCloseForm = () => this.setState({ change: false });

  /**
   * Choose if we should display any or airport code
   * @param {Object} airport - Airport object
   * @returns {JSX}
   */
  chooseAirportDisplay(airport) {
    const { t } = this.props;

    if (!airport) {
      return null;
    }

    const name = airport.get('name');
    const code = airport.get('code');
    let airportCode = t('any');

    if (code.length === 3) {
      airportCode = code;
    }

    return (
      <span>
        {name} <span className="ResultsHeader__airportCode">({airportCode})</span>
      </span>
    );
  }

  renderTitle() {
    const { searchData, t, useGo, go } = this.props;
    const origin = useGo ? go.get('origin') : searchData.get('origin');
    const destination = go
      ? this.chooseAirportDisplay(go.get('destination')) || <span>{t('anywhere')}</span>
      : this.chooseAirportDisplay(searchData.get('destination'));

    return (
      <Fragment>
        <h1 className="ResultsHeader__title">
          {this.chooseAirportDisplay(origin)}
          <span>
            <AirplaneOutbound className="ResultsHeader__airplane" />
          </span>
          {destination}
        </h1>
        <Pen className="ResultsHeader__pen" />
      </Fragment>
    );
  }

  renderChangeDesktop() {
    const { useGo, url, search, go } = this.props;

    return (
      <ContentHeader tab={useGo ? 'go' : 'flights'} url={url} results>
        <SearchForm go={go} search={search} onSubmit={this.onCloseForm} />
      </ContentHeader>
    );
  }

  renderChangeMobile() {
    const { t, useGo, url } = this.props;

    return (
      <ContentHeader tab={useGo ? 'go' : 'flights'} url={url} results>
        <MobileView padding title={t('editsearch')} onClose={this.onCloseForm}>
          <SearchForm onSubmit={this.onCloseForm} />
        </MobileView>
      </ContentHeader>
    );
  }

  /**
   * Either renders renderSubtitle from props or defaults to default JSX
   */
  renderSubtitle() {
    const { search, formatDate, t, renderSubtitle } = this.props;

    const subtitle = (renderSubtitle && renderSubtitle()) || (
      <div>
        {formatDate(search.getMeta().getOutboundDate(), 'll')}
        {!search.getMeta().isOneWay() &&
          ' - ' + formatDate(search.getMeta().getHomeboundDate(), 'll')}
        <div className="ResultsHeader__passengers">
          {search.getMeta().getNumPassengers()}{' '}
          {search.getMeta().getNumPassengers() > 1 ? t('passengers') : t('passenger')}
        </div>
      </div>
    );

    return subtitle;
  }

  renderSearchInfo() {
    const { useGo, url } = this.props;

    return (
      <ContentHeader
        tab={useGo ? 'go' : 'flights'}
        title={this.renderTitle()}
        subtitle={this.renderSubtitle()}
        url={url}
        onClickTitle={this.onEdit}
        results
        logo
      />
    );
  }

  render() {
    const { change } = this.state;

    return (
      <div className={classNames('ResultsHeader', { 'ResultsHeader--form': change })}>
        <MatchMedia max="ipad">
          {change ? this.renderChangeMobile() : this.renderSearchInfo()}
        </MatchMedia>
        <MatchMedia min="ipad">{this.renderChangeDesktop()}</MatchMedia>
      </div>
    );
  }
}

ResultsHeader.propTypes = {
  search: PropTypes.object.isRequired,
};

ResultsHeader = connect(state => ({ url: getFlightFormUrl(state) }))(ResultsHeader);
export default createFilters(['formatDate', 't'])(ResultsHeader);
