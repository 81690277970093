import { Map, List } from 'immutable';
import time from 'dohop-client-api/src/time';

import {
  HOTEL_RECEIVE_DESTINATION,
  HOTEL_RECEIVE_CHECKIN,
  HOTEL_RECEIVE_CHECKOUT,
  HOTEL_RECEIVE_STAY_DURATION,
  HOTEL_RECEIVE_MONTH,
  HOTEL_RECEIVE_FLEXIBLE,
  HOTEL_RECEIVE_GUEST_VALUE,
  HOTEL_RECEIVE_GUESTS,
  HOTEL_RECEIVE_CHILD_AGE,
  HOTEL_RECOMMENDED_SUCCESS,
  HOTEL_RECOMMENDED_FAILED,
  HOTEL_RECEIVE_COOKIEKEYS,
} from '../actions/hotelActions';

const INITIAL_FORM_STATE = Map({
  destination: null,
  checkinDate: new Date(Date.now() + 2 * time.week),
  checkoutDate: new Date(Date.now() + 3 * time.week),
  flexible: false,
  stay: 'any',
  guestDropdownValue: 0,
  month: null,
  rooms: 1,
  adults: 2,
  childrenAges: List(),
  customCount: false,
});

function formReducer(state = INITIAL_FORM_STATE, action) {
  switch (action.type) {
    case HOTEL_RECEIVE_DESTINATION:
      return state.set('destination', action.destination);
    case HOTEL_RECEIVE_CHECKIN:
      return state.set('checkinDate', action.date);
    case HOTEL_RECEIVE_CHECKOUT:
      return state.set('checkoutDate', action.date);
    case HOTEL_RECEIVE_STAY_DURATION:
      return state.set('stay', action.stay);
    case HOTEL_RECEIVE_MONTH:
      return state.set('month', action.date);
    case HOTEL_RECEIVE_FLEXIBLE:
      return state.set('flexible', action.flexible);
    case HOTEL_RECEIVE_GUEST_VALUE:
      return state.set('guestDropdownValue', action.value);
    case HOTEL_RECEIVE_GUESTS:
      let { rooms, adults, children } = action.count;
      if (rooms != null) state = state.set('rooms', rooms);
      if (adults != null) state = state.set('adults', adults);
      if (children != null) {
        let childrenList = Array(children).fill(8);
        state = state.set('childrenAges', List(childrenList));
      }
      return state;
    case HOTEL_RECEIVE_CHILD_AGE:
      if (action.age >= 0 && action.age <= 17 && action.id < state.get('childrenAges').size) {
        return state.setIn(['childrenAges', action.id], action.age);
      }
      return state;
    case HOTEL_RECEIVE_COOKIEKEYS:
      let childrenList = Array(action.children).fill(8);
      return state.merge({
        checkinDate: action.checkin,
        checkoutDate: action.checkout,
        rooms: action.rooms,
        adults: action.adults,
        childrenAges: List(childrenList),
      });
    default:
      return state;
  }
}

const INITIAL_RECOMMENDED_STATE = Map({
  count: 0,
  from: 0,
  hotels: List(),
});

function recommendedReducer(state = INITIAL_RECOMMENDED_STATE, action) {
  switch (action.type) {
    case HOTEL_RECOMMENDED_SUCCESS:
      return action.hotels;
    case HOTEL_RECOMMENDED_FAILED:
      return INITIAL_RECOMMENDED_STATE;
    default:
      return state;
  }
}

export default function hotelReducer(state = Map(), action) {
  state = state.set('form', formReducer(state.get('form'), action));
  state = state.set('recommended', recommendedReducer(state.get('recommended'), action));
  return state;
}
