import React from 'react';
import config from '../../lib/config';
import './SearchError.less';

const images = ['Ghost', 'Click', 'Drops', 'Umbrella', 'Coffee'];

export default class SearchError extends React.Component {
  constructor(props) {
    super(props);
    this.image = images[Math.floor(Math.random() * images.length)];
  }

  renderError() {
    let { t } = this.props;

    const KNOWN_API_ERRORS = {
      'some departure/arrival airports in the same city': t('same_airports'),
      'departure date is set earlier than request date': t('errRetDateInvalid'),
    };

    let details = this.props.error.details;
    if (details && details in KNOWN_API_ERRORS) {
      return <div>{t(KNOWN_API_ERRORS[details])}</div>;
    }
    return (
      <div>
        <div>{t('errUIGeneric')}</div>
        <div>{t('in_the_mean_while')}</div>
      </div>
    );
  }

  render() {
    let { t } = this.props;
    const imageStyle = { backgroundImage: `url(${config.STATIC_URL}/img/${this.image}.svg)` };
    return (
      <div className="SearchError">
        <div className="SearchError__header">{t('oops')}</div>
        <div className="SearchError__text">{this.props.title}</div>
        <div className="SearchError__image" style={imageStyle} />
        {this.props.children}
        {this.props.error && this.renderError()}
      </div>
    );
  }
}
