import { Map, List } from 'immutable';
import moment from 'moment';
import defaultOrigin from '../constants/defaultOrigin';
import { DEHYDRATE } from '../actions/requestActions';
import {
  GO_FETCH_STARTING,
  GO_INSPIRED_FETCH_SUCCESS,
  GO_FETCH_SUCCESS,
  GO_FETCH_FAILED,
  GO_SET_STAY,
  GO_SET_WEEKDAYS,
  GO_SET_ORIGIN,
  GO_SET_DESTINATION,
  GO_SET_DATES,
  GO_SET_ONE_WAY,
  GO_SET_NONSTOP,
  GO_LOADING_TIME,
  GO_SET_FROM_PARAMS,
  GO_RESET_FILTERS,
  GO_SET_SEARCH_RESULT,
  GO_SET_FLIGHT_SEARCH_LOADING,
} from '../actions/goActions';

function defaultState() {
  return Map({
    results: Map(),
    searchResults: Map(),
    inspired: Map(),
    loading: false,
    origin: defaultOrigin,
    destination: null,
    oneWay: false,
    nonStop: false,
    d1: moment(),
    d1_: moment().add(11, 'months').endOf('month'),
    flexibleDate: true,
    stay: '3-14',
    weekdays: '1234567',
    searchIsLoading: false,
  });
}

export default function goReducer(state = defaultState(), action) {
  switch (action.type) {
    case GO_FETCH_STARTING:
      return state
        .set('loading', true)
        .set('fetchStart', Date.now())
        .set('activeKey', action.activeKey);
    case GO_INSPIRED_FETCH_SUCCESS:
      return state.mergeIn(['inspired'], {
        origin: action.origin,
        results: action.results,
      });
    case GO_FETCH_SUCCESS:
      return state
        .set('loading', false)
        .set('fetchStart', Date.now() - GO_LOADING_TIME)
        .setIn(['results', state.get('activeKey')], action.results);
    case GO_SET_SEARCH_RESULT:
      return state
        .setIn(['searchResults', state.get('activeKey')], action.result)
        .setIn(['results', state.get('activeKey')], List.of(action.result));
    case GO_SET_ORIGIN:
      return state.set('origin', action.origin);
    case GO_SET_DESTINATION:
      if (
        !action.destination ||
        action.destination.get('code') !== state.getIn(['destination', 'code'])
      ) {
        state = state.set('results', Map());
      }
      return state.set('destination', action.destination);
    case GO_SET_DATES:
      return state.merge({
        flexibleDate: action.isFlexible,
        d1: action.dates.get('d1'),
        d1_: action.dates.get('d1_'),
      });
    case GO_FETCH_FAILED:
      return state.merge({
        loading: false,
        error: action.error,
      });
    case GO_SET_STAY:
      return state.set('stay', action.stay);
    case GO_SET_ONE_WAY:
      return state.set('oneWay', action.oneWay);
    case GO_SET_NONSTOP:
      return state.set('nonStop', action.nonStop);
    case GO_SET_WEEKDAYS:
      return state.set('weekdays', action.weekdays.split('').sort().join(''));
    case GO_SET_FROM_PARAMS:
      return state.merge(action.options);
    case GO_RESET_FILTERS:
      return state.merge({
        oneWay: false,
        nonStop: false,
        stay: '1-31',
        weekdays: '1234567',
      });
    case GO_SET_FLIGHT_SEARCH_LOADING:
      return state.set('searchIsLoading', action.loading);
    case DEHYDRATE:
      return state.merge({
        d1: moment(state.get('d1')),
        d1_: moment(state.get('d1_')),
      });

    default:
      return state;
  }
}
