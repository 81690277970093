import Immutable, { Map, List } from 'immutable';
import { createAction } from '../lib/redux/actions';
import _zipObject from 'lodash/zipObject';
import time from 'dohop-client-api/src/time';
import moment from 'moment';
import calendar from 'dohop-client-api/src/calendar';
import picker from 'dohop-client-api/src/picker';
import isEqualAirportCodes from '@lib/isEqualAirportCodes';
import axiosWrapper from '@lib/axiosWrapper';
import { getIp } from '@lib/redux/request';
import { getCookie, setCookie } from '@lib/redux/cookie';
import processException from '@lib/processException';

// Cookie constants
export const HOTEL_CHECK_COOKIE = 'hotelcheck';
export const FORM_COOKIE_NAME = 'formstate';
export const RECENT_SEARCHES_COOKIE = 'resentSearches';

// Cookie options
const DEFAULT_COOKIE_OPTIONS = { path: '/', maxAge: 60 * 60 * 24 * 30 }; // Expands after 30 days.

// Action types constants
export const FLIGHT_SEARCH_FORM_CLOSEST_AIRPORT = 'FLIGHT_SEARCH_FORM_GET_CLOSEST_AIRPORT';
export const FLIGHT_SEARCH_FORM_SET_ORIGIN = 'FLIGHT_SEARCH_FORM_SET_ORIGIN';
export const FLIGHT_SEARCH_FORM_SET_DESTINATION = 'FLIGHT_SEARCH_FORM_SET_DESTINATION';
export const FLIGHT_SEARCH_FORM_SET_DEPARTURE_DATE = 'FLIGHT_SEARCH_FORM_SET_DEPARTURE_DATE';
export const FLIGHT_SEARCH_FORM_SET_RETURN_DATE = 'FLIGHT_SEARCH_FORM_SET_RETURN_DATE';
export const FLIGHT_SEARCH_FORM_SET_DATES = 'FLIGHT_SEARCH_FORM_SET_DATES';
export const FLIGHT_SEARCH_FORM_SET_IS_ONE_WAY = 'FLIGHT_SEARCH_FORM_SET_IS_ONE_WAY';
export const FLIGHT_SEARCH_FORM_UPDATE_CALENDAR = 'FLIGHT_SEARCH_FORM_UPDATE_CALENDAR';
export const FLIGHT_SEARCH_FORM_SET_ADULTS = 'FLIGHT_SEARCH_FORM_SET_ADULTS';
export const FLIGHT_SEARCH_FORM_SET_CHILDREN = 'FLIGHT_SEARCH_FORM_SET_CHILDREN';
export const FLIGHT_SEARCH_FORM_SET_CHILD_AGE = 'FLIGHT_SEARCH_FORM_SET_CHILD_AGE';
export const FLIGHT_SEARCH_FORM_SET_FLEXIBLE_DATES = 'FLIGHT_SEARCH_FORM_SET_FLEXIBLE_DATES';
export const FLIGHT_SEARCH_FORM_SET_IS_DATE_FLEXIBLE = 'FLIGHT_SEARCH_FORM_SET_IS_DATE_FLEXIBLE';
export const FLIGHT_SEARCH_FORM_SET_HOTEL_CHECK = 'FLIGHT_SEARCH_FORM_SET_HOTEL_CHECK';
export const FLIGHT_SEARCH_FORM_SET_RECENT_SEARCHES = 'FLIGHT_SEARCH_FORM_SET_RECENT_SEARCHES';

// Actions
export const updateCalendar = createAction(FLIGHT_SEARCH_FORM_UPDATE_CALENDAR, 'calendar');
export const setDateFlexible = createAction(FLIGHT_SEARCH_FORM_SET_IS_DATE_FLEXIBLE, 'isFlexible');
export const setFlexibleDates = createAction(FLIGHT_SEARCH_FORM_SET_FLEXIBLE_DATES, 'dates');
export const setOriginAction = createAction(FLIGHT_SEARCH_FORM_SET_ORIGIN, 'airport');
export const setDestinationAction = createAction(FLIGHT_SEARCH_FORM_SET_DESTINATION, 'destination');
export const setDepartureDateAction = createAction(FLIGHT_SEARCH_FORM_SET_DEPARTURE_DATE, 'date');
export const setReturnDateAction = createAction(FLIGHT_SEARCH_FORM_SET_RETURN_DATE, 'date');
export const setDatesAction = createAction(FLIGHT_SEARCH_FORM_SET_DATES, 'd1', 'd2');
export const setIsOneWayAction = createAction(FLIGHT_SEARCH_FORM_SET_IS_ONE_WAY, 'isOneWay');
export const setAdultsAction = createAction(FLIGHT_SEARCH_FORM_SET_ADULTS, 'adults');
export const setChildrenAction = createAction(FLIGHT_SEARCH_FORM_SET_CHILDREN, 'children');
export const setChildAgeAction = createAction(FLIGHT_SEARCH_FORM_SET_CHILD_AGE, 'idx', 'age');
export const setRecentSearches = createAction(
  FLIGHT_SEARCH_FORM_SET_RECENT_SEARCHES,
  'recentSearches'
);

export const setOrigin = (airport) => saveCookieWrapper(setOriginAction, airport);
export const setDestination = (airport) => saveCookieWrapper(setDestinationAction, airport);
export const setDepartureDate = (date) => saveCookieWrapper(setDepartureDateAction, date);
export const setReturnDate = (date) => saveCookieWrapper(setReturnDateAction, date);
export const setDates = (d1, d2) => saveCookieWrapper(setDatesAction, d1, d2);
export const setIsOneWay = (isOneWay) => saveCookieWrapper(setIsOneWayAction, isOneWay);
export const setAdults = (adults) => saveCookieWrapper(setAdultsAction, adults);
export const setChildren = (children) => saveCookieWrapper(setChildrenAction, children);
export const setChildAge = (idx, age) => saveCookieWrapper(setChildAgeAction, idx, age);

function saveSearchCookie() {
  return (dispatch, getState) => {
    let {
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
      isOneWay,
    } = getState().get('flightSearchForm').toObject();

    let flightCookieValue = [
      origin ? origin.get('code') : '',
      destination ? destination.get('code') : '',
      departureDate ? time.strftime('%Y-%m-%d', departureDate) : '',
      returnDate ? time.strftime('%Y-%m-%d', returnDate) : '',
      adults,
      childrenAges.join('-'),
      isOneWay,
    ].join('|');

    let hotelCookieValue = [
      destination ? destination.get('code') : '',
      departureDate ? time.strftime('%Y-%m-%d', departureDate) : '',
      returnDate ? time.strftime('%Y-%m-%d', returnDate) : '',
      '1', // rooms
      adults,
      childrenAges.size,
    ].join('|');

    let prevCookieValue = dispatch(getCookie(FORM_COOKIE_NAME));
    let prevCarCookieValue = (prevCookieValue || '').split('__')[2];
    let carCookieValue = [
      destination ? destination.get('code') : '',
      prevCarCookieValue ? prevCarCookieValue.split('|')[1] : '',
      departureDate ? time.strftime('%Y-%m-%d', departureDate) : '',
      returnDate ? time.strftime('%Y-%m-%d', returnDate) : '',
      (time.hour * 10).toString(), // pickupTime
      (time.hour * 10).toString(), // dropoffTime
      false,
    ].join('|');

    const packageCookieValue = [
      origin ? origin.get('code') : '',
      destination ? destination.get('code') : '',
      departureDate ? time.strftime('%Y-%m-%d', departureDate) : '',
      returnDate ? time.strftime('%Y-%m-%d', returnDate) : '',
      adults,
      childrenAges.size,
      isOneWay,
    ].join('|');

    const cookieValue = [
      flightCookieValue,
      hotelCookieValue,
      carCookieValue,
      packageCookieValue,
    ].join('__');

    dispatch(setCookie(FORM_COOKIE_NAME, cookieValue, DEFAULT_COOKIE_OPTIONS));
  };
}

function saveCookieWrapper(func, ...args) {
  return (dispatch) => {
    dispatch(func(...args));
    dispatch(saveSearchCookie());
  };
}

/**
 * Stores the values in the flight search from redux state in a cookie.
 * The recent search cookie is a string with the divider '|' between each value in search form
 * and the divider '_' between each search.
 * The values in each search form are, in this order:
 * origin code|destination code (except name for flexible dest)|departure date|return date|number of adults|children ages with "-" in between|
 * is one way boolean|is flexible date boolean|start of flexible date range|end of flexible date range|is flexible destination boolean
 * We store maximum 4 recent searches at a time.
 */
export function saveRecentSearchCookie() {
  return (dispatch, getState) => {
    let {
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
      isOneWay,
      isDateFlexible,
      flexibleDate,
    } = getState().get('flightSearchForm').toObject();

    const prevRecentSearches = dispatch(getCookie(RECENT_SEARCHES_COOKIE));
    const prevRecentSearchesList = prevRecentSearches ? prevRecentSearches.split('_') : [];
    const destinationValue = (dest) => (dest.get('flexible') ? dest.get('name') : dest.get('code'));
    const isFlexibleDest = destination.get('flexible');

    const flightCookieValue = [
      origin ? origin.get('code') : '',
      destination ? destinationValue(destination) : '',
      departureDate ? time.strftime('%Y-%m-%d', departureDate) : '',
      returnDate ? time.strftime('%Y-%m-%d', returnDate) : '',
      adults,
      childrenAges.join('-'),
      isOneWay,
      isDateFlexible,
      flexibleDate ? flexibleDate.get('d1') : '',
      flexibleDate ? flexibleDate.get('d1_') : '',
      isFlexibleDest,
    ].join('|');

    // We don't want to store the search in cookie if it is already there anyways.
    // In that case we remove the older value so that this search appears as the newest recent search.
    if (prevRecentSearchesList.includes(flightCookieValue)) {
      const index = prevRecentSearchesList.indexOf(flightCookieValue);
      prevRecentSearchesList.splice(index, 1);
    }

    prevRecentSearchesList.unshift(flightCookieValue);

    // We store max 4 searches at a time (here we have aldready added the newest one to the list).
    if (prevRecentSearchesList.length >= 5) {
      prevRecentSearchesList.pop();
    }

    const resentSearchesValue = prevRecentSearchesList.join('_');

    dispatch(setCookie(RECENT_SEARCHES_COOKIE, resentSearchesValue, DEFAULT_COOKIE_OPTIONS));
  };
}

export function getAirport(code, language = 'en') {
  return new Promise((resolve, reject) => {
    picker.getAirportFromCode(code, {
      language,
      success: (airport) => resolve(Immutable.Map(airport)),
      error: reject,
    });
  });
}

export function looksLikeAirportCode(codes) {
  return codes && typeof codes === 'string' && codes.split(',').every((code) => code.length === 3);
}

export function getCountry(code, language = 'en') {
  return new Promise((resolve, reject) => {
    picker.getCountryFromCode(code, {
      language,
      success: (country) =>
        resolve(
          Immutable.Map({
            name: country.country_name,
            code: country.country_code,
            continent: country.continent_name,
            isCountry: true,
          })
        ),
      error: reject,
    });
  });
}

export function looksLikeCountryCode(code) {
  return code && typeof code === 'string' && code.length === 2;
}

export function fetchCalendar() {
  return (dispatch, getState) => {
    let origin = getState().getIn(['flightSearchForm', 'origin']);
    let destination = getState().getIn(['flightSearchForm', 'destination']);
    const isCountry = getState().getIn(['flightSearchForm', 'destination', 'isCountry']);
    return new Promise((resolve) => {
      calendar.get(
        origin ? origin.get('code') : '',
        destination && !isCountry ? destination.get('code') : '',
        {
          version: 3,
          success: (cal) => {
            dispatch(updateCalendar(cal));
            resolve();
          },
          error: resolve, // ignore errors - it's not critical
        }
      );
    }).catch(processException);
  };
}

export function updateOriginAndFetchCalendar(airport) {
  return (dispatch, getState) => {
    dispatch(setOrigin(airport));
    dispatch(fetchCalendar());
  };
}

export function updateDestinationAndFetchCalendar(airport) {
  return (dispatch) => {
    dispatch(setDestination(airport));
    dispatch(fetchCalendar());
  };
}

function populateForm(originCode, destinationCode, adults, childrenAges) {
  return (dispatch, getState) => {
    const language = getState().getIn(['user', 'language']);
    const form = getState().get('flightSearchForm');
    const promises = [];

    if (
      looksLikeAirportCode(originCode) &&
      !isEqualAirportCodes(originCode, form.getIn(['origin', 'code']))
    ) {
      promises.push(
        getAirport(originCode, language)
          .then((airport) => {
            dispatch(setOriginAction(airport));
          })
          .catch(processException)
      );
    }

    if (
      looksLikeAirportCode(destinationCode) &&
      !isEqualAirportCodes(destinationCode, form.getIn(['destination', 'code'])) &&
      !isEqualAirportCodes(destinationCode, originCode || form.getIn(['origin', 'code']))
    ) {
      promises.push(
        getAirport(destinationCode, language)
          .then((airport) => dispatch(setDestinationAction(airport)))
          .catch(processException)
      );
    } else if (looksLikeCountryCode(destinationCode)) {
      promises.push(
        getCountry(destinationCode, language)
          .then((country) => dispatch(setDestinationAction(country)))
          .catch(processException)
      );
    }

    if (adults !== null) {
      dispatch(setAdultsAction(adults));
    }

    if (childrenAges !== null) {
      dispatch(setChildrenAction(childrenAges.length));
      childrenAges.forEach((age, i) => dispatch(setChildAgeAction(i, age)));
    }

    return Promise.all(promises).then(() => {
      return dispatch(fetchCalendar());
    });
  };
}

export function populateFromParams(
  origin,
  destination,
  departureDate,
  returnDate,
  adults,
  childrenAges
) {
  return (dispatch) => {
    if (departureDate) {
      dispatch(setDepartureDate(departureDate));
    }
    if (returnDate) {
      dispatch(setReturnDate(returnDate));
    } else {
      dispatch(setIsOneWay(true));
    }
    return dispatch(populateForm(origin, destination, adults, childrenAges));
  };
}

export function populateFromCookie() {
  return populateFromQueryAndCookie();
}

export function populateFromQueryAndCookie(query = {}) {
  return (dispatch, getState) => {
    const cookieValue = dispatch(getCookie(FORM_COOKIE_NAME));
    const flightCookieValue = (cookieValue || '').split('__')[0];
    let cookieKeys = [
      'origin',
      'destination',
      'departureDate',
      'returnDate',
      'adults',
      'childrenAges',
      'oneWay',
    ];

    let input = _zipObject(cookieKeys, flightCookieValue ? flightCookieValue.split('|') : []);
    Object.assign(input, query);

    let departureDate = input.departureDate ? time.parseDate(input.departureDate) : undefined;
    let returnDate = input.returnDate ? time.parseDate(input.returnDate) : undefined;
    let now = time.toUTC(new Date());
    let today = time.toUTC(new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    let yearFromNow = new Date(today.getTime() + time.day * 365);

    if (departureDate && returnDate && departureDate.getTime() > returnDate.getTime()) {
      if (query.departureDate) {
        // query defines departure date, but the return date, wether it is from cookie or query,
        // is not allowed with that departure date
        returnDate = new Date(departureDate.getTime() + time.week * 2);
      } else if (query.returnDate) {
        // same thing, except returnDate is defined and departure is not allowed
        departureDate = new Date(Math.max(today.getTime(), returnDate.getTime() - time.week * 2));
      }
    }

    if (!departureDate) departureDate = getState().getIn(['flightSearchForm', 'departureDate']);
    if (!returnDate) returnDate = getState().getIn(['flightSearchForm', 'returnDate']);

    // validate departure date
    if (
      departureDate.getTime() < today.getTime() ||
      departureDate.getTime() > yearFromNow.getTime()
    ) {
      departureDate = new Date(today.getTime() + time.week);
    }

    // validate return date
    if (
      returnDate.getTime() < departureDate.getTime() ||
      returnDate.getTime() > yearFromNow.getTime()
    ) {
      returnDate = new Date(
        Math.min(yearFromNow.getTime(), departureDate.getTime() + time.week * 2)
      );
    }

    dispatch(setDepartureDateAction(departureDate));
    dispatch(setReturnDateAction(returnDate));

    if (input.oneWay) {
      dispatch(setIsOneWayAction(input.oneWay === 'true' || input.oneWay === '1'));
    }

    let adults = parseInt(input.adults, 10) || 1;
    let childrenAges = [];
    (input.childrenAges || '').split('-').forEach((s) => {
      let age = Number(s);
      if (s && !isNaN(age)) childrenAges.push(age);
    });

    return dispatch(populateForm(input.origin, input.destination, adults, childrenAges));
  };
}

export function fetchClosestAirport() {
  return async (dispatch, getState) => {
    let ip = dispatch(getIp());
    try {
      const res = await axiosWrapper.get('/flights/closest-airport/', { params: { ip } });
      const { airports } = res.data;
      const airport = await getAirport(airports.join(','), getState().getIn(['user', 'language']));
      dispatch({
        type: FLIGHT_SEARCH_FORM_CLOSEST_AIRPORT,
        airport: Immutable.Map(airport),
      });
    } catch (e) {
      processException(e);
    }
  };
}

export function switchOriginAndDestination() {
  return (dispatch, getState) => {
    const origin = getState().getIn(['flightSearchForm', 'origin']);
    const destination = getState().getIn(['flightSearchForm', 'destination']);
    if (
      (destination && destination.get('flexible')) ||
      (destination && destination.get('isCountry'))
    ) {
      return;
    } // from anywhere or country is preposterous
    dispatch(setOrigin(destination));
    dispatch(setDestination(origin));
    dispatch(fetchCalendar());
  };
}

/**
 * Goes over the recent searches cookie and removes values that are outdated.
 */
function reviewRecentSearchesCookie() {
  return (dispatch) => {
    const recentSearchesCookie = dispatch(getCookie(RECENT_SEARCHES_COOKIE));

    if (recentSearchesCookie) {
      const recentSearchesStrings = recentSearchesCookie.split('_');
      const updatedRecentSearchesStrings = recentSearchesStrings.filter((searchString) => {
        const search = searchString.split('|');
        const departure = new Date(search[2]);
        const now = new Date();
        return departure > now;
      });

      const resentSearchesValue = updatedRecentSearchesStrings.join('_');
      dispatch(setCookie(RECENT_SEARCHES_COOKIE, resentSearchesValue, DEFAULT_COOKIE_OPTIONS));
    }
  };
}

/**
 * Fetches more detailed information about a flight search stored in a cookie
 * and adds it to the redux state.
 */
export function fetchRecentSearches(t) {
  return async (dispatch, getState) => {
    try {
      // First we go over the recent searches cookie and remove outdated values if existed.
      dispatch(reviewRecentSearchesCookie());
      const recentSearchesCookie = dispatch(getCookie(RECENT_SEARCHES_COOKIE));

      if (recentSearchesCookie) {
        const recentSearchesStrings = recentSearchesCookie.split('_');
        const language = getState().getIn(['user', 'language']);

        const recentSearchesPromise = recentSearchesStrings.map(async (searchString) => {
          const search = searchString.split('|');
          const originCode = search[0];
          const origin = await getAirport(originCode, language);
          const destinationCode = search[1];
          const isFlexibleDest = search[10];
          const destination = isFlexibleDest
            ? Map({ name: t('anywhere'), flexible: true })
            : await getAirport(destinationCode, language);
          const departure = new Date(search[2]);
          const arrival = new Date(search[3]);
          const childrenString = search[5];
          const childrenAges = List(
            childrenString === '' ? [] : childrenString.split('-')
          ).map((childAge) => Number(childAge));
          const flexibleDate = search[7];
          const d1 = moment(new Date(search[8]));
          const d1_ = moment(new Date(search[9]));

          return Map({
            origin,
            destination,
            departure,
            arrival,
            adults: Number(search[4]),
            childrenAges,
            isOneWay: search[6] === 'true', // To transform string value to boolean.
            isDateFlexible: flexibleDate === 'true', // To transform string value to boolean.
            flexibleDates: Map({ d1, d1_ }),
            recentSearch: true,
          });
        });

        const recentSearches = await Promise.all(recentSearchesPromise);
        dispatch(setRecentSearches(recentSearches));
      }
    } catch (e) {
      processException(e);
    }
  };
}

/**
 * Sets values for the search form using information from a recent search.
 * @param {object <Immutable.map>} recentSearch - recent search as it is stored in redux.
 */
export function setValuesFromRecentSearch(recentSearch) {
  return (dispatch) => {
    const origin = recentSearch.get('origin');
    const destination = recentSearch.get('destination');
    const departure = recentSearch.get('departure');
    const arrival = recentSearch.get('arrival');
    const adults = recentSearch.get('adults');
    const childrenAges = recentSearch.get('childrenAges');
    const children = childrenAges.size;
    const isOneWay = recentSearch.get('isOneWay');
    const isDateFlexible = recentSearch.get('isDateFlexible');
    const flexibleDates = recentSearch.get('flexibleDates');

    dispatch(setOrigin(origin));
    dispatch(setDestination(destination));
    dispatch(setDepartureDate(departure));
    dispatch(setReturnDate(arrival));
    dispatch(setIsOneWay(isOneWay));
    dispatch(setAdults(adults));
    dispatch(setChildren(children));

    for (let idx = 0; idx < children; idx++) {
      dispatch(setChildAge(idx, childrenAges.get(idx)));
    }

    // It is important to set the dates before the isDateFlexible value
    // Because in the reducer, the isDateFlexible value is set to true every time
    // the flexible dates are changed. (Which is not how we should write reducers,
    // there should be no logic there, this is old code that should change.)
    dispatch(setFlexibleDates(flexibleDates));
    dispatch(setDateFlexible(isDateFlexible));
  };
}

/**
 * Saves hotel checkbox value to cookie and saves in Redux state
 * @param {Boolean} check - Save hotel checkbox
 */
export function searchHotelCheckBox(check = true) {
  return (dispatch) => {
    const payload = JSON.parse(check);
    // Save hotel check cookie
    // 1 year = 31536000 = 60 * 60 * 24 * 365
    dispatch(setCookie(HOTEL_CHECK_COOKIE, payload, { path: '/', maxAge: 31536000 }));
    // Dispatch hotel check value to Redux state
    dispatch({
      type: FLIGHT_SEARCH_FORM_SET_HOTEL_CHECK,
      payload,
    });
  };
}

/**
 * Gets cookie value by name
 * @param {String} cookieName - Cookie name
 */
export function getCookieValue(cookieName) {
  return (dispatch) => {
    return dispatch(getCookie(cookieName));
  };
}
