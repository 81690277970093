const WHITELABEL_VENDORS = [
  '1333', // easyjet worldwide staging
  '1379', // easyJet worldwide
  '1514', // Transavia Smart Connect
  '1362', // Volotea via Travelfusion
  '1500', // Volotea whitelabel
  '1479', // Volotea whitelabel staging [test]
  '1377', // La Compagnie Prod
  '1371', // La Compagnie testing [test]
  '1345', // Travelfusion - tests
];

export default function isWhitelabelFare(fare) {
  if (!fare || !fare.isMulti) {
    return false;
  }

  if (!fare.getVendor) {
    return false;
  }

  return WHITELABEL_VENDORS.includes(fare.getVendor().getKey());
}
