import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createFilters from '@lib/createFilters';
import { queries } from '../MatchMedia';
import ClickOutside from '../ClickOutside';
import ConnectIcon from '@content/svg/Self-Connect-ocean.svg';
import AboutIcon from '@content/icons/group.svg';
import B2BIcon from '@content/icons/hands.svg';
import ContactIcon from '@content/icons/phone.svg';
import './HeaderTabs.less';

// minimum timeout to make sure dom changes have been flushed to screen
const TICK = 17;

class HeaderTabs extends Component {
  state = {
    slider: 0,
    openMenu: false,
    dropdownLeftPos: '-50%',
  };

  componentDidMount() {
    const { tab } = this.props;

    if (tab) {
      this.setState({ slider: this.state.slider + 1 });
      this.setState(this.getSliderPosition(this.tabs[tab]));
    }
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const { tab } = this.props;

    if (!tab) return;
    const { left, width } = this.getSliderPosition(this.tabs[tab]);

    if (this.state.left !== left || this.state.width !== width) {
      this.setState({ left, width });
    }
  };

  onMouseEnterMenu = () => {
    this.onMouseEnter('more');
  };

  toogleMenu = (isOpen) => {
    const { openMenu, dropdownLeftPos } = this.state;
    let newDropdownLeftPos = dropdownLeftPos;

    if (this.tabs.more && this.tabs.more.parentElement) {
      newDropdownLeftPos = -this.tabs.more.parentElement.clientWidth / 2;

      if (!queries.desktop.matches) {
        newDropdownLeftPos -= 20;
      }
    }

    if (openMenu && isOpen !== true) {
      this.onMouseLeave();
    }

    this.setState({
      openMenu: !openMenu,
      dropdownLeftPos: newDropdownLeftPos,
    });
  };

  onMouseEnter(tabName) {
    const { tab } = this.props;
    const { left, width } = this.getSliderPosition(this.tabs[tabName]);

    if (
      !this.state.slider ||
      (!tab && this.lastMouseLeave && Date.now() - this.lastMouseLeave >= 200)
    ) {
      // position a new slider in the center of tab with no width
      this.setState((state) => {
        return { slider: state.slider + 1, left: left + width / 2, width: 0 };
      });

      // then render it at the correct position
      setTimeout(() => this.setState({ left, width }), TICK);
    } else {
      this.setState({ left, width });
    }
  }

  onMouseLeave = () => {
    const { tab } = this.props;
    this.lastMouseLeave = Date.now();

    if (tab) {
      this.setState(this.getSliderPosition(this.tabs[tab]));
    } else {
      this.setState({ left: this.state.left + this.state.width / 2, width: 0 });
    }
  };

  getSliderPosition(el) {
    const tabRect = el.getBoundingClientRect();
    const thisRect = this.container.getBoundingClientRect();

    return {
      left: tabRect.left - thisRect.left,
      width: tabRect.width,
    };
  }

  renderTab(name, url, text, targetBlank = false, external = false) {
    const { tab } = this.props;
    const linkProps = {};

    if (targetBlank) {
      linkProps.target = '_blank';
    }

    if (external) {
      linkProps.rel = 'noopener noreferrer';
      linkProps.href = url;
    }

    const LinkComponent = external ? 'a' : Link;

    return (
      <div
        key={name}
        className="HeaderTabs__tab"
        onMouseEnter={() => this.onMouseEnter(name)}
        onMouseLeave={this.onMouseLeave}
      >
        <LinkComponent to={url} className="HeaderTabs__link" {...linkProps}>
          <span
            ref={(a) => {
              this.tabs[name] = a;
            }}
            className={classNames('HeaderTabs__text', {
              'HeaderTabs__text--active': !this.state.slider && name === tab,
              'HeaderTabs__text--ellipsis': name === 'co2',
            })}
            title={text}
          >
            {text}
          </span>
        </LinkComponent>
      </div>
    );
  }

  renderMenu() {
    const { t, light } = this.props;
    const { openMenu, dropdownLeftPos } = this.state;

    return (
      <div
        className={classNames('HeaderTabs__tab', { 'HeaderTabs__tab--light': light })}
        onMouseEnter={this.onMouseEnterMenu}
        onClick={() => this.toogleMenu(true)}
        tabIndex={0}
        role="button"
      >
        <div className="HeaderTabs__link">
          <span
            ref={(a) => {
              this.tabs.more = a;
            }}
            className="HeaderTabs__text"
          >
            {t('more')}
            {' ...'}
          </span>
        </div>
        <div className="HeaderTabs__menuPositioner" style={{ left: dropdownLeftPos }}>
          <CSSTransition
            in={openMenu}
            timeout={200}
            unmountOnExit
            classNames="HeaderTabs__menuContent"
          >
            <ClickOutside onClickOutside={this.toogleMenu}>
              <div className="HeaderTabs__menuContent">
                <Link to="/about/about-us" className="HeaderTabs__menuLink">
                  <AboutIcon className="HeaderTabs__menuIcon" />
                  {t('aboutdohop')}
                </Link>
                <a href="/connect" className="HeaderTabs__menuLink">
                  <ConnectIcon className="HeaderTabs__menuIcon" />
                  {t('Connect')}
                </a>
                <a href="https://b2b.dohop.com" className="HeaderTabs__menuLink">
                  <B2BIcon className="HeaderTabs__menuIcon" />
                  {t('business_solutions')}
                </a>
                <Link to="/about/contact-us/" className="HeaderTabs__menuLink">
                  <ContactIcon className="HeaderTabs__menuIcon" />
                  {t('contact_us')}
                </Link>
              </div>
            </ClickOutside>
          </CSSTransition>
        </div>
      </div>
    );
  }

  render() {
    const { t, colorBackground, tabOrder } = this.props;

    const { slider, left, width } = this.state;
    this.tabs = {};

    return (
      <div
        className={classNames('HeaderTabs', { 'HeaderTabs--light': colorBackground })}
        ref={(c) => (this.container = c)}
      >
        {tabOrder.map((k, i) => this.renderTab(k, '/' + (i ? k : ''), t(k)))}
        {this.renderTab('go', '/go', t('Go'))}
        {this.renderTab(
          'experiences',
          'https://experiences.dohopconnect.com/',
          t('experiences'),
          true,
          true
        )}
        {this.renderMenu()}
        {Boolean(slider) && (
          <div
            key={slider}
            className="HeaderTabs__slider"
            style={{
              left,
              width,
            }}
          />
        )}
      </div>
    );
  }
}

HeaderTabs.propTypes = {
  // tab is not required - defaults to none selected
  tab: PropTypes.oneOf(['flights', 'hotels', 'cars', 'go', 'more']),
};

HeaderTabs = createFilters(['t'])(HeaderTabs);

export default connect((state) => ({ tabOrder: state.getIn(['request', 'tabOrder']) }))(HeaderTabs);
