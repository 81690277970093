import { Map, List } from 'immutable';
import time from 'dohop-client-api/src/time';
import translate from '../lib/translate';
import globals from '../globals';
import config from '../lib/config';
import omit from 'lodash/omit';
import compact from 'lodash/compact';
import { createAction } from '../lib/redux/actions';
import moment from 'moment';
import { months } from '../components/DatePicker/Utils';
import isEqualAirportCodes from '../lib/isEqualAirportCodes';
import axiosWrapper from '../lib/axiosWrapper';
import {
  getAirport,
  looksLikeAirportCode,
  getCountry,
  looksLikeCountryCode,
  setOrigin as setFormOrigin,
  setDestination as setFormDestination,
  setFlexibleDates,
  setDates as setFormDates,
  setDateFlexible,
} from './flightSearchFormActions';
import { initializeFlightSearchFromGo } from './flightSearchActions';
import { trackEmptyGoResults } from '../lib/tracking';
import processException from '../lib/processException';
import { getRecaptchaToken } from 'shared/utils/recaptcha';

export const GO_FETCH_STARTING = 'GO_FETCH_STARTING';
export const GO_INSPIRED_FETCH_SUCCESS = 'GO_INSPIRED_FETCH_SUCCESS';
export const GO_FETCH_SUCCESS = 'GO_FETCH_SUCCESS';
export const GO_FETCH_FAILED = 'GO_FETCH_FAILED';
export const GO_SET_STAY = 'GO_SET_STAY';
export const GO_SET_WEEKDAYS = 'GO_SET_WEEKDAYS';
export const GO_SET_ORIGIN = 'GO_SET_ORIGIN';
export const GO_SET_DESTINATION = 'GO_SET_DESTINATION';
export const GO_SET_DATES = 'GO_SET_DATES';
export const GO_SET_ONE_WAY = 'GO_SET_ONE_WAY';
export const GO_SET_NONSTOP = 'GO_SET_NONSTOP';
export const GO_SET_FLIGHT_LIMIT = 'GO_SET_FLIGHT_LIMIT';
export const GO_SET_HOTEL_LIMIT = 'GO_SET_HOTEL_LIMIT';
export const GO_SET_FROM_PARAMS = 'GO_SET_FROM_PARAMS';
export const GO_RESET_FILTERS = 'GO_RESET_FILTERS';
export const GO_SET_SEARCH_RESULT = 'GO_SET_SEARCH_RESULT';
export const GO_SET_FLIGHT_SEARCH_LOADING = 'GO_SET_FLIGHT_SEARCH_LOADING';
export const GO_LOADING_TIME = 2 * time.second;

export const fetchInspiredSuccess = createAction(GO_INSPIRED_FETCH_SUCCESS, 'origin', 'results');
export const fetchGoStarting = createAction(GO_FETCH_STARTING, 'activeKey');
export const fetchGoSuccess = createAction(GO_FETCH_SUCCESS, 'results');
export const fetchGoFailed = createAction(GO_FETCH_FAILED, 'error');
export const setOrigin = createAction(GO_SET_ORIGIN, 'origin');
export const setDestination = createAction(GO_SET_DESTINATION, 'destination');
export const setDates = createAction(GO_SET_DATES, 'isFlexible', 'dates');
export const setWeekdays = createAction(GO_SET_WEEKDAYS, 'weekdays');
export const setStay = createAction(GO_SET_STAY, 'stay');
export const setOneWay = createAction(GO_SET_ONE_WAY, 'oneWay');
export const setNonStop = createAction(GO_SET_NONSTOP, 'nonStop');
export const resetFilters = createAction(GO_RESET_FILTERS);

export const weekendStay = '2-3';
export const anyStay = '4-31';
export const imageSize = '480x333';

async function sendRequest(user, params = {}) {
  let cities = null;
  const { data } = await axiosWrapper.get('/inspired/', {
    params: Object.assign(
      {
        residency: user.get('residency'),
        language: user.get('language'),
        limit: 7,
        d_max: 1,
      },
      params
    ),
  });

  if (data) {
    cities = List(
      data.map((city) =>
        Map({
          from: List(city.a.split(',')),
          to: List(city.b.split(',')),
          name: city.airport.ci_n,
          countryName: city.airport.cc_n,
          iata: city.airport.a_i,
          price: city.conv_fare,
          image: `${config.M_STATIC}/away/${city.img.flickr_id}_${imageSize}.jpg`,
          departureDate: time.parseDate(city.d1),
          returnDate: city.d2 && time.parseDate(city.d2),
          isDirect: city.n_legs_out === 1 && city.n_legs_home <= 1,
          disabled: !(city.n_legs_out === 1 && city.n_legs_home <= 1) && params.nonstop,
        })
      )
    );
  }

  return cities;
}

export function fetchInspired() {
  return async (dispatch, getState) => {
    const formOrigin = getState().getIn(['flightSearchForm', 'origin']);
    const inspiredOriginCode = getState().getIn(['go', 'inspired', 'origin', 'code']);

    // abort if there is no origin selected or we already have results for requested origin
    if (!formOrigin || formOrigin.get('code') === inspiredOriginCode) {
      return Promise.resolve();
    }

    const user = getState().get('user');
    const params = {
      cache: true,
      'empty-fallback': 'closest-origin',
      origin: formOrigin.get('code'),
    };

    try {
      const [weekends, any] = await Promise.all([
        sendRequest(user, Object.assign({ stay: weekendStay, wd: '45' }, params)),
        sendRequest(user, Object.assign({ stay: anyStay }, params)),
      ]);

      const data = {};

      if (weekends) {
        data.weekends = weekends;
      }

      if (any) {
        data.any = any;
      }

      dispatch(fetchInspiredSuccess(formOrigin, Map({ ...data })));
    } catch (error) {
      processException(error);
    }
  };
}

function createSearchKey(go) {
  return [
    go.getIn(['origin', 'code']),
    go.getIn(['destination', 'code'], 'anywhere'),
    go.get('d1').clone().locale('en').format('YYYY-MM-DD'),
    go.get('d1_').clone().locale('en').format('YYYY-MM-DD'),
    go.get('stay'),
    go.get('weekdays'),
    go.get('oneWay') ? 1 : 0,
    go.get('nonStop') ? 1 : 0,
  ].join('_');
}

export function fetchGo(origin, destination) {
  return async (dispatch, getState) => {
    const go = getState().get('go');
    if (go.get('loading')) return Promise.resolve();
    dispatch(fetchGoStarting(createSearchKey(go)));
    const formOrigin = getState().getIn(['flightSearchForm', 'origin']);
    const goOrigin = go.get('origin') || formOrigin;
    const params = {
      origin: origin || goOrigin.get('code'),
      destination: destination || go.getIn(['destination', 'code']),
      wd: go.get('weekdays'),
      stay: go.get('stay'),
      oneway: go.get('oneWay'),
      nonstop: go.get('nonStop'),
      d1: go.get('d1').clone().locale('en').format('YYYY-MM-DD'),
      d1_: go.get('d1_').clone().locale('en').format('YYYY-MM-DD'),
      limit: 100,
      b_max: go.getIn(['destination', 'isCountry']) ? 1 : '',
    };
    let user = getState().get('user');
    try {
      let response = await sendRequest(user, params);
      let searchResult;
      if (response.size === 0) {
        searchResult = await dispatch(onEmptyResults());
      }
      if (searchResult) response = List.of(searchResult);
      dispatch(fetchGoSuccess(response));
    } catch (error) {
      dispatch(
        fetchGoFailed(
          error.response ? `${error.response.status}: ${error.response.statusText}` : 'Error'
        )
      );
      processException(error);
    }
  };
}

export function populateFromParams(params) {
  return (dispatch, getState) => {
    let go = getState().get('go');
    let translations = globals('translations', getState().getIn(['user', 'language']));
    let t = translate.bind(null, { translations });
    const flexible = Map({
      name: t('anywhere'),
      flexible: true,
    });
    let promises = [];
    const formOrigin = getState().getIn(['flightSearchForm', 'origin']);

    // If the oneWay parameter in url has changed, update the go oneWay state.
    if (params.oneWay && params.oneWay !== go.get('oneWay')) {
      dispatch(setOneWay(params.oneWay));
    }

    if (
      looksLikeAirportCode(params.origin) &&
      !isEqualAirportCodes(params.origin, go.getIn(['origin', 'code']))
    ) {
      promises.push(
        getAirport(params.origin)
          .then((airport) => {
            dispatch(setFormOrigin(airport));
            dispatch(setOrigin(airport));
          })
          .catch(processException)
      );
    } else if (
      formOrigin &&
      !isEqualAirportCodes(formOrigin.get('code'), go.getIn(['origin', 'code']))
    ) {
      dispatch(setOrigin(getState().getIn(['flightSearchForm', 'origin'])));
    }

    if (
      looksLikeAirportCode(params.destination) &&
      !isEqualAirportCodes(params.destination, go.getIn(['destination', 'code']))
    ) {
      promises.push(
        getAirport(params.destination)
          .then((airport) => {
            dispatch(setDestination(airport));
            dispatch(setFormDestination(airport));
          })
          .catch(processException)
      );
    } else if (looksLikeCountryCode(params.destination)) {
      promises.push(
        getCountry(params.destination)
          .then((country) => {
            dispatch(setDestination(country));
            dispatch(setFormDestination(country));
          })
          .catch(processException)
      );
    } else if (!params.destination || params.destination === 'anywhere') {
      dispatch(setFormDestination(flexible));
      if (getState().getIn(['go', 'destination'])) dispatch(setDestination(null));
    }

    if (params.flexibleDate) {
      dispatch(setFlexibleDates(Map({ d1: params.d1, d1_: params.d1_ })));
    } else if (params.d1) {
      const d2 =
        !params.oneWay && params.stay
          ? params.d1.clone().add(params.stay.split('-')[0], 'days').toDate()
          : null;
      dispatch(setFormDates(params.d1.toDate(), d2));
    } else {
      dispatch(setDateFlexible(true));
    }

    let options = omit(params, 'destination', 'origin');

    if (Object.keys(options).length) {
      dispatch({ type: GO_SET_FROM_PARAMS, options });
    }
    return Promise.all(promises);
  };
}

export function createUrl() {
  return (dispatch, getState) => {
    const go = getState().get('go');
    const formatFlexibleDate = (d) => months[d.month()] + d.format('YY');
    const { flexibleDate, oneWay, stay, d1, d1_ } = go.toObject();

    return (
      '/go/' +
      compact([
        go.getIn(['origin', 'code']).replace(/,/g, '-'),
        go.getIn(['destination', 'code'], 'anywhere').replace(/,/g, '-'),
        flexibleDate && [d1, d1_].map(formatFlexibleDate).join('-'),
        !flexibleDate && d1.format('YYYY-MM-DD'),
        !flexibleDate && !oneWay && d1.clone().add(stay.split('-')[0], 'days').format('YYYY-MM-DD'),
      ]).join('/') +
      '?' +
      [
        `stay=${stay}`,
        `weekdays=${go.get('weekdays').split('').sort().join('')}`,
        `oneWay=${go.get('oneWay')}`,
        `nonStop=${go.get('nonStop')}`,
      ].join('&')
    );
  };
}

function onEmptyResults() {
  // When we get an empty response from livestore we make a search
  // on specific date to show at least one result (except when the destination is country).
  return async (dispatch, getState) => {
    getRecaptchaToken(async (token) => {
      dispatch(trackEmptyGoResults());
      const go = getState().get('go');
      if (!go.get('destination')) return;
      let validResult = go.get('searchResults').find((result) => isResultValid(go, result));
      if (validResult) {
        validResult = validResult.set('disabled', shouldBeDisabled(validResult, getState()));
        return validResult;
      }
      if (!go.getIn(['destination', 'isCountry'])) {
        dispatch({ type: GO_SET_FLIGHT_SEARCH_LOADING, loading: true });
        let searchParams = {
          origin: go.getIn(['origin', 'code']),
          destination: go.getIn(['destination', 'code'], 'KEF'),
          adults: 1,
          childrenAges: [],
        };
        if (token) {
          searchParams.token = token;
        }
        const [departureDate, returnDate] = getRandomDates(go);
        searchParams.departureDate = departureDate;
        searchParams.returnDate = returnDate;
        dispatch(initializeFlightSearchFromGo(searchParams));
        const search = getState().getIn(['flightSearch', 'search']);
        search.on('end', () => dispatch({ type: GO_SET_FLIGHT_SEARCH_LOADING, loading: false }));
      }
    });
  };
}

export function addSearchResultToGo(cheapest) {
  return (dispatch, getState) => {
    if (!getState().getIn(['go', 'searchIsLoading'])) return Promise.resolve();
    const fare = cheapest.getFares().getBestFare();
    let result = Map({
      price: fare.getTotal(),
      from: List(cheapest.getRoutes()[0].getOrigin().getCode().split(',')),
      to: List(cheapest.getRoutes()[0].getDestination().getCode().split(',')),
      departureDate: moment.utc(cheapest.getDeparture()).startOf('day').toDate(),
      returnDate:
        cheapest.getRoutes()[1] &&
        moment.utc(cheapest.getRoutes()[1].getDeparture()).startOf('day').toDate(),
      name: cheapest.getRoutes()[0].getDestination().getCityName(),
      image: `${config.STATIC_URL}/img/sea-placeholder.jpeg`,
      country: cheapest.getRoutes()[0].getDestination().getCountryName(),
      // iata is only used for when the header is closed, since we don't have the iata
      // in the airport object this will have to do for now
      iata: cheapest.getRoutes()[0].getDestination().getCode(),
      isDirect:
        cheapest.getRoutes()[0].getNumStops() === 0 &&
        (cheapest.getRoutes().length > 1 ? cheapest.getRoutes()[1].getNumStops() : 0) === 0,
    });
    result = result.set('disabled', shouldBeDisabled(result, getState()));
    dispatch({ type: GO_SET_SEARCH_RESULT, result });
  };
}

function getRandomDates(go) {
  const min = moment.max(go.get('d1').clone(), moment.utc());
  const diff = Math.min(go.get('d1_').diff(min, 'days'), 90);
  const departure = min.add(Math.floor(Math.random() * diff), 'days');
  const weekday = departure.isoWeekday();
  if (go.get('weekdays').indexOf(weekday) === -1) {
    // The array is based on data of more than 5000 transfers.
    const weekdaysInPopularityOrder = [5, 4, 3, 7, 2, 1, 6];
    departure.isoWeekday(weekdaysInPopularityOrder.find((w) => go.get('weekdays').indexOf(w) >= 0));
    if (departure.diff(go.get('d1_')) < 0) departure.subtract(1, 'week');
  }
  const [minStay, maxStay] = go.get('stay').split('-').map(Number);
  const returnDate =
    !go.get('oneWay') &&
    departure.clone().add(Math.floor(Math.random() * (maxStay - minStay)) + minStay, 'days');
  return [departure.toDate(), returnDate && returnDate.toDate()];
}

function isResultValid(go, result) {
  if (!result) return;
  const [minStay, maxStay] = go.get('stay').split('-').map(Number);
  const stay =
    result.get('returnDate') &&
    moment
      .utc(result.get('returnDate'))
      .startOf('day')
      .diff(moment.utc(result.get('departureDate')).startOf('day'), 'days');
  return (
    [
      go.getIn(['origin', 'code']).indexOf(result.get('from').join(',')) < 0,
      go.get('destination') &&
        go.getIn(['destination', 'code']).indexOf(result.get('to').join(',')) < 0,
      stay && minStay > stay,
      stay && maxStay < stay,
      go
        .get('weekdays')
        .split('')
        .map(Number)
        .indexOf(moment.utc(result.get('departureDate')).isoWeekday()) < 0,
      go.get('oneWay') === Boolean(result.get('returnDate')),
      !moment.utc(result.get('departureDate')).isBetween(go.get('d1'), go.get('d1_')),
    ].filter((v) => v).length === 0
  );
}

function shouldBeDisabled(value, state) {
  const nonStop = state.getIn(['go', 'nonStop']);
  return nonStop && !value.isDirect;
}
