import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { is, List } from 'immutable';
import * as userActions from '@actions/userActions';
import * as goActions from '@actions/goActions';
import * as flightSearchFormActions from '@actions/flightSearchFormActions';
import { fetchRecommendedHotels } from '@actions/hotelActions';
import fetchData from '@lib/fetchData';
import City from '@components/cities/City';

class CityLandingRoute extends Component {
  async componentDidMount() {
    const {
      originCode,
      match: { params },
    } = this.props;

    if (process.env.IS_BROWSER) {
      if (originCode !== params.to) {
        goActions.populateFromParams(params);
      } else if (!Boolean(originCode)) {
        await flightSearchFormActions.fetchClosestAirport();
        goActions.fetchGo();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const thisForm = prevProps.flightSearchForm;
    const nextForm = this.props.flightSearchForm;

    if (
      !is(thisForm.get('origin'), nextForm.get('origin')) ||
      !is(thisForm.get('destination'), nextForm.get('destination'))
    ) {
      prevProps.goActions.setDestination(nextForm.get('destination'));
      prevProps.goActions.setOrigin(nextForm.get('origin'));
      prevProps.goActions.fetchGo(
        nextForm.getIn(['origin', 'code']),
        nextForm.getIn(['destination', 'code'])
      );

      if (!is(thisForm.get('destination'), nextForm.get('destination'))) {
        prevProps.fetchRecommendedHotels(nextForm.getIn(['destination', 'code']), 100);
      }
    }
  }

  render() {
    return <City {...this.props} />;
  }
}

CityLandingRoute = connect(
  state => ({
    user: state.get('user'),
    flightSearchForm: state.get('flightSearchForm'),
    hotels: state.getIn(['hotel', 'recommended']),
    results: state.getIn(['go', 'results', state.getIn(['go', 'activeKey'])], List()),
    originCode: state.getIn(['go', 'origin', 'code']),
  }),
  dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    fetchRecommendedHotels: (destination, limit) =>
      dispatch(fetchRecommendedHotels(destination, limit)),
    goActions: bindActionCreators(goActions, dispatch),
    flightSearchFormActions: bindActionCreators(flightSearchFormActions, dispatch),
  })
)(CityLandingRoute);

// Pre fetch data on server
CityLandingRoute = fetchData(CityLandingRoute, async (store, { params, prevParams }) => {
  if (prevParams) return Promise.resolve();
  const { destination, origin } = params;
  const promises = [];

  try {
    await store.dispatch(
      flightSearchFormActions.populateFromQueryAndCookie({
        destination,
        origin,
        oneWay: 'true',
      })
    );
    await store.dispatch(goActions.populateFromParams(params));
    promises.push(store.dispatch(goActions.fetchGo()));

    if (destination) {
      promises.push(store.dispatch(fetchRecommendedHotels(destination, 100)));
    }

    return await Promise.all(promises);
  } catch (error) {
    return Promise.reject(error);
  }
});

export default CityLandingRoute;
