/**
 * Converts radians to degrees
 * @param {Number} radian
 */
export function toDegrees(radian) {
  return radian * (180 / Math.PI);
}

/**
 * Converts degrees to radians
 * @param {Number} degrees
 */
export function toRadian(degrees) {
  return degrees * (Math.PI / 180);
}

/**
 * Generates random integer between two numbers
 * @param {Number} min - Minimum number
 * @param {Number} max - Maximum number
 * @returns {Number} random number
 */
export function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min));
}

/**
 * This function returns a random number between the specified values. The returned value
 * is no lower than (and may possibly equal) min, and is less than (and not equal) max.
 * @param {Number} min - Minimum number
 * @param {Number} max - Maximum number
 * @returns {Number} random number
 */
export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
