/**
 * Gets window scroll top position
 * @returns {Number}
 */
function getScrollTop() {
  return (
    window.scrollY ||
    window.pageYOffset ||
    document.body.scrollTop +
      ((document.documentElement && document.documentElement.scrollTop) || 0)
  );
}

export default getScrollTop;
