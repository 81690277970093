import React, { Component, Children, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import getScrollTop from 'shared/utils/getScrollTop';
import { processImage } from '@lib/stackBlur';
import { queries } from './MatchMedia';
import classNames from 'classnames';
import './Section.less';

const HEIGHT_ABOVE_SEARCHFORM = 88;
const SEARCH_FORM_HEIGHT = '159px'; // The original search form - not the fixed one.
const EVENTS = {
  SCROLL: 'scroll',
  RESIZE: 'resize',
};

let CACHED_IMAGES = {
  flight: false,
  hotel: false,
  car: false,
};

// We want to make components as re-usable as possible. To do that, each component should
// know as little as possible about the content around it, which means it shouldn't set
// it's width or background-color. The width issue is solved by the Constraint component
// and this one is meant to solve the background-color in a consistent, easy-to-change,
// easy-to-maintain way.

class Section extends Component {
  content = createRef();
  canvas = createRef();

  state = {
    backgroundImageLoaded: false,
  };

  componentDidMount() {
    const { desktop } = queries;
    const { backgroundImages } = this.props;

    if (backgroundImages && queries.ipad.matches) {
      this.loadBackgroundImages();
    }
    const searchForm = document.getElementsByClassName('SearchForm');
    const isSearchFormDefined = searchForm && searchForm[0];
    if (isSearchFormDefined) {
      window.addEventListener(EVENTS.SCROLL, this.handleScroll);
      window.addEventListener(EVENTS.RESIZE, this.onResize);

      // When the page is loaded in the begining we calculate the
      // height above the searchbar to know when to switch to fixed header.
      // However, if the page is loaded in the begining with ipad/mobile
      // version or not scrolled all the way to the top this height is not correct.
      // In that case we just use the default height calculated manually until we get an opurtunety to
      // calculate it.
      const isDesktopInBegining = desktop && desktop.matches;
      const scrollTop = getScrollTop();
      const isPageOnTop = scrollTop === 0;

      if (isDesktopInBegining && isPageOnTop) {
        this.setState({
          heightAboveSearchBar: searchForm[0].getBoundingClientRect().top,
        });
      }
    }
  }

  componentWillUnmount() {
    const searchForm = document.getElementsByClassName('SearchForm');
    const isSearchFormDefined = searchForm && searchForm[0];
    if (isSearchFormDefined) {
      window.removeEventListener(EVENTS.SCROLL, this.handleScroll);
      window.removeEventListener(EVENTS.RESIZE, this.onResize);
    }
  }

  /**
   * Preloads background image for smoother image load
   *
   */
  loadBackgroundImages() {
    const {
      pageName,
      backgroundImages: { thumbnail, image },
    } = this.props;
    // Bigger image
    const img = new Image();
    img.src = image;

    // Thumbnail image
    const thumbnailImg = new Image();
    thumbnailImg.src = thumbnail;
    thumbnailImg.onload = () => {
      const canvas = this.canvas.current;
      processImage(thumbnailImg, canvas, 20);
    };

    img.onload = () => {
      setTimeout(() => {
        this.setState({
          backgroundImageLoaded: true,
        });

        if (pageName) {
          CACHED_IMAGES[pageName] = true;
        }
      }, 150);
    };
  }

  /**
   * Handles styles for the section depending on if the page has scrolled down or not.
   * This is needed because the search form is sticky on top of the page
   * after scrolling down the distance it would otherwise disapear.
   * When that happens the section needs to be pushed down to make sure
   * it appears below the fixed searchbar.
   */
  handleScroll = () => {
    const { content } = this.props;
    const { searchFormHeight, heightAboveSearchBar } = this.state;
    const { desktop } = queries;
    const isDesktop = desktop && desktop.matches;
    const isContentRefDefined = this.content && this.content.current;
    const changeToFixedBreakPoint = heightAboveSearchBar || HEIGHT_ABOVE_SEARCHFORM;

    if (isContentRefDefined && content && isDesktop) {
      const scrollTop = getScrollTop();

      if (scrollTop < changeToFixedBreakPoint) {
        // When we see the original search bar we use the oppurtunity to track its real height.
        // (not the hardcoded one.)
        const searchForm = document.getElementsByClassName('SearchForm');
        this.content.current.style.paddingTop = 0;
        if (searchFormHeight !== searchForm[0].offsetHeight) {
          this.setState({ searchFormHeight: searchForm[0].offsetHeight });
        }
      } else {
        // Make the sites content start below the original header so it doesnt jump around.
        this.content.current.style.paddingTop = `${searchFormHeight || SEARCH_FORM_HEIGHT}px`;
      }
    }
  };

  onResize = () => {
    const { desktop } = queries;
    const isContentRefDefined = this.content && this.content.current;
    // Make sure, that if the window is resized to smaller screen after scrolling,
    // we set the padding top back to 0, because the fixed searchbar isn't visible anymore.
    if (desktop && !desktop.matches && isContentRefDefined) {
      this.content.current.style.paddingTop = 0;
    }
  };

  render() {
    const {
      header,
      content,
      alternate,
      footer,
      className,
      children,
      transparent,
      pageName,
      backgroundImages,
      suppressHydrationWarning,
    } = this.props;

    const { backgroundImageLoaded } = this.state;
    let style = {};

    if (backgroundImages) {
      const { image } = backgroundImages;

      if (CACHED_IMAGES[pageName] || backgroundImageLoaded) {
        style = { backgroundImage: `url(${image})` };
      }
    }

    return (
      <div
        suppressHydrationWarning={suppressHydrationWarning}
        className={classNames(
          'Section',
          {
            'Section--header': header,
            'Section--content': content,
            'Section--alternate': alternate,
            'Section--footer': footer,
            'Section--transparent': transparent,
          },
          className
        )}
        ref={content ? this.content : null}
      >
        {backgroundImages && (
          <Fragment>
            {!backgroundImageLoaded && (
              <canvas ref={this.canvas} className="Section__canvas" />
            )}
            <div
              suppressHydrationWarning={suppressHydrationWarning}
              className={classNames('Section--withBackgroundImage', {
                'Section--fadeInBackgroundImage': backgroundImageLoaded && !CACHED_IMAGES[pageName],
              })}
              style={style}
            />
            {backgroundImageLoaded && <div className="Section__overlay" />}
          </Fragment>
        )}
        {Children.toArray(children)}
      </div>
    );
  }
}

Section.defaultProps = {
  transparent: false,
  header: false,
  content: false,
  alternate: false,
  footer: false,
  className: null,
  backgroundImages: null,
  pageName: '',
  suppressHydrationWarning: false,
};

Section.propTypes = {
  transparent: PropTypes.bool,
  header: PropTypes.bool,
  content: PropTypes.bool,
  alternate: PropTypes.bool,
  footer: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundImages: PropTypes.object,
  pageName: PropTypes.string,
  suppressHydrationWarning: PropTypes.bool,
};

export default Section;
