import React from 'react';
import groupBy from 'lodash/groupBy';
import fromPairs from 'lodash/fromPairs';
import FilterBox from './FilterBox';
import Checkbox from '../form/Checkbox';
import { trackFilterCheckbox } from '../../lib/tracking';

import './FilterAirportList.less';

function isValidFilterChange(code, filters, meta) {
  if (!meta.from[code] && !meta.to[code]) return true;
  if (!filters.getFilter('airports').allows(code)) return true;

  const checkedCodes = (meta.from[code] ? meta.getFrom() : meta.getTo()).filter(
    c => meta.airports[c] && filters.getFilter('airports').allows(c)
  );
  return checkedCodes.length !== 1 || checkedCodes[0] !== code;
}

class FilterAirportList extends React.Component {
  onToggle(code) {
    const { search, filters, filterName } = this.props;
    const newFilterState = !filters.getFilter('airports').allows(code);
    // Track filtering
    trackFilterCheckbox(filterName);

    if (isValidFilterChange(code, filters, search.getMeta())) {
      filters.set('airports', { [code]: newFilterState });
    }
  }

  onToggleAll(airports) {
    const { filters, filterName } = this.props;
    const areAirportsHidden = airports.some(
      a => !filters.getFilter('airports').allows(a.getCode())
    );
    // Track filtering
    trackFilterCheckbox(filterName);

    filters.set('airports', fromPairs(airports.map(a => [a.getCode(), areAirportsHidden])));
  }

  renderAirportsGeneric(title, options, firstCheckbox = null) {
    const { filters } = this.props;
    return (
      <div className="FilterAirportList__group">
        <div className="FilterAirportList__heading">{title}</div>
        <ul className="FilterAirportList__rows">
          {firstCheckbox}
          {options.map(({ code, name }) => (
            <li key={code} className="FilterAirportList__row">
              <Checkbox
                checked={filters.getFilter('airports').allows(code)}
                onChange={this.onToggle.bind(this, code)}
              >
                {name}
              </Checkbox>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderMainAirports(airports, formAirport, loadingTitle) {
    if (!airports) return false;

    const title = formAirport ? formAirport.get('name') : loadingTitle;

    const options = airports.map(a => ({
      code: a.getCode(),
      name: `${a.getName()} (${a.getCode()})`,
    }));

    return this.renderAirportsGeneric(title, options);
  }

  renderStopOverAirports(airports) {
    if (!airports) return false;
    const { filters, t } = this.props;

    const options = airports.map(a => ({
      code: a.getCode(),
      name: `${a.getCityName()} (${a.getCode()})`,
    }));

    const toggleAll = (
      <Checkbox
        checked={airports.every(a => filters.getFilter('airports').allows(a.getCode()))}
        onChange={this.onToggleAll.bind(this, airports)}
      >
        {t('all_select_deselect')}
      </Checkbox>
    );

    return this.renderAirportsGeneric(t('Stopover airports'), options, toggleAll);
  }

  render() {
    const { search, filters, origin, destination, t, title, mobile } = this.props;
    const meta = search.getMeta();
    const { origins, destinations, others } = groupBy(meta.getAirports(), a => {
      if (meta.from[a.getCode()]) return 'origins';
      if (meta.to[a.getCode()]) return 'destinations';
      return 'others';
    });
    const isFilterOn = Object.values(filters.getFilter('airports')._state).length > 0;

    return (
      <FilterBox title={title} initialOpen={false} isFilterOn={mobile && isFilterOn}>
        <div className="FilterAirportList">
          {this.renderMainAirports(origins, origin, t('takeoff'))}
          {this.renderMainAirports(destinations, destination, t('dropoff'))}
          {this.renderStopOverAirports(others)}
        </div>
      </FilterBox>
    );
  }
}

export default FilterAirportList;
