import React from 'react';
import './ItinerarySectionLoading.less';

const ItinerarySectionLoading = () => (
  <div className="ItinerarySectionLoading">
    <div className="ItinerarySectionLoading__header">
      <div className="ItinerarySectionLoading__headerTile" />
      <div className="ItinerarySectionLoading__headerSmallTile" />
    </div>
    <div className="ItinerarySectionLoading__route">
      <div className="ItinerarySectionLoading__routeTile" />
    </div>
    <div className="ItinerarySectionLoading__separator" />
    <div className="ItinerarySectionLoading__route">
      <div className="ItinerarySectionLoading__routeTile" />
    </div>
  </div>
);

export default ItinerarySectionLoading;
