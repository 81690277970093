import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sortAndFilterVendorFares } from '@lib/itineraryInfo';
import LoadingTile from './LoadingTile';
import VendorTile from './VendorTile';
import Disclaimer from '../../flights/Disclaimer';

import './VendorPicker.less';

class VendorPicker extends Component {
  /**
   * @param {Function} t
   * @param {Array} youngstersAges
   * @returns {String} in appr. lang. indicating number of children
   * @memberof VendorPicker
   */
  getChildrenString(t, youngstersAges) {
    const s = youngstersAges.length;

    if (s === 0) {
      return '';
    }

    return `, ${s} ${s > 1 ? t('children') : t('Child')}`;
  }

  render() {
    const {
      search,
      filters,
      itinerary,
      openMultiFare,
      vendors,
      filters: { t },
      vendorFilters,
    } = this.props;
    const { adults, youngstersAges } = search.meta;
    const adultsString = adults > 1 ? t('adults') : t('adult');

    // When refreshing site itinerary can be undefined for some time
    let bestFare;
    let sortedTickets = [];

    if (itinerary) {
      bestFare = itinerary.getFares().getBestFare();
      const { displayedFares } = sortAndFilterVendorFares(itinerary, vendors, vendorFilters);
      sortedTickets = displayedFares;
    }

    return (
      <div className="VendorPicker">
        <span className="VendorPicker__title">{t('where_to_book')}</span>
        <span className="VendorPicker__subTitle">
          {adults} {adultsString}
          {this.getChildrenString(t, youngstersAges)}
        </span>
        {itinerary ? (
          <Fragment>
            <Disclaimer search={search} itinerary={itinerary} />
            {sortedTickets.map((fare, i) => (
              <VendorTile
                key={i}
                fare={fare}
                search={search}
                filters={filters}
                bestFare={bestFare}
                itinerary={itinerary}
                openMultiFare={openMultiFare}
                sortedTickets={sortedTickets}
              />
            ))}
          </Fragment>
        ) : (
          <LoadingTile />
        )}
      </div>
    );
  }
}

VendorPicker.propTypes = {
  search: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  vendorFilters: PropTypes.object.isRequired,
  itinerary: PropTypes.object,
  openMultiFare: PropTypes.func.isRequired,
};

export default connect(state => ({
  vendors: state.getIn(['flightSearch', 'vendors']),
  vendorFilters: state.getIn(['filters', 'vendors']),
}))(VendorPicker);
