import React from 'react';
import SearchError from '../errors/SearchError';
import './AllResultsFiltered.less';

const AllResultsFiltered = ({ t, onReset, modifyDates }) => {
  return (
    <SearchError title={t('all_results_filtered')} t={t}>
      <div>
        <div>
          {t('please_adjust_filters_1')}
          <span className="AllResultsFiltered__reset" onClick={onReset}>
            {' '}
            {t('please_adjust_filters_2')}
          </span>
        </div>
        {modifyDates ? (
          <div>
            {t('try_modify_dates_1')}
            <span className="AllResultsFiltered__reset" onClick={modifyDates}>
              {' '}
              {t('try_modify_dates_2')}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </SearchError>
  );
};

export default AllResultsFiltered;
