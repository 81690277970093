import React from 'react';
import './ExploreBoxes.less';

// A better name for this class would be SidebarAds but that className might trigger AdBlocker
const ExploreBoxes = ({ t }) => (
  <a className="ExploreBoxes__box" href="/cars" target="_blank">
    <span
      dangerouslySetInnerHTML={{
        __html: `${t('rent_a_car_with_dohop', {
          icon: '<span class="ExploreBoxes__icon"></span>',
        })}`,
      }}
    />
    <div className="ExploreBoxes__link">{t('see_cars')}</div>
  </a>
);

export default ExploreBoxes;
