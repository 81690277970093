import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { daysApart } from 'dohop-client-api/src/time';
import './DatePickerDay.less';

class DatePickerDay extends React.Component {
  constructor(props) {
    super(props);

    this.onHover = this.onHover.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  getIsBetween() {
    const {
      day,
      highlight: [min, max],
    } = this.props;
    return min && max && daysApart(min, day) >= 0 && daysApart(day, max) >= 0;
  }

  getIsSelected() {
    const { hoverDate, day, selected, activeDateIndex } = this.props;
    // The activeDateIndex is either 0 or 1. Select the oppostie of that
    const inverseIndex = activeDateIndex ^ 1;
    const selectedDate = selected[inverseIndex];
    return (
      (hoverDate && daysApart(hoverDate, day) === 0) ||
      (selectedDate && daysApart(selectedDate, day) === 0) ||
      (!hoverDate && selected.some((d) => d && daysApart(d, day) === 0))
    );
  }

  getAvailability() {
    const { calendar, activeDateIndex, day, selected } = this.props;
    if (!calendar) return 'direct';
    if (activeDateIndex === 0) {
      return calendar.getOutboundAvailability(day);
    }
    return calendar.getHomeboundAvailability(day, selected[0]);
  }

  getIsEnabled() {
    const { day, minDate, maxDate, selected, activeDateIndex } = this.props;
    return (
      (!minDate || daysApart(minDate, day) >= 0) &&
      (!maxDate || daysApart(day, maxDate) >= 0) &&
      (activeDateIndex === 0 || !selected[0] || daysApart(selected[0], day) >= 0)
    );
  }

  onHover() {
    const { onHover, day } = this.props;
    onHover(day);
  }

  onSelect() {
    const { onSelect, day } = this.props;
    onSelect(day);
  }

  render() {
    const availability = this.getAvailability();
    const enabled = availability != null && this.getIsEnabled();
    const { day, month } = this.props;

    return (
      <td
        onMouseOver={enabled ? this.onHover : undefined}
        onMouseOut={enabled ? this.onHover : undefined}
        onClick={enabled ? this.onSelect : undefined}
        className={classNames('DatePickerDay', {
          'DatePickerDay--hidden': day.getUTCMonth() !== month.getUTCMonth(),
          'DatePickerDay--isBetween': this.getIsBetween(),
          'DatePickerDay--indirect': availability === 'indirect',
          'DatePickerDay--selected': this.getIsSelected(),
          'DatePickerDay--disabled': !enabled,
        })}
      >
        {day.getUTCDate()}
      </td>
    );
  }
}

DatePickerDay.propTypes = {
  highlight: PropTypes.array.isRequired,
  onHover: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default DatePickerDay;
