import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowRight from '@content/icons/arrow-right.svg';
import Arrows from '@content/img/icons/arrows_white.svg';
import Compass from '@content/svg/compass.svg';
import City from '@content/svg/city.svg';
import Airport from '@content/svg/airport.svg';
import RecentSearch from '@content/svg/recent_search.svg';
import './Result.less';

class AutocompleteResult extends Component {
  formatTitle() {
    const {
      result: { item, title },
      isActive,
    } = this.props;
    const isRecentSearch = item.get('recentSearch');

    if (isRecentSearch) {
      const origin = item.getIn(['origin', 'name']);
      const destination = item.getIn(['destination', 'name']);
      const isOneWay = item.get('isOneWay');

      return (
        <span>
          {origin}
          {isOneWay ? (
            <ArrowRight
              className={classNames('AutocompleteResult__arrowIcon', {
                'AutocompleteResult__icon--white': isActive,
              })}
            />
          ) : (
            <Arrows
              className={classNames('AutocompleteResult__arrowIcon', {
                'AutocompleteResult__icon--white': isActive,
              })}
            />
          )}
          {destination}
        </span>
      );
    }
    return title;
  }

  renderIcon({ isActive, nested, isFlexible, isRecentSearch }) {
    const className = classNames('AutocompleteResult__icon', {
      'AutocompleteResult__icon--small': nested,
      'AutocompleteResult__icon--white': isActive,
      'AutocompleteResult__icon--gray': isFlexible || nested,
    });

    if (isFlexible) {
      return <Compass className={className} />;
    } else if (nested) {
      return <Airport className={className} />;
    } else if (isRecentSearch) {
      return <RecentSearch className={className} />;
    }
    return <City className={className} />;
  }

  render() {
    const {
      idx,
      id,
      isActive,
      result,
      result: { item, nested, subTitle },
      onMouseOver,
      onClick,
    } = this.props;
    const isFlexible = item.get('flexible');
    const isRecentSearch = item.get('recentSearch');

    return (
      <li
        className={classNames('AutocompleteResult', {
          'AutocompleteResult--active': isActive,
          'AutocompleteResult--nested': nested,
        })}
        onMouseOver={e => onMouseOver(idx, e, id)}
        onClick={e => onClick(result, e)}
      >
        {this.renderIcon({ isActive, nested, isFlexible, isRecentSearch })}
        <div
          className={classNames('AutocompleteResult__main', {
            'AutocompleteResult__main--oneLine': isFlexible,
          })}
        >
          <div className="AutocompleteResult__title">{this.formatTitle()}</div>

          {subTitle && (
            <div
              className={classNames('AutocompleteResult__subTitle', {
                'AutocompleteResult__subTitle--active': isActive,
              })}
            >
              {subTitle}
            </div>
          )}
        </div>
      </li>
    );
  }
}

AutocompleteResult.defaultProps = {
  id: '',
  isActive: false,
  iconSrc: null,
  nested: false,
  title: '',
  subTitle: null,
};

AutocompleteResult.propTypes = {
  idx: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  result: PropTypes.object.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  iconSrc: PropTypes.string,
  nested: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isFlexible: PropTypes.bool,
};

export default AutocompleteResult;
