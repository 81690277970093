import { generateRandomInteger } from 'shared/utils/math';
import isBrowser from '@lib/isBrowser';

const HEADER_IMG_PATH = 'content/img/header/';

function getRandomImages(images) {
  return images[generateRandomInteger(0, images.length - 1)];
}

export const flightSearchImages = [
  {
    image: `${HEADER_IMG_PATH}beach.jpg`,
    thumbnail: `${HEADER_IMG_PATH}beach_small.jpg`,
  },
  {
    image: `${HEADER_IMG_PATH}skii.jpeg`,
    thumbnail: `${HEADER_IMG_PATH}skii_small.jpeg`,
  },
  {
    image: `${HEADER_IMG_PATH}newyork.jpg`,
    thumbnail: `${HEADER_IMG_PATH}newyork_small.jpg`,
  },
  {
    image: `${HEADER_IMG_PATH}sardinia.jpg`,
    thumbnail: `${HEADER_IMG_PATH}sardinia_small.jpg`,
  },
];

export const hotelSearchImages = [
  {
    image: `${HEADER_IMG_PATH}hotel1.jpg`,
    thumbnail: `${HEADER_IMG_PATH}hotel1_small.jpg`,
  },
  {
    image: `${HEADER_IMG_PATH}hotel2.jpg`,
    thumbnail: `${HEADER_IMG_PATH}hotel2_small.jpg`,
  },
  {
    image: `${HEADER_IMG_PATH}hotel3.jpeg`,
    thumbnail: `${HEADER_IMG_PATH}hotel3_small.jpeg`,
  },
  {
    image: `${HEADER_IMG_PATH}cabin1.jpg`,
    thumbnail: `${HEADER_IMG_PATH}cabin1_small.jpg`,
  },
];

export const carSearchImages = [
  {
    image: `${HEADER_IMG_PATH}road.jpg`,
    thumbnail: `${HEADER_IMG_PATH}road_small.jpg`,
  },
];

export function getImagesOnServer() {
  return {
    hotel: getRandomImages(hotelSearchImages),
    flight: getRandomImages(flightSearchImages),
    car: getRandomImages(carSearchImages),
  };
}

export function getImagesOnClient() {
  if (isBrowser()) {
    return window.__HeaderImages__;
  }

  return null;
}
