import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import createFilters from '@lib/createFilters';
import getPrivacyPolicyPath from '@lib/getPrivacyPolicyPath';
import InfoFooter from './info/InfoFooter';
import FacebookCircle from '@content/icons/facebookcircle.svg';
import './Footer.less';

const cities = {
  JFK: 'New York',
  LAX: 'Los Angeles',
  LHR: 'London',
  BCN: 'Barcelona',
  CDG: 'Paris',
  AMS: 'Amsterdam',
  PEK: 'Beijing',
  SYD: 'Sydney',
};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { isCityDropDownOpen: false };
  }

  renderLink(child, to, { condition = true } = {}) {
    return (
      condition && (
        <Link className="Footer__link" to={to}>
          {child}
        </Link>
      )
    );
  }

  renderHref(child, href, { target = '_self', condition = true } = {}) {
    return (
      condition && (
        <a className="Footer__link" target={target} href={href}>
          {child}
        </a>
      )
    );
  }

  renderCitiesLink() {
    const { t } = this.props;
    const { isCityDropDownOpen } = this.state;
    return (
      <div>
        <span
          className="Footer__link Footer__citydropdown"
          onClick={() => {
            this.setState({ isCityDropDownOpen: !isCityDropDownOpen });
          }}
        >
          {t('cities')}
          {isCityDropDownOpen ? ' -' : ' +'}
        </span>
      </div>
    );
  }

  renderCities() {
    const { isCityDropDownOpen } = this.state;
    return (
      <ul
        className={classNames('Footer__lists', {
          'Footer__lists--hidden': !isCityDropDownOpen,
        })}
      >
        {Object.keys(cities).map((city) => (
          <li key={city} className="Footer__cities">
            <a
              className="Footer__link Footer__link__cities"
              href={`/flights-to-${city}`}
              target="_blank"
            >
              {cities[city]}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  getGroups() {
    const { t, user } = this.props;
    const residency = user.get('residency');
    const groups = [
      {
        name: t('search_engines'),
        items: [
          this.renderCitiesLink(),
          this.renderCities(),
          this.renderLink(t('flights'), '/flights'),
          this.renderLink(t('hotels'), '/hotels'),
          this.renderLink(t('Dohop cars'), '/cars'),
        ],
      },
      {
        name: t('aboutdohop'),
        items: [
          this.renderLink(t('history'), '/about/about-us'),
          this.renderLink(t('terms_and_conditions'), '/about/terms-and-conditions'),
          this.renderLink(t('contact_us'), '/about/contact-us'),
          this.renderLink(
            t('CookieTitleIntroduction'),
            'https://www.datocms-assets.com/61479/1734102735-cookie-policy-dh-22.pdf',
            {
              target: '_blank',
            }
          ),
          this.renderHref(t('privacy_policy'), getPrivacyPolicyPath(), {
            target: '_blank',
          }),
          this.renderLink(t('imprint'), '/about/imprint', {
            condition: residency === 'DE',
          }),
        ],
      },
      {
        name: t('business_solutions'),
        items: [
          this.renderHref(t('for_airlines'), 'https://b2b.dohop.com'),
          this.renderHref(t('for_airports'), 'https://b2b.dohop.com'),
        ],
      },
      {
        name: t('social_network'),
        items: [
          <a
            href={'https://www.facebook.com/pages/Dohopcom/41400025548'}
            target="_blank"
            className="Footer__social"
          >
            <FacebookCircle className="Footer__socialIcon" />
          </a>,
        ],
        extraClass: 'Footer__socialLogo',
      },
    ];

    return groups;
  }

  render() {
    const { t, withMobileTabs } = this.props;
    const groups = this.getGroups();

    return (
      <div className={classNames('Footer', { 'Footer--withMobileTabs': withMobileTabs })}>
        <div className="Footer__desktop">
          {groups.map((group) => (
            <div key={group.name} className="Footer__group">
              <div className="Footer__header">{group.name}</div>
              <ul className="Footer__lists">
                {group.items.map((item, index) => (
                  <li key={index} className={classNames(group.extraClass)}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <InfoFooter t={t} />
        </div>
      </div>
    );
  }
}

Footer = connect((state) => ({
  user: state.get('user'),
}))(Footer);

export default createFilters(['t'])(Footer);
