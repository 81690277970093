import React from 'react';
import PropTypes from 'prop-types';
import './FilterFeedback.less';

/**
 *
 * @param {Object} props
 * @returns Bottom bar showing state of filters
 */
const FilterFeedback = ({ apply, onClose, showingResults }) => {
  return (
    <div className="FilterFeedback">
      <span className="FilterFeedback__itineraries">{showingResults}</span>
      <button onClick={() => onClose()} className="FilterFeedback__btn">
        {apply}
      </button>
    </div>
  );
};

FilterFeedback.propTypes = {
  apply: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showingResults: PropTypes.string.isRequired,
};

export default FilterFeedback;
