import Immutable, { Map } from 'immutable';
import transferLib from 'dohop-client-api/src/transfer';
import request from 'dohop-client-api/src/request';
import processException from '../lib/processException';
import { createAction } from '../lib/redux/actions';
import settle from '../lib/settlePromise';

export const TRANSFER_INITIALIZE_SUCCESS = 'TRANSFER_INITIALIZE_SUCCESS';
export const TRANSFER_INITIALIZE_FAILED = 'TRANSFER_INITIALIZE_FAILED';
export const TRANSFER_RESUME_SUCCESS = 'TRANSFER_RESUME_SUCCESS';
export const TRANSFER_RESUME_FAILED = 'TRANSFER_RESUME_FAILED';

export const initializeTransferSuccess = createAction(
  TRANSFER_INITIALIZE_SUCCESS,
  'transferState',
  'vendor'
);
export const initializeTransferFailed = createAction(TRANSFER_INITIALIZE_FAILED, 'error', 'vendor');
export const resumeTransferSuccess = createAction(TRANSFER_RESUME_SUCCESS, 'deeplink');
export const resumeTransferFailed = createAction(TRANSFER_RESUME_FAILED, 'error');

function requestTransfer(sessionKey, fareId, vendorId, currency) {
  return new Promise((resolve, reject) => {
    transferLib.initializeTransfer({
      sessionKey,
      fareId,
      vendorId,
      currency,
      success: resolve,
      error: reject,
    });
  });
}

function fetchVendor(vendorId) {
  return new Promise((resolve, reject) => {
    request.get('vendor', {
      id: vendorId,
      success: resolve,
      error: reject,
    });
  });
}

export function initializeTransfer(sessionKey, fareId, vendorId, currency) {
  return (dispatch, getState) => {
    return Promise.all([
      settle(requestTransfer(sessionKey, fareId, vendorId, currency)),
      settle(fetchVendor(vendorId)),
    ])
      .then(
        ([transfer, vendor]) => {
          if (transfer.success) {
            // the vendor call might have failed, the ui needs to handle an empty vendor gracefully
            dispatch(
              initializeTransferSuccess(transfer.value, Immutable.fromJS(vendor.value || {}))
            );
          } else {
            const { message } = transfer.error;

            // we have a vendor but no transfer, search session has probably expired, best we can do
            // is go to the vendor frontpage
            if (vendor.success) {
              dispatch(initializeTransferFailed(Map({ message }), Immutable.fromJS(vendor.value)));
            } else {
              // both requests failed - we're screwed
              dispatch(initializeTransferFailed(Map({ message }), undefined));
            }
          }
        },
        (error) => dispatch(initializeTransferFailed(error))
      )
      .catch(processException);
  };
}

export function resumeTransfer() {
  return (dispatch, getState) => {
    const state = getState();
    const transferState = state.getIn(['transfer', 'transferState']);
    const transferError = state.getIn(['transfer', 'error', 'message']);

    // If initializing the Transfer has failed, we stop the process and notify bugsnag.
    if (!transferState && transferError) {
      return processException(
        `Generating deeplink from transfer url failed, error message: ${transferError}`
      );
    }
    return new Promise((resolve, reject) => {
      transferLib.poll(transferState, {
        success: resolve,
        error: reject,
      });
    })
      .then((deeplink) => dispatch(resumeTransferSuccess(Immutable.fromJS(deeplink))))
      .catch(processException);
  };
}
