import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiTicket from '../../flights/MultiTicket';
import MatchMedia from '../../MatchMedia';
import createFilters from '../../../lib/createFilters';
import MobileView from '../../MobileView';
import Arrow from '../../Arrow';
import './MultiTickets.less';

class MultiTickets extends Component {
  renderMobileViewTitle() {
    const { itinerary } = this.props;
    return (
      <div>
        {itinerary
          .getRoutes()[0]
          .getOrigin()
          .getCode()}
        <div className="MultiTickets__mobile-arrow">
          <Arrow />
        </div>
        {itinerary
          .getRoutes()[0]
          .getDestination()
          .getCode()}
      </div>
    );
  }

  renderMobileViewSubtitle() {
    const { search, formatDate, t } = this.props;
    return (
      <span>
        {formatDate(search.getMeta().getOutboundDate(), 'D MMM')}
        {!search.getMeta().isOneWay() &&
          ' - ' + formatDate(search.getMeta().getHomeboundDate(), 'D MMM')}
        <span>
          {', '}
          {search.getMeta().getNumPassengers()}{' '}
          {search.getMeta().getNumPassengers() > 1 ? t('passengers') : t('passenger')}
        </span>
      </span>
    );
  }

  render() {
    const { fare, search, onClose, itinerary } = this.props;
    return (
      <div>
        <MatchMedia min="ipad">
          <MultiTicket fare={fare} search={search} onClose={onClose} itinerary={itinerary} />
        </MatchMedia>
        <MatchMedia max="ipad">
          <MobileView
            title={this.renderMobileViewTitle()}
            onClose={onClose}
            subtitle={this.renderMobileViewSubtitle()}
          >
            <MultiTicket
              fare={fare}
              mobile
              search={search}
              onClose={() => {}}
              itinerary={itinerary}
            />
          </MobileView>
        </MatchMedia>
      </div>
    );
  }
}

MultiTickets = createFilters(['t', 'formatDate'])(MultiTickets);

MultiTickets.propTypes = {
  fare: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  itinerary: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

MultiTickets.defaultProps = {
  onClose: () => {},
};

export default MultiTickets;
