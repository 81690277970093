/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS202: Simplify dynamic range loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _every = require('lodash/every');
const _map = require('lodash/map');
const _compact = require('lodash/compact');
const _values = require('lodash/values');
const _once = require('lodash/once');
const sorting = require('./sorting');
const request = require('./request');
const util = require('./util');
const time = require('./time');
const config = require('./config');
const { Itinerary, Route, FareCollection, Flight, Fare, MultiFare } = require('./flight');

const getVendorFareKey = (fare, sep) =>
  Array.from(fare.getVendorFares())
    .map((vf) => vf.getVendor().getKey())
    .join(sep);

const getFareKeys = (fare, sep) =>
  Array.from(fare.getFares()).map(
    (f) => `${f.getId()}${sep}${f.getVersion()}${sep}${getVendorFareKey(f, sep)}`
  );

class ItineraryCache {
  constructor() {
    this.cache = {};
    this.hits = 0;
    this.misses = 0;
  }

  loadItinerary(routes, fares) {
    const itinKey = Array.from(routes)
      .map((r) => r.getId())
      .join('_');
    const fareKey = Array.from(fares)
      .map((f) => getFareKeys(f, '_').join('__'))
      .join('___');

    if ((this.cache[itinKey] != null ? this.cache[itinKey].fareKey : undefined) === fareKey) {
      this.hits++;
      return this.cache[itinKey].itinerary;
    }
    this.misses++;

    const itinerary = new Itinerary(routes, new FareCollection(fares));
    this.cache[itinKey] = { itinerary, fareKey };

    return itinerary;
  }
}

// @nodoc
class ItineraryLoader {
  constructor(queue, fareLoader, cache) {
    this.queue = queue;
    this.fareLoader = fareLoader;
    if (cache == null) {
      cache = new ItineraryCache();
    }
    this.cache = cache;
  }

  next() {
    const routes = this._nextRoutes();
    if (routes == null) {
      return;
    }
    const fares = this._getOrCreateFares(routes);
    return this.cache.loadItinerary(routes, fares);
  }

  _nextRoutes() {
    const routes = this.queue.next();
    if (!routes) {
      return;
    }

    util.assert([1, 2].includes(routes.length));
    util.assert(_every(routes, (r) => r instanceof Route));
    return routes;
  }

  _getOrCreateFares(...args) {
    const [routeOut, routeHome] = Array.from(args[0]);
    let faresOut = this.fareLoader.getOutboundFares(routeOut.getId()).all().slice();

    if (!routeHome) {
      if (faresOut.length > 0) {
        return faresOut;
      }
      return [this._createFare(routeOut.getFlights(), [])];
    }

    let faresHome = this.fareLoader.getHomeboundFares(routeHome.getId()).all().slice();
    let twoWayFares = this.fareLoader
      .getTwoWayFares(routeOut.getId(), routeHome.getId())
      .all()
      .slice();

    if (twoWayFares.length === 0) {
      if (faresOut.length === 0 && faresHome.length === 0) {
        twoWayFares = [this._createFare(routeOut.getFlights(), routeHome.getFlights())];
      } else if (faresOut.length === 0) {
        faresOut = [this._createFare(routeOut.getFlights(), [])];
      } else if (faresHome.length === 0) {
        faresHome = [this._createFare([], routeHome.getFlights())];
      }
    }

    // combine outbound and homebound fares
    for (let fareOut of Array.from(faresOut)) {
      for (let fareHome of Array.from(faresHome)) {
        twoWayFares.push(fareOut.combine(fareHome));
      }
    }

    return twoWayFares;
  }

  _createFare(flightsOut, flightsHome) {
    // map homebound flights to be able to find homebound flight matching outbound flight
    const homeboundMap = util.eachWithObject(
      flightsHome,
      {},
      (map, fhome) =>
        (map[
          `${fhome
            .getAirline()
            .getCode()}${fhome.getDestination().getCode()}${fhome.getOrigin().getCode()}`
        ] = fhome)
    );

    // create fare for each outbound flight and corresponding homebound flight if available
    const fares = _map(flightsOut, (fout) => {
      let left, left1;
      const homeKey = `${fout
        .getAirline()
        .getCode()}${fout.getOrigin().getCode()}${fout.getDestination().getCode()}`;
      const fhome = util.popKey(homeboundMap, homeKey);
      const flights = _compact([fout, fhome]);
      const legsHome = (left = fhome != null ? fhome.getLegs() : undefined) != null ? left : [];
      return (left1 = this.fareLoader.getFareForFlights(flights)) != null
        ? left1
        : new Fare(null, [], fout.getLegs(), legsHome);
    });

    // add fare for homebound flights that did not match any outbound flight
    const homeboundLeft = util.sortBy(_values(homeboundMap), (fhome) => flightsHome.indexOf(fhome));
    for (let fhome of Array.from(homeboundLeft)) {
      var left;
      fares.push(
        (left = this.fareLoader.getFareForFlights([fhome])) != null
          ? left
          : new Fare(null, [], fhome.getLegs(), [])
      );
    }

    // combine multiple fares in a multifare if needed
    if (fares.length === 1) {
      return fares[0];
    } else {
      return new MultiFare(fares);
    }
  }
}

class FareRefresher {
  constructor(_poller, _faresort, events, options) {
    this._releaseLock = this._releaseLock.bind(this);
    this._poller = _poller;
    this._faresort = _faresort;
    this._done = {};
    this.enabled =
      (options != null ? options.enabled : undefined) != null
        ? options != null
          ? options.enabled
          : undefined
        : config.enableFareRefresh;
    this._locked = true;
    this._releaseLock = _once(this._releaseLock);
    setTimeout(this._releaseLock, time.second * 10);
    events.on('end', this._releaseLock);
  }

  _releaseLock() {
    this._locked = false;
    if (this._queuedItineraries) {
      this.sendFareRequests(this._queuedItineraries);
      return delete this._queuedItineraries;
    }
  }

  queueFareRequests(itineraries) {
    if (this._locked) {
      return (this._queuedItineraries = itineraries);
    } else {
      return this.sendFareRequests(itineraries);
    }
  }

  sendFareRequests(itineraries) {
    let key, routes;
    if (this.enabled === false) {
      return;
    }

    const faresort = this._faresort.update();
    const keys = [];
    for (let itinerary of Array.from(itineraries)) {
      routes = itinerary.getRoutes();
      key = Array.from(routes)
        .map((r) => r.getId())
        .join('_');
      if (this._done[key]) {
        continue;
      }

      // nothing to do if we have outbound fares for one-way
      const hasOneWayOut = faresort['1-way-out'][routes[0].getId()] != null;
      if (routes.length === 1 && hasOneWayOut) {
        continue;
      }

      if (routes[1]) {
        const hasOneWayHome = faresort['1-way-home'][routes[1].getId()] != null;
        const hasTwoWay =
          __guard__(faresort.rt[routes[0].getId()], (x) => x[routes[1].getId()]) != null;
        // nothing to do if we fares both ways for two-way
        if ((hasOneWayOut && hasOneWayHome) || hasTwoWay) {
          continue;
        }
      }

      // otherwise we send fare-request for this itinerary
      this._done[key] = true;
      keys.push(key);
    }

    if (keys.length > 0) {
      return request.get('refresh', {
        key: this._poller.key,
        routes: keys.join(','),
        success: () => this._poller.poll(),
      });
    }
  }
}

// Itinerary manager loads itineraries lazily from the sorting routines as needed.
// @example
//   search.getItineraries.getRange(0, 10)
class ItineraryManager {
  // @nodoc
  constructor(queue, _fareRefresher) {
    this.queue = queue;
    this._fareRefresher = _fareRefresher;
    this._itineraries = [];
  }

  // @nodoc
  next() {
    return this.get(this._itineraries.length);
  }

  // Fetches itinerary at certain index starting at 0
  // @param [Integer] i index of itinerary
  // @return [Itinerary]
  get(i) {
    while (i >= this._itineraries.length) {
      const itinerary = this.queue.next();
      if (!itinerary) {
        return;
      }
      this._itineraries.push(itinerary);
    }
    return this._itineraries[i];
  }

  // Fetches multiple itineraries at the given indexes
  // @param [Integer] min lower bound index to fetch
  // @param [Integer] max higher bound index to fetch
  // @return [Array<Itinerary>] list of itineraries whos index is: min <= index < max
  getRange(min, max, sendFareRequest) {
    if (sendFareRequest == null) {
      sendFareRequest = true;
    }
    const itineraries = [];
    for (let k = min, end = max, asc = min <= end; asc ? k < end : k > end; asc ? k++ : k--) {
      const itinerary = this.get(k);
      if (!itinerary) {
        break;
      }
      itineraries.push(itinerary);
    }
    if (this._fareRefresher && sendFareRequest) {
      this._fareRefresher.queueFareRequests(itineraries);
    }
    return itineraries;
  }

  // Returns max or itinerary.length depending on which is smaller
  // @param [Integer] max
  // @return [Integer] max || itinLength
  getLengthOrMax(max) {
    let itineraryCount = 0;
    for (let i = 0, end = max, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
      const itinerary = this.get(i);
      if (!itinerary) {
        break;
      }
      itineraryCount++;
    }
    return itineraryCount;
  }

  // It would not be wise to use the next two methods in production code.
  // They are here for testing and debugging only.

  length() {
    while (this.next()) {
      continue;
    }
    return this._itineraries.length;
  }

  all() {
    return this.getRange(0, this.length(), false);
  }
}

module.exports = { ItineraryCache, ItineraryLoader, FareRefresher, ItineraryManager };

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
