import { List, Map, OrderedMap, Set, OrderedSet, Stack } from 'immutable';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isDate from 'lodash/isDate';

const LIST = 0;
const MAP = 1;
const ORDERED_MAP = 2;
const SET = 3;
const ORDERED_SET = 4;
const STACK = 5;
const JS_ARRAY = 6;
const DATE = 7;

function toJS(input) {
  if (List.isList(input)) {
    return [LIST, input.map(toJS).toArray()];
  }

  if (OrderedMap.isOrderedMap(input)) {
    let output = [];
    input.forEach((v, k) => {
      output.push(toJS(k));
      output.push(toJS(v));
    });
    return [ORDERED_MAP, output];
  }

  if (Map.isMap(input)) {
    let output = [];
    input.forEach((v, k) => {
      output.push(toJS(k));
      output.push(toJS(v));
    });
    return [MAP, output];
  }

  if (OrderedSet.isOrderedSet(input)) {
    let output = input.map(toJS).toArray();
    return [ORDERED_SET, output];
  }

  if (Set.isSet(input)) {
    let output = input.map(toJS).toArray();
    return [SET, output];
  }

  if (Stack.isStack(input)) {
    let output = input.map(toJS).toArray();
    return [STACK, output];
  }

  // because we encode type information in an array, arrays themselves must be encoded
  if (isArray(input)) {
    return [JS_ARRAY, input.map(toJS)];
  }

  // encode values in plain objects
  if (isPlainObject(input)) {
    let output = {};
    forOwn(input, (v, k) => {
      output[k] = toJS(v);
    });
    return output;
  }

  // encode date objects
  if (isDate(input)) {
    return [DATE, input];
  }

  // everything else can be left as is to be json encoded
  return input;
}

function fromJS(input) {
  // plain objects are not encoded in an array, just decode the values and return it
  if (isPlainObject(input)) {
    let output = {};
    forOwn(input, (v, k) => {
      output[k] = fromJS(v);
    });
    return output;
  }

  // if it's not an array (number, string etc.) we can return it as is
  if (!isArray(input)) {
    return input;
  }

  // otherwise it's an encoded array or immutable object
  let [type, value] = input;

  switch (type) {
    case LIST:
      return List(value.map(fromJS));
    case MAP:
      return Map().withMutations(map => {
        for (let i = 0; i < value.length; i += 2) {
          map.set(fromJS(value[i]), fromJS(value[i + 1]));
        }
      });
    case ORDERED_MAP:
      return OrderedMap().withMutations(map => {
        for (let i = 0; i < value.length; i += 2) {
          map.set(fromJS(value[i]), fromJS(value[i + 1]));
        }
      });
    case SET:
      return Set(value.map(fromJS));
    case ORDERED_SET:
      return OrderedSet(value.map(fromJS));
    case STACK:
      return Stack(value.map(fromJS));
    case JS_ARRAY:
      return value.map(fromJS);
    case DATE:
      return new Date(value);
    default:
      throw new Error(`immutableSerializer unexpected input type: ${type}`);
  }
}

export default { toJS, fromJS };
