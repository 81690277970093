import React, { Component } from 'react';
import PropTypes from 'prop-types';
import time from 'dohop-client-api/src/time';
import FilterSlider from './FilterSlider';

const STEP = time.hour;

class FilterDateSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSliderDrag = ([min, max]) => {
    this.setState({
      draggingMin: new Date(min),
      draggingMax: new Date(max),
    });
  };

  onSliderApply = () => {
    const { filters, filterName } = this.props;
    const { draggingMax, draggingMin } = this.state;
    const filterState = { min: draggingMin, max: draggingMax };
    filters.set(filterName, filterState);
    this.setState({ draggingMin: undefined, draggingMax: undefined });
  };

  render() {
    const { filters, filterName } = this.props;
    const { draggingMax, draggingMin } = this.state;
    const f = filters.getFilter(filterName);

    if (!f.getBounds().min || !f.getBounds().max) {
      return false;
    }

    const { formatDate } = this.props;
    const minAllowed = new Date(Math.floor(f.getBounds().min.getTime() / STEP) * STEP);
    const maxAllowed = new Date(Math.ceil(f.getBounds().max.getTime() / STEP) * STEP);
    const min = draggingMin || f.getMin() || minAllowed;
    const max = draggingMax || f.getMax() || maxAllowed;

    return (
      <FilterSlider
        value={[min.getTime(), max.getTime()]}
        hint={[min, max].map((d) => formatDate(d, 'ddd LT')).join(' - ')}
        step={STEP}
        min={minAllowed.getTime()}
        max={maxAllowed.getTime()}
        onChange={this.onSliderDrag.bind(this)}
        onAfterChange={this.onSliderApply.bind(this)}
        {...this.props}
      />
    );
  }
}

FilterDateSlider.propTypes = {
  filters: PropTypes.object.isRequired,
  filterName: PropTypes.string.isRequired,
};

export default FilterDateSlider;
