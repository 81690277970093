export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const ESCAPE = 27;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;

// vim navigation keys
export const H = 72;
export const J = 74;
export const K = 75;
export const L = 76;

// something missing? http://keycode.info/
