import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ENTER } from '@lib/keyCodes';
import withForwardedRef from '../../../wrappers/WithForwardedRef';
import ArrowDown from '@content/svg/arrow_down.svg';
import './SearchInput.less';

class SearchInput extends Component {
  state = { wasOpen: false };

  /**
   * If the shouldPopOpen props changes from false to true, we trigger the
   * onFocus function for that dropdown.
   */
  componentDidUpdate() {
    const { forwardedRef, shouldPopOpen } = this.props;
    const { wasOpen } = this.state;
    const hasRef = forwardedRef && forwardedRef.current;

    if (!wasOpen && shouldPopOpen && hasRef) {
      forwardedRef.current.focus();
      this.setWasOpen(shouldPopOpen);
    }
    if (wasOpen && !shouldPopOpen) {
      this.setWasOpen(shouldPopOpen);
    }
  }

  setWasOpen(shouldPopOpen) {
    this.setState({ wasOpen: shouldPopOpen });
  }

  /**
   * Used to make sure wherever you click on the input and its content (on icon etc) it changes to focus mode.
   */
  onClick = () => {
    const { forwardedRef } = this.props;
    const hasRef = forwardedRef && forwardedRef.current;
    if (hasRef) {
      forwardedRef.current.focus();
    }
  };

  onKeyDown = e => {
    const { forwardedRef, onKeyDown } = this.props;
    const hasRef = forwardedRef && forwardedRef.current;
    if (onKeyDown) {
      onKeyDown(e);
    }
    // Make sure the input always defocuses when pressing enter on keyboard.
    if (hasRef && e.keyCode === ENTER) {
      forwardedRef.current.blur();
    }
  };

  componentDidMount() {
    const { forwardedRef, autoFocus } = this.props;
    const hasRef = forwardedRef && forwardedRef.current;

    if (autoFocus && hasRef) {
      forwardedRef.current.focus();
    }
  }

  render() {
    const {
      id,
      forwardedRef,
      children,
      className,
      inputClassName,
      icon,
      label,
      showLabel,
      hasFocus,
      onClick,
      onFocus,
      onBlur,
      onMouseUp,
      readOnly,
      value,
      onChange,
      autoComplete,
      placeholder,
      pointer,
      withArrow,
      arrowClassName,
      error,
    } = this.props;

    return (
      <div
        className={classNames('SearchInput', className, {
          'SearchInput--focus': error || hasFocus,
        })}
      >
        <div onClick={this.onClick}>
          {showLabel && (
            <label
              className={classNames('SearchInput__label', ' Input__label', {
                'Input__label--focus': hasFocus,
                'Input__label--error': error,
              })}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {icon(
            classNames('SearchInput__icon', {
              'SearchInput__icon--pointer': pointer,
            })
          )}
          {withArrow && (
            <ArrowDown
              className={classNames('SearchInput__arrow', arrowClassName, {
                'SearchInput__arrow--open': hasFocus,
              })}
            />
          )}
          <input
            id={id}
            ref={forwardedRef}
            className={classNames('SearchInput__input', inputClassName, {
              'Input--focus': hasFocus || error,
              'SearchInput__input--pointer': pointer,
              SearchInput__error: error,
            })}
            readOnly={readOnly}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={this.onKeyDown}
            onMouseUp={onMouseUp}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onClick={onClick}
          />
        </div>
        {children}
      </div>
    );
  }
}

SearchInput.defaultProps = {
  id: null,
  forwardedRef: null,
  children: null,
  className: null,
  inputClassName: null,
  icon: () => { },
  label: '',
  showLabel: false,
  hasFocus: false,
  onFocus: () => { },
  onBlur: () => { },
  onMouseUp: () => { },
  onChange: () => { },
  onKeyDown: () => { },
  readOnly: false,
  value: '',
  autoComplete: 'on',
  placeholder: '',
  error: false,
};

SearchInput.propTypes = {
  id: PropTypes.string,
  forwardedRef: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  icon: PropTypes.func,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  hasFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseUp: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};

SearchInput = withForwardedRef(SearchInput);

export default SearchInput;
