import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import _throttle from 'lodash/throttle';
import _omit from 'lodash/omit';
import { EventEmitter } from 'events';

const events = new EventEmitter();
events.setMaxListeners(Infinity);

const WINDOW_EVENTS = {
  scroll: _throttle(() => events.emit('scroll'), 100),
  resize: _throttle(() => events.emit('resize'), 100),
};

export function addListener(callback) {
  Object.keys(WINDOW_EVENTS).forEach(k => {
    events.on(k, callback);
    if (events.listenerCount(k) === 1) {
      window.addEventListener(k, WINDOW_EVENTS[k]);
    }
  });
}

export function removeListener(callback) {
  Object.keys(WINDOW_EVENTS).forEach(k => {
    events.removeListener(k, callback);
    if (events.listenerCount(k) === 0) {
      window.removeEventListener(k, WINDOW_EVENTS[k]);
    }
  });
}

function isVisible(el, offset) {
  let { top, bottom } = el.getBoundingClientRect();
  return bottom + offset > 0 && top - offset < window.innerHeight;
}

class Lazy extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
    this.check = this.check.bind(this);
  }

  componentDidMount() {
    if (this.isVisible()) {
      this.setState({ loaded: true });
    } else {
      this.addListener();
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  addListener() {
    if (this.listening) return;
    addListener(this.check);
    this.listening = true;
  }

  removeListener() {
    if (!this.listening) return;
    removeListener(this.check);
    this.listening = false;
  }

  isVisible() {
    const { offset } = this.props;
    return Boolean(this.el) && isVisible(this.el, offset);
  }

  check() {
    if (this.isVisible()) {
      this.setState({ loaded: true });
      this.removeListener();
    }
  }

  render() {
    const { children, loadedProps, loadedClassName, comp } = this.props;
    const { loaded } = this.state;

    if (typeof children === 'function') {
      return children(loaded);
    }

    const props = _omit(this.props, 'comp', 'offset', 'loadedProps', 'loadedClassName');

    if (loaded) {
      Object.assign(props, loadedProps);
      props.className = classNames(props.className, loadedClassName);
    }

    // Extend props with ref property
    props.ref = c => (this.el = c);
    const childrenArr = React.Children.toArray(children);

    return React.createElement(comp, props, ...childrenArr);
  }
}

Lazy.propTypes = {
  offset: PropTypes.number,
};

Lazy.defaultProps = {
  offset: 100,
  comp: 'div',
  loadedProps: {},
};

export default Lazy;
