/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const util = require('./util');

// @nodoc
class PriorityQueue {
  constructor(cmp) {
    this.cmp = cmp;
    this.q = [];
  }

  _parent(i) {
    return parseInt((i - 1) / 2);
  }
  _left(i) {
    return i + i + 1;
  }
  _right(i) {
    return i + i + 2;
  }

  _heapify(i) {
    const left = this._left(i);
    const right = this._right(i);
    let smallest = i;

    if (left < this.q.length && this.cmp(this.q[left], this.q[smallest]) < 0) {
      smallest = left;
    }

    if (right < this.q.length && this.cmp(this.q[right], this.q[smallest]) < 0) {
      smallest = right;
    }

    if (smallest !== i) {
      [this.q[i], this.q[smallest]] = Array.from([this.q[smallest], this.q[i]]);
      return this._heapify(smallest);
    }
  }

  length() {
    return this.q.length;
  }

  pop() {
    const m = this.q[0];
    this.q[0] = this.q[this.q.length - 1];
    this.q.pop();

    if (this.q.length > 0) {
      this._heapify(0);
    }

    return m;
  }

  push(item) {
    // move item to the bottom of the heap
    this.q.push(item);
    let i = this.q.length - 1;

    // while the item has not been moved to the top
    return (() => {
      const result = [];
      while (i > 0) {
        const p = this._parent(i);

        if (this.cmp(this.q[p], this.q[i]) < 0) {
          break;
        }

        [this.q[p], this.q[i]] = Array.from([this.q[i], this.q[p]]);
        result.push((i = p));
      }
      return result;
    })();
  }
}

const scoreCompare = function (a, b) {
  for (let i = 0, end = a.length, asc = 0 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    if (a[i] < b[i]) {
      return -1;
    }
    if (b[i] < a[i]) {
      return +1;
    }
  }
  return 0;
};

// Iterates efficiently in sorted order through pairs of two sorted arrays. E.g. given two arrays,
// [1, 2] and [2, 3, 4], can be used to generate sums [1+2, 1+3, 2+2, 1+4, 2+3, 2+4]
class OrderedPairs {
  constructor({ aExists, bExists, aScore, bScore }) {
    this.aExists = aExists;
    this.bExists = bExists;
    this.aScore = aScore;
    this.bScore = bScore;
    if (this.aExists == null) {
      this.aExists = (ai) => this.aScore(ai) != null;
    }
    if (this.bExists == null) {
      this.bExists = (bi) => this.bScore(bi) != null;
    }

    this.aTop = 0;

    this.q = new PriorityQueue(scoreCompare);

    if (this.aExists(0) && this.bExists(0)) {
      this._push(0, 0);
    }
  }

  _push(ai, bi) {
    return this.q.push([this.aScore(ai) + this.bScore(bi), ai, bi]);
  }

  // returns two indexes, into the first list and the second list
  next() {
    if (this.q.length() === 0) {
      return;
    }

    const [score, ai, bi] = Array.from(this.q.pop());

    if (ai === this.aTop && this.aExists(ai + 1)) {
      this._push(++this.aTop, 0);
    }

    if (this.bExists(bi + 1)) {
      this._push(ai, bi + 1);
    }

    return [ai, bi];
  }
}

// Iterates in order through items in a array of arrays. The array must be sorted by the smallest item
// in each sub-array, and each sub-array itself must be sorted also. Empty sub-arrays are allowed
// in the array and are ignored.
class OrderedNestedList {
  constructor(n, score) {
    this.n = n;
    this.score = score;
    this.q = new PriorityQueue(scoreCompare);
    this._pushRow(0);
  }

  _pushRow(start) {
    // push the first item in a non-empty subarray
    return (() => {
      const result = [];
      for (
        let i = start, end = this.n, asc = start <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        const score = this.score(i, 0);
        if (score != null) {
          this.q.push([score, i, 0]);
          break;
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  // .next() returns two indexes, first into the main array, and the second is the index into the sub-array.
  next() {
    if (this.q.length() === 0) {
      return;
    }

    const [score, i, j] = Array.from(this.q.pop());

    const nextScore = this.score(i, j + 1);
    if (nextScore != null) {
      this.q.push([nextScore, i, j + 1]);
    }

    if (j === 0) {
      this._pushRow(i + 1);
    }

    return [i, j];
  }
}

// To put in front of an iterator (with a next() method) to get an array like behaviour via get()
class IterCache {
  constructor(iterator) {
    this.iterator = iterator;
    this._cache = [];
  }

  next() {
    return this.get(this._cache.length);
  }

  get(i) {
    while (i >= this._cache.length) {
      const item = this.iterator.next();
      if (!item) {
        break;
      }
      this._cache.push(item);
    }
    return this._cache[i];
  }

  all() {
    while (this.next()) {
      continue;
    }
    return this._cache;
  }
}

// Provides the same interface as IterCache for working with plain arrays
class ArrayIter {
  constructor(array) {
    this.array = array;
    this._i = 0;
  }

  next() {
    if (this._i < this.array.length) {
      return this.array[this._i++];
    }
  }

  get(i) {
    return this.array[i];
  }

  all() {
    return this.array;
  }
}

class FilteredList {
  constructor(items, pred) {
    this.pred = pred;
    this.items = new ArrayIter(items);
  }

  next() {
    let item;
    while ((item = this.items.next()) != null) {
      if (this.pred(item)) {
        return item;
      }
    }
  }
}

class LazySortMulti {
  constructor(q, mainScoreFunc, alternateScoreFunc) {
    this.q = q;
    this.mainScoreFunc = mainScoreFunc;
    this.alternateScoreFunc = alternateScoreFunc;
    this._q = [];
  }

  next() {
    if (this._q.length === 0) {
      this._fillQ();
    }
    return this._q.pop();
  }

  _fillQ() {
    let nextItem;
    const theItem = this._item != null ? this._item : this.q.next();
    if (theItem == null) {
      return;
    }
    const theScore = this.mainScoreFunc(theItem);

    const q = [theItem];
    while ((nextItem = this.q.next())) {
      const nextScore = this.mainScoreFunc(nextItem);
      if (nextScore === theScore) {
        q.push(nextItem);
      } else {
        break;
      }
    }

    this._q = util.sortBy(q, this.alternateScoreFunc);
    this._q.reverse();
    return (this._item = nextItem);
  }
}

module.exports = {
  PriorityQueue,
  OrderedPairs,
  OrderedNestedList,
  IterCache,
  ArrayIter,
  FilteredList,
  LazySortMulti,
};
