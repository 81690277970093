import React from 'react';
import classNames from 'classnames';
import ArrowIcon from '@content/icons/arrow_large_right.svg';
import './Arrow.less';

class Arrow extends React.Component {
  render() {
    const { orientation } = this.props;
    return (
      <ArrowIcon
        className={classNames(`Arrow Arrow--${orientation}`, {
          'Arrow--small': this.props.small,
        })}
      />
    );
  }
}

Arrow.defaultProps = {
  orientation: 'right',
};

export default Arrow;
