import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Constraint from '../Constraint';
import './PageNotFound.less';

class PageNotFound extends Component {
  render() {
    let { t } = this.props.filters;
    return (
      <div className="PageNotFound">
        <Constraint wide>
          <div className="PageNotFound__ghost" />
          <div className="PageNotFound__explaination">
            <div className="PageNotFound__title">{t('errServer404Ghost')}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: t('errServer404Text', {
                  linkStart: '<a class="PageNotFound__home" href="/">',
                  linkEnd: '</a>',
                }),
              }}
            />
            {t('errServer404GoBack')}
          </div>
        </Constraint>
      </div>
    );
  }
}

PageNotFound.propTypes = {
  filters: PropTypes.object.isRequired,
};
export default PageNotFound;
