import classNames from 'classnames';

function createBem(b, e, m) {
  let be = b;

  if (typeof e === 'object') [m, e] = [e];
  if (e) be += `__${e}`;

  let bem = be;

  if (m) {
    for (let m_ of classNames(...[].concat(m)).split(' ')) {
      if (m_) bem += ` ${be}--${m_}`;
    }
  }

  return bem;
}

export default function bemGenerator(b) {
  return (e, m) => createBem(b, e, m);
}
