/* eslint-disable react/no-multi-comp  */
import React from 'react';
import loadable from '@loadable/component';
import GoRoute from './GoRoute';
import FlightFrontPageRoute from './FlightFrontPageRoute';
import TransferPageRoute from './TransferPageRoute';
import SearchPageRoute from './SearchPageRoute';
import CityLandingRoute from './CityLandingRoute';

export const NO_COOKIES_ROUTES = [];
const REGEX_ADULTS = 'adults-\\d{1,2}|\\d{1,2}-adults';
const REGEX_CHILDREN = 'children[-\\d{1,2}]*';
const REGEX_DATE = '\\d{4}-\\d{2}-\\d{2}';

const HotelFrontPageRoute = loadable(() => import('./HotelFrontPageRoute'));
const CarFrontPageRoute = loadable(() => import('./CarFrontPageRoute'));
const DesignRoute = loadable(() => import('./DesignRoute'));
const PageNotFoundRoute = loadable(() => import('./PageNotFoundRoute'));
const AboutRoute = loadable(() => import('./AboutRoute'));
const NewsletterRoute = loadable(() => import('./NewsletterRoute'));
const ConnectRoute = loadable(() => import('./ConnectRoute'));

export const routes = [
  {
    path: '/',
    component: FlightFrontPageRoute,
    exact: true,
  },
  {
    path: '/hotels',
    render: (props) => <HotelFrontPageRoute {...props} />,
  },
  {
    path: '/cars',
    render: (props) => <CarFrontPageRoute {...props} />,
  },
  {
    path: '/design',
    render: (props) => <DesignRoute {...props} />,
  },
  {
    /* eslint-disable max-len */
    path: `/flights/:origin/:destination/:departureDate(${REGEX_DATE})/:returnDate(${REGEX_DATE})?/:adults(${REGEX_ADULTS})?/:children(${REGEX_CHILDREN})?`,
    component: SearchPageRoute,
  },
  {
    path: '/transfer/:sessionKey/:fareId/:vendorId/:currency',
    component: TransferPageRoute,
  },
  {
    path: '/go/:first?/:second?/:third?/:fourth?',
    component: GoRoute,
  },
  {
    path: '/flights-to-:destination',
    component: CityLandingRoute,
  },
  {
    path: '/flights-from-:origin/to-:destination',
    component: CityLandingRoute,
  },
  {
    path: '/about/:selected',
    render: (props) => <AboutRoute {...props} />,
  },
  {
    path: '/connect',
    render: (props) => <ConnectRoute {...props} />,
    exact: true,
  },
  {
    path: '/newsletter',
    render: (props) => <NewsletterRoute {...props} />,
  },
  {
    path: '*',
    render: (props) => <PageNotFoundRoute {...props} />,
  },
];

export const redirectRoutes = [
  {
    from: '/flights',
    to: '/',
    exact: true,
  },
  {
    from: '/go/from-:from/to-:to(/for-:stay-days)(/in-:departure)(/on-:weekdays)',
    to: '/go/:from/:to',
  },
  {
    from: '/go/to-:to',
    to: '/go/:to',
  },
  {
    from: '/g',
    to: '/go',
  },
  {
    from: '/design',
    to: '/media/content/Brand_Guidelines_Dohop.pdf',
  },
];
