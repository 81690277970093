import queryString from 'query-string';
import config from '@lib/config';

const { TRANSFER_STEP_URL } = config;

/**
 * Updates user settings object and
 * also updates query parameters if avaliable in url
 * We dont update query params in url if user is on
 * transfer extra step. The reason is we do not want to
 * reload the window by updating the query params.
 * Default behavior in window history object is to reload
 * the window if query params change in url
 * @param {Object} history window history object
 * @param {Object} settings user settings { currency: ..., language: ..., residency: ... }
 */
function updateUserSettings(history, settings) {
  const queryParams = queryString.parse(location.search);
  const verifiedQuery = { ...queryParams };
  const { currency, language, residency } = verifiedQuery;

  if (currency) {
    verifiedQuery.currency = settings.currency;
  }

  if (language) {
    verifiedQuery.language = settings.language;
  }

  if (residency) {
    verifiedQuery.residency = settings.residency;
  }

  const isTransferExtraStep =
    location.hash && location.hash.includes(TRANSFER_STEP_URL) ? true : false;

  // Update query string in url if currency, language or residency exists
  // Don't update if we are transfer extra step page
  if (
    !isTransferExtraStep &&
    (currency ||
      language ||
      residency ||
      settings.currency ||
      settings.language ||
      settings.residency)
  ) {
    const newQueryString = { ...settings, ...verifiedQuery };

    history.push({
      search: `?${queryString.stringify(newQueryString) + location.hash}`,
    });
  }

  return settings;
}

export default updateUserSettings;
