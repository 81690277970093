/**
 * Checks if value is an valid javascript object
 * @param {Any} value - Any js value
 * @returns {Boolean}
 */
export function isObject(value) {
  return typeof value === 'object' && value !== null;
}

/**
 * Checks if object is empty and if obj param is object
 * @param {Object} obj - Any javascript object
 * @returns {Boolean}
 */
export function isObjectEmpty(obj) {
  return isObject(obj) ? !Object.keys(obj).length : true;
}

/**
 * Checks if value is an valid javascript Array
 * @param {Any} value - Any js value
 * @returns {Boolean}
 */
export function isArray(value) {
  return Array.isArray(value);
}

/**
 * Checks if value is a numeric value
 * @param {Any} value - Any js value
 * @returns {Boolean}
 */
export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

/**
 * Checks if value is a string type
 * @param {Any} value Any js type
 * @returns {Boolean}
 */
export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

/**
 * Checks if value is a boolean type
 * @param {Any} value Any js type
 * @returns {Boolean}
 */
export function isBoolean(value) {
  return typeof value === 'boolean';
}
