/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _filter = require('lodash/filter');
const _isArray = require('lodash/isArray');
const _isDate = require('lodash/isDate');
const _isPlainObject = require('lodash/isPlainObject');
const _isFunction = require('lodash/isFunction');
const _each = require('lodash/each');
const config = require('./config');

const log = function (...args) {
  if (config.log) {
    return config.log(...Array.from(args || []));
  } else if (!config.isBrowser) {
    return console.log(...Array.from(args || []));
  }
};

const logJSON = function (...args) {
  if ((typeof JSON !== 'undefined' && JSON !== null ? JSON.stringify : undefined) != null) {
    args = Array.from(args).map((item) => JSON.stringify(item, null, 4));
  }
  return log(...Array.from(args || []));
};

const concat = function (a, ...args) {
  for (let b of Array.from(args)) {
    for (let v of Array.from(b)) {
      a.push(v);
    }
  }
  return a;
};

const popKey = function (obj, k, default_) {
  if (k in obj) {
    const v = obj[k];
    delete obj[k];
    return v;
  } else {
    return default_;
  }
};

const trim = (s) => s.replace(/^\s+|\s+$/g, '');

const sum = function (items, iterator) {
  let total = 0;
  for (let item of Array.from(items)) {
    total += iterator ? iterator(item) : item;
  }
  return total;
};

const imin = function (items, iterator) {
  if (iterator == null) {
    iterator = (x) => x;
  }
  assert(items.length);

  let mini = 0;
  let minv = iterator(items[0]);
  for (let i = 1, end = items.length, asc = 1 <= end; asc ? i < end : i > end; asc ? i++ : i--) {
    const v = iterator(items[i]);
    if (v < minv) {
      mini = i;
      minv = v;
    }
  }
  return mini;
};

// filter function that works on objects as well as arrays
const filter = function (items, predicate, ...args) {
  if (_isArray(items)) {
    return _filter(items, predicate, ...Array.from(args));
  }

  const result = {};
  for (let k of Object.keys(items || {})) {
    const v = items[k];
    if (predicate(v, k)) {
      result[k] = v;
    }
  }
  return result;
};

// stable sort with comparator function
const sort = function (input, comparator) {
  const indexes = __range__(0, input.length, false);
  indexes.sort(function (i_a, i_b) {
    const v = comparator(input[i_a], input[i_b]);
    if (v < 0) {
      return -1;
    }
    if (v > 0) {
      return 1;
    }
    return i_a - i_b;
  });

  return Array.from(indexes).map((i) => input[i]);
};

// stable sort with a score function
const sortBy = function (input, iteratee) {
  let asc, end, j;
  let i;
  if (iteratee == null) {
    iteratee = (x) => x;
  }
  const args = arguments;

  const indexes = [];
  const values = [];
  for (
    j = 0, i = j, end = input.length, asc = 0 <= end;
    asc ? j < end : j > end;
    asc ? j++ : j--, i = j
  ) {
    indexes.push(i);
    values.push(iteratee(input[i]));
  }

  indexes.sort(function (i_a, i_b) {
    let asc1, end1;
    if (values[i_a] < values[i_b]) {
      return -1;
    }
    if (values[i_b] < values[i_a]) {
      return +1;
    }

    for (
      i = 2, end1 = args.length, asc1 = 2 <= end1;
      asc1 ? i < end1 : i > end1;
      asc1 ? i++ : i--
    ) {
      const a = args[i](input[i_a]);
      const b = args[i](input[i_b]);
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return +1;
      }
    }

    return i_a - i_b;
  });

  return (() => {
    const result = [];
    for (i of Array.from(indexes)) {
      result.push(input[i]);
    }
    return result;
  })();
};

// each function that unlike lodash's forEach doesn't break the loop by returning false
const each = (items, iteratee) =>
  _each(items, function (...args) {
    iteratee(...Array.from(args || []));
  });

// like reduce, but the accumulator is constant and callback return value is ignored
const eachWithObject = function (items, object, iterator) {
  each(items, (item, i) => iterator(object, item, i));
  return object;
};

// simplified version of deep cloning
var deepClone = function (obj, depth) {
  let v;
  if (depth != null && --depth < 0) {
    return obj;
  }

  if (_isArray(obj)) {
    return (() => {
      const result = [];
      for (v of Array.from(obj)) {
        result.push(deepClone(v, depth));
      }
      return result;
    })();
  }

  if (_isDate(obj)) {
    return new Date(obj.getTime());
  }

  if (_isPlainObject(obj)) {
    const r = {};
    for (let k of Object.keys(obj || {})) {
      v = obj[k];
      r[k] = deepClone(v, depth);
    }
    return r;
  }

  return obj;
};

const createSet = function (array, v) {
  if (v == null) {
    v = true;
  }
  return eachWithObject(array, {}, (map, item) => (map[item] = v));
};

var assert = function (condition, message) {
  if (!condition) {
    if (_isFunction(message)) {
      message = message();
    }
    throw new Error(`Assertion error: ${message}`);
  }
};

module.exports = {
  log,
  logJSON,
  concat,
  popKey,
  trim,
  sum,
  imin,
  filter,
  sort,
  sortBy,
  each,
  eachWithObject,
  deepClone,
  createSet,
  assert,
};

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
