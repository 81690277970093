import createConstants from 'shared/utils/createConstants';

const constants = [
  'CONFIGURE',
  'INVALID_PARAMS',
  'INVALID_PASSENGER_COUNT',
  'UPDATE_PASSENGER',
  'UPDATE_MULTIPLE_PASSENGERS',
  'UPDATE_PAYMENT',
  'UPDATE_CLIENT_CAPABILITIES',
  'UPDATE_CONTACT',
  'UPDATE_TERMS',
  'UPDATE_OPTIONAL_FIELDS',
  'VALIDATE_FORMS',
  'VALIDATE_FREQUENT_FLYER_NUMBERS_STARTING',
  'VALIDATE_FREQUENT_FLYER_NUMBERS_FINISHED',
  'VALIDATE_FREQUENT_FLYER_NUMBERS_FAILED',
  'ADD_PASSENGER',
  'EDIT_PASSENGER_COUNT',
  'RECEIVE_USER_SETTINGS',
  'RECEIVE_PAGE_PARAMS',
  'RECEIVE_FLAGS',
  'REMOVE_FLAG',
  'FETCH_FARES_STARTING',
  'FETCH_FARES_FAILED',
  'FETCH_FARES_SUCCESS',
  'FETCH_FARES_FOR_FARECLASS_FAILED',
  'ATTEMPT_BOOK',
  'BOOK_STARTING',
  'BOOK_FAILED',
  'BOOK_SUCCESS',
  'BOOK_PENDING',
  'BOOK_BLOCKED',
  'FETCH_RESOURCES_SUCCESS',
  'AUTOSAVE_KEY_',
  'FETCH_AUTOSAVE_SUCCESS',
  'FETCH_SEAT_MAP_STARTING',
  'FETCH_SEAT_MAP_FAILED',
  'FETCH_SEAT_MAP_SUCCESS',
  'FETCH_SEAT_MAPS_STARTING',
  'FETCH_SEAT_MAPS_FAILED',
  'FETCH_SEAT_MAPS_SUCCESS',
  'UPDATE_BKIDS_FOR_SEAT_MAPS',
  'FETCH_RESOURCES_FAILED',
  'SET_PASSENGERS',
  'RECEIVE_POLL',
  'THREEDS_1_REDIRECT',
  'MB_STATUS_START',
  'FILL_FORM_FROM_MB',
  'UPDATE_GET_MY_BOOKING_PARAMS',
  'FETCH_MY_BOOKING_START',
  'FETCH_MY_BOOKING_FAILED',
  'SET_SUMMARY',
  'SET_PCI_PROXY_SECURE_FIELDS',
  'FETCH_NEW_CURRENCIES_STARTING',
  'FETCH_NEW_CURRENCIES_SUCCESS',
  'FETCH_NEW_CURRENCIES_FAILED',
  'EDIT_SELECTED_BUNDLES',
  'SET_ANCILLARIES',
  'CLEAR_PASSENGER_SEATS',
  'FETCH_TRANSACTIONS_STARTING',
  'FETCH_TRANSACTIONS_FAILED',
  'FETCH_TRANSACTIONS_SUCCESS',
  'FETCH_PAYMENT_FEE_STARTING',
  'FETCH_PAYMENT_FEE_FAILED',
  'FETCH_PAYMENT_FEE_SUCCESS',
  'RESET_PAYMENT_FEE',
  'RESET_PAYMENT_FEE_ERROR',
  'UPDATE_ALIGNED_BUNDLES',
  'UPDATE_FARE_BUNDLES',
  'BOOK_ATTEMPTED',
  'SET_LUGGAGE_GROUND_HANDLING',
  'MULTI_MERCHANT',
  'EDIT_SELECTED_FARE_BUNDLES',
  'SET_MAX_NUM_PAX',
  'POST_ORDER_STARTING',
  'POST_ORDER_FAILED',
  'POST_ORDER_SUCCESS',
  'AB_TEST_SHOW_TRUSTPILOT_WIDGET',
  'ADD_ANCILLARY_TRACKING',
  'TRACK_SELECTED_BUNDLE',
];

export const LUGGAGE_GROUND_HANDLING = 'luggage_ground_handling';
export const ALYZIA_SELF_CONNECT = 'Alyzia SelfConnect';
export const PSEUDO_JETSTAR_SEAT_CODE = 'PSSF';

export const LOUNGE_ACCESS = 'lounge_access';

export default createConstants(constants, 'BOOKING/');

export const BUNDLE_TAXES = ['PLS', 'COMBO', 'PSEUDO_BUNDLE_TAX'];
