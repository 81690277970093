import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dcaTime from 'dohop-client-api/src/time';
import propTypes from '../../../../lib/propTypes';
import createFilters from '../../../../lib/createFilters';
import Tooltip from '../../../Tooltip';
import MatchMedia from '../../../MatchMedia';
import formatAirport from '../../../../lib/formatAirport';
import config from '../../../../lib/config';
import CollapseWithAnimation from '../../../CollapseWithAnimation';
import ItineraryDetailedRoute from '../ItineraryDetailedRoute';
import AirplaneDeparting from '@content/svg/airplane-departing.svg';
import AirplaneArriving from '@content/svg/airplane-arriving.svg';
import ArrowDown from '@content/svg/arrow_down.svg';
import './ItineraryRoute.less';

const RIGHT_ARROW = String.fromCharCode(8594);

class ItineraryRoute extends Component {
  state = {
    isDetailsOpen: false,
  };

  onClickHeader = () => {
    this.setState((prevState) => {
      return { isDetailsOpen: !prevState.isDetailsOpen };
    });
  };

  /**
   * Renders the airlines of the route.
   */
  renderAirlines(device = 'mobile') {
    const { route, mobileVersion } = this.props;
    const airlines = route.getAirlines();

    return (
      <div
        className={classNames('ItineraryRoute__airlines', {
          'ItineraryRoute__airlines--alwaysMobile': mobileVersion,
        })}
      >
        {airlines.map((airline, idx) => {
          const airlineLogo = (key) => (
            <div
              className={classNames('ItineraryRoute__airlineLogo', {
                'ItineraryRoute__airlineLogo--alwaysMobile': mobileVersion,
              })}
              style={{
                backgroundImage: `url(${
                  config.M_STATIC
                }/airlines/${airline.getIATACode().toLowerCase()}.png)`,
              }}
              key={key}
            />
          );

          return device === 'mobile' ? (
            <div className="ItineraryRoute__airline" key={idx}>
              {airlineLogo(`logo-${idx}`)}
              {airline.getName()}
            </div>
          ) : (
            airlineLogo(`logo-${idx}`)
          );
        })}
      </div>
    );
  }

  /**
   * Renders the origin or destination with time and small airplane logo.
   * @param {String} obj.time - The departure/arrival time
   * @param {String} obj.airport - The origin/destination
   */
  renderTimeAndAirport({ time, airport, daysApart }) {
    const { t, formatDate } = this.props;

    return (
      <div className="ItineraryRoute__timeAndAirport">
        <div className="ItineraryRoute__time">
          <Tooltip hideOnTouchDevices title={formatDate(time, 'LLLL')}>
            {formatDate(time, 'LT')}
          </Tooltip>
          {daysApart > 0 && (
            <Tooltip
              title={
                daysApart === 1
                  ? t('arrives_day_after')
                  : t('arrives_days_after', { days: daysApart })
              }
              className="ItineraryRoute__daysApart"
            >{`+${daysApart}`}</Tooltip>
          )}
        </div>
        <div className="ItineraryRoute__airport">
          <Tooltip hideOnTouchDevices title={formatAirport(airport)}>
            {airport.getCode()}
          </Tooltip>
        </div>
      </div>
    );
  }

  /**
   * Returns the airport of this stop, unless the stop has two airports,
   * one for the destination of the first flight and another for the origin
   * of the next one, then it returns both airports with arrow in between.
   * @param {Object} stop - Information about the stop.
   */
  getStopOverAirport(stop) {
    return stop.getAirports().map((a, index) => {
      // If the destination airport of the first flight is different than the origin on the next one.
      if (index > 0) {
        return (
          <span key={index}>
            <span className="Itinerary__stopArrow">{RIGHT_ARROW}</span>
            {a.getCode()}
          </span>
        );
      }

      return <span key={index}>{a.getCode()}</span>;
    });
  }

  /**
   * Renders a line that visualize the flight journey.
   * Includes airpline departing, line with information about the stop over airports
   * and then airplane arriving.
   */
  renderJourneyLine() {
    const { formatDuration, route, mobileVersion } = this.props;
    const stops = route.getStopOvers();

    return (
      <div className="ItineraryRoute__journeyLine">
        {!mobileVersion && (
          <AirplaneDeparting className="ItineraryRoute__airplaneIcon ItineraryRoute__airplaneIcon--departing" />
        )}
        {stops.map((stop, idx) => {
          let posX = '50%';
          const halfStopWidth = '12px';
          const stopNumber = idx + 1;

          if (stops.length === 2) {
            posX = 33.3 * stopNumber + '%';
          } else if (stops.length === 3) {
            posX = 25 * stopNumber + '%';
          }

          return (
            <div
              className="ItineraryRoute__stop"
              key={`stop-${idx + 1}`}
              style={{ left: `calc(${posX} - ${halfStopWidth})` }}
            >
              <div className="ItineraryRoute__bullet" />
              <Tooltip
                className="ItineraryRoute__stopOverAirport"
                hideOnTouchDevices
                title={formatAirport(stop.getAirports()) + ` ${formatDuration(stop.getDuration())}`}
              >
                {this.getStopOverAirport(stop)}
              </Tooltip>
            </div>
          );
        })}
        {!mobileVersion && (
          <AirplaneArriving className="ItineraryRoute__airplaneIcon ItineraryRoute__airplaneIcon--arriving" />
        )}
      </div>
    );
  }

  /**
   * Renders useful information about the journey.
   * This includes how long time it takes, how many stops on the way and
   * visual information about the layovers if there are any.
   */
  renderUsefulInfo({ duration }) {
    const { t, formatDuration, route, mobileVersion } = this.props;
    const numStops = route.getNumStops();
    const isDirect = numStops === 0;
    const stops = `${numStops} ${numStops === 1 ? t('stop') : t('stops')}`;

    return (
      <div
        className={classNames('ItineraryRoute__usefulInfo', {
          'ItineraryRoute__usefulInfo--alwaysMobile': mobileVersion,
        })}
      >
        <div className="ItinearyRoute__duration">
          <span className="ItineraryRoute__avoidBreak">{formatDuration(duration)}</span>
          {!isDirect && (
            <span className="ItineraryRoute__avoidBreak ItineraryRoute__numStops">({stops})</span>
          )}
        </div>
        {this.renderJourneyLine()}
        {isDirect && <div className="ItineraryRoute__directMessage">{t('nonstopflight')}</div>}
      </div>
    );
  }

  /**
   * If the route is expandable we call this function which renders a footer
   * with a hint that it is possible to expand the component.
   */
  renderFooter() {
    const { t } = this.props;
    const { isDetailsOpen } = this.state;

    return (
      <div className="ItineraryRoute__footer">
        {t('details')}
        <ArrowDown
          className={classNames('Itinerary__arrow', {
            'Itinerary__arrow--open': isDetailsOpen,
          })}
          src="/content/svg/arrow_down.svg"
        />
      </div>
    );
  }

  renderShortVersion() {
    const { key, route, isExpandable, showPrice, mobileVersion } = this.props;

    return (
      <div
        key={key}
        className={classNames('ItineraryRoute', {
          'ItineraryRoute--outbound': route.isOutbound(),
          'ItineraryRoute--homebound': route.isHomebound(),
          'ItineraryRoute--alwaysMobile': mobileVersion,
        })}
      >
        {mobileVersion ? (
          this.renderAirlines()
        ) : (
          <MatchMedia max="ipad">{this.renderAirlines()}</MatchMedia>
        )}
        <div className="ItineraryRoute__main">
          {!mobileVersion && <MatchMedia min="ipad">{this.renderAirlines('desktop')}</MatchMedia>}
          <div
            className={classNames('ItineraryRoute__information', {
              'ItineraryRoute__information--showPrice': showPrice,
            })}
          >
            {this.renderTimeAndAirport({
              time: route.getDeparture(),
              airport: route.getOrigin(),
            })}
            {this.renderUsefulInfo({ duration: route.getDuration() })}
            {this.renderTimeAndAirport({
              time: route.getArrival(),
              airport: route.getDestination(),
              daysApart: dcaTime.daysApart(route.getDeparture(), route.getArrival()),
            })}
          </div>
        </div>
        {isExpandable && this.renderFooter()}
      </div>
    );
  }

  render() {
    const { itinerary, flightPlan, route, isExpandable, mobileVersion } = this.props;
    const { isDetailsOpen } = this.state;
    const isOneWay = itinerary.getRoutes().length < 2;

    return (
      <Fragment>
        {isExpandable ? (
          <CollapseWithAnimation
            renderHeader={() => this.renderShortVersion()}
            isCollapsed={!isDetailsOpen}
            onClick={this.onClickHeader}
          >
            <ItineraryDetailedRoute
              flightPlan={flightPlan}
              route={route}
              mobileVersion={mobileVersion}
            />
          </CollapseWithAnimation>
        ) : (
          this.renderShortVersion()
        )}
        {route.isOutbound() &&
          !isOneWay &&
          // If mobile version show all times, otherwise only on small screens.
          (mobileVersion ? (
            <div className="ItineraryRoute__separator" />
          ) : (
            <MatchMedia max="ipad" className="ItineraryRoute__separator" />
          ))}
      </Fragment>
    );
  }
}

ItineraryRoute.defaultProps = {
  flightPlan: [],
  isExpandable: false,
  showPrice: false,
  mobileVersion: false,
};

ItineraryRoute.propTypes = {
  itinerary: propTypes.itinerary,
  route: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  formatDuration: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  flightPlan: PropTypes.array,
  isExpandable: PropTypes.bool,
  showPrice: PropTypes.bool,
  // True if we want to use the mobile version for all screen sizes.
  mobileVersion: PropTypes.bool,
};

ItineraryRoute = createFilters(['t', 'formatDuration', 'formatDate'])(ItineraryRoute);

export default ItineraryRoute;
