export const WHITELABEL_APP_GOOGLE_RECAPTCHA_SECRET = '6Lf5wM0UAAAAACSCXCPtnJyT_rwEZ6ueapu21QVw';

/**
 * Gets recaptcha token from Google services
 * @param {Function} callbackSuccess success callback function
 * @param {Function} callbackFailure failure callback function
 * @param {string} [action=search] name of the action, defaults to search
 */
export function getRecaptchaToken(
  callbackSuccess,
  callbackFailure = (e) => {
    throw e;
  },
  action = 'search'
) {
  window.grecaptcha.ready(async () => {
    try {
      const token = await window.grecaptcha.execute(WHITELABEL_APP_GOOGLE_RECAPTCHA_SECRET, {
        action,
      });
      callbackSuccess(token);
    } catch (error) {
      callbackFailure(error);
    }
  });
}

/**
 * return the recaptcha token with a promise
 *
 * @export
 * @param {string} action name of the action
 * @returns {Promise<string>} resolves to the token as a string
 */
export async function getRecaptchaTokenPromise(action) {
  return new Promise((resolve, reject) => {
    getRecaptchaToken(
      (token) => resolve(token),
      (error) => reject(error),
      action
    );
  });
}
