import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Hamburger.less';

const Hamburger = ({ white, className, onClick }) => (
  <div
    className={classNames(
      'Hamburger',
      {
        'Hamburger--white': white,
      },
      className
    )}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <div className="Hamburger__line" />
  </div>
);

Hamburger.defaultProps = {
  white: false,
  className: '',
};

Hamburger.propTypes = {
  white: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;
