import axios from 'axios';
import _once from 'lodash/once';
import _debounce from 'lodash/debounce';
import time from 'dohop-client-api/src/time';
import config from '../lib/config';
import { createImmutableAction } from '../lib/redux/actions';
import processException from '../lib/processException';
import { onDocumentReady } from '../lib/dom';

export const FETCH_SUCCESS = 'EXPERIMENTS_FETCH_SUCCESS';
export const fetchSuccess = createImmutableAction(FETCH_SUCCESS, 'experiments');

export function fetchExperiments(req, res) {
  return (dispatch) => {
    // TODO: enable when web_api is ready
    /* eslint-disable */
    return Promise.resolve();

    return axios
      .get(config.WEB_API + '/experiments', {
        headers: { Cookie: req.headers.cookie || '' },
        timeout: time.second,
      })
      .then((response) => {
        (response.headers['set-cookie'] || []).forEach((s) => res.set('set-cookie', s));
        dispatch(fetchSuccess(response.data.experiments));
      })
      .catch(processException);
  };
}

// temporary hack to run client side experiments until web_api is ready
const chooseVariation = _once(() => window.cxApi.chooseVariation());
const getSearch = _once(() => location.search);
export function isExperimentRunning(variation = 1) {
  return (
    (window.cxApi != null && chooseVariation() === variation) ||
    getSearch()
      .substring(1)
      .split('&')
      .indexOf('_variation=' + variation) > -1
  );
}

function unconditionallySendChosenVariation(variation, experimentId) {
  // wait for cxApi, loaded in a synchronous script tag after bundle.js
  onDocumentReady(() => {
    if (window.cxApi) {
      window.cxApi.setChosenVariation(variation, experimentId);
    }
  });
}

const sharedSendVariation = {};

export function sendChosenVariation(variation, experimentId) {
  // create a debounced version of unconditionallySendChosenVariation - if there are multiple
  // components on the page running the same experiment, this will make sure we only send the chosen
  // variation once when multiple components are mounted simultaniously
  if (!sharedSendVariation[experimentId]) {
    sharedSendVariation[experimentId] = _debounce(unconditionallySendChosenVariation);
  }
  sharedSendVariation[experimentId](variation, experimentId);
}
