import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import createFilters from '../../../lib/createFilters';
import Tooltip from '../../Tooltip';
import SortButtonsLoading from '../SortButtonsLoading';
import './SortButtons.less';

class SortButtons extends Component {
  state = {
    isDropDownOpen: false,
    fare: this.onFindBestFlight([{ by: 'fare' }, { by: 'duration' }]),
    duration: this.onFindBestFlight([{ by: 'duration' }]),
    rank: this.onFindBestFlight([{ by: 'rank' }]),
  };

  componentDidMount() {
    const { search } = this.props;
    this.onSortChange = () => this.forceUpdate();
    search.on('sort', this.onSortChange);
    search.on('change', this.changePrices);
    search.on('filter-change', this.changePrices);
  }

  componentWillUnmount() {
    const { search } = this.props;
    search.removeListener('sort', this.onSortChange);
    search.removeListener('change', this.changePrices);
    search.removeListener('filter-change', this.changePrices);
  }

  changePrices = () => {
    this.setState({
      fare: this.onFindBestFlight([{ by: 'fare' }, { by: 'duration' }]),
      duration: this.onFindBestFlight([{ by: 'duration' }]),
      rank: this.onFindBestFlight([{ by: 'rank' }]),
    });
  };

  onFindBestFlight(sort) {
    const { formatDuration, search } = this.props;
    const best = search.createQueue(sort).get(0);
    if (best) {
      return {
        price: best.getFares().getBestFare().getAverage(),
        duration: search.getMeta().oneway
          ? formatDuration(best.getDuration())
          : formatDuration(best.getDuration() / 2),
      };
    }
  }

  onClickSort(sort) {
    const { onChangeSort } = this.props;
    onChangeSort(sort);
  }

  isCurrentSort(sort) {
    const { search } = this.props;
    return isEqual(search.getSortOptions(), sort);
  }

  /**
   * Renders sorting button by sort type
   * @param {String} text - Sort text
   * @param {Array<Object>} sort - Sort array
   * @param {Boolean} isLast - Indicator if last button
   * @returns {JSX}
   */
  renderButton(text, sort, isLast) {
    const {
      formatCurrency,
      filters: { t },
    } = this.props;
    const best = this.state[sort[0].by];
    const price = best && best.price ? formatCurrency(best.price) : null;
    const button = (
      <div
        className={classNames('SortButtons__button', {
          'SortButtons__button--disabled': !price,
          'SortButtons__button--animation': price,
          'SortButtons__button--active': this.isCurrentSort(sort),
          'SortButtons__button--last': isLast,
        })}
        role="button"
        tabIndex={0}
        onClick={this.onClickSort.bind(this, sort)}
      >
        {price ? (
          <Fragment>
            <div className="SortButtons__containerLeft">
              <p className="SortButtons__title">{text}</p>
              <h4 className="SortButtons__price">{price}</h4>
            </div>
            <div className="SortButtons__containerRight">
              <p className="SortButtons__duration">{best.duration}</p>
            </div>
          </Fragment>
        ) : (
          <SortButtonsLoading />
        )}
      </div>
    );

    // Only show tooltip description for the best sorting option.
    if (price && sort[0].by === 'rank') {
      return <Tooltip title={t('sort_best_desc')}>{button}</Tooltip>;
    }

    return button;
  }

  render() {
    const {
      filters: { t },
    } = this.props;

    return (
      <div className="SortButtons">
        {this.renderButton(t('best'), [{ by: 'rank' }])}
        {this.renderButton(t('cheapest'), [{ by: 'fare' }, { by: 'duration' }])}
        {this.renderButton(t('quickest'), [{ by: 'duration' }], true)}
      </div>
    );
  }
}

export default createFilters(['formatCurrency', 'formatDuration'])(SortButtons);
