import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { getScrollTop } from '../lib/dom';
import './Tooltip.less';

class Tooltip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { allowTitleAttr: true };
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  _mkslim(width = this.tooltip.offsetWidth) {
    const { offsetHeight, style } = this.tooltip;
    const h = offsetHeight;
    style.width = width - 5 + 'px';

    if (width < 20) {
      style.width = '';
    } else if (offsetHeight === h) {
      this._mkslim(width - 5);
    } else {
      style.width = width + 1 + 'px';
    }
  }

  onTouchStart() {
    // onTouchStart runs before onMouseEnter
    this.touch = true;
  }

  openTooltip() {
    const { title, hideOnTouchDevices } = this.props;

    if (hideOnTouchDevices && this.touch) {
      return false;
    }

    if (!title) return false;

    this.tooltip = document.createElement('div');
    document.body.appendChild(this.tooltip);
    this.tooltip.classList.add('Tooltip');
    this.tooltip.textContent = title;

    this.arrow = document.createElement('div');
    document.body.appendChild(this.arrow);
    this.arrow.classList.add('Tooltip__arrow');

    this._mkslim();

    const el = this.el;
    const rect = el.getBoundingClientRect();
    const top = rect.top + getScrollTop() - this.tooltip.offsetHeight;
    const centerX = rect.left + el.offsetWidth / 2;
    let left = Math.max(0, centerX - this.tooltip.offsetWidth / 2);
    // this 2 pixel offset is a workaround a bug in chrome where text starts to wrap when on the
    // edge of the window even though it shouldn't have to
    left = Math.min(left, document.body.offsetWidth - this.tooltip.offsetWidth - 2);

    this.tooltip.style.left = left + 'px';
    this.tooltip.style.top = top + 'px';

    this.arrow.style.left = centerX + 'px';
    this.arrow.style.top = rect.top + getScrollTop() + 'px';

    // allow elements to be rendered before adding --loaded so we can animate it using css
    setTimeout(() => {
      if (this.tooltip) this.tooltip.classList.add('Tooltip--loaded');
      if (this.arrow) this.arrow.classList.add('Tooltip__arrow--loaded');
    });
  }

  onMouseEnter() {
    // don't do anything on touch devices
    if (this.touch) return;
    // now we know this isn't a touch device, don't render title attribute for non-touch devices
    this.setState({ allowTitleAttr: false });
    this.openTooltip();
  }

  onMouseDown() {
    // If tooltip is already showing
    if (this.tooltip) return;
    if (this.props.title === null) return;
    this.setState({ allowTitleAttr: false });
    this.openTooltip();
  }

  onMouseLeave() {
    clearTimeout(this.timeoutID);
    if (this.tooltip) document.body.removeChild(this.tooltip);
    if (this.arrow) document.body.removeChild(this.arrow);
    delete this.tooltip;
    delete this.arrow;
  }

  componentWillUnmount() {
    this.onMouseLeave();
  }

  render() {
    const { children, comp } = this.props;
    const { allowTitleAttr } = this.state;
    const props = omit(this.props, 'comp');

    if (!allowTitleAttr) {
      delete props.title;
    }
    // If not removed React will produce error.
    delete props.hideOnTouchDevices;

    props.onTouchStart = this.onTouchStart;
    props.onMouseEnter = this.onMouseEnter;
    props.onMouseDown = this.onMouseDown;
    props.onMouseLeave = this.onMouseLeave;
    // Extend props with ref property
    props.ref = c => (this.el = c);

    return React.createElement.apply(React, [comp || 'span', props].concat(children));
  }
}

Tooltip.propTypes = {
  hideOnTouchDevices: PropTypes.bool,
};

Tooltip.defaultProps = {
  hideOnTouchDevices: false,
};

export default Tooltip;
