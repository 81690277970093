import { isSupportedLanguage } from 'shared/data/supportedLanguages';

const twelveHourResidencies = ['US', 'AU', 'CA'];

/**
 * Dayjs does not recognize some of our internal language codes
 * mainly the chinese one. To make sure dayjs translates dates and more
 * we need to map our internal language codes
 * to language codes dayjs recongnises
 * Accepts language code and residency code
 * Returns a language code
 */
export function pickLocale(language, residency) {
  switch (language) {
    case 'no':
      return 'nb';
    case 'zh':
      return 'zh-cn';
    case 'zht':
    case 'yue':
      return 'zh-tw';
    case 'en':
      if (!twelveHourResidencies.includes(residency)) {
        return 'en-gb';
      }
      return 'en';
    default:
      return language;
  }
}

/**
 * This function accepts the language code which we get from API/MBS and maps it to a language
 * code that the client can understand.
 * In most cases this should be the same code with the exception
 * of yue, which is the code used by the backend for trad chinese while the client uses zht
 * This function mirrors the getLanguageFromClientForAPI
 */
export function getLanguageFromAPIForClient(apiLanguageCode, partner) {
  if (!isSupportedLanguage(apiLanguageCode, partner)) {
    return 'en';
  }

  switch (apiLanguageCode) {
    case 'yue':
      return 'zht';
    default:
      return apiLanguageCode;
  }
}

/**
 * This function accepts the language code from the client and maps it to a language
 * code that MBS/API can understand. In most cases this should be the same code with the exception
 * of zht, which is the code used by the backend for trad chinese while the backend uses yue
 * This function mirrors the getLanguageFromAPIForClient
 */
export function getLanguageFromClientForAPI(clientLanguageCode) {
  switch (clientLanguageCode) {
    case 'zht':
      return 'yue';
    default:
      return clientLanguageCode;
  }
}
