import React from 'react';
import { ItineraryFinder } from 'dohop-client-api/src/helpers';
import { withRouter } from 'react-router-dom';
import Itinerary from '../Itinerary';
import Button from '../../../Button';
import './SingleItinerary.less';

class SingleItinerary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isSearching: true };

    this.onChange = () => {
      // TODO: when we add a progressbar this should respect that and not use isPolling
      this.setState({ isSearching: !props.search.isReady() || props.search.isPolling() });
    };
  }

  componentDidMount() {
    const { search, itineraryKey } = this.props;
    this.finder = new ItineraryFinder(search, itineraryKey);
    this.finder.watch((itinerary) => {
      this.setState({ itinerary });
    });
    search.on(['change', 'end'], this.onChange);
  }

  componentWillUnmount() {
    const { search } = this.props;
    this.finder.close();
    search.removeListener(['change', 'end'], this.onChange);
  }

  onClose() {
    const { history, location } = this.props;
    history.push(location.pathname);
  }

  renderContent() {
    const { filters, search, hotels, city } = this.props;
    const { t } = filters;
    const { itinerary, isSearching } = this.state;

    if (itinerary) {
      return (
        <Itinerary
          city={city}
          search={search}
          hotels={hotels}
          itinerary={itinerary}
          initialTab="details"
          showPrice
        />
      );
    }

    if (isSearching) {
      return (
        <div className="SingleItinerary__loading">
          <div className="SingleItinerary__spinner" />
          {t('searching_for_itinerary')}
        </div>
      );
    }

    return (
      <div className="SingleItinerary__notFound">
        <div className="SingleItinerary__title">{t('itinerary_not_found')}</div>
        <Button onClick={this.onClose.bind(this)}>{t('view_all_results')}</Button>
      </div>
    );
  }

  render() {
    let { t } = this.props.filters;

    return (
      <div className="SingleItinerary">
        <div className="SingleItinerary__showOther">
          <a className="SingleItinerary__showOtherLink" onClick={this.onClose.bind(this)}>
            {t('showotherresults')}
          </a>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default withRouter(SingleItinerary);
