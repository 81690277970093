import React from 'react';
import { Provider } from 'react-redux';
import { hydrate } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';
import url from 'url';
import { loadableReady } from '@loadable/component';
import clientAPIConfig from 'dohop-client-api/src/config';
import { createBrowserHistory } from 'history';
import bugsnagReact from '@bugsnag/plugin-react';
import bugsnagClient from '@lib/bugsnag';
import immutableSerializer from '@lib/immutableSerializer';
import config from '@lib/config';
import * as fetchDataFunctions from '@lib/fetchData';
import { trackVirtualPageView, trackUserLanguage } from '@lib/tracking';
import translate from '@lib/translate';
import { dehydrate } from '@actions/requestActions';
import { isExperimentRunning } from '@actions/experimentActions';
import configureStore from '@store/configureStore';
import App from '@components/App';
import ScrollToTop from '@components/scrollToTop';
import { NO_COOKIES_ROUTES } from '@routes';
import globals from './globals';

// Add the React plugin to Bugsnag client, and pass in the React instance
bugsnagClient.use(bugsnagReact, React);
// Wrap our component tree in it
const ErrorBoundary = bugsnagClient.getPlugin('react');

window.__initMomentLocale.call({ moment });

const { SEARCH_API } = config;
const preloadedState = immutableSerializer.fromJS(window.__DohopState__);
const store = configureStore(preloadedState);
store.dispatch(dehydrate());
const user = store.getState().get('user');

// Add user information to bugsnagClient object
if (user) {
  bugsnagClient.user = user.toObject();
}

// apply dohop-client-api config
clientAPIConfig.set({
  apiDomain: url.parse(SEARCH_API).host,
  crossValidateFaresort: process.env.NODE_ENV !== 'production',
});

const history = createBrowserHistory();
trackUserLanguage(user);
let sendPageView = false;
history.listen((location) => {
  // this gets called immediately, before listen() returns, but we don't want to track that event
  // because google tag manager automatically tracks initial page load
  if (!sendPageView) return;

  if (location.state && location.state.pageview === false) return;
  setTimeout(() => trackVirtualPageView());
});
sendPageView = true;

// Does the user's browser support the HTML5 history API?
// If the user's browser doesn't support the HTML5 history API then we
// will force full page refreshes on each page change.
const supportsHistory = 'pushState' in window.history;
const translations = globals('translations', user.get('language'));
const t = (transName, params) => translate({ translations }, transName, params);

/**
 * Renders the given React Application component.
 */
function renderApp(TheApp) {
  // Firstly, define our full application component, wrapping the given
  // component app with a browser based version of react router.
  const app = (
    <ErrorBoundary>
      <Provider store={store}>
        <Router forceRefresh={!supportsHistory}>
          <ScrollToTop>
            <TheApp t={t} noCookieRoutes={NO_COOKIES_ROUTES} />
          </ScrollToTop>
        </Router>
      </Provider>
    </ErrorBoundary>
  );

  loadableReady(() => {
    hydrate(app, document.getElementById('root'));
    // fetchData component must be told that client side rendering is done
    fetchDataFunctions.hasRendered();
  });
}

// Execute the first render of our app.
renderApp(App);

// append classname to body for easier ab-testing experiments
if (isExperimentRunning()) {
  document.body.classList.add('experiment');
}

if (module.hot) {
  module.hot.accept();
}
