import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MobileFilters from '../MobileFilters';
import Itinerary from '../itinerary/Itinerary';
import Button from '../../Button';
import SingleItinerary from '../itinerary/SingleItinerary';
import SortButtons from '../SortButtons';
import CheaperPriceIndicator from '../CheaperPriceIndicator';
import MatchMedia from '../../MatchMedia';
import ExpiredPopup from '../ExpiredPopup';
import AllResultsFiltered from '../AllResultsFiltered';
import SearchError from '../../errors/SearchError';
import ItineraryLoadingCard from '../itinerary/itineraryLoading/ItineraryLoadingCard';
import './Results.less';

const NUM_RESULTS = 10;

function createItineraryState(search, maxResults) {
  return {
    maxResults,
    itineraries: search.getItineraries().getRange(0, maxResults),
    hasMore: Boolean(search.getItineraries().get(maxResults)),
    isEmpty: search.isEmpty(),
    allFiltered: search.hasResults() && !search.getItineraries().get(0),
  };
}

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = Object.assign(createItineraryState(props.search, NUM_RESULTS), {
      maxRecommendedResults: 1,
      searchExpired: false,
    });

    this.onChange = this.onChange.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidMount() {
    const { search } = this.props;
    search.on(['sort', 'error'], this.onChange);
    search.on('filter-change', this.onFilterChange);
    window.addEventListener('scroll', this.onScroll);
    this.addHistoryToNewTab();
    setTimeout(() => {
      this.setState({ searchExpired: true });
      // Search session expires after 60 minutes
    }, 1000 * 60 * 60);
  }

  componentWillUnmount() {
    const { search } = this.props;
    search.removeListener(['sort', 'error'], this.onChange);
    search.removeListener('filter-change', this.onFilterChange);
    window.removeEventListener('scroll', this.onScroll);
    clearInterval(this.intervalID);
  }

  addHistoryToNewTab() {
    if (history.length === 1) {
      try {
        const state = history.state ? history.state : {};
        const url = location.href;
        history.replaceState(state, '', '/');
        history.pushState(state, '', url);
      } catch (p) {
        return;
      }
    }
  }

  /**
   * Updates search based on sort
   * @param {Array<Object>} sort - Array of sort objects
   */
  onChangeSort = (sort) => {
    const { search } = this.props;
    this.setState({ maxResults: NUM_RESULTS }, () => {
      // we must wait until state change is applied, otherwise sort will trigger onChange
      // which might use the previous maxResults and override the reset to NUM_RESULTS
      search.sort(sort);
    });
  };

  onChange() {
    const { search } = this.props;
    const { maxResults } = this.state;
    this.setState(createItineraryState(search, maxResults));
  }

  onFilterChange() {
    const { search } = this.props;
    this.setState(createItineraryState(search, NUM_RESULTS));
  }

  onScroll() {
    const { maxResults } = this.state;
    if (
      this.loadMore &&
      maxResults <= 30 &&
      this.loadMore.getBoundingClientRect().top < window.innerHeight
    ) {
      this.onLoadMore();
    }
  }

  onLoadMore() {
    const { search } = this.props;
    const { maxResults } = this.state;
    this.setState(createItineraryState(search, maxResults + NUM_RESULTS));
  }

  getIsLoading() {
    const { search } = this.props;
    return !search.isDone() && !search.hasResults() && !search.error;
  }

  renderRecommended() {
    const { recommendedItineraries, search, searchData, filters } = this.props;
    const { maxRecommendedResults } = this.state;

    if (!recommendedItineraries || !recommendedItineraries.size) {
      return null;
    }

    return (
      <div>
        {recommendedItineraries.slice(0, maxRecommendedResults).map((itinerary, i) => (
          <Itinerary
            key={itinerary.getKey()}
            city={searchData.getIn(['destination', 'name'])}
            search={search}
            itinerary={itinerary}
            recommended={i === 0}
            showPrice
          />
        ))}
        {maxRecommendedResults < recommendedItineraries.size && (
          <div
            className="Results__moreRecommended"
            onClick={() => this.setState({ maxRecommendedResults: maxRecommendedResults + 5 })}
          >
            {filters.t('show_more_book_on_dohop')}
          </div>
        )}
      </div>
    );
  }

  renderItineraries() {
    const { search, filters, searchData, transferStepUrl, recommendedItineraries } = this.props;
    const { itineraries } = this.state;
    const { t } = filters;
    const topRecommended = recommendedItineraries && recommendedItineraries.first();

    if (search.error) {
      return <SearchError error={search.error} t={t} title={filters.t('genErrorTitle')} />;
    }

    if (this.getIsLoading()) {
      return <ItineraryLoadingCard size={10} />;
    }

    if (search.isEmpty()) {
      return <div className="Results__noResults">{t('noresultsfound')}</div>;
    }

    if (itineraries.length === 0) {
      // No results based on filters
      return <AllResultsFiltered t={t} onReset={() => search.filters.clear()} />;
    }

    let processedItineraries = itineraries
      // We don't want first in both to be the same. Unless the itinerary has more than one vendor
      .filter(
        (itinerary, i) =>
          i !== 0 ||
          (itinerary.getFares().getSingleTicket() &&
            itinerary.getFares().getSingleTicket().getVendorFares().length > 1) ||
          !topRecommended ||
          topRecommended.getKey() !== itinerary.getKey()
      )
      .map((itinerary) => (
        <Itinerary
          key={itinerary.getKey()}
          city={searchData.getIn(['destination', 'name'])}
          search={search}
          itinerary={itinerary}
          transferStepUrl={transferStepUrl}
          showPrice
        />
      ));

    return processedItineraries;
  }

  render() {
    const {
      search,
      location,
      filters,
      history,
      itineraryKey,
      flightSearch,
      searchData,
      reloadSearch,
    } = this.props;

    const hoveringSlider = flightSearch.get('hoveringSlider');
    const { t } = filters;
    const { searchExpired, hasMore, itineraries } = this.state;
    const shouldShowSortButtons =
      this.getIsLoading() || (!search.error && !search.isEmpty() && itineraries.length > 0);

    return (
      <div>
        {searchExpired && <ExpiredPopup t={t} reloadSearch={() => reloadSearch()} />}
        <div
          className={classNames('Results', {
            [`Results--${hoveringSlider}Hover`]: hoveringSlider,
          })}
        >
          <div className="Results__content">
            <div className="Results__head">
              {shouldShowSortButtons && (
                <MatchMedia className="Results__sortButtons" min="desktop">
                  <SortButtons search={search} filters={filters} onChangeSort={this.onChangeSort} />
                </MatchMedia>
              )}
              <MobileFilters
                t={t}
                search={search}
                location={location}
                onChangeSort={this.onChangeSort}
                filters={filters}
                showButtonText
              />
            </div>
            {itineraryKey ? (
              <SingleItinerary
                search={search}
                filters={filters}
                history={history}
                itineraryKey={itineraryKey}
                city={searchData.getIn(['destination', 'name'])}
              />
            ) : (
              <div
                className={classNames('Results__itineraries', {
                  'Results__itineraries--animation': !this.getIsLoading(),
                })}
              >
                {this.renderRecommended()}
                {this.renderItineraries()}
                {hasMore && (
                  <div ref={(c) => (this.loadMore = c)}>
                    <Button loadMore onClick={this.onLoadMore}>
                      {t('showotherresults')}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          <CheaperPriceIndicator {...this.props} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { flightSearchForm: state.get('flightSearchForm') };
}

export default connect(mapStateToProps)(Results);
