/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _isEmpty = require('lodash/isEmpty');
const _isEqual = require('lodash/isEqual');
const _clone = require('lodash/clone');
const _isDate = require('lodash/isDate');
const _every = require('lodash/every');
const _some = require('lodash/some');
const _fromPairs = require('lodash/fromPairs');
const _map = require('lodash/map');
const _isObject = require('lodash/isObject');
const _union = require('lodash/union');
const _keys = require('lodash/keys');
const { EventEmitter } = require('events');
const { Fare, MultiFare } = require('./flight');
const util = require('./util');

// @nodoc
const nullifyOutOfBounds = function (key, time, limit) {
  if (
    limit == null ||
    time == null ||
    ((key !== 'min' || !(time <= limit)) && (key !== 'max' || !(time >= limit)))
  ) {
    return time;
  }
};

// Base Filter class
class Filter {
  constructor(meta, _name) {
    this.meta = meta;
    this._name = _name;
    util.assert(this.meta);
    this.clear();
  }

  // @return [String] Name of this filter, same as used to access the filter via {FilterManager#getFilter}
  getName() {
    return this._name;
  }

  // Resets filter so that it will have no effect on any searchresults
  clear() {
    return (this._state = {});
  }

  set(key, value) {
    if (value) {
      return (this._state[key] = value);
    } else {
      return delete this._state[key];
    }
  }

  // @nodoc
  getState() {
    return this._state;
  }

  // @nodoc
  setState(_state) {
    this._state = _state;
  }

  // @return [Boolean] If this filter is active or not
  isActive() {
    return !_isEmpty(this._state);
  }

  // @nodoc
  clone(meta) {
    if (meta == null) {
      meta = this.meta.clone();
    }
    const clone = new this.constructor(meta, this._name);
    clone.setState(_clone(this.getState()));
    return clone;
  }
}

// Base filter for filters that keep no state except for if they are active or not
class BooleanFilter extends Filter {
  clear() {
    return (this._state = false);
  }

  set(value) {
    return (this._state = !value);
  }

  isActive() {
    return this._state;
  }
}

// Base filter for filters that have multiple boolean choices
class CheckListFilter extends Filter {
  set(k, v) {
    if (v === false) {
      return (this._state[k] = v);
    } else {
      return delete this._state[k];
    }
  }

  allows(code) {
    return this._state[code] !== false;
  }
}

// Base class for filters that filter by min/max time
class TimeFilter extends Filter {
  // @param [String] key 'min' or 'max'
  // @param [Integer] time min/max time allowed
  set(key, time) {
    util.assert(['min', 'max'].includes(key), `Invalid state for time filter: ${key}`);
    return super.set(
      key,
      nullifyOutOfBounds(
        key,
        time,
        typeof this.getBounds === 'function' ? this.getBounds()[key] : undefined
      )
    );
  }

  _isValidTime(time) {
    return (
      (this._state.min == null || this._state.min <= time) &&
      (this._state.max == null || this._state.max >= time)
    );
  }

  // @return [Integer] minimum time this filter allows
  getMin() {
    return this._state.min;
  }

  // @return [Integer] maximum time this filter allows
  getMax() {
    return this._state.max;
  }

  // @return [Object] min/max allowed filter state
  getBounds() {
    throw new Error('Not Implemented');
  }
}

// Base class for filters that filter by min/max date
class DateFilter extends Filter {
  // @param [String] key 'min' or 'max'
  // @param [Integer|Date] time min/max date allowed
  set(key, time) {
    util.assert(['min', 'max'].includes(key), `Invalid state for date filter: ${key}`);
    if (_isDate(time)) {
      time = time.getTime();
    }
    return super.set(
      key,
      nullifyOutOfBounds(
        key,
        time,
        __guard__(typeof this.getBounds === 'function' ? this.getBounds()[key] : undefined, (x) =>
          x.getTime()
        )
      )
    );
  }

  _isValidDate(date) {
    return (
      (this._state.min == null || this._state.min <= date.getTime()) &&
      (this._state.max == null || this._state.max >= date.getTime())
    );
  }

  // @return [Date] minimum date this filter allows
  getMin() {
    if (this._state.min) {
      return new Date(this._state.min);
    }
  }

  // @return [Date] maximum date this filter allows
  getMax() {
    if (this._state.max) {
      return new Date(this._state.max);
    }
  }

  // @return [Object] min/max allowed filter state
  getBounds() {
    throw new Error('Not Implemented');
  }
}

// Filter to filter by outbound route departure date
// @example
//   search.getFilters().getFilter('outboundDeparture').set('min', time.UTCDate(2015, 0, 1, 12, 35))
class OutboundDepartureFilter extends DateFilter {
  // @return [Boolean] if the given fare is valid by this filter
  isValidRoute(route) {
    return !route.isOutbound() || this._isValidDate(route.getDeparture());
  }

  getBounds() {
    return this.meta.dates.outbound.departure;
  }
}

// Filter to filter by outbound route arrival date
// @example
//   search.getFilters().getFilter('outboundArrival').set('min', time.UTCDate(2015, 0, 1, 16, 55))
class OutboundArrivalFilter extends DateFilter {
  isValidRoute(route) {
    return !route.isOutbound() || this._isValidDate(route.getArrival());
  }

  getBounds() {
    return this.meta.dates.outbound.arrival;
  }
}

// Filter to filter by homebound route departure date
// @example
//   search.getFilters().getFilter('homeboundArrival').set('min', time.UTCDate(2015, 0, 14, 12, 35))
class HomeboundDepartureFilter extends DateFilter {
  isValidRoute(route) {
    return !route.isHomebound() || this._isValidDate(route.getDeparture());
  }

  getBounds() {
    return this.meta.dates.homebound.departure;
  }
}

// Filter to filter by homebound route arrival date
// @example
//   search.getFilters().getFilter('homeboundArrival').set('min', time.UTCDate(2015, 0, 14, 16, 55))
class HomeboundArrivalFilter extends DateFilter {
  isValidRoute(route) {
    return !route.isHomebound() || this._isValidDate(route.getArrival());
  }

  getBounds() {
    return this.meta.dates.homebound.arrival;
  }
}

// Filter to put constraints on connection time between connecting flights
// @example
//   search.getFilters().getFilter('connectionTime').set('min', time.hour)
class ConnectionTimeFilter extends TimeFilter {
  isValidRoute(route) {
    return _every(route.getStopOvers(), (stop) => {
      return this._isValidTime(stop.getDuration());
    });
  }

  getBounds() {
    return this.meta.connectionTime;
  }
}

// Filter to put constraints on outbound route duration
// @example
//   search.getFilters().getFilter('outboundDuration').set('max', 2 * time.hour)
class OutboundDurationFilter extends TimeFilter {
  isValidRoute(route) {
    return !route.isOutbound() || this._isValidTime(route.getDuration());
  }

  getBounds() {
    return this.meta.getOutboundDuration();
  }
}

// Filter to put constraint on homebound route duration
class HomeboundDurationFilter extends TimeFilter {
  isValidRoute(route) {
    return !route.isHomebound() || this._isValidTime(route.getDuration());
  }

  getBounds() {
    return this.meta.getHomeboundDuration();
  }
}

// Filter to put constraint on route duration, outbound and homebound
class DurationFilter extends TimeFilter {
  isValidRoute(route) {
    return this._isValidTime(route.getDuration());
  }

  getBounds() {
    return this.meta.getDuration();
  }
}

// Filter to filter out itineraries containing certain airports
// @example
//   search.getFilters().getFilter('airports').set('LHR', false)
class AirportFilter extends CheckListFilter {
  isValidRoute(route) {
    return _every(route.getFlights(), (f) => {
      return this.allows(f.getOrigin().getCode()) && this.allows(f.getDestination().getCode());
    });
  }
}

// Filter to filter out itineraries containing certain aircrafts
class AircraftFilter extends CheckListFilter {
  isValidRoute(route) {
    return _every(route.getFlights(), (f) => {
      return this.allows(f.getAircraft().getCode());
    });
  }
}

// Filter to filter out itineraries containing certain airlines
// @example
//   search.getFilters().getFilter('airlines').set('BAW', false)
class AirlineFilter extends CheckListFilter {
  isValidRoute(route) {
    return _every(route.getFlights(), (f) => {
      return this.allows(f.getAirline().getCode());
    });
  }
}

class VendorFilter extends Filter {
  set(k, v) {
    // vendor filter works by vendor name because there can be multiple vendors with the same name
    // which we want to treat as one
    if (this.meta.vendors[k]) {
      const name = this.meta.vendors[k].getName();
      if (v === false) {
        return (this._state[name] = v);
      } else {
        return delete this._state[name];
      }
    }
  }

  allows(code) {
    return this.meta.vendors[code] && this._state[this.meta.vendors[code].getName()] !== false;
  }

  isValidFare(fare) {
    return _every(fare.getFares(), (f) => {
      return _some(f.getVendorFares(), (vf) => {
        return this.isValidVendorFare(vf);
      });
    });
  }

  isValidVendorFare(vendorFare) {
    return this.allows(vendorFare.getVendor().getKey());
  }
}

// Filter to filter out itineraries with certain number of stops
// @note 3 stops and greater are treated as 2 stops, so disallowing 2 stops will also disallow >= 3 stops
// @example
//   search.getFilters.getFilter('stops').set(2, false)
//   search.getFilters.getFilter('stops').allows(2) // false
class StopsFilter extends CheckListFilter {
  isValidRoute(route) {
    return this.allows(route.getNumStops());
  }

  allows(numStops) {
    return this._state[Math.min(2, numStops)] !== false;
  }
}

// Filter to filter out routes containing ground transit.
// @see Route#isGroundTransit
// @example
//   search.getFilters().getFilter('groundTransit').set(false)
class GroundTransitFilter extends BooleanFilter {
  isValidRoute(route) {
    return !route.isGroundTransit();
  }
}

// Filter to filter out itineraries containing redeye flights
// @see Flight#isRedEye
// @example
//   search.getFilters().getFilter('redEye').set(false)
class RedEyeFilter extends BooleanFilter {
  isValidRoute(route) {
    return !route.isRedEye();
  }
}

// Filter to filter out itineraries containing over night routes
// @see Route#isOverNight
// @example
//   search.getFilters().getFilter('overNight').set(false)
class OverNightFilter extends BooleanFilter {
  isValidRoute(route) {
    return !route.isOverNight();
  }
}

// Filter to filter out itineraries containing Self-Connect
// @see Itinerary#isSelfConnect
// @example
//   search.getFilters().getFilter('selfConnect').set(false)
class SelfConnectFilter extends BooleanFilter {
  isValidFare(fare) {
    return !fare.isSelfConnect(this.meta.from, this.meta.to);
  }

  isValidRoute(route, context, filters) {
    // A route with a single leg can never be self-connect
    if (route.getFlights().length === 1) {
      return true;
    }

    // This filter is special. We can not just look at a route and tell if it's valid or not.
    // It depends on the fares if it's self-connect or not.
    // The solution: There are two kinds of sorts. One sorting routine is sorting route pairs that the
    // faresort mapping generates. In that case we don't need to validate the route, only the fares,
    // which is done in isValidFare above
    if ((context != null ? context.sorting : undefined) !== 'one-way') {
      return true;
    }

    // The other sort joins outbound and homebound routes. When doing so, a route is valid
    // if there is a single ticket fare to cover the route. That sorting routine passes
    // a fares array context we can use when filtering routes.
    return _some(context.fares, (fare) => filters.isValidFare(fare));
  }
}

// This is a special kind of filter. Lets call it a virtual filter. It does not do any filtering,
// but lets the sorting routines query for options that they deal with themselves.
// If this filter is active, sorting only returns results with complete fares
class NoFareFilter extends BooleanFilter {}

const DEFAULT_FILTERS = {
  outboundDeparture: OutboundDepartureFilter,
  outboundArrival: OutboundArrivalFilter,
  homeboundDeparture: HomeboundDepartureFilter,
  homeboundArrival: HomeboundArrivalFilter,
  airports: AirportFilter,
  aircrafts: AircraftFilter,
  airlines: AirlineFilter,
  vendors: VendorFilter,
  stops: StopsFilter,
  groundTransit: GroundTransitFilter,
  redEye: RedEyeFilter,
  overNight: OverNightFilter,
  connectionTime: ConnectionTimeFilter,
  outboundDuration: OutboundDurationFilter,
  homeboundDuration: HomeboundDurationFilter,
  duration: DurationFilter,
  selfConnect: SelfConnectFilter,
  noFare: NoFareFilter,
};

// Add a custom filter class that will be initialized with all searches made after that point
// @param [String] name Name of the filter to be access by when calling search.getFilters().getFilter()
// @param [Class] filter A subclass of {Filter}
const addDefaultFilter = (name, filter) => (DEFAULT_FILTERS[name] = filter);

/**
 * FilterManager takes care of all the complexity of managing the different filters
 */
class FilterManager {
  constructor(param) {
    if (param == null) {
      param = {};
    }
    const { meta, events, filters } = param;
    this._meta = meta;
    this._events = events != null ? events : new EventEmitter();
    this._filters =
      filters != null
        ? filters
        : _fromPairs(
            (() => {
              const result = [];
              for (let k in DEFAULT_FILTERS) {
                const cls = DEFAULT_FILTERS[k];
                result.push([k, new cls(this._meta, k)]);
              }
              return result;
            })()
          );
    this._clearActiveFilters();
  }

  // @nodoc
  clone() {
    const meta = this._meta.clone();
    const filters = _fromPairs(_map(this._filters, (f, k) => [k, f.clone(meta)]));
    // we don't pass the events object so that the search won't be alerted about changes in the clone
    const clone = new FilterManager({ meta, filters });
    clone.setState(this.getState());
    return clone;
  }

  // @return [EventEmitter] event object emitted to when filters change
  getEvents() {
    return this._events;
  }

  // adds a filter with the given name. This can be necessary/preferred over {addDefaultFilter} in cases
  // where either the filter needs specific data the {FilterManager} does not pass to it, or the filter
  // should only by available for this sepcific search
  // @param [Filter] An instance of a subclass of {Filter}
  addFilter(filter) {
    return (this._filters[filter.getName()] = filter);
  }

  // @return [Boolean] is there any filter active that could affect search results
  isActive() {
    return _some(this._filters, (f) => f.isActive());
  }

  // @nodoc
  _clearActiveFilters() {
    return (this._activeFilters = {});
  }

  // @private
  activeRouteFilters() {
    return this._activeFilters.routeFilters != null
      ? this._activeFilters.routeFilters
      : (this._activeFilters.routeFilters = (() => {
          const result = [];
          for (let k in this._filters) {
            const f = this._filters[k];
            if (f.isValidRoute && f.isActive()) {
              result.push(f);
            }
          }
          return result;
        })());
  }

  // @private
  activeFareFilters() {
    return this._activeFilters.fareFilters != null
      ? this._activeFilters.fareFilters
      : (this._activeFilters.fareFilters = (() => {
          const result = [];
          for (let k in this._filters) {
            const f = this._filters[k];
            if (f.isValidFare && f.isActive()) {
              result.push(f);
            }
          }
          return result;
        })());
  }

  // @private
  activeVendorFareFilters() {
    return this._activeFilters.vendorFareFilters != null
      ? this._activeFilters.vendorFareFilters
      : (this._activeFilters.vendorFareFilters = (() => {
          const result = [];
          for (let k in this._filters) {
            const f = this._filters[k];
            if (f.isValidVendorFare && f.isActive()) {
              result.push(f);
            }
          }
          return result;
        })());
  }

  // @param [Route] route a route object to check for validity
  // @return [Boolean] if the route is allowed in search results
  isValidRoute(route, context) {
    return _every(this.activeRouteFilters(), (f) => f.isValidRoute(route, context, this));
  }

  // @param [Fare|MultiFare] fare a fare object to check for validity
  // @return [Boolean] if the fare is allowed in search results
  isValidFare(fare, context) {
    return _every(this.activeFareFilters(), (f) => f.isValidFare(fare, context, this));
  }

  isValidVendorFare(vendorFare, context) {
    return _every(this.activeVendorFareFilters(), (f) =>
      f.isValidVendorFare(vendorFare, context, this)
    );
  }

  // @param [String] name The name of the filter
  // @return Filter object
  // @throw Unknown filter name: [name] if filter does not exist
  getFilter(name) {
    if (!(name in this._filters)) {
      throw new Error(`Unknown filter name: ${name}`);
    }
    return this._filters[name];
  }

  // Sets filter state. Passes data to filter and broadcasts any changes that occured. If update is an object,
  // each key => value pair will be passed to filter.set
  // If changes should be broadcasted, which is generally what you want, then changes should go through this method
  // rather than directly on the filter by calling getFilter(name).set(...)
  // Changes can we listened to be registing to 'filter-change' event on the {FlightSearch} object. The callback will
  // receive a single argument, an array with the name of all the filters that were changed.
  //
  // @example Changing filter
  //   filters.set('airports', {'BWA': false})
  //
  // @example listening to filter changes
  //   search.on('filter-change', function(filterNames) { })
  //
  // @param [String] name Name of the filter
  // @param [Any] data passed to filter.set
  // @param [Object] options
  // @option options [Boolean] silent if changes should be broadcasted. Default = true
  set(name, update, param) {
    if (param == null) {
      param = {};
    }
    const { silent } = param;
    return this.atomic(silent, () => {
      return this._set(name, update);
    });
  }

  // @nodoc
  _set(name, update) {
    const filter = this.getFilter(name);
    if (_isObject(update)) {
      return (() => {
        const result = [];
        for (let k in update) {
          const v = update[k];
          result.push(filter.set(k, v));
        }
        return result;
      })();
    } else {
      return filter.set(update);
    }
  }

  // reset filter state of all filters by calling filter.clear
  // @param [Object] options
  // @option options [Boolean] silent If changes should be broadcasted or not. Default = true
  clear(param) {
    if (param == null) {
      param = {};
    }
    const { silent } = param;
    return this.atomic(silent, () => {
      return (() => {
        const result = [];
        for (let k in this._filters) {
          const f = this._filters[k];
          result.push(f.clear());
        }
        return result;
      })();
    });
  }

  // returns all filters state as a plain old javascript object that can be serialized by calling e.g. JSON.stringify
  // @return [Object] all filter states
  getState() {
    const state = {};
    for (let k in this._filters) {
      const f = this._filters[k];
      if (f.isActive()) {
        state[k] = _clone(f.getState());
      }
    }
    return state;
  }

  // sets filter to a previous state created be {getState}
  setState(state, param) {
    if (param == null) {
      param = {};
    }
    const { silent } = param;
    return this.atomic(silent, () => {
      this.clear();
      return (() => {
        const result = [];
        for (let name in state) {
          const fstate = state[name];
          result.push(this.getFilter(name).setState(fstate));
        }
        return result;
      })();
    });
  }

  // atomic allows you to make changes to any number of filters without broadcasting changes multiple times.
  // Since the broadcasted change event is generally used to re-sorting and re-rendering results, broadcasting
  // change event on every single filter change when changing multiple filters would have serious performance
  // implications. By calling atomic with a callback function, any number of filter changes can be performed in
  // the callback, and only after the callback finishes will it emit a single event for all the changes, if any.
  //
  // @example Clearing multiple filters
  //   search.getFilters().atomic(function(filters){
  //       ['outboundDeparture', 'outboundArrival', 'homeboundDeparture', 'homeboundArrival'].forEeach(function(name) {
  //           filters.getFilter(name).clear()
  //       });
  //   })
  //
  // @param [Function] cb Callback function that can safely perform multiple filter changes
  /**
   *
   * @param {boolean} silent
   * @param {any} cb
   * @returns
   */
  atomic(silent, cb) {
    let r;
    if (arguments.length === 1) {
      [silent, cb] = Array.from([false, silent]);
    }
    if (this.__atomic) {
      return cb(this);
    }

    const oldState = this.getState();
    this.__atomic = true;
    try {
      r = cb(this);
    } finally {
      delete this.__atomic;
    }

    this._diffChanges(oldState, silent);
    return r;
  }

  // @nodoc
  _diffChanges(oldState, silent) {
    const state = this.getState();
    const keys = _union(_keys(oldState), _keys(state));
    const names = (() => {
      const result = [];
      for (let k of Array.from(keys)) {
        if (!_isEqual(oldState[k], state[k])) {
          result.push(k);
        }
      }
      return result;
    })();

    if (names.length) {
      this._clearActiveFilters();
      if (!silent) {
        return this._events.emit('filter-change', names);
      }
    }
  }
}

module.exports = {
  Filter,
  TimeFilter,
  DateFilter,
  BooleanFilter,
  CheckListFilter,
  OutboundDepartureFilter,
  OutboundArrivalFilter,
  HomeboundDepartureFilter,
  HomeboundArrivalFilter,
  AirportFilter,
  AirlineFilter,
  AircraftFilter,
  StopsFilter,
  GroundTransitFilter,
  RedEyeFilter,
  OverNightFilter,
  ConnectionTimeFilter,
  SelfConnectFilter,
  FilterManager,
  addDefaultFilter,
};

function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
