import { Map, List } from 'immutable';
import moment from 'moment';
import time, { daysApart } from 'dohop-client-api/src/time';
import { Calendar } from 'dohop-client-api/src/calendar';
import { HYDRATE, DEHYDRATE } from '../actions/requestActions';
import {
  HOTEL_CHECK_COOKIE,
  FLIGHT_SEARCH_FORM_CLOSEST_AIRPORT,
  FLIGHT_SEARCH_FORM_SET_ORIGIN,
  FLIGHT_SEARCH_FORM_SET_DESTINATION,
  FLIGHT_SEARCH_FORM_SET_DEPARTURE_DATE,
  FLIGHT_SEARCH_FORM_SET_RETURN_DATE,
  FLIGHT_SEARCH_FORM_SET_DATES,
  FLIGHT_SEARCH_FORM_SET_IS_ONE_WAY,
  FLIGHT_SEARCH_FORM_UPDATE_CALENDAR,
  FLIGHT_SEARCH_FORM_SET_ADULTS,
  FLIGHT_SEARCH_FORM_SET_CHILDREN,
  FLIGHT_SEARCH_FORM_SET_CHILD_AGE,
  FLIGHT_SEARCH_FORM_SET_FLEXIBLE_DATES,
  FLIGHT_SEARCH_FORM_SET_IS_DATE_FLEXIBLE,
  FLIGHT_SEARCH_FORM_SET_HOTEL_CHECK,
  FLIGHT_SEARCH_FORM_SET_RECENT_SEARCHES,
} from '../actions/flightSearchFormActions';
import getDocumentCookie from '../lib/getDocumentCookie';
import { queries } from '../components/MatchMedia';

let hotelCheckCookie = getDocumentCookie(HOTEL_CHECK_COOKIE);
let hotelCheck = true;
const isDesktop = queries.ipad && queries.ipad.matches;

if (hotelCheckCookie === undefined && isDesktop) {
  hotelCheck = true;
} else if (!isDesktop) {
  hotelCheck = false;
} else {
  hotelCheck = Boolean(hotelCheckCookie);
}

export function defaultState() {
  return Map({
    defaultOrigin: null,
    origin: null,
    destination: null,
    departureDate: new Date(Date.now() + 2 * time.week),
    returnDate: new Date(Date.now() + 3 * time.week),
    flexibleDate: Map({
      d1: moment().startOf('month'),
      d1_: moment().add(11, 'months').endOf('month'),
    }),
    isDateFlexible: false,
    isOneWay: false,
    adults: 1,
    childrenAges: List(),
    hotelCheck,
    recentSearches: List(),
  });
}

export default function flightSearchFormReducers(state = defaultState(), action) {
  switch (action.type) {
    case FLIGHT_SEARCH_FORM_CLOSEST_AIRPORT:
      return state.merge({
        defaultOrigin: action.airport,
        origin: state.get('origin') || action.airport,
      });
    case FLIGHT_SEARCH_FORM_SET_ORIGIN:
      return state.set('origin', action.airport);
    case FLIGHT_SEARCH_FORM_SET_DESTINATION:
      return state.set('destination', action.destination);
    case FLIGHT_SEARCH_FORM_SET_DEPARTURE_DATE:
      if (state.get('returnDate') && daysApart(action.date, state.get('returnDate')) < 0) {
        state = state.set('returnDate', new Date(action.date.getTime() + time.week));
      }
      return state.set('departureDate', action.date);
    case FLIGHT_SEARCH_FORM_SET_RETURN_DATE:
      return state.set('returnDate', action.date).set('isOneWay', !Boolean(action.date));
    case FLIGHT_SEARCH_FORM_SET_DATES:
      return state.merge({
        departureDate: action.d1,
        returnDate: action.d2 ? action.d2 : new Date(action.d1.getTime() + time.week),
        oneWay: !Boolean(action.d2),
      });
    case FLIGHT_SEARCH_FORM_SET_FLEXIBLE_DATES:
      return state.set('flexibleDate', action.dates).set('isDateFlexible', true);
    case FLIGHT_SEARCH_FORM_SET_IS_DATE_FLEXIBLE:
      return state.set('isDateFlexible', action.isFlexible);
    case FLIGHT_SEARCH_FORM_SET_IS_ONE_WAY:
      return state.set('isOneWay', action.isOneWay);
    case FLIGHT_SEARCH_FORM_UPDATE_CALENDAR:
      return state.set('calendar', action.calendar);
    case FLIGHT_SEARCH_FORM_SET_ADULTS:
      let adults = Math.max(1, Math.min(9, action.adults));
      return state.set('adults', adults);
    case FLIGHT_SEARCH_FORM_SET_CHILDREN:
      let ages = state.get('childrenAges').concat(Array(action.count).fill(8));
      let children = Math.max(0, Math.min(9, action.children));
      return state.set('childrenAges', ages.slice(0, children));
    case FLIGHT_SEARCH_FORM_SET_CHILD_AGE:
      let age = Math.max(0, Math.min(17, action.age));
      return state.setIn(['childrenAges', action.idx], age);
    case FLIGHT_SEARCH_FORM_SET_RECENT_SEARCHES:
      return state.set('recentSearches', action.recentSearches);
    case HYDRATE:
      if (state.get('calendar')) state = state.set('calendar', state.get('calendar').serialize());
      return state;
    case DEHYDRATE:
      return state.merge({
        calendar: new Calendar(state.get('calendar')),
        flexibleDate: Map({
          d1: moment(state.getIn(['flexibleDate', 'd1'])),
          d1_: moment(state.getIn(['flexibleDate', 'd1_'])),
        }),
      });
    case FLIGHT_SEARCH_FORM_SET_HOTEL_CHECK:
      return state.set('hotelCheck', action.payload);
    default:
      return state;
  }
}
