import moment from 'moment';
import { Map } from 'immutable';
import { toUTC } from 'dohop-client-api/src/time';

// Season ids
export const seasonsIds = {
  WINTER: 'winter',
  SPRING: 'spring',
  SUMMER: 'summer',
  FALL: 'fall',
};

export function getWeeksInMonth(month, startDay = 0) {
  let days = Array(moment.utc(month).daysInMonth())
    .fill(null)
    .map((v, i) => {
      return toUTC(new Date(month.getUTCFullYear(), month.getUTCMonth(), i + 1));
    });

  let week = [];
  let weeks = [week];
  days.forEach((day) => {
    if (day.getUTCDay() === startDay && week.length) {
      week = [];
      weeks.push(week);
    }
    week.push(day);
  });

  while (weeks[0].length < 7) {
    let d = weeks[0][0];
    weeks[0].unshift(toUTC(new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate() - 1)));
  }
  return weeks;
}

export function getMonthsInRange(d, range = 2) {
  return Array(range)
    .fill(null)
    .map((v, i) => {
      return toUTC(new Date(d.getUTCFullYear(), d.getUTCMonth() + i));
    });
}

function getSeasonDates(month) {
  const d1 = moment.utc({ M: month }).startOf('month');
  const d1_ = moment.utc({ M: (month + 2) % 12 }).endOf('month');

  if (month === 11) d1.subtract(1, 'year');
  if (moment.utc().diff(d1_, 'days') > 0) {
    d1.add(1, 'year');
    // .endOf('month') is needed here again to make sure that if
    // the new year is a leap year it is set to 29th of februar, not 28th for that case.
    d1_.add(1, 'year').endOf('month');
  }

  return { d1, d1_ };
}

export const SEASONS = Map({
  winter: Map({
    name: (t) => t('winter'),
    id: seasonsIds.WINTER,
  }).merge(getSeasonDates(11)),
  spring: Map({
    name: (t) => t('spring'),
    id: seasonsIds.SPRING,
  }).merge(getSeasonDates(2)),
  summer: Map({
    name: (t) => t('summer'),
    id: seasonsIds.SUMMER,
  }).merge(getSeasonDates(5)),
  fall: Map({
    name: (t) => t('fall'),
    id: seasonsIds.FALL,
  }).merge(getSeasonDates(8)),
});

export function formatFlexibleMonths(dates, t, formatDate) {
  // It should be a season, month or anytime
  const { d1, d1_ } = dates.toObject();
  const chosenSeason = SEASONS.find((season) => {
    return d1.isSame(season.get('d1'), 'day') && d1_.isSame(season.get('d1_'), 'day');
  });
  if (chosenSeason) return chosenSeason.get('name')(t);
  if (d1_.diff(d1, 'months') >= 11) return t('anytime');
  return formatDate(d1.toDate(), 'MMMM YYYY');
}

export const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];
