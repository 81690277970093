import PropTypes from 'prop-types';
import React from 'react';
import propTypes from '../../lib/propTypes';
import formatAirport from '../../lib/formatAirport';
import MatchMedia from '../MatchMedia';
import Tooltip from '../Tooltip';
import './TicketFlight.less';

class TicketFlight extends React.Component {
  render() {
    let { t, formatDate, formatDuration } = this.props.filters;
    let flight = this.props.flight;

    return (
      <div className="TicketFlight">
        <div className="TicketFlight__date">
          <span className={this.props.nextDayDeparture ? 'TicketFlight__nextDay' : ''}>
            {formatDate(flight.getDeparture(), 'll')}
            {flight.isCodeshare() && (
              <Tooltip
                className="TicketFlight__codeshare"
                title={t('Operated_by %(airlineName)s', {
                  airlineName: flight.getLegs()[0]._operatedBy._name,
                })}
              >
                *
              </Tooltip>
            )}
          </span>
        </div>
        <div className="TicketFlight__place">
          <div className="TicketFlight__time">
            <Tooltip title={formatDate(flight.getDeparture(), 'LLLL')}>
              {formatDate(flight.getDeparture(), 'LT')}
            </Tooltip>
          </div>
          <div className="TicketFlight__airport">
            <MatchMedia max="ipad">
              <Tooltip title={formatAirport(flight.getOrigin())}>
                {flight.getOrigin().getCode()}
              </Tooltip>
            </MatchMedia>
            <MatchMedia min="ipad">
              <Tooltip title={formatAirport(flight.getOrigin())}>
                {flight.getOrigin().getCode()}
              </Tooltip>
            </MatchMedia>
          </div>
        </div>
        <div className="TicketFlight__arrow" />
        <div className="TicketFlight__place">
          <div className="TicketFlight__time TicketFlight__time--arrival">
            <Tooltip title={formatDate(flight.getArrival(), 'LLLL')}>
              {formatDate(flight.getArrival(), 'LT')}
            </Tooltip>
          </div>
          <div className="TicketFlight__airport TicketFlight__airport--arrival">
            <MatchMedia max="ipad">
              <Tooltip title={formatAirport(flight.getDestination())}>
                {flight.getDestination().getCode()}
              </Tooltip>
            </MatchMedia>
            <MatchMedia min="ipad">
              <Tooltip title={formatAirport(flight.getDestination())}>
                {flight.getDestination().getCode()}
              </Tooltip>
            </MatchMedia>
          </div>
        </div>
        <span className="TicketFlight__duration">{formatDuration(flight.getDuration())}</span>
      </div>
    );
  }
}

TicketFlight.propTypes = {
  flight: propTypes.flight,
  filters: propTypes.filters,
  isOutbound: PropTypes.bool.isRequired,
};

export default TicketFlight;
