import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLastLegFirstRoute, getFirstLegLastRoute } from '../../../lib/itineraryInfo';
import './CartrawlerWidget.less';

// The partner CarTrawler client ID, we have this ID for the transfer step and another one for the
// Booking confitmation page.
const TRANSFERSTEP_CLIENT_ID = '527309';
const DATA_PARTNER_NAME = 'dohop';

/**
 * Widget from Cartrawler that shows optimal rental cars.
 * Documentation: http://docs.cartrawler.com/docs/ngw/overview.html
 * Html script is set in Tag Manager.
 */

const CartrawlerWidget = props => {
  const { flightSearchForm, user, itinerary } = props;

  const cartrawlerWidgetProps = {
    className: 'CartrawlerWidget',
    'data-client-id': TRANSFERSTEP_CLIENT_ID,
    'data-ct-product': 'widget',
    'data-currency': user.get('currency'),
    'data-language': user.get('language'),
    'data-residence-id': user.get('residency'),
    'data-partner-name': DATA_PARTNER_NAME,
    'data-pax': `${flightSearchForm.get('adults') + flightSearchForm.get('childrenAges').size}`,
    'data-user-age': '30',
  };

  const isOneWay = itinerary.getRoutes().length === 1;

  const lastLegFirstRoute = getLastLegFirstRoute(itinerary);
  const fligh0Destination = lastLegFirstRoute.getDestination();
  const flight0Arrival = moment.utc(lastLegFirstRoute.getArrival());
  const flight0ArrivalCorrectFormat = flight0Arrival.toISOString().split('.')[0];
  const flight0FlightNumber = lastLegFirstRoute.getFlightNumber();

  cartrawlerWidgetProps['data-flight-0-arrival-date-time'] = flight0ArrivalCorrectFormat;
  cartrawlerWidgetProps['data-flight-0-arrival-iata'] = fligh0Destination.getCode();
  cartrawlerWidgetProps['data-flight-0-number'] = flight0FlightNumber;

  // If the flight is one way, we set the dropoff one week after the pickup.
  if (isOneWay) {
    const oneWeekAfterArrival = moment.utc(lastLegFirstRoute.getArrival()).add(7, 'days');
    const oneWeekAfterArrivalCorrectFormat = oneWeekAfterArrival.toISOString().split('.')[0];

    cartrawlerWidgetProps['data-flight-1-departure-date-time'] = oneWeekAfterArrivalCorrectFormat;
    cartrawlerWidgetProps['data-flight-1-departure-iata'] = fligh0Destination.getCode();
  } else {
    const firstLegLastRoute = getFirstLegLastRoute(itinerary);
    const flight1Origin = firstLegLastRoute.getOrigin();
    const flight1Departure = firstLegLastRoute.getDeparture();
    const flight1DepartureCorrectFormat = flight1Departure.toISOString().split('.')[0];
    const flight1FlightNumber = firstLegLastRoute.getFlightNumber();

    cartrawlerWidgetProps['data-flight-1-departure-date-time'] = flight1DepartureCorrectFormat;
    cartrawlerWidgetProps['data-flight-1-departure-iata'] = flight1Origin.getCode();
    cartrawlerWidgetProps['data-flight-1-number'] = flight1FlightNumber;
  }

  return <div {...cartrawlerWidgetProps} />;
};

CartrawlerWidget.propTypes = {
  itinerary: PropTypes.object.isRequired,
  flightSearchForm: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(
  connect(state => ({
    user: state.get('user'),
    flightSearchForm: state.get('flightSearchForm'),
  }))(CartrawlerWidget)
);
