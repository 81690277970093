import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStatus } from '@actions/responseActions';
import createFilters from '@lib/createFilters';
import PageNotFound from '@components/errors/PageNotFound';
import Header from '@components/header/BigLogoHeader';
import Section from '@components/Section';
import Constraint from '@components/Constraint';
import DohopHead from '@components/DohopHead';
import Footer from '@components/Footer';

class PageNotFoundRoute extends Component {
  componentDidMount() {
    this.props.setStatus(404);
  }

  render() {
    return (
      <div className="PageRouteAnimation__fadeIn">
        <DohopHead canonical />
        <Section header>
          <Constraint wide>
            <Header />
          </Constraint>
        </Section>
        <Section content>
          <PageNotFound filters={this.props.filters} />
          <Footer frontpage />
        </Section>
      </div>
    );
  }
}

PageNotFoundRoute = createFilters('filters')(PageNotFoundRoute);
export default connect(
  null,
  dispatch => ({
    setStatus: status => dispatch(setStatus(status)),
  })
)(PageNotFoundRoute);
