import mirrorMap from '../lib/mirrorMap';
import { List, Map } from 'immutable';
import config from '../lib/config';

/*
  Ideally this file should not exist. By the time I am writing this, web-api is not deployed.
  Ideally we would make a http request to web-api which generates a list based on the origin and
  in the correct language.
*/

export const CONTINENTS = mirrorMap([
  'EUROPE',
  'NORTH_AMERICA',
  'ASIA',
  'SOUTH_AMERICA',
  'AFRICA',
  'OCEANIA',
]);

const EUROPE = List([
  Map({
    countryName: 'United Kingdom',
    name: 'London',
    image: `${config.M_STATIC}/away/9083559143_480x333.jpg`,
    to: List(['LHR', 'LGW', 'STN', 'LTN', 'LCY', 'SEN']),
    iata: 'LON',
  }),
  Map({
    countryName: 'United Kingdom',
    name: 'Manchester',
    image: `${config.M_STATIC}/away/7073444693_480x333.jpg`,
    to: List(['MAN']),
    iata: 'MAN',
  }),
  Map({
    countryName: 'Germany',
    name: 'Frankfurt',
    image: `${config.M_STATIC}/away/8354243386_480x333.jpg`,
    to: List(['FRA', 'HHN']),
    iata: 'FRA',
  }),
  Map({
    countryName: 'Germany',
    name: 'Munich',
    image: `${config.M_STATIC}/away/3786952718_480x333.jpg`,
    to: List(['MUC', 'AGB']),
    iata: 'MUC',
  }),
  Map({
    countryName: 'Spain',
    name: 'Madrid',
    image: `${config.M_STATIC}/away/3179480545_480x333.jpg`,
    to: List(['MAD']),
    iata: 'MAD',
  }),
  Map({
    countryName: 'Spain',
    name: 'Barcelona',
    image: `${config.M_STATIC}/away/13128097874_480x333.jpg`,
    to: List(['BCN', 'REU']),
    iata: 'BCN',
  }),
  Map({
    countryName: 'Norway',
    name: 'Oslo',
    image: `${config.M_STATIC}/away/3198546988_480x333.jpg`,
    to: List(['OSL', 'TRF']),
    iata: 'OSL',
  }),
  Map({
    countryName: 'Denmark',
    name: 'Copenhagen',
    image: `${config.M_STATIC}/away/3986835532_480x333.jpg`,
    to: List(['CPH', 'MMX']),
    iata: 'CPH',
  }),
  Map({
    countryName: 'Netherlands',
    name: 'Amsterdam',
    image: `${config.M_STATIC}/away/6674549347_480x333.jpg`,
    to: List(['AMS']),
    iata: 'AMS',
  }),
  Map({
    countryName: 'France',
    name: 'Paris',
    image: `${config.M_STATIC}/away/6223213423_480x333.jpg`,
    to: List(['CDG', 'ORY', 'BVA', 'XCR']),
    iata: 'PAR',
  }),
  Map({
    countryName: 'Sweden',
    name: 'Stockholm',
    image: `${config.M_STATIC}/away/9110105324_480x333.jpg`,
    to: List(['ARN', 'BMA', 'NYO', 'VST']),
    iata: 'STO',
  }),
  Map({
    countryName: 'Iceland',
    name: 'Reykjavik',
    image: `${config.M_STATIC}/away/5120508384_480x333.jpg`,
    to: List(['KEF', 'RKV']),
    iata: 'REK',
  }),
  Map({
    countryName: 'Russia',
    name: 'Moscow',
    image: `${config.M_STATIC}/away/3716549433_480x333.jpg`,
    to: List(['SVO', 'DME', 'VKO', 'ZIA']),
    iata: 'MOW',
  }),
  Map({
    countryName: 'Italy',
    name: 'Rome',
    image: `${config.M_STATIC}/away/6138244968_480x333.jpg`,
    to: List(['FCO', 'CIA']),
    iata: 'ROM',
  }),
  Map({
    countryName: 'Ireland',
    name: 'Dublin',
    image: `${config.M_STATIC}/away/14154234624_480x333.jpg`,
    to: List(['DUB']),
    iata: 'DUB',
  }),
  Map({
    countryName: 'Spain',
    name: 'Palma de Mallorca',
    image: `${config.M_STATIC}/away/4141749131_480x333.jpg`,
    to: List(['PMI']),
    iata: 'PMI',
  }),
  Map({
    countryName: 'Portugal',
    name: 'Lisbon',
    image: `${config.M_STATIC}/away/4355153923_480x333.jpg`,
    to: List(['LIS']),
    iata: 'LIS',
  }),
  Map({
    countryName: 'Italy',
    name: 'Milan',
    image: `${config.M_STATIC}/away/3056007366_480x333.jpg`,
    to: List(['MXP', 'LIN', 'BGY', 'PMF']),
    iata: 'MIL',
  }),
  Map({
    countryName: 'Belgium',
    name: 'Brussels',
    image: `${config.M_STATIC}/away/5474978288_480x333.jpg`,
    to: List(['BRU', 'CRL']),
    iata: 'BRU',
  }),
  Map({
    countryName: 'Germany',
    name: 'Berlin',
    image: `${config.M_STATIC}/away/154695434_480x333.jpg`,
    to: List(['TXL', 'SXF']),
    iata: 'BER',
  }),
  Map({
    countryName: 'Finland',
    name: 'Helsinki',
    image: `${config.M_STATIC}/away/5957177169_480x333.jpg`,
    to: List(['HEL']),
    iata: 'HEL',
  }),
  Map({
    countryName: 'Austria',
    name: 'Vienna',
    image: `${config.M_STATIC}/away/4970314282_480x333.jpg`,
    to: List(['VIE']),
    iata: 'VIE',
  }),
  Map({
    countryName: 'Turkey',
    name: 'Istanbul',
    image: `${config.M_STATIC}/away/3904811506_480x333.jpg`,
    to: List(['IST', 'SAW']),
    iata: 'IST',
  }),
]);

const NORTH_AMERICA = List([
  Map({
    countryName: 'United States',
    name: 'Atlanta',
    image: `${config.M_STATIC}/away/355135172_480x333.jpg`,
    to: List(['ATL', 'PDK']),
    iata: 'ATL',
  }),
  Map({
    countryName: 'United States',
    name: 'Boston',
    image: `${config.M_STATIC}/away/4743036023_480x333.jpg`,
    to: List(['BOS', 'PSM']),
    iata: 'BOS',
  }),
  Map({
    countryName: 'Canada',
    name: 'Toronto',
    image: `${config.M_STATIC}/away/5173748531_480x333.jpg`,
    to: List(['YYZ', 'YTZ', 'YHM']),
    iata: 'YTO',
  }),
  Map({
    countryName: 'United States',
    name: 'New York City',
    image: `${config.M_STATIC}/away/4820215228_480x333.jpg`,
    to: List(['EWR', 'JFK', 'LGA']),
    iata: 'NYC',
  }),
  Map({
    countryName: 'United States',
    name: 'Orlando',
    image: `${config.M_STATIC}/away/5091448811_480x333.jpg`,
    to: List(['MCO', 'SFB']),
    iata: 'ORL',
  }),
  Map({
    countryName: 'United States',
    name: 'Denver',
    image: `${config.M_STATIC}/away/4280266430_480x333.jpg`,
    to: List(['DEN']),
    iata: 'DEN',
  }),
  Map({
    countryName: 'United States',
    name: 'Los Angeles',
    image: `${config.M_STATIC}/away/405973812_480x333.jpg`,
    to: List(['LAX', 'SNA', 'BUR', 'ONT', 'LGB']),
    iata: 'LAX',
  }),
  Map({
    countryName: 'United States',
    name: 'San Francisco',
    image: `${config.M_STATIC}/away/4436427104_480x333.jpg`,
    to: List(['SFO', 'SJC', 'OAK']),
    iata: 'SFO',
  }),
  Map({
    countryName: 'United States',
    name: 'Phoenix',
    image: `${config.M_STATIC}/away/5193707116_480x333.jpg`,
    to: List(['PHX', 'AZA']),
    iata: 'PHX',
  }),
  Map({
    countryName: 'United States',
    name: 'Las Vegas',
    image: `${config.M_STATIC}/away/4675155661_480x333.jpg`,
    to: List(['LAS']),
    iata: 'LAS',
  }),
  Map({
    countryName: 'United States',
    name: 'Detroit',
    image: `${config.M_STATIC}/away/65559106_480x333.jpg`,
    to: List(['DTW']),
    iata: 'DTT',
  }),
  Map({
    countryName: 'United States',
    name: 'Honolulu, Hawaii',
    image: `${config.M_STATIC}/away/2864310837_480x333.jpg`,
    to: List(['HNL']),
    iata: 'HNL',
  }),
  Map({
    countryName: 'United States',
    name: 'Portland, Oregon',
    image: `${config.M_STATIC}/away/2864310837_480x333.jpg`,
    to: List(['PDX']),
    iata: 'PDX',
  }),
  Map({
    countryName: 'Canada',
    name: 'Montreal',
    image: `${config.M_STATIC}/away/261312724_480x333.jpg`,
    to: List(['YUL', 'YHU', 'YMX']),
    iata: 'YMQ',
  }),
  Map({
    countryName: 'Canada',
    name: 'Victoria',
    image: `${config.M_STATIC}/away/4857602637_480x333.jpg`,
    to: List(['YYJ', 'YWH']),
    iata: 'YYJ',
  }),
  Map({
    countryName: 'United States',
    name: 'Dallas',
    image: `${config.M_STATIC}/away/4857602637_480x333.jpg`,
    to: List(['DFW', 'DAL']),
    iata: 'DFW',
  }),
  Map({
    countryName: 'United States',
    name: 'Miami',
    image: `${config.M_STATIC}/away/5326653831_480x333.jpg`,
    to: List(['MIA', 'FLL']),
    iata: 'MIA',
  }),
  Map({
    countryName: 'United States',
    name: 'Chicago',
    image: `${config.M_STATIC}/away/9605601998_480x333.jpg`,
    to: List(['ORD', 'MDW', 'RFD']),
    iata: 'CHI',
  }),
  Map({
    countryName: 'Mexico',
    name: 'Cancún',
    image: `${config.M_STATIC}/away/3489506235_480x333.jpg`,
    to: List(['CUN']),
    iata: 'CUN',
  }),
  Map({
    countryName: 'Mexico',
    name: 'Mexico City',
    image: `${config.M_STATIC}/away/2547560556_480x333.jpg`,
    to: List(['MEX', 'TLC']),
    iata: 'MEX',
  }),
]);

const ASIA = List([
  Map({
    countryName: 'Hong Kong',
    name: 'Hong Kong',
    image: `${config.M_STATIC}/away/11248922415_480x333.jpg`,
    to: List(['HKG']),
    iata: 'HKG',
  }),
  Map({
    countryName: 'United Arab Emirates',
    name: 'Dubai',
    image: `${config.M_STATIC}/away/2086020608_480x333.jpg`,
    to: List(['DXB', 'SHJ', 'DWC']),
    iata: 'DXB',
  }),
  Map({
    countryName: 'China',
    name: 'Beijing',
    image: `${config.M_STATIC}/away/1952627233_480x333.jpg`,
    to: List(['PEK', 'NAY']),
    iata: 'BJS',
  }),
  Map({
    countryName: 'South Korea',
    name: 'Seoul',
    image: `${config.M_STATIC}/away/3768511342_480x333.jpg`,
    to: List(['ICN', 'GMP']),
    iata: 'SEL',
  }),
  Map({
    countryName: 'Japan',
    name: 'Tokyo',
    image: `${config.M_STATIC}/away/14023542994_480x333.jpg`,
    to: List(['HND', 'NRT', 'IBR']),
    iata: 'TYO',
  }),
  Map({
    countryName: 'China',
    name: 'Shanghai',
    image: `${config.M_STATIC}/away/5144482260_480x333.jpg`,
    to: List(['PVG', 'SHA']),
    iata: 'SHA',
  }),
  Map({
    countryName: 'India',
    name: 'Mumbai',
    image: `${config.M_STATIC}/away/568018790_480x333.jpg`,
    to: List(['BOM']),
    iata: 'BOM',
  }),
]);

const SOUTH_AMERICA = List([
  Map({
    countryName: 'Brazil',
    name: 'Rio de Janeiro',
    image: `${config.M_STATIC}/away/3180645952_480x333.jpg`,
    to: List(['GIG', 'SDU']),
    iata: 'RIO',
  }),
  Map({
    countryName: 'Brazil',
    name: 'São Paulo',
    image: `${config.M_STATIC}/away/3192059946_480x333.jpg`,
    to: List(['GRU', 'CGH', 'VCP']),
    iata: 'SAO',
  }),
  Map({
    countryName: 'Colombia',
    name: 'Bogotá',
    image: `${config.M_STATIC}/away/5596490607_480x333.jpg`,
    to: List(['BOG']),
    iata: 'BOG',
  }),
  Map({
    countryName: 'Chile',
    name: 'Santiago',
    image: `${config.M_STATIC}/away/8501779000_480x333.jpg`,
    to: List(['SCL']),
    iata: 'SCL',
  }),
  Map({
    countryName: 'Peru',
    name: 'Lima',
    image: `${config.M_STATIC}/away/7521858506_480x333.jpg`,
    to: List(['LIM']),
    iata: 'LIM',
  }),
  Map({
    countryName: 'Argentina',
    name: 'Buenos Aires',
    image: `${config.M_STATIC}/away/13193646785_480x333.jpg`,
    to: List(['AEP', 'EZE']),
    iata: 'BUE',
  }),
]);

const AFRICA = List([
  Map({
    countryName: 'Nigeria',
    name: 'Lagos',
    image: `${config.M_STATIC}/away/3328307675_480x333.jpg`,
    to: List(['LOS']),
    iata: 'LOS',
  }),
  Map({
    countryName: 'South Africa',
    name: 'Cape Town',
    image: `${config.M_STATIC}/away/7645751700_480x333.jpg`,
    to: List(['CPT']),
    iata: 'CPT',
  }),
  Map({
    countryName: 'Egypt',
    name: 'Cairo',
    image: `${config.M_STATIC}/away/3041117616_480x333.jpg`,
    to: List(['CAI']),
    iata: 'CAI',
  }),
  Map({
    countryName: 'South Africa',
    name: 'Johannesburg',
    image: `${config.M_STATIC}/away/4179057315_480x333.jpg`,
    to: List(['JNB']),
    iata: 'JNB',
  }),
  Map({
    countryName: 'Kenya',
    name: 'Nairobi',
    image: `${config.M_STATIC}/away/3221863269_480x333.jpg`,
    to: List(['NBO', 'WIL']),
    iata: 'NBO',
  }),
  Map({
    countryName: 'Morocco',
    name: 'Casablanca',
    image: `${config.M_STATIC}/away/7401109748_480x333.jpg`,
    to: List(['CMN']),
    iata: 'CAS',
  }),
  Map({
    countryName: 'Tunisia',
    name: 'Tunis',
    image: `${config.M_STATIC}/away/11493097753_480x333.jpg`,
    to: List(['TUN']),
    iata: 'TUN',
  }),
  Map({
    countryName: 'Morocco',
    name: 'Marrakesh',
    image: `${config.M_STATIC}/away/3540234834_480x333.jpg`,
    to: List(['RAK']),
    iata: 'RAK',
  }),
]);

const OCEANIA = List([
  Map({
    countryName: 'Australia',
    name: 'Sydney',
    image: `${config.M_STATIC}/away/6409684029_480x333.jpg`,
    to: List(['SYD']),
    iata: 'SYD',
  }),
  Map({
    countryName: 'New Zealand',
    name: 'Wellington',
    image: `${config.M_STATIC}/away/6063846_480x333.jpg`,
    to: List(['WLG']),
    iata: 'WLG',
  }),
  Map({
    countryName: 'Australia',
    name: 'Canberra',
    image: `${config.M_STATIC}/away/2192098809_480x333.jpg`,
    to: List(['CBR']),
    iata: 'CBR',
  }),
  Map({
    countryName: 'Australia',
    name: 'Melbourne',
    image: `${config.M_STATIC}/away/11321246326_480x333.jpg`,
    to: List(['MEL', 'AVV', 'MEB']),
    iata: 'MEL',
  }),
  Map({
    countryName: 'New Zealand',
    name: 'Auckland',
    image: `${config.M_STATIC}/away/1406449370_480x333.jpg`,
    to: List(['AKL']),
    iata: 'AKL',
  }),
]);

export default Map({
  [CONTINENTS.EUROPE]: EUROPE,
  [CONTINENTS.NORTH_AMERICA]: NORTH_AMERICA,
  [CONTINENTS.ASIA]: ASIA,
  [CONTINENTS.SOUTH_AMERICA]: SOUTH_AMERICA,
  [CONTINENTS.AFRICA]: AFRICA,
  [CONTINENTS.OCEANIA]: OCEANIA,
});
