import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ItineraryFinder } from 'dohop-client-api/src/helpers';
import PropTypes from 'prop-types';
import BackButton from '../BackButton';
import VendorPicker from '../VendorPicker';
import MultiTickets from '../MultiTickets/MultiTickets';
import MatchMedia from '../../MatchMedia';
import TransferStepHeader from '../TransferStepHeader';
import TransferStepAd from '../../ads/TransferStepAd';
import ItinerarySection from '../ItinerarySection';
import CartrawlerWidget from '../CartrawlerWidget';
import './TransferStep.less';

class TransferStep extends Component {
  state = {
    openFare: false,
    isSearchingForItineraries: true,
  };

  componentDidMount() {
    this.getItineraries();
  }

  componentWillUnmount() {
    const { search } = this.props;
    // Necessary for no memory leakage
    if (this.finder) {
      this.finder.close();
      search.removeListener(['end'], this.itinerarySearchFinished);
    }
  }

  itinerarySearchFinished = () => {
    // Can be used for further loading indication/display
    this.setState({ isSearchingForItineraries: false });
  };

  /**
   * If fare needs multiple bookings we open modal
   * @param {Object} fare
   * @memberof TransferStep
   */
  openMultiFare = (fare) => {
    if (fare && !fare.isMulti()) {
      throw new Error('Itinerary can only show multi-fare popups');
    }

    this.setState({ openFare: fare });
  };

  /**
   * Loads itineraries info from server from itineraryKey
   * via ItineraryFinder
   * @memberof TransferStep
   */
  getItineraries = () => {
    const { search, location } = this.props;
    const { hash } = location;
    const id = hash.split('/')[1];
    this.finder = new ItineraryFinder(search, id);
    // As itineraries roll in we update state
    this.finder.watch((itinerary) => this.setState({ itinerary }));
    // Milestones on search, 'change' also possible
    search.on(['end'], this.itinerarySearchFinished);
  };

  closeTransferStep = () => {
    const { history } = this.props;

    // To open transfer step we set state.
    // If no state we're coming directly from server
    if (history.location.state) {
      return history.go(-1);
    }

    this.redirectToItinerary();
  };

  /**
   * Full redirect to itinerary route
   * @memberof TransferStep
   */
  redirectToItinerary = () => {
    const { match, history } = this.props;
    const { params } = match;
    const { origin, destination, departureDate, returnDate, adults, children } = params;

    const url = `/flights/${origin}/${destination}/${departureDate}/${
      returnDate ? returnDate + '/' : ''
    }${adults}/${children ? children : ''}`;

    history.push(url);
  };

  render() {
    const { search, searchData, ItinerarySectionUrl, filters, language, residency } = this.props;
    const { t } = filters;
    const { itinerary, isSearchingForItineraries, openFare } = this.state;

    return (
      <div className="TransferStep">
        {/* When itinerary requires more than 2 bookings */}
        {openFare && (
          <MultiTickets
            fare={openFare}
            search={search}
            onClose={() => this.openMultiFare(false)}
            itinerary={itinerary}
          />
        )}
        <TransferStepHeader t={t} language={language} residency={residency} />
        <BackButton t={t} onClick={this.closeTransferStep} />
        <div className="TransferStep__body">
          <MatchMedia max="desktop" className="TransferStep__side">
            <ItinerarySection
              search={search}
              filters={filters}
              searchData={searchData}
              itinerary={itinerary}
              ItinerarySectionUrl={ItinerarySectionUrl}
              isSearchingForItineraries={isSearchingForItineraries}
            />
          </MatchMedia>
          <div className="TransferStep__main">
            <VendorPicker
              search={search}
              filters={filters}
              itinerary={itinerary}
              openMultiFare={this.openMultiFare}
            />
            {itinerary && <CartrawlerWidget itinerary={itinerary} />}
            <MatchMedia max="desktop" className="TransferStep__ads">
              <TransferStepAd
                search={search}
                t={t}
                type="hotel"
                imgSrc="/content/img/hotelad.jpg"
              />
            </MatchMedia>
          </div>
          <MatchMedia min="desktop" className="TransferStep__side">
            <ItinerarySection
              search={search}
              filters={filters}
              searchData={searchData}
              itinerary={itinerary}
              ItinerarySectionUrl={ItinerarySectionUrl}
              isSearchingForItineraries={isSearchingForItineraries}
            />
            <div className="TransferStep__ads">
              <TransferStepAd
                search={search}
                t={t}
                type="hotel"
                imgSrc="/content/img/hotelad.jpg"
              />
            </div>
          </MatchMedia>
        </div>
      </div>
    );
  }
}

TransferStep.propTypes = {
  search: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  itinerary: PropTypes.object,
};

export default withRouter(
  connect((state) => ({
    residency: state.getIn(['user', 'residency']),
    language: state.getIn(['user', 'language']),
  }))(TransferStep)
);
