import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CounterButton from '../../../buttons/CounterButton';
import './ControlRow.less';

const ControlRow = ({
  label,
  age,
  number,
  isDecDisabled,
  isAddDisabled,
  onClickDec,
  onClickAdd,
  onKeyDownAdd,
  onKeyDownDec,
}) => {
  const extraProps = {};

  if (onKeyDownAdd) {
    extraProps.onKeyDownAdd = onKeyDownAdd;
  }

  if (onKeyDownDec) {
    extraProps.onKeyDownDec = onKeyDownDec;
  }

  return (
    <div className={classNames('ControlRow', { 'ControlRow--noAge': !age })}>
      <div className="ControlRow__label">
        <div className="ControlRow__type">{label}</div>
        <div className="ControlRow__age">{age}</div>
      </div>
      <div className="ControlRow__pax">
        <CounterButton
          readOnly
          value={number}
          onClickSubtract={onClickDec}
          onClickAdd={onClickAdd}
          isSubDisabled={isDecDisabled}
          isAddDisabled={isAddDisabled}
          {...extraProps}
        />
      </div>
    </div>
  );
};

ControlRow.defaultProps = {
  age: null,
  isDecDisabled: false,
  isAddDisabled: false,
  onKeyDownAdd: null,
};

ControlRow.propTypes = {
  label: PropTypes.string.isRequired,
  age: PropTypes.string,
  number: PropTypes.number.isRequired,
  isDecDisabled: PropTypes.bool,
  isAddDisabled: PropTypes.bool,
  onClickDec: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onKeyDownAdd: PropTypes.func,
};

export default ControlRow;
