import React, { Component } from 'react';
import moment from 'moment';
import { pickLocale } from 'shared/utils/languageUtils';
import FilterBox from '../flights/FilterBox';
import FilterSlider from '../flights/FilterSlider';
import Checkbox from '../form/Checkbox';
import { setStay, setWeekdays, setNonStop, resetFilters, fetchGo } from '../../actions/goActions';
import { connect } from 'react-redux';
import './GoFilters.less';

class GoFilters extends Component {
  state = {
    stay: this.props.stay,
  };

  onClearAll() {
    this.props.reset();
  }

  onChangeWeekday(weekday, e) {
    const weekdays = this.props.weekdays.split('').map(Number);
    if (e.target.checked) {
      weekdays.push(weekday);
    } else {
      const index = weekdays.indexOf(weekday);
      if (index === -1) return;
      weekdays.splice(index, 1);
    }
    this.props.setWeekdays(weekdays.join(''));
  }

  onSliderDrag(values) {
    this.setState({ stay: values.join('-') });
  }

  onSliderApply() {
    this.props.setStay(this.state.stay);
  }

  getWeekdays() {
    const { user } = this.props;
    const defaultWeekdays = moment.localeData('en-gb').weekdays();
    let weekdayNames =
      moment.localeData(pickLocale(user.get('language'), user.get('residency'))).weekdays() ||
      defaultWeekdays;
    if (weekdayNames.standalone) weekdayNames = weekdayNames.standalone;
    weekdayNames = weekdayNames.slice();
    weekdayNames.push(weekdayNames.shift()); // We want it to start on a Monday
    return weekdayNames;
  }

  render() {
    const { t, showHeader, nonStop, oneWay, weekdays } = this.props;
    const weekdayNames = this.getWeekdays();
    let sliderValues = this.state.stay.split('-').map(Number);
    const isOneWay = oneWay === 'true';

    return (
      <div className="GoFilters">
        <FilterBox
          showHeader={showHeader}
          title={t('Filters')}
          togglable={false}
          clickableText={t('reset')}
          onClick={this.onClearAll.bind(this)}
          noLine
        >
          <Checkbox
            className="GoFilters__nonstop"
            checked={nonStop === 'true'}
            onChange={e => this.props.setNonStop(e.target.checked)}
          >
            {t('stop_none')}
          </Checkbox>
          {!isOneWay && (
            <FilterBox title={t('hotels_how_long')}>
              <FilterSlider
                value={sliderValues}
                step={1}
                min={1}
                hint={`${sliderValues[0]} ${t('days')}`}
                rightHint={`${sliderValues[1]} ${t('days')}`}
                max={31}
                disabled={isOneWay}
                onChange={this.onSliderDrag.bind(this)}
                onAfterChange={this.onSliderApply.bind(this)}
              />
            </FilterBox>
          )}
          <FilterBox title={t('departure_days')}>
            {weekdayNames.map((weekday, i) => (
              <div key={`weekday${i}`} className="GoFilters__row">
                <Checkbox
                  className="GoFilters__weekday"
                  onChange={this.onChangeWeekday.bind(this, i + 1)}
                  checked={weekdays.indexOf(i + 1) !== -1}
                >
                  {weekday}
                </Checkbox>
              </div>
            ))}
          </FilterBox>
        </FilterBox>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  const wrapFunction = (func, ...args) => {
    dispatch(func(...args));
    dispatch(fetchGo());
  };
  return {
    setStay: stay => wrapFunction(setStay, stay),
    setNonStop: isNonStop => wrapFunction(setNonStop, isNonStop),
    setWeekdays: weekdays => wrapFunction(setWeekdays, weekdays),
    reset: () => wrapFunction(resetFilters),
  };
}

export default connect(
  state => ({
    stay: state.getIn(['go', 'stay']),
    nonStop: state.getIn(['go', 'nonStop']),
    oneWay: state.getIn(['go', 'oneWay']),
    weekdays: state.getIn(['go', 'weekdays']),
    user: state.get('user'),
  }),
  mapDispatchToProps
)(GoFilters);
