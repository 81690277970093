import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CookieSVG from '../svg/cookie.svg';

const COOKIE_KEY = 'COOKIE_CONSENT';

class CookieMessage extends Component {
  constructor(props) {
    super(props);
    this.state = { showCookie: false };
    this.closeMessage = this.closeMessage.bind(this);
  }

  componentDidMount() {
    const { getLocallySaved } = this.props;
    if (!getLocallySaved(COOKIE_KEY)) {
      this.setState({ showCookie: true });
    }
  }

  closeMessage() {
    const { saveLocally, onClose } = this.props;
    saveLocally(COOKIE_KEY, true);
    this.setState({ showCookie: false });
    onClose();
  }

  componentDidUpdate() {
    const { showCookie } = this.state;
    const { onOpen } = this.props;
    if (showCookie && this.cookieMessage) {
      this.animationFrameID = requestAnimationFrame(() => {
        onOpen(this.cookieMessage.clientHeight);
      });
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrameID);
  }

  render() {
    const { t, cookieHref, className, messageString, closeButtonText, showCookieIcon } = this.props;
    const { showCookie } = this.state;
    const messageParams = {
      link_start: `<a target="_blank" href="${cookieHref}">`,
      link_end: '</a>',
    };
    const message = messageString
      ? t(messageString, messageParams)
      : t('cookiemessage', messageParams);

    if (!showCookie) {
      return null;
    }
    return (
      <div className={classNames('CookieMessage', className)} ref={(r) => (this.cookieMessage = r)}>
        <div className="CookieMessage__message">
          <div className="CookieMessage__content">
            {showCookieIcon && <CookieSVG className="CookieMessage__icon" />}
            <div
              className="CookieMessage__text"
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            />
          </div>
          <button className="CookieMessage__close" onClick={this.closeMessage}>
            {closeButtonText}
          </button>
        </div>
      </div>
    );
  }
}

CookieMessage.defaultProps = {
  cookieHref: '/about/cookie-policy',
  onOpen: (height) => null,
  onClose: () => null,
  className: '',
  showCookieIcon: true,
};

CookieMessage.propTypes = {
  t: PropTypes.func.isRequired,
  saveLocally: PropTypes.func.isRequired,
  getLocallySaved: PropTypes.func.isRequired,
  cookieHref: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  messageString: PropTypes.string,
  closeButtonText: PropTypes.string,
  showCookieIcon: PropTypes.bool,
};

export default CookieMessage;
