import React from 'react';
import PropTypes from 'prop-types';
import { getDohopSupportNumber } from 'booking-jslib/src/vendorInfo';
import LogoSvg from '@content/svg/logo.svg';
import Settings from '@components/user/Settings';
import './TransferStepHeader.less';

const TransferStepHeader = ({ t, language, residency }) => {
  const supportNumber = getDohopSupportNumber(language, residency);
  const url = '/';

  return (
    <div className="TransferStepHeader">
      <div className="TransferStepHeader__left">
        <a href={url} alt="Dohop">
          <LogoSvg className="TransferStepHeader__logo" />
        </a>
      </div>
      <div className="TransferStepHeader__right">
        <Settings />
        <span className="TransferStepHeader__divider" />
        <a href={`tel:${supportNumber}`} className="TransferStepHeader__customerService">
          <span className="TransferStepHeader__txt">{t('dohop_customer_service')}</span>
          <span className="TransferStepHeader__phone">{supportNumber}</span>
        </a>
      </div>
    </div>
  );
};

TransferStepHeader.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  residency: PropTypes.string.isRequired,
};

export default TransferStepHeader;
