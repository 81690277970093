import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import OverlayStack from '../modal/OverlayStack';
import connectAutocomplete from './ConnectAutocomplete';
import Results from './Results';
import SearchInput from '../frontpage/SearchForm/SearchInput';
import Location from '@content/svg/location.svg';
import './MobileAutocomplete.less';

const MobileAutocomplete = props => {
  const input = useRef(null);

  useEffect(() => {
    input.current.focus();
  }, []);

  const formatValue = value => {
    const {
      autocomplete: { shouldClearInput },
    } = props;

    return shouldClearInput ? '' : value;
  };

  const {
    t,
    placeholder,
    onClose,
    id,
    autocomplete,
    error,
    inputClassName,
    autocomplete: { value, onChange, onBlur, onFocus, onKeyDown },
  } = props;

  return (
    <OverlayStack>
      <div className="MobileAutocomplete">
        <div className="MobileAutocomplete__topBar">
          <div className="MobileAutocomplete__row">
            <SearchInput
              id={id}
              className="MobileAutocomplete__input"
              ref={input}
              value={formatValue(value)}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              icon={cn => <Location className={cn} />}
              autoComplete="off"
              error={error}
              inputClassName={inputClassName}
            />
            <div
              className="MobileAutocomplete__cancelButton"
              onClick={onClose}
              role="button"
              tabIndex={0}
            >
              {t('cancel')}
            </div>
          </div>
        </div>
        <Results t={t} {...autocomplete} id={id} />
      </div>
    </OverlayStack>
  );
};

MobileAutocomplete.defaultProps = {
  id: null,
  placeholder: '',
  error: false,
  inputClassName: '',
};

MobileAutocomplete.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  t: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  autocomplete: PropTypes.object.isRequired,
  // Used in the ConnectAutocomplete wrapper component.
  source: PropTypes.func.isRequired,
  error: PropTypes.bool,
  inputClassName: PropTypes.string,
};

export default connectAutocomplete()(MobileAutocomplete);
