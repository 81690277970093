import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setValuesFromRecentSearch as setValuesFromRecentSearchAction } from '@actions/flightSearchFormActions';
import * as keyCodes from '@lib/keyCodes';
import { trackRecentSearch } from '@lib/tracking';

/**
 * For async function, if the same function is called again before it manages
 * to return the response, abort the previous function call.
 * @param {function} func - the function.
 */
function onlyResolveLatest(func) {
  let id = 0;
  return (...args) => {
    let currentId = ++id;
    return new Promise((resolve, reject) => {
      func(...args).then(result => {
        // only resolve result if another request hasn't been made before this one returned
        if (currentId === id) {
          return resolve(result);
        }
      });
    });
  };
}

/**
 * Wrapper component for event handlers and other functionality for the Autocompleter.
 * The component using this one as a wrapper can access its functions and state values through the
 * props: autocomplete.
 *
 */
function connectAutocomplete({ passAsProp = 'autocomplete' } = {}) {
  return WrappedComponent => {
    class ConnectAutocomplete extends Component {
      state = {
        results: [],
        selectedIndex: 0,
        noResults: false,
        hasFocus: false,
        // When the input is focused it should clear.
        shouldClearInput: false,
      };

      static getDerivedStateFromProps(props, state) {
        const { results, value, defaultValue, shouldClearInput, hasFocus } = state;
        const { recentSearches, extraResult } = props;
        const updatedState = {};

        // Initialise the value state in the begining.
        if (value === undefined) {
          updatedState.value = props.value || '';
        }

        // When user focuses on the input...
        if (hasFocus) {
          let updatedResults = results;

          // ...and has not begun to type we show their recent searches if existed.
          if (shouldClearInput && recentSearches) {
            updatedResults = recentSearches;
          }

          // ...also, if there is an extra result and it is not already in the results state we add id aswell.
          if (extraResult && !updatedResults.find(result => result.item.get('flexible'))) {
            updatedResults.push(extraResult);
          }

          updatedState.results = updatedResults;
        }

        // If value prop changes it will override the current input value.
        // Mainly needed because values from redux state take time to fetch.
        // Note! We should change how we handle the value changes in the autocompleter.
        // The component should be either fully controlled or fully uncontrolled.
        if (defaultValue !== props.value) {
          updatedState.value = props.value || '';
          updatedState.defaultValue = props.value || '';
        }

        return updatedState;
      }

      componentDidMount() {
        const { source } = this.props;
        this.source = onlyResolveLatest(source);
      }

      componentWillUnmount() {
        clearTimeout(this.timer);
      }

      /**
       * These are the props that are passed to the inner component.
       * The state and the event handlers.
       */
      getPropsToPass() {
        const eventHandlers = {
          onChange: e => this.onChange(e),
          onClose: (...args) => this.onClose(...args),
          onFocus: e => this.onFocus(e),
          onBlur: e => this.onBlur(e),
          onSelect: (...args) => this.onSelect(...args),
          onChangeSelected: selectedIndex => this.onChangeSelected(selectedIndex),
          onKeyDown: e => this.onKeyDown(e),
        };
        return { ...this.state, ...eventHandlers };
      }

      onChange = e => {
        const { minLength, extraResult, delay } = this.props;
        const { shouldClearInput } = this.state;

        if (!e.persist) {
          e.persist = () => {};
        }

        e.persist();

        clearTimeout(this.timer);

        if (shouldClearInput) {
          this.setState({ shouldClearInput: false });
        }

        this.setState({ value: e.target.value }, () => {
          const { value } = this.state;

          // Don't show autocomplete suggestions unless the user has typed in at least 2 letters.
          if (value.length < minLength) {
            const results = extraResult ? [extraResult] : [];
            this.setState({ results });
          } else {
            // We don't send the request unless the value haven't changed for at least the delay amount of time.
            // If the dealy is to short we are sending the request when the user is still obviously typing
            // and the autocompleter appears to be laggy.
            this.timer = setTimeout(() => {
              this.source(value).then(results => {
                const noResults = results.length === 0;
                if (extraResult) {
                  results.push(extraResult);
                }
                this.setState({
                  results,
                  noResults,
                  selectedIndex: 0,
                });
              });
            }, delay);
          }
        });
      };

      onChangeSelected = selectedIndex => {
        this.setState({ selectedIndex });
      };

      onClose = e => {
        const { selectedIndex, defaultValue, results, noResults, hasFocus } = this.state;

        // Unnecessary to close the autocompleter if it is already closed.
        // (Clickoutside on close function can be triggered for more than one autocompleter.)
        if (hasFocus) {
          if (results.length && !results[0].item.get('flexible') && !noResults && e) {
            this.onSelect(results[selectedIndex], e, 'clicked-outside');
          } else {
            this.setState({ value: defaultValue });
          }

          this.setState({
            results: [],
            selectedIndex: 0,
            hasFocus: false,
            shouldClearInput: false,
            noResults: false,
          });
        }
      };

      onFocus = e => {
        const { onFocus } = this.props;
        e.preventDefault();

        this.setState({ hasFocus: true, shouldClearInput: true, noResults: false });

        if (onFocus) {
          onFocus();
        }
      };

      onBlur = e => {
        e.preventDefault();
      };

      onSelect = ({ item, value }, e, action = 'clicked-result') => {
        const { onSelect, setValuesFromRecentSearch, onSelectRecentSearch } = this.props;
        const { hasFocus, shouldClearInput } = this.state;
        const isShowingRecentSearches = hasFocus && shouldClearInput;
        const isRecentSearch = item.get('recentSearch');
        const shouldChange =
          !(isShowingRecentSearches && action === 'clicked-outside') &&
          (this.props.value !== this.state.value || action === 'clicked-result' || 'tab-pressed');

        const stateChange = {
          selectedIndex: 0,
          results: [],
          shouldClearInput: false,
          hasFocus: false,
          noResults: false,
        };

        if (shouldChange) {
          // If the autocmpleter is showing recent searches results we update the entire search form.
          if (isRecentSearch) {
            // Tracks if user clicks on recent search
            trackRecentSearch();
            setValuesFromRecentSearch(item);
            if (onSelectRecentSearch) {
              onSelectRecentSearch();
            }
          } else {
            // Otherwise we are only updating the value for this specific autocompleter.
            stateChange.value = value;
            if (onSelect) {
              onSelect(item, e, action);
            }
          }
        }
        this.setState(stateChange);
      };

      onKeyDown = e => {
        const { onKeyDown, setValuesFromRecentSearch } = this.props;
        const { results, selectedIndex, hasFocus, shouldClearInput } = this.state;
        const isShowingRecentSearches = hasFocus && shouldClearInput;

        if (e.keyCode === keyCodes.TAB) {
          this.onClose(e);
        }

        if (results.length === 0) return;

        if (e.keyCode === keyCodes.ARROW_DOWN || e.keyCode === keyCodes.TAB) {
          this.setState({ shouldClearInput: false });
        }

        if (e.keyCode === keyCodes.ENTER) {
          e.preventDefault();

          if (!e.persist) {
            e.persist = () => {};
          }

          e.persist();
          this.onSelect(results[selectedIndex], e);
        } else if (e.keyCode === keyCodes.TAB) {
          this.onSelect(results[selectedIndex], e, 'tab-pressed');
        } else if (e.keyCode === keyCodes.ARROW_UP) {
          this.changeSelectedIndex(-1, e);

          if (isShowingRecentSearches && results[selectedIndex - 1]) {
            setValuesFromRecentSearch(results[selectedIndex - 1].item);
          }
        } else if (e.keyCode === keyCodes.ARROW_DOWN) {
          this.changeSelectedIndex(1, e);

          if (isShowingRecentSearches && results[selectedIndex + 1]) {
            setValuesFromRecentSearch(results[selectedIndex + 1].item);
          }
        }

        if (onKeyDown) {
          onKeyDown(e);
        }
      };

      changeSelectedIndex(diff, e) {
        let { selectedIndex, results } = this.state;

        if (results.length) {
          e.preventDefault();
          selectedIndex = (selectedIndex + diff + results.length) % results.length;
          this.setState({ selectedIndex, value: results[selectedIndex].value });
        }
      }

      render() {
        const props = Object.assign({}, this.props);
        props[passAsProp] = this.getPropsToPass();

        return <WrappedComponent {...props} />;
      }
    }

    ConnectAutocomplete.defaultProps = {
      minLength: 2,
      delay: 300,
    };

    ConnectAutocomplete.propTypes = {
      source: PropTypes.func.isRequired,
      onSelect: PropTypes.func.isRequired,
    };

    function mapDispatchToProps(dispatch) {
      return {
        setValuesFromRecentSearch: recentSearch =>
          dispatch(setValuesFromRecentSearchAction(recentSearch)),
      };
    }

    return withRouter(
      connect(
        null,
        mapDispatchToProps
      )(ConnectAutocomplete)
    );
  };
}

export default connectAutocomplete;
