import Immutable from 'immutable';
import omit from 'lodash/omit';
import time from 'dohop-client-api/src/time';
import isEqualAirportCodes from '../lib/isEqualAirportCodes';
import {
  FLIGHT_SEARCH_SET_SEARCH,
  FLIGHT_SEARCH_CHEAPEST_ITINERARY,
} from '../actions/flightSearchActions';
import {
  FETCH_DATE_MATRIX_STARTING,
  FETCH_DATE_MATRIX_SUCCESS,
  FETCH_DATE_MATRIX_FAILED,
  DATE_MATRIX_EXPAND,
  DATE_MATRIX_COLLAPSE,
} from '../actions/dateMatrixActions';

// show plus/minus this many days from search date
export const DATEMATRIX_DELTA = 3;

const DEFAULT_STATE = Immutable.Map({
  fares: Immutable.Map({}),
  isActive: false, // don't show for multiple passenger searches
  isExpanded: false,
});

function isValidSearch(search) {
  return search.getMeta().getNumPassengers() === 1;
}

function processLivestoreResponse(response, fares) {
  return fares.withMutations((map) => {
    let seen = {};
    response.fares.forEach((f) => {
      if (Array.isArray(f)) {
        // merge split ticket
        f[0].conv_fare += f[1].conv_fare;

        if (f[1]) {
          f[0].d2 = f[1].d1;
        }

        f[0].age = Math.max(f[0].age, f[1].age);
        f = f[0];
      }
      let k = [f.d1, f.d2].filter(Boolean).join('*');

      if (!seen[k] && map.getIn([k, 'source']) !== 'search') {
        seen[k] = true;
        map.set(
          k,
          Immutable.Map({
            source: 'livestore',
            fare: f.conv_fare,
            age: f.age * time.minute,
            d1: time.strptime('%Y-%m-%d', f.d1),
            d2: f.d2 && time.strptime('%Y-%m-%d', f.d2),
          })
        );
      }
    });
  });
}

function locationChanged(search, state) {
  let meta = search.getMeta();
  return (
    !isEqualAirportCodes(meta.getFrom().join(','), state.get('origin')) ||
    !isEqualAirportCodes(meta.getTo().join(','), state.get('destination'))
  );
}

function nWayChanged(search, state) {
  let meta = search.getMeta();
  return state.get('oneWay') !== meta.oneway;
}

function findCheapest(state) {
  let { fares, d1Min, d1Max, d2Min, d2Max, oneWay } = state.toObject();
  return fares
    .valueSeq()
    .filter(
      (f) =>
        d1Min.getTime() <= f.get('d1').getTime() &&
        f.get('d1').getTime() <= d1Max.getTime() &&
        (oneWay ||
          (d2Min.getTime() <= f.get('d2').getTime() && f.get('d2').getTime() <= d2Max.getTime()))
    )
    .map((f) => f.get('fare'))
    .min();
}

export default function dateMatrixReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FLIGHT_SEARCH_SET_SEARCH:
      let isActive = isValidSearch(action.search);
      if (!isActive || locationChanged(action.search, state) || nWayChanged(action.search, state)) {
        // reset when a different search is made
        state = DEFAULT_STATE;
      }

      if (isActive) {
        let meta = action.search.getMeta();
        state = state.merge({
          isActive,
          isExpanded: false, // always collapse on new search

          oneWay: meta.isOneWay(), // true if oneway search
          origin: meta.getFrom().join(','),
          destination: meta.getTo().join(','),
          d1: meta.getOutboundDate(),
          d2: !meta.isOneWay() && meta.getHomeboundDate(),

          d1Min: new Date(meta.getOutboundDate().getTime() - time.day * DATEMATRIX_DELTA),
          d1Max: new Date(meta.getOutboundDate().getTime() + time.day * DATEMATRIX_DELTA),

          d2Min:
            !meta.isOneWay() &&
            new Date(meta.getHomeboundDate().getTime() - time.day * DATEMATRIX_DELTA),
          d2Max:
            !meta.isOneWay() &&
            new Date(meta.getHomeboundDate().getTime() + time.day * DATEMATRIX_DELTA),
        });
      }
      return state;
    case FLIGHT_SEARCH_CHEAPEST_ITINERARY:
      // don't save fare if we don't show the matrix for this search
      if (!state.get('isActive')) return state;
      // price from full flight search overrides prices from livestore
      let [d1, d2] = action.itinerary.getRoutes().map((r) => r.getDeparture());
      let dateKey = [d1, d2]
        .filter(Boolean)
        .map((d) => time.strftime('%Y-%m-%d', d))
        .join('*');
      state = state.setIn(
        ['fares', dateKey],
        Immutable.Map({
          source: 'search',
          fare: action.itinerary.getFares().getBestFare().getTotal(),
          age: 0,
          d1,
          d2,
        })
      );
      return state.set('cheapest', findCheapest(state));
    case FETCH_DATE_MATRIX_STARTING:
      return state.delete('error').merge({
        isFetching: true,
      });
    case FETCH_DATE_MATRIX_SUCCESS:
      state = state.set('fares', processLivestoreResponse(action.response, state.get('fares')));
      return state.merge({
        isFetching: false,
        cheapest: findCheapest(state),
      });
    case FETCH_DATE_MATRIX_FAILED:
      return state.merge({
        isFetching: false,
        error: omit(action.error, 'response.request'),
      });
    case DATE_MATRIX_EXPAND:
      return state.set('isExpanded', true);
    case DATE_MATRIX_COLLAPSE:
      return state.set('isExpanded', false);
    default:
      return state;
  }
}
