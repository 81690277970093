import Bugsnag from './bugsnag';

export default function processException(error) {
  const isProd = process.env.NODE_ENV === 'production';

  try {
    if (!(error instanceof Error)) {
      error = new Error(error);
    }

    if (isProd) {
      if (error?.config?.url) {
        Bugsnag.leaveBreadcrumb('request failed', { url: error.config.url });
      }
      Bugsnag.notify(error);
    } else {
      /* eslint-disable-next-line no-console */
      console.error(error);
    }
  } catch (err) {
    err.message = `Inner processException: ${err.message}`;

    if (!isProd) {
      /* eslint-disable-next-line no-console */
      console.error(err);
    } else {
      Bugsnag.notify(error);
    }
  }
}
