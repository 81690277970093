/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _last = require('lodash/last');
const _map = require('lodash/map');
const _zip = require('lodash/zip');
const _every = require('lodash/every');
const _some = require('lodash/some');
const _flatten = require('lodash/flatten');
const _find = require('lodash/find');
const _uniq = require('lodash/uniq');
const _uniqBy = require('lodash/uniqBy');
const _reduce = require('lodash/reduce');
const _compact = require('lodash/compact');
const time = require('./time');
const util = require('./util');

// Transport code used for trains
const TRAIN_CODE = 'TRN';

const VENDORS_WITH_BUS_TICKET_INCLUDED = ['DBT'];

// Seperator to join unique flight keys to create a unique key for a route
const FLIGHT_SEPERATOR = '*';

// Seperator to join unique route keys to create a unique key for an itinerary
const ROUTE_SEPERATOR = '$';

// Minimum stay at destination before first return flight departs
const MINIMUM_DESTINATION_STAY = time.hour;

// Short transfer time for self-connect flights
const SHORT_TRANSFERTIME = time.hour * 2.5;

const isFareType = (maybeType, type) => !!maybeType && maybeType[type];

// A transport
class Transport {
  // @nodoc
  constructor(_code, { name }) {
    this._code = _code;
    this._name = name;
  }

  // @return [String] A unique key for this type of transport
  getCode() {
    return this._code;
  }

  // @return [String] The name of this transport
  getName() {
    return this._name;
  }
}

// A Carrier
class Carrier {
  // @nodoc
  constructor(_code, { code, name, www }) {
    this._code = _code;
    this._iata = code;
    this._name = name;
    this._www = www;
  }

  // @return [String] A unique code for this carrier
  getCode() {
    return this._code;
  }

  // @return [String] IATA code of this carrier
  getIATACode() {
    return this._iata;
  }

  // @return [String] Name of this carrier
  getName() {
    return this._name;
  }

  // @return [String] website url of this carrier
  getWebsiteURL() {
    return this._www;
  }
}

// A station
class Station {
  // @nodoc
  constructor(_code, { name, city, continent, country, region, code, transport_type }) {
    this._code = _code;
    this._city = city;
    this._continent = continent;
    this._country = country;
    this._name = name;
    this._region = region;
    this._city_iata = code;
    this._transport_type = transport_type;
  }

  /**
   *
   * @returns {string} A unique 3-letter station code of this station
   */
  getCode() {
    return this._code;
  }

  /**
   *
   * @returns {string} The name of this carrier
   */
  getName() {
    return this._name;
  }

  /**
   *
   * @returns {string} The name of the city where this station is located
   */
  getCityName() {
    return this._city;
  }

  /**
   *
   * @returns {string} The name of the continent where this station is located
   */
  getContinentName() {
    return this._continent;
  }

  /**
   *
   * @returns {string} The name of the country where this station is located
   */
  getCountryName() {
    return this._country;
  }

  /**
   *
   * @returns {string} A region name, if any, where this station is located
   */
  getRegionName() {
    return this._region;
  }

  /**
   *
   * @returns {string} A city IATA code if any otherwise we default to the aiport code
   */
  getCityIATA() {
    return this.city_iata;
  }

  /**
   *
   * @returns {string} The transport type to or from a given station
   */
  getTransportType() {
    return this._transport_type;
  }
}

// A single leg. A single flight has 1 or more legs.
class Leg {
  constructor(
    _airline,
    _flightnumber,
    _origin,
    _destination,
    _departure,
    _arrival,
    _duration,
    _operatedBy,
    _aircraft
  ) {
    this._airline = _airline;
    this._flightnumber = _flightnumber;
    this._origin = _origin;
    this._destination = _destination;
    this._departure = _departure;
    this._arrival = _arrival;
    this._duration = _duration;
    this._operatedBy = _operatedBy;
    this._aircraft = _aircraft;
  }

  getKey() {
    return this.__getKey != null
      ? this.__getKey
      : (this.__getKey = `${
          this._flightnumber
        }-${this._origin.getCode()}-${this._destination.getCode()}-${time.strftime(
          '%m-%d',
          this._departure
        )}`);
  }
  getAirline() {
    return this._airline;
  }
  getOperatingAirline() {
    return this._operatedBy;
  }
  isCodeshare() {
    return this._airline.getCode() !== this._operatedBy.getCode();
  }
  getFlightNumber() {
    return this._flightnumber;
  }
  getOrigin() {
    return this._origin;
  }
  getDestination() {
    return this._destination;
  }
  getDeparture() {
    return this._departure;
  }
  getArrival() {
    return this._arrival;
  }
  getDuration() {
    return this._duration;
  }
  getAircraft() {
    return this._aircraft;
  }
  isTrainLeg() {
    return this._aircraft.getCode().split('_')[0] === TRAIN_CODE;
  }
  // Get the train number by formatting together the transport code and flight number fields
  // This is needed because of backend complexities.
  // Example:
  // Transport code is TRN_ICE
  // Flight number is 2A800
  // We use whatever comes after TRN_ in the transport code and whatever comes after 2A
  // in the flight number and we return ICE800
  // @returns [string]
  getTrainNumber() {
    return `${this._aircraft.getCode().substring(4)} ${this._flightnumber.substring(2)}`;
  }
}

// A Flight
class Flight {
  // @nodoc
  constructor(_id, _legs, duration) {
    this._id = _id;
    this._legs = _legs;
    if (duration != null) {
      util.assert(duration === this.getDuration());
    }
  }

  // @return [Integer] A unique id for this flight in the scope of a single search
  getId() {
    return this._id;
  }

  // @return [String] A unique key to identify this flight
  getKey(includeAllLegs) {
    if (includeAllLegs == null) {
      includeAllLegs = true;
    }
    if (includeAllLegs) {
      return this.__getKey_legs != null
        ? this.__getKey_legs
        : (this.__getKey_legs = Array.from(this._legs)
            .map((leg) => leg.getKey())
            .join(FLIGHT_SEPERATOR));
    } else {
      return this.__getKey_flight != null
        ? this.__getKey_flight
        : (this.__getKey_flight = `${this.getFlightNumber()}-${this.getOrigin().getCode()}-${this.getDestination().getCode()}-${time.strftime(
            '%m-%d',
            this.getDeparture()
          )}`);
    }
  }

  /**
   *
   * @returns {Leg[]} Legs covered by this flight
   */
  getLegs() {
    return this._legs;
  }

  // @return [Carrier] carrier that sells (and usually operates) this flight
  getAirline() {
    return this._legs[0].getAirline();
  }

  // @return [Transport] The transport type for this flight
  getAircraft() {
    return this._legs[0].getAircraft();
  }

  // @return [Carrier] Operating carrier
  // @see http://en.wikipedia.org/wiki/Codeshare_agreement
  getOperatingAirline() {
    return this._legs[0].getOperatingAirline();
  }

  // @return [String] Flight number of this search
  getFlightNumber() {
    return this._legs[0].getFlightNumber();
  }

  // @return [Station] Origin station of this flight
  getOrigin() {
    return this._legs[0].getOrigin();
  }

  // @return [Station] Destination station of this flight
  getDestination() {
    return _last(this._legs).getDestination();
  }

  // @return [Date] the departure date of this flight, local time
  // @note Because of javascipt's poor timezone support, the date object returned is a UTC date.
  //   So to get the actual local time, you will need to call date.getUTC* methods.
  getDeparture() {
    return this._legs[0].getDeparture();
  }

  // @return [Date] the arrival date of this search, local time
  // @note see getDeparture
  getArrival() {
    return _last(this._legs).getArrival();
  }

  // @return [Integer] duration of this flight in milliseconds
  getDuration() {
    return this.__getDuration != null
      ? this.__getDuration
      : (this.__getDuration = (() => {
          return (
            util.sum(this._legs, (leg) => leg.getDuration()) +
            util.sum(this.getStopOvers(), (stop) => stop.getDuration())
          );
        })());
  }

  /**
   *
   * @returns {StopOver[]}
   */
  getStopOvers() {
    return this.__getStopOvers != null
      ? this.__getStopOvers
      : (this.__getStopOvers = _map(_zip(this._legs.slice(0, -1), this._legs.slice(1)), function (
          ...args
        ) {
          const [a, b] = Array.from(args[0]);
          return new StopOver(a.getDestination(), b.getOrigin(), a.getArrival(), b.getDeparture());
        }));
  }

  // @return [Integer] number of stops on this flight
  getNumStops() {
    return this._legs.length - 1;
  }

  // @return [Boolean] If this flight is redeye or not. A flight is redeye if it arrives day/days later than it departs
  isRedEye() {
    return this.getDeparture().getUTCDate() !== this.getArrival().getUTCDate();
  }

  // @return [Boolean] If this flight is codeshare or not
  isCodeshare() {
    return _some(this._legs, (leg) => leg.isCodeshare());
  }

  // @return [Array<Station>] origin and destination station for this flight
  getAirports() {
    return this.__getAirports != null
      ? this.__getAirports
      : (this.__getAirports = [this._legs[0].getOrigin(), _last(this._legs).getDestination()]);
  }

  // @return [Array<Station>] origin, stopover and destination station for this flight
  getAllAirports() {
    return this.__getAllAirports != null
      ? this.__getAllAirports
      : (this.__getAllAirports = (() => {
          const airportStops = _flatten(
            Array.from(this.getStopOvers()).map((stop) => stop.getAirports())
          );
          return util.concat([this.getOrigin()], airportStops, [this.getDestination()]);
        })());
  }

  isTrainLeg() {
    return this._legs[0].isTrainLeg();
  }
}

// A stopover between flights
class StopOver {
  // @nodoc
  constructor(_a, _b, _start, _end, _groundTransit) {
    this._a = _a;
    this._b = _b;
    this._start = _start;
    this._end = _end;
    if (_groundTransit == null) {
      _groundTransit = 0;
    }
    this._groundTransit = _groundTransit;
  }

  /**
   *
   * @returns {Station} the station where the first flight arrives at
   */
  getOrigin() {
    return this._a;
  }

  /**
   *
   * @returns {Station} the station where the second flight departs from. Is not necessarily the same as the origin airport
   */
  getDestination() {
    return this._b;
  }

  // @return [Date] When the stopover begins / when the first flight arrives
  getStartDate() {
    return this._start;
  }

  // @return [Date] When the stopover ends / when the second flight departs
  getEndDate() {
    return this._end;
  }

  // @return [Integer] duration of this stopover in milliseconds
  getDuration() {
    return this._end.getTime() - this._start.getTime();
  }

  // @return [Integer] estimated ground transit time in milliseconds. Always returns zero if {StopOver#isGroundTransit} is false
  getGroundTransitDuration() {
    return this._groundTransit;
  }

  // @return [Array<Station>] A list of stations this stopover covers
  getAirports() {
    const stations = [this._a];
    if (this._a.getCode() !== this._b.getCode()) {
      stations.push(this._b);
    }
    return stations;
  }

  // @return [Boolean] if this stopover is ground transit or not. Ground transit is if person has to get from airport A
  //   to station B to catch the connecting flight
  isGroundTransit() {
    return this._a.getCode() !== this._b.getCode();
  }
}

// A vendor that sells flights
class Vendor {
  // @nodoc
  constructor(_key, { n }) {
    this._key = _key;
    this._name = n;
  }

  // @return [String] a unique key for this vendor
  getKey() {
    return this._key;
  }

  // To provide the same interface as Station and Carrier:
  // @return [String] a unique "code" for this vendor
  getCode() {
    return this._key;
  }

  // @return [String] the name of this vendor
  getName() {
    return this._name;
  }
}

// Datastructure around fares lookes like the following:
//
// FareCollection
//     * Keeps a list of possible combinations that cover all flights in an itinerary
//     [ Fare | MultiFare ]
//
// MultiFare
//     * a list of fares that cover all flights in an itinerary
//     [ Fare ]
//
// Fare
//     * Fare is a single ticket that can either cover all flights in an itinerary or
//     * only a subset of the flights
//     [ VendorFare ]
//     outbound [Flight]
//     homebound [Flight]
//
// VendorFare
//     * this is the actual price and the vendor that a Fare covers
//     total
//     vendor
//     date found

// FareCollection keeps a list of possible componations of fares that cover a certain itinerary
class FareCollection {
  constructor(_fares) {
    this._fares = _fares;
  }

  /**
   * a list of fares where each fare covers an entire itinerary, sorted by fare
   * @returns {(Fare | MultiFare)[]}
   */
  getFares() {
    return this.__getFares != null
      ? this.__getFares
      : (this.__getFares = util.sort(this._fares, function (a, b) {
          // If price is the same for single- and multi-tickets, we want single ticket to come first. But we can't
          // simply do direct comparison of the total price because multi-ticket prices are the sum of multiple
          // single-tickets, and because of imprecise floating point calculations the multi-ticket could be a tiny
          // fraction lower than the single-ticket price
          if (Math.abs(a.getTotal() - b.getTotal()) < 0.001) {
            return a.size() - b.size();
          }
          return a.getTotal() - b.getTotal();
        }));
  }

  // @return [Fare|MultiFare] the best/cheapest fare for the itinerary
  getBestFare() {
    return this.getFares()[0];
  }

  // @return [Fare] get a single ticket fare if there is one for the itinerary
  getSingleTicket() {
    return this.__getSingleTicket != null
      ? this.__getSingleTicket
      : (this.__getSingleTicket = _find(this.getFares(), (f) => !f.isMulti()));
  }
}

const MULTI_FARE_SENTINEL = '@@__MULTI_FARE__@@';
// MultiFare is a collection fare {Fare}s and implements mostly the same interface as a {Fare}
class MultiFare {
  constructor(_fares) {
    this._fares = _fares;
    util.assert(this._fares.length >= 2);
  }

  // @return [Array<Fare>] a list of fares this multifare covers
  getFares() {
    return this._fares;
  }

  // @return [Integer] number of fares this fare covers
  size() {
    return this._fares.length;
  }

  // to destinquish between Fare and MultiFare objects
  // @return [true] always returns true
  isMulti() {
    return true;
  }

  // @return total price of all the fares
  getTotal() {
    return this.__getTotal != null
      ? this.__getTotal
      : (this.__getTotal = util.sum(this._fares, (f) => f.getTotal()));
  }

  // @return [Number] average price per passenger
  getAverage() {
    return this.__getAverage != null
      ? this.__getAverage
      : (this.__getAverage = util.sum(this._fares, (f) => f.getAverage()));
  }

  // @param [Flight] flight Flight to check for
  // @return [Boolean] does this multifare contain the given flight
  hasFlight(flight) {
    return _some(this._fares, (fare) => fare.hasFlight(flight));
  }

  // given 2 connected flights, checks if it is Self-Connect given the fares this multifare contains
  // @param [Flight] flightA the first flight
  // @param [Flight] flightB the second connected flight
  isSelfConnectConnection(flightA, flightB) {
    for (let fare of Array.from(this._fares)) {
      if (fare.hasFlight(flightB)) {
        return !fare.hasFlight(flightA);
      }
    }
    return false;
  }

  // combines this fare with another fare in a new MultiFare
  // @private
  combine(other) {
    return new MultiFare(this._fares.concat(other.getFares()));
  }

  // @return [Boolean] If we have a price for all the fares
  isComplete() {
    return this.__isComplete != null
      ? this.__isComplete
      : (this.__isComplete = _every(this._fares, (fare) => fare.isComplete()));
  }

  // @return [Boolean] if any flight this multifare covers is codeshare
  isCodeshare() {
    return this.__isCodeshare != null
      ? this.__isCodeshare
      : (this.__isCodeshare = _some(this._fares, (f) => f.isCodeshare()));
  }

  // @param [Object] origin station set: {airportcode: true} e.g. {'LHR': true, 'STN': true}
  // @param [Object] destination station set. Same format as origin
  // @return [Boolean] is this fare Self-Connect for the given origin and destination
  isSelfConnect(origin, destination) {
    return (
      this._fares.length > 2 ||
      _some(this._fares, (fare) => fare.isSelfConnect(origin, destination))
    );
  }
}

MultiFare.prototype[MULTI_FARE_SENTINEL] = true;
MultiFare.isMultiFare = (maybeMultifare) => isFareType(maybeMultifare, MULTI_FARE_SENTINEL);

const FARE_SENTINEL = '@@__FARE__@@';
// A single fare / single ticket
class Fare {
  // @nodoc
  constructor(_id, _vendorFares, _outboundLegs, _homeboundLegs, _version, sorted) {
    this._id = _id;
    this._vendorFares = _vendorFares;
    this._outboundLegs = _outboundLegs;
    this._homeboundLegs = _homeboundLegs;
    this._version = _version;
    if (sorted == null) {
      sorted = false;
    }
    if (!sorted) {
      this._vendorFares = util.sortBy(this._vendorFares, (f) => f.getTotal());
    }
  }

  // @return [Integer] unique id within the scope of a single search
  getId() {
    return this._id;
  }

  // @return [Integer] version created of this fare, incremented every time we receive new vendor-fares
  getVersion() {
    return this._version;
  }

  // @return [Integer] number of fares this fare covers
  size() {
    return 1;
  }

  // @return [Array<Fare>] always return [this] to provide the same interface as MultiFare
  getFares() {
    return [this];
  }

  // @return [Array<VendorFare>] Vendor fares that we have price for, unique by name
  getVendorFares() {
    return this.__getVendorFares != null
      ? this.__getVendorFares
      : (this.__getVendorFares = _uniqBy(this._vendorFares, (vf) => vf.getVendor().getName()));
  }

  /**
   * All vendor fares we have, including duplicate names
   * @returns {VendorFare[]}
   */
  getAllVendorFares() {
    return this._vendorFares;
  }

  // @return [VendorFare] The best/cheapest vendor fare we have
  getVendorFare() {
    return this._vendorFares[0];
  }

  // @return [Array<Leg>] A list of outbound Legs this fare covers
  getOutboundLegs() {
    return this._outboundLegs;
  }

  // @return [Array<Leg>] A list of homebound flights this fare covers
  getHomeboundLegs() {
    return this._homeboundLegs;
  }

  getLegs() {
    return this.__legs != null
      ? this.__legs
      : (this.__legs = this._outboundLegs.concat(this._homeboundLegs));
  }

  // @return [false] always returns false
  isMulti() {
    return false;
  }

  // @return [Number] The total price of the cheapest vendor
  getTotal() {
    return this._vendorFares[0] != null ? this._vendorFares[0].getTotal() : null;
  }

  // @return [Number] average price per passenger
  getAverage() {
    return this._vendorFares[0] != null ? this._vendorFares[0].getAverage() : null;
  }

  // @param [Flight] flight Flight to check for
  // @return [Boolean] if this fare covers the given flight
  hasFlight(flight) {
    const legSet = this._getLegSet();
    return _every(flight.getLegs(), (leg) => legSet[leg.getKey()] === true);
  }

  _getLegSet() {
    return this.__getLegSet != null
      ? this.__getLegSet
      : (this.__getLegSet = (() => {
          const set = {};
          for (let legList of [this._outboundLegs, this._homeboundLegs]) {
            for (let leg of Array.from(legList)) {
              set[leg.getKey()] = true;
            }
          }
          return set;
        })());
  }

  // to prive the same interface as MultiFare
  // @return [false] always returns false since flights within a single fare are never self-connect
  isSelfConnectConnection(flightA, flightB) {
    return false;
  }

  // @private
  combine(other) {
    return new MultiFare([this].concat(other.getFares()));
  }

  // @return [Boolean] if we have a price for this fare
  isComplete() {
    return this._vendorFares.length > 0;
  }

  // @return [Boolean] if any flight covered by this fare is codeshare
  isCodeshare() {
    return (
      _some(this._outboundLegs, (l) => l.isCodeshare()) ||
      _some(this._homeboundLegs, (l) => l.isCodeshare())
    );
  }

  _coversAllOrNone(orig, dest, legs) {
    return (
      legs.length === 0 ||
      (orig[legs[0].getOrigin().getCode()] && dest[_last(legs).getDestination().getCode()])
    );
  }

  // Identical to {MultiFare#isSelfConnect}
  // @return [Boolean]
  isSelfConnect(origin, destination) {
    return (
      (!this._coversAllOrNone(origin, destination, this._outboundLegs) &&
        !this._coversAllOrNone(destination, origin, this._outboundLegs)) ||
      !this._coversAllOrNone(destination, origin, this._homeboundLegs)
    );
  }

  // @return [Array<Carrier>] a list of airlines this fare covers
  getAirlines() {
    return this.__getAirlines != null
      ? this.__getAirlines
      : (this.__getAirlines = _uniq(Array.from(this.getLegs()).map((leg) => leg.getAirline())));
  }

  // @return [Array<Transport>] a list of aircrafts this fare covers
  getAircrafts() {
    return this.__getAircrafts != null
      ? this.__getAircrafts
      : (this.__getAircrafts = _uniq(Array.from(this.getLegs()).map((leg) => leg.getAircraft())));
  }
}

Fare.prototype[FARE_SENTINEL] = true;
Fare.isFare = (maybeFare) => isFareType(maybeFare, FARE_SENTINEL);

const VENDOR_FARE_SENTINEL = '@@__VENDOR_FARE__@@';
// VendorFare is a vendor and the actual fare it offers
class VendorFare {
  // @nodoc
  constructor(_fareId, _currency, _age, _total, _vendor, _numPassengers) {
    this._fareId = _fareId;
    this._currency = _currency;
    this._age = _age;
    this._total = _total;
    this._vendor = _vendor;
    if (_numPassengers == null) {
      _numPassengers = 1;
    }
    this._numPassengers = _numPassengers;
  }

  // @return [String] a unique key of this vendorfare in the scope of a single search
  getKey() {
    return `${this._fareId}-${this._vendor.getKey()}`;
  }

  // @return [Integer] The id of the fare this vendorfare belongs to
  getFareId() {
    return this._fareId;
  }

  // @return [Vendor] the vendor that offers this price
  getVendor() {
    return this._vendor;
  }

  // @return [String] the currency the fare amount is stored in
  getCurrency() {
    return this._currency;
  }

  // @return [Number] the total amount for this flight
  getTotal() {
    return this._total;
  }

  // @return [Number] average price per passenger
  getAverage() {
    return this._total / this._numPassengers;
  }

  // @return [Integer] the age in milliseconds since the bots found this price
  getAge() {
    return this._age;
  }

  // @return [false] always return false
  isMulti() {
    return false;
  }
}

VendorFare.prototype[VENDOR_FARE_SENTINEL] = true;
VendorFare.isVendorFare = (maybeVendorFare) => isFareType(maybeVendorFare, VENDOR_FARE_SENTINEL);
// A route is a collection of flight, either outbound or homebound
class Route {
  constructor(_flights, _stops, { id, outbound }) {
    this._flights = _flights;
    this._stops = _stops;
    this._id = id;
    this._outbound = outbound;
  }

  /**
   *
   * @returns {number} Unique id for this route within the scope of a single search
   */
  getId() {
    return this._id;
  }

  // @return [Array<Flight>] a list of flight this route contains
  /**
   *
   * @returns {Flight[]}
   */
  getFlights() {
    return this._flights;
  }

  // @return [Array<Leg>] a list of all legs covered by this itinerary
  getLegs() {
    return this.__getLegs != null
      ? this.__getLegs
      : (this.__getLegs = _reduce(
          this.getFlights(),
          (legs, flight) => util.concat(legs, flight.getLegs()),
          []
        ));
  }

  // @return [Station] the origin station of the first result
  getOrigin() {
    return this._flights[0].getOrigin();
  }

  // @return [Station] the destination airport of the last result
  getDestination() {
    return _last(this._flights).getDestination();
  }

  // @return [Date] the departure date of the first result
  getDeparture() {
    return this._flights[0].getDeparture();
  }

  // @return [Date] the arrival date of the last result
  getArrival() {
    return _last(this._flights).getArrival();
  }

  // @return [Boolean] if this route is outbound
  isOutbound() {
    return this._outbound;
  }

  // @return [Boolean] the opposite if isOutbound
  isHomebound() {
    return !this._outbound;
  }

  // @return [String] A unique key for this route
  getKey(includeAllLegs) {
    return this.__getKey != null
      ? this.__getKey
      : (this.__getKey = Array.from(this._flights)
          .map((f) => f.getKey(includeAllLegs))
          .join(FLIGHT_SEPERATOR));
  }

  // @return [Boolean] If this route contains a red eye flight
  isRedEye() {
    return this.__isRedEye != null
      ? this.__isRedEye
      : (this.__isRedEye = _some(this._flights, (f) => f.isRedEye()));
  }

  // @return [Boolean] If this route contains a over night stopover
  isOverNight() {
    return this.__isOverNight != null
      ? this.__isOverNight
      : (this.__isOverNight = _some(__range__(1, this._flights.length, false), (i) => {
          return (
            this._flights[i - 1].getArrival().getUTCDate() !==
            this._flights[i].getDeparture().getUTCDate()
          );
        }));
  }

  // @return [Array<Carrier>] a list of all airlines contained in this route
  getAirlines() {
    return this.__getAirlines != null
      ? this.__getAirlines
      : (this.__getAirlines = _uniq(
          _flatten(Array.from(this.getFlights()).map((f) => f.getAirline()))
        ));
  }

  // @return [Array<Transport>] a list of all aircrafts contained in this route
  getAircrafts() {
    return this.__getAircrafts != null
      ? this.__getAircrafts
      : (this.__getAircrafts = _uniq(
          _flatten(Array.from(this.getFlights()).map((f) => f.getAircraft()))
        ));
  }

  // Ground transit is when a flight arrives at one airport but next connecting flight departs from another
  // airport. The user is usually responsible himself for making the connection.
  // @return [Boolean] if this route contains ground transit stopover
  isGroundTransit() {
    return this.__isGroundTransit != null
      ? this.__isGroundTransit
      : (this.__isGroundTransit = _some(__range__(1, this._flights.length, false), (i) => {
          return (
            this._flights[i - 1].getDestination().getCode() !==
            this._flights[i].getOrigin().getCode()
          );
        }));
  }

  // @return [Integer] duration in milliseconds between the first flight's departure and the last flight's arrival
  getDuration() {
    // depart/arrival dates are all local time so we can't simply calculate duration by subtraction.
    // We must sum up all flight times and the duration between every flights arrival time and the next
    // flights departure time, which are always in the same time zone
    return this.__getDuration != null
      ? this.__getDuration
      : (this.__getDuration = util.sum(
          Array.from(this.getFlightplan()).map((item) => item.getDuration())
        ));
  }

  // @return [Boolean] If this route contains codeshare flights
  isCodeshare() {
    return this.__isCodshare != null
      ? this.__isCodshare
      : (this.__isCodshare = _some(this._flights, (f) => f.isCodeshare()));
  }

  // @return [Array<Flight|StopOver>] a list of flight and stopovers. Even indexes are flights with stopovers in between ([Flight, StopOver, Flight, ...]
  getFlightplan() {
    return this.__getFlightplan != null
      ? this.__getFlightplan
      : (this.__getFlightplan = (() => {
          const flightplan = [];
          for (let i = 0; i < this._flights.length; i++) {
            const flight = this._flights[i];
            flightplan.push(flight);
            if (this._stops[i]) {
              flightplan.push(this._stops[i]);
            }
          }
          return flightplan;
        })());
  }

  /**
   *
   * @param {boolean} [includeSameFlightTransits]
   * @returns {StopOver[]}
   */
  getStopOvers(includeSameFlightTransits) {
    if (!includeSameFlightTransits) {
      return this._stops;
    } else {
      return this.__getStopOvers != null
        ? this.__getStopOvers
        : (this.__getStopOvers = (() => {
            const stops = [];
            for (let i = 0; i < this._flights.length; i++) {
              const flight = this._flights[i];
              util.concat(stops, flight.getStopOvers());
              if (this._stops[i]) {
                stops.push(this._stops[i]);
              }
            }
            return stops;
          })());
    }
  }

  // @param [Boolean] includeSameFlightTransits If stopovers with single flights should be included or not.
  // @return [Integer] Number of stops on this route
  getNumStops(includeSameFlightTransits) {
    let stops = this._stops.length;
    if (includeSameFlightTransits) {
      stops +=
        this.__getNumStops_flights != null
          ? this.__getNumStops_flights
          : (this.__getNumStops_flights = util.sum(
              Array.from(this._flights).map((f) => f.getNumStops())
            ));
    }
    return stops;
  }

  // @param [Route] route Another route to check
  // @return [Boolean] if this route and the given route are valid to chain in an itinerary
  isValidPair(other) {
    return other.getDeparture().getTime() - this.getArrival().getTime() >= MINIMUM_DESTINATION_STAY;
  }

  // Check if a route has a train leg in it.
  // This function is for the search page, there is another hasTrainLeg
  // in web_booking with a similar function but takes in a flight instead of the whole route
  // @returns [boolean]
  //
  hasTrainLeg() {
    return this.getFlights().some((flight) => flight.isTrainLeg());
  }

  // Check if bus ticket is included in train ticket.
  // This function is for the search page, there is another isTransitBetweenHubsIncludedInTicket
  // in web_booking with the same function.
  // @returns [boolean]
  isTransitBetweenHubsIncludedInTicket() {
    return _some(this.getLegs(), (l) =>
      VENDORS_WITH_BUS_TICKET_INCLUDED.includes(l.getAirline().getCode())
    );
  }
}

class Itinerary {
  constructor(_routes, _fares) {
    this._routes = _routes;
    this._fares = _fares;
    util.assert(this._fares instanceof FareCollection);
    this._departure = this._routes[0].departure;
  }

  /**
   * a list of routes. Currently always of size 1 or 2 (outbound and homebound)
   * @returns {Route[]}
   */
  getRoutes() {
    return this._routes;
  }

  /**
   * a FareCollection instance with all possible fare options for this itinerary
   * @returns {FareCollection}
   */
  getFares() {
    return this._fares;
  }

  // @return [Date] Outbound departure date of this itinerary
  getDeparture() {
    return this._routes[0].getDeparture();
  }

  // @return [String] A unique key for this itinerary
  getKey(includeAllLegs) {
    return this.__getKey != null
      ? this.__getKey
      : (this.__getKey = Array.from(this._routes)
          .map((r) => r.getKey(includeAllLegs))
          .join(ROUTE_SEPERATOR));
  }

  // @return duration of all routes in milliseconds
  getDuration() {
    return this.__getDuration != null
      ? this.__getDuration
      : (this.__getDuration = _reduce(this._routes, (sum, r) => sum + r.getDuration(), 0));
  }

  // @return [Boolean] if itinerary contains a flight that is ground transit
  isGroundTransit() {
    return this.__isGroundTransit != null
      ? this.__isGroundTransit
      : (this.__isGroundTransit = _some(this._routes, (r) => r.isGroundTransit()));
  }

  // @return [Boolean] if itinerary contains a route that has over night stay
  isOverNight() {
    return this.__isOverNight != null
      ? this.__isOverNight
      : (this.__isOverNight = _some(this._routes, (r) => r.isOverNight()));
  }

  // @return [Boolean] if itinerary contains a flight that is red eye
  isRedEye() {
    return this.__isRedEye != null
      ? this.__isRedEye
      : (this.__isRedEye = _some(this._routes, (r) => r.isRedEye()));
  }

  // @return [Boolean] if itinerary contains a codeshare flight
  isCodeshare() {
    return this.__isCodeshare != null
      ? this.__isCodeshare
      : (this.__isCodeshare = _some(this._routes, (r) => r.isCodeshare()));
  }

  // @return [Array<Flight>] a list of all flights covered by this itinerary
  getFlights() {
    return this.__getFlights != null
      ? this.__getFlights
      : (this.__getFlights = _reduce(
          this._routes,
          (flights, route) => util.concat(flights, route.getFlights()),
          []
        ));
  }

  // @return [Array<Leg>] a list of all legs covered by this itinerary
  getLegs() {
    return this.__getLegs != null
      ? this.__getLegs
      : (this.__getLegs = _reduce(
          this.getFlights(),
          (legs, flight) => util.concat(legs, flight.getLegs()),
          []
        ));
  }

  // @return [Array<Carrier>] a list of all airlines contained in this itinerary
  getAirlines() {
    return this.__getAirlines != null
      ? this.__getAirlines
      : (this.__getAirlines = _uniq(
          _flatten(
            Array.from(this._routes).map((route) =>
              Array.from(route.getFlights()).map((f) => f.getAirline())
            )
          )
        ));
  }

  // @return [Array<Transport>] a list of all aircrafts contained in this itinerary
  getAircrafts() {
    return this.__getAircrafts != null
      ? this.__getAircrafts
      : (this.__getAircrafts = _uniq(
          _flatten(
            Array.from(this._routes).map((route) =>
              Array.from(route.getFlights()).map((f) => f.getAircraft())
            )
          )
        ));
  }

  // @return [Boolean] if any route has train leg
  hasTrainLeg() {
    return _some(this._routes, (r) => r.hasTrainLeg());
  }

  // @param [Fare|MultiFare] fare Optional. The fare to check if is self-connect. Default = best fare we have
  // @return [Boolean]
  isSelfConnect(fare) {
    if (fare == null) {
      fare = this._fares.getFares()[0];
    }
    if (this._originMap == null) {
      this._originMap = util.createSet(
        _compact([
          this._routes[0].getOrigin().getCode(),
          this._routes[1] != null ? this._routes[1].getDestination().getCode() : undefined,
        ])
      );
    }
    if (this._destinationMap == null) {
      this._destinationMap = util.createSet(
        _compact([
          this._routes[0].getDestination().getCode(),
          this._routes[1] != null ? this._routes[1].getOrigin().getCode() : undefined,
        ])
      );
    }
    return fare.isSelfConnect(this._originMap, this._destinationMap);
  }
}

module.exports = {
  FLIGHT_SEPERATOR,
  ROUTE_SEPERATOR,
  MINIMUM_DESTINATION_STAY,
  SHORT_TRANSFERTIME,
  Transport,
  Carrier,
  Station,
  Leg,
  Flight,
  StopOver,
  Vendor,
  FareCollection,
  MultiFare,
  Fare,
  VendorFare,
  Route,
  Itinerary,
};

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
