import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { getStore } from '../store/configureStore';

let isInitialBrowserRenderDone = false;

export function hasRendered() {
  isInitialBrowserRenderDone = true;
}

function getRelevantPropChanges(props) {
  return {
    params: props.params,
    query: props.location.query,
    pathname: props.location.pathname,
    search: props.location.search,
  };
}

export default function fetchData(WrappedComponent, fetchDataFunc) {
  class FetchDataComp extends PureComponent {
    componentDidMount() {
      if (process.env.IS_BROWSER && isInitialBrowserRenderDone) {
        const store = getStore();
        const {
          match: { params },
          location,
        } = this.props;
        fetchDataFunc(store, { params, location });
      }
    }

    componentDidUpdate(prevProps) {
      if (!isEqual(getRelevantPropChanges(this.props), getRelevantPropChanges(prevProps))) {
        const {
          match: { params },
          location,
        } = this.props;
        const store = getStore();

        fetchDataFunc(store, {
          params,
          location,
          prevParams: prevProps.params,
          prevLocation: prevProps.location,
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  FetchDataComp.fetchData = fetchDataFunc;

  FetchDataComp.contextTypes = {
    store: PropTypes.any,
  };

  return FetchDataComp;
}
