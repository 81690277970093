import React from 'react';
import './LoadingTile.less';

const LoadingTile = () => {
  return (
    <div className="LoadingTile">
      <div className="LoadingTile__firstSection">
        <span className="LoadingTile__shimmer LoadingTile__shimmer--less-width" />
      </div>
      <span className="LoadingTile__shimmer" />
      <span className="LoadingTile__btn" />
    </div>
  );
};

export default LoadingTile;
