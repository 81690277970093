import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import _last from 'lodash/last';
import { getScrollTop } from '@lib/dom';

const stack = [];

class OverlayStack extends PureComponent {
  constructor(props) {
    super(props);
    this.node = document.createElement('div');
    this.root = document.getElementById('root');
    document.body.appendChild(this.node);
  }

  componentDidMount() {
    // initialize stack with root element first time it's used
    if (stack.length === 0) {
      stack.push(this.root);
    }

    // save scroll position because we'll replace the current page with the overlay
    this.scrollTop = getScrollTop();

    // hide component higher in the stack since this one will take it's place
    _last(stack).style.display = 'none';

    // create new div to render the overlay to
    document.body.appendChild(this.node);

    // add ourselves to the overlay stack
    stack.push(this.node);

    // scroll to top in case the overlay content is higher than the window
    document.body.scrollTop = 0;
  }

  componentDidUpdate(prevProps) {
    // Create a new cloned element and create a React portal ouside of the application DOM hierarchy
    // Portals provide a first-class way to render children into a DOM node
    // that exists outside the DOM hierarchy of the parent component.
    ReactDOM.createPortal(React.cloneElement(prevProps.children, this.props), this.node);
  }

  componentWillUnmount() {
    // remove the div we created for rendering our content
    document.body.removeChild(this.node);

    // remove ourselves from the overlay stack
    stack.pop();

    // reveal again the previous element in the overlay stack
    _last(stack).style.display = '';

    // and restore the previous scroll position
    document.body.scrollTop = this.scrollTop;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.node);
  }
}

export default OverlayStack;
