import Immutable from 'immutable';
import { USER_UPDATES } from '../actions/userActions';

const DEFAULT_STATE = Immutable.Map({
  language: 'is',
  residency: 'IS',
  currency: 'ISK',
});

export default function userReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case USER_UPDATES:
      return state.merge(action.updates);
    default:
      return state;
  }
}
