/*  eslint-disable max-len */
import flattenDeep from 'lodash/flattenDeep';

/**
 * Split a translation string into array by a start string and end string recursively
 * @param {string} translation - translation string (already translated)
 * @param {string} start - this defines where we should start the split
 * @param {string} end - this defines where we should end the split
 * @return {array<string>}
 *
 * Example usage:
 *
 *  const [startText, linkText1, afterLink1, linkText2, afterLink2] = splitTranslateStringToArr(
 *    'This is a nice link %(linkStart)sNice link 1%(linkEnd)s and this one as well %(linkStart)sNice link 2%(linkEnd)s. Thank you.'
 *  );
 *
 * <p>{startText}
 *  <a>{linkText1}</a>
 *  {afterLink1}
 *  <a>{linkText2}</a>
 *  {afterLink2}
 * </p>
 */
export default function splitTranslateStringToArr(str, start = 'linkStart', end = 'linkEnd') {
  const startRegex = new RegExp(`%\\(${start}\\)s`, 'g');
  const endRegex = new RegExp(`%\\(${end}\\)s`, 'g');

  return flattenDeep(str.split(startRegex).map((s) => s.split(endRegex)));
}
