import React from 'react';
import './SortButtonsLoading.less';

const SortButtonsLoading = () => (
  <div className="SortButtonsLoading" aria-hidden="true">
    <div className="SortButtonsLoading__tile--containerLeft">
      <span className="SortButtonsLoading__tile--price" />
      <span className="SortButtonsLoading__tile--title" />
    </div>
    <div className="SortButtonsLoading__tile--containerRight">
      <span className="SortButtonsLoading__tile--duration" />
    </div>
  </div>
);

export default SortButtonsLoading;
