import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';
import createFilters from '../../lib/createFilters';
import StarIcon from '../../../content/svg/star.svg';
import './RecommendedHotels.less';

class RecommendedHotels extends React.Component {
  renderHeading() {
    if (this.props.hideText) return false;

    let { city, t } = this.props;
    if (!city) city = this.props.hotels.getIn(['hotels', 0, 'cityName']);

    if (this.props.headingClassName) {
      return (
        <div className={this.props.headingClassName}>
          {t('dohop_recommends %(city)s', { city })}
        </div>
      );
    }

    return (
      <div className="RecommendedHotels__heading">
        <span className="RecommendedHotels__city">{t('need_hotel_in %(city)s', { city })}</span>
        <span className="RecommendedHotels__promo">{t('book_with_dohop')}</span>
      </div>
    );
  }

  render() {
    if (
      !this.props.hotels ||
      !this.props.hotels.has('hotels') ||
      this.props.hotels.get('hotels').size === 0
    ) {
      return false;
    }

    let hotels = this.props.hotels.get('hotels');
    let { formatCurrency } = this.props;

    return (
      <div
        className={classNames('RecommendedHotels', {
          'RecommendedHotels--vertical': this.props.vertical,
        })}
      >
        {this.renderHeading()}
        <div className="RecommendedHotels__hotels">
          {hotels
            .slice(0, this.props.limit || 3)
            .toArray()
            .map((hotel, index) => (
              <div
                key={index}
                className={classNames('RecommendedHotels__hotel', {
                  'RecommendedHotels__hotel--horizontal': this.props.horizontal,
                })}
              >
                <a href={hotel.get('url')} target="_blank" className="RecommendedHotels__link">
                  <div className="RecommendedHotels__image">
                    <div
                      className="RecommendedHotels__background"
                      style={{ backgroundImage: `url(${hotel.get('imageURL')})` }}
                    />
                  </div>
                  <div className="RecommendedHotels__name">{hotel.get('hotelName')}</div>
                  <div className="RecommendedHotels__stars">
                    {Array(hotel.get('rating'))
                      .fill()
                      .map((_, i) => (
                        <StarIcon key={i} className="RecommendedHotels__star" />
                      ))}
                  </div>
                  <div className="RecommendedHotels__price">
                    {formatCurrency(hotel.get('minRate'))}
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

RecommendedHotels.propTypes = {
  hotels: PropTypes.instanceOf(Immutable.Map),
  horizontal: PropTypes.bool,
};

RecommendedHotels.defaultProps = {
  horizontal: false,
};

RecommendedHotels = createFilters(['t', 'formatCurrency'])(RecommendedHotels);

export default RecommendedHotels;
