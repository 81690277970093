interface IConstants {
  [key: string]: string;
}

/**
 * Creates object from array with same key and value
 * @param {array} constants array of constants strings
 * @param {string} prefix prefix for constant
 * @returns {Object.<string, string>} constant object with same key and value
 */
export default function createConstants(constants: string[], prefix = ''): IConstants {
  const map: IConstants = {};

  for (const c of constants) {
    map[c] = `${prefix}${c}`;
  }

  return map;
}
