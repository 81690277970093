import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _omit from 'lodash/omit';
import last from 'lodash/last';
import moment from 'moment';
import { Map } from 'immutable';
import { daysApart } from 'dohop-client-api/src/time';
import * as keyCodes from '@lib/keyCodes';
import { getScrollTop, scrollTo } from '@lib/dom';
import { getMonthsInRange } from './Utils';
import DatePickerMonth from './DatePickerMonth';
import MatchMedia, { queries } from '../MatchMedia';
import MobileView from '../MobileView';
import CloseButton from '../CloseButton';
import withForwardedRef from '../wrappers/WithForwardedRef';
import DatePickerSeasons from './DatePickerSeasons';
import DatePickerMonths from './DatePickerMonths';
import OneWayOrRoundTrip from '../frontpage/SearchForm/OneWayOrRoundTrip';
import Arrow from '@content/icons/arrow-right.svg';
import './DatePickerCalendar.less';

const KEYCODE_MOVEMENT = {
  [keyCodes.ARROW_UP]: -7,
  [keyCodes.K]: -7,
  [keyCodes.ARROW_DOWN]: 7,
  [keyCodes.J]: 7,
  [keyCodes.ARROW_LEFT]: -1,
  [keyCodes.H]: -1,
  [keyCodes.ARROW_RIGHT]: 1,
  [keyCodes.L]: 1,
};

// returns true if a modifier key is press for the event - ctrl, alt etc.
function hasModifier(e) {
  return Boolean(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
}

function monthsApart(m1, m2) {
  return (
    m1.getUTCFullYear() * 12 + m1.getUTCMonth() - (m2.getUTCFullYear() * 12 + m2.getUTCMonth())
  );
}

class DatePickerCalendar extends Component {
  state = {
    pushX: 0,
    season: null,
    seasonHover: false,
  };

  componentDidUpdate() {
    this.scrollDownToCalender();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    document.addEventListener('keydown', this.onKeyDown);
    this.scrollDownToCalender();
    this.moveCalendarIntoViewPort();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('keydown', this.onKeyDown);
  }

  /**
   * Checks if the calendar is out of view vertically.
   * If so, the page scrolls down to the calendar.
   */
  scrollDownToCalender = () => {
    const { forwardedRef, input } = this.props;
    const { ipad } = queries;

    const hasRefForInput = input && input.current;
    const hasRefForCalendar = forwardedRef && forwardedRef.current;

    // scroll down if calendar is out of view
    if (hasRefForCalendar && hasRefForInput && ipad.matches) {
      const scrollToInput = input.current.getBoundingClientRect().top;
      const scrollToCal = forwardedRef.current.getBoundingClientRect().bottom - window.innerHeight;

      if (scrollToCal > 0 && scrollToInput > 0) {
        scrollTo(getScrollTop() + Math.min(scrollToInput, scrollToCal));
      }
    }
  };

  /**
   * make sure calendar does not leave the window viewport
   */
  moveCalendarIntoViewPort = () => {
    const { forwardedRef } = this.props;
    const { pushX } = this.state;

    if (!forwardedRef || !forwardedRef.current) return;
    const rect = forwardedRef.current.getBoundingClientRect();
    let updatedPushX = pushX;
    const right = rect.right - updatedPushX;
    const left = rect.left - updatedPushX;
    const windowWidth = document.body.clientWidth;

    if (right > windowWidth) {
      updatedPushX = windowWidth - right;
    }

    if (left < 0) {
      updatedPushX = -left;
    }

    if (pushX !== updatedPushX) {
      this.setState({ pushX: updatedPushX });
    }
  };

  getDayAvailability(day) {
    const { calendar, activeDateIndex, selected } = this.props;
    if (!calendar) return true;
    if (activeDateIndex === 0) {
      return calendar.getOutboundAvailability(day);
    }
    return calendar.getHomeboundAvailability(day, selected[0]);
  }

  onChangeDay(num) {
    const {
      activeDateIndex,
      selected,
      month,
      minDate,
      maxDate,
      onChangeMonth,
      onSelectDay,
    } = this.props;
    const nextDay = moment.utc(selected[activeDateIndex]).add(num, 'days').toDate();
    const numMonths = queries.ipad.matches ? 2 : 1;
    const finalMonth = last(getMonthsInRange(month, numMonths));

    if (
      daysApart(minDate, nextDay) >= 0 &&
      daysApart(nextDay, maxDate) >= 0 &&
      this.getDayAvailability(nextDay) &&
      (activeDateIndex === 0 || daysApart(selected[0], nextDay) >= 0)
    ) {
      if (daysApart(nextDay, month) > 0) {
        onChangeMonth(monthsApart(nextDay, month));
      } else if (daysApart(moment.utc(finalMonth).endOf('month').toDate(), nextDay) > 0) {
        onChangeMonth(monthsApart(nextDay, month));
      }
      onSelectDay(nextDay, false);
    }
  }

  onKeyDown = (e) => {
    const { onClose, onKeyDown } = this.props;

    if (e.keyCode === keyCodes.TAB) {
      onClose(e);
    } else if (!hasModifier(e) && KEYCODE_MOVEMENT[e.keyCode]) {
      e.preventDefault();
      this.onChangeDay(KEYCODE_MOVEMENT[e.keyCode]);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  onResize = () => {
    this.moveCalendarIntoViewPort();
  };

  canSelectPrev() {
    const { minDate, month } = this.props;
    return !minDate || monthsApart(month, minDate) > 0;
  }

  canSelectNext(numMonths) {
    const { maxDate, month } = this.props;
    return !maxDate || monthsApart(month, maxDate) < 1 - numMonths;
  }

  /**
   * Callback handler seasons component
   *
   * @param {Object.<Immutable.Map>} season
   * @param {Boolean} seasonHover - Mouse enter/leave indicator
   */
  seasonsCallbackHandler = (season, seasonHover) => {
    const { flexibleValue } = this.props;
    this.setState({
      season: seasonHover
        ? season
        : Map({ d1: flexibleValue.get('d1'), d1_: flexibleValue.get('d1_') }),
      seasonHover,
    });
  };

  /**
   * Date change callback handler
   *
   * @param {Object.<Immutable.Map>} season
   */
  changeDateHandler = (season) => {
    const { onChangeFlexibleDates } = this.props;
    this.setState({
      season,
    });
    onChangeFlexibleDates(season);
  };

  renderContent(numMonths) {
    const {
      t,
      isFlexible,
      flexibleValue,
      onChangeFlexibleDates,
      isOneWay,
      setIsOneWay,
      month,
      calendar,
      canClear,
      onClear,
      onClose,
      onChangeMonth,
      onChangeFlexible,
      forwardedRef,
      isSticky,
    } = this.props;
    const { season, seasonHover, pushX } = this.state;
    let canSelectPrev = this.canSelectPrev();
    let canSelectNext = this.canSelectNext(numMonths);
    let monthProps = _omit(this.props, 'onChangeMonth', 'onClose');
    const showFlexible = isFlexible !== null;

    return (
      <div
        className={classNames('DatePickerCalendar__content', {
          'DatePickerCalendar__content--flexible': isFlexible,
          'DatePickerCalendar__content--sticky': isSticky,
        })}
        style={{ marginLeft: pushX }}
        ref={forwardedRef}
      >
        {showFlexible ? (
          <div className="DatePickerCalendar__flexible__header">
            <MatchMedia max="mobile">
              <div className="DatePickerCalendar__tabs">
                <div
                  className={classNames('DatePickerCalendar__tab', {
                    'DatePickerCalendar__tab--active': !isFlexible,
                  })}
                  onClick={() => onChangeFlexible(false)}
                >
                  <div className="DatePickerCalendar__tab__text"> {t('specific_date')}</div>
                </div>
                <div
                  className={classNames('DatePickerCalendar__tab', {
                    'DatePickerCalendar__tab--active': isFlexible,
                  })}
                  onClick={() => onChangeFlexible(true)}
                >
                  <div className="DatePickerCalendar__tab__text"> {t('flexible_dates')}</div>
                </div>
              </div>
            </MatchMedia>

            <MatchMedia min="mobile">
              <div
                className={classNames(
                  'DatePickerCalendar__type',
                  'DatePickerCalendar__type--specific',
                  { 'DatePickerCalendar__type--active': !isFlexible }
                )}
                onClick={() => onChangeFlexible(false)}
              >
                {t('specific_date')}
              </div>
              <div
                className={classNames(
                  'DatePickerCalendar__type',
                  'DatePickerCalendar__type--flexible',
                  { 'DatePickerCalendar__type--active': isFlexible }
                )}
                onClick={() => onChangeFlexible(true)}
              >
                {t('flexible_dates')}
              </div>
              <CloseButton
                id={'CloseButton-DatePickerCalendar'}
                onClose={onClose}
                className="DatePickerCalendar__close"
              />
            </MatchMedia>
          </div>
        ) : (
          <CloseButton
            id={'CloseButton-DatePickerCalendar'}
            onClose={onClose}
            className={classNames('DatePickerCalendar__close', 'DatePickerCalendar__close--noText')}
            hasCloseText={false}
          />
        )}
        {!isFlexible && (
          <div>
            <div
              className={classNames({
                DatePickerCalendar__navigator: true,
                'DatePickerCalendar__navigator--prev': true,
                'DatePickerCalendar__navigator--disabled': !canSelectPrev,
                'DatePickerCalendar__navigator--flexible': showFlexible,
              })}
              onClick={canSelectPrev ? () => onChangeMonth(-1) : undefined}
            >
              <Arrow />
            </div>
            <div
              className={classNames({
                DatePickerCalendar__navigator: true,
                'DatePickerCalendar__navigator--next': true,
                'DatePickerCalendar__navigator--disabled': !canSelectNext,
                'DatePickerCalendar__navigator--flexible': showFlexible,
              })}
              onClick={canSelectNext ? () => onChangeMonth(1) : undefined}
            >
              <Arrow />
            </div>
            {getMonthsInRange(month, numMonths).map((m) => (
              <div key={m.getUTCMonth()} className="DatePickerCalendar__monthContainer">
                <DatePickerMonth {...monthProps} month={m} />
              </div>
            ))}
            {(Boolean(calendar) || setIsOneWay !== null) && (
              <div className="DatePickerCalendar__footer">
                {Boolean(canClear && onClear) && (
                  <a className="DatePickerCalendar__button" onClick={onClear}>
                    {t('noreturndate')}
                  </a>
                )}
                {Boolean(calendar) && (
                  <ul className="DatePickerCalendar__listItems">
                    <li className="DatePickerCalendar__item">
                      <span className="DatePickerCalendar__info DatePickerCalendar__info--direct" />
                      {t('direct')}
                    </li>
                    <li className="DatePickerCalendar__item">
                      <span className="DatePickerCalendar__info DatePickerCalendar__info--indirect" />
                      {t('indirect')}
                    </li>
                    <li className="DatePickerCalendar__item">
                      <span className="DatePickerCalendar__info DatePickerCalendar__info--unavail" />
                      {t('no connection')}
                    </li>
                  </ul>
                )}
                {setIsOneWay !== undefined && (
                  <MatchMedia min="ipad">
                    <OneWayOrRoundTrip
                      className={classNames('DatePickerCalendar__oneWayOrRoundTrip', {
                        'DatePickerCalendar__oneWayOrRoundTrip--noList': !Boolean(calendar),
                      })}
                      t={t}
                      showAsButton
                      isOneWay={isOneWay}
                      setIsOneWay={(value) => setIsOneWay(value)}
                    />
                  </MatchMedia>
                )}
              </div>
            )}
          </div>
        )}
        {isFlexible && (
          <div className="DatePickerCalendar__flexible__content">
            <DatePickerSeasons
              t={t}
              d1={flexibleValue.get('d1')}
              d1_={flexibleValue.get('d1_')}
              onChangeFlexibleDates={onChangeFlexibleDates}
              callbackHandler={this.seasonsCallbackHandler}
              clickHandler={this.changeDateHandler}
            />
            <DatePickerMonths
              d1={flexibleValue.get('d1')}
              d1_={flexibleValue.get('d1_')}
              season={season}
              seasonHover={seasonHover}
              clickHandler={this.changeDateHandler}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { title, onClose } = this.props;
    const { ipad } = queries;

    if (!queries || !queries.ipad) {
      return null;
    }

    return (
      <div className="DatePickerCalendar">
        {/* Here we don't use MatchMedia because we want to access the size of the calendar content in
        desktop version. To do that we need to make sure that the movile version of the calendar isn't
        loaded to the DOM at all in desktop version, because getBoundingClientRect() returns 0 for everything
        if the element has the styling display:none */}
        {ipad.matches ? (
          this.renderContent(2, true)
        ) : (
          <MobileView title={title} onClose={onClose} showConfirm>
            {this.renderContent(1)}
          </MobileView>
        )}
      </div>
    );
  }
}

DatePickerCalendar.propTypes = {
  month: PropTypes.instanceOf(Date).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  selected: PropTypes.array,
  hover: PropTypes.instanceOf(Date),
  highlight: PropTypes.array,
  onSelectDay: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onDayHover: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  canClear: PropTypes.bool,
  onClear: PropTypes.func,
  flexibleValue: PropTypes.object,
  onChangeFlexibleDates: PropTypes.func,
  onChangeFlexible: PropTypes.func,
  t: PropTypes.func,
  isSticky: PropTypes.bool,
};

export default withForwardedRef(DatePickerCalendar);
