import { Map, List, OrderedMap, Set, fromJS } from 'immutable';
import { jetstar, tigerairtw } from 'shared/data/partnerData.json';
import {
  JETSTAR,
  TIGERAIR,
  AVIANCA,
  EASYJET,
  NORWEGIAN,
  WESTJET,
  AIR_FRANCE,
  KIWI,
  DEUTSCHE_BAHN,
  SUNEXPRESS,
  ETIHAD,
} from './vendorNames';
import { ALYZIA_SELF_CONNECT } from './constants';

/**
 * Vendors that support seat maps
 */
export const SEAT_MAP_VENDORS = Map({
  1336: 'easyJet Staging',
  1341: 'easyJet Prod',
  1378: 'easyJet Others Staging',
  1098: 'easyJet Others Prod',
  1343: 'Norwegian Staging',
  1340: 'Norwegian Prod',
  1541: 'Norwegian Staging',
  1547: 'Norwegian Prod',
  1666: 'Norwegian Staging',
  1667: 'Norwegian Prod',
  1390: 'Tigerair Taiwan dev',
  1344: 'WestJet Staging',
  1229: 'WestJet Prod',
  1540: 'Avianca Staging',
  1555: 'Avianca Prod',
  5032: 'VietJet Staging',
  6032: 'VietJet Prod',
  1465: 'Transavia Staging',
  1498: 'Transavia Prod',
  5031: 'Norse Staging',
  5044: 'Norse Prod',
});

/**
 * Vendors that do not support seat selection afterwards
 */
export const SEATS_AUTO_ALLOCATED_VENDORS = [DEUTSCHE_BAHN];

export const FREQUENT_FLYER_PROGRAMS = {
  [AVIANCA]: 'LifeMiles',
  [EASYJET]: 'easyJet Plus',
  [NORWEGIAN]: 'Norwegian Reward',
  [WESTJET]: 'WestJet Rewards',
  [TIGERAIR]: 'Tigerclub Membership',
  [AIR_FRANCE]: 'Flying Blue',
};

export function getFrequentFlyerProgramsForVendor(t, vendor) {
  const FREQUENT_FLYER_PROGRAMS_LIST = Map({
    [AIR_FRANCE]: List([
      Map({ label: t('airfrance_ff_program_KL'), value: 'KL' }),
      // only offer Flying Blue (KL) for now
      // Map({ label: t('airfrance_ff_program_EI'), value: 'EI' }),
      // Map({ label: t('airfrance_ff_program_SU'), value: 'SU' }),
      // Map({ label: t('airfrance_ff_program_AR'), value: 'AR' }),
      // Map({ label: t('airfrance_ff_program_UX'), value: 'UX' }),
      // Map({ label: t('airfrance_ff_program_SV'), value: 'SV' }),
      // Map({ label: t('airfrance_ff_program_BE'), value: 'BE' }),
      // Map({ label: t('airfrance_ff_program_ME'), value: 'ME' }),
      // Map({ label: t('airfrance_ff_program_AM'), value: 'AM' }),
      // Map({ label: t('airfrance_ff_program_CM'), value: 'CM' }),
      // Map({ label: t('airfrance_ff_program_CI'), value: 'CI' }),
      // Map({ label: t('airfrance_ff_program_MU'), value: 'MU' }),
      // Map({ label: t('airfrance_ff_program_MF'), value: 'MF' }),
      // Map({ label: t('airfrance_ff_program_MH'), value: 'MH' }),
      // Map({ label: t('airfrance_ff_program_EY'), value: 'EY' }),
      // Map({ label: t('airfrance_ff_program_GA'), value: 'GA' }),
      // Map({ label: t('airfrance_ff_program_JL'), value: 'JL' }),
      // Map({ label: t('airfrance_ff_program_9W'), value: '9W' }),
      // Map({ label: t('airfrance_ff_program_MK'), value: 'MK' }),
      // Map({ label: t('airfrance_ff_program_VN'), value: 'VN' }),
      // Map({ label: t('airfrance_ff_program_AZ'), value: 'AZ' }),
      // Map({ label: t('airfrance_ff_program_OK'), value: 'OK' }),
      // Map({ label: t('airfrance_ff_program_QF'), value: 'QF' }), // no translations here
      // Map({ label: t('airfrance_ff_program_CZ'), value: 'CZ' }),
      // Map({ label: t('airfrance_ff_program_DL'), value: 'DL' }),
      // Map({ label: t('airfrance_ff_program_KE'), value: 'KE' }),
      // Map({ label: t('airfrance_ff_program_G3'), value: 'G3' }),
      // Map({ label: t('airfrance_ff_program_VS'), value: 'VS' }),
      // Map({ label: t('airfrance_ff_program_WS'), value: 'WS' }),
    ]),
  });

  return FREQUENT_FLYER_PROGRAMS_LIST.get(vendor, List());
}

export const FRAUD_SCRIPT_VENDORS = {
  [KIWI]: 1267,
};

const EASYJET_VENDORS = [1336, 1341, 1378, 1098];

export function isEasyJetVendor(vid) {
  return EASYJET_VENDORS.includes(vid);
}

const NORWEGIAN_VENDORS = [1343, 1340, 1457, 1463, 1541, 1547, 1666, 1667];

export function isNorwegianVendor(vid) {
  return NORWEGIAN_VENDORS.includes(vid);
}

const VIRGIN_VENDORS = [1385, 1386, 1429, 1472, 1473];

export function isVirginVendor(vid) {
  return VIRGIN_VENDORS.includes(vid);
}

const AVIANCA_VENDORS = [1555, 1540, 1546, 1556, 1539];

export function isAviancaVendor(vid) {
  return AVIANCA_VENDORS.includes(vid);
}

const TRANSAVIA_VENDORS = [1498, 1465, 1501, 1514, 1359, 1594];

export function isTransaviaVendor(vid) {
  return TRANSAVIA_VENDORS.includes(vid);
}

const AIRFRANCE_VENDORS = [1538, 1581, 1590, 1592, 1593];

export const SNCF_VENDORS = [5088, 5089];

export function isAirfranceVendor(vid) {
  return AIRFRANCE_VENDORS.includes(vid);
}

export function isSNCFVendor(vid) {
  return SNCF_VENDORS.includes(vid);
}

export const DEUTSCHE_BAHN_VENDORS = [1548, 1648];

export function isDeutscheBahnVendor(vid) {
  return DEUTSCHE_BAHN_VENDORS.includes(vid);
}

const AIR_TRANSAT_VENDORS = [1414, 1415];
export function isAirTransatVendor(vid) {
  return AIR_TRANSAT_VENDORS.includes(vid);
}

const VUELING_VENDORS = [5007, 5008, 1564, 1569];
export function isVuelingVendor(vid) {
  return VUELING_VENDORS.includes(vid);
}

const NORSE_VENDORS = [5031, 5044];
export function isNorseVendor(vid) {
  return NORSE_VENDORS.includes(vid);
}

const AEGEAN_VENDORS = [5005, 5012, 5042, 5043];
export function isAegeanVendor(vid) {
  return AEGEAN_VENDORS.includes(vid);
}

const THAIVIETJET_VENDORS = [5032, 6032];
export function isThaiVietJetVendor(vid) {
  return THAIVIETJET_VENDORS.includes(vid);
}

const VIVA_AIR_VENDORS = [5025, 5049];
export function isVivaAirVendor(vid) {
  return VIVA_AIR_VENDORS.includes(vid);
}

const LEVEL_VENDOR = [5009, 5010, 5087];
export function isLevelVendor(vid) {
  return LEVEL_VENDOR.includes(vid);
}

const NEOS_VENDOR = [1494, 1369];
export function isNeosVendor(vid) {
  return NEOS_VENDOR.includes(vid);
}

export const TRAVELFUSION_VENDORS = [
  1261,
  1330,
  1345,
  1574,
  1393,
  1364,
  1361,
  1480,
  1413,
  1451,
  1453,
  1439,
  1358,
  1389,
  1360,
  1450,
  1452,
  1365,
  1363,
  1448,
  1357,
  1543,
  1449,
  1359,
  1362,
  1366,
  1573,
  1574,
];

export function isTravelFusionVendor(vid) {
  return TRAVELFUSION_VENDORS.includes(vid);
}

export const SPIRIT_VENDORS = [1587, 1598];

export function isSpiritVendor(vid) {
  return SPIRIT_VENDORS.includes(vid);
}

const CORSAIR_VENDORS = [1367, 1372, 5030, 5038];

export function isCorsairVendor(vid) {
  return CORSAIR_VENDORS.includes(vid);
}

const ICELANDAIR_VENDORS = [1392, 1551, 1468, 5000];

export function isIcelandairVendor(vid) {
  return ICELANDAIR_VENDORS.includes(vid);
}

const PEGASUS_VENDORS = [1621, 5018, 5033, 5034, 5035, 5036];

export function isPegasusVendor(vid) {
  return PEGASUS_VENDORS.includes(vid);
}

const SATA_VENDORS = [5004, 5017, 5022, 5023];

export function isSataVendor(vid) {
  return SATA_VENDORS.includes(vid);
}

export function ssrTofrequentFlierProgram(t) {
  return OrderedMap({
    QFFP: { name: t('qantas_frequent_flyer'), vendor: JETSTAR },
    EKFP: { name: t('emirates_skyward_miles'), vendor: JETSTAR },
    JLFF: { name: 'JAL Mileage', vendor: JETSTAR },
    VNFP: { name: 'Lotusmiles', vendor: JETSTAR },
    VCHR: { name: 'Jetstar Flight Rewards Voucher', vendor: JETSTAR },
    LNGE: { name: 'Qantas Lounge Access', vendor: JETSTAR },
    TTWP: { name: 'Tigerclub Privilige Program', vendor: TIGERAIR },
  });
}

/* eslint-disable max-len */
export const TRAVELFUSION_SUPPORT = {
  FR: {
    supportPhone: '+44 871 246 0002',
    termsAndConditions:
      'https://www.ryanair.com/gb/en/useful-info/help-centre/terms-and-conditions',
    name: 'Ryanair',
  },
  RK: {
    supportPhone: '+44 871 246 0002',
    termsAndConditions:
      'https://www.ryanair.com/gb/en/useful-info/help-centre/terms-and-conditions',
    name: 'Ryanair',
  },
  EI: {
    supportPhone: '+353 1 886 8505',
    termsAndConditions: 'https://www.aerlingus.com/support/legal/conditions-of-carriage/',
    name: 'Aer Lingus',
  },
  GR: {
    supportPhone: '+44 148 182 2886',
    termsAndConditions: 'https://www.aurigny.com/html/en/lConditions.aspx?lang=EN',
    name: 'Aurigny',
  },
  VY: {
    supportPhone: '+34 931 51 81 58',
    termsAndConditions: 'https://www.vueling.com/en/customer-services/conditions-of-carriage',
    name: 'Vueling',
  },
  BE: {
    supportPhone: '+44 (207 308 0812',
    termsAndConditions: 'http://www.flybe.com/ticket-rules/conditions-of-carriage.htm',
    name: 'FlyBE',
  },
  HV: {
    supportPhone: '+352 270 02 728',
    termsAndConditions: 'https://www.transavia.com/en-EU/the-small-print/conditions-of-carriage/',
    name: 'Transavia',
  },
  '0B': {
    supportPhone: '+44 20 7037 2687',
    termsAndConditions: 'https://www.blueairweb.com/en/gb/Travel-Conditions/',
    name: 'Blue Air',
  },
  V7: {
    supportPhone: '+34 931 22 07 17',
    termsAndConditions: 'http://www.volotea.com/en/legal-conditions/conditions-of-carriage/',
    name: 'Volotea',
  },
  '4U': {
    supportPhone: '+44 871 702 9978',
    termsAndConditions:
      'https://www.eurowings.com/en/legal-information/general-conditions-of-carriage.html',
    name: 'Germanwings',
  },
  EW: {
    supportPhone: '+44 330 365 1918',
    termsAndConditions:
      'https://www.eurowings.com/en/legal-information/general-conditions-of-carriage.html',
    name: 'Eurowings',
  },
  LS: {
    supportPhone: '+44 20 3059 8336',
    termsAndConditions: 'https://www.jet2holidays.com/terms-and-conditions',
    name: 'Jet2',
  },
  DE: {
    supportPhone: '+49 (0) 6171 6988920',
    termsAndConditions: 'https://www.condor.com/us/druck/agb.html',
    name: 'Condor',
  },
  AY: {
    supportPhone: '+358 600 140 140',
    termsAndConditions: 'https://www.finnair.com/int/gb/info/terms-of-use',
    name: 'Finnair',
    luggage: 'https://www.finnair.com/int/gb/#ssm-manage-booking',
  },
};

const TIGERAIR_SUPPORT_PHONE_NUMBERS = {
  default: '+886 2 7753 1088',
  JP: '+81(0)3-6455-0242',
};

const TIGERAIR_SUPPORT_OPENING_HOURS = {
  default: '09:00~21:00 (UTC+8)',
  JP: '10:00~12:00 / 13:00~17:00 (UTC+9)',
};

const JETSTAR_SUPPORT_PHONE_NUMBERS = {
  default: '131 538',
};

const DOHOP_SUPPORT_PHONE_NUMBERS = {
  da: '+45 92 45 01 82',
  de: '+49 5119 573 9892',
  en: {
    GB: '+44 1200 401410',
    US: '+1 646 751 8693',
    CA: '+1 (204) 808-2377',
    PH: '+63 82 2260282',
  },
  es: {
    CO: '+57 1 514 1230',
    ES: '+34 91 123 3251',
    BO: '+57 1 514 1230',
    PE: '+57 1 514 1230',
    EC: '+57 1 514 1230',
  },
  fr: { CA: '+1 (204) 808-2377', default: '+33 18 288 1450' },
  it: '+39 029 475 8543',
  nl: '+31 20 8087086',
  pt: '+351 308 802 310',
  sv: '+46 10 551 76 74',
  ph: '+63 82 2260282',
};

/**
 *
 * @param {string} partner name of the partner
 * Return an object containing email and phone number. Defaults to Dohop
 */
export function getSupportInfoByPartner(partner, residency, language) {
  switch (partner) {
    case jetstar.key:
      return {
        phone: getVendorSupportByResidencyOrDefault(JETSTAR_SUPPORT_PHONE_NUMBERS, residency),
        email: 'customerservice@jetstar.com',
      };
    case tigerairtw.key:
      return {
        phone: getVendorSupportByResidencyOrDefault(TIGERAIR_SUPPORT_PHONE_NUMBERS, residency),
        email: 'customerservice@tigerairtw.com',
        openingHours: getVendorSupportByResidencyOrDefault(
          TIGERAIR_SUPPORT_OPENING_HOURS,
          residency
        ),
      };
    default:
      return {
        phone: getDohopSupportNumber(language, residency),
        email: 'service@dohop.com',
        website: `https://support.dohop.com/hc/${language}`,
      };
  }
}

/**
 * Fetched the support number of the partner by residency. If non is found default to the "default"
 * phone number
 * @param {String} vendor name of the vendor
 * @param {String} residency residency of the user
 */
function getVendorSupportByResidencyOrDefault(vendorSupportNumbers, residency) {
  return vendorSupportNumbers[residency]
    ? vendorSupportNumbers[residency]
    : vendorSupportNumbers.default;
}

/**
 * return support number for Dohop
 *
 * @export
 * @param {string} language
 * @param {string} residency
 * @returns {string}
 */
export function getDohopSupportNumber(language, residency) {
  if (language === 'en') {
    return DOHOP_SUPPORT_PHONE_NUMBERS.en[residency]
      ? DOHOP_SUPPORT_PHONE_NUMBERS.en[residency]
      : DOHOP_SUPPORT_PHONE_NUMBERS.en.GB;
  }
  if (language === 'es') {
    return DOHOP_SUPPORT_PHONE_NUMBERS.es[residency]
      ? DOHOP_SUPPORT_PHONE_NUMBERS.es[residency]
      : DOHOP_SUPPORT_PHONE_NUMBERS.es.ES;
  }
  if (language === 'fr') {
    return DOHOP_SUPPORT_PHONE_NUMBERS.fr[residency]
      ? DOHOP_SUPPORT_PHONE_NUMBERS.fr[residency]
      : DOHOP_SUPPORT_PHONE_NUMBERS.fr.default;
  }
  return DOHOP_SUPPORT_PHONE_NUMBERS[language]
    ? DOHOP_SUPPORT_PHONE_NUMBERS[language]
    : DOHOP_SUPPORT_PHONE_NUMBERS.en.GB;
}

export function getFeeVendorSupportNumber(vendor, language, residency) {
  return getDohopSupportNumber(language, residency);
}

export function getEurowingsPlatformTerms(language, residency) {
  const termsBucket = 'https://s3.eu-west-1.amazonaws.com/dohop-web-static/terms-conditions';
  const version = 'V7_200519';
  const supportedLanguages = ['en', 'de', 'fr', 'es', 'it', 'nl'];
  if (!supportedLanguages.includes(language)) {
    language = 'en';
  }
  // We have different version of the english terms if the residency if US
  let locale = language;
  if (language === 'en') {
    if (residency === 'US') {
      locale = 'en-US';
    } else {
      locale = 'en-GB';
    }
  }
  return `${termsBucket}/${language}/Eurowings_Flight_Platform_Terms_${version}_${locale}.pdf`;
}

function getAlyziaDoc(type, language) {
  if (type !== 'FAQ' && type !== 'TOC') {
    return '';
  }

  const alyziaBucket = 'https://s3.eu-west-1.amazonaws.com/dohop-web-static/alyzia';
  const version = type === 'TOC' ? 'version_1.2.pdf' : 'version_1.1.pdf';

  switch (language) {
    case 'en':
      return `${alyziaBucket}/Alyzia_${type}_en_${version}`;
    default:
      return `${alyziaBucket}/Alyzia_${type}_en_${version}`;
  }
}

export function getAlyziaFAQ(language) {
  return getAlyziaDoc('FAQ', language);
}

export function getAlyziaTerms(language) {
  return getAlyziaDoc('TOC', language);
}

/**
 * Fetches the URL for the luggage ground handling FAQ document
 * @param {string} serviceProvider
 * @param {string} language two letter language code
 * @returns {string}
 */
export function getLuggageGroundHandlingFAQ(serviceProvider, language) {
  switch (serviceProvider) {
    case ALYZIA_SELF_CONNECT:
      return getAlyziaFAQ(language);
    default:
      return '';
  }
}

/**
 * Fetches the URL for the luggage ground handling terms and conditions document
 * @param {string} serviceProvider
 * @param {string} language two letter language code
 * @returns {string}
 */
export function getLuggageGroundHandlingTerms(serviceProvider, language) {
  switch (serviceProvider) {
    case ALYZIA_SELF_CONNECT:
      return getAlyziaTerms(language);
    default:
      return '';
  }
}

export const transaviaFaqLinkBylang = fromJS({
  nl: 'https://www.transavia.com/nl-NL/veelgestelde-vragen/smart-connect',
  en: 'https://www.transavia.com/en-NL/faq/smart-connect',
  fr: 'https://www.transavia.com/fr-FR/questions-frequemment-posees/smart-connect',
  es: 'https://www.transavia.com/es-ES/preguntas-frecuentes/smart-connect',
  it: 'https://www.transavia.com/it-IT/domande-e-risposte/smart-connect',
  pt: 'https://www.transavia.com/pt-PT/perguntas-frequentes/smart-connect',
  de: 'https://www.transavia.com/de-DE/haufig-gestellte-fragen/smart-connect',
});

export function getIcelandairLinks(language, residency) {
  if (language === 'en') {
    return ICELANDAIR_LINKS.en[residency] ? ICELANDAIR_LINKS.en[residency] : ICELANDAIR_LINKS.en.GB;
  }
  return ICELANDAIR_LINKS[language] ? ICELANDAIR_LINKS[language] : ICELANDAIR_LINKS.en.GB;
}

const ICELANDAIR_LINKS = {
  en: {
    GB: {
      optionalFees:
        'https://www.icelandair.com/en-gb/support/terms-and-conditions/optional-service-and-fees/',
      privacyPolicy:
        'https://www.icelandair.com/en-gb/support/terms-and-conditions/privacy-policy/',
      dangerousGoods: 'https://www.icelandair.com/en-gb/support/baggage/restrictions/',
      conditionOfCarriage:
        'https://www.icelandair.com/en-gb/support/terms-and-conditions/conditions-of-carriage/',
      baggageAllowance: 'https://www.icelandair.com/en-gb/support/baggage/allowance/',
    },
    US: {
      optionalFees:
        'https://www.icelandair.com/support/terms-and-conditions/optional-service-and-fees/',
      privacyPolicy: 'https://www.icelandair.com/support/terms-and-conditions/privacy-policy/',
      dangerousGoods: 'https://www.icelandair.com/support/baggage/restrictions/',
      conditionOfCarriage:
        'https://www.icelandair.com/support/terms-and-conditions/conditions-of-carriage/',
      baggageAllowance: 'https://www.icelandair.com/support/baggage/allowance/',
    },
  },
  is: {
    optionalFees:
      'https://www.icelandair.com/is/adstod/skilmalar-og-skilyrdi/bokanir-farangur-og-thjonusta-um-bord/',
    privacyPolicy: 'https://www.icelandair.com/is/adstod/skilmalar-og-skilyrdi/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/is/adstod/farangur/takmarkanir/',
    conditionOfCarriage:
      'https://www.icelandair.com/is/adstod/skilmalar-og-skilyrdi/flutningsskilmalar/',
    baggageAllowance: 'https://www.icelandair.com/is/adstod/farangur/heimild/',
  },
  de: {
    optionalFees:
      'https://www.icelandair.com/de-de/hilfe/allgemeine-geschaftsbedingungen/optionale-dienstleistungen-und-gebuehren/',
    privacyPolicy:
      'https://www.icelandair.com/de-de/hilfe/allgemeine-geschaftsbedingungen/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/de-de/hilfe/gepaeck/gepackbeschrankungen/',
    conditionOfCarriage:
      'https://www.icelandair.com/de-de/hilfe/allgemeine-geschaftsbedingungen/befoerderungsbedingungen/',
    baggageAllowance: 'https://www.icelandair.com/de-de/hilfe/gepaeck/freigepackmenge/',
  },
  fr: {
    optionalFees:
      'https://www.icelandair.com/fr-fr/service-client/termes-et-conditions/frais-et-services-optionnels/',
    privacyPolicy:
      'https://www.icelandair.com/fr-fr/service-client/termes-et-conditions/privacy-policy/',
    dangerousGoods:
      'https://www.icelandair.com/fr-fr/service-client/bagages/restrictions-relatives-aux-bagages/',
    conditionOfCarriage:
      'https://www.icelandair.com/fr-fr/service-client/termes-et-conditions/conditions-de-transport/',
    baggageAllowance: 'https://www.icelandair.com/fr-fr/service-client/bagages/franchise-bagages/',
  },
  da: {
    optionalFees:
      'https://www.icelandair.com/da-dk/support/vilkaar-og-betingelser/valgfri-service-og-gebyr/',
    privacyPolicy:
      'https://www.icelandair.com/da-dk/support/vilkaar-og-betingelser/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/da-dk/support/bagage/restriktioner/',
    conditionOfCarriage:
      'https://www.icelandair.com/da-dk/support/vilkaar-og-betingelser/befordringsbestemmelser/',
    baggageAllowance: 'https://www.icelandair.com/da-dk/support/bagage/tilladte-bagage/',
  },
  no: {
    optionalFees:
      'https://www.icelandair.com/no-no/kundeservice/betingelser-og-vilkar/valgfri-service-og-avgifter/+',
    privacyPolicy:
      'https://www.icelandair.com/no-no/kundeservice/betingelser-og-vilkar/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/no-no/kundeservice/bagasje/restriksjoner/',
    conditionOfCarriage:
      'https://www.icelandair.com/no-no/kundeservice/betingelser-og-vilkar/fraktbetingelser/',
    baggageAllowance: 'https://www.icelandair.com/no-no/kundeservice/bagasje/bagasjekvoter/',
  },
  sv: {
    optionalFees:
      'https://www.icelandair.com/sv-se/support/villkor-och-bestammelser/tillvalda-tjanster-och-kostnader/',
    privacyPolicy:
      'https://www.icelandair.com/sv-se/support/villkor-och-bestammelser/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/sv-se/support/bagage/restriktioner/',
    conditionOfCarriage:
      'https://www.icelandair.com/sv-se/support/villkor-och-bestammelser/conditions-of-carriage/',
    baggageAllowance: 'https://www.icelandair.com/sv-se/support/bagage/regler/',
  },
  fi: {
    optionalFees:
      'https://www.icelandair.com/fi-fi/tuki/ehdot-ja-maaraykset/optional-service-and-fees/',
    privacyPolicy: 'https://www.icelandair.com/fi-fi/tuki/ehdot-ja-maaraykset/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/fi-fi/tuki/matkatavarat/rajoitukset/',
    conditionOfCarriage:
      'https://www.icelandair.com/fi-fi/tuki/ehdot-ja-maaraykset/conditions-of-carriage/',
    baggageAllowance: 'https://www.icelandair.com/fi-fi/tuki/matkatavarat/allowance/',
  },
  nl: {
    optionalFees:
      'https://www.icelandair.com/nl-nl/klantenservice/voorwaarden/optionele-diensten-en-kosten/',
    privacyPolicy: 'https://www.icelandair.com/nl-nl/klantenservice/voorwaarden/privacy-policy/',
    dangerousGoods: 'https://www.icelandair.com/nl-nl/klantenservice/bagage/restricties/',
    conditionOfCarriage:
      'https://www.icelandair.com/nl-nl/klantenservice/voorwaarden/vervoersvoorwaarden/',
    baggageAllowance: 'https://www.icelandair.com/nl-nl/klantenservice/bagage/bagagevrijstelling/',
  },
};
const ICELANDAIR_MANAGE_MY_BOOKING_LINKS = {
  en: {
    GB: 'https://www.icelandair.com/en-gb/support/pre-flight/manage-booking/',
    FR: 'https://www.icelandair.com/fr-ca/service-client/avant-depart/mes-reservations/',
    CA: 'https://www.icelandair.com/en-ca/support/pre-flight/manage-booking/',
    US: 'https://www.icelandair.com/support/pre-flight/manage-booking/',
  },
  is: 'https://www.icelandair.com/is/adstod/fyrir-flugid/umsjon-med-bokun/',
  de: 'https://www.icelandair.com/de-de/hilfe/vor-dem-flug/meine-buchungen/',
  fr: 'https://www.icelandair.com/fr-fr/service-client/avant-depart/mes-reservations/',
  da: 'https://www.icelandair.com/da-dk/support/for-afgang/administrer-booking/',
  no: 'https://www.icelandair.com/no-no/kundeservice/for-flygningen/administrer-booking/',
  sv: 'https://www.icelandair.com/sv-se/support/fore-avfard/hantera-bokning/',
  fi: 'https://www.icelandair.com/fi-fi/tuki/ennen-lentoa/manage-booking/',
  nl: 'https://www.icelandair.com/nl-nl/klantenservice/voor-vertrek/mijn-boeking/',
  es: 'https://www.icelandair.com/es-es/asistencia/antes-del-vuelo/manage-booking/',
  it: 'https://www.icelandair.com/it-it/supporto/prima-del-volo/manage-booking/',
  ru: 'https://www.icelandair.com/ru-ru/support/pre-flight/manage-booking/',
};

/**
 * Gets Icelandair manage my booking links by language or language or residency
 * @param {String} language - Language code
 * @param {String} residency - Residency code
 * @returns {String} link
 */
export function getIcelandairManageMyBookingLinks(language, residency) {
  const EN_CODE = 'en';

  if (language === EN_CODE) {
    // default to default english version
    let langKeyCode = 'US';

    switch (residency) {
      case 'GB':
        langKeyCode = 'GB';
        break;
      case 'FR':
        langKeyCode = 'FR';
        break;
      case 'CA':
        langKeyCode = 'CA';
        break;

      default:
        break;
    }

    return ICELANDAIR_MANAGE_MY_BOOKING_LINKS[EN_CODE][langKeyCode];
  }

  return (
    ICELANDAIR_MANAGE_MY_BOOKING_LINKS[language] || ICELANDAIR_MANAGE_MY_BOOKING_LINKS[EN_CODE]
  );
}

export const DOHOP_CONNECT_LINK = 'https://support.dohop.com/';

const VENDOR_ID_TO_AMEX_SUPPORT = Set([
  // EasyJet vendors
  1378, // easyJet staging BOOKING [test]
  1341, // easyJet worldwide BOOKING
  1336, // easyjet worldwide staging BOOKING [test]
  // Norwegian Vendors
  1666, // Norwegian dev
  1667, // Norwegian prod
  // WestJet Vendors
  1229, // WestJet BOOK
  1380, // WestJet BOOK dohop_connect [test]
  1381, // WestJet BOOK gatwick_connect [test]
  1344, // WestJet staging BOOK [test]
  // Air Transat Vendors
  1415, // Air Transat
  1414, // Air Transat testing [test]
  // Virgin Atlantic Vendors
  1386, // Virgin Atlantic BOOK EZY Worldwide
  1429, // Virgin Atlantic BOOK EZY Worldwide pre-prod [test]
  1385, // Virgin Atlantic BOOK EZY Worldwide testing [test]
  // La Compagnie Vendors
  1377, // La Compagnie Prod
  1371, // La Compagnie testing [test]
  // Travelfusion vendors
  1358, // Eurowings via Travelfusion
  1360, // FlyBe via Travelfusion
  1383, // Jetstar via Jetstar
  1390, // Tigerair via Tigerair
  1444, // Jetstar via Tigerair dev
  1445, // TTW via Jetstar dev
  // Transavia vendors
  1465, // Transavia test
  1501, // Transavia preprod
  1498, // Transavia prod
  1542, // Indigo on Jetstar
  // Air France vendor
  1538, // Air France test
  1581, // Air France test
  1590, // Air France prod
  1592, // Air France prod
  // Norse vendors
  5031, // Norse dev
  5044, // Norse prod
]);

const VENDOR_ID_TO_DINERS_SUPPORT = Set([
  1360, // FlyBe via Travelfusion
]);

const VENDOR_ID_TO_JCB_SUPPORT = Set([
  1383, // Jetstar via Jetstar
  1390, // Tigerair via Tigerair
  1444, // Jetstar via Tigerair dev
  1445, // TTW via Jetstar dev
]);

/**
 * @param {Object} fares the fare object
 * @param {Immutable.Set} cardVendorSet a set of vendors that support a given card type
 * @returns {boolean} true if vendor supports a given card type
 */
function vendorSupportsCardtype(fares, cardVendorSet) {
  const vendorIds = fares.split('--').reduce((acc, curr) => {
    const vendorID = parseInt(curr.split('-')[2], 10);
    acc.push(vendorID);
    return acc;
  }, []);
  return vendorIds.reduce((prev, curr) => prev && cardVendorSet.has(curr), true);
}

export function hasAmexSupport(fares) {
  return vendorSupportsCardtype(fares, VENDOR_ID_TO_AMEX_SUPPORT);
}

export function hasDinersSupport(fares) {
  return vendorSupportsCardtype(fares, VENDOR_ID_TO_DINERS_SUPPORT);
}

export function hasJcbSupport(fares) {
  return vendorSupportsCardtype(fares, VENDOR_ID_TO_JCB_SUPPORT);
}

export function shouldUseIncBagSelection(vendor) {
  const vendorsUsingIncBagSelection = [SUNEXPRESS, ETIHAD];
  return vendorsUsingIncBagSelection.includes(vendor);
}
