import React from 'react';
import FilterIcon from '../../../content/icons/filter_icon.svg';
import './NewResultsPopup.less';

const NewResultsPopup = props => {
  const { title, txt, btn, setNewResultsPopup } = props;
  return (
    <div className="NewResultsPopup">
      <div className="NewResultsPopup__content">
        <FilterIcon className="NewResultsPopup__contentIcon" />
        <span className="NewResultsPopup__content__title">{title}</span>
        <span className="NewResultsPopup__content__txt">{txt}</span>
      </div>
      <button onClick={() => setNewResultsPopup(false)} className="NewResultsPopup__btn">
        {btn}
      </button>
    </div>
  );
};

export default NewResultsPopup;
