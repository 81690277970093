import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ButtonRounded.less';

const ButtonRounded = ({ type, children, className, onClick, color }) => {
  const otherProps = {};
  let colorClassName = '';

  if (color) {
    switch (color) {
      case 'white':
        colorClassName = 'ButtonRounded--white';
        break;

      case 'grass':
        colorClassName = 'ButtonRounded--grass';
        break;

      case 'ocean':
        colorClassName = 'ButtonRounded--ocean';
        break;

      default:
        break;
    }
  }

  if (onClick) {
    otherProps.onClick = onClick;
  }

  return (
    <button
      type={type}
      className={classNames('ButtonRounded', colorClassName, className)}
      {...otherProps}
    >
      {children}
    </button>
  );
};

ButtonRounded.propTypes = {
  type: 'button',
  classNames: '',
  onClick: () => {},
  color: '',
};

ButtonRounded.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  classNames: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

export default ButtonRounded;
