import time from 'dohop-client-api/src/time';
import translate from './translate';

export default function formatDuration(context, ms) {
  let t = translate.bind(null, context);
  let a = [];
  if (ms >= time.day) a.push(`${Math.floor(ms / time.day)}${t('dayAccronym')}`);
  // prettier-ignore
  a.push(`${Math.floor((ms % time.day) / time.hour)}${t('hourAccronym')}`);
  // prettier-ignore
  a.push(('0' + Math.floor((ms % time.hour) / time.minute)).slice(-2) + t('minuteAccronym'));
  return a.join(' ');
}
