import moment from 'moment';
import { months } from '../components/DatePicker/Utils';
import { getQueryParamsAsObject } from './getFlightUrl';

const REG_DATES = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;
const REG_FLEXIBLE_DATES = /[a-zA-Z]{3}[0-9]{2}-[a-zA-Z]{3}[0-9]{2}/;

export default function getGoParams(pathParams, queryParams = '') {
  if (!pathParams) return;

  pathParams = Object.values(pathParams)
    .map(p => p)
    .filter(v => v);

  const search = {};
  const dates = pathParams.filter(param => param.match(REG_DATES));
  const flexibleDate = pathParams
    .filter(param => param.match(REG_FLEXIBLE_DATES))
    .map(d => d.split('-'))[0];
  pathParams = pathParams.filter(
    param => !param.match(REG_DATES) && !param.match(REG_FLEXIBLE_DATES)
  );

  if (dates.length) {
    search.d1 = moment.utc(dates[0], 'YYYY-MM-DD');
    search.d1_ = moment.utc(dates[0], 'YYYY-MM-DD');
    search.oneWay = dates.length === 1;
    if (dates.length === 2) {
      search.stay = Array(2)
        .fill(moment.utc(dates[1], 'YYYY-MM-DD').diff(search.d1, 'days'))
        .join('-');
    }
    search.flexibleDate = false;
  } else if (flexibleDate) {
    const getDate = d =>
      moment
        .utc()
        .month(months.indexOf(d.substring(0, 3)))
        .year('20' + d.substr(3));
    search.d1 = getDate(flexibleDate[0]).startOf('month');
    search.d1_ = getDate(flexibleDate[1]).endOf('month');
    search.flexibleDate = true;
  }

  if (pathParams.length === 2) {
    search.origin = pathParams[0].replace(/-/g, ',');
    search.destination = pathParams[1].replace(/-/g, ',');
  } else if (pathParams.length === 1) {
    search.destination = pathParams[0].replace(/-/g, ',');
  }

  // Adding query params.
  queryParams = getQueryParamsAsObject(queryParams);
  Object.keys(queryParams).forEach(key => {
    search[key] = queryParams[key];
  });

  return search;
}
