import animate from './animate';

/* Generic dom utilities, created as needed */

// Returns an array of DOM node acenstor tree.
// example to check if the clicked element is an a tag:
//   getAncestors(clicked, {includeSelf: true}).some(node => node.tagName === 'A');
export function getAncestors(node, { includeSelf = false } = {}) {
  let ancestors = [];
  if (includeSelf) ancestors.push(node);
  while (node) {
    node = node.parentNode;
    if (node) ancestors.push(node);
  }
  return ancestors;
}

// returns true if an element is not part of the rendered dom tree
export function isDetached(el) {
  return (
    el !== document && !getAncestors(el, { includeSelf: true }).some(n => n.tagName === 'HTML')
  );
}

export function onDocumentReady(fn) {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', fn);
  } else {
    fn();
  }
}

// http://youmightnotneedjquery.com/#trigger_native
export function triggerNativeEvent(el, eventName) {
  // For a full list of event types:
  // https://developer.mozilla.org/en-US/docs/Web/API/document.createEvent
  let event = document.createEvent('HTMLEvents');
  event.initEvent(eventName, true, false);
  el.dispatchEvent(event);
}

// http://stackoverflow.com/a/18072246
export function submitForm(form) {
  let button = form.ownerDocument.createElement('input');
  button.style.display = 'none';
  button.type = 'submit';
  form.appendChild(button).click();
  form.removeChild(button);
}

// get document scroll from top
export function getScrollTop() {
  return document.body.scrollTop || document.documentElement.scrollTop || 0;
}

// returns true if child is inside el, or if el and child are the same element
export function containsElement(el, child) {
  return el === child || el.contains(child);
}

export function scrollTo(pos, { duration = 300, offset = 0 } = {}) {
  // find top of element if pos is a DOM element
  if (pos && pos.getBoundingClientRect) {
    pos = getScrollTop() + pos.getBoundingClientRect().top;
  }

  animate({
    duration,
    startValue: getScrollTop(),
    endValue: pos + offset,
    cb: value => window.scrollTo(0, value),
  });
}
