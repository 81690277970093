import React from 'react';
import ItinerarySeparator from '../../../ItinerarySeparator';
import './DesktopLoading.less';

const DesktopLoading = () => (
  <div className="DesktopLoading" aria-hidden="true">
    <div className="DesktopLoading__itinerary">
      <div className="DesktopLoading__itinerary__tiles">
        <span className="DesktopLoading__itinerary__tiles--airline" />
        <span className="DesktopLoading__itinerary__tiles--outbound" />
        <span className="DesktopLoading__itinerary__tiles--homebound" />
        <span className="DesktopLoading__itinerary__tiles--details" />
        <span className="DesktopLoading__itinerary__tiles--share" />
      </div>
    </div>
    <ItinerarySeparator />
    <div className="DesktopLoading__price">
      <div className="DesktopLoading__price__tiles">
        <span className="DesktopLoading__price__tiles--vendor" />
        <span className="DesktopLoading__price__tiles--price" />
        <span className="DesktopLoading__price__tiles--btn" />
      </div>
    </div>
  </div>
);

export default DesktopLoading;
