import Immutable from 'immutable';
import picker from 'dohop-client-api/src/picker';
import { queries } from '@components/MatchMedia';
import { createAction, createImmutableAction } from '../lib/redux/actions';
import axiosWrapper from '../lib/axiosWrapper';
import processException from '../lib/processException';
import { requestHotelAutocomplete } from './autocompleteActions';
import _zipObject from 'lodash/zipObject';
import time from 'dohop-client-api/src/time';
import { getCookie, setCookie } from '../lib/redux/cookie';
import { FORM_COOKIE_NAME, looksLikeAirportCode } from './flightSearchFormActions';

export const HOTEL_RECEIVE_DESTINATION = 'RECEIVE_DESTINATION';
export const HOTEL_RECEIVE_CHECKIN = 'RECEIVE_CHECKIN';
export const HOTEL_RECEIVE_CHECKOUT = 'RECEIVE_CHECKOUT';
export const HOTEL_RECEIVE_STAY_DURATION = 'RECEIVE_STAY_DURATION';
export const HOTEL_RECEIVE_MONTH = 'RECEIVE_MONTH';
export const HOTEL_RECEIVE_FLEXIBLE = 'RECEIVE_FLEXIBLE';
export const HOTEL_RECEIVE_GUEST_VALUE = 'RECEIVE_GUEST_VALUE';
export const HOTEL_RECEIVE_GUESTS = 'RECEIVE_GUESTS';
export const HOTEL_RECEIVE_CHILD_AGE = 'RECEIVE_CHILD_AGE';
export const HOTEL_RECOMMENDED_SUCCESS = 'HOTEL_SEARCH_RECOMMENDED_SUCCESS';
export const HOTEL_RECOMMENDED_FAILED = 'HOTEL_SEARCH_RECOMMENDED_SUCCESS';
export const HOTEL_RECEIVE_COOKIEKEYS = 'HOTEL_RECEIVE_COOKIEKEYS';

export const setDestinationAction = createImmutableAction(HOTEL_RECEIVE_DESTINATION, 'destination');
export const setCheckinAction = createAction(HOTEL_RECEIVE_CHECKIN, 'date');
export const setCheckoutAction = createAction(HOTEL_RECEIVE_CHECKOUT, 'date');
export const setStayDuration = createAction(HOTEL_RECEIVE_STAY_DURATION, 'stay');
export const setMonth = createAction(HOTEL_RECEIVE_MONTH, 'date');
export const setIsFlexible = createAction(HOTEL_RECEIVE_FLEXIBLE, 'flexible');
export const setGuestOptionValue = createAction(HOTEL_RECEIVE_GUEST_VALUE, 'value');
export const setGuestsAction = createAction(HOTEL_RECEIVE_GUESTS, 'count');
export const setChildAge = createAction(HOTEL_RECEIVE_CHILD_AGE, 'id', 'age');

const setRecommendedHotels = createAction(HOTEL_RECOMMENDED_SUCCESS, 'hotels');
const setRecommendedHotelsError = createAction(HOTEL_RECOMMENDED_FAILED, 'error');

export const setDestination = (destination) => saveCookieWrapper(setDestinationAction, destination);
export const setCheckin = (checkin) => saveCookieWrapper(setCheckinAction, checkin);
export const setCheckout = (checkout) => saveCookieWrapper(setCheckoutAction, checkout);
export const setGuests = (count) => saveCookieWrapper(setGuestsAction, count);

function saveSearchCookie() {
  return (dispatch, getState) => {
    let prevCookieValue = dispatch(getCookie(FORM_COOKIE_NAME));
    let [flightCookieValue, hotelCookieValue, carCookieValue] = (prevCookieValue || '').split('__');

    let { destination, checkinDate, checkoutDate, rooms, adults, childrenAges } = getState()
      .getIn(['hotel', 'form'])
      .toObject();

    hotelCookieValue = [
      destination ? destination.get('label') : '',
      checkinDate ? time.strftime('%Y-%m-%d', checkinDate) : '',
      checkoutDate ? time.strftime('%Y-%m-%d', checkoutDate) : '',
      rooms,
      adults,
      childrenAges.size,
    ].join('|');

    let cookieValue = [flightCookieValue, hotelCookieValue, carCookieValue].join('__');
    let cookieOptions = { path: '/', maxAge: 60 * 60 * 24 * 30 };
    dispatch(setCookie(FORM_COOKIE_NAME, cookieValue, cookieOptions));
  };
}

function saveCookieWrapper(func, ...args) {
  return (dispatch) => {
    dispatch(func(...args));
    dispatch(saveSearchCookie());
  };
}

export function fetchRecommendedHotels(airports) {
  return (dispatch) => {
    if (!airports) {
      dispatch(setRecommendedHotels(Immutable.List()));
      return Promise.resolve();
    }
    return dispatch(setRecommendedHotelsError('Service temporarily unavailable'));
  };
}

export function autoFillForm() {
  return (dispatch, getState) => {
    let cookieValue = dispatch(getCookie(FORM_COOKIE_NAME));
    const hotelCookieValue = (cookieValue || '').split('__')[1];

    let cookieKeys = ['destination', 'checkinDate', 'checkoutDate', 'rooms', 'adults', 'children'];
    let input = _zipObject(cookieKeys, hotelCookieValue ? hotelCookieValue.split('|') : []);

    if (!input.destination) return Promise.resolve();

    const checkinDate = new Date(input.checkinDate);
    const checkoutDate = new Date(input.checkoutDate);

    dispatch({
      type: HOTEL_RECEIVE_COOKIEKEYS,
      checkin: checkinDate,
      checkout: checkoutDate,
      rooms: Number(input.rooms),
      adults: Number(input.adults),
      children: Number(input.children),
    });

    return new Promise((resolve, reject) => {
      let onError = (e) => {
        processException(e);
        resolve();
      };
      let language = getState().getIn(['user', 'language']);

      const onAutocompleteSuccess = (cities) => {
        if (cities.length) {
          dispatch(setDestinationAction(cities[0].item));
        }
        resolve();
      };

      if (!looksLikeAirportCode(input.destination)) {
        requestHotelAutocomplete(input.destination, language)
          .then(onAutocompleteSuccess)
          .catch(onError);
      } else {
        picker.getAirportFromCode(input.destination, {
          language,
          error: onError,
          success: (airport) => {
            requestHotelAutocomplete(airport.name, language)
              .then(onAutocompleteSuccess)
              .catch(onError);
          },
        });
      }
    });
  };
}

const TRACKING_PARAM_LABEL = 'label=checkbox';
const TRACKING_PARAM_LABEL_MOBILE = 'label=checkboxMobile';
const HOTEL_BASE_URL = `https://hotel.dohop.com/?${TRACKING_PARAM_LABEL}`;

/**
 * Makes get request to web_api hotels/comparison to get hotel whitelabel booking url
 * @param {String} path - url path to web_api /hotels/comparison
 */
export function getHotelsByCity(path) {
  return async () => {
    try {
      if (!path) {
        return HOTEL_BASE_URL;
      }

      const res = await axiosWrapper.get(path);
      return res.data.url !== ''
        ? res.data.url +
            `&${queries.ipad.matches ? TRACKING_PARAM_LABEL : TRACKING_PARAM_LABEL_MOBILE}`
        : HOTEL_BASE_URL;
    } catch (error) {
      processException(error);
      return HOTEL_BASE_URL;
    }
  };
}
