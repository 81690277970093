export const DOHOP_VENDORS = [
  '1266',
  '1272',
  '1241',
  '1201',
  '1392',
  '1337',
  '1468',
  '1637',
  '1638',
  '1639',
];

export default function isDohopFare(fare) {
  if (!fare || !fare.isMulti || !fare.getVendor) {
    return false;
  }

  return !fare.isMulti() && DOHOP_VENDORS.includes(fare.getVendor().getKey());
}
