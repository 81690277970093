import React from 'react';
import config from '../../lib/config';
import Constraint from '../Constraint';
import classNames from 'classnames';
import './TrustBanner.less';
let allIcons = {
  is: ['icelandair', 'lufthansa', 'vueling', 'norwegian', 'british_airways', 'easyJet'],
  de: ['ryanair', 'airfrance', 'lufthansa', 'expedia', 'easyJet', 'TUIfly'],
  sc: ['easyJet', 'norwegian', 'ryanair', 'bravofly', 'kilroy', 'klm', 'sas', 'travellink'],
  gb: ['british_airways', 'easyJet', 'virgin_atlantic', 'flybe', 'ryanair'],
  us: [
    'aircanada',
    'american_airlines',
    'cheaptickets',
    'edreams',
    'flightnetwork',
    'kiwicom',
    'westjet',
  ],
  ae: ['Emirates', 'Etihad_Airways', 'Qatar_Airways', 'lufthansa', 'Swiss_International'],
  world: ['airfrance', 'american_airlines', 'Emirates', 'lufthansa', 'expedia'],
  cars: ['avis', 'Europcar', 'Hertz', 'Sixt'],
};

let TrustBanner = ({ site, residency, title, text, logos, subtitle }) => {
  const findResidencyIcons = (res) => {
    if (site === 'cars') {
      return 'cars';
    }
    switch (res) {
      case 'IS':
        return 'is';
      case 'DE':
        return 'de';
      case 'DK':
      case 'SE':
      case 'NO':
        return 'sc';
      case 'GB':
        return 'gb';
      case 'US':
      case 'CA':
        return 'us';
      case 'AE':
        return 'ae';
      default:
        return 'world';
    }
  };
  let res = findResidencyIcons(residency);
  let icons = allIcons[res];
  return (
    <div
      className={classNames('TrustBanner', {
        'TrustBanner--flights': site === 'flights',
        'TrustBanner--hotels': site === 'hotels',
        'TrustBanner--cars': site === 'cars',
      })}
    >
      <div className="TrustBanner__content">
        <h2 className="TrustBanner__content--title">{title}</h2>
        <p className="TrustBanner__content--txt">{text}</p>
        <Constraint front>
          {logos ? (
            <div className={`TrustBanner__logos TrustBanner__logos--${res}`}>
              {icons.map((icon) => {
                return (
                  <div
                    key={icon}
                    className={`TrustBanner__container TrustBanner__container--${res}_${icon}`}
                  >
                    <img
                      src={`${config.STATIC_URL}/trustbanner/${icon}_logo_white.svg`}
                      className={`TrustBanner__logo TrustBanner__logo--${res}_${icon}`}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="TrustBanner__subtitle">{subtitle}</div>
          )}
        </Constraint>
      </div>
    </div>
  );
};

export default TrustBanner;
