import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import propTypesDohop from '../../../../lib/propTypes';
import ItineraryDetailedRoute from '../ItineraryDetailedRoute';
import createFilters from '../../../../lib/createFilters';
import './ItineraryDetails.less';

class ItineraryDetails extends Component {
  render() {
    const { itinerary, outboundFlightplan, inboundFlightplan, visible } = this.props;
    const routes = itinerary.getRoutes();

    return (
      <div
        className={classNames('ItineraryDetails', {
          ItineraryDetails__open: visible,
        })}
      >
        <div className="ItineraryDetails__line" />
        {routes.map((route, idx) => (
          <div className="ItineraryDetails__route" key={`ItineraryDetails__route-${idx}`}>
            <ItineraryDetailedRoute
              flightPlan={idx === 0 ? outboundFlightplan : inboundFlightplan}
              route={route}
              itinerary={itinerary}
            />
          </div>
        ))}
      </div>
    );
  }
}

ItineraryDetails.propTypes = {
  itinerary: propTypesDohop.itinerary,
  visible: PropTypes.bool,
};

ItineraryDetails = createFilters(['t', 'formatDate', 'formatDuration'])(ItineraryDetails);

export default ItineraryDetails;
