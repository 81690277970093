const supportedLanguages: { [key: string]: string[] } = {
  aegean: ['en', 'fr', 'de', 'es', 'it', 'el'],
  airfrance: ['en', 'fr', 'it', 'pt', 'es', 'de'],
  airtransat: ['en', 'fr'],
  avianca: ['en', 'es', 'de', 'pt'],
  cebu: ['en'],
  citilink: ['en'],
  dohopconnect: ['en', 'fr', 'de'],
  easyjet: ['en', 'fr', 'es', 'de', 'nl', 'it', 'pt'],
  jazeera: ['en'],
  jetstar: ['en', 'ja', 'zh', 'zht', 'id', 'th', 'vi'],
  lacompagnie: ['en', 'fr'],
  norwegian: ['en', 'no'],
  tigerairtw: ['en', 'ja', 'zht', 'th', 'ko'],
  transavia: ['en', 'nl', 'fr', 'es', 'de', 'it', 'pt'],
  volotea: ['en', 'es', 'it', 'fr', 'pt', 'de'],
  vueling: ['en', 'es', 'it', 'fr', 'de'],
  default: ['en'],
  thaivietjet: ['en', 'th'],
  norse: ['en', 'fr', 'de', 'no', 'it'],
};

export function isSupportedLanguage(language: string, partner?: string) {
  const partnerKey = partner?.toLowerCase();
  let supportedLanguagesForPartner = supportedLanguages.default;
  if (partnerKey && supportedLanguages[partnerKey]) {
    supportedLanguagesForPartner = supportedLanguages[partnerKey];
  }
  return supportedLanguagesForPartner.indexOf(language) !== -1;
}

export function getSupportedLanguages(partner: string) {
  return supportedLanguages[partner] || supportedLanguages.default;
}
