/**
 * Creates query string from params object
 * @param {Object} params f.ex. params = {a: 1, b: 2}
 * @returns {string} f.ex. 'a=1&b=2'
 */
export function encodeURLQueryData(params) {
  const queryStringArr = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryStringArr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }
  }

  return queryStringArr.join('&');
}

/**
 * Creates query string object with key value pairs from query string
 * Note that if the have ?hello=1&hello=2 we will only use the last one
 * @param {string} queryString f.ex. queryString = "?foo=bar&baz=foo"
 * @returns {Object<string, string>} parsed query string, f.ex. { foo: "bar", baz: "foo" }
 */
export function decodeURLQueryData(queryString) {
  const params = {};
  const queryArr = queryString.substring(1).split('&');

  for (const query of queryArr) {
    const pair = query.split('=');
    params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return params;
}
