import React from 'react';
import ItinerarySeparator from '../../../ItinerarySeparator';
import './MobileLoading.less';

const MobileLoading = () => (
  <div className="MobileLoading" aria-hidden="true">
    <div className="MobileLoading__body">
      <span className="MobileLoading__body__tile--airlines" />
      <span className="MobileLoading__body__tile--itinerary" />
      <div className="MobileLoading__line" />
      <span className="MobileLoading__body__tile--airlines" />
      <span className="MobileLoading__body__tile--itinerary" />
    </div>
    <ItinerarySeparator />
    <div className="MobileLoading__footer">
      <span className="MobileLoading__footer__tiles--vendor" />
      <div className="MobileLoading__footer__tiles">
        <span className="MobileLoading__footer__tiles--price" />
        <span className="MobileLoading__footer--btn" />
      </div>
    </div>
  </div>
);

export default MobileLoading;
