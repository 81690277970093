import getRealIp from '../getIp';

const REQUEST_IP = '__REQUEST_IP__';

export function requestMiddleware(request) {
  return () => {
    return next => action => {
      if (action.type === REQUEST_IP) {
        return getRealIp(request);
      }
      return next(action);
    };
  };
}

export function getIp() {
  return {
    type: REQUEST_IP,
  };
}
