import React from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../../../../MatchMedia';
import MobileLoading from './MobileLoading';
import DesktopLoading from './DesktopLoading';
import '../index.less';

const ItineraryLoadingCard = ({ size }) => (
  <div>
    {Array(size)
      .fill()
      .map((v, i) => (
        <div key={i} aria-hidden="true">
          <MatchMedia max="ipad">
            <MobileLoading />
          </MatchMedia>
          <MatchMedia min="ipad">
            <DesktopLoading />
          </MatchMedia>
        </div>
      ))}
  </div>
);

ItineraryLoadingCard.propTypes = {
  // Reflecting the number of cards you get
  size: PropTypes.number,
};

ItineraryLoadingCard.defaultProps = {
  size: 5,
};

export default ItineraryLoadingCard;
