import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import createFilters from '../../lib/createFilters';
import getGoParams from '../../lib/getGoParams';
import Constraint from '../Constraint';
import ResultsHeader from '../flights/ResultsHeader';
import Section from '../Section';
import DohopHead from '../DohopHead';
import GoDestinations from './GoDestinations';
import GoContent from './GoContent';
import Footer from '../Footer';
import Filters from './GoFilters';
import { formatFlexibleMonths } from '../DatePicker/Utils';
import MatchMedia from '../MatchMedia';
import LoadingBar from '../flights/LoadingBar';
import { GO_LOADING_TIME } from '@actions/goActions';
import './GoFrontPage.less';

class GoFrontPage extends Component {
  renderHeaderSubtitle = () => {
    const { go, formatDate } = this.props;
    if (go.get('flexibleDate')) {
      return formatFlexibleMonths(go, this.props.t, formatDate);
    }
    const returnDate =
      go.get('oneWay') || !go.get('stay')
        ? ''
        : ' \u2014 ' +
          formatDate(go.get('d1').clone().add(go.get('stay').split('-')[0], 'days').toDate(), 'll');
    return formatDate(go.get('d1').toDate(), 'll') + returnDate;
  };

  render() {
    const {
      t,
      form,
      go,
      search,
      match: { params },
    } = this.props;
    const Results =
      !go.get('destination') || go.getIn(['destination', 'isCountry']) ? GoDestinations : GoContent;
    const goParams = getGoParams(params);
    const ogImage = go.get('destination') ? go.getIn(['results', 'go', 0, 'image'], null) : null;
    const originName = go.getIn(['origin', 'name']);
    const destinationName = go.getIn(['destination', 'name']);

    let title = `${t('away_heading')} ${originName}`;
    title += destinationName
      ? ` ${t('to').toLowerCase()} ${destinationName}`
      : ` ${t('to').toLowerCase()} ${goParams.destination}`;
    title += ' | Dohop';
    const loadingBarProps = {
      duration: GO_LOADING_TIME,
      start: go.get('fetchStart'),
    };

    return (
      <div className="GoFrontPage PageRouteAnimation__fadeIn">
        <DohopHead title={title} metaDescription={t('og_description_go')} ogImage={ogImage} />
        <Section header>
          <ResultsHeader
            form={form}
            go={go}
            renderSubtitle={this.renderHeaderSubtitle}
            useGo
            search={search}
            loadingBar={loadingBarProps}
          />
        </Section>
        <Section content>
          <LoadingBar {...loadingBarProps} />
          <Constraint wide className="GoFrontPage__content">
            <div className="GoFrontPage__left">
              <MatchMedia min="desktop">
                <Filters t={this.props.t} />
              </MatchMedia>
            </div>
            <div className="GoFrontPage__results">
              <Results t={t} history={this.props.history} />
            </div>
          </Constraint>
          <Constraint laptop>
            <Footer line={false} />
          </Constraint>
        </Section>
      </div>
    );
  }
}

GoFrontPage.defaultProps = {
  destination: Map(),
};

GoFrontPage.propTypes = {
  destination: PropTypes.object,
  search: PropTypes.object.isRequired,
};

/**
 * Maps redux state to component props
 * @param {Object} state - Redux root state
 * @returns {Object}
 */
function mapStateToProps(state) {
  return {
    destination: state.getIn(['go', 'destination']),
    search: state.get('flightSearch'),
  };
}

export default createFilters(['t', 'formatDate'])(connect(mapStateToProps, null)(GoFrontPage));
