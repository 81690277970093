import React from 'react';
import PropTypes from 'prop-types';
import './BackButton.less';

const BackButton = ({ t, onClick }) => {
  return (
    <div className=" BackButton" role="button" tabIndex={0} onClick={onClick}>
      <div className="BackButton__content">
        <span className="BackButton__arrow" />
        <span className="BackButton__txt">{t('backtoresults')}</span>
      </div>
    </div>
  );
};

BackButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: () => {},
};

export default BackButton;
