import Immutable from 'immutable';
import userReducer from './userReducer';
import requestReducer from './requestReducer';
import responseReducer from './responseReducer';
import experimentReducer from './experimentReducer';
import flightSearchReducer from './flightSearchReducer';
import flightSearchFormReducer from './flightSearchFormReducer';
import hotelReducer from './hotelReducer';
import carSearchReducer from './carSearchReducer';
import goReducer from './goReducer';
import transferReducer from './transferReducer';
import dateMatrixReducer from './dateMatrixReducer';
import filtersReducer from './filtersReducer';

export default function dohopReducer(state = Immutable.Map(), action) {
  return state.merge({
    user: userReducer(state.get('user'), action),
    request: requestReducer(state.get('request'), action),
    response: responseReducer(state.get('response'), action),
    experiments: experimentReducer(state.get('experiments'), action),
    flightSearch: flightSearchReducer(state.get('flightSearch'), action),
    flightSearchForm: flightSearchFormReducer(state.get('flightSearchForm'), action),
    hotel: hotelReducer(state.get('hotel'), action),
    cars: carSearchReducer(state.get('cars'), action),
    go: goReducer(state.get('go'), action),
    transfer: transferReducer(state.get('transfer'), action),
    dateMatrix: dateMatrixReducer(state.get('dateMatrix'), action),
    filters: filtersReducer(state.get('filters'), action),
  });
}
