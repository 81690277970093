import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import createFilters from '../lib/createFilters';
import isSupportedLanguage from '../lib/isSupportedLanguage';
import globals from '../globals';

class DohopHead extends Component {
  getDomainURL() {
    const { request } = this.props;
    return `https://${request.get('hostname')}`;
  }

  getFullPath() {
    return this.getFullURL().split('?')[0];
  }

  getPath() {
    const {
      location: { search, pathname },
    } = this.props;
    let path = pathname;

    // Remove trailing slash from canonical urls.
    // This is done to prevent duplicate content issues with search engines,
    // i.e. https://www.dohop.com/cars
    //      https://www.dohop.com/cars/
    // The search engine will assume this as duplicate content,
    // thats why we remove the traling slash
    if (!search && pathname.endsWith('/')) {
      path = pathname.replace(/\/$/, '');
    }

    return path + search;
  }

  getFullURL() {
    return this.getDomainURL() + this.getPath();
  }

  gethtmlAttributes() {
    const { user } = this.props;
    return { lang: user.get('language') };
  }

  render() {
    const {
      t,
      link,
      canonical,
      hrefLang = true,
      meta,
      metaDescription,
      metaKeywords,
      metaRobots,
      metaDistribution,
      openGraph,
      ogImage,
      ogTitle,
      ogURL,
      ogDescription,
      ogSiteName,
    } = this.props;
    let { title } = this.props;
    const linkArr = (link || []).slice();
    const metaArr = (meta || []).slice();

    if (!title) {
      title = t('site_title');
    }

    if (canonical) {
      linkArr.push({ rel: 'canonical', href: this.getFullPath() });
    }

    if (hrefLang) {
      globals('languages')
        .filter(lang => isSupportedLanguage(lang.get('code')))
        .forEach(lang => {
          const code = lang.get('code');
          linkArr.push({
            rel: 'alternative',
            hrefLang: code,
            href: code === 'is' ? 'https://www.dohop.is/' : 'https://www.dohop.com/',
          });
        });
    }

    metaArr.push(
      { name: 'description', content: metaDescription || t('meta_description') },
      { name: 'keywords', content: metaKeywords || t('meta_keywords') },
      { name: 'robots', content: metaRobots || 'index, follow' },
      { name: 'distribution', content: metaDistribution || 'global' }
    );

    if (!openGraph) {
      metaArr.push(
        {
          property: 'og:image',
          content: ogImage || this.getDomainURL() + '/content/img/share.png',
        },
        { property: 'og:title', content: ogTitle || title },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: ogURL || this.getFullURL() },
        { property: 'og:description', content: ogDescription || t('og_description') },
        { property: 'og:site_name', content: ogSiteName || 'Dohop' }
      );
    }

    return (
      <Helmet
        title={title}
        link={linkArr}
        meta={metaArr}
        htmlAttributes={this.gethtmlAttributes()}
      />
    );
  }
}

DohopHead = createFilters(['t'])(DohopHead);

export default withRouter(
  connect(state => ({
    request: state.get('request'),
    user: state.get('user'),
  }))(DohopHead)
);
