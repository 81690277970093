import React from 'react';
import classNames from 'classnames';
import config from '../../lib/config';
import createFilters from '../../lib/createFilters';
import Constraint from '../Constraint';
import './PageInfoBanner.less';

class PageInfoBanner extends React.Component {
  getIconList() {
    const { bannerType, t } = this.props;
    const iconLists = {
      flightsBottom: [
        {
          img: 'cheapest-flights.svg',
          title: t('cheapest_flights_title'),
          description: t('cheapest_options', {
            hotelLinkStart: '<a href="/hotels" target="_blank" >',
            carLinkStart: '<a href="/cars" target="_blank">',
            linkEnd: '</a>',
          }),
        },
        {
          img: 'unique-connections.svg',
          title: t('unique_connections_title'),
          description: t('unique_connections'),
        },
        {
          img: 'protection.svg',
          title: t('dohop_guarantee'),
          description: t('dohop_protection_description', {
            linkStart: '<a href="https://support.dohop.com/" target="_blank">',
            linkEnd: '</a>',
          }),
        },
      ],
      hotelsTop: [
        {
          img: 'hotel-room.svg',
          title: t('search_dohop_hotels'),
          description: t('hotels_benefits', {
            linkStart:
              '<a href=' +
              '"https://hotel.dohop.com/index.en-gb.html?aid=946321;label=frontpagetext"' +
              'target="_blank" >',
            linkEnd: '</a>',
          }),
        },
        {
          img: 'piggy.svg',
          title: t('daily_deals_title'),
          description: t('daily_deals'),
        },
        {
          img: 'pen.svg',
          title: t('change_booking_title'),
          description: t('change_booking'),
        },
      ],
      hotelsBottom: [
        {
          img: 'discount.svg',
          title: t('daily_discounts_title'),
          description: t('daily_discounts'),
        },
        {
          img: 'compare-prices.svg',
          title: t('compare_prices_hotels_title'),
          description: t('compare_prices_hotels'),
        },
        {
          img: 'search.svg',
          title: t('simplify_search_title'),
          description: t('simplify_search'),
        },
      ],
      cars: [
        {
          img: 'piggy.svg',
          title: t('compare_prices_title'),
          description: t('compare_prices'),
        },
        {
          img: 'info.svg',
          title: t('free_cancellation_title'),
          description: t('free_cancellation'),
        },
        {
          img: 'star.svg',
          title: t('no_added_fees_title'),
          description: t('no_added_fees'),
        },
      ],
    };
    return iconLists[bannerType];
  }

  render() {
    const { bannerType, t } = this.props;
    const iconList = this.getIconList();
    return (
      <div
        className={classNames(
          'PageInfoBanner__container',
          `PageInfoBanner__container--${bannerType}`
        )}
      >
        <Constraint front>
          <div className={classNames('PageInfoBanner', `PageInfoBanner--${bannerType}`)}>
            {iconList.map((icon, index) => (
              <div key={index} className="PageInfoBanner__iconObject__container">
                <div className="PageInfoBanner__iconObject">
                  <div className="PageInfoBanner__image__container">
                    <img
                      className="PageInfoBanner__image"
                      src={`${config.STATIC_URL}/pageinfobanner/${icon.img}`}
                    />
                  </div>
                  <div className="PageInfoBanner__text__container">
                    {Boolean(icon.title) && (
                      <div
                        className={classNames(
                          'PageInfoBanner__text__title',
                          `PageInfoBanner__text__title--${bannerType}`
                        )}
                      >
                        {t(icon.title)}
                      </div>
                    )}
                    <div
                      className="PageInfoBanner__text__description"
                      dangerouslySetInnerHTML={{
                        __html: icon.description,
                      }}
                    />
                    {Boolean(icon.linkText) && (
                      <div className="PageInfoBanner__link__container">
                        <a className="PageInfoBanner__link" href={icon.link} target="_blank">
                          {icon.linkText}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Constraint>
      </div>
    );
  }
}

export default createFilters(['t'])(PageInfoBanner);
