import pick from 'lodash/pick';
import GA_PARAMS from './googleAnalyticsTrackingParams';
import { encodeURLQueryData, decodeURLQueryData } from './transformURLQueryData';

// The only qeury params we allow to add/change in the deeplink
const ALLOWED_QUERY_PARAMS = GA_PARAMS;

/**
 * Add query parameters if they are present and allowed
 *
 * @param  {String} deeplink - A url the user is about to click or be redirected to
 * @return {String} the url with added query parameters if they are allowed
 */
export function formatTransferQueryParams(deeplink) {
  const queries = decodeURLQueryData(location.search);
  const queryData = encodeURLQueryData(pick(queries, ALLOWED_QUERY_PARAMS));
  if (!queryData) {
    return deeplink;
  }

  const hasQuery = deeplink.search(/\?/) > -1;
  return `${deeplink}${hasQuery ? '&' : '?'}${queryData}`;
}
