import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HeaderTabs from './HeaderTabs';
import MobileMenu from './MobileMenu';
import Settings from '../user/Settings';
import LogoSvg from '@content/svg/logo.svg';
import './Bar.less';

/**
 * The top bar of headers, containing navigation and user-settings.
 * Not functional component so it can be given ref
 */
const Bar = ({ tab, colorBackground, url, logo, barRef, className, isFrontpage, noPadding }) => {
  return (
    <header
      className={classNames('Bar', className, {
        'Bar--white': !isFrontpage && !colorBackground,
        'Bar--noPadding': noPadding,
      })}
      ref={barRef}
    >
      <div className="Bar__container Bar__container--mobile">
        {logo && (
          <a href={url} alt="Dohop" className="Bar__link">
            <LogoSvg className="Bar__logo" />
          </a>
        )}
        <Settings dark={colorBackground} />
        <MobileMenu className="Bar__hamburger" hamburgerWhite={colorBackground} />
      </div>
      <div className="Bar__container">
        {logo && (
          <Link to={url}>
            <LogoSvg className="Bar__logo" />
          </Link>
        )}
        <HeaderTabs tab={tab} colorBackground={colorBackground} />
        <div className="Bar__right">
          <Settings dark={colorBackground} />
        </div>
      </div>
    </header>
  );
};

Bar.defaultProps = {
  noPadding: false,
  url: '/',
  colorBackground: false,
  tab: 'flights',
  logo: true,
  isFrontpage: false,
};

Bar.propTypes = {
  noPadding: PropTypes.bool,
  url: PropTypes.string,
  tab: PropTypes.oneOf(['flights', 'hotels', 'cars', 'go', 'more']),
  colorBackground: PropTypes.bool,
  logo: PropTypes.bool,
  isFrontpage: PropTypes.bool,
};

export default Bar;
