import Immutable from 'immutable';
import zipObject from 'lodash/zipObject';

export function createAction(type, ...keys) {
  return (...values) => {
    let action = zipObject(keys, values);
    action.type = type;
    return action;
  };
}

export function createImmutableAction(type, ...keys) {
  return (...values) => {
    let action = zipObject(keys, values.map(v => Immutable.fromJS(v)));
    action.type = type;
    return action;
  };
}
