import React, { Component } from 'react';
import MatchMedia from '../MatchMedia';
import Filters from './Filters';
import Footer from '../Footer';
import Results from './Results';
import Section from '../Section';
import Constraint from '../Constraint';
import ExploreBoxes from '../ads/ExploreBoxes';
import HotelsExplore from '../ads/HotelsExplore';
import DohopHead from '../DohopHead';
import ResultsHeader from './ResultsHeader';
import './SearchPage.less';

class SearchPage extends Component {
  render() {
    const {
      form,
      search,
      searchData,
      params,
      location,
      filters: { t },
      flightSearch,
    } = this.props;
    const destination = flightSearch.getIn(['destination', 'name']);
    const origin = flightSearch.getIn(['origin', 'name']);

    return (
      <div className="SearchPage PageRouteAnimation__fadeIn">
        <DohopHead
          canonical
          title={`${t('great_fares_from_to %(origin)s %(destination)s', {
            origin,
            destination,
          })} - Dohop`}
          ogTitle={t('found_flights_from_to %(origin)s %(destination)s', { origin, destination })}
          ogDescription={t('site_title')}
        />
        <Section header>
          <ResultsHeader form={form} search={search} searchData={searchData} params={params} />
        </Section>
        <Section content>
          <Constraint wide>
            <div className="SearchPage__left">
              <MatchMedia min="desktop">
                <Filters search={search} location={location} />
              </MatchMedia>
            </div>
            <div className="SearchPage__results">
              <Results {...this.props} />
            </div>
            <div className="SearchPage__right">
              <HotelsExplore search={search} />
              <ExploreBoxes t={t} />
            </div>
          </Constraint>
          <Constraint laptop>
            <Footer line={false} />
          </Constraint>
        </Section>
      </div>
    );
  }
}

export default SearchPage;
