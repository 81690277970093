import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getWeeksInMonth } from './Utils';
import Day from './DatePickerDay';
import './DatePickerMonth.less';

class DatePickerMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weeks: getWeeksInMonth(props.month, moment().startOf('week').isoWeekday() % 7),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.month.getUTCFullYear() !== nextProps.month.getUTCFullYear() ||
      this.props.month.getUTCMonth() !== nextProps.month.getUTCMonth()
    ) {
      this.setState({
        weeks: getWeeksInMonth(nextProps.month, moment().startOf('week').isoWeekday() % 7),
      });
    }
  }

  getWeekdayNames() {
    let weekdayNames = moment.weekdaysMin();
    // move sunday to end of week if that's correct for the currect locale
    if (moment().startOf('week').isoWeekday() % 7 === 1) {
      weekdayNames.push(weekdayNames.shift());
    }
    return weekdayNames;
  }

  render() {
    return (
      <table className="DatePickerMonth">
        <caption className="DatePickerMonth__title">
          {moment.months(this.props.month.getUTCMonth())} {this.props.month.getUTCFullYear()}
        </caption>
        <thead>
          <tr>
            {this.getWeekdayNames().map((weekday) => (
              <th key={weekday} className="DatePickerMonth__weekday">
                {weekday}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.state.weeks.map((week, iWeek) => (
            <tr key={iWeek}>
              {week.map((day) => (
                <Day
                  key={`${day.getUTCFullYear()}-${day.getUTCMonth()}-${day.getUTCDate()}`}
                  day={day}
                  minDate={this.props.minDate}
                  maxDate={this.props.maxDate}
                  hoverDate={this.props.hoverDate}
                  month={this.state.weeks[1][0]}
                  highlight={this.props.highlight}
                  calendar={this.props.calendar}
                  activeDateIndex={this.props.activeDateIndex}
                  onHover={this.props.onDayHover}
                  selected={this.props.selected}
                  onSelect={this.props.onSelectDay}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

DatePickerMonth.propTypes = {
  highlight: PropTypes.array,
  onDayHover: PropTypes.func,
  selected: PropTypes.array,
};

export default DatePickerMonth;
