import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatFlexibleMonths } from './Utils';
import SearchInput from '../frontpage/SearchForm/SearchInput';
import withForwardedRef from '../wrappers/WithForwardedRef';
import Calendar from '@content/icons/calendar.svg';
import './DatePickerInput.less';

class DatePickerInput extends React.Component {
  /**
   * Used to fix following bug:
   * When click input, then click scrollbar then
   * directly again input, Dateform does not appear
   * @memberof DatePickerInput
   */
  onClick = () => {
    const { forwardedRef } = this.props;
    const hasRef = forwardedRef && forwardedRef.current;

    if (hasRef && forwardedRef.current.id === document.activeElement.id) {
      forwardedRef.current.blur();
    }
  };

  /**
   * Formats the date depending on whether it is a flexible date period or a specific date.
   * @param {Date} date - the date to format (for specific date)
   */
  formatDate(date) {
    const { t, formatDate, flexible, flexibleValue } = this.props;

    return flexible
      ? formatFlexibleMonths(flexibleValue, t, formatDate)
      : formatDate(date, 'shortDate');
  }

  render() {
    const {
      id,
      twin,
      date,
      label,
      isOpen,
      onOpen,
      flexible,
      onKeyDown,
      showLabel,
      className,
      placeholder,
      inputClassName,
      iconClassName,
      forwardedRef,
      shouldPopOpen,
    } = this.props;

    return (
      <div
        className={classNames('DatePickerInput', className, {
          'DatePickerInput--twin': twin,
          'DatePickerInput--flexible': flexible,
          'DatePickerInput--focus': isOpen,
        })}
      >
        <SearchInput
          id={id}
          ref={forwardedRef}
          value={date ? this.formatDate(date) : placeholder}
          onFocus={onOpen}
          onClick={this.onClick}
          readOnly
          onKeyDown={onKeyDown}
          label={label}
          showLabel={showLabel}
          icon={cn => <Calendar className={cn} />}
          hasFocus={isOpen}
          shouldPopOpen={shouldPopOpen}
          inputClassName={inputClassName}
          iconClassName={iconClassName}
          pointer
        >
          {isOpen && <div className="DatePickerInput__pointer" />}
        </SearchInput>
      </div>
    );
  }
}

DatePickerInput.defaultProps = {
  id: null,
  twin: false,
  label: '',
  showLabel: false,
  isOpen: false,
  flexible: false,
  className: '',
  placeholder: '',
  flexibleValue: {},
  inputClassName: '',
  iconClassName: '',
  forwardedRef: null,
  shouldPopOpen: false,
  onOpen: () => {},
  canClear: false,
  onKeyDown: () => {},
};

DatePickerInput.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  twin: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  flexible: PropTypes.bool,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  flexibleValue: PropTypes.object,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  forwardedRef: PropTypes.object,
  shouldPopOpen: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  canClear: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default withForwardedRef(DatePickerInput);
