import moment from 'moment';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import { pickLocale } from 'shared/utils/languageUtils';

export default function formatDate({ translations, user }, format, date, options) {
  if (isDate(format) || isNumber(format)) [format, date] = [date, format];
  let momentDate = moment.utc(date);
  momentDate.locale(pickLocale(user.get('language'), user.get('residency')));
  /*
    We use a special date format in the date input. Usually that format is
    is similar to MMM D or D MMM (jan 25 and 25 jan).
    Since moment doesn't offer any localization on that format we must
    explicitly tell what format we want. We do that using our translate system.
    The default one being D MMM
  */
  if (format === 'shortDate') {
    if (user.get('language') === 'en' && user.get('residency') === 'US') {
      return momentDate.format('MMM D');
    }
    return momentDate.format(translations.get('shortDate_format'));
  }
  let s = momentDate.format(format);

  if (!options || options.short !== false) {
    s = s.replace(/\s+(AM|PM)($|\s+)/, (match, ampm, after) => {
      return ampm.toLowerCase() + after;
    });
  }
  return s;
}
