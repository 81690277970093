import React, { Component } from 'react';
import classNames from 'classnames';
import createFilters from '../../lib/createFilters';
import Lazy from '../Lazy';
import './GoImageLayer.less';

class GoImageLayer extends Component {
  state = { isHighlighted: false };

  renderLazy(city) {
    return (
      <Lazy
        className="GoImageLayer__image"
        loadedProps={{ style: { backgroundImage: `url(${city.get('image')})` } }}
      />
    );
  }

  renderHighligher(extraClassName) {
    const { isHighlighted } = this.state;

    return (
      <div
        className={classNames('GoImageLayer__highlighter', extraClassName, {
          'GoImageLayer__highlighter--visible': isHighlighted,
        })}
      />
    );
  }

  render() {
    const { city, t, formatCurrency, lazyLoad } = this.props;
    // The price can be 0.
    const isPriceDefined = city.get('price') || city.get('price') === 0;
    const showHighlightOnHover = {
      onMouseOver: () => {
        this.setState({ isHighlighted: true });
      },
      onMouseOut: () => {
        this.setState({ isHighlighted: false });
      },
    };

    return (
      <div className="GoImageLayer">
        {lazyLoad ? (
          this.renderLazy(city)
        ) : (
          <div
            className="GoImageLayer__image"
            style={{ backgroundImage: `url(${city.get('image')})` }}
          />
        )}
        <div className="GoImageLayer__overlay" />
        <div className="GoImagelayer__info">
          <div className="GoImageLayer__titleWrapper" {...showHighlightOnHover}>
            {this.renderHighligher()}
            <div className="GoImageLayer__title">
              {city.get('name')} {isPriceDefined && t('from').toLowerCase()}
            </div>
          </div>
          {isPriceDefined && (
            <div className="GoImageLayer__priceWrapper" {...showHighlightOnHover}>
              {this.renderHighligher('GoImageLayer__highlighter--price')}
              <div className="GoImageLayer__price">{formatCurrency(city.get('price'))}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

GoImageLayer = createFilters(['formatCurrency', 't'])(GoImageLayer);

export default GoImageLayer;
