/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');
const util = require('./util');
const request = require('./request');

// Get deeplink needs either {search, vendorFare, currency} or {sessionKey, fareId, vendorId, currency}
// This is to support both calling this from the results page where you have the search object, or via
// a seperate transfer page that could be linked to with the required parameters.
// onData will be passed an itinerary object for flights covered by this fare
// onDeeplink will be passed an object of the form:
//   {
//     "url": String // url to link to
//     "expected-currency": String // currency expected to get on the linked page
//     "expected-fare": Number // fare expected to get on the linked page
//     "post": Object // Optional key-value object of post parameters. If not set, transfer should use GET
//     "isFallback": Boolean // if true, this is a fallback url which will probaly not link directly to the ticket
//   }
const getDeeplink = (options) =>
  initializeTransfer(
    _extend({}, options, {
      success(response) {
        // TODO: pass itinerary to onData
        return poll(response, { success: options.onDeeplink, error: options.error });
      },
    })
  );

var initializeTransfer = function ({
  search,
  vendorFare,
  sessionKey,
  fareId,
  vendorId,
  currency,
  apiVersion,
  fareClass,
  success,
  error,
}) {
  const options = {
    key: sessionKey != null ? sessionKey : search.getSessionKey(),
    fareId: fareId != null ? fareId : vendorFare.getFareId(),
    vendorId: vendorId != null ? vendorId : vendorFare.getVendor().getKey(),
    currency: currency != null ? currency : search.getCurrency(),
    apiVersion,
    fareClass: apiVersion >= 3 ? (fareClass != null ? fareClass : 'legacy') : undefined,
    success(response) {
      const itineraryData = util.popKey(response, 'itinerary');
      // TODO: process itineraryData and pass an itinerary as second parameter to success
      return success(response);
    },
    error,
  };

  return request.get('transfer', options);
};

var poll = function (response, { success, error }) {
  if (response && 'deeplink-url' in response) {
    return success(_extend({ isFallback: false }, response['deeplink-url']));
  } else if (response && 'deeplink-uri-poll' in response) {
    return request.get(null, {
      fullPath: response['deeplink-uri-poll'],
      success(response) {
        return success(_extend({ isFallback: false }, response['deeplink-url']));
      },
      error() {
        return success(_extend({ isFallback: true }, response['deeplink-url-fallback']));
      },
    });
  } else {
    return error('Failed to get deeplink');
  }
};

module.exports = { getDeeplink, initializeTransfer, poll };
