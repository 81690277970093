import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formatTransferQueryParams } from 'shared/utils/transferUtils';
import TransferPage from '@components/flights/TransferPage';
import * as actions from '@actions/transferActions';
import fetchData from '@lib/fetchData';
import redirectWithPost from '@lib/redirectWithPost';
import DohopHead from '@components/DohopHead';
import createFilters from '@lib/createFilters';
import PageNotFoundRoute from './PageNotFoundRoute';

function submitDeeplink(link) {
  // Add / replace query params if they are there and are necessary
  const deeplink = formatTransferQueryParams(link.get('url'));

  if (link.get('post')) {
    redirectWithPost(deeplink, link.get('post').toJS());
  } else {
    location.replace(deeplink);
  }
}

class TransferPageRoute extends PureComponent {
  componentDidMount() {
    // start polling client side
    this.props.dispatch(actions.resumeTransfer());
  }

  componentDidUpdate(prevProps) {
    const { transfer } = this.props;
    const deeplink = transfer.get('deeplink');

    if (!prevProps.transfer.get('deeplink') && deeplink && !deeplink.get('isFallback')) {
      // submit deeplink if successful
      submitDeeplink(deeplink);
    }
  }

  onSubmit() {
    submitDeeplink(this.props.transfer.get('deeplink'));
  }

  render() {
    if (this.props.transfer.get('notFound')) {
      return <PageNotFoundRoute />;
    }

    return (
      <div className="PageRouteAnimation__fadeIn">
        <DohopHead hreflang={false} openGraph={false} title={this.props.t('transfer_to_vendor')} />
        <TransferPage
          vendor={this.props.transfer.get('vendor')}
          hasError={this.props.transfer.getIn(['deeplink', 'isFallback'])}
          onSubmit={this.onSubmit.bind(this)}
        />
      </div>
    );
  }
}

TransferPageRoute = createFilters(['t'])(TransferPageRoute);

TransferPageRoute = connect(state => ({
  transfer: state.get('transfer'),
}))(TransferPageRoute);

TransferPageRoute = fetchData(TransferPageRoute, (store, { params }) => {
  const { sessionKey, fareId, vendorId, currency } = params;
  return store.dispatch(actions.initializeTransfer(sessionKey, fareId, vendorId, currency));
});

export default TransferPageRoute;
