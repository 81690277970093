import React, { Component } from 'react';
import { trackSearchExpired, trackSearchRefreshed } from '../../lib/tracking';
import Portal from '../modal/Portal';
import Overlay from '../modal/Overlay';
import Button from '../Button';
import './ExpiredPopup.less';

class ExpiredPopup extends Component {
  componentDidMount() {
    trackSearchExpired();
  }
  render() {
    const { t } = this.props;
    const resetSearch = () => {
      trackSearchRefreshed();
      this.props.reloadSearch();
    };
    const reloadTexts = t('reload_text_expired').split('.');
    return (
      <Portal>
        <Overlay onClick={() => resetSearch()} middle contentClassName="ExpiredPopup__content">
          <img className="ExpiredPopup__clock" src="/content/svg/short_transfer.svg" />
          <div className="ExpiredPopup__message">{reloadTexts[0]}.</div>
          <div className="ExpiredPopup__message">{reloadTexts[1]}</div>
          <Button className="ExpiredPopup__button" onClick={() => resetSearch()}>
            {t('reload')}
          </Button>
        </Overlay>
      </Portal>
    );
  }
}

export default ExpiredPopup;
