import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import classNames from 'classnames';
import getScrollTop from 'shared/utils/getScrollTop';
import { getHotelUrlFromForm, getFlightUrlFromSearchForm } from '@lib/getFlightUrl';
import { trackFlightSearch } from '@lib/tracking';
import execFBPixelEvent from '@lib/facebookPixel';
import createFilters from '@lib/createFilters';
import { ENTER } from '@lib/keyCodes';
import processException from '@lib/processException';
import { formatRecentFlightSearches } from '@lib/formatRecentSearches';
import { PROGRESSBAR_DURATION } from '@actions/flightSearchActions';
import { flightSearchAutocomplete, formatValue } from '@actions/autocompleteActions';
import * as formActions from '@actions/flightSearchFormActions';
import { getHotelsByCity } from '@actions/hotelActions';
import { setStay as setStayAction } from '@actions/goActions';
import Button from '@components/Button';
import MatchMedia, { queries } from '@components/MatchMedia';
import Checkbox from '@components/form/Checkbox';
import ResponsiveAutocomplete from '@components/autocomplete/ResponsiveAutocomplete';
import DateRangePicker from '@components/DatePicker/DateRangePicker';
import LoadingBar from '@components/flights/LoadingBar';
import DateMatrix from '@components/flights/DateMatrix';
import TravellersControl from '../TravellersControl';
import OneWayOrRoundTrip from './OneWayOrRoundTrip';
import SwitchIcon from '@content/img/icons/arrows_white.svg';
import './SearchForm.less';

const HEIGHT_ABOVE_SEARCH_BAR = 88;
const ORIGIN = 'origin';
const DESTINATION = 'destination';
const DEPARTURE = 'departure';
const RETURN = 'return';
const PASSENGERS = 'passengers';
const DEFAULT_SHOULD_POP_OPEN = {
  [ORIGIN]: false,
  [DESTINATION]: false,
  [DEPARTURE]: false,
  [RETURN]: false,
  [PASSENGERS]: false,
};
const DEFAULT_ERRORS = Map({
  origin: Map({
    error: false,
    value: '',
    shake: false,
  }),
  destination: Map({
    error: false,
    value: '',
    shake: false,
  }),
});
const EVENTS = {
  SCROLL: 'scroll',
  RESIZE: 'resize',
};

const searchFormRef = React.createRef();

class SearchForm extends Component {
  state = {
    switchClick: false,
    shouldPopOpen: DEFAULT_SHOULD_POP_OPEN,
    errors: DEFAULT_ERRORS,
  };

  static getDerivedStateFromProps(props, state) {
    const { form, t, isDateMatrixActive, search, go } = props;

    const origin = formatValue(form.get(ORIGIN), t);
    const destination = formatValue(form.get(DESTINATION), t);

    return {
      origin,
      destination,
      isDateMatrixVisible: search && !go && isDateMatrixActive,
    };
  }

  componentDidMount() {
    const { actions, go, form } = this.props;
    const { destination } = this.state;
    const { desktop } = queries;
    const isDesktop = desktop && desktop.matches;
    const isDateFlexible = form.get('isDateFlexible');

    // Listen for scroll event for knowing if searchform should be sticky at top or not.
    window.addEventListener(EVENTS.SCROLL, this.handleScroll);

    // If ipad or bigger
    if (queries.ipad && queries.ipad.matches) {
      // Store hotel check value in a cookie and Redux state
      const hotelCheckboxValue = actions.getCookieValue(formActions.HOTEL_CHECK_COOKIE);
      actions.searchHotelCheckBox(hotelCheckboxValue);
    }

    // If we navigate back to the frontpage or flightsearch after searching for flexible destination
    // in go, we want to remove the anywhere value from the destination form.
    if (destination === 'Anywhere' && !go) {
      actions.setDestination(null);
    }

    this.fetchRecentSearches();
    // If we navigate back to the frontpage or flightsearch after searching for flexible dates
    // in go, we want to change the dates to specific dates in desktop.
    if (isDateFlexible && !go && isDesktop) {
      actions.setDateFlexible(false);
    }

    // When the page is loaded in the begining we calculate the height above the searchbar to know when to
    // switch to fixed header.
    // However, if the page is loaded in the begining with ipad/mobile version or not scrolled all the way to the top
    // this height is not correct.
    // In that case we just use the default height calculated manually until we get an opurtunety to
    // calculate it.
    const isSearchFormDefined = searchFormRef && searchFormRef.current;
    const scrollTop = getScrollTop();
    const isPageOnTop = scrollTop === 0;

    if (isDesktop && isSearchFormDefined && isPageOnTop) {
      this.setState({
        heightAboveSearchBar: searchFormRef.current.getBoundingClientRect().top,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(EVENTS.SCROLL, this.handleScroll);
    window.removeEventListener(EVENTS.RESIZE, this.onResize);

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  async fetchRecentSearches() {
    const { t, actions } = this.props;

    try {
      await actions.fetchRecentSearches(t);
    } catch (e) {
      processException(e);
    }
  }

  /**
   * Handles style changes when scrolling for the search form.
   * It should be sticky on the top of the page when scrolling down at results page.
   */
  handleScroll = () => {
    const { search, go } = this.props;
    const { isDateMatrixActive, heightAboveSearchBar } = this.state;
    const onResultsPage = search || go;
    const changeToFixedBreakPoint = heightAboveSearchBar || HEIGHT_ABOVE_SEARCH_BAR;

    if (onResultsPage) {
      const scrollTop = getScrollTop();

      this.calculateHeightAboveSearchBar();
      if (scrollTop < changeToFixedBreakPoint) {
        this.setState({
          isSticky: false,
          isDateMatrixVisible: isDateMatrixActive && search && !go,
        });
      } else {
        this.setState({ isSticky: true, isDateMatrixVisible: false });
      }
    }
  };

  /**
   * On Resize event handler.
   */
  onResize = () => {
    this.calculateHeightAboveSearchBar();
  };

  calculateHeightAboveSearchBar() {
    const { heightAboveSearchBar } = this.state;
    const { desktop } = queries;
    const isDesktop = desktop && desktop.matches;
    const isSearchFormDefined = searchFormRef && searchFormRef.current;
    const scrollTop = getScrollTop();

    // If this is the first time we see the page in desktop version and scrolled all the way
    // to the top, it is our first opurtunety to calculate the distance from the searchbar to
    // the top of the page.
    if (isSearchFormDefined && scrollTop === 0 && isDesktop && !heightAboveSearchBar) {
      this.setState({
        heightAboveSearchBar: searchFormRef.current.getBoundingClientRect().top,
      });
    }
  }

  /**
   * Makes the input, with corresponding id, pop open.
   * Mainly used to automatically open the following input when the value in the previous
   * one have been chosen.
   *
   * @param {String} id - The id of the input that should open.
   */
  makeInputPopOpen(id) {
    // We can't use the default state again because after been used in the state
    // the key value pairs have changed there.
    const shouldPopOpen = {
      [ORIGIN]: false,
      [DESTINATION]: false,
      [DEPARTURE]: false,
      [RETURN]: false,
      [PASSENGERS]: false,
    };

    switch (id) {
      case ORIGIN:
        shouldPopOpen[ORIGIN] = true;
        break;
      case DESTINATION:
        shouldPopOpen[DESTINATION] = true;
        break;
      case DEPARTURE:
        shouldPopOpen[DEPARTURE] = true;
        break;
      case RETURN:
        shouldPopOpen[RETURN] = true;
        break;
      case PASSENGERS:
        shouldPopOpen[PASSENGERS] = true;
        break;
      default:
        break;
    }

    this.setState({ shouldPopOpen });
  }

  /**
   * When we focus on input, we make sure the shouldPopOpen state is set back to false.
   * Note, we can't use the default state again because after been used
   * in the state the key value pairs have changed.
   */
  onFocusInput = () => {
    this.setState({
      shouldPopOpen: {
        [ORIGIN]: false,
        [DESTINATION]: false,
        [DEPARTURE]: false,
        [RETURN]: false,
        [PASSENGERS]: false,
      },
    });
  };

  /**
   * Handles setState callback when user clicks on switch button to switch origin and destination.
   * If either origin or destination is empty then focus empty input box.
   *
   * @param {String} origin - Flight origin
   * @param {String} destination - Flight destination
   */
  onSwitchSetStateCallback(origin, destination) {
    if (queries.desktop.matches && (origin || destination)) {
      if (!origin) this.makeInputPopOpen(DESTINATION);
      if (!destination) this.makeInputPopOpen(ORIGIN);
    }
  }

  /**
   * Event handler for the switch origin/destination button.
   */
  onSwitch = () => {
    const { actions, form, t } = this.props;
    actions.switchOriginAndDestination();
    let { origin, destination } = form.toObject();
    origin = formatValue(form.get(ORIGIN), t);
    destination = formatValue(form.get(DESTINATION), t);

    this.setState(
      (state) => {
        return {
          origin: destination,
          destination: origin,
          switchClick: !state.switchClick,
        };
      },
      () => this.onSwitchSetStateCallback(origin, destination)
    );
  };

  /**
   * Toggles hotel checkbox state
   */
  hotelCheckboxCallbackHandler = () => {
    const { actions, enableHotelCheckbox } = this.props;
    // Store hotel check value in a cookie and Redux state
    actions.searchHotelCheckBox(!enableHotelCheckbox);
  };

  /** Handles origin changes. */
  onChangeOrigin = async (airports, e, previousAction) => {
    const { actions } = this.props;
    let { errors } = this.state;
    const { desktop } = queries;

    await actions.updateOriginAndFetchCalendar(airports);

    // Set origin error state to false if airports exists
    if (airports) {
      errors = errors.setIn(['origin', 'error'], false);
      this.setState({ errors });
    }

    // If origin have been selected by clicking on a result from the autocompleter,
    // then we want to autocmatically open the destination input.
    if (previousAction === 'clicked-result' && desktop.matches) {
      this.makeInputPopOpen(DESTINATION);
    }
  };

  /** Handles destination changes. */
  onChangeDestination = async (airports, e, previousAction) => {
    if (!e.persist) {
      e.persist = () => {};
    }

    e.persist();
    const { actions } = this.props;
    let { errors } = this.state;
    const { desktop } = queries;

    // Set destination error state to false if airports exists
    if (airports) {
      errors = errors.setIn(['destination', 'error'], false);
      this.setState({ errors });
    }

    await actions.updateDestinationAndFetchCalendar(airports);

    // If origin have been selected by clicking on a result from the autocompleter,
    // then we want to autocmatically open the departure input.
    if (previousAction === 'clicked-result' && desktop.matches) {
      e.stopPropagation(); // prevent document click that would otherwise close the datepicker
      this.makeInputPopOpen(DEPARTURE);
    }
  };

  /**
   * Event handler for changing dates.
   * @param {Number} idx - identifies whether it is for departure or return date.
   *                       0 is for departure, 1 is for return.
   * @param {Date} date - the new date to set.
   * @param {bool} shouldClose - true if we want the input to close and open the next one.
   */
  onChangeDate = (idx, date, shouldClose) => {
    const { actions, form } = this.props;
    const { desktop } = queries;
    const isOneWay = form.get('isOneWay');

    const { setDepartureDate, setReturnDate } = actions;
    (idx === 0 ? setDepartureDate : setReturnDate)(date);

    if (shouldClose && desktop.matches) {
      if (idx === 0 && !isOneWay) {
        this.makeInputPopOpen(RETURN);
      } else {
        this.makeInputPopOpen(PASSENGERS);
      }
    }
  };

  /**
   * Event handler for changing flexible dates.
   * @param {object <Immutable.map>} dates - The dates to set on the form { d1: ..., d1_:...}
   */
  onChangeFlexibleDates = (dates, shouldClose = true) => {
    const { actions, setStay } = this.props;
    const { desktop } = queries;

    actions.setFlexibleDates(dates);
    // Reset stay filter to default, so we wont filter out results where the trip is not exactly
    // as long as the one that the user searched for before.
    setStay('3-14');

    if (shouldClose && desktop.matches) {
      this.makeInputPopOpen(PASSENGERS);
    }
  };

  /**
   * Sets the isFlexible value in redux state.
   * is Flexible defines whether the user is searching for flexible dates/destinations or not
   */
  onChangeFlexible = (isFlexible) => {
    const { actions, setStay } = this.props;
    actions.setDateFlexible(isFlexible);
    // Reset stay filter to default, so we wont filter out results where the trip is not exactly
    // as long as the one that the user searched for before.
    setStay('3-14');
  };

  /**
   * When the return date calendar is closed,
   * we want to open the next input, which is the passengers one.
   */
  onCloseCalendarCallback = (e) => {
    const { desktop } = queries;

    const selectedReturnUsingEnter = e && e.keyCode === ENTER;
    const withCloseButton = e && e.target.id === 'CloseButton-DatePickerCalendar';

    if ((selectedReturnUsingEnter || withCloseButton) && desktop.matches) {
      this.makeInputPopOpen(PASSENGERS);
    }
  };

  /**
   * Set all shake props in errors to false
   * @param {Object<Immutable.Map>} errors
   * @returns {Object} errors - Updated errors
   */
  removeShakes(errors) {
    let updatedErrors = errors;

    errors.keySeq().forEach((key) => {
      updatedErrors = updatedErrors.setIn([key, 'shake'], false);
    });

    return updatedErrors;
  }

  /**
   * Creates and validates errors
   * @param {String} origin
   * @param {String} destination
   * @returns {Object}
   */
  validateErrors(origin, destination) {
    const { t } = this.props;

    const errors = Map({
      origin: Map({
        error: origin ? false : true,
        value: t('errFromEmpty'),
        shake: origin ? false : true,
      }),
      destination: Map({
        error: destination ? false : true,
        value: t('errToEmpty'),
        shake: destination ? false : true,
      }),
    });

    return {
      errors,
      hasError: !origin || !destination,
    };
  }

  /**
   * Handles the onSubmit action, when the search button is clicked.
   */
  onSubmit = async (e) => {
    e.preventDefault();

    const { actions, form, onSubmit, history, enableHotelCheckbox, user } = this.props;
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
      isDateFlexible,
    } = form.toObject();

    const { errors, hasError } = this.validateErrors(origin, destination);

    if (hasError) {
      this.setState({ errors });

      this.timeoutId = setTimeout(() => {
        this.setState({ errors: this.removeShakes(errors) });
      }, 1000);

      return;
    }

    this.setState({ errors: DEFAULT_ERRORS });

    const isFlexible = destination.get('flexible') || isDateFlexible;
    onSubmit(e, isFlexible);

    /**
     * Add search to recent searches.
     */
    actions.saveRecentSearchCookie();

    /**
     * Execute facebook pixel search event
     */
    execFBPixelEvent({
      event: 'Search',
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      childrenAges,
    });
    const url = getFlightUrlFromSearchForm(form);

    // if hotel checkbox is checked
    if (enableHotelCheckbox) {
      // Open a new tab with flight search
      window.open(url, '_blank');

      const hotelBaseUrl = 'https://hotel.dohop.com';

      const params = await getHotelUrlFromForm(form, user);

      const payload = {
        ss: params.city_name,
        interval_of_time: 'any',
        flex_checkin_year_month: 'any',
        no_rooms: 1,
        group_adults: 2,
        group_children: 0,
        airports: params.airports,

        checkin_year_month: params.checkin_ym,
        checkin_monthday: params.checkin_d,
        checkout_year_month: params.checkout_ym,
        checkout_monthday: params.checkin_d,
        aid: 946321,
        language: params.languauge,
      };

      const query = new URLSearchParams(payload).toString();

      const hotelUrl = `${hotelBaseUrl}/searchresults.html?${query}`;

      // Redirect user to Dohop whitelabel booking url
      window.location.href = hotelUrl;
    } else if (window.location.pathname !== url) {
      history.push(url, null);
    }
  };

  /**
   * Renders the checkbox that searches also for hotels for chosen parameters if checked.
   */
  renderHotelCheckbox() {
    const { t, form, showCompare, enableHotelCheckbox } = this.props;
    const city = form.getIn([DESTINATION, 'city']) || form.getIn([DESTINATION, 'name']);
    const isDestinationFlexible = form.getIn(['destination', 'flexible']);

    if (!showCompare) {
      return null;
    }

    return (
      <div
        className={classNames('SearchForm__hotelCheckboxContainer', {
          'SearchForm__hotelCheckboxContainer--isEmpty': isDestinationFlexible,
        })}
      >
        {!isDestinationFlexible && (
          <Checkbox
            className="SearchForm__checkbox"
            checked={enableHotelCheckbox}
            onChange={this.hotelCheckboxCallbackHandler}
          >
            {city ? t('findhotel', { city }) : t('findhotels')}
          </Checkbox>
        )}
      </div>
    );
  }

  /**
   * Renders the input for defining city/airport.
   * @param {String} origOrDest - defines whether it is for origin or destination.
   */
  renderPlaceInput(origOrDest) {
    const { t, formatDate, autocomplete, focusOnMount, recentSearches } = this.props;
    const { origin, destination, isSticky, shouldPopOpen, switchClick, errors } = this.state;
    const { desktop } = queries;
    const isOrigin = origOrDest === ORIGIN;
    const isDestination = origOrDest === DESTINATION;
    const originError = isOrigin && errors.getIn([ORIGIN, 'error']);
    const destinationError = isDestination && errors.getIn([DESTINATION, 'error']);
    let shake = false;
    let error = false;

    if (originError) {
      shake = errors.getIn([ORIGIN, 'shake']);
      error = errors.getIn([ORIGIN, 'error']);
    } else if (destinationError) {
      shake = errors.getIn([DESTINATION, 'shake']);
      error = errors.getIn([DESTINATION, 'error']);
    }

    const flexible = {
      value: t('anywhere'),
      title: t('im_flexible'),
      item: Map({
        name: t('anywhere'),
        flexible: true,
      }),
    };
    const hint = isOrigin ? t('from') : t('to');
    let autoFocus = null;

    if (isDestination && desktop && desktop.matches) {
      autoFocus = focusOnMount;
    } else if (originError || destinationError) {
      autoFocus = true;
    }

    return (
      <div
        className={classNames('SearchForm__subForm ', `SearchForm__${origOrDest}`, {
          'SearchForm__subForm--sticky': isSticky,
        })}
      >
        <ResponsiveAutocomplete
          id={origOrDest}
          t={t}
          source={autocomplete}
          autoFocus={autoFocus}
          shouldPopOpen={isOrigin ? shouldPopOpen[ORIGIN] : shouldPopOpen[DESTINATION]}
          label={hint}
          showLabel={!isSticky}
          onSelect={isOrigin ? this.onChangeOrigin : this.onChangeDestination}
          placeholder={hint}
          value={isOrigin ? origin : destination}
          extraResult={isDestination ? flexible : null}
          recentSearches={
            recentSearches.length > 0
              ? formatRecentFlightSearches(recentSearches, t, formatDate)
              : null
          }
          onFocus={this.onFocusInput}
          iconClassName={classNames({
            'SearchForm__inputIcon--dest': isDestination,
            'SearchForm__inputIcon--sunburn': isSticky,
          })}
          inputClassName={classNames({
            'SearchForm__input--dest': isDestination,
            'SearchForm__input--sticky': isSticky,
            'SearchInput__input--iconRight': isOrigin,
            Input__shake: shake,
          })}
          error={error}
        />
        {isOrigin && (
          <div className="SearchForm__switch" onClick={this.onSwitch}>
            <SwitchIcon
              className={classNames('SearchForm__switchIcon', {
                'SearchForm__switchIcon--rotated': switchClick,
              })}
            />
          </div>
        )}
        {originError && (
          <span className="SearchForm__error">{errors.getIn([ORIGIN, 'value'])}</span>
        )}
        {destinationError && (
          <span className="SearchForm__error">{errors.getIn([DESTINATION, 'value'])}</span>
        )}
      </div>
    );
  }

  /**
   * Renders the dates inputs, which opens a date picker in a calander, for the search form.
   */
  renderDatesInput() {
    const { t, formatDate, form, initialOpen, actions } = this.props;
    const { isSticky, shouldPopOpen } = this.state;
    const isOneWay = form.get('isOneWay');

    return (
      <div className="SearchForm__subForm SearchForm__dates">
        <DateRangePicker
          ids={[DEPARTURE, RETURN]}
          t={t}
          formatDate={formatDate}
          initialOpen={initialOpen === 'departureDate'}
          dates={[form.get('departureDate'), form.get('isOneWay') ? null : form.get('returnDate')]}
          shouldPopOpen={[shouldPopOpen[DEPARTURE], shouldPopOpen[RETURN]]}
          labels={[t('Departure'), t('returndate')]}
          showLabels={!isSticky}
          placeholders={[t('book_departure_date'), t('return_date')]}
          titles={[t('title_departdate'), t('title_returndate')]}
          onSelect={this.onChangeDate}
          onClear={actions.setIsOneWay.bind(null, true)}
          autoNext={!form.get('isOneWay')}
          calendar={form.get('calendar')}
          isFlexible={form.get('isDateFlexible')}
          onChangeFlexible={this.onChangeFlexible}
          flexibleValue={form.get('flexibleDate')}
          onChangeFlexibleDates={this.onChangeFlexibleDates}
          isOneWay={isOneWay}
          setIsOneWay={(value) => actions.setIsOneWay(value)}
          inputClassName={classNames('SearchForm__input', {
            'SearchForm__input--sticky': isSticky,
          })}
          iconClassName={classNames({
            'SearchForm__inputIcon--sunburn': isSticky,
          })}
          onFocus={this.onFocusInput}
          onCloseCallback={this.onCloseCalendarCallback}
        />
      </div>
    );
  }

  /**
   * Renders the input for choosing passengers.
   */
  renderPassengersInput() {
    const { form, actions, t } = this.props;
    const { isSticky, shouldPopOpen } = this.state;

    return (
      <div className="SearchForm__subForm SearchForm__passengers">
        <TravellersControl
          t={t}
          adults={form.get('adults')}
          childrenAges={form.get('childrenAges')}
          actions={actions}
          showLabel={!isSticky}
          arrowClassName={classNames({ 'SearchForm__travellersArrow--sunburn': isSticky })}
          shouldPopOpen={shouldPopOpen[PASSENGERS]}
          onFocus={this.onFocusInput}
          iconClassName={classNames({
            'SearchForm__inputIcon--sunburn': isSticky,
          })}
          inputClassName={classNames('SearchForm__input', {
            'SearchForm__input--sticky': isSticky,
          })}
        />
      </div>
    );
  }

  /**
   * Renders the submit button for the search form.
   */
  renderSubmitButton() {
    const { t, search } = this.props;
    const { isSticky } = this.state;

    return (
      <div className="SearchForm__subForm SearchForm__submit">
        <Button
          className={classNames({
            'SearchForm__submit--onResultsPage': search,
            'SearchForm__submit--sticky': isSticky,
          })}
          submit
          form
          fill
        >
          {t('search')}
        </Button>
      </div>
    );
  }

  /**
   * The order is different for each screensize.
   * Renders the form in the correct order for mobile devices.
   */
  renderMobileForm() {
    return (
      <form id="flightform" onSubmit={this.onSubmit} target="_blank">
        {this.renderPlaceInput(ORIGIN)}
        {this.renderPlaceInput(DESTINATION)}
        {this.renderDatesInput()}
        {this.renderPassengersInput()}
        {this.renderHotelCheckbox()}
        {this.renderSubmitButton()}
      </form>
    );
  }

  /**
   * The order is different for each screensize.
   * Renders the form in the correct order for tablet/ipad devices.
   */
  renderIpadForm() {
    const { search, go } = this.props;
    const { isSticky } = this.state;

    return (
      <form id="flightform" onSubmit={this.onSubmit} target="_blank">
        {this.renderPlaceInput(ORIGIN)}
        {this.renderPlaceInput(DESTINATION)}
        {this.renderPassengersInput()}
        {this.renderDatesInput()}
        {this.renderHotelCheckbox()}
        {search && !go && !isSticky && <DateMatrix search={search} />}
        {this.renderSubmitButton()}
      </form>
    );
  }

  /**
   * The order is different for each screensize.
   * Renders the form in the correct order for desktop devices.
   */
  renderDesktopForm() {
    const { search, go } = this.props;
    const { isSticky } = this.state;

    return (
      <Fragment>
        <form id="flightform" onSubmit={this.onSubmit} target="_blank">
          {this.renderPlaceInput(ORIGIN)}
          {this.renderPlaceInput(DESTINATION)}
          {this.renderDatesInput()}
          {this.renderPassengersInput()}
          {this.renderSubmitButton()}
          {this.renderHotelCheckbox()}
          {search && !go && !isSticky && <DateMatrix search={search} />}
        </form>
      </Fragment>
    );
  }

  render() {
    const { t, form, search, flightSearch, actions } = this.props;
    const { isSticky, isDateMatrixVisible } = this.state;

    return (
      <div
        className={classNames('SearchForm', {
          'SearchForm--onResultsPage': search,
          'SearchForm--withDateMatrix': isDateMatrixVisible,
          'SearchForm--sticky': isSticky,
        })}
        ref={searchFormRef}
      >
        <OneWayOrRoundTrip
          t={t}
          ocean={isSticky}
          isDisabled={form.get('isDateFlexible')}
          isOneWay={form ? form.get('isOneWay') : false}
          setIsOneWay={(value) => actions.setIsOneWay(value)}
        />
        <MatchMedia max="ipad">{this.renderMobileForm()}</MatchMedia>
        <MatchMedia min="ipad" max="desktop">
          {this.renderIpadForm()}
        </MatchMedia>
        <MatchMedia min="desktop">{this.renderDesktopForm()}</MatchMedia>
        {search && (
          <LoadingBar
            duration={PROGRESSBAR_DURATION}
            start={flightSearch.get('progressbarStartTime')}
            className="SearchForm__loadingBar"
          />
        )}
      </div>
    );
  }
}

SearchForm.defaultProps = {
  focusOnMount: false,
  showCompare: false,
  enableHotelCheckbox: false,
  singleLineBreakpoint: 'desktop',
  search: null,
  go: null,
  initialOpen: '',
  history: {},
  progressbarStartTime: null,
  loadingBar: {},
};

SearchForm.propTypes = {
  t: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  userAgent: PropTypes.string.isRequired,
  singleLineBreakpoint: PropTypes.oneOf(['desktop', 'laptop']),
  focusOnMount: PropTypes.bool,
  showCompare: PropTypes.bool,
  enableHotelCheckbox: PropTypes.bool,
  search: PropTypes.object,
  go: PropTypes.object,
  initialOpen: PropTypes.string,
  history: PropTypes.object,
  actions: PropTypes.object.isRequired,
  autocomplete: PropTypes.func.isRequired,
  setStay: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  progressbarStartTime: PropTypes.number,
  flightSearch: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  progressbarStartTime: state.getIn(['flightSearch', 'progressbarStartTime']),
  user: state.get('user'),
  form: state.get('flightSearchForm'),
  userAgent: state.getIn(['request', 'userAgent']),
  isDateMatrixActive: state.getIn(['dateMatrix', 'isActive']),
  recentSearches: state.getIn(['flightSearchForm', 'recentSearches']),
  flightSearch: state.get('flightSearch'),
});

const mapDispatchToProps = (dispatch, props) => ({
  autocomplete: (...args) => dispatch(flightSearchAutocomplete(...args)),
  actions: bindActionCreators({ ...formActions, getHotelsByCity }, dispatch),
  setStay: (...args) => dispatch(setStayAction(...args)),
  onSubmit: (e, isFlexible) => {
    if (!isFlexible) dispatch(trackFlightSearch());
    if (props.onSubmit) props.onSubmit(e);
  },
});

SearchForm = createFilters(['t', 'formatDate'])(SearchForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
