import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Airplane from '@content/svg/airplane.svg';
import Globe from '@content/svg/globe.svg';
import './Loading.less';

class Loading extends Component {
  renderLoading() {
    const { text } = this.props;

    return (
      <div className="Loading">
        {Boolean(text) && <div className="Loading__text">{text}</div>}
        <div className="Loading__content">
          <Airplane className="Loading__airplane" />
          <Globe className="Loading__globe" />
        </div>
      </div>
    );
  }

  render() {
    const { withContainer } = this.props;

    if (withContainer) {
      return <div className="Loading__container">{this.renderLoading()}</div>;
    }

    return this.renderLoading();
  }
}

Loading.propTypes = {
  text: PropTypes.string,
  withContainer: PropTypes.bool,
};

Loading.defaultProps = {
  withContainer: false,
};

export default Loading;
