import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '@lib/config';
import propTypes from '@lib/propTypes';
import { sortAndFilterVendorFares } from '@lib/itineraryInfo';
import createFilters from '@lib/createFilters';
import MatchMedia from '@components/MatchMedia';
import Tooltip from '@components/Tooltip';
import Button from '@components/Button';
import Airplane from '@content/img/plane.svg';
import './ItineraryPrice.less';

const { TRANSFER_STEP_URL } = config;

const POLL_STATE_EVENTS = ['poll-start', 'end'];

class ItineraryPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPolling: props.search.isPolling(),
    };
    this.onBtnClick = this.onBtnClick.bind(this);
    this.onPollStateChange = this.onPollStateChange.bind(this);
  }

  /**
   * Listen if the price has been fetched.
   */
  componentDidMount() {
    const { search } = this.props;
    search.on(POLL_STATE_EVENTS, this.onPollStateChange);
  }

  /**
   * Make sure we are not calling setState on unmounted component.
   */
  componentWillUnmount() {
    const { search } = this.props;

    POLL_STATE_EVENTS.forEach(event => {
      search.getEvents().removeListener(event, this.onPollStateChange);
    });
  }

  /**
   * Update the state when the price has been fetched.
   */
  onPollStateChange() {
    const { search } = this.props;
    this.setState({ isPolling: search.isPolling() });
  }

  /**
   * Opens transferStep.
   * @param {Object} e - Button onClick event
   * @returns undefined
   * @memberof ItineraryPrice
   */
  onBtnClick(e) {
    const { history, itinerary } = this.props;
    const { search, pathname } = window.location;
    // Stop the event from bubbling and causing side effects
    e.stopPropagation();

    history.push(pathname + search + `#${TRANSFER_STEP_URL}/${itinerary.getKey()}`, 'onBtnClick');
  }

  /**
   * Renders the price button.
   */
  renderPriceButton() {
    const { t, fare } = this.props;

    return (
      <Button
        className="ItineraryPrice__button"
        onClick={fare.isComplete() ? this.onBtnClick : () => {}}
        disabled={!fare.isComplete()}
      >
        {t('see_details')}
      </Button>
    );
  }

  /**
   * Renders a label with the dohop airplane logo that indicates that we (Dohop) are
   * selling this fare. Clicking on the label transfer the user to our booking page.
   */
  renderProtectionLabel() {
    const { t } = this.props;

    return (
      <div className="ItineraryPrice__protectionLabel">
        <Tooltip
          className="ItineraryPrice__protectionLabel__tooltip"
          title={t('recommended_book_with_dohop')}
        >
          <Airplane className="ItineraryPrice__protectionIcon" />
        </Tooltip>
      </div>
    );
  }

  /**
   * Renders the price of the fare. (or loading message if not fetched etc).
   */
  renderPrice() {
    const { t, formatCurrency, search, fare } = this.props;
    const { isPolling } = this.state;

    if (fare.isComplete()) {
      const paxNum = search.getMeta().getNumPassengers();

      return (
        <div className="ItineraryPrice__priceContainer">
          <div
            className={classNames('ItineraryPrice__price', {
              'ItineraryPrice__price--moreSpace': paxNum === 1,
            })}
          >
            {formatCurrency(fare.getAverage())}
          </div>
          {paxNum > 1 && (
            <div className="ItineraryPrice__price ItineraryPrice__price--pax">
              {`${formatCurrency(fare.getTotal())} ${t('total').toLowerCase()}`}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="ItineraryPrice__priceContainer">
        {isPolling ? `${t('gettingprice')}...` : t('farenotfound')}
      </div>
    );
  }

  /**
   * Renders information about the vendor/vendors selling this fare.
   */
  renderVendor() {
    return <div className="ItineraryPrice__vendor">{this.getVendorName()}</div>;
  }

  /**
   * Returns information about the vendor/vendors selling this fare.
   */
  getVendorName() {
    const { t, fare } = this.props;
    const deals = this.getAlternativesLength();

    // If the only alternative we have is multible booking, we show the text: x bookings
    // where x is the number of seperate bookings the user need to buy.
    if (deals === 1 && fare.isMulti()) {
      return t('n_separate_tickets', { num: fare.size() });
    }

    // If we offer more than one deal, we show the text: x deals from
    // Where x is the number of alternatives we are offering.
    if (deals > 1) {
      return t('deals_from', { deals });
    }

    // If we are only offering one alternative, we simply display the vendors name.
    if (fare.getVendorFare()) {
      return fare
        .getVendorFare()
        .getVendor()
        .getName();
    }

    // Otherwise we show the airline names.
    return fare
      .getAirlines()
      .map(a => a.getName())
      .join(', ');
  }

  /**
   * Returns how many alternatives/types of ticket the user can pick from.
   */
  getAlternativesLength() {
    const { itinerary, vendors } = this.props;
    const { displayedFares } = sortAndFilterVendorFares(itinerary, vendors);

    return displayedFares.size;
  }

  /**
   * The order of which the information are shown are different in mobile and desktop.
   * Here we are rendering information, in the correct order for the desktop version.
   */
  renderDesktopOrder(showProtectionLabel) {
    const { className } = this.props;

    return (
      <MatchMedia className={classNames('ItineraryPrice', className)} min="ipad">
        {showProtectionLabel && this.renderProtectionLabel()}
        {this.renderVendor()}
        {this.renderPrice()}
        {this.renderPriceButton()}
      </MatchMedia>
    );
  }

  /**
   * The order of which the information are shown are different in mobile and desktop.
   * Here we are rendering information, in the correct order for the mobile version.

   */
  renderMobileOrder(showProtectionLabel) {
    return (
      <MatchMedia className="ItineraryPrice" max="ipad">
        {showProtectionLabel && this.renderProtectionLabel()}
        {this.renderVendor()}
        <div className="ItineraryPrice__rightWrapper">
          {this.renderPrice()}
          {this.renderPriceButton()}
        </div>
      </MatchMedia>
    );
  }

  render() {
    const { itinerary, vendors } = this.props;

    let showProtectionLabel = false;

    if (itinerary && vendors) {
      const { whitelabelFares, dohopFares } = sortAndFilterVendorFares(itinerary, vendors);
      showProtectionLabel = dohopFares.size > 0 || whitelabelFares.size > 0;
    }

    return (
      <Fragment>
        {this.renderDesktopOrder(showProtectionLabel)}
        {this.renderMobileOrder(showProtectionLabel)}
      </Fragment>
    );
  }
}

ItineraryPrice.propTypes = {
  t: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  itinerary: propTypes.itinerary.isRequired,
  search: PropTypes.object.isRequired,
  fare: PropTypes.object.isRequired,
  vendors: PropTypes.object.isRequired,
};

ItineraryPrice = createFilters(['t', 'formatCurrency'])(ItineraryPrice);

export default withRouter(ItineraryPrice);
