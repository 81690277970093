import { useEffect, useRef, cloneElement } from 'react';

const ClickOutside = ({ children, onClickOutside }) => {
  const node = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    // Called when unmounted.
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) {
      // Do nothing when clicked inside.
      return;
    }
    onClickOutside();
  };

  return cloneElement(children, { ref: node });
};

export default ClickOutside;
