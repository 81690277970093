import PropTypes from 'prop-types';
import { FlightSearch } from 'dohop-client-api/src/search';
import flight from 'dohop-client-api/src/flight';

export default {
  search: PropTypes.instanceOf(FlightSearch),
  itinerary: PropTypes.instanceOf(flight.Itinerary),
  route: PropTypes.instanceOf(flight.Route),
  flight: PropTypes.instanceOf(flight.Flight),
  fare: PropTypes.oneOfType([
    PropTypes.instanceOf(flight.Fare),
    PropTypes.instanceOf(flight.MultiFare),
  ]),
  filters: PropTypes.shape({ t: PropTypes.func.isRequired }),
};
