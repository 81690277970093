/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const _extend = require('lodash/extend');

// global configurations. Configuration of interest is:
// "index" - search index to tell the server to use
// "language" - Language code to use. All data like names of airports, cities etc. will be in this language
// "defaultCurrency" - Default currency to use if none specified when initializing search
// "protocol" - set to "https" to force https, defaults to creating urls starting with '//' which is browsers uses the same
// 	protocal as the page the user is on.
const config = {
  index: 'main',
  language: 'en',
  apiDomain: 'api.dohop.com',
  domainPrefix: '',
  extraSearchParams: {},
  crossValidateFaresort: false,
  defaultCurrency: 'EUR',
  enableFareRefresh: true,
  protocol: 'https:',
  searchAPIPrefix: '',
  set(conf) {
    return _extend(this, conf);
  },
};

module.exports = config;
