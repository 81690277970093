import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './LoadingBar.less';

class LoadingBar extends PureComponent {
  _isMounted = false;

  state = {
    progress: 0,
    finishing: false,
  };

  componentDidMount() {
    this.createInterval();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalId);
  }

  createInterval() {
    const { duration } = this.props;

    this.intervalId = setInterval(() => {
      const { start } = this.props;

      let progress = this.calculateProgress(start);

      if (progress >= 100) {
        progress = 100;
        clearInterval(this.intervalId);
      }

      this.setState({ progress });
    }, duration / 100);
  }

  componentDidUpdate() {
    const { start } = this.props;
    const { progress } = this.state;

    if (!start) {
      return;
    }

    if (progress >= 100 && this.calculateProgress(start) < 100) {
      this.createInterval();
    }

    if (progress < 100 && this.calculateProgress(start) >= 100) {
      clearInterval(this.intervalId);
      this.finishLoading();
    }
  }

  calculateProgress(startTime) {
    const { duration } = this.props;
    const elapsed = Date.now() - startTime;

    return (elapsed / duration) * 100;
  }

  finishLoading() {
    this.setState({ finishing: true, progress: 99.9 });

    // This prevent React setState on unmounted Component
    if (this._isMounted) {
      // After animation is over we stop rendering
      setTimeout(() => {
        this.setState({ finishing: false, progress: 100 });
      }, 500);
    }
  }

  render() {
    const { progress, finishing } = this.state;
    const { duration, className } = this.props;

    if (progress >= 99.9) {
      return false;
    }

    const transTime = finishing ? 0.5 : duration / 25000;

    return (
      <div
        className={classNames('LoadingBar', className, {
          'LoadingBar--finishing': finishing,
        })}
        style={{ width: progress + '%', transition: `width ${transTime}s linear` }}
      />
    );
  }
}

LoadingBar.defaultProps = {
  className: '',
};

LoadingBar.propTypes = {
  className: PropTypes.string,
};

export default LoadingBar;
