import React, { Component } from 'react';
import { connect } from 'react-redux';
import filtersSelector from '../selectors/filtersSelector';

export default function createFilters(filters, propName) {
  // single parameter is propName only
  if (typeof filters === 'string') [filters, propName] = [undefined, filters];

  const selector = filtersSelector(filters);

  return WrappedComponent => {
    class FiltersWrapper extends Component {
      render() {
        return <WrappedComponent ref={this.wrappedInstance} {...this.props} />;
      }
    }

    return connect(
      state => {
        if (propName) return { [propName]: selector(state) };
        return selector(state);
      },
      null,
      null
    )(FiltersWrapper);
  };
}
