import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _compact from 'lodash/compact';
import { toUTC } from 'dohop-client-api/src/time';
import { ENTER } from '@lib/keyCodes';
import { defaultState } from '../../reducers/flightSearchFormReducer';
import ClickOutside from '../ClickOutside';
import { queries } from '../MatchMedia';
import DatePickerInput from './DatePickerInput';
import DatePickerCalendar from './DatePickerCalendar';
import './DateRangePicker.less';

class DateRangePicker extends React.Component {
  state = {
    activeDateIndex: null, // null | 0 | 1
    month: null, // null | Date
    hoverDate: null, // null | Date
  };

  // Ment to be attached to the flexible input.
  flexibleInput = React.createRef();
  // Ment to be attached to the first input (departure input).
  firstInput = React.createRef();
  // Ment to be attached to the second input (return input).
  secondInput = React.createRef();
  // Ment to be attached to the calendar.
  calendar = React.createRef();

  componentDidMount() {
    const { initialOpen } = this.props;
    const { ipad } = queries;

    if (initialOpen && ipad.matches) {
      this.onOpen(0);
    }
  }

  /**
   * Triggered when the DatePicker should open and show the calendar.
   * @param {Number} idx - 0 || 1,
   *                       0 for the version where the departure date should be picked.
   *                       1 for the version where the return date should be picked.
   */
  onOpen = (idx) => {
    const { dates, onFocusCallback } = this.props;
    const { month } = this.state;
    let now = toUTC(new Date());

    this.setState({
      now,
      yearFromNow: toUTC(new Date(now.getUTCFullYear(), now.getUTCMonth() + 13, 0)),
      activeDateIndex: idx,
      month: month || dates[idx] || _compact(dates)[0] || now,
    });

    if (onFocusCallback) {
      onFocusCallback();
    }
  };

  /**
   * Closes the DatePicker.
   */
  onClose = (e) => {
    const { onCloseCallback } = this.props;
    this.setState({ activeDateIndex: null });

    if (onCloseCallback) {
      onCloseCallback(e);
    }
  };

  /**
   * Triggered when clicked outside the DatePicker.
   * Then we want it to close for screen sizes ipad and larger.
   */
  onClickOutside = () => {
    const { ipad } = queries;

    if (ipad.matches) {
      this.onClose();
    }
  };

  /**
   * Changes the month the user is viewing and picking dates from.
   */
  onChangeMonth = (diff) => {
    const { month } = this.state;
    let m = month;

    this.setState({ month: toUTC(new Date(m.getUTCFullYear(), m.getUTCMonth() + diff, 1)) });
  };

  /**
   * Triggered when a new day is selected.
   */
  onSelectDay = (day, shouldClose = true) => {
    const { onSelect } = this.props;
    const { activeDateIndex } = this.state;

    onSelect(activeDateIndex, day, shouldClose);
    this.setState({ hoverDate: null });

    if (shouldClose) {
      this.setState((state) => {
        return {
          activeDateIndex: state.activeDateIndex === 0 ? 1 : null,
        };
      });
      this.onClose();
    }
  };

  /**
   * Triggered when new days in the flexible calendar are selected.
   */
  onChangeFlexibleDates = (dates) => {
    const { onChangeFlexibleDates } = this.props;

    onChangeFlexibleDates(dates);
    this.onClose();
  };

  /**
   * When date is hovered this is needed for styling.
   */
  onDayHover = (date) => {
    this.setState({ hoverDate: date });
  };

  /**
   * Returns which date range should be highlighted.
   * The time period from the departure to the return date.
   */
  getHighlight() {
    const { dates } = this.props;
    const { hoverDate, activeDateIndex } = this.state;

    if (hoverDate) {
      if (activeDateIndex === 0) {
        return [hoverDate, dates[1]];
      }

      if (activeDateIndex === 1) {
        return [dates[0], hoverDate];
      }
    }

    return dates;
  }

  /**
   * On key down event handler for the first date.
   * If the enter key is pressed, the return date selection is activated,
   * unless its a one way flight, then we close the calendar.
   * @param {object} e - the event object.
   */
  onFirstInputKeyDown = (e) => {
    const { isOneWay } = this.props;

    if (e.keyCode === ENTER) {
      e.preventDefault();

      this.setState((state) => {
        return {
          activeDateIndex: state.activeDateIndex && !isOneWay === 0 ? 1 : null,
        };
      });

      if (!isOneWay) {
        this.secondInput.current.focus();
      } else {
        this.onClose(e);
      }
    }
  };

  /**
   * On key down event handler for the second date.
   * If the enter key is pressed, the onClose function is triggered.
   * @param {object} e - the event object.
   */
  onSecondInputKeyDown = (e) => {
    if (e.keyCode === ENTER) {
      e.preventDefault();
      this.onClose(e);
    }
  };

  render() {
    const {
      t,
      formatDate,
      isFlexible,
      flexibleValue,
      className,
      ids,
      labels,
      dates,
      shouldPopOpen,
      placeholders,
      showLabels,
      onClear,
      minDate,
      maxDate,
      calendar,
      titles,
      isOneWay,
      setIsOneWay,
      inputClassName,
      iconClassName,
      onChangeFlexible,
    } = this.props;
    const { activeDateIndex, month, now, yearFromNow, hoverDate } = this.state;
    const inputIdxList = isOneWay ? [0] : [0, 1];
    const inputRef = activeDateIndex === 0 ? this.firstInput : this.secondInput;

    return (
      <ClickOutside event="mousedown" onClickOutside={this.onClickOutside}>
        <div className={classNames('DateRangePicker', className)}>
          <div className="DateRangePicker__inputs">
            {!isFlexible &&
              inputIdxList.map((i) => (
                <DatePickerInput
                  id={ids && ids[i]}
                  t={t}
                  formatDate={formatDate}
                  twin={!isOneWay}
                  ref={i === 0 ? this.firstInput : this.secondInput}
                  key={i}
                  label={labels[i]}
                  showLabel={showLabels}
                  date={dates[i]}
                  isOpen={activeDateIndex === i}
                  placeholder={placeholders[i]}
                  onOpen={() => this.onOpen(i)}
                  canClear={i === 1 && Boolean(onClear && dates[i])}
                  inputClassName={inputClassName}
                  iconClassName={iconClassName}
                  shouldPopOpen={shouldPopOpen[i]}
                />
              ))}
            {isFlexible && (
              <DatePickerInput
                id={'flexibleDates'}
                t={t}
                formatDate={formatDate}
                ref={this.flexibleInput}
                placeholder={t('when')}
                label={t('when')}
                showLabel={showLabels}
                date={dates[0]}
                isOpen={activeDateIndex === 0}
                onOpen={() => this.onOpen(0)}
                flexibleValue={flexibleValue}
                flexible
                onClear={() => this.onChangeFlexibleDates(defaultState().get('flexibleDate'))}
                canClear={flexibleValue.get('d1_').diff(flexibleValue.get('d1'), 'months') < 11}
                inputClassName={inputClassName}
                iconClassName={iconClassName}
                shouldPopOpen={shouldPopOpen[0]}
              />
            )}
          </div>
          {activeDateIndex != null && (
            <DatePickerCalendar
              t={t}
              input={isFlexible ? this.flexibleInput : inputRef}
              ref={this.calendar}
              month={month}
              isSticky={!showLabels}
              minDate={minDate || now}
              maxDate={maxDate || yearFromNow}
              selected={dates}
              calendar={calendar}
              activeDateIndex={activeDateIndex}
              highlight={this.getHighlight()}
              hoverDate={hoverDate}
              title={titles[activeDateIndex]}
              onSelectDay={(...args) => this.onSelectDay(...args)}
              onChangeMonth={(diff) => this.onChangeMonth(diff)}
              onDayHover={(day) => this.onDayHover(day)}
              onClose={this.onClose}
              onKeyDown={
                activeDateIndex === 0 ? this.onFirstInputKeyDown : this.onSecondInputKeyDown
              }
              isFlexible={isFlexible}
              onChangeFlexible={onChangeFlexible}
              onChangeFlexibleDates={(d) => this.onChangeFlexibleDates(d)}
              flexibleValue={flexibleValue}
              isOneWay={isOneWay}
              setIsOneWay={setIsOneWay}
            />
          )}
        </div>
      </ClickOutside>
    );
  }
}

DateRangePicker.defaultProps = {
  ids: [null, null],
  isFlexible: null,
  flexibleValue: {},
  placeholders: ['', ''],
  labels: ['', ''],
  showLabels: false,
  titles: ['', ''],
  className: '',
  shouldPopOpen: [false, false],
  onClear: () => {},
  onFocusCallback: null,
  onChangeFlexible: () => {},
  nextDate: null,
  prevDate: null,
  calendar: null,
  minDate: null,
  maxDate: null,
  isOneWay: false,
  setIsOneWay: null,
};

DateRangePicker.propTypes = {
  ids: PropTypes.array,
  t: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired,
  isFlexible: PropTypes.bool,
  flexibleValue: PropTypes.object,
  isOneWay: PropTypes.bool,
  setIsOneWay: PropTypes.func,
  placeholders: PropTypes.array,
  labels: PropTypes.array,
  showLabels: PropTypes.bool,
  calendar: PropTypes.object,
  titles: PropTypes.array,
  className: PropTypes.string,
  shouldPopOpen: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onFocusCallback: PropTypes.func,
  onClear: PropTypes.func,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  onChangeFlexible: PropTypes.func,
  nextDate: PropTypes.instanceOf(Date),
  prevDate: PropTypes.instanceOf(Date),
  dates: PropTypes.array,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default DateRangePicker;
