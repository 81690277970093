import React, { forwardRef } from 'react';

/**
 * This component makes it possible for outer components to access their inner components DOM nodes.
 * This er nessasery so that outer components can manage
 * f.ex. focus, selection, or animations for inner components nodes.
 *
 * Example:
 * If we have an component called FancyInput we would do the following:
 *
 * In an outer component using the FancyInput component we add the ref on the FancyInput
 * like we would normally do on a regular DOM input.
 * fancyInput = React.createRef();
 * ...
 * <FancyInput ref={this.fancyInput}.../>
 *
 * In the FanycyInput.js file, before exporting the component, we owerwrite it by doing
 * FancyInput = withForwardedRef(FancyInput);
 *
 * Finally we add the ref on the regular DOM input inside the FancyInput component by doing
 * <input ref={forwardedRef} .../>.
 *
 * Now the outer component, using the FancyInput component, can access the real DOM input and f.ex. focus on it
 * or select the value by doing this.fancyInput.current.focus() or this.fancyInput.current.select().
 *
 * */

export default function withForwardedRef(Component) {
  return forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
}
