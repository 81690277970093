/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import './InfoFooter.less';

class InfoFooter extends Component {
  render() {
    const { t, extraClassName, children } = this.props;
    const dohopYears = `Dohop 2004-${moment().year()}`;
    return (
      <div className={classNames('InfoFooter', extraClassName)}>
        © {dohopYears}
        <p className="InfoFooter__text">{children || t('dohop_info_footer')}</p>
        <a
          href="https://www.worldtravelawards.com/award-travel-technologys-leading-flight-comparison-website-2019"
          target="_blank"
        >
          <img className="InfoFooter__img" src="/content/img/wta2019.png" alt={t('WTA_title')} />
        </a>
        {/* This is a honeypot link to blacklist bad bots that ignore our robots.txt file.
          PLEASE DON'T CLICK THIS LINK!
          If you do, please find solution in the README file to get back access to dohop.com*/}
        <a href="/hunang/" className="InfoFooter__hunang" aria-hidden>
          hunang
        </a>
      </div>
    );
  }
}

InfoFooter.propTypes = {
  t: PropTypes.func.isRequired,
  extraClassName: PropTypes.string,
  children: PropTypes.string,
};

export default InfoFooter;
