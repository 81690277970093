/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const { EventEmitter } = require('events');
const _fromPairs = require('lodash/fromPairs');
const _isString = require('lodash/isString');
const _extend = require('lodash/extend');
const _keys = require('lodash/keys');
const _values = require('lodash/values');
const _uniqBy = require('lodash/uniqBy');
const _some = require('lodash/some');
const _clone = require('lodash/clone');
const util = require('./util');
const config = require('./config');

const dateUpdate = function (obj, newDate) {
  if (obj.min == null || newDate.getTime() < obj.min.getTime()) {
    obj.min = new Date(newDate.getTime());
  }
  if (obj.max == null || newDate.getTime() > obj.max.getTime()) {
    return (obj.max = new Date(newDate.getTime()));
  }
};

const timeUpdate = function (obj, newTime) {
  if (obj.min == null || newTime < obj.min) {
    obj.min = newTime;
  }
  if (obj.max == null || newTime > obj.max) {
    return (obj.max = newTime);
  }
};

const airportCodeToList = (csv) =>
  Array.from((csv || '').split(',')).map((code) => util.trim(code).toUpperCase());

const mapTo = (keys, value) => _fromPairs(Array.from(keys).map((key) => [key, value]));

const nameToLower = (v) => v.getName().toLowerCase();

// MetaTracker keeps track of various information about single flight search
class MetaTracker {
  constructor(events, { from, to, d1, d2, residency, language, index, adults, youngstersAges }) {
    this.from = from;
    this.to = to;
    this.d1 = d1;
    this.d2 = d2;
    this.residency = residency;
    this.language = language;
    this.index = index;
    this.adults = adults;
    this.youngstersAges = youngstersAges;
    if (_isString(this.from)) {
      this.from = mapTo(airportCodeToList(this.from), true);
    }
    if (_isString(this.to)) {
      this.to = mapTo(airportCodeToList(this.to), true);
    }

    this.twoway = !!this.d2;
    this.oneway = !this.twoway;

    this.dates = {
      outbound: { departure: {}, arrival: {} },
      homebound: { departure: {}, arrival: {} },
    };

    if (this.adults == null) {
      this.adults = 1;
    }
    if (this.youngstersAges == null) {
      this.youngstersAges = [];
    }

    if (this.language == null) {
      this.language = config.language;
    }
    if (this.index == null) {
      this.index = config.index;
    }

    this.redEye = false;
    this.overNight = false;
    this.connectionTime = {};
    this.vendors = {};
    this.airlines = {};
    this.aircraft = {};
    this.airports = {};
    this.stopCount = {};
    this.duration = {};
    this.routeDuration = { outbound: {}, homebound: {} };
    this.groundTransit = { outbound: {}, homebound: {} };

    util.each(['outbound', 'homebound'], (k) => {
      return events.on(k, (routes) => {
        return (() => {
          const result = [];
          for (let r of Array.from(routes)) {
            if (!this.redEye && r.isRedEye()) {
              this.redEye = true;
            }
            if (!this.overNight && r.isOverNight()) {
              this.overNight = true;
            }
            this.stopCount[r.getNumStops()] = true;
            this.groundTransit[k][r.isGroundTransit()] = true;
            timeUpdate(this.duration, r.getDuration());
            timeUpdate(this.routeDuration[k], r.getDuration());
            dateUpdate(this.dates[k].departure, r.getDeparture());
            dateUpdate(this.dates[k].arrival, r.getArrival());

            result.push(
              Array.from(r.getStopOvers()).map((stop) =>
                timeUpdate(this.connectionTime, stop.getDuration())
              )
            );
          }
          return result;
        })();
      });
    });

    util.each(['vendors', 'airlines', 'airports', 'aircraft'], (k) => {
      return events.on(k, (vs) => {
        return _extend(this[k], vs);
      });
    });
  }

  // @return [Array<String>] the search's origin airport codes
  getFrom() {
    return _keys(this.from);
  }

  // @return [Array<String>] the search's destination airport codes
  getTo() {
    return _keys(this.to);
  }

  // @return [Date] the search's outbound date
  getOutboundDate() {
    return this.d1;
  }

  // @return [Date] the search's return date
  getHomeboundDate() {
    return this.d2;
  }

  // @return [Boolean] Is the search doing a one-way search
  isOneWay() {
    return this.oneway;
  }

  // @return [Number] Number of adult passengers
  getNumberOfAdults() {
    return this.adults;
  }

  // @return [Array<Number>] Ages of passengers under 18.
  getYoungstersAges() {
    return this.youngstersAges;
  }

  // @return [Integer] total number of passengers
  getNumPassengers() {
    return this.adults + this.youngstersAges.length;
  }

  // @return [String] language used for this search
  getLanguage() {
    return this.language;
  }

  // @return [String] search index used for this search
  getIndex() {
    return this.index;
  }

  // @return [String] Residency of user making the search
  getResidency() {
    return this.residency;
  }

  // @return [Object] min/max outbound departure dates of all itineraries
  getOutboundDepartureDates() {
    return this.dates.outbound.departure;
  }

  // @return [Object] min/max outbound arrival dates of all itinerares
  getOutboundArrivalDates() {
    return this.dates.outbound.arrival;
  }

  // @return [Object] min/max homebound departure dates of all itineraries
  getHomeboundDepartureDates() {
    return this.dates.homebound.departure;
  }

  // @return [Object] min/max homebound arrival dates of all itineraries
  getHomeboundArrivalDates() {
    return this.dates.homebound.arrival;
  }

  // @return [Object] min/max connection times of all flight connections
  getConnectionTimes() {
    return this.connectionTime;
  }

  // @return [Object] min/max duration of all routes, outbound and homebound
  getDuration() {
    return this.duration;
  }

  // @return [Object] min/max duration of outbound dates
  getOutboundDuration() {
    return this.routeDuration.outbound;
  }

  // @return [Object] min/max duration of homebound dates
  getHomeboundDuration() {
    return this.routeDuration.homebound;
  }

  // @return [Boolean] if any flight is red eye
  hasRedEye() {
    return this.redEye;
  }

  // @return [Boolean] if any flight is over night
  hasOverNight() {
    return this.overNight;
  }

  // @return [<Array<Vendor>] a list of vendors that appear in this search
  getVendors() {
    return _uniqBy(util.sortBy(_values(this.vendors), nameToLower), nameToLower);
  }

  // @return [Array<Aircraft>] a list of aircrafts that appear in this search
  getAircrafts() {
    return util.sortBy(_values(this.aircraft), (a) => a.getName().toLowerCase());
  }

  // @return [Array<Airline>] a list of airlines that appear in this search
  getAirlines() {
    return util.sortBy(_values(this.airlines), (a) => a.getName().toLowerCase());
  }

  // @return [Array<Airport>] a list of airports that appear in this search
  getAirports() {
    return util.sortBy(_values(this.airports), (a) => a.getCityName().toLowerCase());
  }

  // @return [Array<Integer>] an array of all number of stops of all search's routes
  getStopCounts() {
    return _keys(this.stopCount);
  }

  // @return [Boolean] if any route of this search has the given stop count
  // @param [Integer] n number of stops
  hasStopCount(n) {
    return this.stopCount[n] === true;
  }

  // @return [Boolean] if any flight/itinerary has ground transit
  hasGroundTransit() {
    return _some(this.groundTransit, (v, k) => v[true] === true);
  }

  // @return [Boolean] if search contains only ground transit itineraries
  hasOnlyGroundTransit() {
    return _some(this.groundTransit, (v, k) => v[true] === true && v[false] !== true);
  }

  // @nodoc
  clone() {
    const clone = new MetaTracker({ on() {} }, this);

    for (let k of Object.keys(this || {})) {
      const v = this[k];
      clone[k] = util.deepClone(v);
    }

    return clone;
  }
}

exports.MetaTracker = MetaTracker;
