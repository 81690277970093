import { Map } from 'immutable';
import axios from 'axios';
import omit from 'lodash/omit';
import picker from 'dohop-client-api/src/picker';
import processException from '@lib/processException';
import translate from '../lib/translate';
import globals from '../globals';
import config from '../lib/config';

export function formatValue(value, t) {
  if (!value) return '';
  if (value.get('flexible')) return t('anywhere');
  if (value.get('isCountry')) {
    return `${value.get('name')}`;
  }
  const codeOrAny = value.get('numChildren') ? t('any') : value.get('code');
  return `${value.get('name')} (${codeOrAny})`;
}

/**
 * Finds parent city of airports. For example if city name is London Heatthrow
 * then the return city should be just London
 * @param {Array<Object>} airports - Airpors array
 * @param {Object<immutable<Map>>} childAirport - Child airport
 * @returns {String}
 */
function getCityNameFromAirport(airports, childAirport) {
  let city = '';

  airports.forEach((a) => {
    const airport = Map(a);

    if (airport.get('iata') === childAirport.get('iata') && !airport.get('isChild')) {
      city = airport.get('name');
    }
  });

  return city.trim();
}

export function requestFlightSearchAutocomplete(term, user, t, showCountries) {
  return new Promise((resolve, reject) => {
    if (!term) {
      return resolve([]);
    }

    picker.autocomplete(term, {
      language: user.get('language'),
      success: (airports, countries) => {
        let countryList = [];

        const airportList = airports.map((airport) => {
          airport = Map(airport);
          const isChild = airport.get('isChild');
          const format = formatValue(airport, t);
          // Add city to destination map
          airport = airport.set(
            'city',
            isChild ? getCityNameFromAirport(airports, airport) : airport.get('name')
          );

          return {
            item: airport,
            value: format,
            title: format,
            subTitle: isChild ? '' : airport.get('country'),
            nested: isChild,
          };
        });

        if (showCountries) {
          countryList = countries.map((country) => {
            country = Map({
              name: country.country_name,
              code: country.country_code,
              continent: country.continent_name,
              isCountry: true,
            });

            const format = formatValue(country, t);

            return {
              item: country,
              value: format,
              title: format,
              subTitle: country.get('continent'),
            };
          });
        }

        return resolve(airportList.concat(countryList));
      },
      error: reject,
    });
  });
}

// redux action wrapper around requestFlightSearchAutocomplete
export function flightSearchAutocomplete(term, showCountries = false) {
  return (dispatch, getState) => {
    const user = getState().get('user');
    const translations = globals('translations', user.get('language'));
    const t = translate.bind(null, { translations });
    return requestFlightSearchAutocomplete(term, user, t, showCountries);
  };
}

export async function requestHotelAutocomplete(text, language) {
  if (!text) return Promise.resolve([]);

  const url = process.env.IS_BROWSER
    ? '/proxy/hotel-autocomplete'
    : config.HOTEL_AUTOCOMPLETE_PROXY_URL;

  const params = {
    language,
    text,
    affiliate_id: 304142,
  };

  const headers = {};
  if (!process.env.IS_BROWSER) {
    headers.Authorization = config.HOTEL_AUTOCOMPLETE_AUTHORIZATION;
  }

  const response = await axios.get(url, { params, headers });
  return response.data.result.map((city) => {
    let labels = city.label.split(',');
    if (labels.length === 4) labels.splice(2, 1);
    const title = labels.join(',');

    return {
      item: Map(omit(city)).set('label', title),
      value: title,
      title,
    };
  });
}

// redux action wrapper around requestHotelAutocomplete
export function hotelAutocomplete(term) {
  return (dispatch, getState) => {
    return requestHotelAutocomplete(term, getState().getIn(['user', 'language']));
  };
}

export async function requestCarAutocomplete(term, language) {
  if (!term) return [];
  // JSON-ified XML request body
  const data = {
    '@xmlns': 'http://www.cartrawler.com/',
    '@Version': '1.000',
    '@PrimaryLangID': language,
    '@Target': 'Production',
    POS: {
      Source: {
        RequestorID: {
          '@Type': '16',
          '@ID': '77445',
          '@ID_Context': 'CARTRAWLER',
        },
      },
    },
    VehLocSearchCriterion: {
      '@ExactMatch': 'true',
      '@ImportanceType': 'Mandatory',
      PartialText: {
        '@Size': '30',
        '#text': term,
      },
    },
  };
  let response;
  try {
    response = await axios({
      url: '/proxy/car-autocomplete',
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data,
      timeout: 3000,
    });
  } catch (error) {
    processException(error);
    return [];
  }

  const { VehMatchedLocs: locations, Errors: error } = response.data;

  if (locations && !error) {
    let { LocationDetail: locDetails = [] } = locations;
    if (!Array.isArray(locDetails)) {
      // the API is so smart it retuns LocationDetail as an object if there is only one result
      locDetails = [locDetails];
    }
    const countries = globals('countries', language);
    return locDetails.map((loc) => {
      return {
        item: Map({
          name: loc['@Name'],
          code: loc['@Code'],
          airportCode: loc['@AirportCode'],
          countryCode: loc['@CountryCode'],
        }),
        title: loc['@Name'],
        value: loc['@Name'],
        subTitle: countries.getIn([loc['@CountryCode'], 'name'], null),
      };
    });
  }

  return [];
}

// redux action wrapper around requestCarAutocomplete
export function carAutocomplete(term) {
  return (dispatch, getState) => {
    return requestCarAutocomplete(term, getState().getIn(['user', 'language']));
  };
}
