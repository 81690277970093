import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable';
import classNames from 'classnames';
import { getInspiredUrl } from '@lib/getFlightUrl';
import { weekendStay, anyStay, fetchInspired as fetchInspiredAction } from '@actions/goActions';
import { getImagesOnClient } from '@lib/headerImages';
import Header from '../header/BigLogoHeader';
import Heading from './Heading';
import Footer from '../Footer';
import SearchForm from './SearchForm';
import DohopHead from '../DohopHead';
import Inspired from './Inspired';
import Constraint from '../Constraint';
import PageInfoBanner from './PageInfoBanner';
import TrustBanner from './TrustBanner';
import Section from '../Section';
import MatchMedia from '../MatchMedia';
import MobileTabs from '../mobile/MobileTabs';
import './FrontPage.less';

const HEADER_IMAGES = getImagesOnClient();

class FrontPage extends Component {
  componentDidMount() {
    const { fetchInspired, inspired } = this.props;

    // If server side fetchInspired failed then retry on the client side
    if (inspired && inspired.size === 0) {
      fetchInspired();
    }
  }

  render() {
    const { t, formatCurrency, inspired, user, hotelCheck } = this.props;

    const weekendCities = inspired.getIn(['results', 'weekends'], List()).map((c) =>
      c.merge({
        caption: formatCurrency(c.get('price')),
        url: getInspiredUrl(c, weekendStay, true),
      })
    );

    const anyCities = inspired.getIn(['results', 'any'], List()).map((c) =>
      c.merge({
        caption: formatCurrency(c.get('price')),
        url: getInspiredUrl(c, anyStay, false),
      })
    );

    const sectionProps = {};
    let suppressHydrationWarning = false;

    if (HEADER_IMAGES) {
      sectionProps.backgroundImages = HEADER_IMAGES.flight;
      suppressHydrationWarning = true;
    }

    return (
      <div className={classNames('FrontPage', 'PageRouteAnimation__fadeIn')}>
        <Section header transparent>
          <Constraint front mobilePadding>
            <DohopHead />
            <Header tab="flights" isFrontpage logo />
          </Constraint>
        </Section>
        <Section
          className="FrontPage__header"
          {...sectionProps}
          pageName="flight"
          suppressHydrationWarning={suppressHydrationWarning}
        >
          <Constraint front mobilePadding className="FrontPage__headerConstraint">
            <Heading className="FrontPage__heading">{t('find_best_flights')}</Heading>
            <SearchForm showCompare focusOnMount autoFocusNext enableHotelCheckbox={hotelCheck} />
          </Constraint>
        </Section>
        <Section header transparent>
          <Constraint front>
            <Inspired
              maxSize={5}
              title={t('get_inspired')}
              subtitle={t('check_out_our_cheapest_flights %(origin)s', {
                origin: inspired.getIn(['origin', 'name']),
              })}
              items={anyCities}
              trackingId="cheapestCity"
            />
            <Inspired
              maxSize={2}
              title={t('just_for_the_weekend')}
              subtitle={t('discover_travels')}
              items={weekendCities}
              trackingId="cheapestWeekendCity"
            />
          </Constraint>
        </Section>
        <TrustBanner
          logos
          site="flights"
          residency={user.get('residency')}
          title={t('trustbanner_header_free')}
          text={t('trustbanner_text_free')}
        />
        <PageInfoBanner bannerType="flightsBottom" />
        <MatchMedia max="ipad" key="matchmedia">
          <MobileTabs tab="flights" />
        </MatchMedia>
        <Section footer>
          <Constraint front>
            <Footer withMobileTabs />
          </Constraint>
        </Section>
      </div>
    );
  }
}

FrontPage.propTypes = {
  inspired: ImmutablePropTypes.map.isRequired,
  hotelCheck: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hotelCheck: state.getIn(['flightSearchForm', 'hotelCheck']),
});

const mapDispatchToProps = (dispatch) => ({
  fetchInspired: () => dispatch(fetchInspiredAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrontPage);
